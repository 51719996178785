import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { startCase } from 'lodash';

import { selectLayout, selectSidebar } from 'src/redux/layout/selectors';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { useWindowSize } from 'src/hooks/useWindowSize';
import CustomLink from 'src/components/CustomLink';
import { sideItems } from './constants';
import SidebarTooltip from '../SidebarTooltip';
import './styles.scss';

export const getUrl = (link: string, showSidebarState?: string, id?: string) => {
    if (showSidebarState === 'root') {
        return link;
    }
    return `${link}/${id}`;
};

export const filterSideItems = (
    items: Record<string, any>[],
    layout: Record<string, any>,
    isModuleActive: (name: Modules) => boolean,
    width: number,
) => {
    return items.filter((item: Record<string, any>) => {
        if (layout.layout.user.admin === false && item?.showOnlyAdmin === true) {
            return false;
        }

        if (layout.layout.user.admin && item?.showOnMobileOnStaffOnly && width < 1024) {
            return false;
        }

        if (layout.layout.school.infoPages && item.infoPages) {
            return false;
        }

        if (!isModuleActive(item.id)) {
            return false;
        }

        return !(width < 1024 && !item.showOnMobile);
    });
};

const Sidebar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const showSidebar = useSelector(selectSidebar);
    const layout = useSelector(selectLayout);
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const { width } = useWindowSize();
    const menuItems = filterSideItems(
        sideItems(t)[showSidebar || 'root'],
        layout,
        isModuleActive,
        width,
    );

    const id = useMemo(() => {
        const locArr = location.pathname.split('/');
        return locArr.find((loc) => +loc);
    }, [location]);

    const isActive = useCallback(
        (item) => {
            return new RegExp(item.searchLink).test(location.pathname);
        },
        [location, id],
    );

    return (
        <div className={classNames('sidebar', { 'with-less-than-4-items': menuItems.length < 4 })}>
            {showSidebar &&
                menuItems.map((item: Record<string, any>, idx: number) => (
                    <div
                        className={classNames('sidebar-item sc_hover_color_text_svg', {
                            'sidebar-item--active': isActive(item),
                        })}
                        key={idx}
                    >
                        <CustomLink
                            to={getUrl(item.link, showSidebar, id)}
                            style={{ textDecoration: 'none' }}
                            className="sc_hover_color_text_svg"
                            key={idx}
                            data-testid={`sidebar-item-${idx}`}
                        >
                            {item.icon(isActive(item))}
                            <span>{getModuleName(item.id) || startCase(item.name)}</span>
                        </CustomLink>
                        <SidebarTooltip
                            id={item.link}
                            title={getModuleName(item.id) || item.name}
                            desc={item.desc}
                        />
                    </div>
                ))}
        </div>
    );
};

export default Sidebar;
