import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { validateValue } from 'src/helpers/validateValue';
import { InputTextOldProps } from './types';
import './styles.scss';

const InputTextOld = ({
    validators,
    inputProcessor,
    inputName,
    inputLabel,
    fullWidth,
    onChange,
}: InputTextOldProps) => {
    const [inlineError, setInlineError] = useState('');

    useEffect(() => {
        setInlineError(inputProcessor.getInlineErrors(inputName));
    }, [inputProcessor.errors, inputProcessor.values]);

    useEffect(() => {
        if (inputProcessor.getValue(inputName) !== undefined) {
            inputProcessor.updateError({
                [inputName]: validateValue(
                    inputProcessor.getValue(inputName),
                    inputLabel,
                    validators,
                ),
            });
        }
    }, []);

    return (
        <div className="input">
            <div>
                <input
                    data-testid="inputText"
                    className={classNames('input__field', { invalid: inlineError, fullWidth })}
                    type="text"
                    onChange={(e) => {
                        inputProcessor.updateInput({ [inputName]: e.target.value });

                        inputProcessor.updateError({
                            [inputName]: validateValue(e.target.value, inputLabel, validators),
                        });
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    value={inputProcessor.getValue(inputName) ?? ''}
                />
                {inputProcessor.isLoading(inputName) && <div className="loader-line" />}
            </div>
            <div className="input-error" title={inlineError}>
                {inlineError}
            </div>
        </div>
    );
};

export default InputTextOld;
