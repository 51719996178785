export const useNewAssignmentsFilterHelper = ({
    filters,
    setFilters,
    titles,
    setTitles,
}: {
    filters: any;
    setFilters: any;
    titles: any;
    setTitles: any;
}) => {
    const checkFilter = (cb: any) => {
        if (cb) {
            cb();
        }
    };

    const updateFilter = (newValue: any) => {
        setFilters(Object.assign(filters, newValue));
    };

    const deleteFilter = (filtersToDelete: any) => {
        if (filtersToDelete) {
            filtersToDelete.forEach((curFilter: any) => {
                delete filters[curFilter];
            });
        }
    };

    const updateTitle = (newValue: any) => {
        const tempTitles = Object.assign(structuredClone(titles), newValue);
        setTitles({});
        setTitles(tempTitles);
    };

    return {
        updateFilter,
        updateTitle,
        checkFilter,
        deleteFilter,
    };
};
