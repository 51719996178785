const ManPlus = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="user-add"
            width="20.124"
            height="20.124"
            viewBox="0 0 20.124 20.124"
        >
            <path
                id="Path_25407"
                data-name="Path 25407"
                d="M21.87,10.516H20.193V8.839a.839.839,0,0,0-1.677,0v1.677H16.839a.839.839,0,0,0,0,1.677h1.677V13.87a.839.839,0,0,0,1.677,0V12.193H21.87a.839.839,0,0,0,0-1.677Z"
                transform="translate(-2.584 -1.292)"
                fill="#54585a"
            />
            <path
                id="Path_25408"
                data-name="Path 25408"
                d="M8.031,10.062A5.031,5.031,0,1,0,3,5.031a5.031,5.031,0,0,0,5.031,5.031Zm0-8.385A3.354,3.354,0,1,1,4.677,5.031,3.354,3.354,0,0,1,8.031,1.677Z"
                transform="translate(-0.484 0)"
                fill="#54585a"
            />
            <path
                id="Path_25409"
                data-name="Path 25409"
                d="M7.547,14A7.555,7.555,0,0,0,0,21.547a.839.839,0,1,0,1.677,0,5.87,5.87,0,1,1,11.739,0,.839.839,0,0,0,1.677,0A7.555,7.555,0,0,0,7.547,14Z"
                transform="translate(0 -2.261)"
                fill="#54585a"
            />
        </svg>
    );
};
export default ManPlus;
