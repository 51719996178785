import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ActionText from 'src/components/ActionText';
import HeaderButton from 'src/components/HeaderButton';
import Add from 'src/components/Icons/Add';
import Remove from 'src/components/Icons/Remove';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { appConstants } from 'src/constants';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { getClasses } from 'src/redux/classes/actions';
import { selectClasses } from 'src/redux/classes/selectors';
import classNames from 'classnames';
import {
    deleteStudentMarking,
    deleteStudentPackage,
    deleteStudentTutorial,
    getStudentAudit,
    getStudentEmails,
    getStudentMarking,
    getStudentResultsHistory,
    getStudentTests,
    getStudentTutorials,
    getStudentUpgrades,
} from 'src/redux/students/actions';
import {
    selectStudent,
    selectStudentAudit,
    selectStudentEmails,
    selectStudentMarkings,
    selectStudentResultsHistory,
    selectStudentTests,
    selectStudentTutorials,
    selectStudentUpgrades,
} from 'src/redux/students/selectors';
import {
    Assignment,
    PutStudentMarkingHistory,
    PutStudentProps,
    PutStudentResultsHistoryAP,
} from 'src/redux/students/types';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { selectLayout } from 'src/redux/layout/selectors';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { API } from 'src/api';
import CheckMark from 'src/components/Icons/CheckMark';
import { ActiveClasses } from '../../Classes/constants';
import ProfileCardContent from '../ProfileCardContent';
import { getResultScore } from './getResultsScore';
import { deleteTestDispatch, getReportLink, profileInfoGetReportBlock } from './helpers';
import { linkReview, reviewDisabled } from './linkReview';
import ProfileInfoAssignProgressTestModal from './modals/ProfileInfoAssignProgressTestModal';
import ProfileInfoAuditLogModal from './modals/ProfileInfoAuditLogModal';
import ProfileInfoCreateEmailModal from './modals/ProfileInfoCreateEmailModal';
import ProfileInfoEmailsModal from './modals/ProfileInfoEmailsModal';
import ProfileInfoResultsAndHistoryModal from './modals/ProfileInfoResultsAndHistoryModal';
import ProfileInfoTestsModal from './modals/ProfileInfoTestsModal';
import ProfileInfoTutorialsModal from './modals/ProfileInfoTutorialsModal';
import ProfileMain from './ProfileMain';
import TableLight from './TableLight';
import ProfileInfoAssignCreditsModal from './modals/ProfileInfoAssignCreditsModal';
import ProfileInfoAssignmentsModal from './modals/ProfileInfoAssignmentsModal';
import ProfileInfoAssignPackagesModal from './modals/ProfileInfoAssignPackagesModal';

import './styles.scss';

const ProfileInfo = ({ profile }: { profile: PutStudentProps }) => {
    const [openDeleteTutorialConfirm, setOpenDeleteTutorialConfirm] = useState<{
        studentId?: string;
        tutorialId: string | number;
    } | null>();
    const [openDeleteTestConfirm, setOpenDeleteTestConfirm] = useState<any>();
    const [openDeletePackagesConfirm, setOpenDeletePackagesConfirm] = useState<number | null>(null);
    const [openDeleteCheckConfirm, setOpenDeleteCheckConfirm] =
        useState<PutStudentMarkingHistory | null>(null);
    const dispatch = useDispatch();
    const [openAssignNewTest, setOpenAssignNewTest] = useState<boolean | null>();
    const [openAssignCredits, setOpenAssignCredits] = useState<boolean>(false);
    const [openAssignPackages, setOpenAssignPackages] = useState<boolean>(false);
    const [openEmails, setOpenEmails] = useState<string | null>();
    const [openAuditLog, setOpenAuditLog] = useState<string | null>();
    const params = useParams();
    const openModal = new URLSearchParams(window.location.search).get('openModal');
    const [openAssign, setOpenAssign] = useState(openModal === 'assignments');
    const [openTutorials, setOpenTutorials] = useState<string | null>();
    const [openTests, setOpenTests] = useState<string | null>();
    const [openSuccessModal, setOpenSuccessModal] = useState<string | null>();
    const [openCreateEmail, setOpenCreateEmail] = useState<boolean | null>();
    const [openResultsAndHistory, setOpenResultsAndHistory] =
        useState<null | PutStudentResultsHistoryAP>();
    const studentResults = useSelector(selectStudentResultsHistory);
    const auditLog = useSelector(selectStudentAudit);
    const emails = useSelector(selectStudentEmails);
    const tutorials = useSelector(selectStudentTutorials);
    const tests = useSelector(selectStudentTests);
    const classesData = useSelector(selectClasses);
    const markings = useSelector(selectStudentMarkings);
    const upgrades = useSelector(selectStudentUpgrades);
    const { layout } = useSelector(selectLayout);
    const student = useSelector(selectStudent);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isModuleActive } = useIsModuleActive();
    const [assignments, setAssignments] = useState([] as unknown as Assignment[]);

    useEffect(() => {
        if (!layout.user.uid) {
            return;
        }
        if (layout.isIelts) {
            dispatch(
                getStudentMarking({ dataType: 'ielts/markings', studentId: params.id as string }),
            );
            dispatch(
                getStudentUpgrades({ dataType: 'ielts/upgrades', studentId: params.id as string }),
            );
        }
        dispatch(getStudentAudit({ dataType: 'audit', studentId: params.id as string, limit: 4 }));
        dispatch(
            getStudentEmails({ dataType: 'emails', studentId: params.id as string, limit: 4 }),
        );
        dispatch(
            getStudentTutorials({
                dataType: 'tutorials',
                studentId: params.id as string,
                limit: 4,
            }),
        );
        dispatch(getClasses(ActiveClasses.OPEN));
        dispatch(getStudentTests({ dataType: 'tests', studentId: params.id as string, limit: 4 }));
        dispatch(getStudentResultsHistory({ studentId: params.id as string }));

        dispatch(startLoading());
        API.get()(`/staff/students/${params.id}/assignments`)
            .then((data) => {
                const sortedData = [...data].sort((prev, next) => {
                    if (prev.date > next.date) {
                        return -1;
                    }
                    if (prev.date > next.date) {
                        return 1;
                    }
                    return 0;
                });
                setAssignments(sortedData);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    }, [JSON.stringify(layout || {})]);

    return (
        <>
            <div className="profile-items">
                <ProfileMain profile={profile} />
                <ProfileCardContent
                    id={Modules.StudentProfileHistory}
                    title={t('profile.resultsHistory')}
                    buttons={
                        <HeaderButton
                            onClick={() => {
                                setOpenResultsAndHistory(studentResults);
                            }}
                        >
                            {t('profile.viewAll')}
                        </HeaderButton>
                    }
                    table={
                        <TableLight
                            header={
                                <div className="profile-results profile-table-header">
                                    <span>{t('profile.type')}</span>
                                    <span>{t('profile.score')}</span>
                                    <span>{t('emails.date')}</span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={studentResults.slice(0, 4).map((result) => {
                                return (
                                    <div
                                        key={result.id}
                                        className="profile-results profile-table-row"
                                    >
                                        {reviewDisabled(result) ? (
                                            <span>{result.type}</span>
                                        ) : (
                                            <ActionText
                                                onClick={() =>
                                                    window.open(
                                                        linkReview(result, profile),
                                                        '_blank',
                                                    )
                                                }
                                            >
                                                {result.type}
                                            </ActionText>
                                        )}
                                        <div>{getResultScore(result)}</div>
                                        <div>{moment.unix(result.datetime).format()}</div>
                                    </div>
                                );
                            })}
                        />
                    }
                />
                <ProfileCardContent
                    title="Assignments"
                    id={Modules.StudentProfileAssignments}
                    buttons={
                        <>
                            <HeaderButton
                                onClick={() => {
                                    navigate(
                                        `/${
                                            /*eslint no-restricted-globals: ["error", "event"]*/
                                            location.pathname.includes('all-students')
                                                ? `all-students`
                                                : `students/${params.classId ?? ''}`
                                        }/${params.id}/assignments`,
                                    );
                                }}
                            >
                                <Add />
                                {t('profile.addWork')}
                            </HeaderButton>
                            <HeaderButton
                                onClick={() => {
                                    setOpenAssign(true);
                                }}
                            >
                                {t('profile.viewAll')}
                            </HeaderButton>
                        </>
                    }
                    table={
                        <TableLight
                            header={
                                <div className="profile-assignments profile-table-header">
                                    <span>{t('profile.type')}</span>
                                    <span>{t('profile.name')}</span>
                                    <span className="profile-assignments-completed">
                                        {t('profile.completed')}
                                    </span>
                                    <span className="profile-assignments-score">
                                        {t('profile.score')}
                                    </span>
                                    <span className="profile-assignments-date">
                                        {t('profile.date')}
                                    </span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={assignments.slice(0, 4).map((result) => {
                                return (
                                    <div
                                        key={`${result.id}_${result.date}`}
                                        className="profile-assignments profile-table-row"
                                    >
                                        <div className="profile-assignments-type">
                                            {getNoEmpty(result.type || result.actionType)}
                                        </div>
                                        <div className="profile-assignments-name">
                                            {result.title}
                                        </div>
                                        <div className="profile-assignments-completed">
                                            {/* eslint-disable-next-line no-nested-ternary */}
                                            {result.progress === 100 || result?.completed ? (
                                                <CheckMark />
                                            ) : result.progress ? (
                                                `${result.progress}%`
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                        <div className="profile-assignments-score">
                                            {typeof result.score === 'number'
                                                ? `${result.score}%`
                                                : '-'}
                                        </div>
                                        <div className="profile-assignments-date">
                                            <span>{moment.unix(result.date).format()}</span>
                                            <span
                                                className="profile-assignments-button"
                                                onClick={() => {
                                                    window.open(result.preview);
                                                }}
                                            >
                                                {t('profile.view')}
                                            </span>
                                            <span
                                                className="profile-assignments-button"
                                                onClick={() => {
                                                    const url = getReportLink({
                                                        id: result.id,
                                                        timestamp: result.date,
                                                        uid: +params.id!,
                                                        type: (result.type ||
                                                            result.actionType)!.toLowerCase() as any,
                                                    });
                                                    window.open(url);
                                                }}
                                            >
                                                {t('profile.report')}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        />
                    }
                />
                <ProfileCardContent
                    title="Emails"
                    id={Modules.StudentProfileEmails}
                    buttons={
                        <>
                            <HeaderButton
                                onClick={() => {
                                    setOpenCreateEmail(true);
                                }}
                            >
                                <Add />
                                {t('profile.createEmail')}
                            </HeaderButton>
                            <HeaderButton
                                onClick={() => {
                                    setOpenEmails(params.id);
                                }}
                            >
                                {t('profile.viewAll')}
                            </HeaderButton>
                        </>
                    }
                    table={
                        <TableLight
                            header={
                                <div className="profile-emails profile-table-header">
                                    <span>{t('profile.sentEmails')}</span>
                                    <span>{t('profile.sentDate')}</span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={emails.slice(0, 4).map((result, index) => {
                                return (
                                    <div
                                        key={`${result.timestamp}_${index}`}
                                        className="profile-emails profile-table-row"
                                    >
                                        <div title={result.subject}>{result.subject}</div>
                                        <div title={moment.unix(result.timestamp).format()}>
                                            {moment.unix(result.timestamp).format()}
                                        </div>
                                    </div>
                                );
                            })}
                        />
                    }
                />
                <ProfileCardContent
                    title={t('profile.auditLog')}
                    id={Modules.StudentProfileAuditLog}
                    buttons={
                        <HeaderButton
                            onClick={() => {
                                setOpenAuditLog(params.id);
                            }}
                        >
                            {t('profile.viewAll')}
                        </HeaderButton>
                    }
                    table={
                        <TableLight
                            header={
                                <div className="profile-results profile-table-header">
                                    <span>{t('profile.username')}</span>
                                    <span>{t('general.action')}</span>
                                    <span>{t('emails.date')}</span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={auditLog.slice(0, 4).map((result) => {
                                return (
                                    <div
                                        key={`${result.action}_${result.timestamp}`}
                                        className="profile-results profile-table-row"
                                    >
                                        <div>{getNoEmpty(result.username)}</div>
                                        <div>{result.action}</div>
                                        <div>{moment.unix(result.timestamp).format()}</div>
                                    </div>
                                );
                            })}
                        />
                    }
                />
                <ProfileCardContent
                    id={Modules.StudentProfileTutorials}
                    title={<div>{t('profile.tutorials')}</div>}
                    buttons={
                        <>
                            <HeaderButton
                                onClick={() => {
                                    window.open(
                                        `${appConstants.server}/administration/tutorials/add/${params.id}`,
                                        '_blank',
                                    );
                                }}
                            >
                                <Add />
                                {t('profile.newTutorial')}
                            </HeaderButton>
                            <div className="profileInfo-tutorials-view-all">
                                <HeaderButton
                                    onClick={() => {
                                        setOpenTutorials(params.id);
                                    }}
                                >
                                    {t('profile.viewAll')}
                                </HeaderButton>
                            </div>
                        </>
                    }
                    table={
                        <TableLight
                            header={
                                <div className="profile-tutorials profile-table-header">
                                    <span>{t('profile.last4Tutorials')}</span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={[...tutorials]
                                .sort((prev, next) => {
                                    if (prev.timestamp > next.timestamp) {
                                        return -1;
                                    }
                                    if (prev.timestamp > next.timestamp) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .slice(0, 4)
                                .map((result, index) => {
                                    return (
                                        <div
                                            key={`${result.id}_${index}`}
                                            className="profile-tutorials profile-table-row"
                                        >
                                            <div>{moment.unix(result.timestamp).format()}</div>
                                            <div className="profileInfo-table-buttons">
                                                <ActionText
                                                    onClick={() =>
                                                        window.open(
                                                            `${appConstants.server}/study/tutorials/print/${params.id}/${result.timestamp}`,
                                                            '_blank',
                                                        )
                                                    }
                                                >
                                                    {t('general.view').toUpperCase()}
                                                </ActionText>
                                                <div
                                                    onClick={() => {
                                                        setOpenDeleteTutorialConfirm({
                                                            studentId: params.id,
                                                            tutorialId: result.id,
                                                        });
                                                    }}
                                                    className="sc_hover_color_text_svg cursor-pointer"
                                                >
                                                    <Remove />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        />
                    }
                />
                <ProfileCardContent
                    title="Tests"
                    id={Modules.StudentProfileTests}
                    buttons={
                        <>
                            <HeaderButton onClick={() => setOpenAssignNewTest(true)}>
                                {t('profile.assignProgressTest')}
                            </HeaderButton>

                            <HeaderButton
                                onClick={() => {
                                    setOpenTests(params.id);
                                }}
                            >
                                {t('profile.viewAll')}
                            </HeaderButton>
                        </>
                    }
                    tableClass="profile-tests-table"
                    table={
                        <TableLight
                            header={
                                <div className="profile-tests profile-table-header">
                                    <span>{t('profile.assignedTests')}</span>
                                    <span>{t('profile.score')}</span>
                                    <span>{t('emails.date')}</span>
                                </div>
                            }
                            tableRowsClassName="profile-table-outside-rows"
                            rows={tests.slice(0, 4).map((result, index) => {
                                return (
                                    <div
                                        key={`${result.id}_${index}`}
                                        className="profile-tests profile-table-row"
                                    >
                                        <div>{result.name}</div>
                                        <div>
                                            {result.score ? `${getNoEmpty(result.score)}%` : ''}
                                        </div>
                                        <div>{moment.unix(result.timestamp).format()}</div>
                                        <div className="profileInfo-table-buttons">
                                            {profileInfoGetReportBlock(
                                                result,
                                                profile,
                                                params.id,
                                                classesData,
                                                params,
                                            )}
                                            <div
                                                onClick={() => {
                                                    setOpenDeleteTestConfirm(result);
                                                }}
                                                className="sc_hover_color_text_svg cursor-pointer"
                                            >
                                                <Remove />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        />
                    }
                />
                {layout.isIelts && (
                    <>
                        <ProfileCardContent
                            title={
                                <div className="student_profile_credits-title">
                                    <div className="student_profile_credits-subtitle">
                                        <span className="profile-title">
                                            FlexChecks {t('profile.and')} {t('profile.lessons')}
                                        </span>
                                        <span className="student_profile_credits-count">
                                            {markings?.ai} FlexChecks {t('profile.and')}{' '}
                                            {markings?.general} FlexLessons
                                        </span>
                                    </div>
                                    <HeaderButton onClick={() => setOpenAssignCredits(true)}>
                                        + {t('profile.assignCredits')}
                                    </HeaderButton>
                                </div>
                            }
                            id={Modules.StudentProfileCredits}
                            tableClass="profile-checks-table"
                            table={
                                <TableLight
                                    header={
                                        <div className="profile-checks profile-table-header">
                                            <span>{t('emails.date')}</span>
                                            <span>{t('profile.type')}</span>
                                            <span>{t('profile.type')}</span>
                                        </div>
                                    }
                                    tableRowsClassName="profile-table-outside-rows"
                                    rows={markings.history.map((result, index) => {
                                        return (
                                            <div
                                                key={`${result.id}_${index}`}
                                                className="profile-checks profile-table-row"
                                            >
                                                <div>{moment.unix(result.timestamp).format()}</div>
                                                <div>{result.ai ? `FlexChecks` : 'FlexLesson'}</div>
                                                <div>{result.type}</div>
                                                {!result.ai &&
                                                    isModuleActive(
                                                        Modules.StudentProfileCreditsDelete,
                                                    ) && (
                                                        <div className="profileInfo-table-buttons">
                                                            <div
                                                                onClick={() => {
                                                                    setOpenDeleteCheckConfirm(
                                                                        result,
                                                                    );
                                                                }}
                                                                className="sc_hover_color_text_svg cursor-pointer"
                                                            >
                                                                <Remove />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    })}
                                />
                            }
                        />
                        <ProfileCardContent
                            title={
                                <div className="student_profile_credits-title">
                                    <div className="student_profile_credits-subtitle">
                                        <span className="profile-title">
                                            {t('profile.packages')}
                                        </span>
                                    </div>
                                    <HeaderButton onClick={() => setOpenAssignPackages(true)}>
                                        + {t('profile.assignNewPackage')}
                                    </HeaderButton>
                                </div>
                            }
                            id={Modules.StudentProfilePackages}
                            tableClass="profile-packages-table"
                            table={
                                <TableLight
                                    header={
                                        <div className="profile-packages profile-table-header">
                                            <span>{t('general.date')}</span>
                                            <span>{t('profile.package')}</span>
                                        </div>
                                    }
                                    tableRowsClassName="profile-table-outside-rows"
                                    rows={upgrades.map((result, index) => {
                                        return (
                                            <div
                                                key={`${result.id}_${index}`}
                                                className="profile-packages profile-table-row"
                                            >
                                                <div>{moment.unix(result.timestamp).format()}</div>
                                                <div>{result.name}</div>
                                                <div className="profileInfo-table-buttons">
                                                    <div
                                                        onClick={() => {
                                                            setOpenDeletePackagesConfirm(result.id);
                                                        }}
                                                        className="sc_hover_color_text_svg cursor-pointer"
                                                    >
                                                        <Remove />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                />
                            }
                        />
                        <ProfileCardContent
                            title="IELTS marking history"
                            id={Modules.StudentProfileMarking}
                            tableClass="profile-history-table"
                            table={
                                <TableLight
                                    header={
                                        <div className="profile-history profile-table-header">
                                            <span>{t('profile.overallScoreLowerCase')}</span>
                                            <span>{t('profile.markingDate')}</span>
                                            <span>{t('profile.markingName')}</span>
                                            <span>{t('profile.paper')}</span>
                                        </div>
                                    }
                                    tableRowsClassName="profile-table-outside-rows"
                                    rows={markings.history
                                        .filter(({ type }) => type !== 'Tutoring')
                                        .filter(({ ai }) => !ai)
                                        .map((result, index) => {
                                            return (
                                                <div
                                                    key={`${result.id}_${index}`}
                                                    className={classNames(
                                                        'profile-history profile-table-row',
                                                        {
                                                            'profile-history-noMark':
                                                                !result.marking,
                                                        },
                                                    )}
                                                >
                                                    <div>{result.marking?.score || 'N/A'}</div>
                                                    <div>
                                                        {result.marking?.timestamp
                                                            ? moment
                                                                  .unix(result.marking?.timestamp)
                                                                  .format()
                                                            : t('profile.pending')}
                                                    </div>
                                                    <div>{result.marking?.teacher || 'N/A'}</div>
                                                    <div>{result.type}</div>
                                                    <div className="profileInfo-table-buttons">
                                                        {isModuleActive(
                                                            Modules.StudentProfileMarkingLink,
                                                        ) && (
                                                            <span
                                                                className="profile-history-button"
                                                                onClick={() => {
                                                                    let linkSpeaking = `${appConstants.frontendIelts}/tracker/results/${result.id}/marking/${student.uid}/${result.attemptId}`;
                                                                    let linkWriting = `${appConstants.frontendIelts}/tracker/writing/practice/${result.id}/marking/${student.uid}/${result.attemptId}`;
                                                                    if (result.marking) {
                                                                        linkSpeaking = `${appConstants.frontendIelts}/tracker/results/${result.id}/${student.uid}?attempt=${result.attemptId}`;
                                                                        linkWriting = `${appConstants.frontendIelts}/mock/writing/practice/${result.id}/feedback/${student.uid}?attempt=${result.attemptId}`;
                                                                    }

                                                                    const link =
                                                                        result.type === 'Speaking'
                                                                            ? linkSpeaking
                                                                            : linkWriting;
                                                                    window.open(link, '_blank');
                                                                }}
                                                            >
                                                                {result.marking
                                                                    ? t('general.view')
                                                                    : t('profile.mark')}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                />
                            }
                        />
                    </>
                )}
            </div>
            <ProfileInfoAssignmentsModal
                open={openAssign}
                assignments={assignments}
                onClose={() => {
                    setOpenAssign(false);
                }}
            />
            <ProfileInfoAssignPackagesModal
                open={openAssignPackages}
                onClose={() => {
                    setOpenAssignPackages(false);
                }}
            />
            <ProfileInfoAssignCreditsModal
                open={openAssignCredits}
                onClose={() => {
                    setOpenAssignCredits(false);
                }}
            />
            <ProfileInfoResultsAndHistoryModal
                open={openResultsAndHistory}
                onClose={() => {
                    setOpenResultsAndHistory(null);
                }}
                profile={profile}
            />
            <ProfileInfoAuditLogModal
                open={openAuditLog}
                onClose={() => {
                    setOpenAuditLog(null);
                }}
            />
            <ProfileInfoEmailsModal
                open={openEmails}
                onClose={() => {
                    setOpenEmails(null);
                }}
            />
            <ProfileInfoTutorialsModal
                open={openTutorials}
                onClose={() => {
                    setOpenTutorials(null);
                }}
            />
            <ProfileInfoTestsModal
                open={openTests}
                onClose={() => {
                    setOpenTests(null);
                }}
                profile={profile}
            />

            <ProfileInfoAssignProgressTestModal
                profile={profile}
                open={openAssignNewTest}
                onClose={() => setOpenAssignNewTest(null)}
            />
            <ProfileInfoCreateEmailModal
                open={openCreateEmail}
                onClose={() => setOpenCreateEmail(null)}
            />
            <ConfirmModal
                open={!!openDeletePackagesConfirm}
                text={t('profile.confirmPackageDeletion')}
                onClick={() => {
                    dispatch(
                        deleteStudentPackage({
                            studentId: params.id as string,
                            id: openDeletePackagesConfirm!,
                            cb: () => {
                                dispatch(
                                    getStudentUpgrades({
                                        studentId: params.id as string,
                                        dataType: 'ielts/upgrades',
                                    }),
                                );
                                setOpenSuccessModal(t('profile.packageDeletedSuccessfully'));
                            },
                        }),
                    );
                }}
                success={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(null);
                    setOpenDeletePackagesConfirm(null);
                }}
            />
            <ConfirmModal
                open={!!openDeleteCheckConfirm}
                text={`${t('profile.confirmThisDeletion')}${
                    openDeleteCheckConfirm?.type === 'Tutoring' ? 'FlexLesson' : 'FlexChecks'
                }?`}
                onClick={() => {
                    dispatch(
                        deleteStudentMarking({
                            studentId: params.id as string,
                            mark: openDeleteCheckConfirm!,
                            cb: () => {
                                dispatch(
                                    getStudentMarking({
                                        studentId: params.id as string,
                                        dataType: 'ielts/markings',
                                    }),
                                );
                                setOpenSuccessModal(
                                    `${
                                        params?.type === 'Tutoring' ? 'FlexLesson' : 'FlexChecks'
                                    } ${t('profile.deletedSuccessfully')}`,
                                );
                            },
                        }),
                    );
                }}
                success={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(null);
                    setOpenDeleteCheckConfirm(null);
                }}
            />
            <ConfirmModal
                open={openDeleteTestConfirm}
                text={t('profile.confirmTestDeletion')}
                onClick={() => {
                    deleteTestDispatch(
                        openDeleteTestConfirm,
                        params,
                        setOpenSuccessModal,
                        dispatch,
                        t,
                    );
                }}
                success={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(null);
                    setOpenDeleteTestConfirm(null);
                }}
            />
            <ConfirmModal
                open={Boolean(openDeleteTutorialConfirm)}
                text={t('profile.confirmTutorialDeletion')}
                onClick={() => {
                    if (!openDeleteTutorialConfirm) return;
                    dispatch(
                        deleteStudentTutorial({
                            studentId: openDeleteTutorialConfirm?.studentId,
                            tutorialId: openDeleteTutorialConfirm.tutorialId,
                            cb: () => {
                                setOpenSuccessModal(t('profile.tutorialDeletedSuccessfully'));
                                dispatch(
                                    getStudentTutorials({
                                        dataType: 'tutorials',
                                        studentId: params.id as string,
                                        limit: 4,
                                    }),
                                );
                            },
                        }),
                    );
                }}
                success={openSuccessModal}
                onClose={() => {
                    setOpenSuccessModal(null);
                    setOpenDeleteTutorialConfirm(null);
                }}
            />
        </>
    );
};

export default ProfileInfo;
