import { Props } from '../types';

const ExclamationIcon = ({ fill = '#fd1300' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.732"
        height="14.732"
        viewBox="0 0 14.732 14.732"
    >
        <g id="exclamation" transform="translate(0.16)">
            <path
                id="Path_30161"
                data-name="Path 30161"
                d="M7.366,0a7.366,7.366,0,1,0,7.366,7.366A7.366,7.366,0,0,0,7.366,0Zm0,13.5A6.138,6.138,0,1,1,13.5,7.366,6.138,6.138,0,0,1,7.366,13.5Z"
                transform="translate(-0.16)"
                fill={fill}
            />
            <path
                id="Path_30162"
                data-name="Path 30162"
                d="M11.577,5A.577.577,0,0,0,11,5.577V10.19a.577.577,0,0,0,1.153,0V5.577A.577.577,0,0,0,11.577,5Z"
                transform="translate(-4.16 -2.117)"
                fill={fill}
            />
            <rect
                id="Rectangle_7356"
                data-name="Rectangle 7356"
                width="1"
                height="1"
                rx="0.5"
                transform="translate(6.839 10)"
                fill={fill}
            />
        </g>
    </svg>
);

export default ExclamationIcon;
