import { Props } from '../types';

const Check = ({ fill = '#54585a' }: Props) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Check">
            <path
                id="Vector"
                d="M7.22492 13.4485L3.56958 9.8952L2 11.421L7.22492 16.5L18 6.02576L16.4304 4.5L7.22492 13.4485Z"
                fill="#007FA3"
            />
        </g>
    </svg>
);

export default Check;
