import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { resetUserPasswordSaga } from 'src/redux/users/sagas/resetUserPasswordSaga';
import {
    BLOCK_STUDENT,
    GET_DUPLICATED_STUDENTS,
    GET_MAIL_AVAILABILITY,
    GET_UNMATCHED_STUDENTS,
    GET_USERNAME_AVAILABILITY,
    MERGE_STUDENTS,
    RESET_USER_PASSWORD,
} from '../actions';
import { getDuplicatedStudentsSaga } from './getDuplicatedStudentsSaga';
import { mergeStudentsSaga } from './mergeStudentsSaga';
import { blockStudentSaga } from './blockStudentSaga';
import { getUnmatchedStudentsSaga } from './getUnmatchedStudentsSaga';
import { getUsernameAvailabilitySaga } from './getUsernameAvailabilitySaga';
import { getMailAvailabilitySaga } from './getMailAvailabilitySaga';

export function* usersWeather(): SagaIterator {
    yield takeLatest(GET_DUPLICATED_STUDENTS, getDuplicatedStudentsSaga);
    yield takeLatest(MERGE_STUDENTS, mergeStudentsSaga);
    yield takeLatest(BLOCK_STUDENT, blockStudentSaga);
    yield takeLatest(GET_UNMATCHED_STUDENTS, getUnmatchedStudentsSaga);
    yield takeLatest(GET_USERNAME_AVAILABILITY, getUsernameAvailabilitySaga);
    yield takeLatest(GET_MAIL_AVAILABILITY, getMailAvailabilitySaga);
    yield takeLatest(RESET_USER_PASSWORD, resetUserPasswordSaga);
}
