import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs } from 'src/redux/layout/actions';

export const useClass = (addBreadcrumbs = true) => {
    const params = useParams();
    const dispatch = useDispatch();
    const classData = useSelector(selectClass);

    useEffect(() => {
        if (params.id && +params.id !== classData?.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        if (!addBreadcrumbs) return;
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `Class ${data.name}`,
                                },
                            ]),
                        );
                    },
                }),
            );
        } else if (params.id && classData?.id) {
            if (!addBreadcrumbs) return;
            dispatch(
                putBreadcrumbs([
                    {
                        name: `Class ${classData.name}`,
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id]);
};
