import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getReports, putReports } from '../actions';

export const COURSE_SUMMARY = {
    name: 'Course Summary BETA',
};

export function* getReportsSaga({ payload }: ReturnType<typeof getReports>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/classes/${payload.classId}/reports`);
        yield put(putReports([...data, COURSE_SUMMARY]));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
