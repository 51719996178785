import { useDispatch, useSelector } from 'react-redux';
import { putApiRequestError } from 'src/redux/error/actions';
import { selectApiRequestError } from 'src/redux/error/selector';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import Modal from '../../Modal';
import './styles.scss';
import SupportEmail from '../../SupportEmail';

const ApiErrorModal = () => {
    const dispatch = useDispatch();
    const error = useSelector(selectApiRequestError);
    const onClose = () => {
        dispatch(putApiRequestError(null));
    };
    const { t } = useTranslation();

    const getAllErrorMessagesInline = (curError: any) => {
        if (curError?.message?.length) {
            return curError.message.map((item: any) => {
                return `${item?.message} `;
            });
        }
        return t('modals.apiError.wrongClass');
    };
    const getErrorBody = () => {
        let message: JSX.Element;
        let button: JSX.Element;
        switch (error?.code) {
            case 403:
                message = <>{t('modals.apiError.accessDenied')}</>;
                button = (
                    <Button type="square" onClick={() => window.location.replace('/')}>
                        {t('modals.apiError.chooseAnotherClass')}
                    </Button>
                );
                break;
            case 404:
                message = (
                    <>
                        {t('modals.apiError.sorryList')} {getAllErrorMessagesInline(error)}
                    </>
                );
                button = (
                    <Button type="square" onClick={() => window.location.replace('/')}>
                        {t('modals.apiError.chooseAnotherClass')}
                    </Button>
                );
                break;
            default:
                message = (
                    <>
                        {t('modals.apiError.errorOccurred')}
                        <br />
                        {t('modals.apiError.okReload')}
                        <br />
                        {t('modals.apiError.pleaseContact')}{' '}
                        <SupportEmail style={{ display: 'inline-block' }} />.{' '}
                    </>
                );
                button = (
                    <Button type="square" onClick={() => window.location.reload()}>
                        {t('general.OK')}
                    </Button>
                );
                break;
        }
        return { message, button };
    };
    return (
        <>
            <Modal open={error} onClose={onClose}>
                <div className="error-modal">
                    <span className="error-modal-title">{getErrorBody().message}</span>
                    <div className="error-modal-button">{getErrorBody().button}</div>
                </div>
            </Modal>
        </>
    );
};

export default ApiErrorModal;
