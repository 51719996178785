import cookie from 'react-cookies';

export const rollbarConfig = {
    accessToken: '',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'production',
        token: `${cookie.load('API_TOKEN') || cookie.load('IELTS_API_TOKEN')}`,
    },
};
