import { createReducer } from '@reduxjs/toolkit';
import { PUT_ASSESSMENT, PUT_ASSESSMENT_STUDENTS, PUT_ASSESSMENT_TYPES } from './actions';
import { PutAssessmentProps, PutAssessmentStudentsProps, PutAssessmentTypesProps } from './types';

export type AssessmentState = {
    assessment: PutAssessmentProps;
    types: PutAssessmentTypesProps[];
    students: PutAssessmentStudentsProps[];
};

const initialState = {
    assessment: {
        id: 0,
        name: '',
        categories: [
            {
                name: '',
                labels: [
                    {
                        id: 0,
                        name: '',
                        pattern: '',
                        maxValue: 0,
                    },
                ],
            },
        ],
    },
    types: [
        {
            id: 0,
            name: '',
        },
    ],
    students: [
        {
            name: '',
            classId: '',
            labels: [],
        },
    ],
};

export const assessmentReducer = createReducer<AssessmentState>(initialState, {
    [PUT_ASSESSMENT]: (state, action) => {
        state.assessment = action.payload;
    },
    [PUT_ASSESSMENT_TYPES]: (state, action) => {
        state.types = action.payload;
    },
    [PUT_ASSESSMENT_STUDENTS]: (state, action) => {
        state.students = action.payload;
    },
});
