import './styles.scss';
import { useState } from 'react';
import CloseButton from 'src/components/CloseButton';
import { SidebarTooltipProps } from './types';

const SidebarTooltip = ({ title, desc, id }: SidebarTooltipProps) => {
    const items = JSON.parse(localStorage.getItem('hidden-tooltip') ?? '[]');
    const [, setTooltip] = useState(1);

    const hideTooltip = () => {
        items.push(id);
        localStorage.setItem('hidden-tooltip', JSON.stringify(Array.from(new Set(items))));
        setTooltip(0);
    };

    return (
        <>
            {items?.indexOf(id) === -1 ? (
                <div className="sidebarTooltip__parent" data-testid="sidebarTooltip">
                    <div className="sidebarTooltip__box">
                        {title && <div className="sidebarTooltip__title">{title}</div>}
                        <div
                            className="sidebarTooltip__desc"
                            dangerouslySetInnerHTML={{ __html: desc }}
                        />
                        <div className="sidebarTooltip__brill" />
                        <CloseButton onClick={hideTooltip} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SidebarTooltip;
