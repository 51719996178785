const TestByAim = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            fill="#676E7A"
            fillOpacity="0.1"
            stroke="#DDE0E5"
        />
        <path
            d="M26.15 10.7474L22.15 14.7474C22.05 14.8474 22 14.9674 22 15.1074V23.2774C22 23.7074 22.51 23.9374 22.83 23.6474L26.83 20.0474C26.94 19.9574 27 19.8174 27 19.6774V11.1074C27 10.6574 26.46 10.4374 26.15 10.7474ZM30.47 14.5974C30 14.3574 29.51 14.1574 29 13.9874V26.0174C27.86 25.6074 26.69 25.3974 25.5 25.3974C23.6 25.3974 21.72 25.9374 20 26.9774V14.8774C18.38 13.9474 16.51 13.3974 14.5 13.3974C12.71 13.3974 11.02 13.8374 9.53 14.5974C9.2 14.7574 9 15.1074 9 15.4774V27.5574C9 28.3174 9.81 28.7874 10.48 28.4274C11.69 27.7974 13.05 27.3974 14.5 27.3974C16.57 27.3974 18.48 28.2174 20 29.3974C21.52 28.2174 23.43 27.3974 25.5 27.3974C26.95 27.3974 28.31 27.7974 29.52 28.4374C30.19 28.7974 31 28.3274 31 27.5674V15.4774C31 15.1074 30.8 14.7574 30.47 14.5974Z"
            fill="#676E7A"
        />
    </svg>
);
export default TestByAim;
