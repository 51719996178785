import { createReducer } from '@reduxjs/toolkit';
import {
    CLEAR_ACTIVITIES,
    PUT_ACTIVITIES,
    PUT_ASSIGMENTS,
    PUT_BOOKS,
    PUT_ELEARNINGS,
    PUT_UNITS,
    PUT_WEEKLY_PLAN,
    UPDATE_CURATED_HEADS,
} from './actions';
import { WeeklyPlanInitialStateProps } from './types';

const initialState = {
    weeklyPlan: {
        week: 0,
        year: 0,
        summary: '',
        days: [
            {
                day: 0,
                lessons: [
                    {
                        columnId: 0,
                        lessonId: 0,
                        published: 0,
                        studentText: '',
                        teacherText: '',
                        syllabus: [],
                        activities: [
                            {
                                id: 0,
                                type: undefined,
                                name: '',
                                preview: undefined,
                            },
                        ],
                        suggestion: 0,
                    },
                ],
                tests: [],
            },
        ],
        lessons: [
            {
                day: 0,
                lessons: [
                    {
                        lessonId: 0,
                        columnId: 0,
                        name: '',
                    },
                ],
                name: '',
            },
        ],
    },
    activities: [],
    books: [],
    units: [],
    elernings: [],
    assigments: [],
    weeklyPlanCuratedHeads: [],
};
export const weeklyPlanReducer = createReducer<WeeklyPlanInitialStateProps>(initialState, {
    [PUT_WEEKLY_PLAN]: (state, action) => {
        state.weeklyPlan = action.payload;
    },
    [PUT_ACTIVITIES]: (state, action) => {
        state.activities = [...state.activities, ...action.payload];
    },
    [CLEAR_ACTIVITIES]: (state) => {
        state.activities = [];
    },
    [PUT_BOOKS]: (state, action) => {
        state.books = action.payload;
    },
    [PUT_UNITS]: (state, action) => {
        state.units = action.payload;
    },
    [PUT_ELEARNINGS]: (state, action) => {
        state.elernings = action.payload;
    },
    [PUT_ASSIGMENTS]: (state, action) => {
        state.assigments = action.payload;
    },
    [UPDATE_CURATED_HEADS]: (state, action) => {
        state.weeklyPlanCuratedHeads = action.payload;
    },
});
