import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { API } from 'src/api';
import { putApiRequestError } from '../../error/actions';
import { getLayout, putLayoutUserFields } from '../actions';

export function* putLayoutUserFieldsSaga({
    payload,
}: ReturnType<typeof putLayoutUserFields>): SagaIterator {
    if (!payload) return;

    try {
        yield call(API.post(), `/layout/fields`, payload);
        yield put(getLayout({ loaderDisabled: true }));
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
