import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteStudentPackage } from '../actions';

export function* deleteStudentPackageSaga({
    payload,
}: ReturnType<typeof deleteStudentPackage>): SagaIterator {
    try {
        yield put(startLoading());
        const link = `/staff/students/${payload.studentId}/ielts/upgrades/${payload.id}`;
        yield call(API.delete(), link);
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
