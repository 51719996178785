import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import { getAssigments, getWeeklyPlan } from 'src/redux/weeklyPlan/actions';
import { useTranslation } from 'react-i18next';
import { selectStudent } from 'src/redux/students/selectors';
import { getStudent } from 'src/redux/students/actions';
import HomeworkData from './HomeworkData';

import './styles.scss';

const Homework = () => {
    const dispatch = useDispatch();
    const classData = useSelector(selectClass);
    const params = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const profile = useSelector(selectStudent);
    const isStudent = location.pathname.includes('students');
    const isAll = location.pathname.includes('all-students');

    useEffect(() => {
        if (isAll) {
            dispatch(putSidebar('root'));
        } else {
            dispatch(putSidebar('classSelected'));
        }

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        if (params.uid && isStudent) {
            dispatch(getStudent({ uid: +params.uid }));
        }
    }, [params, location]);

    useEffect(() => {
        if (!isStudent) {
            return;
        }
        dispatch(
            getAssigments({
                classId: params.id,
            }),
        );
        if (!isAll && params.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `${t('general.class')} ${data.name}`,
                                },
                                {
                                    name: t('general.students'),
                                    link: `students/${classData.id}`,
                                },
                                {
                                    name: `${
                                        profile.name?.slice(0, 100) ?? t('general.notAvailable')
                                    }`,
                                    link: `students/${classData.id}/${profile.uid}/profile`,
                                },
                                {
                                    name: t('homework.assignments'),
                                },
                            ]),
                        );
                    },
                }),
            );
        }

        if (isAll) {
            dispatch(
                putBreadcrumbs([
                    {
                        name: t('general.students'),
                        link: `all-students`,
                    },
                    {
                        name: `${profile.name?.slice(0, 100) ?? t('general.notAvailable')}`,
                        link: `all-students/${profile.uid}/profile`,
                    },
                    {
                        name: t('homework.assignments'),
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id, location, profile]);

    useEffect(() => {
        if (isStudent) {
            return;
        }
        if (params.id && +params.id !== classData?.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `${t('general.class')} ${data.name}`,
                                },
                            ]),
                        );
                    },
                }),
            );
        } else if (params.id && classData?.id) {
            dispatch(
                getWeeklyPlan({
                    year: params.year as unknown as number,
                    week: params.week as unknown as number,
                    id: params.id,
                }),
            );
            dispatch(
                getAssigments({
                    classId: params.id,
                }),
            );
            dispatch(
                putBreadcrumbs([
                    {
                        name: `${t('general.class')} ${classData.name}`,
                        link: `/weekly-plan/${params.id}`,
                    },

                    {
                        name: t('homework.assignments'),
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id, location]);

    useEffect(() => {
        return () => {
            const nextPath = window.location.pathname;

            if (!nextPath.includes('/assignment')) {
                sessionStorage.removeItem('updateLessonCurrentlyAddedActivities');
            }
        };
    }, [location]);

    return <HomeworkData />;
};

export default Homework;
