import classNames from 'classnames';
import { ButtonProps } from './types';
import './styles.scss';

const Button = ({
    children,
    minWidth,
    maxWidth,
    padding,
    onClick,
    type,
    className,
    disabled = false,
    submit = false,
}: ButtonProps) => {
    const runIfNotDisabled = () => (disabled || !onClick ? null : onClick());

    return (
        <button
            type={submit ? 'submit' : 'button'}
            className={classNames('button', className, {
                'button-square pr_hover_color_text_svg_bg': type === 'square',
                'button-secondary sc_hover_color_text_svg_bg': type === 'secondary',
                'button-secondary-thin sc_hover_color_text_svg_bg': type === 'secondary-thin',
                'button-ternary sc_hover_color_text_svg_bg': type === 'ternary',
                'button-quaternary qr_hover_color_text_svg_bg': type === 'quaternary',
                'button-quaternary-new sc_hover_color_text_svg_bg': type === 'quaternary-new',
                'button-grey sc_hover_color_text_svg_bg': type === 'grey',
                'button-outline sc_hover_color_text_svg_bg': type === 'outline',
                'button-outline grey': type === 'outline-grey',
                'disabled cursor-not-allowed': disabled,
            })}
            style={{
                minWidth: minWidth || 'auto',
                maxWidth: maxWidth || 'auto',
                padding,
            }}
            onClick={runIfNotDisabled}
            onKeyPress={(event) => {
                if (event && event.key === 'Enter') {
                    runIfNotDisabled();
                }
            }}
        >
            {children}
        </button>
    );
};

export default Button;
