import './styles.scss';
import { useTranslation } from 'react-i18next';
import { ClassPreviewProps } from './types';

const ClassPreview = ({
    textRight = 'classPreview.viewAsStudent',
    callback,
}: ClassPreviewProps) => {
    const { t } = useTranslation();
    return (
        <div className="switcher">
            <div
                data-testid="classPreview"
                className="switcher__button pr_hover_color_text_svg_bg"
                onClick={callback}
            >
                {t(textRight)}
            </div>
        </div>
    );
};

export default ClassPreview;
