const DuplicateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.667" height="20" viewBox="0 0 16.667 20">
            <path
                id="duplicate"
                d="M17.963,2.727,16.059.761A2.517,2.517,0,0,0,14.262,0H10.333A4.174,4.174,0,0,0,6.25,3.333H6.167A4.172,4.172,0,0,0,2,7.5v8.333A4.172,4.172,0,0,0,6.167,20h5a4.172,4.172,0,0,0,4.167-4.167V15.75a4.174,4.174,0,0,0,3.333-4.083v-7.2A2.49,2.49,0,0,0,17.963,2.727Zm-6.8,15.607h-5a2.5,2.5,0,0,1-2.5-2.5V7.5A2.5,2.5,0,0,1,6.167,5v6.667a4.172,4.172,0,0,0,4.167,4.167h3.333A2.5,2.5,0,0,1,11.167,18.333ZM14.5,14.167H10.333a2.5,2.5,0,0,1-2.5-2.5v-7.5a2.5,2.5,0,0,1,2.5-2.5h3.333V3.333A1.667,1.667,0,0,0,15.333,5H17v6.667A2.5,2.5,0,0,1,14.5,14.167Z"
                transform="translate(-2)"
                fill="#54585a"
            />
        </svg>
    );
};
export default DuplicateIcon;
