import { useEffect } from 'react';

export const useMobileControls = (open: boolean) => {
    const mobileControls = document.querySelector(`#mobileControls`);

    useEffect(() => {
        if (open && mobileControls) {
            (mobileControls as HTMLElement).style.overflow = 'unset';
        }

        return () => {
            if (mobileControls) {
                (mobileControls as HTMLElement).style.overflow = 'auto';
            }
        };
    }, [open, mobileControls]);
};
