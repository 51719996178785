import { Props } from '../types';

const Remove = ({ fill = '#54585a' }: Props) => (
    <svg height="15" viewBox="0 0 12.5 15" width="12.5" xmlns="http://www.w3.org/2000/svg">
        <g fill={fill}>
            <path d="m11.875 2.5h-1.937a3.131 3.131 0 0 0 -3.063-2.5h-1.25a3.131 3.131 0 0 0 -3.062 2.5h-1.938a.625.625 0 0 0 0 1.25h.625v8.125a3.129 3.129 0 0 0 3.125 3.125h3.75a3.129 3.129 0 0 0 3.125-3.125v-8.125h.625a.625.625 0 1 0 0-1.25zm-6.25-1.25h1.25a1.879 1.879 0 0 1 1.768 1.25h-4.786a1.879 1.879 0 0 1 1.768-1.25zm4.375 10.625a1.875 1.875 0 0 1 -1.875 1.875h-3.75a1.875 1.875 0 0 1 -1.875-1.875v-8.125h7.5z" />
            <path
                d="m149.958 218.333a.625.625 0 0 0 .625-.625v-3.75a.625.625 0 0 0 -1.25 0v3.75a.625.625 0 0 0 .625.625z"
                transform="translate(-144.958 -207.083)"
            />
            <path
                d="m235.292 218.333a.625.625 0 0 0 .625-.625v-3.75a.625.625 0 1 0 -1.25 0v3.75a.625.625 0 0 0 .625.625z"
                transform="translate(-227.792 -207.083)"
            />
        </g>
    </svg>
);

export default Remove;
