import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { putStaffReports } from '../actions';

export function* getStaffReportsSaga({ payload }: ReturnType<any>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/reports`);

        yield put(putStaffReports(data));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
