import { createAction } from '@reduxjs/toolkit';
import {
    BlockStudentProps,
    GetDuplicatedStudentsProps,
    GetMailAvailabilityAP,
    GetUnmatchedStudentsProps,
    GetUsernameAvailabilityAP,
    MergeStudentsProps,
    PutDuplicatedStudentsProps,
    PutUnmatchedStudentsProps,
    ResetUserPasswordAP,
} from './types';

export const GET_DUPLICATED_STUDENTS = 'GET_DUPLICATED_STUDENTS';
export const PUT_DUPLICATED_STUDENTS = 'PUT_DUPLICATED_STUDENTS';
export const MERGE_STUDENTS = 'MERGE_STUDENTS';
export const BLOCK_STUDENT = 'BLOCK_STUDENT';
export const GET_UNMATCHED_STUDENTS = 'GET_UNMATCHED_STUDENTS';
export const PUT_UNMATCHED_STUDENTS = 'PUT_UNMATCHED_STUDENTS';
export const GET_USERNAME_AVAILABILITY = 'GET_USERNAME_AVAILABILITY';
export const GET_MAIL_AVAILABILITY = 'GET_MAIL_AVAILABILITY';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';

export const getDuplicatedStudents =
    createAction<GetDuplicatedStudentsProps>(GET_DUPLICATED_STUDENTS);
export const putDuplicatedStudents =
    createAction<PutDuplicatedStudentsProps>(PUT_DUPLICATED_STUDENTS);
export const mergeStudents = createAction<MergeStudentsProps>(MERGE_STUDENTS);
export const blockStudent = createAction<BlockStudentProps>(BLOCK_STUDENT);
export const getUnmatchedStudents = createAction<GetUnmatchedStudentsProps>(GET_UNMATCHED_STUDENTS);
export const putUnmatchedStudents = createAction<PutUnmatchedStudentsProps>(PUT_UNMATCHED_STUDENTS);
export const getUsernameAvailability =
    createAction<GetUsernameAvailabilityAP>(GET_USERNAME_AVAILABILITY);
export const getMailAvailability = createAction<GetMailAvailabilityAP>(GET_MAIL_AVAILABILITY);
export const resetUserPassword = createAction<ResetUserPasswordAP>(RESET_USER_PASSWORD);
