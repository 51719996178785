export const useFilterHelper = ({ filter, setFilter }: { filter: any; setFilter: any }) => {
    const checkFilter = (cb: any) => {
        if (cb) {
            cb();
        }
    };

    const updateFilter = (newValue: any) => {
        const tempItems = structuredClone(Object.assign(filter, newValue));
        setFilter(null);
        setFilter(tempItems);
    };

    return {
        filter,
        updateFilter,
        checkFilter,
    };
};
