import classNames from 'classnames';
import _ from 'lodash';
import React, { useLayoutEffect, useRef, useState } from 'react';
import EyeSlashSvg from 'src/components/Icons/EyeSlashSvg';
import EyeSvg from 'src/components/Icons/EyeSvg';
import { TIME } from 'src/constants';
import './styles.scss';
import { InputPasswordProps } from './types';

const InputPassword = ({
    fullWidth,
    onChange,
    error,
    template = 1,
    inputProps,
    errorAsBlock = false,
    value = '',
    disableDebounce = false,
}: InputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleOnChange = _.debounce((e) => {
        onChange(e.target.value);
    }, TIME.inputDebounce);
    const inputRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(
                inputRef.current.selectionEnd,
                inputRef.current.selectionEnd,
            );
            inputRef.current.focus();
        }
    }, [showPassword]);

    return (
        <div className="input-password">
            <div className="input-password-input-area">
                <input
                    value={value}
                    ref={inputRef}
                    className={classNames('input-password__field', `template-${template}`, {
                        invalid: error,
                        fullWidth,
                    })}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                        if (disableDebounce) {
                            onChange(e.target.value);
                        } else {
                            handleOnChange(e);
                        }
                    }}
                    {...inputProps}
                />
                {showPassword ? (
                    <span
                        className="input-password-eye"
                        onClick={() => {
                            setShowPassword(false);
                        }}
                    >
                        <EyeSvg />
                    </span>
                ) : (
                    <span
                        className="input-password-eye"
                        onClick={() => {
                            setShowPassword(true);
                        }}
                    >
                        <EyeSlashSvg />
                    </span>
                )}
            </div>
            <div className={classNames('input-password-error', { errorAsBlock })} title={error}>
                {error}
            </div>
        </div>
    );
};

export default InputPassword;
