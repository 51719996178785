import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import React, { useEffect, useState } from 'react';
import InputTextOld from 'src/components/InputTextOld';
import InputTextareaOld from 'src/components/InputTextareaOld';
import Button from 'src/components/Button';
import { getStudentEmails, sendStudentMail } from 'src/redux/students/actions';
import ActionText from 'src/components/ActionText';
import Modal from 'src/components/Modal';
import useInputProcessor from 'src/hooks/useInputProcessor';
import SuccessModal from 'src/components/Modals/SuccessModal';

const ProfileInfoCreateEmailModal = ({ open, onClose }: { open: any; onClose: any }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const [openSuccessModal, setOpenSuccessModal] = useState(null as any);
    const [inputs, setInputs] = useState({ values: [], errors: [], loading: [] });
    const inputProcessor = useInputProcessor({ inputs, setInputs });

    useEffect(() => {
        inputProcessor.updateInput({ mailSubject: '', mailMessage: '' });
    }, [open]);

    return (
        <Modal
            disableOutsideClick={openSuccessModal}
            modalBackdropClass="createEmail-backdrop"
            open={open}
            onClose={() => onClose(null)}
        >
            <div className="createEmail">
                <div className="createEmail__title">{t('profile.sendEmail')}</div>
                <div className="createEmail__subject">
                    <span>{t('emails.subject')}</span>
                    <InputTextOld
                        fullWidth
                        inputProcessor={inputProcessor}
                        validators={{ empty: 1 }}
                        inputName="mailSubject"
                        inputLabel={t('emails.subject')}
                    />
                </div>
                <div className="createEmail__message">
                    <span>{t('profile.message')}</span>
                    <InputTextareaOld
                        fullWidth
                        inputProcessor={inputProcessor}
                        validators={{ empty: 1 }}
                        inputName="mailMessage"
                        inputLabel={t('profile.message')}
                    />
                </div>
                <div className="createEmail__buttons">
                    <Button
                        type="secondary"
                        disabled={inputProcessor.hasError(['mailSubject', 'mailMessage'])}
                        onClick={() => {
                            dispatch(
                                sendStudentMail({
                                    studentId: params.id as string,
                                    data: {
                                        subject: inputProcessor.getValue('mailSubject'),
                                        message: inputProcessor.getValue('mailMessage'),
                                    },
                                    cb: () => {
                                        dispatch(
                                            getStudentEmails({
                                                studentId: params.id as string,
                                                limit: 4,
                                                dataType: 'emails',
                                            }),
                                        );
                                        setOpenSuccessModal(t('profile.emailSent'));
                                    },
                                }),
                            );
                        }}
                    >
                        {t('profile.sendToStudent')}
                    </Button>
                    <ActionText
                        disabled={inputProcessor.hasError(['mailSubject', 'mailMessage'])}
                        onClick={() => {
                            dispatch(
                                sendStudentMail({
                                    studentId: params.id as string,
                                    data: {
                                        subject: inputProcessor.getValue('mailSubject'),
                                        message: inputProcessor.getValue('mailMessage'),
                                    },
                                    query: {
                                        test: '',
                                    },
                                    cb: () => {
                                        setOpenSuccessModal('Test email successfully sent.');
                                    },
                                }),
                            );
                        }}
                    >
                        {t('emails.sendATest')}
                    </ActionText>
                </div>
                <SuccessModal
                    lottie
                    open={openSuccessModal}
                    text={openSuccessModal}
                    onClose={() => {
                        setOpenSuccessModal(false);
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoCreateEmailModal;
