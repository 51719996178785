import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CustomLink from 'src/components/CustomLink';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';
import ConfirmModal from 'src/components/Modals/ConfirmModal';

import Table from 'src/components/Table';
import { appConstants } from 'src/constants';
import { sendEmail } from 'src/redux/emails/actions';
import { SendEmailDataType } from 'src/redux/emails/types';
import { useGetEmails } from 'src/redux/emails/useGetEmails';
import { selectLayout } from 'src/redux/layout/selectors';
import './styles.scss';

const EmailsMessageCentre = () => {
    const { t } = useTranslation();
    const layout = useSelector(selectLayout);
    const dispatch = useDispatch();
    const [sendTestEmailOpenModal, setSendTestEmailOpenModal] = useState<SendEmailDataType | null>(
        null,
    );
    const emails = useGetEmails(t);

    return (
        <>
            <Table
                header={
                    <>
                        <span className="assessments-name">{t('emails.emails')}</span>
                    </>
                }
                rows={emails.map((email) => (
                    <>
                        <span className="messageCenter-active name ">
                            <CustomLink
                                to={
                                    email.id
                                        ? `${appConstants.server}/messagecentre/add?id=${email.id}`
                                        : `${appConstants.server}${email.url}`
                                }
                                title={email.name}
                                className="messageCenter-active name "
                            >
                                {email.name}
                            </CustomLink>
                        </span>
                        <span className="messageCenter-active send ">
                            <span
                                title={t('emails.sendATest')}
                                onClick={() => {
                                    setSendTestEmailOpenModal(
                                        email.id ? { id: email.id } : { type: email.key },
                                    );
                                }}
                                className="tr_hover_color_text"
                            >
                                {t('emails.sendATest')}
                            </span>
                        </span>
                        <span className="messageCenter-active edit">
                            <CustomLinkOverloaded
                                parentProps={{
                                    className: 'messageCenter-active',
                                }}
                                linkProps={{
                                    to: email.id
                                        ? `${appConstants.server}/messagecentre/add?id=${email.id}`
                                        : `${appConstants.server}${email.url}`,
                                    title: t('general.edit'),
                                    className: 'messageCenter-active',
                                }}
                            >
                                {t('general.edit')}
                            </CustomLinkOverloaded>
                        </span>
                    </>
                ))}
                headerMobile={
                    <>
                        <span>{t('emails.emails').toUpperCase()}</span>
                    </>
                }
                rowsMobile={emails.map((email) => {
                    return {
                        head: (
                            <>
                                <div className="messageCenter-name-mobile messageCenter-active">
                                    {email.name}
                                </div>
                                <div
                                    className="messageCenter-send-a-test-mob messageCenter-active"
                                    onClick={() =>
                                        setSendTestEmailOpenModal(
                                            email.id ? { id: email.id } : { type: email.key },
                                        )
                                    }
                                >
                                    {t('emails.sendATest').toUpperCase()}
                                </div>
                            </>
                        ),
                    };
                })}
            />
            <ConfirmModal
                open={!!sendTestEmailOpenModal}
                text={`${t('emails.sendTestEmailTo')} ${layout.layout.user.mail}?`}
                onClick={() => {
                    dispatch(sendEmail(sendTestEmailOpenModal));
                    setSendTestEmailOpenModal(null);
                }}
                onClose={() => setSendTestEmailOpenModal(null)}
            />
        </>
    );
};

export default EmailsMessageCentre;
