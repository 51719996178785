import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, Location } from 'react-router';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when: boolean): [boolean, () => void, () => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState<{ location: Location }>();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
    }, []);

    /*
    handle blocking when user click on another route prompt will be shown
    */
    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            /*
             in if condition we are checking next location and current location are equals or not
            */
            if (
                !confirmedNavigation &&
                `${nextLocation.location.pathname}${nextLocation.location.search}` !==
                    `${location.pathname}${location.search}`
            ) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }

            return true;
        },
        [confirmedNavigation],
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
    }, [lastLocation]);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(`${lastLocation.location.pathname}${lastLocation.location.search}`, {
                state: lastLocation.location.state,
            });
        }
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
}
