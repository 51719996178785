import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getWeeklyPlan, moveDay } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* addClassHolidaySaga({ payload }: ReturnType<typeof moveDay>): SagaIterator {
    try {
        const { classId, dataToSave, year, week } = payload;
        yield put(startLoading());
        yield call(API.post(), `/staff/classes/${classId}/holiday`, dataToSave);
        yield put(
            getWeeklyPlan({
                id: payload.classId,
                year,
                week,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
