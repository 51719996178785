const Disc = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="disk" transform="translate(0 0)">
            <path
                id="Path_2537"
                data-name="Path 2537"
                d="M11.257,10a3.257,3.257,0,1,0,3.257,3.257A3.257,3.257,0,0,0,11.257,10Zm0,4.885a1.628,1.628,0,1,1,1.628-1.628A1.628,1.628,0,0,1,11.257,14.885Z"
                transform="translate(-1.257 -1.571)"
                fill="#54585a"
            />
            <path
                id="Path_2538"
                data-name="Path 2538"
                d="M18.78,3.435,16.565,1.22A4.138,4.138,0,0,0,13.619,0H4.167A4.172,4.172,0,0,0,0,4.167V15.833A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833V6.381a4.138,4.138,0,0,0-1.22-2.946Zm-4.613-1.7V2.5a2.5,2.5,0,0,1-2.5,2.5H8.333a2.5,2.5,0,0,1-2.5-2.5V1.667h7.786a2.461,2.461,0,0,1,.547.067Zm4.167,14.1a2.5,2.5,0,0,1-2.5,2.5H4.167a2.5,2.5,0,0,1-2.5-2.5V4.167a2.5,2.5,0,0,1,2.5-2.5V2.5A4.172,4.172,0,0,0,8.333,6.667h3.333A4.159,4.159,0,0,0,15.8,2.813l1.8,1.8a2.517,2.517,0,0,1,.732,1.767Z"
                transform="translate(0 0)"
                fill="#54585a"
            />
        </g>
    </svg>
);

export default Disc;
