import { Props } from '../types';

const All = ({ fill = '#676E7A' }: Props) => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.20874 4.54C9.20874 3.99 8.75874 3.54 8.20874 3.54H1.20874C0.65874 3.54 0.20874 3.99 0.20874 4.54C0.20874 5.09 0.65874 5.54 1.20874 5.54H8.20874C8.75874 5.54 9.20874 5.09 9.20874 4.54ZM9.20874 12.54C9.20874 11.99 8.75874 11.54 8.20874 11.54H1.20874C0.65874 11.54 0.20874 11.99 0.20874 12.54C0.20874 13.09 0.65874 13.54 1.20874 13.54H8.20874C8.75874 13.54 9.20874 13.09 9.20874 12.54ZM15.2587 6.83C14.8687 7.22 14.2387 7.22 13.8487 6.83L11.7287 4.71C11.3387 4.32 11.3387 3.69 11.7287 3.3C12.1187 2.91 12.7487 2.91 13.1387 3.3L14.5487 4.71L18.0887 1.17C18.4787 0.780002 19.1087 0.780002 19.4987 1.17C19.8887 1.56 19.8887 2.19 19.4987 2.58L15.2587 6.83ZM15.2587 14.83C14.8687 15.22 14.2387 15.22 13.8487 14.83L11.7287 12.71C11.3387 12.32 11.3387 11.69 11.7287 11.3C12.1187 10.91 12.7487 10.91 13.1387 11.3L14.5487 12.71L18.0887 9.17C18.4787 8.78 19.1087 8.78 19.4987 9.17C19.8887 9.56 19.8887 10.19 19.4987 10.58L15.2587 14.83Z"
            fill={fill}
        />
    </svg>
);

export default All;
