import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { urlQueryFromObject } from 'src/helpers/queryFromObject';
import { getClassesByParams, putClasses } from '../actions';

export function* getClassesByParamsSaga({
    payload,
}: ReturnType<typeof getClassesByParams>): SagaIterator {
    try {
        yield put(startLoading());
        const query = urlQueryFromObject(payload.data);
        const data = yield call(API.get(), `/staff/classes?${query}`);
        yield put(putClasses(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
