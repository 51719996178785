import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import '../styles.scss';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { selectStudentResultsHistory } from 'src/redux/students/selectors';
import ActionText from 'src/components/ActionText';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { linkReview, reviewDisabled } from '../../linkReview';
import { getResultScore } from '../../getResultsScore';
import { useAddLeftBorderToTableHeader } from '../../helpers';

const ProfileInfoResultsAndHistoryModal = ({
    open,
    onClose,
    profile,
}: {
    open: any;
    onClose: any;
    profile: any;
}) => {
    const { t } = useTranslation();
    const studentResults = useSelector(selectStudentResultsHistory);
    const { addScroll } = useAddLeftBorderToTableHeader();

    useEffect(() => {
        addScroll();
    }, [studentResults]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal open={open} onClose={() => onClose()} noPadding>
            <div className="profileInfoTableModal resultsAndHistory">
                <div className="profileInfoTableModal-title">{t('profile.resultsHistory')}</div>
                <Table
                    tableRowsClassName="profileInfoTableModal-table"
                    header={
                        <>
                            <span>{t('profile.type')}</span>
                            <span>{t('profile.score')}</span>
                            <span>{t('emails.date')}</span>
                        </>
                    }
                    rows={studentResults.map((oneResult: any) => {
                        return (
                            <>
                                <span title={getNoEmpty(oneResult.type)}>
                                    {reviewDisabled(oneResult) ? (
                                        <span>{oneResult.type}</span>
                                    ) : (
                                        <ActionText
                                            onClick={() =>
                                                window.open(
                                                    linkReview(oneResult, profile),
                                                    '_blank',
                                                )
                                            }
                                        >
                                            {oneResult.type}
                                        </ActionText>
                                    )}
                                </span>
                                <span title={getResultScore(oneResult)}>
                                    {getResultScore(oneResult)}{' '}
                                </span>
                                <span title={moment.unix(oneResult.datetime).format()}>
                                    {moment.unix(oneResult.datetime).format()}
                                </span>
                            </>
                        );
                    })}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoResultsAndHistoryModal;
