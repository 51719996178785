import React from 'react';
import './styles.scss';

const Heading = ({
    tag = 'h1',
    children,
    type,
}: {
    tag?: 'h1' | 'h2';
    children: JSX.Element | string;
    type: 'main' | 'modal-title-1';
}) => {
    return React.createElement(tag, { className: `heading ${type}` }, children);
};

export default Heading;
