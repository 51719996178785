import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteStudentMarking } from '../actions';

export function* deleteStudentMarkingSaga({
    payload,
}: ReturnType<typeof deleteStudentMarking>): SagaIterator {
    try {
        yield put(startLoading());
        const isTutoring = payload.mark.type === 'Tutoring';
        const type = isTutoring ? 'booking' : payload.mark.type.toLocaleLowerCase();
        const id = isTutoring ? payload.mark.id : payload.mark.attemptId;
        const link = `/staff/students/${payload.studentId}/ielts/markings/${type}/${id}`;
        yield call(API.delete(), link);
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
