import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Add from 'src/components/Icons/Add';
import Edit from 'src/components/Icons/Edit';
import QuestionIcon from 'src/components/Icons/QuestionIcon';
import Remove from 'src/components/Icons/Remove';
import SearchAlt from 'src/components/Icons/SearchAlt';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Tooltip from 'src/components/Tooltip';
import { TooltipId } from 'src/components/Tooltip/types';
import WhiteBox from 'src/components/WhiteBox';
import { appConstants } from 'src/constants';
import { notInString } from 'src/helpers/notInString';
import { weeklyPlanGetPathToEditOnlineTest } from 'src/pages/WeeklyPlan/helpers';
import { selectSearchValue } from 'src/redux/search/selector';
import { updateWeeklyPlanCuratedHeads } from 'src/redux/weeklyPlan/actions';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { useTranslation } from 'react-i18next';
import { selectLayout } from 'src/redux/layout/selectors';
import CustomDroppable from './CustomDroppable';
import { onDragEnd, prepareColumns } from './helpers';
import { ResourcesRender } from './resourcesRender';
import { WeeklyPlanTableProps } from './types';
import WeeklyPlanHelp from './WeeklyPlanHelp';
import WeeklyPlanMenu from './WeeklyPlanMenu';
import Zoom from './Zoom';

import './styles.scss';

const WeeklyPlanTable = ({
    activeMode,
    week,
    year,
    lessons,
    days,
    setDays,
    getUpdatedDays,
    restWeekly,
    setIsDirty,
    onSave,
    pushTestToDelete,
    columns,
    setColumns,
    deleteDayContent,
    sectionsOpenState,
    setSectionsOpenState,
    setSavedHistoryData,
}: WeeklyPlanTableProps) => {
    const { t } = useTranslation();
    const [editedColumns, setEditedColumns] = useState<any[][]>([]);
    const navigate = useNavigate();
    const params = useParams();
    const searchValue = useSelector(selectSearchValue);
    const [activeResourcesRows, setActiveResourcesRows] = useState(new Set() as any);
    const dispatch = useDispatch();
    const { layout } = useSelector(selectLayout);

    const {
        columns: preparedColumns,
        lessonSubNames,
        curatedLessonSubNames,
    } = useMemo(() => {
        return prepareColumns(days);
    }, [days]);

    useEffect(() => {
        dispatch(updateWeeklyPlanCuratedHeads(curatedLessonSubNames));
    }, [curatedLessonSubNames]);

    const ref = useRef([new Set([0])]);
    const [openHelp, setOpenHelp] = useState(false);
    const [automatedTestToDelete, setAutomatedTestToDelete] = useState(0);
    const [progressTestToDelete, setProgressTestToDelete] = useState(0);

    useEffect(() => {
        days.forEach((day: any) => {
            day?.lessons?.forEach((lessonInner: any, lessonInnerIndex: any) => {
                if (lessonInner.activities?.length) {
                    setActiveResourcesRows(activeResourcesRows.add(lessonInnerIndex));
                }
            });
        });
    }, [days]);

    useEffect(() => {
        setEditedColumns(columns);
    }, [columns]);

    const lessonNames = useMemo(() => {
        const names = {} as any;
        if (!lessons[0]) {
            return names;
        }
        lessons[0].lessons.forEach((lesson: any) => {
            names[lesson.name] = {
                columnIds: [],
                lessonIds: [],
                lessonDays: [],
            };
        });

        lessons.forEach((data: any) => {
            data.lessons.forEach((lesson: any) => {
                names[lesson.name].columnIds.push(lesson.columnId);
                names[lesson.name].lessonIds.push(lesson.lessonId);
                names[lesson.name].lessonDays.push({
                    day: data.day,
                    columnId: lesson.columnId,
                    lessonId: lesson.lessonId,
                });
            });
        });

        return names;
    }, [lessons]);

    const isCuratedColumnPart = curatedLessonSubNames.length;

    return (
        <WhiteBox styles={{ overflow: 'unset', minHeight: 'unset' }}>
            <div
                className={classNames('weeklyPlan-days', {
                    'weeklyPlan-days-open-help': openHelp || isCuratedColumnPart,
                    disabled: isCuratedColumnPart,
                })}
            >
                {!openHelp && (
                    <div
                        onClick={() => setOpenHelp(true)}
                        className="weeklyPlan-days__question sc_hover_color_text_svg cursor-pointer"
                    >
                        <QuestionIcon />
                    </div>
                )}

                {Array(columns.length)
                    .fill(0)
                    .map((_item, idx: number) => {
                        const lesson: any = lessons.find((ls) => ls.day - 1 === idx);

                        if (!lesson) return <div style={{ width: '20%' }} />;

                        return (
                            <WeeklyPlanMenu
                                key={idx}
                                week={week}
                                year={year}
                                lesson={lesson}
                                columns={columns}
                                setColumns={(data: any) => {
                                    setColumns(data);
                                    setIsDirty(true);
                                }}
                                days={days}
                                setDays={setDays}
                                columnIndex={lesson.day - 1}
                                getUpdatedDays={getUpdatedDays}
                                lessons={lessons}
                                restWeekly={restWeekly}
                                onSave={onSave}
                                deleteDayContent={deleteDayContent}
                                setSavedHistoryData={setSavedHistoryData}
                            />
                        );
                    })}
            </div>
            <WeeklyPlanHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

            {layout.school.zoom ? (
                <Zoom
                    lessons={lessons}
                    sectionsOpenState={sectionsOpenState}
                    setSectionsOpenState={setSectionsOpenState}
                    week={week}
                    year={year}
                />
            ) : (
                <></>
            )}

            <div
                className={classNames('weeklyPlan-lessons', {
                    '-studentMode': activeMode === 'studentText',
                    'd-none': openHelp,
                })}
            >
                <DragDropContext
                    onDragEnd={(result) => {
                        onDragEnd(result, columns, lessonNames, setColumns);
                        setIsDirty(true);
                    }}
                >
                    {Object.entries(lessonNames).map(
                        (
                            [name, { columnIds, lessonIds, lessonDays }]: any,
                            lessonIndex: number,
                        ) => {
                            const isHomework = name.includes('Homework');

                            return (
                                <div
                                    key={`${name}`}
                                    className={classNames('weeklyPlan-lesson', {
                                        'weeklyPlan-in-class': !isHomework,
                                        'weeklyPlan-homework': isHomework,
                                    })}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '36px',
                                            background: isHomework ? '#5F249F' : '#AC1359',
                                        }}
                                        className="weeklyPlan-sectionName"
                                    >
                                        <div
                                            style={{
                                                transform: 'rotate(180deg)',
                                                whiteSpace: 'nowrap',
                                                color: '#FFF',
                                                fontSize: '1rem',
                                                lineHeight: '150%',
                                            }}
                                        >
                                            {name}
                                        </div>
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        {Object.entries(lessonSubNames)
                                            .filter(([lessonSubName]: any) => {
                                                if (isHomework) return true;

                                                return !(
                                                    !isHomework && !lessonSubName.split('##')[0]
                                                );
                                            })
                                            .filter(([lessonSubName]: any) => {
                                                if (activeMode === 'studentText') {
                                                    return ![
                                                        'Suggested extensions and teacher notes',
                                                        'Teacher Note',
                                                    ].some((element) =>
                                                        lessonSubName.includes(element),
                                                    );
                                                }

                                                return true;
                                            })
                                            .filter(([_, ids]: any) => {
                                                return !(
                                                    !ids.columnIds.every((id: any) =>
                                                        columnIds.includes(id),
                                                    ) ||
                                                    !ids.lessonIds.every((id: any) =>
                                                        lessonIds.includes(id),
                                                    )
                                                );
                                            })
                                            .map(([lessonSubName]: any, index) => {
                                                const subName =
                                                    lessonSubName.split('##')[0] || 'Homework';
                                                const openStateObjectKey: any = lessonSubName;

                                                return (
                                                    <>
                                                        <div
                                                            key={lessonSubName}
                                                            className={classNames(
                                                                'collapsible-header',
                                                                {
                                                                    opened: sectionsOpenState?.[
                                                                        openStateObjectKey
                                                                    ]?.opened,
                                                                },
                                                            )}
                                                            style={{
                                                                borderTop:
                                                                    index === 0
                                                                        ? ''
                                                                        : '1px solid #9CA3AF',
                                                            }}
                                                            onClick={() =>
                                                                setSectionsOpenState((prev) => ({
                                                                    ...prev,
                                                                    [openStateObjectKey]: {
                                                                        ...prev?.[
                                                                            openStateObjectKey
                                                                        ],
                                                                        opened: !prev?.[
                                                                            openStateObjectKey
                                                                        ].opened,
                                                                    },
                                                                }))
                                                            }
                                                        >
                                                            <div
                                                                className={`collapsible-header-text ${
                                                                    isHomework
                                                                        ? 'homework'
                                                                        : 'in-class'
                                                                }`}
                                                            >
                                                                {subName}
                                                            </div>
                                                            <div className="collapsible-arrow-wrapper">
                                                                <ArrowThin
                                                                    fill={
                                                                        isHomework
                                                                            ? '#5F249F'
                                                                            : '#AC1359'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {sectionsOpenState?.[openStateObjectKey]
                                                            ?.opened && (
                                                            <div
                                                                className="weeklyPlan-lesson-droppable-parent"
                                                                style={{
                                                                    display: 'flex',
                                                                    marginBottom: '40px',
                                                                    marginRight: '20px',
                                                                    marginLeft: '40px',
                                                                }}
                                                            >
                                                                {columns.map(
                                                                    (column: any, columnIndex) => (
                                                                        <CustomDroppable
                                                                            key={columnIndex}
                                                                            lessons={lessons}
                                                                            columnIndex={
                                                                                columnIndex
                                                                            }
                                                                            lessonSubName={
                                                                                lessonSubName
                                                                            }
                                                                            lessonDays={lessonDays}
                                                                            lessonIndex={
                                                                                lessonIndex
                                                                            }
                                                                            column={column}
                                                                            columnIds={columnIds}
                                                                            lessonIds={lessonIds}
                                                                            editedColumns={
                                                                                editedColumns
                                                                            }
                                                                            setEditedColumns={
                                                                                setEditedColumns
                                                                            }
                                                                            columns={columns}
                                                                            setColumns={(
                                                                                data: any,
                                                                            ) => {
                                                                                setColumns(data);
                                                                                setIsDirty(true);
                                                                            }}
                                                                            activeMode={activeMode}
                                                                            isHomework={
                                                                                /homework/i.test(
                                                                                    name.trim(),
                                                                                ) &&
                                                                                (!subName ||
                                                                                    /homework/i.test(
                                                                                        subName.trim(),
                                                                                    ))
                                                                            }
                                                                            week={week}
                                                                            year={year}
                                                                        />
                                                                    ),
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            );
                        },
                    )}
                </DragDropContext>

                <div className="weeklyPlan-lesson weeklyPlan-resources" style={{ display: 'flex' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '36px',
                            backgroundColor: '#9333EA',
                        }}
                        className="weeklyPlan-sectionName"
                    >
                        <div
                            style={{
                                transform: 'rotate(180deg)',
                                whiteSpace: 'nowrap',
                                color: '#FFF',
                                fontSize: '1rem',
                                lineHeight: '150%',
                            }}
                        >
                            {t('weeklyPlan.homeworkResources')}
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div
                            className={classNames('collapsible-header', {
                                opened: sectionsOpenState.homeworkResources?.opened,
                            })}
                            onClick={() =>
                                setSectionsOpenState((prev) => ({
                                    ...prev,
                                    homeworkResources: { opened: !prev.homeworkResources?.opened },
                                }))
                            }
                        >
                            <div className="collapsible-header-text homework-resources">
                                {t('weeklyPlan.homeworkResources')}
                            </div>
                            <div className="collapsible-arrow-wrapper">
                                <ArrowThin fill="#9333EA" />
                            </div>
                        </div>
                        {sectionsOpenState.homeworkResources?.opened && (
                            <div
                                style={{
                                    minHeight: '120px',
                                    padding: '0.5rem 1.25rem 0.5rem 2.25rem',
                                }}
                            >
                                {days[0]?.lessons?.map((lesson: any, index: number) => {
                                    if (
                                        lesson.activities?.length ||
                                        activeResourcesRows.has(index)
                                    ) {
                                        return (
                                            <ResourcesRender
                                                activeResourcesRows={activeResourcesRows}
                                                key={`${index}${days[0].day}'__resRenderKey'`}
                                                index={index}
                                                setIsDirty={setIsDirty}
                                                lessons={lessons}
                                                getUpdatedDays={getUpdatedDays}
                                                columns={columns}
                                                setDays={setDays}
                                                days={days}
                                                week={week}
                                                year={year}
                                            />
                                        );
                                    }
                                    return <div key={`${index}${days[0].day}'__resRenderKey'`} />;
                                })}
                            </div>
                        )}
                    </div>
                </div>

                <div className="weeklyPlan-lesson weeklyPlan-tests">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '36px',
                            background: '#AEBD36',
                        }}
                        className="weeklyPlan-sectionName"
                    >
                        <div
                            style={{
                                transform: 'rotate(180deg)',
                                whiteSpace: 'nowrap',
                                color: '#FFF',
                                fontSize: '1rem',
                                lineHeight: '150%',
                            }}
                        >
                            {t('weeklyPlan.tests')}
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        <div
                            className={classNames('collapsible-header', {
                                opened: sectionsOpenState.tests?.opened,
                            })}
                            onClick={() =>
                                setSectionsOpenState((prev) => ({
                                    ...prev,
                                    tests: { opened: !prev.tests?.opened },
                                }))
                            }
                        >
                            <div className="collapsible-header-text tests">
                                {t('weeklyPlan.tests')}
                            </div>
                            <div className="collapsible-arrow-wrapper">
                                <ArrowThin fill="#AEBD36" />
                            </div>
                        </div>
                        {sectionsOpenState.tests?.opened && (
                            <div
                                style={{
                                    display: 'flex',
                                    minHeight: '10rem',
                                    padding: '0 1.25rem 0 2.25rem',
                                }}
                            >
                                {days.map((day: any, index: number) => {
                                    return (
                                        <div
                                            key={day.day}
                                            className="weeklyPlan-droppable"
                                            style={{
                                                width: `calc(100% / ${lessons.length})`,
                                            }}
                                        >
                                            <div className="weeklyPlan-add">
                                                <div
                                                    className="weeklyPlan-add__svg-parent sc_hover_color_text_svg"
                                                    onClick={() => {
                                                        const currentDate = moment()
                                                            .day(day.day)
                                                            .isoWeekYear(year)
                                                            .isoWeek(week)
                                                            .startOf('day')
                                                            .unix();
                                                        navigate(
                                                            `/online-testing/automated-tests/${params.id}?tab=Library&currentDate=${currentDate}`,
                                                        );
                                                    }}
                                                >
                                                    <Tooltip
                                                        id={TooltipId.WeeklyPlanAddNewTest}
                                                        desc={t('weeklyPlan.addNewTest')}
                                                    />
                                                    <Add fill="#bcbcbc" />
                                                </div>
                                            </div>
                                            {JSON.parse(JSON.stringify(day.tests)).map(
                                                (test: any, testIndex: number) => {
                                                    let testLen = 0;

                                                    for (let i = index; i < 5; i += 1) {
                                                        if (
                                                            days[i].tests.map(
                                                                ({ testId }: any) => testId,
                                                            )[testIndex] === test.testId
                                                        ) {
                                                            testLen += 1;
                                                        } else {
                                                            break;
                                                        }
                                                    }
                                                    const curTestId = test.testId as number;
                                                    const isLastBlock =
                                                        days[index - 1]?.tests.map(
                                                            ({ testId }: any) => testId,
                                                        )[testIndex] === test.testId;
                                                    return (
                                                        <div
                                                            ref={(element) => {
                                                                const clientHeight =
                                                                    element?.clientHeight;
                                                                if (
                                                                    ref.current[curTestId] &&
                                                                    clientHeight
                                                                ) {
                                                                    ref.current[curTestId].add(
                                                                        clientHeight,
                                                                    );
                                                                } else if (clientHeight) {
                                                                    ref.current[curTestId] =
                                                                        new Set([clientHeight]);
                                                                }
                                                            }}
                                                            className={classNames(
                                                                'weeklyPlan-draggable',
                                                                {
                                                                    'd-none': notInString(
                                                                        test.name,
                                                                        searchValue,
                                                                    ),
                                                                },
                                                            )}
                                                            key={`${test.testId}_${day.day}_${testIndex}__testIdKey`}
                                                            style={{
                                                                maxHeight: ref.current[curTestId]
                                                                    ? Math.min(
                                                                          ...ref.current[curTestId],
                                                                      )
                                                                    : 'unset',
                                                                wordBreak: 'break-all',
                                                                cursor: 'pointer',
                                                                zIndex: 5 - index,
                                                                boxShadow: isLastBlock
                                                                    ? 'unset'
                                                                    : '0 1px 6px rgb(0 0 0 / 24%)',
                                                                width: `calc(${
                                                                    100 * testLen
                                                                }% - 8px)`,
                                                                pointerEvents: isLastBlock
                                                                    ? 'none'
                                                                    : 'inherit',
                                                                color: isLastBlock
                                                                    ? 'white'
                                                                    : 'inherit',
                                                                minHeight: 40,
                                                            }}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    'weeklyPlan-draggable-controls',
                                                                )}
                                                            >
                                                                <Link
                                                                    state={{ test }}
                                                                    to={weeklyPlanGetPathToEditOnlineTest(
                                                                        week,
                                                                        params,
                                                                        test,
                                                                    )}
                                                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                >
                                                                    <Edit />
                                                                </Link>
                                                                <Link
                                                                    target="_blank"
                                                                    to={
                                                                        test.type === 'automated'
                                                                            ? `${appConstants.server}/dashboard/${test.reportId}/${params.id}/0/0/0/${test.exerciseId}`
                                                                            : `${appConstants.server}/dashboard/${test.reportId}/${params.id}/0/0/0/0/${test.testId}`
                                                                    }
                                                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                >
                                                                    <SearchAlt />
                                                                </Link>
                                                                <div
                                                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                    onClick={() => {
                                                                        if (
                                                                            test.type ===
                                                                            'automated'
                                                                        ) {
                                                                            setAutomatedTestToDelete(
                                                                                test.assignmentId,
                                                                            );
                                                                        } else {
                                                                            setProgressTestToDelete(
                                                                                test.assignmentId,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <Remove />
                                                                </div>
                                                            </div>
                                                            <div className="weeklyPlan-editable">
                                                                {test.name}
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmModal
                text={t('weeklyPlan.unassignTest')}
                onClick={() => {
                    pushTestToDelete('automated', automatedTestToDelete);
                    setIsDirty(true);
                    setAutomatedTestToDelete(0);
                }}
                onClose={() => {
                    setAutomatedTestToDelete(0);
                }}
                open={automatedTestToDelete}
            />
            <ConfirmModal
                text={t('weeklyPlan.unassignTest')}
                onClick={() => {
                    pushTestToDelete('progress', progressTestToDelete);
                    setIsDirty(true);
                    setProgressTestToDelete(0);
                }}
                onClose={() => {
                    setProgressTestToDelete(0);
                }}
                open={progressTestToDelete}
            />
        </WhiteBox>
    );
};

export default WeeklyPlanTable;
