const CircleCloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_1385_1355)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="#4B5563"
            />
            <path
                d="M12 11.1111L8.88889 8L8 8.88889L11.1111 12L8 15.1111L8.88889 16L12 12.8889L15.1111 16L16 15.1111L12.8889 12L16 8.88889L15.1111 8L12 11.1111Z"
                fill="#6B7280"
            />
        </g>
        <defs>
            <clipPath id="clip0_1385_1355">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleCloseIcon;
