import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InputCalendar from 'src/components/InputCalendar';
import Button from 'src/components/Button';
import '../styles.scss';

const ProfileEditChangeClass = ({
    open,
    setOpen,
    inputProcessor,
    curClassIndex,
}: {
    open: any;
    setOpen: any;
    inputProcessor: any;
    curClassIndex: any;
}) => {
    const { t } = useTranslation();
    const [selectedClass, setSelectedClass] = useState({
        id: 0,
        from: moment(new Date()).unix(),
        to: moment(new Date()).add(2, 'day').unix(),
        name: 'empty',
    } as any);
    const [dateInvalid, setDateInvalid] = useState(null as any);
    const [invalid, setInvalid] = useState(null as any);

    const saveNewClass = (classForSave: any) => {
        const tempClasses = structuredClone(inputProcessor.getValue('classes'));
        tempClasses[curClassIndex] = classForSave;
        inputProcessor.updateInput({ classes: tempClasses });
    };
    useEffect(() => {
        if (open) {
            setSelectedClass(open);
        }
    }, [open]);

    return (
        <div className="profileEdit-change-class">
            <div className="profileEdit-change-class__calendar">
                <span>{t('profile.startDate')}</span>
                <InputCalendar
                    onChange={(e: any) => {
                        const from = moment(e).unix();
                        setSelectedClass({ ...selectedClass, from });
                        if (from > selectedClass.to) {
                            setSelectedClass({
                                ...selectedClass,
                                from,
                                to: moment(e).add(2, 'day').unix(),
                            });
                        } else {
                            setSelectedClass({ ...selectedClass, from });
                        }
                    }}
                    date={new Date(selectedClass.from * 1000)}
                />
            </div>
            <div className="profileEdit-change-class__calendar">
                <span>{t('profile.endDate')}</span>
                <InputCalendar
                    invalid={dateInvalid}
                    minDate={new Date(moment.unix(selectedClass.from).add(2, 'day').format())}
                    onChange={(e: any) => {
                        setDateInvalid(false);
                        setSelectedClass({ ...selectedClass, to: moment(e).unix() });
                    }}
                    date={new Date(selectedClass.to * 1000)}
                />
            </div>
            <div className="profileEdit-change-class__buttons">
                <Button
                    onClick={() => {
                        let errors = false;
                        if (selectedClass.from > selectedClass.to) {
                            errors = true;
                            setDateInvalid(true);
                        }
                        if (selectedClass.id === 0) {
                            errors = true;
                            setInvalid(true);
                        }
                        if (!errors) {
                            saveNewClass(selectedClass);
                            setOpen(null);
                        }
                    }}
                    type="secondary"
                >
                    {t('profile.apply')}
                </Button>
                <Button
                    onClick={() => {
                        setOpen(null);
                    }}
                    type="secondary"
                >
                    {t('profile.cancel')}
                </Button>
            </div>
        </div>
    );
};

export default ProfileEditChangeClass;
