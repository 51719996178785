import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Checkbox from 'src/components/Checkbox';
import AddNewTest from 'src/components/Icons/AddNewTest';
import MobileControls from 'src/components/MobileControls';
import Search from 'src/components/Search';
import SelectMenu from 'src/components/SelectMenu';
import TextWithIcon from 'src/components/TextWithIcon';
import WhiteBox from 'src/components/WhiteBox';
import AutomatedTests from 'src/containers/AutomatedTests';
import ProgressTest from 'src/containers/ProgressTest';
import { useClass } from 'src/hooks/useClass';
import { putSidebar } from 'src/redux/layout/actions';
import { GetAutomatedProps } from 'src/redux/automated/types';
import Button from 'src/components/Button';
import { selectProgress } from 'src/redux/progress/selectors';
import AddUnitTestModal from 'src/components/Modals/AddUnitTestModal/AddUnitTestModal';
import './styles.scss';

type LevelsTemplateProps = {
    levels: any;
    currentActiveTab: any;
    activeLevel: any;
    setActiveLevel: any;
    t: any;
};

const levelsTemplate = ({
    levels,
    currentActiveTab,
    activeLevel,
    setActiveLevel,
    t,
}: LevelsTemplateProps) => {
    return levels.length > 1 && currentActiveTab === 'progress-tests' ? (
        <>
            <div className="onlineTesting__levels">
                {t('OnlineTesting.level')}
                <SelectMenu
                    wrapperClass="type_3"
                    text={
                        <div
                            className="assessments-truncate"
                            title={activeLevel || t('OnlineTesting.levels')}
                        >
                            {activeLevel || t('OnlineTesting.levels')}
                        </div>
                    }
                    options={levels.map((level: any) => {
                        return {
                            content: (
                                <div className="assessments-option">
                                    <Checkbox checked={activeLevel === level} />
                                    <span className="assessments-truncate">{level}</span>
                                </div>
                            ),
                            onClick: () => {
                                setActiveLevel(level);
                            },
                        };
                    })}
                />
            </div>
        </>
    ) : null;
};
const OnlineTesting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [activeType, setActiveType] = useState('Assigned this week' as GetAutomatedProps['type']);
    const [search, setSearch] = useState('');
    const { tests } = useSelector(selectProgress);
    const [levels, setLevels] = useState([] as any);
    const [activeLevel, setActiveLevel] = useState('All' as any);
    const [currentActiveTab, setCurrentActiveTab] = useState('progress-tests' as any);
    useClass();
    const [searchParams] = useSearchParams();
    const [isUnitModalOpen, setUnitModalOpen] = useState(false);

    const parentTabs: {
        name: string;
        id: 'Assigned' | 'Unassigned' | 'Past Tests' | 'Assigned this week';
    }[] = [
        { name: t('OnlineTesting.thisWeekTests'), id: 'Assigned this week' },
        { name: t('OnlineTesting.pastTests'), id: 'Past Tests' },
        { name: t('OnlineTesting.futureTests'), id: 'Assigned' },
        { name: t('OnlineTesting.library'), id: 'Unassigned' },
    ];
    const [parentTab, setParentTab] = useState(parentTabs[0].name);

    const getCurrentDateForSearch = () => {
        return searchParams.get('currentDate')
            ? `?currentDate=${searchParams.get('currentDate')}`
            : '';
    };
    const setActiveTab = (type: string) => {
        setCurrentActiveTab(type);
        navigate({
            pathname: `/online-testing/${type}/${params.id}`,
            search: getCurrentDateForSearch(),
        });
    };

    useEffect(() => {
        if (tests && tests.length) {
            const tempLevels = new Set();
            tempLevels.add('All');
            tests.map((item: any) => {
                return item.level ? tempLevels.add(item.level) : null;
            });
            setLevels(Array.from(tempLevels));
            setActiveLevel('All');
        }
    }, [tests, parentTab]);

    useEffect(() => {
        const tabToSet = parentTabs.find((tb) => {
            return tb.name === searchParams.get('tab');
        });
        if (tabToSet) {
            setParentTab(tabToSet.name);
            setActiveType(tabToSet.id);
        }
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params, searchParams]);

    const getCurrentWeekDates = () => {
        const curMoment = moment();
        const startOfWeek = curMoment.startOf('isoWeek').format('D MMM');
        const endOfWeek = curMoment.endOf('isoWeek').format('D MMM');
        return t('OnlineTesting.testsFor', { d1: startOfWeek, d2: endOfWeek });
    };

    return (
        <>
            <div className="onlineTesting-parent-tabs">
                <div className="onlineTesting-parent-tabs__item">
                    <div className="onlineTesting-parent-tabs__active-tab">
                        {parentTab === parentTabs[0].name ? getCurrentWeekDates() : parentTab}
                    </div>
                </div>
                <div className="onlineTesting-parent-tabs__items">
                    {parentTabs.map((item, i) => {
                        return (
                            <div
                                key={i}
                                className={classNames({
                                    'onlineTesting-parent-tabs__tab-active':
                                        item.name === parentTab,
                                })}
                            >
                                <Button
                                    type="square"
                                    onClick={() => {
                                        setParentTab(item.name);
                                        setActiveType(item.id);
                                    }}
                                >
                                    {item.name}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <WhiteBox>
                <div className="onlineTesting-header">
                    <div className="onlineTesting-tabs">
                        <span
                            className={classNames('onlineTesting-tab sc_hover_color_text', {
                                'onlineTesting-tab--active': params.type === 'automated-tests',
                            })}
                            onClick={() => {
                                setActiveTab('automated-tests');
                                setSearch('');
                            }}
                        >
                            {t('OnlineTesting.unitTests')}
                        </span>
                        <span
                            className={classNames('onlineTesting-tab sc_hover_color_text', {
                                'onlineTesting-tab--active': params.type === 'progress-tests',
                            })}
                            onClick={() => {
                                setActiveTab('progress-tests');
                                setSearch('');
                            }}
                        >
                            {t('OnlineTesting.progressTests')}
                        </span>
                    </div>
                    <div className="onlineTesting-subhead">
                        <Search onChange={(text: string) => setSearch(text)} />
                        {levelsTemplate({
                            levels,
                            currentActiveTab,
                            activeLevel,
                            setActiveLevel,
                            t,
                        })}
                        {params.type === 'automated-tests' ? (
                            <TextWithIcon
                                icon={<AddNewTest />}
                                text={t('OnlineTesting.createNewTest')}
                                onClick={() => setUnitModalOpen(true)}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="onlineTestingMobControls">
                    <span
                        className={classNames('onlineTesting-tab sc_hover_color_text', {
                            'onlineTesting-tab--active': params.type === 'automated-tests',
                        })}
                        onClick={() => {
                            setActiveTab('automated-tests');
                            setSearch('');
                        }}
                    >
                        {t('OnlineTesting.unitTests')}
                    </span>
                    <span
                        className={classNames('onlineTesting-tab sc_hover_color_text', {
                            'onlineTesting-tab--active': params.type === 'progress-tests',
                        })}
                        onClick={() => {
                            setActiveTab('progress-tests');
                            setSearch('');
                        }}
                    >
                        {t('OnlineTesting.progressTests')}
                    </span>
                </div>
                <MobileControls>
                    <Search alwaysWide onChange={(text: string) => setSearch(text)} />
                    {levelsTemplate({ levels, currentActiveTab, activeLevel, setActiveLevel, t })}
                    {params.type === 'automated-tests' ? (
                        <TextWithIcon
                            icon={<AddNewTest />}
                            text={t('OnlineTesting.createNewTest')}
                            onClick={() => setUnitModalOpen(true)}
                        />
                    ) : null}
                </MobileControls>
                {params.type === 'automated-tests' ? (
                    <AutomatedTests activeType={activeType} search={search} />
                ) : (
                    <ProgressTest
                        activeType={activeType}
                        search={search}
                        activeLevel={activeLevel}
                    />
                )}
            </WhiteBox>
            <AddUnitTestModal
                open={isUnitModalOpen}
                onClose={() => setUnitModalOpen(false)}
                parentTab={parentTab}
            />
        </>
    );
};

export default OnlineTesting;
