import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Modal from 'src/components/Modal';
import { getStudents, putStudents, updateStudent } from 'src/redux/students/actions';
import { getDuplicatedStudents, getUnmatchedStudents } from 'src/redux/users/actions';
import Table from 'src/components/Table';
import { getAllStudents, putAllStudents } from 'src/redux/allStudents/actions';
import { selectAllStudents } from 'src/redux/allStudents/selectors';
import { selectStudents } from 'src/redux/students/selectors';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import TableMobRow from 'src/components/TableMobRow';
import SuccessModal from 'src/components/Modals/SuccessModal';
import ActionText from 'src/components/ActionText';
import { allStudentsInitialState } from 'src/redux/allStudents/reducer';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { appConstants } from 'src/constants';
import './styles.scss';

const UnmatchedStudentsViewModal = ({
    open,
    setOpen,
    searchBy,
}: {
    open: any;
    setOpen: any;
    searchBy: 'classId' | 'name';
}) => {
    const dispatch = useDispatch();
    const [disableOutsideClick, setDisableOutsideClick] = useState(false);
    const [openUseThisId, setOpenUseThisId] = useState(null as any);
    const [openSuccessModal, setOpenSuccessModal] = useState(false as any);
    const allStudents = useSelector(selectAllStudents);
    const classStudents = useSelector(selectStudents);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (searchBy === 'name') {
            const search = {
                active: true,
                school: 'gid',
                search: open.name ? open.name.split(' ')[0] : '',
            };
            dispatch(getAllStudents(search as any));
        } else if (open.class) {
            dispatch(getStudents({ classId: open.class.id }));
        }
        return () => {
            dispatch(putAllStudents(allStudentsInitialState.allStudents));
            dispatch(
                putStudents({
                    age: 0,
                    attendance: 0,
                    end: 0,
                    name: '',
                    note: false,
                    start: 0,
                    survey: null,
                    test: {
                        type: '',
                        timestamp: 0,
                    },
                    tutorial: {
                        late: false,
                        timestamp: 0,
                    },
                    uid: 0,
                    accessed: 0,
                    ielts: {
                        credits: {
                            ai: 0,
                            general: 0,
                        },
                        scores: {
                            listening: 0,
                            overall: 0,
                            reading: 0,
                            speaking: 0,
                            writing: 0,
                        },
                        marking: {
                            speaking: {
                                attemptId: '0',
                                id: '0',
                            },
                            writing: {
                                attemptId: '0',
                                id: '0',
                            },
                        },
                    },
                }),
            );
        };
    }, [dispatch]);

    const getSubtitleName = () => {
        let result = '';
        if (open.name === open.mail) {
            result = `“${getNoEmpty(open.name)}”`;
        } else {
            result = `“${getNoEmpty(open.name)}” (${getNoEmpty(open.mail)})`;
        }
        return result;
    };
    const successModal = (
        <>
            {openSuccessModal && (
                <SuccessModal
                    open={openSuccessModal}
                    text={t('unmatchedStudents.checkDuplicatesPageForNewDuplicates')}
                    onClose={() => {
                        setOpenSuccessModal(null);
                        setOpen(null);
                        dispatch(getDuplicatedStudents({}));
                    }}
                />
            )}
        </>
    );

    const useThisId = (
        <>
            {openUseThisId && (
                <ConfirmModal
                    modalBackdropClass="unmatchedStudents-view-modal-confirm"
                    open={openUseThisId}
                    onClose={() => {
                        setDisableOutsideClick(false);
                        setOpenUseThisId(null);
                    }}
                    onClick={() => {
                        dispatch(
                            updateStudent({
                                studentId: open.uid,
                                data: { reportedId: openUseThisId.username },
                                cb: () => {
                                    setOpenUseThisId(false);
                                    setOpenSuccessModal(true);
                                    dispatch(getUnmatchedStudents());
                                },
                            }),
                        );
                    }}
                    text={`${t('unmatchedStudents.addIDConfirmation')} ${getNoEmpty(
                        openUseThisId.username,
                    )} ${t('unmatchedStudents.toTheUserAccount')} “${getNoEmpty(
                        open.name,
                    )}” (id: ${getNoEmpty(open.reportedId)})?`}
                />
            )}
        </>
    );

    if (searchBy === 'name') {
        return (
            <>
                {allStudents.data.length && (
                    <>
                        {successModal}
                        <Modal
                            modalBackdropClass="unmatchedStudentsViewModal-modal-backdrop"
                            disableOutsideClick={disableOutsideClick}
                            open={open}
                            onClose={() => {
                                setOpen(null);
                            }}
                        >
                            <div className="unmatchedStudentsViewModal-parent">
                                {useThisId}
                                <div className="unmatchedStudentsViewModal-title">
                                    {t('unmatchedStudents.studentsWithSimilarNameOrEmail')}
                                </div>
                                <div className="unmatchedStudentsViewModal-subtitle">
                                    {t('unmatchedStudents.schoolCreatedAccountsSameClassSameTime')}{' '}
                                    {getSubtitleName()}. {t('.click')}{' '}
                                    <span>{t('unmatchedStudents.useThisId')}</span>{' '}
                                    {t('unmatchedStudents.assignSchoolIDToInvitedStudent')}.
                                </div>
                                <Table
                                    tableRowsClassName="unmatchedStudentsViewModal-table-rows"
                                    header={
                                        <>
                                            <span>{t('general.name')}</span>
                                            <span>{t('general.email')}</span>
                                            <span>{t('general.startDate')}</span>
                                            <span>{t('general.lastClass')}</span>
                                            <span>{t('general.sameClass')}</span>
                                            <span>{t('general.similarName')}</span>
                                            <span>{t('general.schoolID')}</span>
                                            <span>{t('general.action')}</span>
                                        </>
                                    }
                                    rows={allStudents.data.map((oneStudent: any) => {
                                        return (
                                            <>
                                                <span title={getNoEmpty(oneStudent.name)}>
                                                    <ActionText
                                                        onClick={() =>
                                                            navigate(
                                                                `${appConstants.server}/administration/studentmanagement/report/${oneStudent.uid}`,
                                                            )
                                                        }
                                                    >
                                                        {getNoEmpty(oneStudent.name)}
                                                    </ActionText>
                                                </span>
                                                <span title={getNoEmpty(oneStudent.mail)}>
                                                    {getNoEmpty(oneStudent.mail)}
                                                </span>
                                                <span
                                                    title={moment
                                                        .unix(oneStudent.start as number)
                                                        .format('DD MMM YYYY')}
                                                >
                                                    {moment
                                                        .unix(oneStudent.start as number)
                                                        .format('DD MMM YYYY')}
                                                </span>
                                                <span title={getNoEmpty(open?.class?.name)}>
                                                    {getNoEmpty(open?.class?.name)}
                                                </span>
                                                <span title={getNoEmpty(oneStudent.username)}>
                                                    {getNoEmpty(oneStudent.username)}
                                                </span>
                                                <span>
                                                    <ActionText
                                                        onClick={() => {
                                                            setOpenUseThisId(oneStudent);
                                                            setDisableOutsideClick(true);
                                                        }}
                                                    >
                                                        {t(
                                                            'unmatchedStudents.useThisId',
                                                        ).toUpperCase()}
                                                    </ActionText>
                                                </span>
                                            </>
                                        );
                                    })}
                                    headerMobile={
                                        <>
                                            <span>{t('general.student')}</span>
                                        </>
                                    }
                                    rowsMobile={allStudents.data.map(
                                        (oneStudentMob: any, i: number) => {
                                            return {
                                                head: (
                                                    <>
                                                        <div className="students-name-mobile students-active">
                                                            <b>{i + 1}.</b>
                                                            {getNoEmpty(oneStudentMob.name)}
                                                        </div>
                                                    </>
                                                ),
                                                content: (
                                                    <>
                                                        <TableMobRow title={t('general.email')}>
                                                            {getNoEmpty(oneStudentMob.mail)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.startDate')}>
                                                            {moment
                                                                .unix(oneStudentMob.start as number)
                                                                .format('DD MMM YYYY')}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.lastClass')}>
                                                            {getNoEmpty(open?.class?.name)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.schoolID')}>
                                                            {getNoEmpty(oneStudentMob.username)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.action')}>
                                                            <ActionText
                                                                onClick={() => {
                                                                    setOpenUseThisId(oneStudentMob);
                                                                    setDisableOutsideClick(true);
                                                                }}
                                                            >
                                                                {t('unmatchedStudents.useThisId')}
                                                            </ActionText>
                                                        </TableMobRow>
                                                    </>
                                                ),
                                            };
                                        },
                                    )}
                                />
                            </div>
                        </Modal>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <>
                {classStudents.length && (
                    <>
                        {successModal}
                        <Modal
                            modalBackdropClass="unmatchedStudentsViewModal-modal-backdrop"
                            disableOutsideClick={disableOutsideClick}
                            open={open}
                            onClose={() => {
                                setOpen(null);
                            }}
                        >
                            <div className="unmatchedStudentsViewModal-parent">
                                {useThisId}
                                <div className="unmatchedStudentsViewModal-title">
                                    {t('unmatchedStudents.studentsSameClass')}
                                </div>
                                <div className="unmatchedStudentsViewModal-subtitle">
                                    {t('unmatchedStudents.schoolCreatedAccountsSameClassSameTime')}{' '}
                                    {getSubtitleName()}. {t('.click')}{' '}
                                    <span>{t('unmatchedStudents.useThisId')}</span>{' '}
                                    {t('unmatchedStudents.assignSchoolIDToInvitedStudent')}.
                                </div>
                                <Table
                                    tableRowsClassName="unmatchedStudentsViewModal-table-rows"
                                    header={
                                        <>
                                            <span>{t('general.name')}</span>
                                            <span>{t('general.email')}</span>
                                            <span>{t('general.startDate')}</span>
                                            <span>{t('general.lastClass')}</span>
                                            <span>{t('general.schoolID')}</span>
                                            <span>{t('general.action')}</span>
                                        </>
                                    }
                                    rows={classStudents
                                        .filter((student) => student.username !== open.reportedId)
                                        .map((oneStudent: any) => {
                                            return (
                                                <>
                                                    <span title={getNoEmpty(oneStudent.name)}>
                                                        <ActionText
                                                            onClick={() =>
                                                                navigate(
                                                                    `${appConstants.server}/administration/studentmanagement/report/${oneStudent.uid}`,
                                                                )
                                                            }
                                                        >
                                                            {getNoEmpty(oneStudent.name)}
                                                        </ActionText>
                                                    </span>
                                                    <span title={getNoEmpty(oneStudent.mail)}>
                                                        {getNoEmpty(oneStudent.mail)}
                                                    </span>
                                                    <span
                                                        title={moment
                                                            .unix(oneStudent.start as number)
                                                            .format('DD MMM YYYY')}
                                                    >
                                                        {moment
                                                            .unix(oneStudent.start as number)
                                                            .format('DD MMM YYYY')}
                                                    </span>
                                                    <span title={getNoEmpty(open?.class?.name)}>
                                                        {getNoEmpty(open?.class?.name)}
                                                    </span>
                                                    <span title={getNoEmpty(oneStudent.username)}>
                                                        {getNoEmpty(oneStudent.username)}
                                                    </span>
                                                    <span>
                                                        <ActionText
                                                            onClick={() => {
                                                                setOpenUseThisId(oneStudent);
                                                                setDisableOutsideClick(true);
                                                            }}
                                                        >
                                                            {t('unmatchedStudents.useThisId')}
                                                        </ActionText>
                                                    </span>
                                                </>
                                            );
                                        })}
                                    headerMobile={
                                        <>
                                            <span>Student</span>
                                        </>
                                    }
                                    rowsMobile={classStudents
                                        .filter((student) => student.username !== open.reportedId)
                                        .map((oneStudentMob: any, i: number) => {
                                            return {
                                                head: (
                                                    <>
                                                        <div className="students-name-mobile students-active">
                                                            <b>{i + 1}.</b>
                                                            {getNoEmpty(oneStudentMob.name)}
                                                        </div>
                                                    </>
                                                ),
                                                content: (
                                                    <>
                                                        <TableMobRow title={t('general.email')}>
                                                            {getNoEmpty(oneStudentMob.mail)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.startDate')}>
                                                            {moment
                                                                .unix(oneStudentMob.start as number)
                                                                .format('DD MMM YYYY')}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.lastClass')}>
                                                            {getNoEmpty(open?.class?.name)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.schoolID')}>
                                                            {getNoEmpty(oneStudentMob.username)}
                                                        </TableMobRow>
                                                        <TableMobRow title={t('general.action')}>
                                                            <ActionText
                                                                onClick={() => {
                                                                    setOpenUseThisId(oneStudentMob);
                                                                    setDisableOutsideClick(true);
                                                                }}
                                                            >
                                                                {t('unmatchedStudents.useThisId')}
                                                            </ActionText>
                                                        </TableMobRow>
                                                    </>
                                                ),
                                            };
                                        })}
                                />
                            </div>
                        </Modal>
                    </>
                )}
            </>
        </>
    );
};
export default UnmatchedStudentsViewModal;
