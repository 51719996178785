import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { getBook, putBook } from '../actions';
import { getBooks } from '../../weeklyPlan/sagas/getBooks';

export function* getBookSaga({ payload }: ReturnType<typeof getBook>): SagaIterator {
    try {
        const { classId } = payload;
        yield put(startLoading());
        const result = yield call(API.get(), `/staff/classes/${classId}/book`);
        if (result?.id) {
            yield call(getBooks, { classId, id: result.id } as any);
        }
        yield put(putBook(result));
    } catch (error) {
        const typedError = error as { code: number };
        if (typedError.code === 404) {
            return;
        }
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
