import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAutomatedTest, putAutomatedTest } from '../actions';

export function* getAutomatedTestSaga({
    payload,
}: ReturnType<typeof getAutomatedTest>): SagaIterator {
    try {
        yield put(startLoading());
        let data;
        if (!window.location.pathname.includes('assigned')) {
            data = yield call(API.get(), `/staff/tests/${payload.classId}/${payload.testId}`);
        } else {
            data = yield call(
                API.get(),
                `/staff/tests/${payload.classId}/assignments/${payload.testId}`,
            );
        }
        yield put(putAutomatedTest(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
