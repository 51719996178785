import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { DELETE_TO_DO, GET_ALL_TO_DO, GET_TO_DO_REFERENCES, SAVE_TO_DO } from '../actions';
import { getAllToDoSaga } from './getAllToDoSaga';
import { saveToDoSaga } from './saveToDoSaga';
import { getToDoReferencesSaga } from './getToDoReferencesSaga';
import { deleteToDoSaga } from './deleteToDoSaga';

export function* toDoWatcher(): SagaIterator {
    yield takeLatest(GET_ALL_TO_DO, getAllToDoSaga);
    yield takeLatest(SAVE_TO_DO, saveToDoSaga);
    yield takeLatest(GET_TO_DO_REFERENCES, getToDoReferencesSaga);
    yield takeLatest(DELETE_TO_DO, deleteToDoSaga);
}
