import './styles.scss';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { useMobileControls } from 'src/hooks/useMobileControls';
import { createPortal } from 'react-dom';
import { OptionsItem, Props } from './types';
import ArrowBoldDown from '../Icons/ArrowBoldDown';

const SelectMenu = ({
    options,
    text,
    icon,
    wrapperClass,
    disableOutside,
    alwaysWide = false,
    isCloseDropdownOnClick = true,
    disabled = false,
    invalid,
    error = undefined,
    withPortal,
}: Props) => {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);
    useOutsideClick(
        { current: [wrapperRef?.current, portalRef?.current] },
        () => setOpen(false),
        disableOutside,
    );
    useMobileControls(open);
    const [selectPosition, setSelectPosition] = useState<string>();
    const [selected, setSelected] = useState(false);

    const WHITE_BOX_BOTTOM_PADDING_HEIGHT = 60;
    const DEFAULT_MIN_HEIGHT_SELECT = 30;
    useEffect(() => {
        const vh = (percent: number) => {
            const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            return (percent * h) / 100;
        };

        const vw = (percent: number) => {
            const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            return (percent * w) / 100;
        };

        const vmin = (percent: number) => {
            return Math.min(vh(percent), vw(percent));
        };

        const rect = wrapperRef.current?.getBoundingClientRect();
        const whiteBox = document.querySelector('.whiteBox');

        if (
            rect &&
            whiteBox &&
            whiteBox.getBoundingClientRect().bottom -
                (rect.bottom + vmin(DEFAULT_MIN_HEIGHT_SELECT)) <
                WHITE_BOX_BOTTOM_PADDING_HEIGHT
        ) {
            const s = `${rect.height + 1}px`;
            setSelectPosition(s);
        }
    }, [open]);

    const validate = () => {
        if (selected) {
            return false;
        }
        if (error) {
            return error;
        }
    };

    if (withPortal) {
        const rect = wrapperRef.current?.getBoundingClientRect();

        return (
            <div
                className={classNames('selectMenu', wrapperClass, {
                    'selectMenu--active': open,
                    [`${wrapperClass}--active`]: open,
                    invalid,
                })}
                ref={wrapperRef}
            >
                <div
                    style={
                        selectPosition
                            ? {
                                  bottom: selectPosition,
                                  top: 'unset',
                                  borderRadius: '0 0 10px 10px',
                              }
                            : {}
                    }
                    className={classNames('selectMenu-selected', {
                        'selectMenu-selected-mobile': !alwaysWide,
                        'selectMenu-selected--active': open,
                        'selectMenu-selected--down': !open,
                        'selectMenu-selected-mobile--active': open,
                        'selectMenu-selected-mobile--down': !open,
                        'selectMenu-selected-mobile--invalid': validate(),
                        disabled,
                    })}
                    onClick={() => {
                        if (!disabled) setOpen(!open);
                    }}
                >
                    {icon && <div style={{ display: 'flex' }}>{icon}</div>}
                    <span>{!text || text === '' ? '-Select-' : text}</span>
                    <ArrowBoldDown />
                </div>

                {createPortal(
                    <div className={classNames(wrapperClass)} ref={portalRef}>
                        <div
                            className="selectMenu-options"
                            style={{
                                top: (rect as any)?.y + 50,
                                left: rect?.x,
                                zIndex: 999999999999999,
                                display: open ? 'inherit' : 'none',
                            }}
                        >
                            {options.map((option: OptionsItem, i: number) => {
                                return (
                                    <div
                                        className="selectMenu-option qr_hover_color_text_svg_bg"
                                        key={i}
                                        onClick={() => {
                                            setSelected(true);
                                            if (isCloseDropdownOnClick) {
                                                setOpen(false);
                                            }
                                            if (option.onClick) {
                                                option.onClick();
                                            }
                                        }}
                                    >
                                        {option.content}
                                    </div>
                                );
                            })}
                        </div>
                    </div>,
                    document.getElementById('modalPortal') || document.body,
                )}
            </div>
        );
    }

    return (
        <div
            className={classNames('selectMenu', wrapperClass, {
                'selectMenu--active': open,
                [`${wrapperClass}--active`]: open,
                invalid,
            })}
            ref={wrapperRef}
        >
            <div
                style={
                    selectPosition
                        ? {
                              bottom: selectPosition,
                              top: 'unset',
                              borderRadius: '0 0 10px 10px',
                          }
                        : {}
                }
                className={classNames('selectMenu-selected', {
                    'selectMenu-selected-mobile': !alwaysWide,
                    'selectMenu-selected--active': open,
                    'selectMenu-selected--down': !open,
                    'selectMenu-selected-mobile--active': open,
                    'selectMenu-selected-mobile--down': !open,
                    'selectMenu-selected-mobile--invalid': validate(),
                    disabled,
                })}
                onClick={() => {
                    if (!disabled) setOpen(!open);
                }}
            >
                {icon && <div style={{ display: 'flex' }}>{icon}</div>}
                <span>{!text || text === '' ? '-Select-' : text}</span>
                <ArrowBoldDown />
            </div>
            <div
                className="selectMenu-options"
                style={
                    selectPosition
                        ? {
                              bottom: selectPosition,
                              top: 'unset',
                              borderRadius: '10px 10px 0 0 ',
                              display: open ? 'inherit' : 'none',
                          }
                        : {
                              display: open ? 'inherit' : 'none',
                          }
                }
                ref={portalRef}
            >
                {options.map((option: OptionsItem, i: number) => {
                    return (
                        <div
                            className="selectMenu-option qr_hover_color_text_svg_bg"
                            key={i}
                            onClick={() => {
                                setSelected(true);
                                if (isCloseDropdownOnClick) {
                                    setOpen(false);
                                }
                                if (option.onClick) {
                                    option.onClick();
                                }
                            }}
                        >
                            {option.content}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default SelectMenu;
