import './styles.scss';
import classNames from 'classnames';
import { useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { ProfileCardContentProps } from './types';

const ProfileCardContent = ({ title, buttons, table, tableClass, id }: ProfileCardContentProps) => {
    const { isModuleActive, getModuleName } = useIsModuleActive();

    if (!isModuleActive(id)) {
        return null;
    }

    return (
        <div className={`profileCard ${id}`}>
            <div className="profileCardContent">
                <div className="profileCardContent__title profile-title">
                    {getModuleName(id) || title}
                </div>
                {buttons && <div className="profileCardContent__buttons">{buttons}</div>}
                <div className={classNames('profileCardContent__table', tableClass)}>{table}</div>
            </div>
        </div>
    );
};

export default ProfileCardContent;
