import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClasses } from 'src/redux/classes/selectors';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import './styles.scss';
import { Props } from './types';
import SelectMenu from '../../SelectMenu';
import Button from '../../Button';

const InviteRow = ({ options, onSelect, selected, title }: any) => {
    const { t } = useTranslation();
    return (
        <div className="invite-student-modal-row">
            <span className="restart-modal-row-name">{title}:</span>
            <SelectMenu
                alwaysWide
                wrapperClass="restart-select"
                text={
                    <div className="restart-truncate">
                        {selected || t('modals.inviteStudent.chooseClass')}
                    </div>
                }
                options={options.map((item: any) => {
                    return {
                        content: (
                            <div className="restart-option" title={item.name}>
                                <span className="restart-truncate">{item.name}</span>
                            </div>
                        ),
                        onClick: () => {
                            onSelect(item);
                        },
                    };
                })}
            />
        </div>
    );
};

const InviteStudentModal = ({ open, onSelect, onClose, withPreSessionalClass }: Props) => {
    const classesData = useSelector(selectClasses);
    let classes = [...classesData];
    const onCloseChild = () => {
        onClose();
    };

    if (withPreSessionalClass) {
        classes = [
            ...classes,
            {
                id: 'pre-sessional',
                lastAssessment: {
                    teacher: '',
                    timestamp: 0,
                },
                lastPlan: {
                    teacher: '',
                    timestamp: 0,
                },
                level: null,
                name: 'Pre-sessional',
                open: true,
                room: '',
            },
        ];
    }

    const [classToInvite, setClassToInvite] = useState(undefined as any);
    const [selected, setSelected] = useState('' as any);
    const { t } = useTranslation();

    return (
        <>
            <Modal open={open} onClose={onCloseChild}>
                <div className="invite-student-modal">
                    <InviteRow
                        options={classes}
                        onSelect={(data: any) => {
                            setClassToInvite(data.id);
                            setSelected(data.name);
                        }}
                        selected={selected}
                        title={t('modals.inviteStudent.chooseClass')}
                    />
                    <div className="invite-student-modal__button">
                        <Button
                            disabled={classToInvite === undefined}
                            type="secondary"
                            onClick={() => {
                                onSelect(classToInvite);
                            }}
                        >
                            {t('general.next')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InviteStudentModal;
