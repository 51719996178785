import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';
import Delete from 'src/components/Icons/Delete';
import Edit from 'src/components/Icons/Edit';
import AssignModal from 'src/components/Modals/AssignModal';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import CustomLink from 'src/components/CustomLink';
import Table from 'src/components/Table';
import { getDatesFormattedInterval } from 'src/containers/helpers/getDatesFormattedInterval';
import { getEndOfUtcDay } from 'src/helpers/getEndOfUtcDay';
import { getStartOfUtcDay } from 'src/helpers/getStartOfUtcDay';
import { assignAutomated, deleteAutomated } from 'src/redux/automated/actions';
import { appConstants } from 'src/constants';
import { getPartialRange } from 'src/helpers/getPartialRange';
import { useTranslation } from 'react-i18next';
import { AssignedProps, AssignedTestProps } from '../types';

import './styles.scss';

const Assigned = ({ assigned, activeType }: AssignedProps) => {
    const { t } = useTranslation();
    const [tesToDelete, setTestToDelete] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const [assign, setAssign] = useState(0);
    const navigate = useNavigate();
    return (
        <>
            <Table
                tableRowsClassName="automatedTests-table-rows"
                header={
                    <>
                        <span className="automatedTests-list">
                            {t('assigned.listOfAssignedTests')}
                        </span>
                        <span className="automatedTests-assigned">{t('assigned.assignedBy')}</span>
                        <span className="automatedTests-available">{t('assigned.available')}</span>
                    </>
                }
                rows={assigned.map((test: AssignedTestProps, i: number) => (
                    <div
                        key={`${test.id}_${i}`}
                        className={classNames('online-test-row-item', {
                            selected: test.currentlySelected,
                        })}
                    >
                        <span
                            title={test.testName}
                            className="automatedTests-list automatedTests-active tr_hover_color_text"
                        >
                            <b>{i + 1}.</b>
                            <CustomLinkOverloaded
                                linkProps={{
                                    to: `/online-testing/automated-tests/assigned/${params.id}/${test.id}`,
                                    className: 'tr_hover_color_text automatedTests-active',
                                }}
                                parentProps={{
                                    style: { display: 'contents' },
                                }}
                            >
                                {test.testName}
                            </CustomLinkOverloaded>
                        </span>
                        <span className="automatedTests-assigned" title={test.teacher}>
                            {test.teacher}
                        </span>
                        <div className="automatedTests-available automatedTests-available-row">
                            <div
                                className="automatedTests-edit automatedTests-active tr_hover_color_text_svg"
                                onClick={() => {
                                    setAssign(test.id);
                                }}
                            >
                                <span>{getDatesFormattedInterval(test.from, test.to)}</span>
                                <Edit />
                            </div>
                            <span title={test.testGlobal ? t('assigned.globalTest') : ''}>
                                {test.testGlobal ? t('assigned.globalTest') : ''}
                            </span>
                            <div className="automatedTests-report tr_hover_color_text_svg">
                                <CustomLink
                                    className="automatedTests-active tr_hover_color_text_svg"
                                    target="_blank"
                                    rel="noreferrer"
                                    to={`${appConstants.server}/dashboard/${test.report}/${params.id}/0/0/0/${test.exerciseId}`}
                                >
                                    {t('assigned.report')}
                                </CustomLink>
                                <div
                                    onClick={() => {
                                        setTestToDelete(test.id);
                                    }}
                                    className="automatedTests-delete sc_hover_color_text_svg"
                                >
                                    <Delete />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                headerMobile={
                    <>
                        <span>{t('assigned.listOfAssignedTests')}</span>
                    </>
                }
                rowsMobile={assigned.map((test: AssignedTestProps, idx: number) => {
                    return {
                        head: (
                            <div className="assessments-name-mobile__row">
                                <div
                                    key={`${test.id}_${idx}`}
                                    className={classNames(
                                        'assessments-name-mobile automatedTests-active',
                                        {
                                            selected: test.currentlySelected,
                                        },
                                    )}
                                >
                                    <b>{idx + 1}.</b>
                                    {test.testName}
                                </div>
                                <Link
                                    className="assessments-name-mobile__view"
                                    to={`/online-testing/automated-tests/assigned/${params.id}/${test.id}`}
                                >
                                    {t('general.view')}
                                </Link>
                            </div>
                        ),
                        content: (
                            <>
                                <div className="automatedTests-mobile-row">
                                    <div className="automatedTests-mobile-row-title">
                                        <b>{t('assigned.assignedBy')}</b>
                                    </div>
                                    <span>{test.teacher}</span>
                                </div>
                                <div className="automatedTests-mobile-row">
                                    <div className="automatedTests-mobile-row-title">
                                        <b>{t('assigned.available')}</b>
                                    </div>
                                    <div
                                        className="automatedTests-active automatedTests-mobile-edit"
                                        onClick={() => {
                                            setAssign(test.id);
                                        }}
                                    >
                                        <span>{getDatesFormattedInterval(test.from, test.to)}</span>
                                        <Edit />
                                    </div>
                                </div>
                                <div className="automatedTests-mobile-row">
                                    <div className="automatedTests-mobile-row-title" />
                                    <span>{test.testGlobal ? t('assigned.globalTest') : ''}</span>
                                </div>
                                <div className="automatedTests-mobile-row">
                                    <div className="automatedTests-mobile-row-title">
                                        <b>{t('assigned.action')}</b>
                                    </div>
                                    <a
                                        className="automatedTests-active"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${appConstants.server}}/dashboard/${test.report}/${params.id}/0/0/0/${test.exerciseId}`}
                                    >
                                        {t('assigned.report')}
                                    </a>
                                </div>
                                <div className="automatedTests-mobile-row">
                                    <div className="automatedTests-mobile-row-title" />
                                    <div
                                        onClick={() => {
                                            setTestToDelete(test.id);
                                        }}
                                        className="automatedTests-delete"
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            </>
                        ),
                    };
                })}
            />
            <AssignModal
                type="rangeCustom"
                onChangeReturnType="date"
                allowPartialRange
                defaultDateEmpty
                open={assign}
                onAssign={(days) => {
                    days = getPartialRange(days);
                    dispatch(
                        assignAutomated({
                            classId: params.id,
                            from: getStartOfUtcDay(days[0]),
                            to: getEndOfUtcDay(days[1]),
                            testId: assign,
                            update: true,
                            type: activeType,
                        }),
                    );
                }}
                onClose={() => {
                    setAssign(0);
                }}
            />
            <ConfirmModal
                text={t('assigned.areYouSure')}
                onClick={() => {
                    dispatch(
                        deleteAutomated({
                            classId: params.id,
                            testId: tesToDelete,
                            type: activeType,
                        }),
                    );
                    setTestToDelete(0);
                }}
                onClose={() => {
                    setTestToDelete(0);
                }}
                open={tesToDelete}
            />
        </>
    );
};

export default Assigned;
