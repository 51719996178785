import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAssessmentStudents, saveAssessmentMarks } from '../actions';

export function* saveAssessmentMarksSaga({
    payload,
}: ReturnType<typeof saveAssessmentMarks>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(
            API.post(),
            `/staff/classes/${payload.classId}/assessments`,
            payload.data.filter(({ value }) => value !== ''),
        );
        if (payload.week && payload.year) {
            yield put(
                getAssessmentStudents({
                    week: payload.week,
                    year: payload.year,
                    classId: payload.classId,
                }),
            );
        }
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
