import { createAction } from '@reduxjs/toolkit';

export const GET_IP_INFO = 'GET_IP_INFO';
export const PUT_IP_INFO = 'PUT_IP_INFO';

export type PutIPInfoType = {
    ip: string;
    city: string;
    region: string;
    country: string;
    loc: string;
    org: string;
    postal: string;
    timezone: string;
} | null;

export const putIPInfo = createAction<PutIPInfoType>(PUT_IP_INFO);
export const getIPInfo = createAction(GET_IP_INFO);
