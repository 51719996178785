import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { putApiRequestError } from 'src/redux/error/actions';
import { urlQueryFromObject } from 'src/helpers/queryFromObject';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { sendStudentMail } from '../actions';

export function* sendStudentMailSaga({
    payload,
}: ReturnType<typeof sendStudentMail>): SagaIterator {
    try {
        yield put(startLoading());
        const query = payload.query ? urlQueryFromObject(payload.query) : '';
        yield call(API.post(), `/staff/students/${payload.studentId}/emails?${query}`, {
            subject: payload.data.subject,
            message: payload.data.message,
        });
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
