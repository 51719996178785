const Export = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 11C4.55228 11 5 11.4477 5 12L5 15.2C5 16.0565 5.00078 16.6388 5.03755 17.0889C5.07337 17.5273 5.1383 17.7515 5.21799 17.9079C5.40973 18.2843 5.71569 18.5902 6.09202 18.782C6.24842 18.8617 6.47262 18.9266 6.91104 18.9624C7.36113 18.9992 7.94342 19 8.8 19L15.2 19C16.0566 19 16.6389 18.9992 17.089 18.9624C17.5274 18.9266 17.7516 18.8617 17.908 18.782C18.2843 18.5902 18.5903 18.2843 18.782 17.908C18.8617 17.7516 18.9266 17.5273 18.9624 17.0889C18.9992 16.6388 19 16.0565 19 15.2L19 12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12L21 15.2413C21 16.0463 21 16.7106 20.9558 17.2518C20.9099 17.8139 20.8113 18.3306 20.564 18.8159C20.1805 19.5686 19.5686 20.1805 18.816 20.564C18.3306 20.8113 17.8139 20.9098 17.2518 20.9558C16.7106 21 16.0463 21 15.2413 21L8.75868 21C7.95372 21 7.28936 21 6.74817 20.9558C6.18608 20.9099 5.66937 20.8113 5.18404 20.564C4.43139 20.1805 3.81947 19.5686 3.43597 18.8159C3.18868 18.3306 3.09012 17.8139 3.04419 17.2518C2.99997 16.7106 2.99999 16.0462 3 15.2413L3 12C3 11.4477 3.44772 11 4 11Z"
            fill="#54585A"
        />
        <path
            d="M11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4L13 12.5858L14.2929 11.2929C14.6834 10.9024 15.3166 10.9024 15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L11 4Z"
            fill="#54585A"
        />
    </svg>
);

export default Export;
