import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { extendQrCode, getStudentAndTeacherQrCode, resetStudentAndTeacherQrCode } from '../actions';

export function* extendQrCodeSaga({ payload }: ReturnType<typeof extendQrCode>): SagaIterator {
    try {
        yield put(startLoading());
        yield put(resetStudentAndTeacherQrCode());

        yield call(API.put(), `/staff/invites/${payload.id}`, {
            expires: payload.expires,
        });

        yield put(
            getStudentAndTeacherQrCode({
                classId: payload.classId,
            }),
        );

        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
