import { Props } from '../types';

const StudentsIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        id="Students"
        xmlns="http://www.w3.org/2000/svg"
        width="23.911"
        height="23.911"
        viewBox="0 0 23.911 23.911"
    >
        <path
            id="Path_1232"
            data-name="Path 1232"
            d="M11.956,15.941a3.985,3.985,0,1,1,3.985-3.985A3.985,3.985,0,0,1,11.956,15.941Zm0-5.978a1.993,1.993,0,1,0,1.993,1.993A1.993,1.993,0,0,0,11.956,9.963Zm5.978,12.952a5.978,5.978,0,0,0-11.956,0,1,1,0,1,0,1.993,0,3.985,3.985,0,0,1,7.97,0,1,1,0,1,0,1.993,0Zm0-14.945a3.985,3.985,0,1,1,3.985-3.985A3.985,3.985,0,0,1,17.934,7.97Zm0-5.978a1.993,1.993,0,1,0,1.993,1.993A1.993,1.993,0,0,0,17.934,1.993Zm5.978,12.952a5.984,5.984,0,0,0-5.978-5.978,1,1,0,0,0,0,1.993,3.985,3.985,0,0,1,3.985,3.985,1,1,0,0,0,1.993,0ZM5.978,7.97A3.985,3.985,0,1,1,9.963,3.985,3.985,3.985,0,0,1,5.978,7.97Zm0-5.978A1.993,1.993,0,1,0,7.97,3.985,1.993,1.993,0,0,0,5.978,1.993ZM1.993,14.945a3.985,3.985,0,0,1,3.985-3.985,1,1,0,0,0,0-1.993A5.984,5.984,0,0,0,0,14.945a1,1,0,0,0,1.993,0Z"
            transform="translate(0 0)"
            fill={fill}
        />
    </svg>
);

export default StudentsIcon;
