import './styles.scss';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import InputRadioButtons from 'src/components/InputRadioButtons';
import { selectBooks } from 'src/redux/weeklyPlan/selectors';
import { restart } from 'src/redux/weeklyPlan/actions';
import { selectClass, selectInvites } from 'src/redux/classes/selectors';
import { createClass, getClasses, getInvites } from 'src/redux/classes/actions';
import { selectLoading } from 'src/redux/loading/selectors';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { ActiveClasses } from 'src/pages/Classes/constants';
import teacherWizardGif1 from 'src/assets/images/teacher_wizard_1.gif';
import teacherWizardGif2 from 'src/assets/images/teacher_wizard_2.gif';
import teacherWizardGif3 from 'src/assets/images/teacher_wizard_3.gif';
import teacherWizardJpeg2 from 'src/assets/images/teacher_wizard_2.jpeg';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import { RestartRow } from '../RestartClass';
import Arrow from '../../Arrow';

const CreateClassModal = ({
    open,
    setOpen,
    onDoneCallback,
}: {
    open: boolean;
    setOpen: any;
    onDoneCallback: () => void;
}) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [inputs, setInputs] = useState({} as any);
    const [nextStepDisabled, setNextStepDisabled] = useState(true);
    const classData = useSelector(selectClass);
    const invites = useSelector(selectInvites);
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const books = useSelector(selectBooks);
    const [book, setActiveBook] = useState(null as any);
    const [level, setActiveLevel] = useState(null as any);
    const [unit, setActiveUnit] = useState(null as any);
    const [qrCode, setQrCode] = useState({ student: '' } as any);
    const loading = useSelector(selectLoading);
    const body = document.querySelector(`body`);
    const queryParams = new URLSearchParams(window.location.search);
    const [firstOpen, setFirstOpen] = useState(false);
    const [showArrowButton, setShowArrowButton] = useState(true);
    const navigate = useNavigate();
    const [curated, setCurated] = useState(false);
    const { isModuleActive } = useIsModuleActive();

    const levelOptions = useMemo(() => {
        return books.find(({ name }: any) => name === book?.name)?.levels || [];
    }, [books, book]);

    const unitOptions = useMemo(() => {
        return levelOptions.find(({ name }: any) => name === level?.name)?.units || [];
    }, [books, level, book]);

    const getValue = (valueName: string) => {
        return inputs[valueName] ?? '';
    };

    const handleChange = ({ target: { name, value, required } }: any) => {
        if (value === '' && required) {
            setNextStepDisabled(true);
        } else {
            setNextStepDisabled(false);
        }
        setInputs((currentInputs: any) => ({ ...currentInputs, [name]: value }));
    };

    const onClose = () => {
        setOpen(false);
        setActiveBook(null);
        setActiveLevel(null);
        setActiveUnit(null);
        setQrCode({ student: '' });
        setStep(1);
        setInputs({});
        setShowArrowButton(true);
        dispatch(getClasses(ActiveClasses.OPEN));
        navigate('/');
        setCurated(false);
    };

    const stepDecrement = () => {
        const backStep = step - 1;
        if (backStep > 0) {
            setStep(backStep);
        } else {
            setOpen(false);
            navigate('/');
        }
    };

    const stepIncrement = () => {
        const forwardStep = step + 1;
        if (forwardStep < 5) {
            setStep(forwardStep);
        }
    };

    useOutsideClick(wrapperRef, () => {
        onClose();
    });

    useEffect(() => {
        if (queryParams.has('showWizard')) {
            queryParams.delete('showWizard');
            setFirstOpen(true);
        }
    }, [queryParams, open]);

    useEffect(() => {
        if (+invites.student.url !== null) {
            setQrCode({
                student: (
                    <QRCode
                        value={`${invites.student.url}`}
                        size={177}
                        viewBox="0 0 177 177"
                        style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                        }}
                    />
                ),
            });
        }
    }, [invites]);

    useEffect(() => {
        if (open) {
            (body as HTMLElement).style.overflow = 'hidden';
        } else {
            setFirstOpen(false);
        }
        return () => {
            (body as HTMLElement).style.overflow = 'auto';
        };
    }, [open]);

    if (!open) {
        return <></>;
    }
    return createPortal(
        <div className="get-st">
            <div className="get-st__modal" ref={wrapperRef}>
                <div className="get-st-step-switch">
                    {showArrowButton ? (
                        <Arrow
                            large
                            onClick={stepDecrement}
                            direction="left"
                            active={step - 1 >= 0}
                        />
                    ) : (
                        <div />
                    )}
                    <div className="get-st-step-switch__step">
                        {step} {t('general.of')} 4
                    </div>
                </div>
                {step === 1 && (
                    <>
                        <div className="get-st-1">
                            <div className="get-st-1__left">
                                {firstOpen && (
                                    <div className="get-st-1__title">
                                        {t('modals.createClass.getStarted')}
                                    </div>
                                )}
                                <div className="get-st-info">
                                    <div className="get-st-info__title">
                                        {t('modals.createClass.addAClass')}
                                    </div>
                                    <div className="get-st-info__desc">
                                        {t('modals.createClass.enterNameClass')}
                                    </div>
                                    {firstOpen && (
                                        <div className="get-st-info__desc">
                                            {t('modals.createClass.moreClassesLater')}{' '}
                                        </div>
                                    )}
                                </div>
                                <div className="get-st-fieldset">
                                    <div className="get-st-fieldset__title">
                                        {t('modals.createClass.className')}
                                        <span className="red">*</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Beginners class 1"
                                        className="get-st-fieldset__class-name"
                                        name="class-name"
                                        value={getValue('class-name')}
                                        onChange={(event) => handleChange(event)}
                                        required
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                            <div className="get-st-1__right">
                                {!loading && (
                                    <img
                                        src={teacherWizardGif1}
                                        className="get-sc-1__gif-block"
                                        alt="teacher instruction 1"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="get-st-footer">
                            <Button
                                disabled={nextStepDisabled}
                                onClick={() => {
                                    stepIncrement();
                                }}
                            >
                                {t('modals.createClass.addAClass')}
                            </Button>
                        </div>
                    </>
                )}
                {step === 2 && (
                    <>
                        <div className="get-st-2 step-2">
                            <div className="get-st-2__left">
                                <div className="get-st-info">
                                    <div className="get-st-info__title">
                                        {t('modals.createClass.optionalClassDetails')}{' '}
                                        <span className="main_color">{getValue('class-name')}</span>
                                    </div>
                                    <div className="get-st-info__desc">
                                        {t('modals.createClass.addTheseLater')}
                                    </div>
                                </div>

                                <div className="get-st-fieldset">
                                    <div className="get-st-fieldset__title">
                                        {t('general.level')}
                                    </div>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="class-level"
                                        value={getValue('class-level')}
                                        onChange={(event) => handleChange(event)}
                                        required
                                        maxLength={255}
                                    />
                                    <div className="get-st-fieldset__title">
                                        {t('general.room')}
                                    </div>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="class-room"
                                        value={getValue('class-room')}
                                        onChange={(event) => handleChange(event)}
                                        required
                                        maxLength={255}
                                    />
                                    <div className="get-st-fieldset__title">
                                        {t('modals.createClass.classType')}
                                    </div>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="class-type"
                                        value={getValue('class-type')}
                                        onChange={(event) => handleChange(event)}
                                        required
                                        maxLength={255}
                                    />
                                    <div className="get-st-fieldset__title">
                                        {t('general.teacher')}
                                    </div>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="class-teacher"
                                        value={getValue('class-teacher')}
                                        onChange={(event) => handleChange(event)}
                                        required
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                            <div className="get-st-2__right">
                                {!loading && (
                                    <img
                                        src={teacherWizardJpeg2}
                                        className="get-sc-2__gif-block"
                                        alt="teacher instruction"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="get-st-footer">
                            <div className="get-st-skip-button">
                                <Button
                                    type="square"
                                    onClick={() => {
                                        setShowArrowButton(false);
                                        stepIncrement();
                                        setNextStepDisabled(true);
                                        dispatch(
                                            createClass({
                                                name: getValue('class-name'),
                                            }),
                                        );
                                    }}
                                >
                                    {t('general.skip')}
                                </Button>
                            </div>
                            <Button
                                onClick={() => {
                                    setShowArrowButton(false);
                                    stepIncrement();
                                    setNextStepDisabled(true);
                                    dispatch(
                                        createClass({
                                            name: getValue('class-name'),
                                            level: getValue('class-level'),
                                            room: getValue('class-room'),
                                            type: getValue('class-type'),
                                            teacher: getValue('class-teacher'),
                                        }),
                                    );
                                }}
                            >
                                {t('general.next')}
                            </Button>
                        </div>
                    </>
                )}
                {step === 3 && (
                    <>
                        <div className="get-st-2">
                            <div className="get-st-2__left">
                                <div className="get-st-info">
                                    <div className="get-st-info__title">
                                        {t('general.chooseBookLevelUnit')}{' '}
                                        <span className="main_color">{getValue('class-name')}</span>
                                    </div>
                                    <div className="get-st-info__desc">
                                        {t('general.editableWeeklyPlan')}
                                    </div>
                                    <div className="get-st-info__desc">
                                        {t('general.notFollowingNoListedCoursebook')}
                                    </div>
                                </div>

                                <div className="get-st-fieldset">
                                    <RestartRow
                                        options={books}
                                        onSelect={(data: any) => {
                                            setNextStepDisabled(true);
                                            setActiveLevel(null);
                                            setActiveUnit(null);
                                            setActiveBook(data);
                                            setCurated(false);
                                        }}
                                        selected={book}
                                        title={t('modals.createClass.chooseBook')}
                                        name={t('modals.createClass.bookName')}
                                    />
                                    <RestartRow
                                        options={levelOptions}
                                        onSelect={(data: any) => {
                                            setNextStepDisabled(true);
                                            setActiveLevel(data);
                                            setActiveUnit(null);
                                            setCurated(false);
                                        }}
                                        selected={level}
                                        title={t('modals.createClass.chooseLevel')}
                                        name={t('modals.createClass.levelName')}
                                    />
                                    <RestartRow
                                        options={unitOptions}
                                        onSelect={(data: any) => {
                                            setActiveUnit(data);
                                            setNextStepDisabled(false);
                                            setCurated(false);
                                        }}
                                        selected={unit}
                                        title={t('modals.createClass.chooseUnit')}
                                        name={t('modals.createClass.unitName')}
                                    />
                                    {isModuleActive(Modules.WeeklyPlannerApplyTemplateCurated) && (
                                        <div
                                            className={classNames('restart-modal-row', {
                                                'restart-modal-row--disabled':
                                                    !unit || !unit.curated,
                                            })}
                                        >
                                            <span className="restart-modal-row-name">
                                                {t('modals.createClass.aims')}
                                            </span>
                                            <div>
                                                <InputRadioButtons
                                                    buttons={[
                                                        {
                                                            label: t('general.all'),
                                                            value: t('general.all'),
                                                        },
                                                        {
                                                            label: t('general.core'),
                                                            value: t('general.core'),
                                                        },
                                                    ]}
                                                    onChange={(button) => {
                                                        setCurated(
                                                            button.value === t('general.core'),
                                                        );
                                                    }}
                                                    checkedIndex={curated ? 1 : 0}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="get-st-2__right">
                                {!loading && (
                                    <img
                                        src={teacherWizardGif2}
                                        className="get-sc-2__gif-block"
                                        alt="teacher instruction 2"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="get-st-footer">
                            <Button
                                disabled={nextStepDisabled}
                                onClick={() => {
                                    stepIncrement();
                                    dispatch(
                                        getInvites({
                                            classId: classData.id,
                                            type: 'student',
                                        }),
                                    );
                                    dispatch(
                                        restart({
                                            week: moment(new Date()).isoWeek(),
                                            year: moment(new Date()).year(),
                                            unit: unit.id,
                                            classId: classData.id,
                                            fullSet: true,
                                            curated,
                                        }),
                                    );
                                }}
                            >
                                {t('general.next')}
                            </Button>
                        </div>
                    </>
                )}
                {step === 4 && (
                    <>
                        <div className="get-st-3">
                            <div className="get-st-3__left">
                                <div className="get-st-info">
                                    <div className="get-st-info__title">
                                        {t('modals.createClass.inviteStudent')}
                                    </div>
                                    <div className="get-st-info__desc">
                                        {t('modals.createClass.askScanQr')}
                                    </div>
                                </div>
                                <div className="get-st-qr">
                                    <div className="get-st-qr__parent">
                                        <div className="get-st-qr__img">{qrCode.student}</div>
                                        <div className="get-st-qr__code-subtitle">
                                            {t('modals.createClass.studentQr')}
                                        </div>
                                        <Button
                                            onClick={() => {
                                                window.open(
                                                    invites.student.poster.pdf,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                );
                                            }}
                                            type="secondary"
                                        >
                                            {t('modals.createClass.downloadPdf')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="get-st-3__right">
                                {!loading && (
                                    <img
                                        src={teacherWizardGif3}
                                        className="get-sc-3__gif-block"
                                        alt="teacher instruction 3"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="get-st-footer">
                            <Button
                                disabled={nextStepDisabled}
                                onClick={() => {
                                    onClose();
                                    onDoneCallback();
                                }}
                            >
                                {t('general.done')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>,
        document.getElementsByTagName('body')[0] as HTMLElement,
    );
};

export default CreateClassModal;
