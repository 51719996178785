import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { confirm2FACodeSaga } from 'src/redux/settings/sagas/confirm2FACodeSaga';
import { delete2FAPhoneNumberSaga } from 'src/redux/settings/sagas/delete2FAPhoneNumberSaga';
import { put2FAPhoneNumberSaga } from 'src/redux/settings/sagas/put2FAPhoneNumberSaga';
import {
    CONFIRM_2FA_CODE,
    DELETE_2FA_PHONE_NUMBER,
    PUT_2FA_PHONE_NUMBER,
    PUT_SETTINGS,
} from '../actions';
import { putSettingsSaga } from './putSettingsSaga';

export function* settingsWatcher(): SagaIterator {
    yield takeLatest(PUT_SETTINGS, putSettingsSaga);
    yield takeLatest(PUT_2FA_PHONE_NUMBER, put2FAPhoneNumberSaga);
    yield takeLatest(CONFIRM_2FA_CODE, confirm2FACodeSaga);
    yield takeLatest(DELETE_2FA_PHONE_NUMBER, delete2FAPhoneNumberSaga);
}
