import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmails } from './selectors';
import { getEmails } from './actions';
import { EmailType } from './types';

const STATIC_EMAILS = (t: any) =>
    [
        {
            key: 'emailToTeacher',
            name: t('staticEmails.messageTeachers'),
            url: '/messagecentre/welcomemessage/teachers/edit',
        },
        {
            key: 'emailToStudent',
            name: t('staticEmails.messageStudents'),
            url: '/messagecentre/welcomemessage/students/edit',
        },
        {
            key: 'placementTestEmail',
            name: t('staticEmails.studentsPlacementTest'),
            url: '/node/36914',
        },
        {
            key: 'placementTestEmailTwoWeeksBeforeStartDate',
            name: t('staticEmails.placementTest'),
            url: '/node/36915',
        },

        {
            key: 'placementTestEmailFridayBeforeStartDate',
            name: t('staticEmails.noPlacementTest'),
            url: '/node/36916',
        },
        {
            key: 'emailHeaderFooter',
            name: t('staticEmails.headerAndFooter'),
            url: '/messagecentre/emailheaderfooter/edit',
        },
    ] as EmailType[];

export function useGetEmails(t: any) {
    const selectedEmails = useSelector(selectEmails);
    const dispatch = useDispatch();
    const [emails, setEmails] = useState(STATIC_EMAILS(t));

    useEffect(() => {
        dispatch(getEmails());
    }, [dispatch]);

    useEffect(() => {
        setEmails([...STATIC_EMAILS(t), ...selectedEmails]);
    }, [selectedEmails]);

    return emails;
}
