import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd';
import classNames from 'classnames';
import moment from 'moment';
import Move from 'src/components/Icons/Move';
import SearchAlt from 'src/components/Icons/SearchAlt';
import ChartHistogram from 'src/components/Icons/ChartHistogram';
import Remove from 'src/components/Icons/Remove';
import { selectActivities } from 'src/redux/weeklyPlan/selectors';
import { selectSearchValue } from 'src/redux/search/selector';
import { handleResourceMove } from './helpers/handleResourceMove';
import { notInString } from '../../helpers/notInString';
import {
    getActivitiesHrefChartHistogram,
    getActivitiesHrefSearchAlt,
} from './helpers/getActivitiesHref';

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    if (!enabled) {
        return null;
    }

    return <Droppable {...props}>{children}</Droppable>;
};

export const ResourcesRender = ({
    setIsDirty,
    lessons,
    getUpdatedDays,
    columns,
    setDays,
    days,
    index,
    activeResourcesRows,
    week,
    year,
}: {
    setIsDirty: any;
    lessons: any;
    getUpdatedDays: any;
    columns: any;
    setDays: any;
    days: any;
    index: number;
    activeResourcesRows: any;
    week: number;
    year: number;
}) => {
    const activities = useSelector(selectActivities);
    const params = useParams();
    const searchValue = useSelector(selectSearchValue);

    const removeResource = (dayIndex: any, currentLessonId: any, linkId: any, linkType: any) => {
        getUpdatedDays(columns, (daysToUpdate: any) => {
            const newDays = JSON.parse(JSON.stringify(daysToUpdate));
            const currentLesson = newDays[dayIndex].lessons.filter(
                ({ lessonId }: { lessonId: any }) => lessonId === currentLessonId,
            )[0];
            currentLesson.activities = currentLesson.activities.filter((activity: any) => {
                return !(activity.id === linkId && activity.type === linkType);
            });
            setIsDirty(true);
            setDays(newDays);
        });
    };

    return (
        <div className="weeklyPlan-resources">
            {activeResourcesRows.size > 1 && index > 0 && <hr />}
            <DragDropContext
                onDragEnd={(result) => {
                    handleResourceMove({
                        result,
                        setIsDirty,
                        lessons,
                        getUpdatedDays,
                        columns,
                        setDays,
                    });
                }}
            >
                {days.map((day: any, dayIndex: number) => {
                    return (
                        <StrictModeDroppable droppableId={`${dayIndex}`} key={day.day}>
                            {(providedDroppable) => {
                                const activityIds: number[] = [];
                                const isActivityDuplicate = (id: number) => {
                                    let hide = false;
                                    if (activityIds.includes(id)) {
                                        hide = true;
                                    } else {
                                        activityIds.push(id);
                                    }
                                    return hide;
                                };
                                const timestamp = moment()
                                    .utc(true)
                                    .day(day.day)
                                    .isoWeekYear(year)
                                    .isoWeek(week)
                                    .unix();
                                return (
                                    <div
                                        key={day.day}
                                        className="weeklyPlan-droppable"
                                        {...(providedDroppable.droppableProps as any)}
                                        ref={providedDroppable.innerRef}
                                        style={{
                                            width: `calc(100% / ${lessons.length})`,
                                        }}
                                    >
                                        {day?.lessons?.map((lesson: any) => {
                                            return lesson.syllabus.map(
                                                ({ id }: any, syllabusIndex: any) => {
                                                    if (!activities.length) {
                                                        return null;
                                                    }
                                                    let hideActivity = false;

                                                    const currentActivity = activities.find(
                                                        (activity: any) => {
                                                            if (activity.id === id) {
                                                                hideActivity =
                                                                    isActivityDuplicate(id);
                                                                return true;
                                                            }
                                                            return false;
                                                        },
                                                    );
                                                    if (!currentActivity) {
                                                        return '';
                                                    }
                                                    return '';
                                                    return (
                                                        <Draggable
                                                            key={id}
                                                            draggableId={`${id}-${lesson.lessonId}-syllabus`}
                                                            index={syllabusIndex}
                                                        >
                                                            {(provided: any, snapshot: any) => {
                                                                return (
                                                                    <div
                                                                        className={classNames(
                                                                            'weeklyPlan-draggable',
                                                                            {
                                                                                'd-none':
                                                                                    hideActivity,
                                                                            },
                                                                        )}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div
                                                                            className={classNames(
                                                                                'weeklyPlan-draggable-controls',
                                                                                {
                                                                                    'weeklyPlan-draggable-controls--active':
                                                                                        snapshot.isDragging,
                                                                                },
                                                                            )}
                                                                        >
                                                                            <div
                                                                                {...provided.dragHandleProps}
                                                                                className="weeklyPlan-move grab weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                            >
                                                                                <Move />
                                                                            </div>
                                                                        </div>
                                                                        <div className="weeklyPlan-editable">
                                                                            {`Page ${
                                                                                currentActivity?.page
                                                                            } ${
                                                                                currentActivity?.activity?.includes(
                                                                                    '-',
                                                                                )
                                                                                    ? 'exercises'
                                                                                    : 'exercise'
                                                                            } ${
                                                                                currentActivity?.activity
                                                                            }`}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                },
                                            );
                                        })}
                                        {day?.lessons[index]?.activities?.map(
                                            (activity: any, activityIndex: number) => {
                                                return (
                                                    <Draggable
                                                        key={activityIndex}
                                                        draggableId={`${activityIndex}-${day.lessons[index].lessonId}-${activity.type}-${activity.id}`}
                                                        index={activityIndex}
                                                    >
                                                        {(provided: any, snapshot: any) => {
                                                            return (
                                                                <div
                                                                    className={classNames(
                                                                        'weeklyPlan-draggable',
                                                                        {
                                                                            'd-none': notInString(
                                                                                activity.name,
                                                                                searchValue,
                                                                            ),
                                                                        },
                                                                    )}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            'weeklyPlan-draggable-controls',
                                                                            {
                                                                                'weeklyPlan-draggable-controls--active':
                                                                                    snapshot.isDragging,
                                                                            },
                                                                        )}
                                                                    >
                                                                        <a
                                                                            rel="noreferrer"
                                                                            target="_blank"
                                                                            href={
                                                                                getActivitiesHrefSearchAlt(
                                                                                    {
                                                                                        activity,
                                                                                    },
                                                                                ) as string
                                                                            }
                                                                            className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                        >
                                                                            <SearchAlt />
                                                                        </a>

                                                                        <a
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            href={
                                                                                getActivitiesHrefChartHistogram(
                                                                                    {
                                                                                        activity,
                                                                                        classId:
                                                                                            params.id,
                                                                                        timestamp,
                                                                                    },
                                                                                ) as string
                                                                            }
                                                                            className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                        >
                                                                            <ChartHistogram />
                                                                        </a>
                                                                        <div
                                                                            {...provided.dragHandleProps}
                                                                            className="weeklyPlan-move grab weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                        >
                                                                            <Move />
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                removeResource(
                                                                                    dayIndex,
                                                                                    day.lessons[
                                                                                        index
                                                                                    ].lessonId,
                                                                                    activity.id,
                                                                                    activity.type,
                                                                                );
                                                                            }}
                                                                            className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                        >
                                                                            <Remove />
                                                                        </div>
                                                                    </div>
                                                                    <div className="weeklyPlan-editable">
                                                                        {activity?.name}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            },
                                        )}

                                        {providedDroppable.placeholder}
                                    </div>
                                );
                            }}
                        </StrictModeDroppable>
                    );
                })}
            </DragDropContext>
        </div>
    );
};
