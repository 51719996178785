import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useOutsideClick } from 'src/hooks/outsideClick';
import classNames from 'classnames';
import { DateSelectorProps } from './types';
import { getWeekDate } from '../../helpers/getWeekDate';
import CalendarIcon from '../Icons/CalendarIcon';
import 'react-calendar/dist/Calendar.css';
import CustomCalendar from '../CustomCalendar';

const DateSelector = ({
    weekNumber,
    year,
    onChange,
    hideControls = false,
    onClick,
    type = 'range',
    className,
}: DateSelectorProps) => {
    const [week, setWeek] = useState(+weekNumber);
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
        setOpen(false);
        if (onClick && open) {
            onClick();
        }
    });

    useEffect(() => {
        setWeek(weekNumber);
    }, [weekNumber]);

    useEffect(() => {
        onChange(week, year);
    }, []);

    const prevWeek = () => {
        const prevYearWeeksCount = moment(new Date(year - 1, 5)).isoWeeksInYear();
        const weekToBeSet = week - 1;
        if (weekToBeSet <= 0) {
            setWeek(prevYearWeeksCount);
            onChange(prevYearWeeksCount, year - 1);
        } else {
            setWeek(weekToBeSet);
            onChange(weekToBeSet, year);
        }
    };

    const nextWeek = () => {
        const currYearWeeksCount = moment(new Date(year, 6)).isoWeeksInYear();
        const weekToBeSet = week + 1;
        if (weekToBeSet > currYearWeeksCount) {
            setWeek(1);
            onChange(1, year + 1);
        } else {
            setWeek(weekToBeSet);
            onChange(weekToBeSet, year);
        }
    };
    return (
        <div className={classNames('dateSelector', { [`${className}`]: !!className })}>
            {!hideControls && (
                <div className="dateSelector-body sc_hover_color_text_svg">
                    <div className="dateSelector-arrow" data-testid="prevWeek" onClick={prevWeek}>
                        {'<'}
                    </div>
                    <span>{getWeekDate(week, year)}</span>
                    <div className="dateSelector-arrow" data-testid="nextWeek" onClick={nextWeek}>
                        {'>'}
                    </div>
                </div>
            )}
            <div ref={wrapperRef}>
                <div
                    className={classNames('dateSelector-icon pr_hover_color_text_svg_bg', {
                        'dateSelector-icon--active': open,
                    })}
                    onClick={() => {
                        setOpen(!open);
                        if (onClick) {
                            onClick();
                        }
                    }}
                >
                    <CalendarIcon />
                </div>
                {open && (
                    <div className="dateSelector-calendar">
                        <CustomCalendar
                            week={week}
                            year={year}
                            type={type}
                            onChangeReturnType="date"
                            onChange={(date: Date | Date[]) => {
                                if (type === 'rangeCustom') {
                                    onChange(0, 0, date as Date[]);
                                    return;
                                }
                                setWeek(moment(date as Date).isoWeek());
                                onChange(
                                    moment(date as Date).isoWeek(),
                                    moment(date as Date).year(),
                                );
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateSelector;
