import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getSettingsValidationErrorsSelector = (state: RootState): any =>
    state.settings.validationErrors;

export const selectSettingsValidationErrors = createSelector(
    createSelector(getSettingsValidationErrorsSelector, (report) => report),
    (report) => report,
);
