export const useScrollbar = () => {
    const scrollbarWidth = (element: any) => {
        if (!element) {
            return 0;
        }
        return element.offsetWidth - element.clientWidth;
    };

    return {
        scrollbarWidth,
    };
};
