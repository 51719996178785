import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { getDuplicatedStudents, mergeStudents } from '../actions';

export function* mergeStudentsSaga({ payload }: ReturnType<typeof mergeStudents>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.post(), `/staff/users/merge`, payload);
        yield put(getDuplicatedStudents({}));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
