import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getEndOfUtcDay } from 'src/helpers/getEndOfUtcDay';
import { getStartOfUtcDay } from 'src/helpers/getStartOfUtcDay';
import { assignAutomated, getAutomated } from 'src/redux/automated/actions';
import { selectAutomated } from 'src/redux/automated/selectors';
import { getTestsWithCurrentlySelected } from 'src/pages/OnlineTesting/helpers';
import Assigned from './Assigned';
import { AssignedTestProps, Props } from './types';
import Unassigned from '../Unassigned';

const AutomatedTests = ({ activeType, search }: Props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { tests } = useSelector(selectAutomated);
    const navigate = useNavigate();
    const state = useLocation().state as any;
    const [filteredTests, setFilteredTests] = useState<AssignedTestProps[]>([]);

    useEffect(() => {
        dispatch(getAutomated({ classId: params.id, type: activeType }));
    }, [dispatch, params, activeType]);

    useEffect(() => {
        setFilteredTests(getTestsWithCurrentlySelected(tests, state));
    }, [tests]);

    switch (activeType) {
        case 'Unassigned':
            return (
                <>
                    {filteredTests[0]?.name && (
                        <Unassigned
                            type="rangeCustom"
                            onClickReturn={(id) => {
                                return `/online-testing/automated-tests/${params.id}/${id}`;
                            }}
                            unAssigned={filteredTests.filter(({ name }: any) =>
                                name.toLowerCase().includes(search.toLowerCase()),
                            )}
                            onAssign={(days, testId) => {
                                dispatch(
                                    assignAutomated({
                                        from: getStartOfUtcDay(days[0]),
                                        to: getEndOfUtcDay(days[1]),
                                        classId: params.id,
                                        testId: testId as number,
                                        type: activeType,
                                    }),
                                );
                            }}
                        />
                    )}
                </>
            );
        default:
            if (filteredTests[0]?.testName) {
                return (
                    <Assigned
                        activeType={activeType}
                        assigned={filteredTests.filter(({ testName }) =>
                            testName.toLowerCase().includes(search.toLowerCase()),
                        )}
                    />
                );
            }
            return <Assigned assigned={filteredTests} activeType={activeType} />;
    }
};
export default AutomatedTests;
