import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { useTranslation } from 'react-i18next';
import { CategorySelectorProps } from './types';
import ArrowSelector from '../Icons/ArrowSelector';
import CheckSelector from '../Icons/CheckSelector';
import CrossSelector from '../Icons/CrossSelector';
import './styles.scss';

const CategorySelector = ({ options, onSelect, selected, name }: CategorySelectorProps) => {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    const { t } = useTranslation();
    useOutsideClick(wrapperRef, () => setOpen(false));

    return (
        <div
            className={classNames('categorySelector', {
                'categorySelector--active': open || (selected && selected !== t('general.all')),
            })}
            onClick={() => setOpen(!open)}
            ref={wrapperRef}
            data-testid="selectorTest"
        >
            <div
                className={classNames('selector-selected selector-new-selected', {
                    'selector-selected--active': open,
                    'selector-selected--down': !open,
                })}
            >
                <span>
                    {name}
                    {selected !== t('general.all') && selected ? `: ${selected}` : ''}
                </span>
                {selected !== t('general.all') && selected ? (
                    <div
                        onClick={(e) => {
                            onSelect('');
                            setOpen(false);
                            e.stopPropagation();
                        }}
                    >
                        <CrossSelector />
                    </div>
                ) : (
                    <div>
                        <ArrowSelector />
                    </div>
                )}
            </div>
            {open && (
                <div className="selector-new-options">
                    {[t('general.all'), ...options].map((option, idx: number) => {
                        return (
                            <div
                                key={idx}
                                className="selector-new-option"
                                onClick={() => {
                                    onSelect(option);
                                }}
                                data-testid={`selectorTestItem_${idx}`}
                            >
                                <span>{option}</span>
                                {selected === option && <CheckSelector />}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default CategorySelector;
