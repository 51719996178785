import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectClasses } from 'src/redux/classes/selectors';
import SelectMenu from 'src/components/SelectMenu';
import InputCalendar from 'src/components/InputCalendar';
import Button from 'src/components/Button';
import '../styles.scss';
import { getClassesByParams } from 'src/redux/classes/actions';

const ProfileEditAssignClass = ({
    classesForDropdownGid,
    open,
    setOpen,
    inputProcessor,
}: {
    open: any;
    setOpen: any;
    inputProcessor: any;
    classesForDropdownGid: any;
}) => {
    const { t } = useTranslation();
    const classesForDropdown = useSelector(selectClasses);
    const dispatch = useDispatch();
    const [selectedClass, setSelectedClass] = useState({
        id: 0,
        from: moment(new Date()).unix(),
        to: moment(new Date()).add(2, 'day').unix(),
        name: 'Select class',
    } as any);
    const [invalid, setInvalid] = useState(null as any);
    const [dateInvalid, setDateInvalid] = useState(null as any);
    const saveNewClass = (classForSave: any) => {
        const tempClasses = structuredClone(inputProcessor.getValue('classes'));
        tempClasses.push(classForSave);
        inputProcessor.updateInput({ classes: tempClasses });
    };

    useEffect(() => {
        if (open && classesForDropdownGid) {
            setSelectedClass({
                id: 0,
                from: moment(new Date()).unix(),
                to: moment(new Date()).add(2, 'day').unix(),
                name: 'Select class',
            });
            dispatch(getClassesByParams({ data: { gid: classesForDropdownGid } }));
        }
    }, [classesForDropdownGid]);

    return (
        <>
            <div className="profileEdit-change-class">
                <span>
                    {t('profile.assignNewClass')}
                    <hr />
                </span>
                <div className="profileEdit-change-class__dropdown assign">
                    <span>{t('profile.newClass')}</span>
                    <SelectMenu
                        invalid={invalid}
                        alwaysWide
                        wrapperClass="type_3"
                        options={classesForDropdown.map((item: any) => {
                            return {
                                content: (
                                    <div title={item.name}>
                                        <span>
                                            {item.name} - {item.open ? 'Open' : 'Closed'}
                                        </span>
                                    </div>
                                ),
                                onClick: () => {
                                    setInvalid(false);
                                    setSelectedClass({
                                        ...selectedClass,
                                        id: item.id,
                                        name: item.name,
                                    });
                                },
                            };
                        })}
                        text={selectedClass.name}
                    />
                </div>
                <div className="profileEdit-change-class__calendar assign">
                    <span>{t('profile.startDate')}</span>
                    <InputCalendar
                        onChange={(e: any) => {
                            const from = moment(e).unix();
                            setSelectedClass({ ...selectedClass, from });
                            if (from > selectedClass.to) {
                                setSelectedClass({
                                    ...selectedClass,
                                    from,
                                    to: moment(e).add(2, 'day').unix(),
                                });
                            } else {
                                setSelectedClass({ ...selectedClass, from });
                            }
                        }}
                        date={new Date(selectedClass.from * 1000)}
                    />
                </div>
                <div className="profileEdit-change-class__calendar assign">
                    <span>{t('profile.endDate')}</span>
                    <InputCalendar
                        invalid={dateInvalid}
                        minDate={new Date(moment.unix(selectedClass.from).add(2, 'day').format())}
                        onChange={(e: any) => {
                            setDateInvalid(false);
                            setSelectedClass({ ...selectedClass, to: moment(e).unix() });
                        }}
                        date={new Date(selectedClass.to * 1000)}
                    />
                </div>
                <div className="profileEdit-change-class__buttons assign">
                    <div />
                    <div className="profileEdit-change-class__buttons">
                        <Button
                            minWidth={50}
                            onClick={() => {
                                let errors = false;
                                if (selectedClass.from > selectedClass.to) {
                                    errors = true;
                                    setDateInvalid(true);
                                }
                                if (selectedClass.id === 0) {
                                    errors = true;
                                    setInvalid(true);
                                }
                                if (!errors) {
                                    saveNewClass(selectedClass);
                                    setOpen(null);
                                }
                            }}
                            type="secondary"
                        >
                            {t('profile.assign')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(null);
                            }}
                            type="secondary"
                        >
                            {t('profile.cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileEditAssignClass;
