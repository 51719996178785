import { Props } from '../types';

const WarningIcon = ({ fill = '#d30000' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="49.5" height="43.324" viewBox="0 0 49.5 43.324">
        <path
            id="triangle-warning-2"
            d="M47.632,30.578,30.955,4.241A7.555,7.555,0,0,0,18.508,4.3L1.914,30.536A9.122,9.122,0,0,0,.882,39.948c1.445,2.786,4.479,4.376,8.277,4.376H40.367c3.818,0,6.832-1.589,8.277-4.376a9.176,9.176,0,0,0-1.011-9.371ZM22.7,13.384a2.064,2.064,0,1,1,4.128,0V25.768a2.064,2.064,0,1,1-4.128,0Zm2.064,24.768a3.1,3.1,0,1,1,3.1-3.1A3.092,3.092,0,0,1,24.762,38.152Z"
            transform="translate(-0.005 -1)"
            fill={fill}
        />
    </svg>
);

export default WarningIcon;
