const Bold = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
        <text
            id="B"
            transform="translate(5 13)"
            fill="#54585a"
            fontSize="14"
            fontFamily="Raleway-Bold, Raleway"
            fontWeight="700"
        >
            <tspan x="-4" y="0">
                B
            </tspan>
        </text>
    </svg>
);

export default Bold;
