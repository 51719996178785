import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { putSidebar } from 'src/redux/layout/actions';
import { selectLayout } from 'src/redux/layout/selectors';
import { selectClasses } from 'src/redux/classes/selectors';
import { deleteQrCode, getQrCode, getQrCodes } from 'src/redux/qrCodes/actions';
import { selectQrCodes } from 'src/redux/qrCodes/selectors';
import { getClasses } from 'src/redux/classes/actions';
import WhiteBox from 'src/components/WhiteBox';
import Search from 'src/components/Search';
import Table from 'src/components/Table';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import InviteStudentButtonModal from 'src/components/InviteStudentButtonModal';
import { ActiveClasses } from '../Classes/constants';
import './styles.scss';

const columns = (t: (key: string) => string) => [
    {
        title: t('qrCodes.code'),
        width: '7.75rem',
    },
    {
        title: t('qrCodes.urlForCode'),
        width: '6.25rem',
    },
    {
        title: t('qrCodes.creationDate'),
        width: '6.5rem',
    },
    {
        title: t('qrCodes.className'),
        width: '11rem',
    },
    {
        title: t('qrCodes.userWhoGeneratedTheCode'),
        width: '11rem',
    },
    {
        title: t('qrCodes.usageCount'),
        width: '6rem',
    },
    {
        title: t('qrCodes.expiryDate'),
        width: '6rem',
    },
    {
        title: t('qrCodes.generatePDF'),
        width: '4.125rem',
    },
];

const InvalidateQRCodePopup = ({
    open = true,
    close,
    code,
}: {
    open: boolean;
    close: () => void;
    code: string;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    if (!open) {
        return null;
    }

    const handleSave = () => {
        dispatch(deleteQrCode({ id: code, cb: () => close() }));
    };

    return (
        <Modal open={open} onClose={close} noPadding>
            <div className="qrcodes-popup">
                <div className="qrcodes-popup-head">{t('qrCodes.invalidate')}</div>
                <div className="qrcodes-popup-content">
                    {t('qrCodes.invalidateConfirmation')}{' '}
                    <b>
                        {t('qrCodes.code').toLowerCase()} {code}
                    </b>
                    ?<br />
                    {t('general.pleaseConfirm')}.
                </div>
                <div className="qrcodes-popup-footer">
                    <Button type="outline-grey" onClick={() => close()} minWidth={80}>
                        {t('general.cancel')}
                    </Button>
                    <Button maxWidth={80} type="secondary" onClick={() => handleSave()}>
                        {t('general.OK')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const QrCodes = () => {
    const [search, setSearch] = useState('');
    const [invalidateCode, setInvalidateCode] = useState<string | null>(null);
    const classesData = useSelector(selectClasses);
    const dispatch = useDispatch();
    const params = useParams();
    const qrCodesData = useSelector(selectQrCodes);
    const layout = useSelector(selectLayout);
    const { t } = useTranslation();

    const qrCodes = useMemo(() => {
        return qrCodesData.filter(({ id }) => {
            return id.toLowerCase().includes(search.toLowerCase());
        });
    }, [qrCodesData, search]);

    useEffect(() => {
        if (!classesData.length) {
            dispatch(getClasses(ActiveClasses.OPEN));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        dispatch(getQrCodes());
    }, []);

    const handleCopyUrl = (id: string) => {
        const matched = qrCodesData.find((code) => code.id === id);

        if (matched?.url) {
            navigator.clipboard.writeText(matched.url);
        } else {
            dispatch(
                getQrCode({
                    id,
                    cb: (qrCode) => {
                        if (qrCode.url) {
                            navigator.clipboard.writeText(qrCode.url);
                        }
                    },
                }),
            );
        }
    };

    const handleDownloadPdf = (id: string) => {
        const matched = qrCodesData.find((code) => code.id === id);

        if (matched?.poster?.pdf) {
            window.open(matched?.poster?.pdf, '_blank', 'noopener,noreferrer');
        } else {
            dispatch(
                getQrCode({
                    id,
                    cb: (qrCode) => {
                        if (qrCode?.poster?.pdf) {
                            const a = document.createElement('a');
                            a.setAttribute('href', qrCode.poster.pdf);
                            a.setAttribute('target', '_blank');
                            setTimeout(() => {
                                a.click();
                            }, 0);
                        }
                    },
                }),
            );
        }
    };

    return (
        <>
            <WhiteBox>
                <div className="qrcodes-header">
                    <Search onChange={(text: string) => setSearch(text)} />
                    <InviteStudentButtonModal
                        page="QRCodes"
                        withPreSessionalClass={layout.layout.school['pre-sessional']}
                    />
                </div>
                <div className="qrcodes-header-mobile">
                    <Search onChange={(text: string) => setSearch(text)} />
                    <InviteStudentButtonModal
                        page="QRCodes"
                        withPreSessionalClass={layout.layout.school['pre-sessional']}
                    />
                </div>
                <Table
                    header={
                        <>
                            {columns(t).map(({ title, width }, index) => (
                                <div
                                    key={`${index}_${title}`}
                                    style={{
                                        minWidth: width,
                                        maxWidth: width,
                                    }}
                                >
                                    {title}
                                </div>
                            ))}
                            <div style={{ width: '5rem' }} />
                        </>
                    }
                    rows={qrCodes.map(({ id, created, expires, creator, usedCount, class: cl }) => (
                        <>
                            <div
                                style={{
                                    minWidth: columns(t)[0].width,
                                    maxWidth: columns(t)[0].width,
                                }}
                            >
                                {id}
                            </div>
                            <div
                                className="color-main cursor-pointer"
                                onClick={() => handleCopyUrl(id)}
                                style={{
                                    minWidth: columns(t)[1].width,
                                    maxWidth: columns(t)[1].width,
                                }}
                            >
                                {t('qrCodes.copyURL')}
                            </div>
                            <div
                                style={{
                                    minWidth: columns(t)[2].width,
                                    maxWidth: columns(t)[2].width,
                                }}
                            >
                                {moment.unix(created).format('DD MMM YYYY')}
                            </div>
                            <div
                                style={{
                                    minWidth: columns(t)[3].width,
                                    maxWidth: columns(t)[3].width,
                                }}
                            >
                                {cl !== null ? cl.name : t('qrCodes.preSessional')}
                            </div>
                            <div
                                style={{
                                    minWidth: columns(t)[4].width,
                                    maxWidth: columns(t)[4].width,
                                }}
                            >
                                {creator?.name}
                            </div>
                            <div
                                style={{
                                    minWidth: columns(t)[5].width,
                                    maxWidth: columns(t)[5].width,
                                }}
                            >
                                {usedCount}
                            </div>
                            <div
                                style={{
                                    minWidth: columns(t)[6].width,
                                    maxWidth: columns(t)[6].width,
                                }}
                            >
                                {moment.unix(expires).format('DD MMM YYYY')}
                            </div>
                            <div
                                className="color-main cursor-pointer"
                                onClick={() => handleDownloadPdf(id)}
                                style={{
                                    minWidth: columns(t)[7].width,
                                    maxWidth: columns(t)[7].width,
                                }}
                            >
                                {t('qrCodes.PDF')}
                            </div>
                            <div
                                className="color-main cursor-pointer"
                                style={{ width: '5rem' }}
                                onClick={() => setInvalidateCode(id)}
                            >
                                {t('qrCodes.invalidate').toUpperCase()}
                            </div>
                        </>
                    ))}
                    headerMobile={<span>{t('qrCodes.code')}</span>}
                    rowsMobile={qrCodes.map(
                        ({ id, created, expires, creator, usedCount, class: cl }) => ({
                            head: (
                                <div className="qrcodes-table-mobile-row-head color-main">{id}</div>
                            ),
                            content: (
                                <div className="qrcodes-table-mobile-row-content">
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div
                                            className="qrcodes-table-mobile-row-content-item-title"
                                            onClick={() => handleCopyUrl(id)}
                                        >
                                            <b>{t('qrCodes.urlForCode')}</b>
                                        </div>
                                        <div
                                            className="qrcodes-table-mobile-row-content-item-action color-main cursor-pointer"
                                            onClick={() => handleCopyUrl(id)}
                                        >
                                            {t('qrCodes.copyURL')}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('qrCodes.creationDate')}</b>
                                        </div>
                                        <div className="qrcodes-table-mobile-row-content-item-action">
                                            {moment.unix(created).format('DD MMM YYYY')}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('qrCodes.className')}</b>
                                        </div>
                                        <div className="qrcodes-table-mobile-row-content-item-action">
                                            {cl !== null ? cl.name : t('qrCodes.preSessional')}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('qrCodes.userWhoGeneratedTheCode')}</b>
                                        </div>
                                        <div className="qrcodes-table-mobile-row-content-item-action">
                                            {creator?.name}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('qrCodes.usageCount')}</b>
                                        </div>
                                        <div className="qrcodes-table-mobile-row-content-item-action">
                                            {usedCount}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('qrCodes.expiryDate')}</b>
                                        </div>
                                        <div className="qrcodes-table-mobile-row-content-item-action">
                                            {moment.unix(expires).format('DD MMM YYYY')}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title cursor-pointer">
                                            <b>{t('qrCodes.generatePDF')}</b>
                                        </div>
                                        <div
                                            className="qrcodes-table-mobile-row-content-item-action color-main cursor-pointer"
                                            onClick={() => handleDownloadPdf(id)}
                                        >
                                            {t('qrCodes.PDF')}
                                        </div>
                                    </div>
                                    <div className="qrcodes-table-mobile-row-content-item">
                                        <div className="qrcodes-table-mobile-row-content-item-title">
                                            <b>{t('general.notes')}</b>
                                        </div>
                                        <div
                                            className="qrcodes-table-mobile-row-content-item-action color-main cursor-pointer"
                                            onClick={() => setInvalidateCode(id)}
                                        >
                                            {t('qrCodes.invalidate').toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                            ),
                        }),
                    )}
                />
            </WhiteBox>
            <InvalidateQRCodePopup
                open={!!invalidateCode}
                close={() => setInvalidateCode(null)}
                code={invalidateCode || ''}
            />
        </>
    );
};
export default QrCodes;
