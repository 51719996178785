import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PutAllToDoAP, PutToDoReferencesAP } from './types';

export const getAllToDoSelector = (state: RootState): PutAllToDoAP => state.toDo.toDo;

export const selectAllToDo = createSelector(
    createSelector(getAllToDoSelector, (item) => item),
    (item) => item,
);

export const getToDoReferences = (state: RootState): PutToDoReferencesAP =>
    state.toDo.toDoReferences;

export const selectToDoReferences = createSelector(
    createSelector(getToDoReferences, (item) => item),
    (item) => item,
);
