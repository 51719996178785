import { Props } from '../types';

const Checked = ({ fill = '#007fa3' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g id="Checked" transform="translate(234.665 234.665)">
            <g id="Group_497" data-name="Group 497" transform="translate(-234.665 -234.665)">
                <path
                    id="Path_980"
                    data-name="Path 980"
                    d="M10.909,0H1.091A1.092,1.092,0,0,0,0,1.091v9.818A1.092,1.092,0,0,0,1.091,12h9.818A1.092,1.092,0,0,0,12,10.909V1.091A1.092,1.092,0,0,0,10.909,0Zm-.9,3.851L5.295,8.567a.545.545,0,0,1-.771,0L1.989,6.033a.273.273,0,0,1,0-.386l.386-.386a.273.273,0,0,1,.386,0L4.909,7.41,9.24,3.08a.273.273,0,0,1,.386,0l.386.386A.273.273,0,0,1,10.011,3.851Z"
                    fill={fill}
                />
                <path
                    id="Path_981"
                    data-name="Path 981"
                    d="M83.023,118.125l-4.867,4.394a.606.606,0,0,1-.8,0l-2.615-2.361a.237.237,0,0,1,0-.359l.4-.359a.3.3,0,0,1,.4,0l2.217,2,4.469-4.034a.3.3,0,0,1,.4,0l.4.359A.237.237,0,0,1,83.023,118.125Z"
                    transform="translate(-72.884 -114.145)"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
);

export default Checked;
