export const successCheckboxLottie = {
    v: '5.5.7',
    meta: {
        g: 'LottieFiles AE 0.1.20',
        a: 'waqar',
        k: 'check animaion',
        d: 'ali',
        tc: '#FFFFFF',
    },
    fr: 29.9700012207031,
    ip: 0,
    op: 120.0000048877,
    w: 512,
    h: 512,
    nm: 'check Mark',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'tik',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [256, 256, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [0, 0, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-61.75, 7.75],
                                        [-22.75, 45.25],
                                        [65.25, -42.25],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.792156862745098, 0, 1],
                                ix: 3,
                            },
                            o: {
                                a: 0,
                                k: 100,
                                ix: 4,
                            },
                            w: {
                                a: 0,
                                k: 24,
                                ix: 5,
                            },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 0,
                                k: [0, 0],
                                ix: 2,
                            },
                            a: {
                                a: 0,
                                k: [0, 0],
                                ix: 1,
                            },
                            s: {
                                a: 0,
                                k: [100, 100],
                                ix: 3,
                            },
                            r: {
                                a: 0,
                                k: 0,
                                ix: 6,
                            },
                            o: {
                                a: 0,
                                k: 100,
                                ix: 7,
                            },
                            sk: {
                                a: 0,
                                k: 0,
                                ix: 4,
                            },
                            sa: {
                                a: 0,
                                k: 0,
                                ix: 5,
                            },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.4],
                                    y: [1],
                                },
                                o: {
                                    x: [0.6],
                                    y: [0],
                                },
                                t: 25,
                                s: [0],
                            },
                            {
                                t: 45.0000018328876,
                                s: [100],
                            },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.4],
                                    y: [1],
                                },
                                o: {
                                    x: [0.6],
                                    y: [0],
                                },
                                t: 60,
                                s: [0],
                            },
                            {
                                t: 75.0000030548126,
                                s: [100],
                            },
                        ],
                        ix: 2,
                    },
                    o: {
                        a: 0,
                        k: 0,
                        ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 120.0000048877,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'circle',
            sr: 1,
            ks: {
                o: {
                    a: 0,
                    k: 100,
                    ix: 11,
                },
                r: {
                    a: 0,
                    k: 0,
                    ix: 10,
                },
                p: {
                    a: 0,
                    k: [256, 256, 0],
                    ix: 2,
                },
                a: {
                    a: 0,
                    k: [-3, 1, 0],
                    ix: 1,
                },
                s: {
                    a: 0,
                    k: [100, 100, 100],
                    ix: 6,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: {
                                a: 0,
                                k: [250, 250],
                                ix: 2,
                            },
                            p: {
                                a: 0,
                                k: [0, 0],
                                ix: 3,
                            },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.792156862745098, 0, 1],
                                ix: 3,
                            },
                            o: {
                                a: 0,
                                k: 100,
                                ix: 4,
                            },
                            w: {
                                a: 0,
                                k: 24,
                                ix: 5,
                            },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 0,
                                k: [-3, 1],
                                ix: 2,
                            },
                            a: {
                                a: 0,
                                k: [0, 0],
                                ix: 1,
                            },
                            s: {
                                a: 0,
                                k: [100, 100],
                                ix: 3,
                            },
                            r: {
                                a: 0,
                                k: 0,
                                ix: 6,
                            },
                            o: {
                                a: 0,
                                k: 100,
                                ix: 7,
                            },
                            sk: {
                                a: 0,
                                k: 0,
                                ix: 4,
                            },
                            sa: {
                                a: 0,
                                k: 0,
                                ix: 5,
                            },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.4],
                                    y: [1],
                                },
                                o: {
                                    x: [0.6],
                                    y: [0],
                                },
                                t: 70,
                                s: [0],
                            },
                            {
                                t: 95.0000038694293,
                                s: [100],
                            },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.4],
                                    y: [1],
                                },
                                o: {
                                    x: [0.6],
                                    y: [0],
                                },
                                t: 5,
                                s: [0],
                            },
                            {
                                t: 30.0000012219251,
                                s: [100],
                            },
                        ],
                        ix: 2,
                    },
                    o: {
                        a: 0,
                        k: 0.1,
                        ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 120.0000048877,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
