import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { QrCode } from './types';

export const getQrCodesSelector = (state: RootState): QrCode[] => state.qrCodes.qrCodes;

export const selectQrCodes = createSelector(
    createSelector(getQrCodesSelector, (qrCodes) => qrCodes),
    (qrCodes) => qrCodes,
);

export const getStudentAndTeacherQrCodeSelector = (
    state: RootState,
): {
    student: QrCode;
    teacher: QrCode;
} => state.qrCodes.studentAndTeacherQrCode;

export const selectStudentAndTeacherQrCode = createSelector(
    createSelector(
        getStudentAndTeacherQrCodeSelector,
        (studentAndTeacherQrCode) => studentAndTeacherQrCode,
    ),
    (studentAndTeacherQrCode) => studentAndTeacherQrCode,
);
