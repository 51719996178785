import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { getEndOfUtcDay } from 'src/helpers/getEndOfUtcDay';
import { getStartOfUtcDay } from 'src/helpers/getStartOfUtcDay';
import { assignProgress, getProgress } from 'src/redux/progress/actions';
import { selectProgress } from 'src/redux/progress/selectors';
import { GetProgressProps } from 'src/redux/progress/types';
import { getTestsWithCurrentlySelected } from 'src/pages/OnlineTesting/helpers';
import { ProgressTestType } from 'src/redux/progress/reducer';
import { appConstants } from 'src/constants';
import Assigned from './Assigned';
import Unassigned from '../Unassigned';
import { AssignedTestProps } from '../AutomatedTests/types';

type ProgressTestProps = {
    activeType: GetProgressProps['type'];
    search: string;
    activeLevel: string;
};
const ProgressTest = ({ activeType, search, activeLevel }: ProgressTestProps) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { tests } = useSelector(selectProgress);
    const [filteredTests, setFilteredTests] = useState<ProgressTestType[]>([]);
    const navigate = useNavigate();
    const state = useLocation().state as any;

    useEffect(() => {
        dispatch(getProgress({ classId: params.id, type: activeType }));
    }, [dispatch, params, activeType]);

    useEffect(() => {
        setFilteredTests(
            getTestsWithCurrentlySelected(tests as never as AssignedTestProps[], state),
        );
    }, [tests]);

    useEffect(() => {
        if (activeLevel === 'All') {
            setFilteredTests(tests);
        } else if (activeLevel) {
            const currentTests = tests.filter((item) => {
                return item.level === activeLevel;
            });
            setFilteredTests(currentTests);
        }
    }, [activeLevel]);

    if (!tests) {
        return <Assigned />;
    }

    switch (activeType) {
        case 'Unassigned':
            return (
                <Unassigned
                    unAssigned={filteredTests.filter(({ name }) =>
                        name.toLowerCase().includes(search.toLowerCase()),
                    )}
                    onClickReturn={(testId: string) => {
                        return `${appConstants.server}/progress_test/testdisplay/demo/${testId}`;
                    }}
                    type="rangeCustom"
                    onAssign={(date, testId: string) => {
                        dispatch(
                            assignProgress({
                                classId: params.id,
                                from: getStartOfUtcDay(date[0]),
                                to: getEndOfUtcDay(date[1]),
                                testId,
                                type: activeType,
                            }),
                        );
                    }}
                />
            );
        default:
            return (
                <Assigned
                    activeType={activeType}
                    assigned={filteredTests.filter(({ name }) =>
                        name.toLowerCase().includes(search.toLowerCase()),
                    )}
                />
            );
    }
};
export default ProgressTest;
