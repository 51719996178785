import { Props } from '../types';

const SettingsGearIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.695" height="23" viewBox="0 0 20.695 23">
        <g id="settings" transform="translate(-1.203)">
            <path
                id="Path_26500"
                data-name="Path 26500"
                d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"
                transform="translate(-0.45 -0.5)"
                fill={fill}
            />
            <path
                id="Path_26501"
                data-name="Path 26501"
                d="M20.457,13.321l-.425-.245a8.721,8.721,0,0,0,0-3.153l.425-.245a2.877,2.877,0,1,0-2.875-4.983l-.426.246a8.6,8.6,0,0,0-2.73-1.574V2.875a2.875,2.875,0,1,0-5.75,0v.492A8.6,8.6,0,0,0,5.946,4.944L5.517,4.7A2.877,2.877,0,1,0,2.642,9.679l.425.245a8.721,8.721,0,0,0,0,3.153l-.426.245a2.877,2.877,0,1,0,2.875,4.983l.426-.246a8.6,8.6,0,0,0,2.731,1.574v.492a2.875,2.875,0,0,0,5.75,0v-.492a8.6,8.6,0,0,0,2.729-1.577l.428.247a2.877,2.877,0,1,0,2.875-4.983ZM18.015,9.7a6.754,6.754,0,0,1,0,3.594.958.958,0,0,0,.445,1.086l1.039.6a.959.959,0,1,1-.958,1.661l-1.041-.6a.958.958,0,0,0-1.164.158A6.693,6.693,0,0,1,13.227,18a.958.958,0,0,0-.72.929v1.2a.958.958,0,0,1-1.917,0v-1.2A.958.958,0,0,0,9.871,18a6.693,6.693,0,0,1-3.107-1.8A.958.958,0,0,0,5.6,16.038l-1.039.6a.958.958,0,1,1-.958-1.66l1.039-.6a.958.958,0,0,0,.445-1.086,6.754,6.754,0,0,1,0-3.594.958.958,0,0,0-.446-1.082L3.6,8.017A.959.959,0,0,1,4.56,6.357l1.041.6A.958.958,0,0,0,6.764,6.8a6.693,6.693,0,0,1,3.108-1.8.958.958,0,0,0,.72-.932v-1.2a.958.958,0,0,1,1.917,0v1.2a.958.958,0,0,0,.72.929,6.693,6.693,0,0,1,3.108,1.8.958.958,0,0,0,1.164.158l1.039-.6a.958.958,0,1,1,.958,1.66l-1.039.6A.958.958,0,0,0,18.015,9.7Z"
                fill={fill}
            />
        </g>
    </svg>
);

export default SettingsGearIcon;
