const CircleCheckGradientIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_826_15329)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.17719 11.2464L9.91869 13.9114L16.8228 7.2L18 8.34432L9.91869 16.2L6 12.3907L7.17719 11.2464Z"
                fill="url(#paint0_linear_826_15329)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_826_15329"
                x1="3.80625"
                y1="7.92222"
                x2="25.1369"
                y2="13.2057"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#075985" />
                <stop offset="1" stopColor="#0284C7" />
            </linearGradient>
            <clipPath id="clip0_826_15329">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CircleCheckGradientIcon;
