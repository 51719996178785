import { Props } from '../types';

const CircleCheck = ({ fill = '#007E4C' }: Props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM6.74163 12.575L3.74996 9.58329C3.42496 9.25829 3.42496 8.73329 3.74996 8.40829C4.07496 8.08329 4.59996 8.08329 4.92496 8.40829L7.33329 10.8083L13.0666 5.07496C13.3916 4.74996 13.9166 4.74996 14.2416 5.07496C14.5666 5.39996 14.5666 5.92496 14.2416 6.24996L7.91663 12.575C7.59996 12.9 7.06663 12.9 6.74163 12.575Z"
            fill={fill}
        />
    </svg>
);

export default CircleCheck;
