import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { selectElernings } from 'src/redux/weeklyPlan/selectors';
import { selectClass } from 'src/redux/classes/selectors';
import Button from 'src/components/Button';
import CheckedType2 from 'src/components/Icons/Checked-type-2';
import Tooltip from 'src/components/Tooltip';
import { TooltipId } from 'src/components/Tooltip/types';
import ELearningInfoData from 'src/components/ELearningInfoData';
import ELearningDescription from 'src/components/ELearningDescription';
import { ElearningProps } from './types';
import './styles.scss';

const ELearning = ({ setUpdateLesson, updateLesson }: ElearningProps) => {
    const { t } = useTranslation();
    const eLearning = useSelector(selectElernings);
    const selectedClass = useSelector(selectClass);
    const [num, setNum] = useState(0);
    const arrowLeftActive = num >= 1;
    const arrowRightActive = num < eLearning.length - 1;

    if (!eLearning[num]) {
        return null;
    }

    const isCurrentLessonAdded = () => {
        return updateLesson?.activities?.map(({ id }: any) => id).includes(eLearning[num].id);
    };

    return (
        <div className="elearning">
            <div className="elearning-header">
                <div className="elearning-activity">
                    {t('homework.eLearning.activity')} {num + 1} {t('homework.eLearning.of')}{' '}
                    {eLearning.length}
                </div>
                <div className="elearning-arrows">
                    <div
                        className={cn('elearning-arrow', {
                            'pr_hover_color_text_svg_bg cursor-pointer': arrowLeftActive,
                        })}
                        onClick={() => {
                            if (arrowLeftActive) {
                                setNum(num - 1);
                            }
                        }}
                    >
                        {'<'}
                        {arrowLeftActive && (
                            <Tooltip
                                id={TooltipId.WeeklyPlanArrowsForActivities}
                                desc={t('homework.eLearning.activityArrows')}
                            />
                        )}
                    </div>
                    <div
                        className={cn('elearning-arrow', {
                            'pr_hover_color_text_svg_bg cursor-pointer': arrowRightActive,
                        })}
                        onClick={() => {
                            if (arrowRightActive) {
                                setNum(num + 1);
                            }
                        }}
                    >
                        {'>'}
                        {arrowRightActive && (
                            <Tooltip
                                id={TooltipId.WeeklyPlanArrowsForActivities}
                                desc={t('homework.eLearning.activityArrows')}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="elearning-header elearning-header-mobile">
                <div
                    className="elearning-arrow"
                    onClick={() => {
                        if (num >= 1) {
                            setNum(num - 1);
                        }
                    }}
                >
                    {'<'}
                </div>
                <div className="elearning-activity">
                    {t('homework.eLearning.activity')} {num + 1} {t('homework.eLearning.of')}{' '}
                    {eLearning.length}
                </div>
                <div
                    className="elearning-arrow"
                    onClick={() => {
                        if (num < eLearning.length - 1) {
                            setNum(num + 1);
                        }
                    }}
                >
                    {'>'}
                </div>
            </div>

            <div className="elearning-info">
                <div className="elearning-score">
                    <span className="elearning-score-title">
                        {t('homework.eLearning.matchStrength')}
                    </span>
                    <div className="elearning-score-blocks">
                        <span
                            className={cn('elearning-score-block', {
                                'elearning-score-block--active': eLearning[num].score <= 3,
                            })}
                        >
                            {t('homework.eLearning.ok')}
                        </span>
                        <span
                            className={cn('elearning-score-block', {
                                'elearning-score-block--active': eLearning[num].score === 4,
                            })}
                        >
                            {t('homework.eLearning.good')}
                        </span>
                        <span
                            className={cn('elearning-score-block', {
                                'elearning-score-block--active': eLearning[num].score === 5,
                            })}
                        >
                            {t('homework.eLearning.excellent')}
                        </span>
                    </div>
                </div>

                <ELearningInfoData
                    taskTime={eLearning[num].time}
                    taskLevel={eLearning[num].level}
                    taskPreviewUrl={`${eLearning[num].preview}`}
                    taskOup={eLearning[num].oup}
                    isOld
                />
                <div className="elearning-students">
                    <div className="elearning-students-count">
                        {eLearning[num].studentsFinish} / {selectedClass.students || 0}
                    </div>
                    <div className="elearning-students-description">
                        {t('homework.eLearning.studentsHaveCompleted')}
                    </div>
                </div>
            </div>

            <div className="elearning-activities-data">
                <ELearningDescription eLearning={eLearning} num={num} />
            </div>
            <div className="elearning-footer">
                <Button
                    type="secondary"
                    onClick={() => {
                        setUpdateLesson((data: any) => {
                            const tempELearning = structuredClone(eLearning[num]);
                            tempELearning.currentlyAdded = true;
                            const newActivities = data.activities
                                .map(({ id }: any) => id)
                                .includes(tempELearning.id)
                                ? data.activities
                                : [...data.activities, tempELearning];

                            const newTeacherText = data.teacherText.includes(
                                tempELearning.description,
                            )
                                ? data.teacherText
                                : `${data.teacherText}<p>${tempELearning.description}</p>`;
                            return {
                                ...data,
                                activities: newActivities,
                                teacherText: newTeacherText,
                            };
                        });
                    }}
                >
                    {isCurrentLessonAdded() ? (
                        <>
                            <CheckedType2 />
                            {t('homework.eLearning.added')}{' '}
                        </>
                    ) : (
                        <>{t('homework.eLearning.addToHomework')}</>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default ELearning;
