import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { TIME } from 'src/constants';
import './styles.scss';
import { InputTextProps } from './types';

const InputText = ({
    fullWidth,
    onChange,
    error,
    template = 1,
    inputProps,
    errorAsBlock = false,
}: InputTextProps) => {
    const handleOnChange = _.debounce((e) => {
        onChange(e.target.value);
    }, TIME.inputDebounce);

    return (
        <div className="input">
            <div>
                <input
                    data-testid="inputText"
                    className={classNames('input__field', `template-${template}`, {
                        invalid: error,
                        fullWidth,
                    })}
                    type="text"
                    onChange={handleOnChange}
                    {...inputProps}
                />
            </div>
            <div className={classNames('input-error', { errorAsBlock })} title={error}>
                {error}
            </div>
        </div>
    );
};

export default InputText;
