import { all, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API } from '../../../api';
import { getElearning, putElearning } from '../actions';
import { putApiRequestError } from '../../error/actions';
import { finishLoading, startLoading } from '../../loading/actions';

export function* getElearningSaga({ payload }: ReturnType<typeof getElearning>): SagaIterator {
    try {
        yield put(startLoading());
        const { classId, id, cb, type } = payload;
        let data;
        if (Array.isArray(id)) {
            data = yield all(
                id.map((currId: any) => {
                    return call(
                        API.get(),
                        `/staff/course-planner${classId ? `/${classId}` : ''}/${
                            type ?? 'pages'
                        }/${currId}/eLearnings`,
                    );
                }),
            );
            data = data.flat();
        } else {
            data = yield call(
                API.get(),
                `/staff/course-planner${classId ? `/${classId}` : ''}/${
                    type ?? 'pages'
                }/${id}/eLearnings`,
            );
        }
        yield put(putElearning(data));

        if (cb) {
            cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
