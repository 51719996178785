import { ErrorBoundary } from '@rollbar/react';
import { useDispatch } from 'react-redux';
import Button from 'src/components/Button';
import WhiteBox from 'src/components/WhiteBox';
import SupportEmail from 'src/components/SupportEmail';
import { deleteWeekly } from 'src/redux/weeklyPlan/actions';
import spLoaderGif from 'src/assets/images/sp_loader.gif';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const ErrorWrapper = ({ resetError }: ErrorBoundary) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getWeeklyResetButton = () => {
        const location = window.location.href.match(/(\/weekly-plan\/)(\d*)/);
        let result = <></>;
        if (location) {
            const week = window.sessionStorage.getItem('weekly-plan-week');
            const year = window.sessionStorage.getItem('weekly-plan-year');
            if (location[1] === '/weekly-plan/' && location[2] && week && year) {
                result = (
                    <Button
                        type="square"
                        onClick={() => {
                            window.sessionStorage.removeItem('weekly-plan-week');
                            window.sessionStorage.removeItem('weekly-plan-year');
                            window.sessionStorage.setItem('restart-class-popup', 'true');
                            dispatch(
                                deleteWeekly({
                                    week,
                                    year,
                                    classId: location[2],
                                    cb: () => resetError(),
                                }),
                            );
                        }}
                    >
                        {t('errorWrapper.clickHere')}
                    </Button>
                );
            }
        }
        return result;
    };

    return (
        <>
            <WhiteBox>
                <div className="error-page">
                    <h1>
                        {t('errorWrapper.oops')}
                        <br /> {t('errorWrapper.pleaseContact')}{' '}
                        <SupportEmail style={{ display: 'inline' }} title="support" />.
                    </h1>
                    <img src={spLoaderGif} alt="loader" />
                    <div className="error-page__buttons">
                        {getWeeklyResetButton()}
                        <Button onClick={() => window.location.reload()}>
                            {t('errorWrapper.refreshPage')}
                        </Button>
                    </div>
                </div>
            </WhiteBox>
        </>
    );
};

export default ErrorWrapper;
