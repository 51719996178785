import classNames from 'classnames';
import Tooltip from 'src/components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getElearning } from 'src/redux/weeklyPlan/actions';
import { PageType } from 'src/redux/weeklyPlan/types';
import { useWindowSize } from 'src/hooks/useWindowSize';
import ELearning from '../ELearning';
import { ElearningProps } from '../ELearning/types';

import './styles.scss';
import { TooltipId } from '../../../components/Tooltip/types';

const Recommendations = ({
    currentUnits,
    setUpdateLesson,
    updateLesson,
    activeExercise,
    setActiveExercise,
}: ElearningProps & { activeExercise: PageType; setActiveExercise: (data: PageType) => void }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const state = useLocation().state as any;
    const { width } = useWindowSize();
    const isMob = width && width <= 768;
    /*eslint no-restricted-globals: ["error", "event"]*/
    const isStudent = location.pathname.includes('students');

    useEffect(() => {
        if (!currentUnits[0]) {
            return;
        }

        setActiveExercise(state && state.activeEx ? state.activeEx : currentUnits[0].pages[0]);
    }, [currentUnits]);

    useEffect(() => {
        if (activeExercise?.id) {
            dispatch(
                getElearning({
                    classId: params.id,
                    id: activeExercise.id,
                }),
            );
        }
    }, [activeExercise?.id, params?.id]);

    useEffect(() => {
        if (isMob) {
            setActiveExercise({} as PageType);
        }
    }, [isMob]);

    if (isStudent) {
        return (
            <div>
                <div className="recommendations recommendations-student">
                    <div>
                        <div className="recommendations-head">
                            <span className="recommendations-title">{t('homework.step1')}</span>
                            <span className="recommendations-subTitle">
                                {t('homework.itemOptionsSelection')}
                            </span>
                        </div>
                    </div>
                    <div className="recommendations-content">
                        <div className="recommendations-head">
                            <span className="recommendations-title">{t('homework.step2')}</span>
                            <span className="recommendations-subTitle">
                                {t('homework.selectionOptions')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="recommendations-no">
                    <span className="recommendations-no-title">
                        {t('homework.noRecommendations')}
                    </span>
                    <span className="recommendations-no-text">
                        {t('homework.noRecommendationsText')}
                    </span>
                </div>
            </div>
        );
    }
    return (
        <div className="recommendations">
            {(!isMob || !activeExercise?.id) && (
                <div>
                    <div className="recommendations-head">
                        <span className="recommendations-title">{t('homework.step1')}</span>
                        <span className="recommendations-subTitle">
                            {t('homework.itemOptionsSelection')}
                        </span>
                    </div>
                    <div className="recommendations-units">
                        {currentUnits?.map(({ name, pages }: any) => (
                            <div key={name} className="recommendations-unit">
                                <span className="recommendations-unit-name">
                                    {`${t('homework.unit')} ${name}`}
                                </span>
                                {pages.map((ex: any) => (
                                    <span
                                        key={ex.id}
                                        className={classNames('recommendations-unit-exercise', {
                                            'recommendations-unit-exercise--active pr_hover_color_text_svg_bg':
                                                ex.id === activeExercise.id,
                                            sc_hover_color_text_svg: ex.id !== activeExercise.id,
                                        })}
                                        onClick={() => {
                                            setActiveExercise(ex);
                                        }}
                                    >
                                        {ex.exName}
                                        <div className="homework-unit-exercise__tooltip">
                                            <Tooltip
                                                id={TooltipId.WeeklyPlanTodayLessonExercises}
                                                desc={t('homework.todaysLessonExercises')}
                                                styles={{ left: '' }}
                                            />
                                        </div>
                                    </span>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {(!isMob || activeExercise?.id) && (
                <div className="recommendations-content">
                    <div className="recommendations-head">
                        <span className="recommendations-title">{t('homework.step2')}</span>
                        <span className="recommendations-subTitle">
                            {t('homework.selectionOptions')}
                        </span>
                    </div>
                    <span className="recommendations-eLerning-title recommendations-eLerning-title-mob">
                        {t('homework.eLearningTitle')}
                    </span>
                    <div className="recommendations-eLerning">
                        <span className="recommendations-eLerning-title">
                            {t('homework.eLearningTitle')}
                        </span>
                        <ELearning
                            setUpdateLesson={(data) => setUpdateLesson(data)}
                            updateLesson={updateLesson}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Recommendations;
