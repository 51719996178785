import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { TO_DO_LIST_REF } from 'src/pages/ToDoList/ToDoListAddOrEditItemModal/constants';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAllToDo, saveToDo } from '../actions';

export function* saveToDoSaga({ payload }: ReturnType<typeof saveToDo>): SagaIterator {
    try {
        yield put(startLoading());
        if (payload.toDoId) {
            const { toDoId } = {
                ...payload,
            };
            delete payload.toDoId;
            yield call(API.put(), `/staff/todo/${toDoId}`, payload);
        } else {
            yield call(API.post(), '/staff/todo', payload);
        }
        yield put(
            getAllToDo({
                cb: () => {
                    if (payload.cb) {
                        payload.cb();
                    }
                },
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
