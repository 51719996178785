import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';
import AssignModal from 'src/components/Modals/AssignModal';
import Table from 'src/components/Table';
import { getPartialRange } from 'src/helpers/getPartialRange';
import { ProgressTestType } from 'src/redux/progress/reducer';
import { AssignedTestProps } from '../AutomatedTests/types';
import './styles.scss';

type UnassignedProps = {
    unAssigned: Partial<AssignedTestProps>[] | Partial<ProgressTestType>[];
    type: string;
    onAssign(date: [Date, Date], assign: string | number): void;
    onClickReturn(id: string): string;
};
const Unassigned = ({ unAssigned, type, onAssign, onClickReturn }: UnassignedProps) => {
    const { t } = useTranslation();
    const [assign, setAssign] = useState(0);
    return (
        <>
            <Table
                header={
                    <>
                        <span className="unassignedTests-table">
                            {t('unassigned.unassignedTests')}
                        </span>
                    </>
                }
                rows={
                    unAssigned?.map((test, i: number) => (
                        <div key={i} className="unassignedTests-table unassignedTests-table-row">
                            <span
                                className="unassignedTests-active unassignedTests-name tr_hover_color_text"
                                title={test.name}
                                onClick={() => {
                                    if (onClickReturn) {
                                        onClickReturn(test.id as string);
                                    }
                                }}
                            >
                                <b>{i + 1}.</b>

                                <CustomLinkOverloaded
                                    linkProps={{
                                        to: onClickReturn ? onClickReturn(test.id as string) : '',
                                        className: 'automatedTests-active tr_hover_color_text',
                                    }}
                                    parentProps={{
                                        style: { display: 'contents' },
                                    }}
                                >
                                    {test.name}
                                </CustomLinkOverloaded>
                            </span>
                            <span
                                className="unassignedTests-active tr_hover_color_text"
                                onClick={() => {
                                    setAssign(test.id as number);
                                }}
                            >
                                {t('unassigned.assign')}
                            </span>
                        </div>
                    )) ?? []
                }
                headerMobile={
                    <>
                        <span>{t('unassigned.notAssignedTests')}</span>
                    </>
                }
                rowsMobile={
                    unAssigned?.map((test, idx: number) => {
                        return {
                            head: (
                                <div className="unassignedTests-row-mobile">
                                    <div
                                        onClick={() => {
                                            if (onClickReturn) {
                                                onClickReturn(test.id as string);
                                            }
                                        }}
                                        className="unassignedTests-name-mobile unassignedTests-active"
                                    >
                                        <b>{idx + 1}.</b>
                                        {test.name}
                                    </div>
                                    <span
                                        className="unassignedTests-active"
                                        onClick={() => {
                                            setAssign(test.id as number);
                                        }}
                                    >
                                        {t('unassigned.assign')}
                                    </span>
                                </div>
                            ),
                        };
                    }) ?? []
                }
            />
            <AssignModal
                allowPartialRange
                type={type}
                open={assign}
                onChangeReturnType="date"
                onAssign={(date) => {
                    const partialDate = getPartialRange(date);
                    onAssign(partialDate, assign);
                }}
                onClose={() => {
                    setAssign(0);
                }}
            />
        </>
    );
};

export default Unassigned;
