import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import HeaderButton from 'src/components/HeaderButton';
import ManPlus from 'src/components/Icons/ManPlus';
import InviteStudentEmailModal from 'src/components/Modals/InviteStudentEmailModal';
import InviteStudentModal from 'src/components/Modals/InviteStudentModal';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { getClass } from 'src/redux/classes/actions';
import { putBreadcrumbs } from 'src/redux/layout/actions';
import { useTranslation } from 'react-i18next';

const InviteStudentButtonModal = ({
    page,
    desc = false,
    withPreSessionalClass,
}: {
    page: 'student' | 'studentFilter' | 'unmatchedStudent' | 'duplicateStudent' | 'QRCodes';
    desc?: boolean;
    withPreSessionalClass?: boolean;
}) => {
    const [inviteStudentEmailModal, setInviteStudentEmailModal] = useState<boolean | string>(false);
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const params = useParams();
    const navigate = useNavigate();
    const [openInviteStudentModal, setOpenInviteStudentModal] = useState(false);
    const inviteStudent = isModuleActive(Modules.InviteStudent);
    const inviteStudentQr = isModuleActive(Modules.InviteStudentQr);
    const dispatch = useDispatch();
    const classId = useRef<undefined | string>(undefined);
    const { t } = useTranslation();

    if (!inviteStudent) {
        return null;
    }

    const getButtons = () => {
        const title = getModuleName(Modules.InviteStudent) || t('inviteStudentButtonModal.invite');
        switch (page) {
            case 'student':
                return (
                    <div
                        className="weeklyPlan-restart pr_hover_color_text_svg_bg"
                        onClick={() => {
                            if (inviteStudentQr) {
                                navigate(`/invite/student/${params.id}`);
                            } else {
                                classId.current = params.id;
                                setInviteStudentEmailModal(true);
                            }
                        }}
                    >
                        <ManPlus />
                        {desc && title}
                    </div>
                );
            case 'studentFilter':
                return (
                    <HeaderButton onClick={() => setOpenInviteStudentModal(true)}>
                        <ManPlus />
                        <span className="all-students-header__invite-student-title">{title}</span>
                    </HeaderButton>
                );
            case 'unmatchedStudent':
                return (
                    <>
                        <HeaderButton onClick={() => setOpenInviteStudentModal(true)}>
                            <ManPlus />
                            <span className="unmatchedStudentsHeader__invite-student-title">
                                {title}
                            </span>
                        </HeaderButton>
                    </>
                );
            case 'duplicateStudent':
                return (
                    <>
                        <HeaderButton onClick={() => setOpenInviteStudentModal(true)}>
                            <ManPlus />
                            <span>
                                {getModuleName(Modules.InviteStudent) ||
                                    t('inviteStudentButtonModal.inviteStudent')}
                            </span>
                        </HeaderButton>
                    </>
                );
            case 'QRCodes':
                return (
                    <>
                        <HeaderButton onClick={() => setOpenInviteStudentModal(true)}>
                            <span style={{ fontSize: '1.5rem' }}>+</span>{' '}
                            {t('inviteStudentButtonModal.inviteStudent')}
                        </HeaderButton>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <>
            {getButtons()}
            <InviteStudentEmailModal
                open={inviteStudentEmailModal}
                classId={classId.current}
                setOpen={setInviteStudentEmailModal}
            />
            <InviteStudentModal
                withPreSessionalClass={withPreSessionalClass}
                open={openInviteStudentModal}
                onSelect={(classToInvite) => {
                    if (classToInvite === 'pre-sessional') {
                        navigate(`/invite/student/`);
                    } else {
                        classId.current = classToInvite;

                        if (inviteStudentQr) {
                            dispatch(
                                getClass({
                                    classId: classToInvite,
                                    cb: (data: any) => {
                                        dispatch(
                                            putBreadcrumbs([
                                                {
                                                    name: `${t('general.class')} ${data.name}`,
                                                },
                                            ]),
                                        );
                                        navigate(`/invite/student/${classToInvite}`);
                                    },
                                }),
                            );
                        } else {
                            setInviteStudentEmailModal(classToInvite);
                            setOpenInviteStudentModal(false);
                        }
                    }
                }}
                onClose={() => setOpenInviteStudentModal(false)}
            />
        </>
    );
};

export default InviteStudentButtonModal;
