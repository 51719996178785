import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { put2FAPhoneNumber, putSettingsValidationErrors } from '../actions';

export function* put2FAPhoneNumberSaga({
    payload,
}: ReturnType<typeof put2FAPhoneNumber>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.post(), `/settings/phone/verify`, {
            number: payload.number,
        });
        yield put(putSettingsValidationErrors(true));
        yield put(putSettingsValidationErrors(false));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(
            putSettingsValidationErrors({
                message: [{ message: 'Your number could not be verified. Please try again.' }],
            }),
        );
    } finally {
        yield put(finishLoading());
    }
}
