import { useEffect, useState } from 'react';

export const useValuesFilter = ({ values, filter }: { values: any; filter: any }) => {
    const [newValues, setNewValues] = useState(null as any);

    useEffect(() => {
        if (filter) {
            let flag = false;
            Object.keys(filter).forEach((key) => {
                if (filter[key] !== '') {
                    let tempFilteredValues: any = [];
                    switch (key) {
                        case 'search':
                            tempFilteredValues = values.filter((val: any) => {
                                if (val.name) {
                                    return val.name
                                        .toLowerCase()
                                        .includes(filter.search.toLowerCase());
                                }
                                return false;
                            });
                            if (tempFilteredValues.length) {
                                flag = true;
                                setNewValues(tempFilteredValues);
                            } else if (filter.search !== '') {
                                flag = true;
                                setNewValues(tempFilteredValues);
                            }
                            break;
                        default:
                            flag = false;
                            break;
                    }
                }
            });
            if (!flag) {
                setNewValues(values);
            }
        } else if (filter !== null) {
            setNewValues(values);
        }
    }, [filter, values]);

    return {
        newValues,
    };
};
