import './styles.scss';
import { CheckboxProps } from './types';
import Unchecked from '../Icons/Unchecked';
import Checked from '../Icons/Checked';
import CheckMark from '../Icons/CheckMark';

const Checkbox = ({
    checked,
    onClick,
    customIcon,
    inline,
    type = 'default',
    checkedIconType = 'checkmark',
    styles = {
        checkedBackground: 'rgb(var(--mainColor))',
        borderColor: '#A3A3A3',
        checkedBorderColor: 'rgb(var(--mainColor))',
    },
}: CheckboxProps) => {
    if (type === 'bold') {
        const {
            wrapperBackground,
            background,
            checkedBackground,
            checkedBorderColor,
            borderColor,
        } = styles || {};
        return (
            <div style={{ background: wrapperBackground }}>
                <div
                    className="checkbox-bold"
                    data-testid="checkbox"
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                    style={{
                        background: checked ? checkedBackground : background,
                        borderColor: checked ? checkedBorderColor : borderColor,
                    }}
                >
                    {checked && (
                        <>
                            {checkedIconType === 'checkmark' ? (
                                <>
                                    <CheckMark />
                                </>
                            ) : (
                                <div
                                    style={{
                                        height: '21px',
                                        fontWeight: '500',
                                    }}
                                >
                                    -
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            data-testid="checkbox"
            className="checkbox"
            style={{
                ...(inline ? { display: 'inline-block' } : {}),
            }}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >
            {checked ? customIcon || <Checked /> : <Unchecked />}
        </div>
    );
};

export default Checkbox;
