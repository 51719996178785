import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useParams } from 'react-router';
import SelectMenu from 'src/components/SelectMenu';
import Modal from 'src/components/Modal';
import { getProgressTests } from 'src/redux/staff/actions';
import { selectProgressTests } from 'src/redux/staff/selectors';
import Button from 'src/components/Button';
import { addStudentProgressTest, getStudentTests } from 'src/redux/students/actions';
import SuccessModal from 'src/components/Modals/SuccessModal';
import { selectStudentTests } from 'src/redux/students/selectors';
import ActionText from 'src/components/ActionText';
import { appConstants } from 'src/constants';
import { useTranslation } from 'react-i18next';

const ProfileInfoAssignProgressTestModal = ({
    open,
    onClose,
    profile,
}: {
    profile: any;
    open: any;
    onClose: any;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const studentTests = useSelector(selectStudentTests);
    const [selected, setSelected] = useState({ name: 'Select test', id: null } as any);
    const [filteredProgressTests, setFilteredProgressTests] = useState([] as any);
    const progressTests = useSelector(selectProgressTests);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    useEffect(() => {
        if (progressTests.length) {
            const alreadyAssignedTests = studentTests.map((test) => {
                return test.testId;
            });
            const tempFilteredProgressTests: any = [];
            progressTests.forEach((pt: any) => {
                if (!alreadyAssignedTests.includes(pt.id)) {
                    tempFilteredProgressTests.push(pt);
                }
            });
            setFilteredProgressTests(tempFilteredProgressTests);
        }
    }, [studentTests, progressTests]);

    useEffect(() => {
        if (open) {
            dispatch(getProgressTests());
            dispatch(getStudentTests({ dataType: 'tests', studentId: params.id as string }));
        }
    }, [open]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            disableOutsideClick={openSuccessModal}
            open={open}
            onClose={() => {
                setOpenSuccessModal(false);
                setSelected({ name: 'Select test', id: null });
                onClose();
            }}
        >
            <div className="profileInfoProgressTestsModal">
                <div className="profileInfoProgressTestsModal__title">
                    {t('profile.assignTo', { name: profile.name })}
                </div>

                <div className="profileInfoProgressTestsModal__selector">
                    {t('profile.chooseTest')}
                    <div>
                        <SelectMenu
                            alwaysWide
                            wrapperClass="restart-select"
                            text={selected.name}
                            options={filteredProgressTests?.map((item: any) => {
                                return {
                                    content: (
                                        <div className="restart-option" title={item.name}>
                                            <span className="restart-truncate">{item.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        setSelected(item);
                                    },
                                };
                            })}
                        />
                    </div>
                    <div />
                    <div className="profileInfoProgressTestsModal__subtitle">
                        {t('profile.assignableUntakenTests')}
                    </div>
                </div>
                <div className={classNames('profileInfoProgressTestsModal-buttons')}>
                    <Button
                        disabled={selected.id === null}
                        type="secondary"
                        onClick={() => {
                            dispatch(
                                addStudentProgressTest({
                                    studentId: params.id as string,
                                    id: selected.id,
                                    cb: () => {
                                        dispatch(
                                            getStudentTests({
                                                dataType: 'tests',
                                                studentId: params.id as string,
                                            }),
                                        );
                                        setOpenSuccessModal(true);
                                    },
                                }),
                            );
                        }}
                    >
                        {t('profile.assign')}
                    </Button>
                    <ActionText
                        disabled={selected.id === null}
                        onClick={() => {
                            window.open(
                                `${appConstants.server}/progress_test/testdisplay/demo/${selected.id}`,
                                '_blank',
                                'noreferrer',
                            );
                        }}
                    >
                        {t('profile.previewTest')}
                    </ActionText>
                </div>
                <SuccessModal
                    lottie
                    open={openSuccessModal}
                    text={t('profile.progressAssigned')}
                    onClose={() => {
                        setOpenSuccessModal(false);
                        setSelected({ name: 'Select test', id: null });
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoAssignProgressTestModal;
