import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PutAttendanceStudentsProps } from './types';

export const getAttendanceSelector = (state: RootState): any => state.attendance;
export const selectAttendance = createSelector(
    createSelector(getAttendanceSelector, (attendance) => attendance),
    (attendance) => attendance,
);

export const getAttendanceStudentsSelector = (state: RootState): PutAttendanceStudentsProps[] =>
    state.attendance.students;

export const selectAttendanceStudents = createSelector(
    createSelector(getAttendanceStudentsSelector, (students) => students),
    (students) => students,
);
