import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PutAssessmentProps, PutAssessmentStudentsProps, PutAssessmentTypesProps } from './types';

export const getAssessmentSelector = (state: RootState): PutAssessmentProps =>
    state.assessment.assessment;
export const selectAssessment = createSelector(
    createSelector(getAssessmentSelector, (assessment) => assessment),
    (assessment) => assessment,
);

export const getAssessmentTypesSelector = (state: RootState): PutAssessmentTypesProps[] =>
    state.assessment.types;

export const selectAssessmentTypes = createSelector(
    createSelector(getAssessmentTypesSelector, (types) => types),
    (types) => types,
);

export const getAssessmentStudentsSelector = (state: RootState): PutAssessmentStudentsProps[] =>
    state.assessment.students;

export const selectAssessmentStudents = createSelector(
    createSelector(getAssessmentStudentsSelector, (students) => students),
    (students) => students,
);
