import React, { useEffect, useState } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { validateValue } from 'src/helpers/validateValue';
import { InputTextareaProps } from './types';

const InputTextareaOld = ({
    validators,
    inputProcessor,
    inputName,
    inputLabel,
    fullWidth,
}: InputTextareaProps) => {
    const [inlineError, setInlineError] = useState('');
    useEffect(() => {
        setInlineError(inputProcessor.getInlineErrors(inputName));
    }, [inputProcessor.errors, inputProcessor.values]);

    useEffect(() => {
        inputProcessor.updateError({
            [inputName]: validateValue(inputProcessor.getValue(inputName), inputLabel, validators),
        });
    }, []);

    return (
        <div className="inputTextarea">
            <textarea
                data-testid="inputTextarea"
                value={inputProcessor.getValue(inputName)}
                className={classNames('inputTextarea__field', { invalid: inlineError, fullWidth })}
                onChange={(e) => {
                    inputProcessor.updateInput({ [inputName]: e.target.value });

                    inputProcessor.updateError({
                        [inputName]: validateValue(e.target.value, inputLabel, validators),
                    });
                }}
            />
            <div className="inputTextarea-error">{inlineError}</div>
        </div>
    );
};

export default InputTextareaOld;
