import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAimsMaterials, putAimsMaterials } from '../actions';

export function* getAimsMaterialsSaga({
    payload,
}: ReturnType<typeof getAimsMaterials>): SagaIterator {
    try {
        const { classId } = payload;
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/course-planner${classId ? `/${classId}` : ''}/aims/materials`,
        );
        yield put(putAimsMaterials(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
