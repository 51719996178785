import { Props } from '../types';

const CheckedMinus = ({ fill = '#0070C0' }: Props) => (
    <svg width="12" height="12" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8333 0H2.16667C1.25 0 0.5 0.75 0.5 1.66667V13.3333C0.5 14.25 1.25 15 2.16667 15H13.8333C14.75 15 15.5 14.25 15.5 13.3333V1.66667C15.5 0.75 14.75 0 13.8333 0ZM11.3333 8.33333H4.66667C4.20833 8.33333 3.83333 7.95833 3.83333 7.5C3.83333 7.04167 4.20833 6.66667 4.66667 6.66667H11.3333C11.7917 6.66667 12.1667 7.04167 12.1667 7.5C12.1667 7.95833 11.7917 8.33333 11.3333 8.33333Z"
            fill={fill}
        />
    </svg>
);

export default CheckedMinus;
