import moment from 'moment/moment';
import classNames from 'classnames';
import AnimatedRow from '../../Table/components/AnimatedRow';

const MoveToAnimatedDropdown = ({
    selectedSubCategory,
    setSelectedSubCategory,
    date,
    moveToModalData,
    lessonNames,
    lessonSubNames,
    lessons,
    day,
}: any) => {
    let result: any;
    const getInClassContent = (lesson: any, index: number) => {
        return Object.keys(lesson).map((item: any) => {
            let currentText: string;
            const lessonItem = lesson[item].lessonName.replace(/##.*/g, '');
            if (lessonItem !== '') {
                currentText = lessonItem;
            } else {
                currentText = 'Student sees';
            }
            const key = `${moment(date).isoWeekday() - 1}-${lesson[item].lessonName}-${
                lesson[item].lessonData.columnId
            }-${lesson[item].lessonDirectionId}-${index}`;
            return (
                <div
                    className={classNames('move-to-modal__dropdown-content', {
                        'move-to-modal__dropdown-content_active': key === selectedSubCategory,
                    })}
                    key={key}
                    onClick={() => {
                        setSelectedSubCategory(key);
                    }}
                >
                    {currentText}
                </div>
            );
        });
    };

    switch (moveToModalData?.type) {
        case 'resources':
            result = (
                <div className="table-row-mobile-head table-row-mobile-head--active">Resources</div>
            );
            break;
        default: {
            const lessonParentCategories: any = {};
            result = Object.keys(lessonNames).map((lessonParentCategory) => {
                Object.keys(lessonSubNames).map((lessonName) => {
                    if (
                        lessonSubNames[lessonName].lessonIds.some(
                            (val: any) =>
                                lessonNames[lessonParentCategory].lessonIds.indexOf(val) >= 0,
                        )
                    ) {
                        const lessonData = lessons[day].lessons.filter((lesson: any) => {
                            return lesson.name === lessonParentCategory;
                        })[0];

                        const lessonParentCategoryItem = {
                            lessonName,
                            lessonData,
                            lessonDirectionId:
                                lessonNames[lessonParentCategory].lessonDays[
                                    moment(date).isoWeekday() - 1
                                ].lessonId,
                        };

                        if (lessonParentCategories[lessonParentCategory]) {
                            lessonParentCategories[lessonParentCategory].push(
                                lessonParentCategoryItem,
                            );
                        } else {
                            lessonParentCategories[lessonParentCategory] = [
                                lessonParentCategoryItem,
                            ];
                        }
                    }
                    return false;
                });
                return false;
            });
            return Object.keys(lessonParentCategories).map((category, index) => {
                return (
                    <AnimatedRow
                        onClickHead={() => {
                            if (+selectedSubCategory?.split('-')[4] === index) {
                                setSelectedSubCategory(null);
                            }
                        }}
                        key={index}
                        content={getInClassContent(lessonParentCategories[category], index)}
                        head={<div>{category}</div>}
                        arrowLeft={false}
                    />
                );
            });
        }
    }
    return result;
};
export default MoveToAnimatedDropdown;
