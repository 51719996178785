import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LayoutState } from './reducer';

export const getBreadcrumbsSelector = (state: RootState): LayoutState['breadcrumbs'] =>
    state.layout.breadcrumbs;

export const selectBreadcrumbs = createSelector(
    createSelector(getBreadcrumbsSelector, (breadcrumbs) => breadcrumbs),
    (breadcrumbs) => breadcrumbs,
);

export const getSidebarSelector = (state: RootState): LayoutState['showSidebar'] =>
    state.layout.showSidebar;

export const selectSidebar = createSelector(
    createSelector(getSidebarSelector, (showSidebar) => showSidebar),
    (showSidebar) => showSidebar,
);

export const getLayoutSelector = (state: RootState): LayoutState => state.layout;

export const selectLayout = createSelector(
    createSelector(getLayoutSelector, (layout) => layout),
    (layout) => layout,
);

export const getModuleSelector = (state: RootState): LayoutState => state.layout;

export const selectModule = createSelector(getModuleSelector, ({ modules }) => modules);
