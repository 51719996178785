import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './styles.scss';
import WhiteBox from 'src/components/WhiteBox';
import { selectStundentReport } from 'src/redux/reports/selectors';
import { getStudentReport } from 'src/redux/reports/actions';
import { useWindowSize } from 'src/hooks/useWindowSize';

const ProfileReport = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const report = useSelector(selectStundentReport);
    const { isDesktop } = useWindowSize();

    useEffect(() => {
        dispatch(
            getStudentReport({
                classId: params.id,
            }),
        );
    }, [dispatch, params]);

    return (
        <>
            <WhiteBox>
                <div className="report">
                    <iframe
                        src={isDesktop ? report?.desktopPath : report?.mobilePath}
                        title="Student report"
                        id="iframe"
                    />
                </div>
            </WhiteBox>
        </>
    );
};

export default ProfileReport;
