import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import moment from 'moment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationPt from './locales/pt/translation.json';
import translationSpa from './locales/spa/translation.json';
import translationCn from './locales/cn/translation.json';
import Router from './Route';
import store from './redux/store';
import { NotificationProvider } from './providers/NotificationProvider';
import NotificationsList from './components/NotificationsList';

export const i18next = i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: translationEN,
            },
            pt: {
                translation: translationPt,
            },
            es: {
                translation: translationSpa,
            },
            cn: {
                translation: translationCn,
            },
        },
        lng: 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

window.addEventListener(
    'paste',
    (event) => {
        event.preventDefault();
        event.stopPropagation();
        const ev: any = event;
        const text = (ev.originalEvent || event).clipboardData.getData('text/plain');
        document.execCommand('insertHTML', false, text);
    },
    true,
);

moment.defaultFormat = 'D MMM YYYY';
const App: React.FC = () => {
    return (
        <Provider store={store}>
            <NotificationProvider>
                <Router />
                <NotificationsList />
            </NotificationProvider>
        </Provider>
    );
};

export default App;
