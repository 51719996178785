export type SagaError =
    | {
          code: number;
          message: Array<{
              code: number;
              message: string;
              error: any;
          }>;
      }
    | any;

export const getErrorMessage = (error: SagaError) => {
    if (error && error.message && error.message.length) {
        return error.message.map((item: any) => item.message).join(', ');
    }
    return '';
};
