import ExclamationIcon from 'src/components/Icons/ExclamationIcon';

export const profileGetExclamationIcon = (id = '', reportedId = '') => {
    const intId = id?.replace(/\D/g, '');
    const intReportedId = reportedId?.replace(/\D/g, '');
    if (intId !== intReportedId) {
        return (
            <span className="cursor-pointer">
                <ExclamationIcon />
            </span>
        );
    }
    return null;
};
