export const handleResourceMove = ({
    result,
    setIsDirty,
    lessons,
    getUpdatedDays,
    columns,
    setDays,
}: {
    result: any;
    setIsDirty: any;
    lessons: any;
    getUpdatedDays: any;
    columns: any;
    setDays: any;
}) => {
    const { destination, source, draggableId } = result;
    const [syllabusId, sourceLessonId, type, activityId] = draggableId.split('-');
    if (!destination) {
        return;
    }
    setIsDirty(true);

    const allLessons = lessons
        .map(({ lessons: lessonsData }: any) => {
            return lessonsData;
        })
        .flat();
    const { name } = lessons
        .map(({ lessons: lessonsData }: any) => {
            return lessonsData;
        })
        .flat()
        .find(({ lessonId }: any) => {
            return lessonId === +sourceLessonId;
        });

    switch (type) {
        case 'syllabus':
            getUpdatedDays(columns, (daysToUpdate: any) => {
                const newDays = JSON.parse(JSON.stringify(daysToUpdate));
                if (source.droppableId !== destination.droppableId) {
                    const currentLesson = newDays[source.droppableId].lessons.find(
                        ({ syllabus, lessonId }: any) => {
                            return (
                                syllabus.map(({ id }: any) => id).includes(+syllabusId) &&
                                lessonId === +sourceLessonId
                            );
                        },
                    );
                    const indexToRemove = currentLesson.syllabus.findIndex(({ id }: any) => {
                        return id === +syllabusId;
                    });
                    const allIds = allLessons
                        .filter(({ name: lessonName }: any) => {
                            return lessonName === name;
                        })
                        .map(({ lessonId }: any) => lessonId);

                    const lessonToMove = newDays[destination.droppableId].lessons.find(
                        ({ lessonId }: any) => allIds.includes(lessonId),
                    );
                    lessonToMove.syllabus.push(currentLesson.syllabus[indexToRemove]);
                    currentLesson.syllabus.splice(indexToRemove, 1);
                    setDays(newDays);
                }
            });
            break;
        default:
            getUpdatedDays(columns, (daysToUpdate: any) => {
                const newDays = JSON.parse(JSON.stringify(daysToUpdate));

                if (source.droppableId !== destination.droppableId) {
                    const currentLesson = newDays[source.droppableId].lessons.find(
                        (lesson: any) => {
                            return lesson.lessonId === +sourceLessonId;
                        },
                    );
                    const indexToRemove = currentLesson.activities.findIndex((activity: any) => {
                        return activity.id === +activityId;
                    });

                    const allIds = allLessons
                        .filter(({ name: lessonName }: any) => {
                            return lessonName === name;
                        })
                        .map(({ lessonId }: any) => lessonId);

                    const lessonToMove = newDays[destination.droppableId].lessons.find(
                        ({ lessonId }: any) => allIds.includes(lessonId),
                    );
                    lessonToMove.activities.splice(
                        destination.index,
                        0,
                        currentLesson.activities[indexToRemove],
                    );
                    currentLesson.activities.splice(indexToRemove, 1);
                    setDays(newDays);
                } else if (source.droppableId === destination.droppableId) {
                    const currentLesson = newDays[source.droppableId].lessons.find(
                        (lesson: any) => {
                            return lesson.lessonId === +sourceLessonId;
                        },
                    );
                    const indexToRemove = currentLesson.activities.findIndex((activity: any) => {
                        return activity.id === +activityId;
                    });
                    const itemToPaste = currentLesson.activities[indexToRemove];
                    currentLesson.activities.splice(indexToRemove, 1);
                    currentLesson.activities.splice(destination.index, 0, itemToPaste);
                    setDays(newDays);
                }
            });
            break;
    }
};
