import React from 'react';
import WarningIcon from '../Icons/WarningIcon';
import Button from '../Button';
import './styles.scss';

const InlineConfirm = ({
    children,
    onClick,
    onClose,
    onClickText,
    onCloseText,
}: {
    children: React.ReactNode;
    onClick(): void;
    onClose(): void;
    onClickText: string;
    onCloseText: string;
}) => {
    return (
        <div className="inline-confirm">
            <div className="inline-confirm__icon">
                <WarningIcon />
            </div>
            <div className="inline-confirm__text">{children}</div>
            <div className="inline-confirm__buttons">
                <Button type="secondary" onClick={onClick}>
                    {onClickText}
                </Button>
                <Button type="secondary" onClick={onClose}>
                    {onCloseText}
                </Button>
            </div>
        </div>
    );
};

export default InlineConfirm;
