import classNames from 'classnames';
import { useWindowSize } from 'src/hooks/useWindowSize';
import CircleCheck from 'src/components/Icons/CircleCheck';
import EmptyCircle from 'src/components/Icons/EmptyCircle';
import PlayItem from 'src/components/Icons/PlayItem';
import './styles.scss';

const stepsArray = ['Method', 'Content', 'Questions', 'Test Name', 'Review'];

export default function StepHistory({
    stepId,
    completedSteps,
    onStepClick,
}: {
    stepId: number;
    completedSteps: number[];
    onStepClick: (step: number) => void;
}) {
    const { isDesktop } = useWindowSize();

    return isDesktop ? (
        <div className="makeUnit-stepHistory">
            {stepsArray.map((step, idx) => (
                <div
                    key={step}
                    className={classNames('makeUnit-stepHistoryItem', {
                        inactive: !completedSteps.includes(idx) && idx !== stepId,
                        active: idx === stepId,
                    })}
                    onClick={() => {
                        if (completedSteps.includes(idx)) {
                            onStepClick(idx);
                        }
                    }}
                >
                    {!completedSteps.includes(idx) && idx !== stepId && <EmptyCircle />}
                    {idx === stepId && <PlayItem />}
                    {completedSteps.includes(idx) && idx !== stepId && (
                        <CircleCheck fill="#005FAB" />
                    )}
                    {step}
                </div>
            ))}
        </div>
    ) : (
        <>
            <ul className="makeUnit-stepHistory mobile">
                {stepsArray.map((step, idx) => (
                    <li
                        key={step}
                        className={classNames({
                            inactive: idx > stepId,
                            active: idx === stepId,
                            complete: idx <= stepId,
                        })}
                    />
                ))}
            </ul>
            <div className="makeUnit-stepHistoryMobile-head">
                <span className="makeUnit-stepHistoryMobile-name">{stepsArray[stepId]}</span>
                <span className="makeUnit-stepHistoryMobile-number">Step {stepId + 1} of 5</span>
            </div>
        </>
    );
}
