const CloseButtonSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.283"
            height="7.283"
            viewBox="0 0 7.283 7.283"
        >
            <g id="exit" transform="translate(3.641 3.641)">
                <g id="Group_6638" data-name="Group 6638" transform="translate(-3.641 -3.641)">
                    <g id="Group_6637" data-name="Group 6637">
                        <path
                            id="Path_25482"
                            data-name="Path 25482"
                            d="M4.071,3.642,7.194.518A.3.3,0,0,0,6.765.089L3.642,3.213.518.089A.3.3,0,0,0,.089.518L3.213,3.642.089,6.765a.3.3,0,1,0,.429.429L3.642,4.071,6.765,7.194a.3.3,0,0,0,.429-.429Z"
                            transform="translate(0 0)"
                            fill="#565656"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CloseButtonSvg;
