import { useEffect, useState } from 'react';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import Bold from '../Icons/Bold';
import Italic from '../Icons/Italic';
import Hyperlink from '../Icons/Hyperlink';
import './styles.scss';
import { EditableProps, EditButtonProps } from './types';

const sectionActive = (type: string) => {
    let active = false;
    if (document.queryCommandState) {
        active = document.queryCommandState(type);
    }
    return active;
};

const EditButton = ({ cmd, arg, icon, isActive }: EditButtonProps) => {
    const { t } = useTranslation();
    return (
        <div
            key={cmd}
            className={classNames('editable-control qr_hover_color_text_svg_bg', {
                'editable-control--active': isActive,
            })}
            onMouseDown={() => {
                if (cmd === 'createLink') {
                    /* eslint-disable-next-line no-alert */
                    const newLink = prompt(t('editable.insertLink'), '');
                    /*Send the command to the browser*/
                    document.execCommand(cmd, false, newLink || '');
                    return;
                }
                /*Send the command to the browser*/
                document.execCommand(cmd, false, arg);
            }}
        >
            {icon || cmd}
        </div>
    );
};

const Editable = ({ html, onChange, edit, onSave }: EditableProps) => {
    const { t } = useTranslation();
    const [activeSections, setActiveSections] = useState({
        italic: false,
        bold: false,
        createLink: false,
    });
    useEffect(() => {
        if (edit) {
            setActiveSections({
                italic: sectionActive('italic'),
                bold: sectionActive('bold'),
                createLink: sectionActive('createLink'),
            });
        }
    }, [sectionActive('italic'), sectionActive('bold'), sectionActive('createLink'), edit]);

    return (
        <>
            {edit && (
                <div className="editable-controls">
                    <EditButton cmd="italic" icon={<Italic />} isActive={activeSections.italic} />
                    <EditButton cmd="bold" icon={<Bold />} isActive={activeSections.bold} />
                    <EditButton
                        cmd="createLink"
                        name="hyperlink"
                        icon={<Hyperlink />}
                        isActive={activeSections.createLink}
                    />
                </div>
            )}
            <div className={classNames({ 'editable--active': edit })}>
                <ContentEditable
                    className={classNames('editable')}
                    tagName="p"
                    html={html as string}
                    onChange={onChange}
                    disabled={!edit}
                />
                {edit && (
                    <div className="editable-button">
                        <span
                            data-testid="done-edit"
                            onClick={onSave}
                            className="editable-done sc_hover_color_text_svg_bg"
                        >
                            {t('general.done')}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};

export default Editable;
