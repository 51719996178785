import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteProgress, getProgress } from '../actions';

export function* deleteProgressSaga({ payload }: ReturnType<typeof deleteProgress>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(
            API.delete(),
            `/staff/classes/${payload.classId}/progress-tests/assigned/${payload.testId}`,
        );
        if (payload.type) {
            yield put(
                getProgress({
                    classId: payload.classId,
                    type: payload.type,
                }),
            );
        }
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
