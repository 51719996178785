import classNames from 'classnames';
import Exit from 'src/components/Icons/Exit';
import MoveTo from 'src/components/Icons/MoveTo';
import ScreenPauseIcon from 'src/components/Icons/ScreenPauseIcon';
import ArrowInSquareIcon from 'src/components/Icons/ArrowInSquareIcon';
import SquareCheckedIcon from 'src/components/Icons/SquareCheckedIcon';
import Bin from 'src/components/Icons/Bin';
import Publish2Icon from 'src/components/Icons/Publish2Icon';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const WeeklyPlanHelp = ({ openHelp, setOpenHelp }: { openHelp: boolean; setOpenHelp: any }) => {
    const { t } = useTranslation();
    if (!openHelp) return <></>;
    return (
        <>
            <div className={classNames('weeklyPlan-help', { 'd-none': !openHelp })}>
                <div className="weeklyPlan-help__close-button">
                    <div
                        onClick={() => {
                            setOpenHelp(false);
                        }}
                        className="sc_hover_color_text_svg cursor-pointer"
                    >
                        <Exit />
                    </div>
                </div>
                <div className="weeklyPlan-help-description-parent">
                    <div className="weeklyPlan-help-description">
                        <div className="weeklyPlan-help-description__title">
                            {t('weeklyPlan.howDoes')}
                        </div>
                        <div className="weeklyPlan-help-description__subtitle">
                            {t('weeklyPlan.clicking')}
                        </div>
                        <div className="weeklyPlan-help-description-item">
                            <div className="weeklyPlan-help-description-item__title">
                                <MoveTo /> {t('weeklyPlan.moveContentTo')}
                            </div>
                            <div className="weeklyPlan-help-description-item__desc">
                                {t('weeklyPlan.thisMoves')}
                            </div>
                        </div>
                        <div className="weeklyPlan-help-description-item">
                            <div className="weeklyPlan-help-description-item__title">
                                <ScreenPauseIcon />
                                {t('weeklyPlan.addClassHoliday')}
                            </div>
                            <div className="weeklyPlan-help-description-item__desc">
                                {t('weeklyPlan.thisInserts')}
                            </div>
                        </div>

                        <div className="weeklyPlan-help-description-item">
                            <div className="weeklyPlan-help-description-item">
                                <div className="weeklyPlan-help-description-item__title">
                                    <ArrowInSquareIcon />
                                    {t('weeklyPlan.unpublish')}
                                </div>
                                <div className="weeklyPlan-help-description-item__desc">
                                    {t('weeklyPlan.thisremoves')}
                                </div>
                            </div>
                        </div>
                        <div className="weeklyPlan-help-description-item">
                            <div className="weeklyPlan-help-description-item__title">
                                <Publish2Icon />
                                {t('weeklyPlan.publish')}
                                <SquareCheckedIcon />
                                {t('weeklyPlan.published')}
                            </div>
                        </div>
                        <div className="weeklyPlan-help-description-item">
                            <div className="weeklyPlan-help-description-item">
                                <div className="weeklyPlan-help-description-item__title">
                                    <Bin />
                                    {t('weeklyPlan.deleteAllContent')}
                                </div>
                                <div className="weeklyPlan-help-description-item__desc">
                                    {t('weeklyPlan.thisRemoves')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="weeklyPlan-help-video">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/NNApG6lizLA"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WeeklyPlanHelp;
