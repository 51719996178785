import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { createPortal } from 'react-dom';
import SimpleCalendar from '../SimpleCalendar';
import CalendarIcon from '../Icons/CalendarIcon';
import { InputCalendarProps } from './types';

import './styles.scss';

const initialDate = new Date();
const InputCalendar = ({
    onChange,
    date,
    invalid,
    minDate,
    isUtc,
    withPortal,
    disabledDays,
}: InputCalendarProps) => {
    const calendarRef = useRef<HTMLDivElement>(null);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState('');
    const [curDate, setCurDate] = useState(initialDate);
    const portalRef = useRef<HTMLDivElement>(null);

    useOutsideClick({ current: [calendarRef?.current, portalRef?.current] }, () => {
        setOpenCalendar(false);
    });

    /*move calendar to top if does not fit bottom*/
    const WHITE_BOX_BOTTOM_PADDING_HEIGHT = 60;
    const CALENDAR_HEIGHT = 332;
    const PADDING_FOR_SEPARATE_CALENDAR_FROM_INPUT = 5;
    useEffect(() => {
        const rect = calendarRef.current?.getBoundingClientRect();
        const whiteBox = document.querySelector('.whiteBox');

        if (
            rect &&
            whiteBox &&
            whiteBox.getBoundingClientRect().bottom - (rect.bottom + CALENDAR_HEIGHT) <
                WHITE_BOX_BOTTOM_PADDING_HEIGHT
        ) {
            const s = `${rect.height + PADDING_FOR_SEPARATE_CALENDAR_FROM_INPUT}px`;
            setCalendarPosition(s);
        }
    }, [openCalendar]);

    useEffect(() => {
        if (date) {
            setCurDate(date);
        }
    }, [date]);

    const curOnChange = (_date: Date) => {
        onChange(_date);
        setOpenCalendar(false);
    };

    if (withPortal) {
        const rect = calendarRef.current?.getBoundingClientRect();

        return (
            <div className="inputCalendar sc_hover_color_text_svg" ref={calendarRef}>
                <div
                    className={classNames(
                        'inputCalendar-input-area cursor-pointer inputCalendar-withportal',
                        {
                            invalid,
                            'inputCalendar-withportal--opened': openCalendar,
                        },
                    )}
                    data-testid="open-calendar"
                    onClick={() => {
                        setOpenCalendar(!openCalendar);
                    }}
                >
                    <div className="inputCalendar-input-area__label">
                        {moment(curDate).format('DD. MMM YYYY')}
                    </div>
                    <div className="inputCalendar-input-area__button">
                        <CalendarIcon />
                    </div>
                </div>
                {createPortal(
                    <div
                        style={{
                            top: (rect as any)?.y + 50,
                            right: (rect as any)?.x + 24,
                            zIndex: 999999999999999,
                        }}
                        className={classNames('inputCalendar__calendar', { open: openCalendar })}
                        ref={portalRef}
                    >
                        <SimpleCalendar
                            onChange={curOnChange}
                            allowPartialRange={false}
                            selectRange={false}
                            defaultDate={curDate}
                            minDate={minDate}
                            isUtc={isUtc}
                            disabledDays={disabledDays}
                        />
                    </div>,
                    document.getElementById('modalPortal') || document.body,
                )}
            </div>
        );
    }

    return (
        <div className="inputCalendar sc_hover_color_text_svg" ref={calendarRef}>
            <div
                className={classNames('inputCalendar-input-area cursor-pointer', {
                    invalid,
                })}
                data-testid="open-calendar"
                onClick={() => {
                    setOpenCalendar(!openCalendar);
                }}
            >
                <div className="inputCalendar-input-area__label">
                    {moment(curDate).format('DD. MMM YYYY')}
                </div>
                <div className="inputCalendar-input-area__button">
                    <CalendarIcon />
                </div>
            </div>
            <div
                style={calendarPosition ? { bottom: calendarPosition, top: 'unset' } : {}}
                className={classNames('inputCalendar__calendar', { open: openCalendar })}
                ref={portalRef}
            >
                <SimpleCalendar
                    onChange={curOnChange}
                    allowPartialRange={false}
                    selectRange={false}
                    defaultDate={curDate}
                    minDate={minDate}
                    isUtc={isUtc}
                    disabledDays={disabledDays}
                />
            </div>
        </div>
    );
};

export default InputCalendar;
