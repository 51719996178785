import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { putUnmatchedStudents } from '../actions';

export function* getUnmatchedStudentsSaga(): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/users/unmatched`);
        yield put(putUnmatchedStudents(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
