import { useEffect, useState } from 'react';
import './styles.scss';
import Calendar from 'react-calendar';
import moment from 'moment';
import { Props } from './types';
import 'react-calendar/dist/Calendar.css';

const SimpleCalendar = ({
    navigationLabelFormat = 'MMMM YYYY',
    allowPartialRange,
    defaultDate,
    maxDate,
    minDate,
    onChange,
    selectRange,
    isUtc = false,
    disabledDays,
}: Props) => {
    const [date, setDate] = useState(defaultDate);

    useEffect(() => {
        if (defaultDate) {
            setDate(defaultDate);
        }
    }, [defaultDate]);

    return (
        <Calendar
            formatMonth={(locale, curDate) => moment(curDate).format('MMM')}
            formatShortWeekday={(_, dateFormat) => moment(dateFormat, 'DDD').toString()[0]}
            locale="en"
            value={isUtc ? moment(date).utc().toDate() : date}
            navigationLabel={(d) => moment(d.date).format(navigationLabelFormat)}
            minDate={minDate}
            maxDate={maxDate}
            selectRange={selectRange}
            allowPartialRange={allowPartialRange}
            tileDisabled={({ activeStartDate, date: d, view }) => {
                if (disabledDays?.length) {
                    return disabledDays.map((item) => item % 7).includes(d.getDay());
                }

                return false;
            }}
            onChange={(selectedDate: Date) => {
                setDate(selectedDate);
                onChange(selectedDate);
            }}
        />
    );
};

export default SimpleCalendar;
