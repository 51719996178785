import { Props } from '../types';

const ScreenPauseIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.54" height="15" viewBox="0 0 17.54 15">
        <g id="Pause" transform="translate(8.77 7.5)">
            <g id="Group_393" data-name="Group 393" transform="translate(-8.77 -7.5)">
                <path
                    id="Path_801"
                    data-name="Path 801"
                    d="M13.886,0H3.654A3.659,3.659,0,0,0,0,3.654V8.422a3.659,3.659,0,0,0,3.654,3.654H8.039v1.461H5.847a.731.731,0,1,0,0,1.462h5.847a.731.731,0,1,0,0-1.462H9.5V12.077h4.385A3.659,3.659,0,0,0,17.54,8.422V3.654A3.659,3.659,0,0,0,13.886,0Zm2.193,5.847V8.422a2.192,2.192,0,0,1-2.193,2.193H3.654A2.192,2.192,0,0,1,1.462,8.422V3.654A2.192,2.192,0,0,1,3.654,1.462H13.886a2.192,2.192,0,0,1,2.193,2.193V5.847Z"
                    fill={fill}
                />
                <path
                    id="Path_802"
                    data-name="Path 802"
                    d="M82,37.67h0l0,0Z"
                    transform="translate(-79.191 -36.379)"
                />
                <path
                    id="Path_803"
                    data-name="Path 803"
                    d="M429.865,37.67v0l0,0Z"
                    transform="translate(-415.134 -36.379)"
                />
                <g id="Group_392" data-name="Group 392" transform="translate(6.568 3.715)">
                    <rect
                        id="Rectangle_214"
                        data-name="Rectangle 214"
                        width="1.461"
                        height="4.685"
                        rx="0.73"
                        fill={fill}
                    />
                    <rect
                        id="Rectangle_215"
                        data-name="Rectangle 215"
                        width="1.461"
                        height="4.685"
                        rx="0.73"
                        transform="translate(2.912)"
                        fill={fill}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default ScreenPauseIcon;
