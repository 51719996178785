const ArrowLongThin2Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.065"
        height="14.761"
        viewBox="0 0 28.065 14.761"
    >
        <path
            id="arrow-right"
            d="M27.039,10.623,22.513,6.05a1.17,1.17,0,0,0-1.661,0h0a1.17,1.17,0,0,0,0,1.649l4.163,4.2H1.17A1.17,1.17,0,0,0,0,13.067H0a1.17,1.17,0,0,0,1.17,1.17H25.086l-4.234,4.222a1.17,1.17,0,0,0,0,1.661h0a1.17,1.17,0,0,0,1.661,0l4.526-4.538A3.509,3.509,0,0,0,27.039,10.623Z"
            transform="translate(0 -5.704)"
            fill="#54585a"
        />
    </svg>
);

export default ArrowLongThin2Icon;
