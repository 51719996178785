import sanitizeHtml from 'sanitize-html';
import { ChangeEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import classNames from 'classnames';
import CircleCheck from 'src/components/Icons/CircleCheck';
import CircleCheckGradientIcon from 'src/components/Icons/CircleCheckGradient';
import CircleCloseIcon from 'src/components/Icons/CircleClose';
import Edit from 'src/components/Icons/Edit';
import Editable from 'src/components/Editable';
import Move from 'src/components/Icons/Move';
import Remove from 'src/components/Icons/Remove';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { sanitizeConf } from 'src/components/Editable/constants';
import { selectSearchValue } from 'src/redux/search/selector';
import { notInString } from 'src/helpers/notInString';
import { CustomDraggableProps } from './types';
import {
    removeColumn,
    WEEKLY_PLAN_ITEM_CURATED_OFF,
    WEEKLY_PLAN_ITEM_CURATED_ON,
    WEEKLY_PLAN_ITEM_EDIT_OFF,
} from '../helpers';
import '../styles.scss';
import { EditableColumnType } from '../types';

const CustomDraggable = ({
    item,
    indexDraggable,
    columnIndex,
    editedColumns,
    setColumns,
    columns,
    setEditedColumns,
    editContent,
    isCuratedHead,
}: CustomDraggableProps) => {
    const [edit, setEdit] = useState('');
    const wrapperRef = useRef<{ [key: string]: HTMLDivElement | null }>();
    const searchValue = useSelector(selectSearchValue);
    useOutsideClick({ current: wrapperRef.current?.[edit] }, () => {
        setEdit('');
        setEditedColumns(columns);
    });

    const getNewColumnsForSave = (tempColumns?: EditableColumnType[][]) => {
        const newColumns: EditableColumnType[][] = JSON.parse(
            JSON.stringify(tempColumns ?? editedColumns),
        );
        const editedItem = newColumns[columnIndex as number].find(({ id }) => {
            return id === item.id;
        });
        if (editedItem) {
            const spansReg = new RegExp(/<span ?>.*?<\/span>/g);
            const spans = editedItem.content.match(spansReg)?.join('') ?? '';

            const tempText = editedItem.content
                .replaceAll(/(<div><br><\/div>)|(<div>)/gi, '<br>')
                .replaceAll(/<\/div>/gi, '')
                .replaceAll(spansReg, '');
            editedItem.content = sanitizeHtml(`${tempText}${spans}`, sanitizeConf);
        }
        return newColumns;
    };

    const updateCuratedStatus = () => {
        const newColumns = JSON.parse(JSON.stringify(editedColumns));
        const editedItem = newColumns[columnIndex as number].find(({ id }: { id: string }) => {
            return id === item.id;
        });
        editedItem.curated =
            item.curated === WEEKLY_PLAN_ITEM_CURATED_ON
                ? WEEKLY_PLAN_ITEM_CURATED_OFF
                : WEEKLY_PLAN_ITEM_CURATED_ON;
        if (editContent) {
            editContent(newColumns);
            setColumns(getNewColumnsForSave(newColumns));
            setEditedColumns(getNewColumnsForSave(newColumns));
        }
    };

    if (!item.isNewItem && item.content === '') {
        return <></>;
    }

    return (
        <Draggable key={item.id} draggableId={item.id} index={indexDraggable}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                const curated = item.curated === WEEKLY_PLAN_ITEM_CURATED_ON;
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames('weeklyPlan-draggable', {
                            'd-none': notInString(item.content, searchValue),
                            curated,
                        })}
                    >
                        {curated && (
                            <div className="weeklyPlan-draggable-curated-check">
                                <CircleCheckGradientIcon />
                            </div>
                        )}
                        <div
                            ref={(ref) => {
                                if (wrapperRef.current) {
                                    wrapperRef.current[item.id] = ref;
                                }
                            }}
                            className={classNames('weeklyPlan-editable', {
                                curated,
                            })}
                        >
                            <div
                                className={classNames('weeklyPlan-draggable-controls', {
                                    'weeklyPlan-draggable-controls--active': snapshot.isDragging,
                                    'weeklyPlan-draggable-controls--disable': edit,
                                    'd-none': item.editable === WEEKLY_PLAN_ITEM_EDIT_OFF,
                                    curated,
                                })}
                            >
                                <div
                                    className={classNames(
                                        'weeklyPlan-draggable-controls-icons sc_hover_color_text_svg',
                                        { 'd-none': !isCuratedHead },
                                    )}
                                    onClick={updateCuratedStatus}
                                >
                                    {curated ? <CircleCloseIcon /> : <CircleCheck fill="#4B5563" />}
                                </div>
                                <div
                                    {...provided.dragHandleProps}
                                    className="weeklyPlan-move grab weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                >
                                    <Move />
                                </div>
                                <div
                                    className={classNames(
                                        'weeklyPlan-draggable-controls-icons sc_hover_color_text_svg',
                                        {
                                            'd-none': isCuratedHead,
                                        },
                                    )}
                                    onClick={() => setEdit(item.id)}
                                >
                                    <Edit fill="#54585a" />
                                </div>
                                <div
                                    className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                    onClick={() =>
                                        removeColumn(columns, setColumns, columnIndex, item.id)
                                    }
                                >
                                    <Remove />
                                </div>
                            </div>

                            <Editable
                                html={
                                    edit
                                        ? editedColumns[columnIndex as number].find(({ id }) => {
                                              return id === item.id;
                                          })?.content
                                        : item.content
                                }
                                onChange={(e: ChangeEvent) => {
                                    const newColumns = JSON.parse(JSON.stringify(editedColumns));

                                    const editedItem = newColumns[columnIndex as number].find(
                                        ({ id }: { id: string }) => {
                                            return id === item.id;
                                        },
                                    );
                                    editedItem.content = (e.target as HTMLTextAreaElement).value;
                                    if (editContent) editContent(newColumns);
                                }}
                                onSave={() => {
                                    setColumns(getNewColumnsForSave());
                                    setEdit('');
                                }}
                                edit={edit === item.id}
                            />
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
};

export default CustomDraggable;
