import { motion, AnimatePresence } from 'framer-motion';
import { useNotificationQueue } from 'src/providers/NotificationProvider';
import NotificationItem from './NotificationItem';
import './styles.scss';

export default function NotificationHandler() {
    const queue = useNotificationQueue();

    return (
        <AnimatePresence>
            <div className="notifications-container">
                {queue.entries.map(({ id, data }) => (
                    <motion.div
                        key={id}
                        initial={{ opacity: 0, scale: 0.3 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.3, transition: { duration: 0.5 } }}
                    >
                        <NotificationItem id={id} notification={data} />
                    </motion.div>
                ))}
            </div>
        </AnimatePresence>
    );
}
