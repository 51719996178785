import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import {
    ADD_CLASS_HOLIDAY,
    DELETE_WEEKLY,
    GET_ASSIGMENTS,
    GET_BOOKS,
    GET_ELEARNINGS,
    GET_WEEKLY_PLAN,
    MOVE_DAY,
    RESTART,
    UPDATE_WEEKLY_DAY,
    UPDATE_WEEKLY_PLAN,
} from '../actions';
import { deleteWeeklySaga } from './deleteWeeklySaga';
import { getBooksSaga } from './getBooksSaga';
import { getWeeklyPlanSaga } from './getWeeklyPlanSaga';
import { moveDaySaga } from './moveDaySaga';
import { restartSaga } from './restartSaga';
import { updateWeeklyPlanSaga } from './updateWeeklyPlanSaga';
import { addClassHolidaySaga } from './addClassHolidaySaga';
import { getElearningSaga } from './getElearningSaga';
import { getAssigmentsSaga } from './getAssigmentsSaga';
import { updateWeeklyDaySaga } from './updateWeeklyDaySaga';

export function* weeklyPlanWatcher(): SagaIterator {
    yield takeLatest(GET_WEEKLY_PLAN, getWeeklyPlanSaga);
    yield takeLatest(UPDATE_WEEKLY_PLAN, updateWeeklyPlanSaga);
    yield takeLatest(MOVE_DAY, moveDaySaga);
    yield takeLatest(GET_BOOKS, getBooksSaga);
    yield takeLatest(RESTART, restartSaga);
    yield takeLatest(DELETE_WEEKLY, deleteWeeklySaga);
    yield takeLatest(ADD_CLASS_HOLIDAY, addClassHolidaySaga);
    yield takeLatest(GET_ELEARNINGS, getElearningSaga);
    yield takeLatest(GET_ASSIGMENTS, getAssigmentsSaga);
    yield takeLatest(UPDATE_WEEKLY_DAY, updateWeeklyDaySaga);
}
