import { createAction } from '@reduxjs/toolkit';
import {
    GetAttendanceStudentsProps,
    PutAttendanceStudentsProps,
    SaveAttendanceProps,
} from './types';

export const GET_ATTENDANCE_STUDENTS = 'GET_ATTENDANCE_STUDENTS';
export const PUT_ATTENDANCE_STUDENTS = 'PUT_ATTENDANCE_STUDENTS';
export const SAVE_ATTENDANCE = 'SAVE_ATTENDANCE';

export const getAttendanceStudents =
    createAction<GetAttendanceStudentsProps>(GET_ATTENDANCE_STUDENTS);
export const putAttendanceStudents =
    createAction<PutAttendanceStudentsProps>(PUT_ATTENDANCE_STUDENTS);
export const saveAttendance = createAction<SaveAttendanceProps>(SAVE_ATTENDANCE);
