const TestByAim = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            fill="#676E7A"
            fillOpacity="0.1"
            stroke="#DDE0E5"
        />
        <path
            d="M25.5049 11.6775C23.5549 11.6775 21.4549 12.0775 20.0049 13.1775C18.5549 12.0775 16.4549 11.6775 14.5049 11.6775C13.0549 11.6775 11.5149 11.8975 10.2249 12.4675C9.49488 12.7975 9.00488 13.5075 9.00488 14.3175V25.5975C9.00488 26.8975 10.2249 27.8575 11.4849 27.5375C12.4649 27.2875 13.5049 27.1775 14.5049 27.1775C16.0649 27.1775 17.7249 27.4375 19.0649 28.0975C19.6649 28.3975 20.3449 28.3975 20.9349 28.0975C22.2749 27.4275 23.9349 27.1775 25.4949 27.1775C26.4949 27.1775 27.5349 27.2875 28.5149 27.5375C29.7749 27.8675 30.9949 26.9075 30.9949 25.5975V14.3175C30.9949 13.5075 30.5049 12.7975 29.7749 12.4675C28.4949 11.8975 26.9549 11.6775 25.5049 11.6775ZM29.0049 24.4075C29.0049 25.0375 28.4249 25.4975 27.8049 25.3875C27.0549 25.2475 26.2749 25.1875 25.5049 25.1875C23.8049 25.1875 21.3549 25.8375 20.0049 26.6875V15.1775C21.3549 14.3275 23.8049 13.6775 25.5049 13.6775C26.4249 13.6775 27.3349 13.7675 28.2049 13.9575C28.6649 14.0575 29.0049 14.4675 29.0049 14.9375V24.4075Z"
            fill="#676E7A"
        />
        <path
            d="M21.9849 18.1875C21.6649 18.1875 21.3749 17.9875 21.2749 17.6675C21.1449 17.2775 21.3649 16.8475 21.7549 16.7275C23.2949 16.2275 25.2849 16.0675 27.1149 16.2775C27.5249 16.3275 27.8249 16.6975 27.7749 17.1075C27.7249 17.5175 27.3549 17.8175 26.9449 17.7675C25.3249 17.5775 23.5549 17.7275 22.2149 18.1575C22.1349 18.1675 22.0549 18.1875 21.9849 18.1875Z"
            fill="#676E7A"
        />
        <path
            d="M21.9849 20.8475C21.6649 20.8475 21.3749 20.6475 21.2749 20.3275C21.1449 19.9375 21.3649 19.5075 21.7549 19.3875C23.2849 18.8875 25.2849 18.7275 27.1149 18.9375C27.5249 18.9875 27.8249 19.3575 27.7749 19.7675C27.7249 20.1775 27.3549 20.4775 26.9449 20.4275C25.3249 20.2375 23.5549 20.3875 22.2149 20.8175C22.1349 20.8375 22.0549 20.8475 21.9849 20.8475Z"
            fill="#676E7A"
        />
        <path
            d="M21.9849 23.5075C21.6649 23.5075 21.3749 23.3075 21.2749 22.9875C21.1449 22.5975 21.3649 22.1675 21.7549 22.0475C23.2849 21.5475 25.2849 21.3875 27.1149 21.5975C27.5249 21.6475 27.8249 22.0175 27.7749 22.4275C27.7249 22.8375 27.3549 23.1275 26.9449 23.0875C25.3249 22.8975 23.5549 23.0475 22.2149 23.4775C22.1349 23.4975 22.0549 23.5075 21.9849 23.5075Z"
            fill="#676E7A"
        />
    </svg>
);
export default TestByAim;
