import { CSSProperties } from 'react';

export type TooltipProps = {
    id: TooltipId;
    desc: string;
    styles?: CSSProperties | undefined;
    hideButton?: boolean;
};

export enum TooltipId {
    WeeklyPlanTodayLessonExercises = 'weekly_plan_today_lesson_exercises',
    WeeklyPlanTheseAreRecommendations = 'weekly_plan_these_are_recommendations',
    WeeklyPlanPreviousAssignmentsList = 'weekly_plan_previous_assignments_list',
    WeeklyPlanAddOrEditBlock = 'weekly_plan_add_or_edit_block',
    WeeklyPlanArrowsForActivities = 'weekly_plan_arrows_for_activities',
    HomeworkAddNewBlock = 'homework_add_new_block',
    WeeklyPlanAddNewBlock = 'weekly_plan_add_new_block',
    WeeklyPlanAddNewTest = 'weekly_plan_add_new_test',
    WeeklyPlanAddHomework = 'weekly_plan_add_homework',
    WeeklyPlanAddNewZoom = 'weekly_plan_add_new_zoom',
    WeeklyPlanTeacherNotesViewable = 'weekly_plan_teacher_notes_viewable',
}
