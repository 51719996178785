const ArrowThin = ({ fill = '#54585A' }: { fill?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="5.123" height="10" viewBox="0 0 5.123 10">
        <g id="Arrow_left" data-name="Arrow left" transform="translate(2.562 5)">
            <path
                id="Path_26039"
                data-name="Path 26039"
                d="M1.634,5.507a.714.714,0,0,1,0-1.01l0,0L4.912,1.222a.714.714,0,0,0,0-1.01l0,0a.714.714,0,0,0-1.007,0L.627,3.486a2.143,2.143,0,0,0,0,3.028L3.905,9.793a.715.715,0,0,0,1.011-1.01l0,0Z"
                transform="translate(-2.562 -5)"
                fill={fill}
            />
        </g>
    </svg>
);

export default ArrowThin;
