import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import {
    DELETE_QR_CODE,
    EXTEND_QR_CODE,
    GET_QR_CODE,
    GET_QR_CODES,
    GET_STUDENT_AND_TEACHER_QR_CODE,
    REGENERATE_QR_CODE,
} from '../actions';
import { getQrCodesSaga } from './getQrCodesSaga';
import { getQrCodeSaga } from './getQrCodeSaga';
import { deleteQrCodeSaga } from './deleteQrCodeSaga';
import { extendQrCodeSaga } from './extendQrCodeSaga';
import { regenerateQrCodeSaga } from './regenerateQrCodeSaga';
import { getStudentAndTeacherQrCodeSaga } from './getStudentAndTeacherQrCodeSaga';

export function* qrWatcher(): SagaIterator {
    yield takeLatest(GET_QR_CODES, getQrCodesSaga);
    yield takeLatest(GET_QR_CODE, getQrCodeSaga);
    yield takeLatest(DELETE_QR_CODE, deleteQrCodeSaga);
    yield takeLatest(EXTEND_QR_CODE, extendQrCodeSaga);
    yield takeLatest(REGENERATE_QR_CODE, regenerateQrCodeSaga);
    yield takeLatest(GET_STUDENT_AND_TEACHER_QR_CODE, getStudentAndTeacherQrCodeSaga);
}
