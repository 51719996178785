import { Props } from '../types';

const BookOpen = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.998" height="23" viewBox="0 0 22.998 23">
        <path
            id="book-open-reader"
            d="M11.5,8.624C17.186,8.475,17.185.149,11.5,0,5.813.149,5.814,8.476,11.5,8.624Zm0-6.708a2.4,2.4,0,0,1,0,4.791A2.4,2.4,0,0,1,11.5,1.917Zm9.778,8.78A4.785,4.785,0,0,0,17.35,9.66l-3.662.666A3.823,3.823,0,0,0,11.5,11.562a3.825,3.825,0,0,0-2.19-1.235L5.648,9.66A4.792,4.792,0,0,0,0,14.375v2.676a4.79,4.79,0,0,0,3.935,4.714l6.022,1.1a8.6,8.6,0,0,0,3.085,0l6.023-1.1A4.789,4.789,0,0,0,23,17.052V14.375A4.775,4.775,0,0,0,21.277,10.7ZM10.541,21.014c-.08-.011-.161-.025-.241-.039l-6.022-1.1a2.874,2.874,0,0,1-2.361-2.829V14.374a2.875,2.875,0,0,1,3.389-2.828l3.662.666A1.915,1.915,0,0,1,10.541,14.1Zm10.541-3.962a2.874,2.874,0,0,1-2.36,2.829l-6.023,1.1c-.08.014-.161.028-.241.039V14.1a1.915,1.915,0,0,1,1.573-1.886l3.662-.666a2.874,2.874,0,0,1,3.389,2.828v2.676Z"
            fill={fill}
        />
    </svg>
);

export default BookOpen;
