import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Table from 'src/components/Table';
import { selectStaffEmailsLog } from 'src/redux/staff/selectors';
import './styles.scss';

const EmailsEmailLog = () => {
    const { t } = useTranslation();
    const emailsLog = useSelector(selectStaffEmailsLog);

    return (
        <Table
            tableRowsClassName="emailLog-table"
            header={
                <>
                    <span className="assessments-name">{t('emails.sentToUser')}</span>
                    <span className="assessments-name">{t('emails.sentByUser')}</span>
                    <span className="assessments-name">{t('emails.subject')}</span>
                    <span className="assessments-name">{t('emails.to')}</span>
                    <span className="assessments-name">{t('emails.date')}</span>
                    <span className="assessments-name">{t('emails.sentStatus')}</span>
                </>
            }
            rows={emailsLog.map((item: any) => (
                <>
                    <span className="assessments-name" title={item.receiver}>
                        {item.receiver}
                    </span>
                    <span className="assessments-name" title={item.sender}>
                        {item.sender}
                    </span>
                    <span className="assessments-name" title={item.subject}>
                        {item.subject}
                    </span>
                    <span className="assessments-name" title={item.email}>
                        {item.email}
                    </span>
                    <span className="assessments-name">
                        {moment.unix(item.timestamp).format('DD-MM-YYYY')}
                    </span>
                    <span className="assessments-name">
                        {item.status ? t('emails.sent') : t('emails.notSent')}
                    </span>
                </>
            ))}
            headerMobile={
                <>
                    <span>{t('emails.emailsLog')}</span>
                </>
            }
            rowsMobile={emailsLog.map((item: any, i: any) => {
                return {
                    head: (
                        <div className="assessments-name-mobile assessments-active">
                            <b>{i + 1}.</b>
                            {item.receiver}
                        </div>
                    ),
                    content: (
                        <>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.sentToUser')}:</b>
                                <span className="assessments-name">{item.receiver}</span>
                            </div>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.sentByUser')}:</b>
                                <span className="assessments-name">{item.sender}</span>
                            </div>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.subject')}:</b>
                                <span className="assessments-name">{item.subject}</span>
                            </div>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.to')}:</b>
                                <span className="assessments-name">{item.email}</span>
                            </div>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.date')}:</b>
                                <span className="assessments-name">
                                    {moment.unix(item.timestamp).format('DD-MM-YYYY')}
                                </span>
                            </div>
                            <div className="emailsLog-row-mob">
                                <b className="assessments-name">{t('emails.sentStatus')}:</b>
                                <span className="assessments-name">
                                    {item.status ? t('emails.sent') : t('emails.notSent')}
                                </span>
                            </div>
                        </>
                    ),
                };
            })}
        />
    );
};

export default EmailsEmailLog;
