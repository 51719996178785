import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SelectMenu from 'src/components/SelectMenu';
import CustomCalendar from 'src/components/CustomCalendar';
import CalendarIcon from 'src/components/Icons/CalendarIcon';
import { useOutsideClick } from 'src/hooks/outsideClick';
import Button from 'src/components/Button';
import { selectClasses } from 'src/redux/classes/selectors';
import { getClasses } from 'src/redux/classes/actions';
import { getAllStudents } from 'src/redux/allStudents/actions';
import { useFilterHelper } from 'src/hooks/useFilterHelper';
import { getPartialRange } from 'src/helpers/getPartialRange';
import { ActiveClasses } from '../../Classes/constants';
import {
    allStudentsAreInSchoolFilterItems,
    allStudentsDefaultClass,
    allStudentsSchoolFilterItems,
    allStudentsStudentFilterItems,
} from '../constants';

import './styles.scss';

type AllStudentsFilterProps = {
    filter: { class: string };
    setFilter: Dispatch<SetStateAction<any>>;
    setOpenFilterModal: Dispatch<SetStateAction<boolean>>;
};
const AllStudentsFilter = ({ filter, setFilter, setOpenFilterModal }: AllStudentsFilterProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { updateFilter, checkFilter } = useFilterHelper({ filter, setFilter });
    const [titles, setTitles] = useState({
        active: allStudentsStudentFilterItems(t)[0].name,
        rangeType: allStudentsAreInSchoolFilterItems(t)[0].name,
        school: allStudentsSchoolFilterItems(t)[0].name,
    } as any);
    const [disabledFilters, setDisabledFilters] = useState({} as any);

    const updateDisabledFilter = (newValue: any) => {
        const newDisabled = Object.assign(structuredClone(disabledFilters), newValue);
        setDisabledFilters({});
        setDisabledFilters(newDisabled);
    };
    const updateTitle = (newValue: Record<string, string>) => {
        const tempTitles = Object.assign(structuredClone(titles), newValue);
        setTitles({});
        setTitles(tempTitles);
    };
    const [openCalendar, setOpenCalendar] = useState(false);
    const calendarRef = useRef(null);

    const [classesForDropdown, setClassesForDropdown] = useState([{}] as {
        id: string;
        name: string;
    }[]);
    const [dateRange, setDateRange] = useState([new Date(), new Date()] as [Date] | [Date, Date]);
    const [isSearchStart, setIsSearchStart] = useState(false);
    const classesData = useSelector(selectClasses);
    const getClassesForDropdown = () => {
        const tempClasses = allStudentsDefaultClass(t) as never as { id: string; name: string }[];
        updateTitle({ class: tempClasses[0]?.name });
        classesData.map((item) => {
            return tempClasses.push({ id: item.id, name: item.name });
        });
        return tempClasses;
    };

    useOutsideClick(calendarRef, () => setOpenCalendar(false));

    useEffect(() => {
        setClassesForDropdown(getClassesForDropdown());
    }, [classesData]);

    useEffect(() => {
        if (!classesData.length) {
            dispatch(getClasses(ActiveClasses.OPEN));
        }
    }, [dispatch]);

    return (
        <>
            <div className="all-students-filter">
                <div className="all-students-filter__left-block">
                    <div className="all-students-filter__item students">
                        {t('general.status')}
                        <SelectMenu
                            wrapperClass="type_3"
                            options={allStudentsStudentFilterItems(t).map((item) => {
                                return {
                                    content: (
                                        <div title={item.name}>
                                            <span>{item.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        updateFilter({ active: item.id });
                                        updateTitle({ active: item.name });
                                    },
                                };
                            })}
                            text={titles.active}
                        />
                    </div>
                    <div className="all-students-filter__item are-in-school">
                        <SelectMenu
                            wrapperClass="type_3"
                            options={allStudentsAreInSchoolFilterItems(t).map((item) => {
                                return {
                                    content: (
                                        <div title={item.name}>
                                            <span>{item.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        updateFilter({ rangeType: item.id });
                                        updateTitle({ rangeType: item.name });
                                    },
                                };
                            })}
                            text={titles.rangeType}
                        />
                    </div>
                    <div className="all-students-filter__item calendar" ref={calendarRef}>
                        {t('general.between')}
                        <div className={classNames('all-students-filter-date-selector')}>
                            <div className="all-students-filter-date-selector__range">
                                {isSearchStart
                                    ? `${moment(dateRange[0]).format('ll')} - ${moment(
                                          dateRange[1],
                                      ).format('ll')}`
                                    : t('general.thisWeek')}
                            </div>
                            <div
                                className="dateSelector-icon pr_hover_color_text_svg_bg"
                                onClick={() => {
                                    setOpenCalendar(!openCalendar);
                                }}
                            >
                                <CalendarIcon />
                            </div>
                            {openCalendar && (
                                <div className="all-students-filter-date-selector__calendar">
                                    <CustomCalendar
                                        minDate={null}
                                        onChangeReturnType="date"
                                        allowPartialRange
                                        type="rangeCustom"
                                        onChange={(data) => {
                                            setIsSearchStart(true);
                                            const partialRange = getPartialRange(data);
                                            setDateRange(partialRange);

                                            updateFilter({
                                                rangeFrom: moment(partialRange[0]).unix(),
                                                rangeTo: moment(partialRange[1]).unix(),
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="all-students-filter__right-block">
                    <div className="all-students-filter__item school">
                        {t('general.school')}
                        <SelectMenu
                            wrapperClass="type_3"
                            options={allStudentsSchoolFilterItems(t).map(
                                (item: { name: string; id: string }) => {
                                    return {
                                        content: (
                                            <div title={item.name}>
                                                <span>{item.name}</span>
                                            </div>
                                        ),
                                        onClick: () => {
                                            if (item.id === allStudentsSchoolFilterItems(t)[1].id) {
                                                updateFilter({
                                                    school: item.id,
                                                    class: allStudentsDefaultClass(t)[0].id,
                                                });
                                                updateTitle({
                                                    school: item.name,
                                                    class: allStudentsDefaultClass(t)[0].name,
                                                });
                                                updateDisabledFilter({ class: true });
                                            } else if (
                                                filter.class === allStudentsDefaultClass(t)[0].id
                                            ) {
                                                updateFilter({ school: item.id });
                                                updateTitle({ school: item.name });
                                                updateDisabledFilter({ class: false });
                                            } else {
                                                updateFilter({ school: item.id });
                                                updateTitle({ school: item.name });
                                            }
                                        },
                                    };
                                },
                            )}
                            text={titles.school}
                        />
                    </div>
                    <div className="all-students-filter__item classes">
                        {t('general.class')}
                        <SelectMenu
                            disabled={disabledFilters.class}
                            wrapperClass="type_3"
                            options={classesForDropdown.map((item) => {
                                return {
                                    content: (
                                        <div title={item.name}>
                                            <span>{item.name}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        updateFilter({ class: item.id });
                                        updateTitle({ class: item.name });
                                    },
                                };
                            })}
                            text={titles.class}
                        />
                    </div>
                    <div className="all-students-filter__button">
                        <Button
                            onClick={() => {
                                checkFilter(() => {
                                    setOpenFilterModal(false);
                                    dispatch(getAllStudents(filter as any));
                                });
                            }}
                            type="secondary"
                        >
                            {t('general.search')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllStudentsFilter;
