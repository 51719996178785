const MinusCircle = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.66659 6.99998C3.66659 7.36665 3.96659 7.66665 4.33325 7.66665H9.66659C10.0333 7.66665 10.3333 7.36665 10.3333 6.99998C10.3333 6.63331 10.0333 6.33331 9.66659 6.33331H4.33325C3.96659 6.33331 3.66659 6.63331 3.66659 6.99998ZM6.99992 0.333313C3.31992 0.333313 0.333252 3.31998 0.333252 6.99998C0.333252 10.68 3.31992 13.6666 6.99992 13.6666C10.6799 13.6666 13.6666 10.68 13.6666 6.99998C13.6666 3.31998 10.6799 0.333313 6.99992 0.333313ZM6.99992 12.3333C4.05992 12.3333 1.66659 9.93998 1.66659 6.99998C1.66659 4.05998 4.05992 1.66665 6.99992 1.66665C9.93992 1.66665 12.3333 4.05998 12.3333 6.99998C12.3333 9.93998 9.93992 12.3333 6.99992 12.3333Z"
            fill="#1A1C1E"
        />
    </svg>
);

export default MinusCircle;
