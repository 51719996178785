const Delete = () => (
    <svg
        id="Delete"
        xmlns="http://www.w3.org/2000/svg"
        width="11.719"
        height="15"
        viewBox="0 0 11.719 15"
    >
        <path
            id="Path_419"
            data-name="Path 419"
            d="M10.505,4.453H1.213c-.01.114-.044-.5.629,9.6A1.021,1.021,0,0,0,2.858,15h6a1.021,1.021,0,0,0,1.016-.95C10.55,3.949,10.515,4.565,10.505,4.453Zm-6.48,8.67a.469.469,0,0,1-.508-.426l-.469-5.39a.469.469,0,1,1,.934-.081l.469,5.39A.469.469,0,0,1,4.025,13.123Zm2.3-.467a.469.469,0,1,1-.937,0V7.265a.469.469,0,1,1,.937,0ZM8.67,7.306,8.2,12.7a.469.469,0,0,1-.934-.081l.469-5.39a.469.469,0,1,1,.934.081ZM10.7,1.641H8.434c0-.062,0-.016,0-.623A1.019,1.019,0,0,0,7.419,0H4.3A1.019,1.019,0,0,0,3.281,1.018c0,.6,0,.561,0,.623H1.018A1.019,1.019,0,0,0,0,2.659c0,.837,0,.8,0,.857H11.715c0-.06,0-.024,0-.857A1.019,1.019,0,0,0,10.7,1.641ZM7.5,1.56a.081.081,0,0,1-.081.081H4.3a.081.081,0,0,1-.081-.081V1.018A.081.081,0,0,1,4.3.937h3.12a.081.081,0,0,1,.081.081Z"
            transform="translate(0)"
            fill="#b7b7b7"
        />
    </svg>
);

export default Delete;
