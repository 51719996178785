import { createReducer } from '@reduxjs/toolkit';
import { PUT_API_REQUEST_ERROR } from './actions';

export type ErrorState = {
    error: any;
};

const initialState = {
    error: null,
};

export const errorReducer = createReducer<ErrorState>(initialState, {
    [PUT_API_REQUEST_ERROR]: (state, action) => {
        if (typeof action.payload?.message === 'string') {
            reportError(new Error(action.payload));
        }
        state.error = action.payload;
    },
});
