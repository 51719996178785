import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import InviteStudentButtonModal from 'src/components/InviteStudentButtonModal';
import Search from 'src/components/Search';
import HeaderButton from 'src/components/HeaderButton';
import SelectMenu from 'src/components/SelectMenu';
import DuplicateIcon from 'src/components/Icons/DuplicateIcon';
import { selectDuplicatedStudents } from 'src/redux/users/selectors';
import { getDuplicatedStudents } from 'src/redux/users/actions';
import UnmatchedStudentsIcon from 'src/components/Icons/UnmatchedStudentsIcon';
import { putBreadcrumbs } from 'src/redux/layout/actions';
import TablePaginationAPI from 'src/components/TablePaginationAPI';
import { PAGINATION_LIMITS } from 'src/constants';
import './styles.scss';

type UnmatchedStudentsProps = { filterHelper: any; items: any };
const UnmatchedStudentsHeader = ({ filterHelper, items }: UnmatchedStudentsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentLimit, setCurrentLimit] = useState(PAGINATION_LIMITS[0]);
    const duplicatedStudents = useSelector(selectDuplicatedStudents);
    const { t } = useTranslation();

    const duplicatedStudentsLength =
        duplicatedStudents.length > 9 ? '9+' : duplicatedStudents.length;

    useEffect(() => {
        dispatch(
            putBreadcrumbs([
                {
                    name: t('general.students'),
                    link: `/all-students`,
                },
                {
                    name: t('general.unmatchedStudents'),
                },
            ]),
        );
        dispatch(getDuplicatedStudents({}));
    }, []);

    return (
        <>
            <div className="unmatchedStudentsHeader">
                <div className="unmatchedStudentsHeader__search-block">
                    <Search
                        onPushEnter={() => {}}
                        alwaysWide
                        onChange={(text: string) => {
                            filterHelper.updateFilter({ search: text });
                        }}
                    />
                    <div className="unmatchedStudentsHeader__filters-button">
                        <HeaderButton active to="/all-students">
                            <UnmatchedStudentsIcon />
                            <span className="unmatchedStudentsHeader__invite-student-title">
                                {t('duplicateStudents.unmatchedInvitedStudents')}
                            </span>
                        </HeaderButton>
                    </div>
                </div>

                <div className="unmatchedStudentsHeader__buttons">
                    <HeaderButton active to="/all-students">
                        <UnmatchedStudentsIcon />
                        <span className="unmatchedStudentsHeader__invite-student-title">
                            {t('duplicateStudents.unmatchedInvitedStudents')}
                        </span>
                    </HeaderButton>
                    <HeaderButton
                        to="/all-students/duplicate-students"
                        infoText={duplicatedStudentsLength}
                    >
                        <DuplicateIcon />
                        <span className="unmatchedStudentsHeader__invite-student-title">
                            {t('duplicateStudents.duplicateStudents')}
                        </span>
                    </HeaderButton>
                    <InviteStudentButtonModal page="unmatchedStudent" />
                    <div className="unmatchedStudentsHeader__limit">
                        <span className="unmatchedStudentsHeader__limit-title">
                            {t('general.show')}
                        </span>
                        <SelectMenu
                            wrapperClass="type_2"
                            options={PAGINATION_LIMITS.map((limit: any) => {
                                return {
                                    content: (
                                        <div title={limit}>
                                            <span>{limit}</span>
                                        </div>
                                    ),
                                    onClick: () => {
                                        setCurrentLimit(limit);
                                        filterHelper.updateFilter({ recordsPerPage: limit });
                                    },
                                };
                            })}
                            text={currentLimit}
                        />
                    </div>
                    <TablePaginationAPI
                        mob={false}
                        paginationProcessor={items}
                        updatePage={({ page }) => {
                            filterHelper.updateFilter({ page });
                            if (page > items.page) {
                                items.nextPage();
                            } else {
                                items.previousPage();
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};
export default UnmatchedStudentsHeader;
