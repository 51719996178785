import { createReducer } from '@reduxjs/toolkit';
import { PUT_ATTENDANCE_STUDENTS } from './actions';
import { PutAttendanceStudentsProps } from './types';

export type AttendanceState = {
    students: PutAttendanceStudentsProps[];
};

const initialState = {
    students: [],
};

export const attendanceReducer = createReducer<AttendanceState>(initialState, {
    [PUT_ATTENDANCE_STUDENTS]: (state, action) => {
        state.students = action.payload;
    },
});
