import { createAction } from '@reduxjs/toolkit';
import { StudentsData } from 'src/pages/Students/types';
import {
    AddStudentProgressTestAP,
    AssignPackagesProps,
    AssignStudentCreditsProps,
    BulkUpdateStudentsProps,
    DeleteStudentMarking,
    DeleteStudentPackage,
    DeleteStudentProgressTestAP,
    DeleteStudentProgressTestResultAP,
    DeleteStudentsProps,
    DeleteStudentTutorialAP,
    DeleteStudentUnitTestResultAP,
    GetStudentDataAP,
    GetStudentProps,
    GetStudentResultsHistoryAP,
    GetStudentsProps,
    InviteStudentMailAP,
    PutCreditsProps,
    PutStudentAuditAP,
    PutStudentEmailsAP,
    PutStudentMarkingAP,
    PutStudentProps,
    PutStudentResultsHistoryAP,
    PutStudentTestsAP,
    PutStudentTutorialsAP,
    PutUpgradesProps,
    ResetPasswordAP,
    SaveStudentCommentProps,
    SendStudentMailAP,
    UpdateStudentAP,
} from './types';

export const GET_UPGRADES = 'GET_UPGRADES';
export const GET_CREDITS = 'GET_CREDITS';
export const GET_STUDENTS = 'GET_STUDENTS';
export const PUT_STUDENTS = 'PUT_STUDENTS';
export const GET_STUDENT = 'GET_STUDENT';
export const PUT_STUDENT = 'PUT_STUDENT';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const SAVE_STUDENT_COMMENT = 'SAVE_STUDENT_COMMENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const GET_STUDENT_RESULTS_HISTORY = 'GET_STUDENT_RESULTS_HISTORY';
export const PUT_STUDENT_RESULTS_HISTORY = 'PUT_STUDENT_RESULTS_HISTORY';

export const PUT_UPGRADES = 'PUT_UPGRADES';
export const PUT_CREDITS = 'PUT_CREDITS';
export const PUT_STUDENT_AUDIT = 'PUT_STUDENT_AUDIT';
export const PUT_STUDENT_EMAILS = 'PUT_STUDENT_EMAILS';
export const PUT_STUDENT_TUTORIALS = 'PUT_STUDENT_TUTORIALS';
export const PUT_STUDENT_TESTS = 'PUT_STUDENT_TESTS';
export const PUT_STUDENT_MARKING = 'PUT_STUDENT_MARKING';
export const PUT_STUDENT_UPGRADES = 'PUT_STUDENT_UPGRADES';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const GET_STUDENT_UPGRADES = 'GET_STUDENT_UPGRADES';
export const GET_STUDENT_MARKING = 'GET_STUDENT_MARKING';
export const GET_STUDENT_AUDIT = 'GET_STUDENT_AUDIT';
export const GET_STUDENT_EMAILS = 'GET_STUDENT_EMAILS';
export const GET_STUDENT_TESTS = 'GET_STUDENT_TESTS';
export const GET_STUDENT_TUTORIALS = 'GET_STUDENT_TUTORIALS';
export const SEND_STUDENT_MAIL = 'SEND_STUDENT_MAIL';
export const INVITE_STUDENT_MAIL = 'INVITE_STUDENT_MAIL';
export const DELETE_STUDENT_TUTORIAL = 'DELETE_STUDENT_TUTORIAL';
export const ADD_STUDENT_PROGRESS_TEST = 'ADD_STUDENT_PROGRESS_TEST';
export const DELETE_STUDENT_PROGRESS_TEST = 'DELETE_STUDENT_PROGRESS_TEST';
export const DELETE_STUDENT_PROGRESS_TEST_RESULT = 'DELETE_STUDENT_PROGRESS_TEST_RESULT';
export const DELETE_STUDENT_UNIT_TEST_RESULT = 'DELETE_STUDENT_UNIT_TEST_RESULT';
export const DELETE_STUDENT_MARKING = 'DELETE_STUDENT_MARKING';
export const DELETE_STUDENT_PACKAGE = 'DELETE_STUDENT_PACKAGE';

export const ASSIGN_STUDENT_CREDITS = 'ASSIGN_STUDENT_CREDITS';
export const ASSIGN_PACKAGES = 'ASSIGN_PACKAGES';
export const BULK_UPDATE_STUDENTS = 'BULK_UPDATE_STUDENTS';

export const assignStudentCredits = createAction<AssignStudentCreditsProps>(ASSIGN_STUDENT_CREDITS);
export const assignPackages = createAction<AssignPackagesProps>(ASSIGN_PACKAGES);

export const getStudents = createAction<GetStudentsProps>(GET_STUDENTS);
export const putStudents = createAction<StudentsData>(PUT_STUDENTS);
export const getStudent = createAction<GetStudentProps>(GET_STUDENT);
export const putStudent = createAction<PutStudentProps>(PUT_STUDENT);
export const deleteStudent = createAction<DeleteStudentsProps>(DELETE_STUDENT);
export const saveStudentComment = createAction<SaveStudentCommentProps>(SAVE_STUDENT_COMMENT);
export const updateStudent = createAction<UpdateStudentAP>(UPDATE_STUDENT);
export const getStudentResultsHistory = createAction<GetStudentResultsHistoryAP>(
    GET_STUDENT_RESULTS_HISTORY,
);
export const putStudentResultsHistory = createAction<PutStudentResultsHistoryAP>(
    PUT_STUDENT_RESULTS_HISTORY,
);

export const getCredits = createAction(GET_CREDITS);
export const putCredits = createAction<PutCreditsProps>(PUT_CREDITS);

export const getUpgrades = createAction(GET_UPGRADES);
export const putUpgrades = createAction<PutUpgradesProps>(PUT_UPGRADES);
export const bulkUpdateStudents = createAction<BulkUpdateStudentsProps>(BULK_UPDATE_STUDENTS);

export const getStudentUpgrades = createAction<GetStudentDataAP>(GET_STUDENT_UPGRADES);
export const getStudentMarking = createAction<GetStudentDataAP>(GET_STUDENT_MARKING);
export const getStudentAudit = createAction<GetStudentDataAP>(GET_STUDENT_AUDIT);
export const getStudentEmails = createAction<GetStudentDataAP>(GET_STUDENT_EMAILS);
export const getStudentTests = createAction<GetStudentDataAP>(GET_STUDENT_TESTS);
export const getStudentTutorials = createAction<GetStudentDataAP>(GET_STUDENT_TUTORIALS);

export const putStudentUpgrades = createAction<PutStudentAuditAP>(PUT_STUDENT_UPGRADES);
export const putStudentEmails = createAction<PutStudentEmailsAP>(PUT_STUDENT_EMAILS);
export const putStudentTutorials = createAction<PutStudentTutorialsAP>(PUT_STUDENT_TUTORIALS);
export const putStudentTests = createAction<PutStudentTestsAP>(PUT_STUDENT_TESTS);
export const putStudentMarking = createAction<PutStudentMarkingAP>(PUT_STUDENT_MARKING);
export const putStudentAudit = createAction<PutStudentAuditAP>(PUT_STUDENT_AUDIT);

export const resetPassword = createAction<ResetPasswordAP>(RESET_PASSWORD);
export const sendStudentMail = createAction<SendStudentMailAP>(SEND_STUDENT_MAIL);
export const inviteStudentMail = createAction<InviteStudentMailAP>(INVITE_STUDENT_MAIL);
export const deleteStudentTutorial = createAction<DeleteStudentTutorialAP>(DELETE_STUDENT_TUTORIAL);
export const addStudentProgressTest =
    createAction<AddStudentProgressTestAP>(ADD_STUDENT_PROGRESS_TEST);
export const deleteStudentProgressTest = createAction<DeleteStudentProgressTestAP>(
    DELETE_STUDENT_PROGRESS_TEST,
);
export const deleteStudentProgressTestResult = createAction<DeleteStudentProgressTestResultAP>(
    DELETE_STUDENT_PROGRESS_TEST_RESULT,
);
export const deleteStudentUnitTestResult = createAction<DeleteStudentUnitTestResultAP>(
    DELETE_STUDENT_UNIT_TEST_RESULT,
);

export const deleteStudentMarking = createAction<DeleteStudentMarking>(DELETE_STUDENT_MARKING);
export const deleteStudentPackage = createAction<DeleteStudentPackage>(DELETE_STUDENT_PACKAGE);
