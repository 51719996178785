import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import {
    assignStudentCredits,
    getCredits,
    getStudentAudit,
    getStudentMarking,
} from 'src/redux/students/actions';
import SuccessModal from 'src/components/Modals/SuccessModal';
import { selectStudentCredits } from 'src/redux/students/selectors';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const ProfileInfoAssignCreditsModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const [assign, setAsign] = useState({
        flexChecks: '',
        flexLessons: '',
    });
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const credits = useSelector(selectStudentCredits);
    useEffect(() => {
        if (open) {
            dispatch(getCredits());
            setAsign({
                flexChecks: '',
                flexLessons: '',
            });
        }
    }, [open]);

    const assignCredits = (type: string) => (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLTextAreaElement;

        setAsign((prev) => {
            return {
                ...prev,
                [type]: target.value.replace(/^0+/, '').replace(/[^0-9.]/g, '') || 0,
            };
        });
    };

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            disableOutsideClick={openSuccessModal}
            open={open}
            noPadding
            onClose={() => {
                setOpenSuccessModal(false);
                onClose();
            }}
            className="creditModal"
        >
            <div className="creditModal-content">
                <div className="creditModal-head">
                    <span>{t('profile.assignCredits')}</span>
                </div>
                <div className="creditModal-body">
                    <div className="creditModal-assign">
                        <div className="creditModal-assign-body">
                            <div className="creditModal-assign-col">
                                <span>FlexChecks</span>
                                <input
                                    placeholder="0"
                                    onChange={assignCredits('flexChecks')}
                                    value={assign.flexChecks}
                                />
                            </div>
                            <div className="creditModal-assign-col">
                                <span>FlexLessons</span>
                                <input
                                    placeholder="0"
                                    onChange={assignCredits('flexLessons')}
                                    value={assign.flexLessons}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="creditModal-buttons">
                    <Button
                        type="outline"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        type="secondary"
                        onClick={() => {
                            dispatch(
                                assignStudentCredits({
                                    id: params.id as string,
                                    assign: {
                                        ai: +assign.flexChecks || 0,
                                        general: +assign.flexLessons || 0,
                                    },
                                    cb: () => {
                                        dispatch(
                                            getStudentMarking({
                                                dataType: 'ielts/markings',
                                                studentId: params.id as string,
                                            }),
                                        );
                                        dispatch(
                                            getStudentAudit({
                                                dataType: 'audit',
                                                studentId: params.id as string,
                                                limit: 4,
                                            }),
                                        );

                                        setOpenSuccessModal(true);
                                    },
                                }),
                            );
                        }}
                        disabled={
                            +assign.flexChecks > credits.ai ||
                            +assign.flexLessons > credits.general ||
                            !(+assign.flexChecks || +assign.flexLessons)
                        }
                    >
                        {t('general.OK')}
                    </Button>
                </div>
                <SuccessModal
                    lottie
                    open={openSuccessModal}
                    text={t('profile.creditsAssigned')}
                    onClose={() => {
                        setOpenSuccessModal(false);
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoAssignCreditsModal;
