import { useMemo } from 'react';

export type UsePaginationAPIProcessorReturnProps = {
    data: any[];
    pages: number;
    page: number;
    currentPageStartIndex: number;
};

export const usePaginationAPIProcessor = ({
    items,
    limit,
}: {
    items: {
        page: number;
        pages: number;
        recordsPerPage: number;
        data: Array<any>;
    };
    limit: number;
}): UsePaginationAPIProcessorReturnProps => {
    const currentPageStartIndex = useMemo(() => {
        return limit * (items.page - 1 ?? 1);
    }, [items.page, limit]);

    return {
        data: items.data,
        pages: items.pages,
        page: items.page,
        currentPageStartIndex,
    };
};
