import { Props } from '../types';

const Edit = ({ fill = '#54585a' }: Props) => (
    <svg
        id="Edit"
        xmlns="http://www.w3.org/2000/svg"
        width="12.249"
        height="12.249"
        viewBox="0 0 12.249 12.249"
    >
        <g id="_01_align_center" data-name=" 01 align center">
            <path
                id="Path_357"
                data-name="Path 357"
                d="M106.667,10.9h2.253l6.963-6.963a1.6,1.6,0,0,0,0-2.253,1.63,1.63,0,0,0-2.253,0l-6.963,6.963Zm1.021-1.83,6.664-6.664a.586.586,0,0,1,.809,0,.573.573,0,0,1,0,.809L108.5,9.881h-.809Z"
                transform="translate(-104.115 -1.205)"
                fill={fill}
            />
            <path
                id="Path_358"
                data-name="Path 358"
                d="M12.055,3.89l-.827.827V8.166H8.166v3.062H1.021v-9.7a.51.51,0,0,1,.51-.51h6L8.359.193A2.554,2.554,0,0,1,8.594,0H1.531A1.531,1.531,0,0,0,0,1.531V12.249H9.4L12.249,9.4V3.655A2.631,2.631,0,0,1,12.055,3.89ZM9.186,11.017V9.186h1.83Z"
                fill={fill}
            />
        </g>
    </svg>
);

export default Edit;
