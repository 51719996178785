import { useSelector } from 'react-redux';
import { ReactElement } from 'react';
import classNames from 'classnames';

import { selectLoading } from 'src/redux/loading/selectors';
import noData1 from 'src/assets/images/noData1.gif';
import { useTranslation } from 'react-i18next';
import AnimatedRow from './components/AnimatedRow';
import { AnimatedRowsProps, TableProps } from './types';
import './styles.scss';

const Table = ({
    header,
    rows,
    headerMobile,
    rowsMobile,
    arrowLeft,
    tableRowsClassName,
    customEmptyMessage,
}: TableProps) => {
    const { t } = useTranslation();
    const loading = useSelector(selectLoading);
    if (!rows.length && !loading) {
        if (customEmptyMessage) {
            return (
                <div className="table-noData custom-empty-message">
                    <span>{customEmptyMessage}</span>
                </div>
            );
        }
        return (
            <div className="table-noData" data-testid="table-noData">
                <img src={noData1} alt="noData" />
                <span>{t('general.noData')}</span>
            </div>
        );
    }
    return (
        <div className={classNames('table', { 'only-desc': !rowsMobile })} data-testid="table">
            <div className="table-header">{header}</div>
            <div className={classNames(tableRowsClassName, 'desc')}>
                {rows.map((row: ReactElement | AnimatedRowsProps, idx) => (
                    <div
                        className={classNames('table-row', {
                            selected: (
                                row as { props: { className: string[] } }
                            ).props.className?.includes('selected'),
                        })}
                        key={idx}
                    >
                        {row}
                    </div>
                ))}
            </div>

            {rowsMobile && (
                <>
                    <div className="table-header-mobile">{headerMobile || header}</div>
                    <div className={classNames(tableRowsClassName, 'mob')}>
                        {rowsMobile.map((row, i) => (
                            <div className="table-row-mobile" key={i}>
                                <AnimatedRow
                                    content={row.content}
                                    head={row.head}
                                    arrowLeft={arrowLeft}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Table;
