const AddNewTest = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.667" height="20" viewBox="0 0 16.667 20">
        <g id="Add_new_test" data-name="Add new test" transform="translate(8.333 10)">
            <path
                id="Path_372"
                data-name="Path 372"
                d="M10.345,0H2.5A2.5,2.5,0,0,0,0,2.5V20H16.667V6.322Zm.488,2.845,2.988,2.988H10.833ZM1.667,18.333V2.5A.833.833,0,0,1,2.5,1.667H9.167V7.5H15V18.333Zm7.5-6.667h2.5v1.667h-2.5v2.5H7.5v-2.5H5V11.667H7.5v-2.5H9.167Z"
                transform="translate(-8.333 -10)"
                fill="#54585a"
            />
        </g>
    </svg>
);

export default AddNewTest;
