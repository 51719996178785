import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { GET_AIMS_MATERIALS, GET_AIMS, GET_QUESTIONS, GET_BOOK, SAVE_UNIT_TEST } from '../actions';
import { getAimsMaterialsSaga } from './getAimsMaterialsSaga';
import { getAimsSaga } from './getAimsSaga';
import { getBookSaga } from './getBookSaga';
import { getQuestionsSaga } from './getQuestionsSaga';
import { saveUnitTestSaga } from './saveUnitTestSaga';

export function* coursePlannerWather(): SagaIterator {
    yield takeLatest(GET_AIMS_MATERIALS, getAimsMaterialsSaga);
    yield takeLatest(GET_AIMS, getAimsSaga);
    yield takeLatest(GET_QUESTIONS, getQuestionsSaga);
    yield takeLatest(GET_BOOK, getBookSaga);
    yield takeLatest(SAVE_UNIT_TEST, saveUnitTestSaga);
}
