const Hamburger = () => (
    <svg height="21" viewBox="0 0 26 21" width="26" xmlns="http://www.w3.org/2000/svg">
        <g fill="#fff">
            <rect height="3" rx="1.5" width="26" />
            <rect height="3" rx="1.5" width="10" y="9" />
            <rect height="3" rx="1.5" width="16" y="18" />
        </g>
    </svg>
);

export default Hamburger;
