import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const DuplicatedStudentsSelector = (state: RootState) => state.users.duplicatedStudents;

export const selectDuplicatedStudents = createSelector(
    createSelector(DuplicatedStudentsSelector, (duplicatedStudents) => duplicatedStudents),
    (duplicatedStudents) => duplicatedStudents,
);

export const UnmatchedStudentsSelector = (state: RootState) => state.users.unmatchedStudents;

export const selectUnmatchedStudents = createSelector(
    createSelector(UnmatchedStudentsSelector, (unmatchedStudents) => unmatchedStudents),
    (unmatchedStudents) => unmatchedStudents,
);
