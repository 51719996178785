import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteClass, getClasses } from '../actions';

export function* deleteClassSaga({ payload }: ReturnType<typeof deleteClass>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.delete(), `/staff/classes/${payload.classId}`);
        yield put(getClasses(payload.activeClassesProp));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
