import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import WhiteBox from 'src/components/WhiteBox';
import TabBar from 'src/components/TabBar';
import { getStaffReports } from 'src/redux/staff/actions';
import { selectStaffReports } from 'src/redux/staff/selectors';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { putSidebar } from 'src/redux/layout/actions';

const StaffReports = () => {
    const [tab, setTab] = useState('');
    const [frameSrc, setFrameSrc] = useState('');
    const dispatch = useDispatch();
    const staffReports = useSelector(selectStaffReports);
    const { isDesktop } = useWindowSize();
    const setFrameTabSrc = () => {
        if (!staffReports.length) {
            return '';
        }
        const frameIndex = staffReports.findIndex((item) => {
            return item.name === tab;
        });
        const report = staffReports[frameIndex];
        if (report) {
            setFrameSrc(isDesktop ? report.desktop : report.mobile);
        }
        return true;
    };

    useEffect(() => {
        setFrameTabSrc();
    }, [tab, isDesktop]);

    useEffect(() => {
        if (staffReports.length) {
            setTab(staffReports[0].name);
        }
    }, [staffReports]);

    useEffect(() => {
        dispatch(getStaffReports({}));
    }, [dispatch]);

    useEffect(() => {
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch]);

    return (
        <>
            <WhiteBox
                styles={
                    isDesktop
                        ? { height: '100%' }
                        : {
                              background: 'unset',
                              boxShadow: 'unset',
                              borderRadius: 'unset',
                              height: '100%',
                          }
                }
            >
                <div className="staff-reports">
                    <div className="staff-reports-tabBar">
                        <div className="staff-reports-tabs">
                            <TabBar
                                activeTab={tab}
                                tabs={staffReports.map((item) => {
                                    return {
                                        tabId: item.name,
                                        label: item.name,
                                        onClick: () => {
                                            setTab(item.name);
                                        },
                                    };
                                })}
                            />
                        </div>
                    </div>
                    <div className="staff-reports__frame">
                        <iframe
                            style={{ overflow: 'hidden', height: '90%', width: '100%' }}
                            title={tab}
                            frameBorder="0"
                            src={frameSrc}
                        />
                    </div>
                </div>
            </WhiteBox>
        </>
    );
};

export default StaffReports;
