import classNames from 'classnames';
import Arrow from '../Arrow';
import './styles.scss';
import { TablePaginationJSProps } from './types';

const TablePaginationJS = ({
    alwaysShow = false,
    mob = false,
    onLeft,
    onRight,
    paginationProcessor,
    type = 'pages-in-center',
}: TablePaginationJSProps) => {
    const updatePage = (right = true) => {
        const curPage = +paginationProcessor.page;
        if (right) {
            const nextPage = curPage + 1;
            onRight({ page: nextPage });
        } else {
            const previousPage = curPage > 1 ? curPage - 1 : 1;
            onLeft({ page: previousPage });
        }
    };

    if (!paginationProcessor.data.length) {
        return null;
    }

    return (
        <div
            className={classNames(
                'tablePagination',
                alwaysShow
                    ? {}
                    : {
                          mob,
                          desc: !mob,
                      },
            )}
            data-testid="tablePagination"
        >
            {type === 'pages-left' && (
                <div className="tablePagination-pages-left">
                    {paginationProcessor.getCurrentPageStartIndex() + 1} -{' '}
                    {paginationProcessor.getCurrentPageStartIndex() + paginationProcessor.limit >
                    paginationProcessor.getItemsCount()
                        ? paginationProcessor.getItemsCount()
                        : paginationProcessor.getCurrentPageStartIndex() +
                          paginationProcessor.limit}{' '}
                    of {paginationProcessor.getItemsCount()}{' '}
                </div>
            )}
            <div>
                <Arrow
                    onClick={() => updatePage(false)}
                    disabled={paginationProcessor.page <= 1}
                    direction="left"
                    dataTestId="tablePagination-left"
                />
            </div>
            {type !== 'pages-left' && `${paginationProcessor.page} of ${paginationProcessor.pages}`}
            <div>
                <Arrow
                    onClick={() => updatePage()}
                    disabled={paginationProcessor.page >= paginationProcessor.pages}
                    direction="right"
                    dataTestId="tablePagination-right"
                />
            </div>
        </div>
    );
};
export default TablePaginationJS;
