import { createAction } from '@reduxjs/toolkit';
import {
    DeleteQrCodeProps,
    ExtendQrCodeProps,
    GetQrCodeProps,
    GetStudentAndTeacherQrCodeProps,
    PutQrCodesProps,
    PutStudentAndTeacherQrCodeProps,
    RegenerateQrCodeProps,
} from './types';

export const GET_QR_CODES = 'GET_QR_CODES';
export const PUT_QR_CODES = 'PUT_QR_CODES';
export const GET_QR_CODE = 'GET_QR_CODE';
export const PUT_QR_CODE = 'PUT_QR_CODE';
export const DELETE_QR_CODE = 'DELETE_QR_CODE';
export const EXTEND_QR_CODE = 'EXTEND_QR_CODE';
export const GET_STUDENT_AND_TEACHER_QR_CODE = 'GET_STUDENT_AND_TEACHER_QR_CODE';
export const PUT_STUDENT_AND_TEACHER_QR_CODE = 'PUT_STUDENT_AND_TEACHER_QR_CODE';
export const REGENERATE_QR_CODE = 'REGENERATE_QR_CODE';
export const RESET_STUDENT_AND_TEACHER_QR_CODE = 'RESET_STUDENT_AND_TEACHER_QR_CODE';

export const getQrCodes = createAction(GET_QR_CODES);
export const putQrCodes = createAction<PutQrCodesProps>(PUT_QR_CODES);
export const getQrCode = createAction<GetQrCodeProps>(GET_QR_CODE);
export const putQrCode = createAction(PUT_QR_CODE);
export const deleteQrCode = createAction<DeleteQrCodeProps>(DELETE_QR_CODE);
export const extendQrCode = createAction<ExtendQrCodeProps>(EXTEND_QR_CODE);
export const getStudentAndTeacherQrCode = createAction<GetStudentAndTeacherQrCodeProps>(
    GET_STUDENT_AND_TEACHER_QR_CODE,
);
export const putStudentAndTeacherQrCode = createAction<PutStudentAndTeacherQrCodeProps>(
    PUT_STUDENT_AND_TEACHER_QR_CODE,
);
export const regenerateQrCode = createAction<RegenerateQrCodeProps>(REGENERATE_QR_CODE);
export const resetStudentAndTeacherQrCode = createAction(RESET_STUDENT_AND_TEACHER_QR_CODE);
