import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Add from 'src/components/Icons/Add';
import Edit from 'src/components/Icons/Edit';
import Remove from 'src/components/Icons/Remove';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Tooltip from 'src/components/Tooltip';
import { TooltipId } from 'src/components/Tooltip/types';
import { notInString } from 'src/helpers/notInString';
import { selectSearchValue } from 'src/redux/search/selector';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { useTranslation } from 'react-i18next';
import {
    CreateZoomModalType,
    MeetingType,
    WeeklyPlanTableProps,
} from 'src/containers/WeeklyPlanTable/types';
import ChartHistogram from 'src/components/Icons/ChartHistogram';
import LinkIcon from 'src/components/Icons/Link';
import moment from 'moment';
import { API } from 'src/api';
import { useParams } from 'react-router';
import { getWeeklyPlan } from 'src/redux/weeklyPlan/actions';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import CreateZoomModal from './CreateZoomModal';
import ReportZoomModal from './ReportZoomModal';

import './styles.scss';

const Zoom = ({
    lessons,
    sectionsOpenState,
    setSectionsOpenState,
    week,
    year,
    isMobile,
    activeDay,
}: Pick<
    WeeklyPlanTableProps,
    'lessons' | 'sectionsOpenState' | 'setSectionsOpenState' | 'week' | 'year'
> & { isMobile?: boolean; activeDay?: number }) => {
    const { t } = useTranslation();
    const searchValue = useSelector(selectSearchValue);
    const dispatch = useDispatch();
    const params = useParams();
    const { days: dataDays } = useSelector(selectWeeklyPlan);

    const days = activeDay ? [dataDays[activeDay - 1]] : dataDays;

    const [zoomToDelete, setZoomToDelete] = useState(null as unknown as MeetingType);
    const [report, setReport] = useState(0);
    const [zoomModal, setZoomModal] = useState({
        type: 'Close' as 'Close' | 'Edit' | 'New',
        data: {
            start: null as unknown as number,
            end: null as unknown as number,
            topic: '',
            date: null as unknown as number,
            id: 0,
        },
    } as CreateZoomModalType);

    const disabledDays = [1, 2, 3, 4, 5, 6, 7].filter((d) => {
        return !dataDays.map(({ day }) => day).includes(d);
    });

    return (
        <>
            <div className="weeklyPlan-lesson weeklyPlan-zoom" style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: isMobile ? '16px' : '36px',
                        backgroundColor: '#0B5CFF',
                    }}
                    className="weeklyPlan-sectionName"
                >
                    <div
                        style={{
                            transform: 'rotate(180deg)',
                            whiteSpace: 'nowrap',
                            color: '#FFF',
                            fontSize: isMobile ? '0.75rem' : '1rem',
                            lineHeight: '150%',
                        }}
                    >
                        {t('weeklyPlan.zoom')}
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <div
                        className={classNames('collapsible-header', {
                            opened: sectionsOpenState.zoom?.opened,
                        })}
                        onClick={() =>
                            setSectionsOpenState((prev) => ({
                                ...prev,
                                zoom: { opened: !prev.zoom?.opened },
                            }))
                        }
                    >
                        <div className="collapsible-header-text homework-zoom">
                            {t('weeklyPlan.zoom')}
                        </div>
                        <div className="collapsible-arrow-wrapper">
                            <ArrowThin fill="#9333EA" />
                        </div>
                    </div>
                    {sectionsOpenState.zoom?.opened && (
                        <div
                            style={{
                                display: 'flex',
                                minHeight: '10rem',
                                padding: '0 1.25rem 0 2.25rem',
                            }}
                        >
                            {days.map((day: any) => {
                                return (
                                    <div
                                        key={day.day}
                                        className="weeklyPlan-droppable"
                                        style={{
                                            width: `calc(100% / ${lessons.length})`,
                                        }}
                                    >
                                        <div className="weeklyPlan-add">
                                            <div
                                                className="weeklyPlan-add__svg-parent sc_hover_color_text_svg"
                                                onClick={() => {
                                                    setZoomModal({
                                                        type: 'New',
                                                        data: {
                                                            date: moment()
                                                                .day(day.day)
                                                                .isoWeekYear(year)
                                                                .isoWeek(week)
                                                                .toDate()
                                                                .getTime(),
                                                        },
                                                    });
                                                }}
                                            >
                                                <Tooltip
                                                    id={TooltipId.WeeklyPlanAddNewZoom}
                                                    desc={t('weeklyPlan.addNewZoom')}
                                                    hideButton
                                                />
                                                <Add fill="#bcbcbc" />
                                            </div>
                                        </div>
                                        {day?.meetings?.map(
                                            (meeting: MeetingType, meetingIndex: number) => {
                                                return (
                                                    <div
                                                        className={classNames(
                                                            'weeklyPlan-draggable',
                                                            {
                                                                'd-none': notInString(
                                                                    `${meeting.id}`,
                                                                    searchValue,
                                                                ),
                                                            },
                                                        )}
                                                        key={`${meeting.id}_${day.day}_${meetingIndex}__zoomIdKey`}
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            cursor: 'pointer',
                                                            boxShadow: '0 1px 6px rgb(0 0 0 / 24%)',
                                                            width: `calc(100% - 8px)`,
                                                            minHeight: 40,
                                                        }}
                                                    >
                                                        <div
                                                            className={classNames(
                                                                'weeklyPlan-draggable-controls',
                                                            )}
                                                        >
                                                            <div
                                                                className="weeklyPlan-draggable-controls-icons svg-stroke-hover-color-main"
                                                                onClick={() =>
                                                                    window.open(
                                                                        meeting.link,
                                                                        '_blank',
                                                                        'noreferrer',
                                                                    )
                                                                }
                                                            >
                                                                <LinkIcon />
                                                            </div>
                                                            <div
                                                                className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                onClick={() => {
                                                                    setZoomModal({
                                                                        type: 'Edit',
                                                                        data: {
                                                                            date: moment()
                                                                                .day(day.day)
                                                                                .isoWeekYear(year)
                                                                                .isoWeek(week)
                                                                                .toDate()
                                                                                .getTime(),
                                                                            topic: meeting.name,
                                                                            start:
                                                                                moment(
                                                                                    new Date(
                                                                                        meeting.from_date *
                                                                                            1000,
                                                                                    ),
                                                                                ).hours() *
                                                                                    60 +
                                                                                moment(
                                                                                    new Date(
                                                                                        meeting.from_date *
                                                                                            1000,
                                                                                    ),
                                                                                ).minutes(),
                                                                            end:
                                                                                moment(
                                                                                    new Date(
                                                                                        meeting.to_date *
                                                                                            1000,
                                                                                    ),
                                                                                ).hours() *
                                                                                    60 +
                                                                                moment(
                                                                                    new Date(
                                                                                        meeting.to_date *
                                                                                            1000,
                                                                                    ),
                                                                                ).minutes(),
                                                                            id: meeting.id,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <Edit />
                                                            </div>
                                                            <div
                                                                className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                onClick={() => {
                                                                    setReport(meeting.id);
                                                                }}
                                                            >
                                                                <ChartHistogram />
                                                            </div>
                                                            <div
                                                                className="weeklyPlan-draggable-controls-icons sc_hover_color_text_svg"
                                                                onClick={() => {
                                                                    setZoomToDelete(meeting);
                                                                }}
                                                            >
                                                                <Remove />
                                                            </div>
                                                        </div>
                                                        <div className="weeklyPlan-editable zoom-editable">
                                                            <span>
                                                                {moment(
                                                                    new Date(
                                                                        (meeting.from_date || 0) *
                                                                            1000,
                                                                    ),
                                                                ).format('hh:mm A')}{' '}
                                                                -{' '}
                                                                {moment(
                                                                    new Date(
                                                                        (meeting.to_date || 0) *
                                                                            1000,
                                                                    ),
                                                                ).format('hh:mm A')}
                                                            </span>
                                                            <span>{meeting.name}</span>
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>

            <CreateZoomModal
                zoomModal={zoomModal}
                setZoomModal={setZoomModal}
                year={year}
                week={week}
                disabledDays={disabledDays}
            />

            <ReportZoomModal report={report} setReport={setReport} />

            <ConfirmModal
                text={t('weeklyPlan.deleteZoom', { name: zoomToDelete?.name })}
                onClick={() => {
                    dispatch(startLoading());
                    API.delete()(`/staff/classes/${params.id}/meetings/${zoomToDelete?.id}`)
                        .then(() => {
                            dispatch(
                                getWeeklyPlan({
                                    year,
                                    week,
                                    id: params.id,
                                }),
                            );
                            setZoomToDelete(null as unknown as MeetingType);
                        })
                        .finally(() => {
                            dispatch(finishLoading());
                        });
                }}
                onClose={() => {
                    setZoomToDelete(null as unknown as MeetingType);
                }}
                open={!!zoomToDelete}
                isNewDesign
            />
        </>
    );
};

export default Zoom;
