const Notifications = () => (
    <svg
        enableBackground="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m504.5 365.9c-60.2-60.2-69.3-90.5-69.3-186.7 0-99-80.2-179.2-179.2-179.2s-179.2 80.2-179.2 179.2c0 51.9-1.4 71.3-8.5 95-8.9 29.6-27.1 58-60.8 91.7-16.1 16.1-4.7 43.7 18.1 43.7h142.1l-1.3 12.8c0 49.5 40.1 89.6 89.6 89.6s89.6-40.1 89.6-89.6l-1.3-12.8h142.1c22.8 0 34.2-27.6 18.1-43.7zm-248.5 120.5c-35.3 0-64-28.7-64-64l1.3-12.8h125.4l1.3 12.8c0 35.3-28.7 64-64 64zm-230.3-102.4c76.8-76.8 76.8-128 76.8-204.8 0-84.8 68.8-153.6 153.6-153.6s153.6 68.8 153.6 153.6c0 76.8 0 128 76.8 204.8z"
            fill="#fff"
        />
    </svg>
);

export default Notifications;
