import { Props } from '../types';

const ToDoListIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.69" height="23" viewBox="0 0 24.69 23">
        <g id="Too_do" data-name="Too do" transform="translate(12.345 11.5)">
            <g id="Group_6893" data-name="Group 6893" transform="translate(-12.345 -11.5)">
                <g id="Group_6892" data-name="Group 6892">
                    <path
                        id="Path_26511"
                        data-name="Path 26511"
                        d="M19.547,0H5.144A5.15,5.15,0,0,0,0,5.144V17.856A5.15,5.15,0,0,0,5.144,23h14.4a5.15,5.15,0,0,0,5.144-5.144V5.144A5.15,5.15,0,0,0,19.547,0Zm3.086,17.856a3.086,3.086,0,0,1-3.086,3.086H5.144a3.086,3.086,0,0,1-3.086-3.086V5.144A3.086,3.086,0,0,1,5.144,2.058h14.4a3.086,3.086,0,0,1,3.086,3.086Z"
                        fill={fill}
                    />
                </g>
                <path
                    id="Path_26512"
                    data-name="Path 26512"
                    d="M98.2,116.779H95.318a.719.719,0,0,0-.719.72v2.878a.719.719,0,0,0,.719.719H98.2a.719.719,0,0,0,.719-.719V117.5A.719.719,0,0,0,98.2,116.779Zm-.992,2.6h-.893v-.893H97.2Z"
                    transform="translate(-90.019 -111.125)"
                    fill={fill}
                />
                <path
                    id="Path_26513"
                    data-name="Path 26513"
                    d="M98.2,261.779H95.318a.719.719,0,0,0-.719.719v2.878a.719.719,0,0,0,.719.719H98.2a.719.719,0,0,0,.719-.719V262.5A.719.719,0,0,0,98.2,261.779Zm-.992,2.6h-.893v-.893H97.2Z"
                    transform="translate(-90.019 -249.106)"
                    fill={fill}
                />
                <rect
                    id="Rectangle_6435"
                    data-name="Rectangle 6435"
                    width="8.714"
                    height="2.033"
                    rx="1.017"
                    transform="translate(11.26 6.795)"
                    fill={fill}
                />
                <rect
                    id="Rectangle_6436"
                    data-name="Rectangle 6436"
                    width="8.714"
                    height="2.033"
                    rx="1.017"
                    transform="translate(11.115 13.815)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default ToDoListIcon;
