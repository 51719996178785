const Publish = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="12.5" viewBox="0 0 12.5 12.5">
        <g id="Artwork_186" data-name="Artwork 186" transform="translate(6.25 6.25)">
            <g id="Group_288" data-name="Group 288" transform="translate(-6.25 -6.25)">
                <path
                    id="Path_493"
                    data-name="Path 493"
                    d="M221.267.369A.605.605,0,0,0,220.71,0h-3.267a.6.6,0,0,0-.605.6h0a.6.6,0,0,0,.6.6h1.81l-4.82,4.822h0a.6.6,0,1,0,.852.855l4.822-4.822v1.81a.6.6,0,1,0,1.207,0V.6A.6.6,0,0,0,221.267.369Z"
                    transform="translate(-208.815 0)"
                    fill="#54585a"
                />
                <path
                    id="Path_494"
                    data-name="Path 494"
                    d="M12.23,5.955a.6.6,0,0,0-.94.5V9.77a1.509,1.509,0,0,1-.12.591,1.532,1.532,0,0,1-.552.67,1.51,1.51,0,0,1-.849.26H2.728a1.509,1.509,0,0,1-.591-.12,1.532,1.532,0,0,1-.67-.552,1.509,1.509,0,0,1-.26-.849V2.73a1.509,1.509,0,0,1,.12-.591,1.533,1.533,0,0,1,.552-.67,1.51,1.51,0,0,1,.849-.26H6.015A.605.605,0,0,0,6.57.37.607.607,0,0,0,6.352.1.6.6,0,0,0,6.015,0H2.728A2.715,2.715,0,0,0,1.666.215a2.742,2.742,0,0,0-1.2.989A2.714,2.714,0,0,0,0,2.728H0V9.77A2.732,2.732,0,0,0,2.728,12.5H9.77A2.73,2.73,0,0,0,12.5,9.772V6.456a.6.6,0,0,0-.267-.5Z"
                    transform="translate(0 0)"
                    fill="#54585a"
                />
            </g>
        </g>
    </svg>
);

export default Publish;
