import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useOutsideClick } from 'src/hooks/outsideClick';
import Exit from '../Icons/Exit';
import { ModalProps } from './types';
import './styles.scss';

const Modal = ({
    open,
    children,
    onClose,
    modalBackdropClass,
    disableOutsideClick,
    noPadding,
    fullWindow,
    overflowEnable = false,
    className,
    template = 1,
    isNewDesign,
    footer,
    header,
}: ModalProps) => {
    const [opened, setOpened] = useState(0);
    const body = document.querySelector(`body`);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
        if (!disableOutsideClick) {
            setOpened(open);
            if (onClose) {
                onClose();
            }
        }
    });

    useEffect(() => {
        setOpened(open);
    }, [open]);

    useEffect(() => {
        if (opened) {
            (body as HTMLElement).style.overflow = 'hidden';
        }

        return () => {
            (body as HTMLElement).style.overflow = 'auto';
        };
    }, [opened]);

    if (!opened) {
        return null;
    }

    if (isNewDesign) {
        return createPortal(
            <div className={classNames('modal-backdrop')} data-testid="modal">
                <div id="modalPortal" ref={wrapperRef}>
                    <div
                        className={classNames('modal-new', {
                            [className || '']: className,
                        })}
                    >
                        <div className="modal-new-header">
                            <div
                                className={classNames('modal-close sc_hover_color_text_svg')}
                                data-testid="modal-close"
                                onClick={() => {
                                    if (onClose) {
                                        onClose();
                                    }
                                }}
                            >
                                <Exit />
                            </div>
                            {header}
                        </div>

                        <div className="modal-new-body">{children}</div>

                        <div className="modal-new-footer">{footer}</div>
                    </div>
                </div>
            </div>,
            document.getElementsByTagName('body')[0] as HTMLElement,
        );
    }

    return createPortal(
        <div
            className={classNames(
                'modal-backdrop',
                `template-${template}`,
                modalBackdropClass,
                noPadding ? 'noPadding' : '',
                overflowEnable ? 'overflowEnable' : '',
            )}
            data-testid="modal"
        >
            <div
                className={classNames('modal', `template-${template}`, {
                    fullWindow,
                    overflowEnable,
                    [className || '']: className,
                })}
                ref={wrapperRef}
            >
                <div
                    className={classNames(
                        'modal-close sc_hover_color_text_svg',
                        `template-${template}`,
                    )}
                    data-testid="modal-close"
                    onClick={() => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                >
                    <Exit />
                </div>
                {children}
            </div>
        </div>,
        document.getElementsByTagName('body')[0] as HTMLElement,
    );
};

export default Modal;
