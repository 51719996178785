import { createReducer } from '@reduxjs/toolkit';
import { PUT_SCHOOLS } from './actions';

export type SchoolsReducerState = {
    schools: Array<{
        gid: number;
        name: string;
    }>;
};

export const schoolsInitialState = {
    schools: [
        {
            gid: 0,
            name: '',
        },
    ],
};

export const schoolsReducer = createReducer<SchoolsReducerState>(schoolsInitialState, {
    [PUT_SCHOOLS]: (state, action) => {
        state.schools = action.payload;
    },
});
