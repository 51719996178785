import { createAction } from '@reduxjs/toolkit';
import { EmailType, SendEmailDataType } from './types';

export const PUT_EMAILS = 'PUT_EMAILS';
export const GET_EMAILS = 'GET_EMAILS';
export const PUT_LOGS = 'PUT_LOGS';
export const SEND_EMAIL = 'POST_EMAIL';

export const putEmails = createAction<EmailType[]>(PUT_EMAILS);
export const getEmails = createAction(GET_EMAILS);
export const sendEmail = createAction<SendEmailDataType | null>(SEND_EMAIL);
