import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import {
    CLASS_PREVIEW,
    CREATE_CLASS,
    DELETE_CLASS,
    GET_CLASS,
    GET_CLASSES,
    GET_CLASSES_BY_PARAMS,
    GET_INVITES,
    SAVE_CLASS,
} from '../actions';
import { deleteClassSaga } from './deleteClassSaga';
import { getClassesSaga } from './getClassesSaga';
import { getClassSaga } from './getClassSaga';
import { classPreviewSaga } from './classPreviewSaga';
import { createNewClassSaga } from './createNewClassSaga';
import { getInvitesSaga } from './getInvitesSaga';
import { saveClassSaga } from './saveClassSaga';
import { getClassesByParamsSaga } from './getClassesByParams';

export function* classesWatcher(): SagaIterator {
    yield takeLatest(GET_CLASSES, getClassesSaga);
    yield takeLatest(GET_CLASS, getClassSaga);
    yield takeLatest(DELETE_CLASS, deleteClassSaga);
    yield takeLatest(CLASS_PREVIEW, classPreviewSaga);
    yield takeLatest(CREATE_CLASS, createNewClassSaga);
    yield takeLatest(GET_INVITES, getInvitesSaga);
    yield takeLatest(SAVE_CLASS, saveClassSaga);
    yield takeLatest(GET_CLASSES_BY_PARAMS, getClassesByParamsSaga);
}
