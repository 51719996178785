import SearchAlt from 'src/components/Icons/SearchAlt';
import Oxford from 'src/components/Icons/Oxford';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const ELearningInfoData = ({
    taskTime,
    taskLevel,
    taskPreviewUrl,
    taskOup,
    isOld,
}: {
    taskTime: number;
    taskLevel: string;
    taskPreviewUrl: string;
    taskOup: boolean;
    isOld?: boolean;
}) => {
    const { t } = useTranslation();

    if (isOld) {
        return (
            <>
                <div className="elearning-info-data-parent">
                    <div className="elearning-info-data elearning-info-data-old">
                        <span className="elearning-info-content">{taskTime}</span>
                        <span className="elearning-info-subtitle">{t('general.minutesShort')}</span>
                    </div>

                    <div className="elearning-info-data elearning-info-data-old">
                        <span className="elearning-info-content">{taskLevel}</span>
                        <span className="elearning-info-subtitle">
                            {t('general.level').toLowerCase()}
                        </span>
                    </div>

                    <a
                        className="elearning-info-data elearning-info-data-subtitle-button elearning-info-data-old"
                        href={taskPreviewUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SearchAlt />
                        <span className="elearning-info-subtitle">
                            {t('general.preview').toLowerCase()}
                        </span>
                    </a>
                </div>
                {taskOup && (
                    <div className="elearning-info-data-parent" data-testid="oxford">
                        <div className="elearning-info-oxford elearning-info-oxford-old">
                            <Oxford />
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <div className="elearning-info-data-parent">
                <div className="elearning-info-data">
                    <span className="elearning-info-content">{taskTime}</span>
                    <span className="elearning-info-subtitle">{t('general.minutesShort')}</span>
                </div>

                <div className="elearning-info-data">
                    <span className="elearning-info-content">{taskLevel}</span>
                    <span className="elearning-info-subtitle">
                        {t('general.level').toLowerCase()}
                    </span>
                </div>

                <a
                    className="elearning-info-data elearning-info-data-subtitle-button"
                    href={taskPreviewUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SearchAlt />
                    <span className="elearning-info-subtitle">
                        {t('general.preview').toLowerCase()}
                    </span>
                </a>
                {taskOup && (
                    <div className="elearning-info-oxford" data-testid="oxford">
                        <Oxford />
                    </div>
                )}
            </div>
        </>
    );
};
export default ELearningInfoData;
