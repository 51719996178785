import { useSelector, useDispatch } from 'react-redux';
import { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { selectQuestions } from 'src/redux/coursePlanner/selectors';
import { getQuestions, putQuestions } from 'src/redux/coursePlanner/actions';
import Grammar from 'src/components/Icons/Grammar';
import Vocabulary from 'src/components/Icons/Vocabulary';
import Cross from 'src/components/Icons/Cross';
import CircleCheck from 'src/components/Icons/CircleCheck';
import All from 'src/components/Icons/All';
import noData2 from 'src/assets/images/noData2.gif';
import MinusCircle from 'src/components/Icons/MinusCircle';
import { selectLoading } from 'src/redux/loading/selectors';
import Checkbox from 'src/components/Checkbox';
import { Method, QuestionType } from '../../types';
import './styles.scss';

const Row = memo(
    function Dummy({ question, isActive, onClick }: any) {
        return (
            <div
                key={question.id}
                className={classNames('makeUnit-questionsBody', {
                    'makeUnit-questionsBody--active': isActive,
                })}
                onClick={() => onClick(question.id)}
            >
                <div className="makeUnit-questionType">
                    <div className="makeUnit-questionTypeData">
                        <div className="makeUnit-questionTypeIcon">
                            {QuestionType[question.type as keyof typeof QuestionType] ===
                            QuestionType.G ? (
                                <Grammar />
                            ) : (
                                <Vocabulary />
                            )}
                        </div>
                        <span>{QuestionType[question.type as keyof typeof QuestionType]}</span>
                    </div>

                    {isActive ? (
                        <div className="makeUnit-questionTypeRemove">
                            <MinusCircle />
                            <span>Remove</span>
                        </div>
                    ) : null}
                </div>
                <span className="makeUnit-questionData">{question.question}</span>
                <div className="makeUnit-questionAnswers">
                    {question.answers.map((answer: string, index: number) => {
                        return (
                            <div key={index} className="makeUnit-questionAnswer">
                                <div className="makeUnit-questionAnswerIcon">
                                    {answer === question.answer ? <CircleCheck /> : <Cross />}
                                </div>
                                <span>{answer}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    },
    (prev, next) => {
        return prev.isActive === next.isActive;
    },
);

export default function CreateUnitTest({
    method,
    onChange,
    units,
    selectedQuestions = [],
}: {
    method?: string;
    onChange: ({ questions }: any) => void;
    units: number[];
    selectedQuestions: number[];
}) {
    const loading = useSelector(selectLoading);
    const params = useParams();
    const dispatch = useDispatch();
    const questions = useSelector(selectQuestions);
    const [activeFilter, setActiveFilter] = useState('All');
    const [activeQuestions, setActiveQuestions] = useState(selectedQuestions);

    useEffect(() => {
        const isAim = method === Method.TestByAim;
        dispatch(
            getQuestions({
                isAim,
                classId: params.id,
                units,
            }),
        );
    }, [method, units]);

    useEffect(() => {
        return () => {
            dispatch(putQuestions([]));
        };
    }, []);

    const filteredQuestions = useMemo(() => {
        const res = questions.filter((question: any) => {
            if (activeFilter === 'All') {
                return true;
            }

            const result =
                QuestionType[question.type as keyof typeof QuestionType] === activeFilter;
            return result;
        });

        return res;
    }, [questions, activeFilter, activeQuestions]);

    const filters = [
        {
            icon: (isActive: boolean) => <All fill={isActive ? 'white' : '#676E7A'} />,
            text: 'All',
            count: questions.length,
        },
        {
            icon: (isActive: boolean) => <Grammar fill={isActive ? 'white' : '#676E7A'} />,
            text: 'Grammar',
            count: questions.filter(
                (question: any) =>
                    QuestionType[question.type as keyof typeof QuestionType] === QuestionType.G,
            ).length,
        },
        {
            icon: (isActive: boolean) => <Vocabulary fill={isActive ? 'white' : '#676E7A'} />,
            text: 'Vocabulary',
            count: questions.filter(
                (question: any) =>
                    QuestionType[question.type as keyof typeof QuestionType] === QuestionType.V,
            ).length,
        },
    ];

    const handleTestRowCLick = (questionId: number) => {
        onChange({
            name: '',
        });
        setActiveQuestions((prev) => {
            if (prev.includes(questionId)) {
                const filtered = prev.filter((id: number) => id !== questionId);
                return filtered;
            }
            return [...prev, questionId];
        });
    };

    const isCheckAllChecked = useMemo(
        () =>
            filteredQuestions.filter(
                (element: { id: number }) => !activeQuestions.includes(element.id),
            ).length === 0,
        [filteredQuestions, activeQuestions],
    );

    const handleSelectAll = () => {
        const newActiveQuestions: number[] = filteredQuestions.reduce(
            (acc: number[], curr: { id: number }) => {
                const presentInActive = acc.indexOf(curr.id);
                if (presentInActive !== -1) {
                    if (isCheckAllChecked) acc.splice(presentInActive, 1);
                } else {
                    acc.push(curr.id);
                }
                return acc;
            },
            activeQuestions,
        );
        setActiveQuestions([...newActiveQuestions]);
    };

    useEffect(() => {
        onChange({
            questions: activeQuestions,
        });
    }, [activeQuestions]);

    return (
        <div className="makeUnit-stepContent createUnitTest">
            <h1 className="makeUnit-stepTitle">Add questions</h1>
            <span className="makeUnit-stepSubTitle">
                Choose questions that you want to add to your unit test
            </span>

            <div className="makeUnit-questionsFilters">
                {filters.map((filter) => {
                    return (
                        <div
                            key={filter.text}
                            className={classNames('makeUnit-questionsFilter', {
                                'makeUnit-questionsFilter--active': filter.text === activeFilter,
                            })}
                            onClick={() => {
                                setActiveFilter(filter.text);
                            }}
                        >
                            <div className="makeUnit-questionsFilterIcon">
                                {filter.icon(filter.text === activeFilter)}
                            </div>
                            <div className="makeUnit-questionsFilterContent">
                                <span className="makeUnit-questionsFilterText">{filter.text}</span>
                                <span className="makeUnit-questionsFilterCount">
                                    {filter.count}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="makeUnit-allTestsSelector">
                <Checkbox onClick={handleSelectAll} checked={isCheckAllChecked} />
                <span className="makeUnit-questions_counter">
                    {`${activeQuestions.length} Questions`}
                </span>
            </div>

            {!filteredQuestions.length && !loading ? (
                <div className="makeUnit-tableNoData">
                    <img src={noData2} alt="noData" />
                    <span>No data to display</span>
                </div>
            ) : (
                <div className="makeUnit-tableScroll createUnitTable">
                    {filteredQuestions.map((question: any) => {
                        return (
                            <Row
                                key={question.id}
                                question={question}
                                isActive={activeQuestions.includes(question.id)}
                                onClick={handleTestRowCLick}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}
