import { createReducer } from '@reduxjs/toolkit';
import { PutDuplicatedStudentsProps, PutUnmatchedStudentsProps } from './types';
import { PUT_DUPLICATED_STUDENTS, PUT_UNMATCHED_STUDENTS } from './actions';

export type UsersReducerState = {
    duplicatedStudents: PutDuplicatedStudentsProps;
    unmatchedStudents: PutUnmatchedStudentsProps;
};

const initialState = {
    duplicatedStudents: [],
    unmatchedStudents: [],
};

export const usersReducer = createReducer<UsersReducerState>(initialState, {
    [PUT_DUPLICATED_STUDENTS]: (state, action) => {
        state.duplicatedStudents = action.payload;
    },
    [PUT_UNMATCHED_STUDENTS]: (state, action) => {
        state.unmatchedStudents = action.payload;
    },
});
