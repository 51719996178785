export const getInlineLangLevel = (level: number, t: any): string => {
    switch (level) {
        case 2:
            return `A1 - ${t('profile.elementary')}`;
        case 3:
            return `A2 - ${t('profile.preIntermediate')}`;
        case 4:
            return `B1 - ${t('profile.intermediate')}`;
        case 5:
            return `B2 - ${t('profile.upperIntermediate')}`;
        case 6:
            return `C1 - ${t('profile.advanced')}`;
        default:
            return t('profile.none');
    }
};
