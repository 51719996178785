import { validateValue } from 'src/helpers/validateValue';

export type InputProcessorReturnProps = {
    updateInput: (newValue: { [key: string | number]: any }) => void;
    updateError: (newError: { [key: string | number]: any }) => void;
    getInlineErrors: (inputName: any) => string;
    getValue: (inputName: any) => any;
    hasError: (inputName: any) => boolean;
    valid: (inputName: any) => boolean;
    clearInput: (inputName: string | Array<string>) => void;
    errors: any[];
    values: any[];
    startLoading: (inputName: any) => void;
    stopLoading: (inputName: any) => void;
    isLoading: (inputName: any) => boolean;
    validateAll: (validators: InputProcessorValidators, runIfValidate: () => void) => void;
};

export type InputProcessorValidators = {
    [key: string]: {
        empty?: boolean | 1 | 0;
        maxLength?: number;
        email?: 1 | 0 | boolean;
        byRule?: () => string | null | undefined;
    };
};

type Inputs = {
    inputs: {
        values: any[];
        errors: any[];
        loading: any[];
        labels?: { [key: string]: string };
    };
    setInputs: any;
};

const useInputProcessor = ({ inputs, setInputs }: Inputs): InputProcessorReturnProps => {
    const reRender = (type?: 'loading') => {
        const tempInputs = structuredClone(inputs);
        switch (type) {
            case 'loading':
                setInputs({ values: tempInputs.values, errors: [], loading: [] });
                setInputs(tempInputs);
                break;
            default:
                setInputs({ values: [], errors: [], loading: [] });
                setInputs(tempInputs);
                break;
        }
    };

    const updateInput = (newValue: any) => {
        Object.assign(inputs.values, newValue);
        reRender();
    };

    const updateError = (newError: any) => {
        Object.assign(inputs.errors, newError);
        reRender();
    };

    const getInlineErrors = (inputName: any) => {
        let curErrors = '';
        if (inputs.errors && inputs.errors[inputName]) {
            Object.values(inputs.errors[inputName]).forEach((value) => {
                curErrors += value;
            });
        }
        return curErrors;
    };

    const clearInput = (inputName: string | Array<string>) => {
        if (typeof inputName === 'string') {
            updateInput({ [inputName]: '' });
            updateError({ [inputName]: '' });
        } else {
            const toClean = Object.assign(
                {},
                ...inputName.map((item) => {
                    return { [item]: '' };
                }),
            );
            updateInput(toClean);
            updateError(toClean);
        }
    };

    const getValue = (inputName: any) => {
        return inputs.values[inputName];
    };

    const hasError = (inputName: any) => {
        const flag = [];
        if (Array.isArray(inputName)) {
            if (inputs.errors && Object.keys(inputs.errors).length) {
                inputName.forEach((name) => {
                    if (inputs.errors[name] && Object.keys(inputs.errors[name]).length) {
                        flag.push(true);
                    }
                });
            }
        } else if (inputs.errors[inputName] && Object.keys(inputs.errors[inputName]).length) {
            flag.push(!!Object.keys(inputs.errors[inputName]).length);
        }
        return !!flag.length;
    };

    const valid = (inputName: any) => {
        return !Object.keys(inputs.errors[inputName]).length;
    };

    const startLoading = (inputName: any) => {
        Object.assign(inputs.loading, { [inputName]: true });
        reRender('loading');
    };

    const stopLoading = (inputName: any) => {
        Object.assign(inputs.loading, { [inputName]: false });
        reRender('loading');
    };

    const isLoading = (inputName: any) => {
        return !!inputs.loading[inputName];
    };

    const validateAll = (validators: InputProcessorValidators, runIfValidate: () => void) => {
        const getLabel = (inputName: string) => {
            if (inputs && inputs.labels) {
                return inputs.labels[inputName];
            }
            return '';
        };
        const tempErrors: any = [];
        const validate = (curValidator: any) => {
            const val = getValue(curValidator[0]);
            const validateResult = validateValue(val, getLabel(curValidator[0]), curValidator[1]);
            if (Object.keys(validateResult).length) {
                tempErrors.push(1);
            }
            updateError({
                [curValidator[0]]: validateResult,
            });
        };
        Object.entries(validators).forEach((curValidator) => validate(curValidator));
        if (!tempErrors.length && runIfValidate) {
            runIfValidate();
        }
    };

    return {
        updateInput,
        updateError,
        getInlineErrors,
        getValue,
        hasError,
        valid,
        clearInput,
        errors: inputs.errors,
        values: inputs.values,
        startLoading,
        stopLoading,
        isLoading,
        validateAll,
    };
};
export default useInputProcessor;
