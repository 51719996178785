import AssessmentsIcon from 'src/components/Icons/AssessmentsIcon';
import AttendanceIcon from 'src/components/Icons/AttendanceIcon';
import InfoIcon from 'src/components/Icons/InfoIcon';
import OnlineTestingIcon from 'src/components/Icons/OnlineTestingIcon';
import ReportIcon from 'src/components/Icons/ReportIcon';
import StudentsIcon from 'src/components/Icons/StudentsIcon';
import WeeklyPlanIcon from 'src/components/Icons/WeeklyPlanIcon';
import BookOpenIcon from 'src/components/Icons/BookOpen';
import EmailsIcon from 'src/components/Icons/EmailsIcon';
import ToDoListIcon from 'src/components/Icons/ToDoListIcon';
import SettingsGearIcon from 'src/components/Icons/SettingsGearIcon';
import QRCodesIcon from 'src/components/Icons/QRCodesIcon';
import { appConstants } from 'src/constants';
import { Modules } from 'src/hooks/useIsModuleActive';

export const sideItems = (t: any) =>
    ({
        root: [
            {
                name: t('sidebar.classes'),
                desc: t('sidebar.classesTitle'),
                link: '/',
                searchLink: '/$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <BookOpenIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.Classes,
                showOnMobile: true,
            },
            {
                name: t('sidebar.students'),
                desc: t('sidebar.studentTitle'),
                link: '/all-students',
                searchLink: `(/all-students.*)`,
                icon: (isActive: boolean, forceFill?: string) => (
                    <StudentsIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.Students,
                showOnMobile: true,
            },

            {
                name: t('sidebar.schoolReports'),
                desc: t('sidebar.schoolReportsTitle'),
                link: '/school-reports',
                searchLink: '/school-reports$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <ReportIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.Reports,
                showOnMobile: true,
            },

            {
                name: t('sidebar.emails'),
                desc: t('sidebar.emailsTitle'),
                link: '/emails',
                searchLink: '/emails$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <EmailsIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.Emails,
            },

            {
                name: t('sidebar.toDoList'),
                desc: t('sidebar.toDoListTitle'),
                link: `/to-do-list`,
                searchLink: '/to-do-list$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <ToDoListIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.Todo,
                showOnMobile: true,
            },

            {
                name: t('sidebar.information'),
                desc: t('sidebar.informationTitle'),
                link: `//${appConstants.server}/administration/information`,
                searchLink: '/administration/information$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <InfoIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.Information,
                showOnMobile: true,
                showOnMobileOnStaffOnly: true,
            },

            {
                name: t('sidebar.qrCodes'),
                desc: t('sidebar.qrCodesTitle'),
                link: `/qr-codes`,
                searchLink: '/qr-codes$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <QRCodesIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.QRCodes,
                showOnMobile: true,
            },

            {
                name: t('sidebar.settings'),
                desc: t('sidebar.settingsTitle'),
                link: `//${appConstants.server}/administration`,
                searchLink: '\\/settings$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <SettingsGearIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                showOnlyAdmin: true,
                id: Modules.Settings,
                showOnMobile: true,
            },
        ],
        classSelected: [
            {
                name: t('sidebar.weeklyPlan'),
                desc: t('sidebar.weeklyPlanTitle'),
                link: '/weekly-plan',
                searchLink: '\\/weekly-plan\\/\\d*$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <WeeklyPlanIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassWeeklyPlan,
                showOnMobile: true,
            },
            {
                name: t('sidebar.reports'),
                desc: t('sidebar.reportsTitle'),
                link: '/reports',
                searchLink: '\\/reports\\/\\d*$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <ReportIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassReports,
                showOnMobile: true,
            },
            {
                name: t('sidebar.studentsClass'),
                desc: t('sidebar.studentsClassTitle'),
                link: '/students',
                searchLink: '(/students/\\d*)',
                icon: (isActive: boolean, forceFill?: string) => (
                    <StudentsIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassStudents,
                showOnMobile: true,
            },
            {
                name: t('sidebar.assessments'),
                desc: t('sidebar.assessmentsTitle'),
                link: '/assessments',
                searchLink: '/assessments\\/\\d*$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <AssessmentsIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassAssessments,
                showOnMobile: true,
            },
            {
                name: t('sidebar.onlineTesting'),
                desc: t('sidebar.onlineTestingTitle'),
                link: '/online-testing/automated-tests',
                searchLink:
                    '\\/(online-testing\\/automated-tests|online-testing\\/progress-tests)\\/\\d*$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <OnlineTestingIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassTests,
                showOnMobile: true,
            },
            {
                name: t('sidebar.attendance'),
                desc: t('sidebar.attendanceTitle'),
                link: '/attendance',
                searchLink: '/attendance\\/\\d*$',
                icon: (isActive: boolean, forceFill?: string) => (
                    <AttendanceIcon fill={forceFill || (isActive ? '#007fa3' : '#54585a')} />
                ),
                id: Modules.ClassAttendance,
                showOnMobile: true,
            },
        ],
    } as any);
