const EmptyCircle = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.00008 0.666687C4.40008 0.666687 0.666748 4.40002 0.666748 9.00002C0.666748 13.6 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6 17.3334 9.00002C17.3334 4.40002 13.6001 0.666687 9.00008 0.666687ZM9.00008 15.6667C5.31675 15.6667 2.33341 12.6834 2.33341 9.00002C2.33341 5.31669 5.31675 2.33335 9.00008 2.33335C12.6834 2.33335 15.6667 5.31669 15.6667 9.00002C15.6667 12.6834 12.6834 15.6667 9.00008 15.6667Z"
            fill="#005FAB"
        />
    </svg>
);

export default EmptyCircle;
