import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { getTimezoneForRequest } from 'src/helpers/getTimezoneForRequest';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { assignProgress, getProgress } from '../actions';

export function* assignProgressSaga({ payload }: ReturnType<typeof assignProgress>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(
            API.post(),
            `/staff/classes/${payload.classId}/progress-tests/assigned?${getTimezoneForRequest()}`,
            {
                id: payload.testId,
                from: payload.from,
                to: payload.to,
            },
        );
        yield put(
            getProgress({
                classId: payload.classId,
                type: payload.type,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
