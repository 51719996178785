import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { handleResourceMove } from 'src/containers/WeeklyPlanTable/helpers/handleResourceMove';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { onDragEnd } from 'src/containers/WeeklyPlanTable/helpers';
import Modal from '../../Modal';
import './styles.scss';
import CustomCalendar from '../../CustomCalendar';
import { Props } from './types';
import Button from '../../Button';
import MoveToAnimatedDropdown from './MoveToAnimatedDropdown';
import CalendarArrow from '../../Icons/CalendarArrow';

const MoveToModal = ({
    day,
    setMoveToModalData,
    moveToModalData,
    lessons,
    lessonSubNames,
    buttonText = 'Move',
    navigationLabelFormat,
    lessonNames,
    allowPartialRange,
    columns,
    setColumns,
    setIsDirty,
    getUpdatedDays,
    setDays,
    week,
    year,
}: Props) => {
    const ref = useRef(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const setDateByDay = (currentYear: number, currentWeek: number, currentDay: number) => {
        return moment()
            .isoWeekYear(currentYear)
            .isoWeek(currentWeek)
            .startOf('isoWeek')
            .add(currentDay, 'days')
            .toDate();
    };
    const [date, setDate] = useState(setDateByDay(year, week, day));

    useOutsideClick(ref, () => {
        setMoveToModalData(null);
    });

    useEffect(() => {
        setDate(setDateByDay(year, week, day));
    }, [day, week, year]);

    const getNextWeekStart = () => {
        const today = moment(date);
        const daysToMonday = 0 - (today.isoWeekday() - 1) + 4;
        return today.add(daysToMonday, 'days').toDate();
    };
    const getWeekStartDay = () => {
        const today = moment(date);
        return today.startOf('isoWeek').toDate();
    };
    const getMomentTitle = () => {
        const today = moment(date);
        return `${moment(date).format('dddd, D.')} ${today.format('MMM').toUpperCase()}`;
    };

    const currentOnClose = () => {
        setDate(setDateByDay(year, week, day));
        setMoveToModalData(null);
    };

    const processMove = () => {
        switch (moveToModalData.type) {
            case 'resources':
                handleResourceMove({
                    result: {
                        ...moveToModalData.provided,
                        destination: { droppableId: moment(date).isoWeekday() - 1, index: 9999 },
                    },
                    getUpdatedDays,
                    columns,
                    setDays,
                    setIsDirty,
                    lessons,
                });
                break;
            default: {
                const { provided, item, lessonIndex, indexDraggable } = moveToModalData;
                const result = {
                    source: {
                        droppableId: `${day}-${item.head}-${item.columnId}-${item.lessonId}-${lessonIndex}`,
                        index: indexDraggable,
                    },
                    destination: {
                        droppableId: `${selectedSubCategory}-${lessonIndex}}`,
                        index: columns[moment(date).isoWeekday() - 1].length,
                    },
                    draggableId: provided.dragHandleProps['data-rbd-drag-handle-draggable-id'],
                };
                if (selectedSubCategory) {
                    onDragEnd(result, columns, lessonNames, setColumns);
                }
                break;
            }
        }
    };

    return (
        <>
            <Modal open={moveToModalData} onClose={currentOnClose}>
                <div>
                    <div className="move-to-modal" ref={ref}>
                        <span className="move-to-modal-title">
                            <CalendarArrow />
                            <span className="move-to-modal__title-text">{getMomentTitle()}</span>
                        </span>
                        <CustomCalendar
                            minDate={getWeekStartDay()}
                            maxDate={getNextWeekStart()}
                            definedDate={date}
                            type="defined"
                            onChangeReturnType="date"
                            allowPartialRange={allowPartialRange}
                            navigationLabelFormat={navigationLabelFormat}
                            onChange={(data) => {
                                setDate(data);
                            }}
                        />
                        <MoveToAnimatedDropdown
                            selectedSubCategory={selectedSubCategory}
                            setSelectedSubCategory={setSelectedSubCategory}
                            date={date}
                            moveToModalData={moveToModalData}
                            lessonNames={lessonNames}
                            lessonSubNames={lessonSubNames}
                            lessons={lessons}
                            day={day}
                        />
                        <div className="move-to-modal-button">
                            <Button
                                type={
                                    selectedSubCategory || moveToModalData?.type === 'resources'
                                        ? 'secondary'
                                        : 'square'
                                }
                                onClick={() => {
                                    processMove();
                                    currentOnClose();
                                }}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default MoveToModal;
