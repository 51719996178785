import classNames from 'classnames';
import { TabBarProps } from './types';
import './styles.scss';

const TabBar = ({ tabs, activeTab, mobUnderline }: TabBarProps) => {
    return (
        <div
            className={classNames('tabBar', { 'tabBar-mob-underline': mobUnderline })}
            data-testid="tabBar"
        >
            {tabs.map((item) => {
                return (
                    <span
                        key={item.tabId}
                        className={classNames('tabBar__tab sc_hover_color_text_svg', {
                            'tabBar__tab--active': activeTab === item.tabId,
                        })}
                        onClick={() => item.onClick()}
                        data-testid={`tabBar-${item.tabId}`}
                    >
                        {item.label}
                    </span>
                );
            })}
        </div>
    );
};

export default TabBar;
