import classNames from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import loader from 'src/assets/images/loaderCircle.gif';
import { useOutsideClick } from 'src/hooks/outsideClick';
import ArrowBoldDown from '../Icons/ArrowBoldDown';
import InputTextDropdownSelect from './InputTextDropdownSelect';
import { InputTextDropdownProps } from './props';
import './styles.scss';

const InputTextDropdown = ({
    error,
    value,
    fullWidth,
    options,
    dropdownMaxHeight,
    load,
    onChange,
    template,
    onSelect,
    errorAsBlock = false,
}: InputTextDropdownProps) => {
    const [open, setOpen] = useState(false);
    const [dropdownIndex, setDropdownIndex] = useState<number>(-1);
    const [button, setButton] = useState('');
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => setOpen(false));

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const optionsCount = options.length;
        const { key } = event;
        if (key === 'ArrowDown' && dropdownIndex < optionsCount - 1) {
            setDropdownIndex(dropdownIndex + 1);
            setButton(key);
            event.preventDefault();
        } else if (key === 'ArrowUp' && dropdownIndex > 0) {
            setDropdownIndex(dropdownIndex - 1);
            setButton(key);
            event.preventDefault();
        } else if (key === 'Enter') {
            if (!options.length || dropdownIndex < 0) return;
            setOpen(false);
            onSelect(options[dropdownIndex].title, options[dropdownIndex].id);
            event.preventDefault();
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDropdownIndex(-1);
        setOpen(true);
        onChange(e.target.value);
    };

    const handleOnDropdownClick = (curDropdownIndex: number) => {
        setDropdownIndex(curDropdownIndex);
        setOpen(false);
        onSelect(options[curDropdownIndex].title, options[curDropdownIndex].id);
    };

    return (
        <div
            className={classNames('inputTextDropdown', `template-${template}`, { open })}
            ref={wrapperRef}
        >
            <div
                className={classNames('inputTextDropdown-input', {
                    options: open && options.length,
                    invalid: error,
                })}
            >
                <input
                    onFocus={() => {
                        setOpen(true);
                    }}
                    data-testid="test-inputTextDropdown__input"
                    onClick={() => {
                        setOpen(true);
                    }}
                    className={classNames('inputTextDropdown__field', {
                        fullWidth,
                    })}
                    type="text"
                    onKeyDown={handleKeyDown}
                    onChange={handleOnChange}
                    value={value ?? ''}
                />
                <div
                    onClick={() => {
                        setOpen(!open);
                    }}
                    className={classNames('inputTextDropdown-dropdown-arrow cursor-pointer', {
                        active: open && options,
                    })}
                >
                    {load ? (
                        <img className="inputTextDropdown-loader" src={loader} alt="running cat" />
                    ) : (
                        <ArrowBoldDown />
                    )}
                </div>
            </div>
            <InputTextDropdownSelect
                dropdownMaxHeight={dropdownMaxHeight}
                open={open}
                button={button}
                dropdownIndex={dropdownIndex}
                options={options}
                onClick={handleOnDropdownClick}
            />
            <div className={classNames('inputTextDropdown-error', { errorAsBlock })} title={error}>
                {error}
            </div>
        </div>
    );
};

export default InputTextDropdown;
