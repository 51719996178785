import { createReducer } from '@reduxjs/toolkit';
import { BreadItem } from 'src/components/Breadcrumbs/types';
import { PUT_BREADCRUMBS, PUT_LAYOUT, PUT_MODULES, PUT_SIDEBAR } from './actions';
import { PutLayoutProps, PutModuleProps } from './types';

export type LayoutState = {
    breadcrumbs: BreadItem[];
    showSidebar: 'classSelected' | 'root' | undefined;
    layout: PutLayoutProps;
    modules: PutModuleProps | null;
};

const modules = JSON.parse(localStorage.getItem('modules') ?? (null as unknown as string));
const layout = JSON.parse(localStorage.getItem('layout') ?? (null as unknown as string));

const initialState = {
    breadcrumbs: [],
    showSidebar: undefined,
    layout: layout || {
        isIelts: false,
        styling: {
            mainColor: '',
            headerColor: '',
            linesColor: '',
            iconsColor: '',
            fontColor: '',
            hoverColor: '',
            navLogoUrl: '',
            logoUrl: '',
            video: '',
            breadcrumbsLogo: '',
            agentLogoUrl: '',
            menuItems: '',
            template: '',
        },
        user: {
            uid: 0,
            name: '',
            mail: '',
            staff: false,
            admin: null,
            created: 0,
            phoneNumber: null,
        },
        school: {
            gid: 0,
            name: '',
            chain: '',
            social: {
                twitter: '',
                facebook: '',
                instagram: '',
                youtube: '',
            },
            infoPages: false,
            'pre-sessional': false,
        },
        cambridge: false,
        oup: false,
        chart: '',
        language: '',
    },
    modules: modules || null,
};

export const layoutReducer = createReducer<LayoutState>(initialState, {
    [PUT_BREADCRUMBS]: (state, action) => {
        state.breadcrumbs = action.payload;
    },
    [PUT_SIDEBAR]: (state, action) => {
        state.showSidebar = action.payload;
    },
    [PUT_LAYOUT]: (state, action) => {
        state.layout = action.payload;
    },
    [PUT_MODULES]: (state, action) => {
        state.modules = action.payload;
    },
});
