import React from 'react';
import './styles.scss';
import { PAGINATION_LIMITS } from 'src/constants';
import SelectMenu from 'src/components/SelectMenu';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { TablePaginationLimitsProps } from './types';

const TablePaginationLimits = ({
    title = '',
    filter,
    updateFilter,
    hide,
}: TablePaginationLimitsProps) => {
    const { isDesktop } = useWindowSize();
    if (hide) {
        return <></>;
    }
    return (
        <div className="tablePaginationLimits">
            <div className="tablePaginationLimits-title">{isDesktop ? title : 'Records:'}</div>
            <SelectMenu
                alwaysWide
                wrapperClass="type_2"
                options={PAGINATION_LIMITS.map((limit) => {
                    return {
                        content: (
                            <div title={limit.toString()}>
                                <span>{limit}</span>
                            </div>
                        ),
                        onClick: () => {
                            updateFilter({ recordsPerPage: limit, limit });
                        },
                    };
                })}
                text={filter.limit}
            />
        </div>
    );
};
export default TablePaginationLimits;
