import { Props } from '../types';

const CommentIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="comment-alt"
        width="12.264"
        height="12.264"
        viewBox="0 0 12.264 12.264"
    >
        <path
            id="Path_630"
            data-name="Path 630"
            d="M10.22,0H2.044A2.044,2.044,0,0,0,0,2.044V8.176A2.044,2.044,0,0,0,2.044,10.22H3.526L5.8,12.143a.511.511,0,0,0,.66,0L8.738,10.22H10.22a2.044,2.044,0,0,0,2.044-2.044V2.044A2.044,2.044,0,0,0,10.22,0Zm1.022,8.176A1.022,1.022,0,0,1,10.22,9.2H8.738a1.022,1.022,0,0,0-.66.242L6.132,11.083,4.187,9.439h0A1.022,1.022,0,0,0,3.526,9.2H2.044A1.022,1.022,0,0,1,1.022,8.176V2.044A1.022,1.022,0,0,1,2.044,1.022H10.22a1.022,1.022,0,0,1,1.022,1.022Z"
            fill={fill}
        />
        <path
            id="Path_631"
            data-name="Path 631"
            d="M6.511,6.022H9.066A.511.511,0,0,0,9.066,5H6.511a.511.511,0,1,0,0,1.022Z"
            transform="translate(-2.934 -2.445)"
            fill={fill}
        />
        <path
            id="Path_632"
            data-name="Path 632"
            d="M11.621,9H6.511a.511.511,0,0,0,0,1.022h5.11a.511.511,0,0,0,0-1.022Z"
            transform="translate(-2.934 -4.401)"
            fill={fill}
        />
        <path
            id="Path_633"
            data-name="Path 633"
            d="M11.621,13H6.511a.511.511,0,1,0,0,1.022h5.11a.511.511,0,1,0,0-1.022Z"
            transform="translate(-2.934 -6.357)"
            fill={fill}
        />
    </svg>
);

export default CommentIcon;
