import { AssignedTestProps } from 'src/containers/AutomatedTests/types';

export const getTestsWithCurrentlySelected = (tests: AssignedTestProps[], state: any) => {
    const tempTests = structuredClone(tests);
    if (state && state.test) {
        let indexToSetActive = null;
        indexToSetActive = tests.findIndex((test) => {
            return +test.id === +state.test.assignmentId && +test.report === +state.test.reportId;
        });
        if (indexToSetActive >= 0) {
            tempTests[indexToSetActive].currentlySelected = true;
        }
    }
    return tempTests;
};
