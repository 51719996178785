import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getStudentAndTeacherQrCode, putStudentAndTeacherQrCode } from '../actions';

export function* getStudentAndTeacherQrCodeSaga({
    payload,
}: ReturnType<typeof getStudentAndTeacherQrCode>): SagaIterator {
    try {
        yield put(startLoading());
        let data = null;

        if (payload.classId) {
            data = yield call(API.get(), `/staff/classes/${payload.classId}/invites`);
        } else {
            data = yield call(API.get(), `/staff/users/invites`);
        }

        yield put(putStudentAndTeacherQrCode(data));

        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
