import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Envelope from 'src/components/Icons/Envelope';
import CustomLink from 'src/components/CustomLink';
import TextWithIcon from 'src/components/TextWithIcon';
import Search from 'src/components/Search';
import WhiteBox from 'src/components/WhiteBox';
import Add from 'src/components/Icons/Add';
import { deleteClass, getClass, getClasses } from 'src/redux/classes/actions';
import { selectClass, selectClasses } from 'src/redux/classes/selectors';
import Table from 'src/components/Table';
import Delete from 'src/components/Icons/Delete';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import MobileControls from 'src/components/MobileControls';
import SettingsIcon from 'src/components/Icons/Settings';
import SelectMenu from 'src/components/SelectMenu';
import Checkbox from 'src/components/Checkbox';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { getNotifications } from 'src/redux/notifications/actions';
import { selectNotifications } from 'src/redux/notifications/selectors';
import ManPlus from 'src/components/Icons/ManPlus';
import CreateClassModal from 'src/components/Modals/CreateClassModal';
import { selectLayout } from 'src/redux/layout/selectors';
import EditClassModal from 'src/components/Modals/EditClassModal';
import SortableHeader from 'src/components/SortableHeader';
import useSortable from 'src/hooks/useSortable';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { ClassesData } from './types';
import { ActiveClasses } from './constants';
import './styles.scss';

const Classes = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classesData = useSelector(selectClasses);
    const notifications = useSelector(selectNotifications);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [activeClasses, setActiveClasses] = useState(ActiveClasses.OPEN);
    const [classToDelete, setClassToDelete] = useState<string | number>(0);
    const [createClassModal, setCreateClassModal] = useState(false);
    const layout = useSelector(selectLayout);
    const queryParams = new URLSearchParams(window.location.search);
    const params = useParams();
    const [openEditClassModal, setOpenEditClassModal] = useState<string | null>();
    const classData = useSelector(selectClass);
    const sortable = useSortable({ itemsToSort: classesData });
    const { isModuleActive, getModuleName } = useIsModuleActive();

    useEffect(() => {
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    const updateActiveClasses = (activeClassesState: ActiveClasses) => {
        setActiveClasses(() => {
            dispatch(getClasses(activeClassesState));
            return activeClassesState;
        });
    };
    useMemo(() => {
        sortable.updateFilter('open', activeClasses);
        sortable.updateFilter('name', search);
    }, [search, classesData, activeClasses]);

    useEffect(() => {
        dispatch(getClasses(activeClasses));
        dispatch(putBreadcrumbs([]));
        dispatch(getNotifications());
    }, [dispatch]);

    useEffect(() => {
        if (queryParams.has('showWizard') && layout.layout.user.admin === true) {
            if (!layout.layout.isIelts) {
                setCreateClassModal(true);
                return;
            }
            setOpenEditClassModal('create');
            window.history.pushState({}, document.title, window.location.pathname);
        } else if (layout.layout.user.admin === false) {
            queryParams.delete('showWizard');
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }, [queryParams, layout]);

    const getClassesFilterTitle = (activeClassesVal: ActiveClasses) =>
        activeClassesVal === ActiveClasses.OPEN
            ? t('classes.openClasses')
            : t('classes.closedClasses');
    return (
        <>
            <div className="classes-header">
                <Search onChange={(text: string) => setSearch(text)} />
                <div className="classes-subheader">
                    {layout.layout.user.admin === true && isModuleActive(Modules.InviteTeacher) && (
                        <TextWithIcon
                            icon={<ManPlus />}
                            text={
                                getModuleName(Modules.InviteTeacher) || t('general.inviteTeacher')
                            }
                            link="/invite/teacher"
                            navigateAsLink
                        />
                    )}
                    <SelectMenu
                        text={getClassesFilterTitle(activeClasses)}
                        icon={<SettingsIcon />}
                        options={[
                            {
                                content: (
                                    <div className="classes-option">
                                        <Checkbox checked={activeClasses === ActiveClasses.OPEN} />
                                        <span>{t('classes.openClasses')}</span>
                                    </div>
                                ),
                                onClick: () => updateActiveClasses(ActiveClasses.OPEN),
                            },
                            {
                                content: (
                                    <div className="classes-option">
                                        <Checkbox
                                            checked={activeClasses === ActiveClasses.CLOSED}
                                        />
                                        <span>{t('classes.closedClasses')}</span>
                                    </div>
                                ),
                                onClick: () => updateActiveClasses(ActiveClasses.CLOSED),
                            },
                        ]}
                    />
                    {layout.layout.user.admin === true && (
                        <div
                            className="textWithIcon sc_hover_color_text_svg"
                            onClick={() => {
                                if (!layout.layout.isIelts) {
                                    setCreateClassModal(true);
                                    return;
                                }
                                setOpenEditClassModal('create');
                            }}
                        >
                            <Add />
                            <span>{t('general.addClass')}</span>
                        </div>
                    )}
                </div>
            </div>
            <WhiteBox>
                {notifications.length ? (
                    <div className="title">
                        <Envelope />
                        <div className="title-text">
                            {notifications.map((data: string, key: number) => (
                                <div key={key} dangerouslySetInnerHTML={{ __html: data }} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <MobileControls>
                    <Search onChange={(text: string) => setSearch(text)} />
                    {layout.layout.user.admin === true && isModuleActive(Modules.InviteTeacher) && (
                        <TextWithIcon
                            icon={<ManPlus />}
                            text={
                                getModuleName(Modules.InviteTeacher) || t('general.inviteTeacher')
                            }
                            link="/invite/teacher"
                        />
                    )}
                    <SelectMenu
                        text={getClassesFilterTitle(activeClasses)}
                        icon={<SettingsIcon />}
                        options={[
                            {
                                content: (
                                    <div className="classes-option">
                                        <Checkbox checked={activeClasses === ActiveClasses.OPEN} />
                                        <span>{t('classes.openClasses')}</span>
                                    </div>
                                ),
                                onClick: () => updateActiveClasses(ActiveClasses.OPEN),
                            },
                            {
                                content: (
                                    <div className="classes-option">
                                        <Checkbox
                                            checked={activeClasses === ActiveClasses.CLOSED}
                                        />
                                        <span>{t('classes.closedClasses')}</span>
                                    </div>
                                ),
                                onClick: () => updateActiveClasses(ActiveClasses.CLOSED),
                            },
                        ]}
                    />
                    {layout.layout.user.admin === true && (
                        <div
                            className="textWithIcon sc_hover_color_text_svg"
                            onClick={() => {
                                if (!layout.layout.isIelts) {
                                    setCreateClassModal(true);
                                    return;
                                }
                                setOpenEditClassModal('create');
                            }}
                        >
                            <Add />
                            <span>{t('general.addClass')}</span>
                        </div>
                    )}
                </MobileControls>
                <Table
                    tableRowsClassName="classes-rows"
                    arrowLeft
                    header={
                        <>
                            <SortableHeader
                                addClass="classes-name"
                                direction={sortable.getDirection('name')}
                                onClick={() =>
                                    sortable.updateSort('name', (item: any) =>
                                        item?.name?.toLowerCase(),
                                    )
                                }
                            >
                                {t('general.className')}
                            </SortableHeader>
                            <SortableHeader
                                addClass={
                                    !layout.layout.isIelts ? 'classes-level' : 'classes-level-ielts'
                                }
                                direction={sortable.getDirection('level')}
                                onClick={() =>
                                    sortable.updateSort('level', (item: any) =>
                                        item?.level?.toLowerCase(),
                                    )
                                }
                            >
                                {t('general.level')}
                            </SortableHeader>
                            {!layout.layout.isIelts && (
                                <>
                                    <SortableHeader
                                        addClass="classes-plan"
                                        direction={sortable.getDirection('lastPlan')}
                                        onClick={() =>
                                            sortable.updateSort('lastPlan', (item: any) => {
                                                return item?.lastPlan?.teacher;
                                            })
                                        }
                                    >
                                        {t('general.lastPlan')}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass="classes-room"
                                        direction={sortable.getDirection('room')}
                                        onClick={() =>
                                            sortable.updateSort('room', (item: any) =>
                                                item?.room?.toLowerCase(),
                                            )
                                        }
                                    >
                                        {t('general.room')}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass="classes-entry"
                                        direction={sortable.getDirection('entry')}
                                        onClick={() =>
                                            sortable.updateSort('entry', (item: any) => {
                                                return item?.lastAssessment?.teacher;
                                            })
                                        }
                                    >
                                        {t('classes.lastAssessmentEntry')}
                                    </SortableHeader>
                                </>
                            )}
                            <div className="classes-entry-edit" />
                        </>
                    }
                    rows={sortable.items.map((data: ClassesData) => {
                        return (
                            <>
                                <div className="classes-name">
                                    <CustomLink
                                        to={`/weekly-plan/${data.id}`}
                                        className="classes-name classes-name classes-blue tr_hover_color_text"
                                    >
                                        {data.name}
                                    </CustomLink>
                                </div>
                                <span
                                    className={
                                        !layout.layout.isIelts
                                            ? 'classes-level'
                                            : 'classes-level-ielts'
                                    }
                                >
                                    {data.level || '-'}
                                </span>
                                {!layout.layout.isIelts && (
                                    <>
                                        <span className="classes-plan">
                                            {data?.lastPlan?.teacher
                                                ? `${data?.lastPlan?.teacher} on ${moment
                                                      .unix(data?.lastPlan?.timestamp)
                                                      .format('DD MMM YYYY')}`
                                                : '-'}
                                        </span>
                                        <span className="classes-room">{data.room || '-'}</span>
                                        <div className="classes-entry">
                                            <span>
                                                {data?.lastAssessment?.teacher
                                                    ? `${data?.lastAssessment?.teacher} on ${moment
                                                          .unix(data?.lastAssessment?.timestamp)
                                                          .format('DD MMM YYYY')}`
                                                    : '-'}
                                            </span>
                                        </div>
                                    </>
                                )}
                                <div className="classes-entry-edit">
                                    <span
                                        onClick={() => {
                                            dispatch(
                                                getClass({
                                                    classId: data.id,
                                                    cb: () => {
                                                        setOpenEditClassModal(data.id);
                                                    },
                                                }),
                                            );
                                        }}
                                        className="classes-blue tr_hover_color_text"
                                    >
                                        {t('general.edit').toUpperCase()}
                                    </span>
                                    <div
                                        onClick={() => {
                                            setClassToDelete(data.id);
                                        }}
                                        className="classes-delete sc_hover_color_text_svg"
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                    headerMobile={
                        <>
                            <SortableHeader
                                addClass=""
                                direction={sortable.getDirection('name')}
                                onClick={() =>
                                    sortable.updateSort('name', (item: any) =>
                                        item?.name?.toLowerCase(),
                                    )
                                }
                            >
                                <span>{t('general.className')}</span>
                            </SortableHeader>
                        </>
                    }
                    rowsMobile={sortable.items.map((data: ClassesData, i: number) => {
                        return {
                            head: (
                                <>
                                    <div className="classes-name-mobile classes-blue">
                                        <div>
                                            <b>{i + 1}.</b>
                                            {data.name}
                                        </div>
                                        <span
                                            onClick={() => {
                                                navigate(`/weekly-plan/${data.id}`);
                                            }}
                                        >
                                            {t('general.view')}
                                        </span>
                                    </div>
                                </>
                            ),
                            content: (
                                <>
                                    <div className="classes-mobile-row">
                                        <div className="classes-mobile-row-title">
                                            <b>{t('general.level')}</b>
                                        </div>
                                        <span>{data.level || '-'}</span>
                                    </div>
                                    {!layout.layout.isIelts && (
                                        <>
                                            <div className="classes-mobile-row">
                                                <div className="classes-mobile-row-title">
                                                    <b>{t('general.lastPlan')}</b>
                                                </div>
                                                <span>
                                                    {data?.lastPlan?.teacher
                                                        ? `${data?.lastPlan?.teacher} on ${moment
                                                              .unix(data?.lastPlan?.timestamp)
                                                              .format('DD MMM YYYY')}`
                                                        : '-'}
                                                </span>
                                            </div>
                                            <div className="classes-mobile-row">
                                                <div className="classes-mobile-row-title">
                                                    <b>{t('general.room')}</b>
                                                </div>
                                                <span>{data.room || '-'}</span>
                                            </div>
                                            <div className="classes-mobile-row">
                                                <div className="classes-mobile-row-title">
                                                    <b>{t('classes.lastAssessmentEntry')}</b>
                                                </div>
                                                <span>
                                                    {data?.lastAssessment?.teacher
                                                        ? `${
                                                              data?.lastAssessment?.teacher
                                                          } on ${moment
                                                              .unix(data?.lastAssessment?.timestamp)
                                                              .format('DD MMM YYYY')}`
                                                        : '-'}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    <div className="classes-mobile-row">
                                        <div className="classes-mobile-row-title" />
                                        <span
                                            onClick={() => {
                                                dispatch(
                                                    getClass({
                                                        classId: data.id,
                                                        cb: () => {
                                                            setOpenEditClassModal(data.id);
                                                        },
                                                    }),
                                                );
                                            }}
                                            className="classes-blue"
                                        >
                                            {t('general.edit').toUpperCase()}
                                        </span>
                                    </div>
                                    <div className="classes-mobile-row">
                                        <div className="classes-mobile-row-title" />
                                        <div
                                            className="classes-delete"
                                            onClick={() => {
                                                setClassToDelete(data.id);
                                            }}
                                        >
                                            <Delete />
                                        </div>
                                    </div>
                                </>
                            ),
                        };
                    })}
                />
            </WhiteBox>
            <CreateClassModal
                open={createClassModal}
                setOpen={setCreateClassModal}
                onDoneCallback={() => dispatch(getClasses(activeClasses))}
            />
            <EditClassModal
                open={openEditClassModal}
                classData={classData}
                activeClasses={activeClasses}
                onClose={() => {
                    setOpenEditClassModal(null);
                }}
            />
            <ConfirmModal
                open={!!classToDelete}
                text={t('general.deleteClassConfirmation')}
                onClick={() => {
                    dispatch(
                        deleteClass({
                            classId: classToDelete,
                            activeClassesProp: activeClasses,
                        }),
                    );
                    setClassToDelete(0);
                }}
                onClose={() => {
                    setClassToDelete(0);
                }}
            />
        </>
    );
};

export default Classes;
