import ELearningDescription from 'src/components/ELearningDescription';
import Button from 'src/components/Button';
import CheckedType2 from 'src/components/Icons/Checked-type-2';
import { ELearningType } from 'src/redux/weeklyPlan/types';
import { useTranslation } from 'react-i18next';
import { ElearningProps } from 'src/pages/Homework/ELearning/types';

const NewAssignmentsELearningActivities = ({
    eLearning,
    num,
    setUpdateLesson,
    updateLesson,
}: {
    eLearning: ELearningType[];
    num: number;
} & ElearningProps) => {
    const { t } = useTranslation();

    const isCurrentAssignmentAdded = (assignment?: { id: number }) => {
        return updateLesson?.activities
            ?.map(({ id }: { id: number }) => id)
            .includes(assignment?.id);
    };

    return (
        <div className="NewAssignmentsEL-activities">
            <div className="NewAssignmentsEL-activities-data">
                <ELearningDescription eLearning={eLearning} num={num} />
            </div>
            {!!eLearning.length && (
                <div className="elearning-footer">
                    {isCurrentAssignmentAdded(eLearning[num]) ? (
                        <Button onClick={() => {}} type="secondary">
                            <>
                                <CheckedType2 />
                                {`${t('NewAssigment.added')} `}
                            </>
                        </Button>
                    ) : (
                        <Button
                            type="secondary"
                            onClick={() => {
                                setUpdateLesson((data: any) => {
                                    const tempELearning = structuredClone(eLearning[num]);
                                    tempELearning.currentlyAdded = true;
                                    const newActivities = data.activities
                                        .map(({ id }: any) => id)
                                        .includes(tempELearning.id)
                                        ? data.activities
                                        : [...data.activities, tempELearning];

                                    const newTeacherText = data.teacherText.includes(
                                        tempELearning.description,
                                    )
                                        ? data.teacherText
                                        : `${data.teacherText}<p>${tempELearning.description}</p>`;
                                    return {
                                        ...data,
                                        activities: newActivities,
                                        teacherText: newTeacherText,
                                    };
                                });
                            }}
                        >
                            {t('NewAssigment.addToHomework')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
export default NewAssignmentsELearningActivities;
