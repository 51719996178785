const BanIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" id="ban" width="20" height="20" viewBox="0 0 20 20">
        <path
            id="Path_30507"
            data-name="Path 30507"
            d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Zm8.333-10a8.327,8.327,0,0,1-13.6,6.448L16.448,4.73A8.291,8.291,0,0,1,18.333,10ZM10,1.667a8.291,8.291,0,0,1,5.27,1.885L3.552,15.27A8.327,8.327,0,0,1,10,1.667Z"
            fill="#54585a"
        />
    </svg>
);

export default BanIcon;
