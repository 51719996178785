import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AutomatedState } from './types';

export const getAutomatedSelector = (state: RootState): AutomatedState['automated'] =>
    state.automated.automated;

export const selectAutomated = createSelector(
    createSelector(getAutomatedSelector, (automated) => automated),
    (automated) => automated,
);

export const getAutomatedTestSelector = (state: RootState): AutomatedState['test'] =>
    state.automated.test;

export const selectAutomatedTest = createSelector(
    createSelector(getAutomatedTestSelector, (test) => test),
    (test) => test,
);
