import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { StaffReportType, EmailLogType, StaffProgressTest } from './reducer';

export const getStaffReportsSelector = (state: RootState): StaffReportType[] =>
    state.staffReports.staffReports;

export const selectStaffReports = createSelector(
    createSelector(getStaffReportsSelector, (item) => item),
    (item) => item,
);

export const getStaffEmailsLogSelector = (state: RootState): EmailLogType[] =>
    state.staffReports.staffEmailsLog;

export const selectStaffEmailsLog = createSelector(
    createSelector(getStaffEmailsLogSelector, (item) => item),
    (item) => item,
);

export const getProgressTestsSelector = (state: RootState): StaffProgressTest[] =>
    state.staffReports.progressTests;

export const selectProgressTests = createSelector(
    createSelector(getProgressTestsSelector, (item) => item),
    (item) => item,
);
