import { createAction } from '@reduxjs/toolkit';
import { AssignProgress, GetProgressProps } from './types';

export const GET_PROGRESS = 'GET_PROGRESS';
export const PUT_PROGRESS = 'PUT_PROGRESS';
export const DELETE_PROGRESS = 'DELETE_PROGRESS';
export const ASSIGN_PROGRESS = 'ASSIGN_PROGRESS';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

export const getProgress = createAction<GetProgressProps>(GET_PROGRESS);
export const putProgress = createAction<any>(PUT_PROGRESS);
export const deleteProgress = createAction<any>(DELETE_PROGRESS);
export const assignProgress = createAction<AssignProgress>(ASSIGN_PROGRESS);
export const updateProgress = createAction<any>(UPDATE_PROGRESS);
