import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { saveToDo, getAllToDo, getToDoReferences, putToDoReferences } from '../actions';

export function* getToDoReferencesSaga({
    payload,
}: ReturnType<typeof getToDoReferences>): SagaIterator {
    try {
        const data = yield call(API.get(), `/staff/todo/${payload.type}?search=${payload.search}`);
        yield put(putToDoReferences(data));
        if (payload.cb) {
            payload.cb(data);
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
