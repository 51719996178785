import { createSelector } from '@reduxjs/toolkit';
import { ClassesData } from 'src/pages/Classes/types';
import { RootState } from '../store';
import { PutInvitesDataProps, PutClassProps } from './types';

export const getClassesSelector = (state: RootState): ClassesData[] => state.classes.classes;

export const selectClasses = createSelector(
    createSelector(getClassesSelector, (classes) => classes),
    (classes) => classes,
);

export const getClassSelector = (state: RootState): PutClassProps => state.classes.class;

export const selectClass = createSelector(
    createSelector(getClassSelector, (classData) => classData),
    (classData) => classData,
);

export const getInvitesSelector = (state: RootState): PutInvitesDataProps => state.classes.invites;

export const selectInvites = createSelector(
    createSelector(getInvitesSelector, (invites) => invites),
    (invites) => invites,
);
