import { ErrorBoundary, Provider } from '@rollbar/react';
import errorWrapper from '../ErrorWrapper';
import { rollbarConfig } from './rollbarConfig';

const Rollbar = ({ children }: any) => {
    return (
        <Provider config={rollbarConfig}>
            <ErrorBoundary fallbackUI={errorWrapper}>{children}</ErrorBoundary>
        </Provider>
    );
};

export default Rollbar;
