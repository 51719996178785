import { ELearningType } from 'src/redux/weeklyPlan/types';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const ELearningDescription = ({ eLearning, num }: { eLearning: ELearningType[]; num: number }) => {
    const { t } = useTranslation();
    if (eLearning.length) {
        return (
            <div className="elearning-description">
                <span className="elearning-description-title">{eLearning[num].name}</span>
                <span className="elearning-description-text">{eLearning[num].description}</span>
                {!!eLearning[num].highlights.length && (
                    <span
                        data-testid="highlight-title"
                        className="elearning-description-title elearning-description-highlights"
                    >
                        {t('eLearningDescription.matchHighlights')}
                    </span>
                )}
                {eLearning[num].highlights.map((highlight: string) => (
                    <span className="elearning-description-text" key={highlight}>
                        • {highlight}
                    </span>
                ))}
            </div>
        );
    }
    return <></>;
};
export default ELearningDescription;
