import { Props } from '../types';

const Home = ({ fill = '#54585a' }: Props) => (
    <svg height="13" viewBox="0 0 12.793 13" width="12.793" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m248.065 2.2c-2 2.152-5.508 5.833-5.508 5.833a.415.415 0 0 0 .292.738h1.2v5.3a.9.9 0 0 0 .9.9h2.051a.3.3 0 0 0 .3-.3v-3.535a.3.3 0 0 1 .306-.306h2.341a.3.3 0 0 1 .306.306v3.544a.3.3 0 0 0 .3.3h2.088a.9.9 0 0 0 .9-.9v-5.3h1.059c.637 0 .719-.453.411-.768-1.915-1.962-3.947-4.086-5.625-5.8a1.061 1.061 0 0 0 -1.322-.007z"
            fill={fill}
            transform="translate(-242.394 -1.975)"
        />
    </svg>
);

export default Home;
