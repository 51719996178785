import classNames from 'classnames';

import Arrow from '../Arrow';
import { TablePaginationAPIProps } from './types';
import './styles.scss';

const TablePaginationAPI = ({
    mob = false,
    updatePage,
    paginationProcessor,
}: TablePaginationAPIProps) => {
    const updateCurrentPage = (to: 'prev' | 'next') => {
        const curPage = +paginationProcessor.page;

        if (to === 'next') {
            updatePage({ page: curPage + 1 });
        }
        if (to === 'prev') {
            updatePage({ page: curPage - 1 });
        }
    };

    if (!paginationProcessor.data.length) {
        return null;
    }

    return (
        <div
            className={classNames('tablePagination', { mob, desc: !mob })}
            data-testid="tablePagination"
        >
            <div>
                <Arrow
                    onClick={() => updateCurrentPage('prev')}
                    disabled={paginationProcessor.page <= 1}
                    direction="left"
                    dataTestId="tablePagination-left"
                />
            </div>
            <div style={{ userSelect: 'none', minWidth: '42px', textAlign: 'center' }}>
                {paginationProcessor.page} of {paginationProcessor.pages}
            </div>
            <div>
                <Arrow
                    onClick={() => updateCurrentPage('next')}
                    disabled={paginationProcessor.page >= paginationProcessor.pages}
                    direction="right"
                    dataTestId="tablePagination-right"
                />
            </div>
        </div>
    );
};

export default TablePaginationAPI;
