import { Props } from '../types';

const ReportIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        id="Report"
        xmlns="http://www.w3.org/2000/svg"
        width="26.093"
        height="23.911"
        viewBox="0 0 26.093 23.911"
    >
        <g id="Group_576" data-name="Group 576" transform="translate(0 0)">
            <path
                id="Path_1227"
                data-name="Path 1227"
                d="M25.006,21.737H3.262A1.087,1.087,0,0,1,2.174,20.65V1.087A1.087,1.087,0,0,0,0,1.087V20.65a3.262,3.262,0,0,0,3.262,3.262H25.006a1.087,1.087,0,0,0,0-2.174Z"
                fill={fill}
            />
            <path
                id="Path_1228"
                data-name="Path 1228"
                d="M117.893,189.752a1.192,1.192,0,0,0,1.192-1.192v-8.342a1.192,1.192,0,0,0-2.383,0v8.342A1.192,1.192,0,0,0,117.893,189.752Z"
                transform="translate(-110.768 -169.926)"
                fill={fill}
            />
            <path
                id="Path_1229"
                data-name="Path 1229"
                d="M399.194,40.55a1.192,1.192,0,0,0,1.192-1.192V23.026a1.192,1.192,0,0,0-2.383,0V39.359A1.192,1.192,0,0,0,399.194,40.55Z"
                transform="translate(-377.769 -20.724)"
                fill={fill}
            />
            <path
                id="Path_1230"
                data-name="Path 1230"
                d="M304.386,134.16a1.192,1.192,0,0,0,1.192-1.192V121.649a1.192,1.192,0,1,0-2.383,0v11.319A1.192,1.192,0,0,0,304.386,134.16Z"
                transform="translate(-287.781 -114.333)"
                fill={fill}
            />
            <path
                id="Path_1231"
                data-name="Path 1231"
                d="M211.66,265.522a1.192,1.192,0,0,0,1.192-1.192v-4.284a1.192,1.192,0,0,0-2.383,0v4.284A1.192,1.192,0,0,0,211.66,265.522Z"
                transform="translate(-199.769 -245.696)"
                fill={fill}
            />
        </g>
    </svg>
);

export default ReportIcon;
