import { SagaIterator } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { IPInfoWatcher } from 'src/redux/ipInfo/sagas';
import { assessmentWatcher } from './assessment/sagas';
import { qrWatcher } from './qrCodes/sagas';
import { automatedWatcher } from './automated/sagas';
import { classesWatcher } from './classes/sagas';
import { layoutWatcher } from './layout/sagas';
import { notificationsWatcher } from './notifications/sagas';
import { reportsWatcher } from './reports/sagas';
import { studentsWatcher } from './students/sagas';
import { progressWatcher } from './progress/sagas';
import { unassignedWatcher } from './unassigned/sagas';
import { emailsWatcher } from './emails/sagas';
import { weeklyPlanWatcher } from './weeklyPlan/sagas';
import { settingsWatcher } from './settings/sagas';
import { allStudentsWatcher } from './allStudents/sagas';
import { staffWatcher } from './staff/sagas';
import { coursePlannerWather } from './coursePlanner/sagas';
import { usersWeather } from './users/sagas';
import { schoolsWatcher } from './schools/sagas';
import { toDoWatcher } from './toDo/sagas';
import { attendanceWatcher } from './attendance/sagas';

export default function* rootSaga(): SagaIterator {
    yield all([
        call(emailsWatcher),
        call(classesWatcher),
        call(studentsWatcher),
        call(automatedWatcher),
        call(unassignedWatcher),
        call(weeklyPlanWatcher),
        call(assessmentWatcher),
        call(attendanceWatcher),
        call(qrWatcher),
        call(progressWatcher),
        call(layoutWatcher),
        call(reportsWatcher),
        call(notificationsWatcher),
        call(settingsWatcher),
        call(allStudentsWatcher),
        call(staffWatcher),
        call(coursePlannerWather),
        call(usersWeather),
        call(schoolsWatcher),
        call(toDoWatcher),
        call(IPInfoWatcher),
    ]);
}
