import classNames from 'classnames';
import './styles.scss';
import CustomLink from 'src/components/CustomLink';
import { ActionTextProps } from './types';

const ActionText = ({ onClick, children, disabled, to }: ActionTextProps) => {
    if (to) {
        return (
            <CustomLink
                to={to}
                className={classNames('actionText cursor-pointer tr_hover_color_text', {
                    disabled,
                })}
                data-testid="actionText"
                style={{ display: 'inline' }}
                onClick={() => {
                    if (!disabled && onClick) onClick();
                }}
            >
                {children}
            </CustomLink>
        );
    }
    return (
        <div
            className={classNames('actionText cursor-pointer tr_hover_color_text', {
                disabled,
            })}
            data-testid="actionText"
            onClick={() => {
                if (!disabled && onClick) onClick();
            }}
        >
            {children}
        </div>
    );
};

export default ActionText;
