import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { getTimezoneForRequest } from 'src/helpers/getTimezoneForRequest';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { assignAutomated, getAutomated } from '../actions';

export function* assignAutomatedSaga({
    payload,
}: ReturnType<typeof assignAutomated>): SagaIterator {
    try {
        yield put(startLoading());
        if (payload.update) {
            yield call(
                API.put(),
                `/staff/tests/${payload.classId}/assignments/${
                    payload.testId
                }?${getTimezoneForRequest()}`,
                {
                    from: payload.from,
                    to: payload.to,
                },
            );
        } else {
            yield call(
                API.post(),
                `/staff/tests/${payload.classId}/assignments?${getTimezoneForRequest()}`,
                {
                    testId: payload.testId,
                    from: payload.from,
                    to: payload.to,
                },
            );
        }
        yield put(
            getAutomated({
                classId: payload.classId,
                type: payload.type,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
