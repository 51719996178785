import moment from 'moment';

export const weeklyPlanGetPathToEditOnlineTest = (week: any, params: any, test: any) => {
    const curWeek = moment().isoWeek();
    let tab = '?tab=';
    if (curWeek < week) {
        tab += 'Future tests';
    } else if (curWeek > week) {
        tab += 'Past tests';
    } else {
        tab += 'This week’s tests';
    }

    return test.type === 'automated'
        ? `/online-testing/automated-tests/${params.id}${tab}`
        : `/online-testing/progress-tests/${params.id}${tab}`;
};
