import React from 'react';
import { useSelector } from 'react-redux';
import { selectSettingsValidationErrors } from 'src/redux/settings/selectors';

const SettingsModalValidationError = () => {
    const settingsValidationErrors = useSelector(selectSettingsValidationErrors);
    return (
        <>
            {settingsValidationErrors?.message && (
                <div className="settings__api-errors">
                    {settingsValidationErrors?.message?.map(({ message }: any) => {
                        return (
                            <span key={message} className="settings__api-error">
                                {message}
                            </span>
                        );
                    })}
                </div>
            )}
        </>
    );
};
export default SettingsModalValidationError;
