import { Props } from '../types';

const SwitchIosOnSvg = ({ fill = '#007FA3' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 40 20" fill="none">
        <rect width="40" height="20" rx="10" fill={fill} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 18C34.4183 18 38 14.4183 38 10C38 5.58172 34.4183 2 30 2C25.5817 2 22 5.58172 22 10C22 14.4183 25.5817 18 30 18Z"
            fill="#FAFAFA"
        />
    </svg>
);

export default SwitchIosOnSvg;
