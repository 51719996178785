import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import moment from 'moment';
import App from './App';
import reportWebVitals from './reportWebVitals';

moment.locale('en', {
    week: {
        dow: 6,
    },
});
moment.locale('en');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

/*
 If you want to start measuring performance in your app, pass a function
 to log results (for example: reportWebVitals(console .log))
 or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
*/
reportWebVitals();
