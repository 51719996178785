import moment from 'moment';

export const getPartialRange = (date: Date | [Date, Date]): [Date, Date] => {
    let result: [Date, Date];
    if (Array.isArray(date) && date.length < 2) {
        result = [
            moment(date[0].toString()).startOf('day').toDate(),
            moment(date[0].toString()).endOf('day').toDate(),
        ];
    } else if (!Array.isArray(date) && typeof date === 'object') {
        result = [
            moment(date.toString()).startOf('day').toDate(),
            moment(date.toString()).endOf('day').toDate(),
        ];
    } else {
        result = [date[0], date[1]];
    }
    return result;
};
