const CalendarArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Artwork_185" data-name="Artwork 185" transform="translate(10 10)">
                <g id="Group_287" data-name="Group 287" transform="translate(-10 -10)">
                    <path
                        id="Path_491"
                        data-name="Path 491"
                        d="M15.833,1.667H15V.833a.833.833,0,0,0-1.667,0v.833H6.667V.833A.833.833,0,0,0,5,.833v.833H4.167A4.172,4.172,0,0,0,0,5.833v10A4.172,4.172,0,0,0,4.167,20H15.833A4.172,4.172,0,0,0,20,15.833v-10A4.172,4.172,0,0,0,15.833,1.667ZM1.667,5.833a2.5,2.5,0,0,1,2.5-2.5H15.833a2.5,2.5,0,0,1,2.5,2.5v.833H1.667Zm14.167,12.5H4.167a2.5,2.5,0,0,1-2.5-2.5v-7.5H18.333v7.5A2.5,2.5,0,0,1,15.833,18.333Z"
                        fill="#54585a"
                    />
                    <path
                        id="Path_492"
                        data-name="Path 492"
                        d="M163.414,257.66l-1.5-1.533a.8.8,0,0,0-1.134,0l0,0h0a.8.8,0,0,0,0,1.13l1.252,1.276h-4.906a.8.8,0,0,0-.8.8h0a.8.8,0,0,0,.8.8h4.954l-1.2,1.195a.8.8,0,0,0,0,1.134l0,0h0a.8.8,0,0,0,1.134,0l0,0,1.4-1.411A2.43,2.43,0,0,0,163.414,257.66Z"
                        transform="translate(-150.214 -245.894)"
                        fill="#54585a"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CalendarArrow;
