import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { confirm2FACode, delete2FAPhoneNumber, putSettingsValidationErrors } from '../actions';

export function* delete2FAPhoneNumberSaga({
    payload,
}: ReturnType<typeof delete2FAPhoneNumber>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.delete(), `/settings/phone`);
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putSettingsValidationErrors(error));
    } finally {
        yield put(finishLoading());
    }
}
