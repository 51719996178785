import { createReducer } from '@reduxjs/toolkit';
import { PUT_AIMS_MATERIALS, PUT_AIMS, PUT_QUETIONS, PUT_BOOK } from './actions';
import { AimMaterialType } from './types';

export type CourPlannerState = {
    aimsMaterials: AimMaterialType;
    aims: any;
    questions: any;
    book: any;
};

const initialState = {
    aimsMaterials: { categories: [] },
    aims: {},
    questions: [],
    book: {},
};

export const coursePlannerReducer = createReducer<CourPlannerState>(initialState, {
    [PUT_AIMS_MATERIALS]: (state, action) => {
        state.aimsMaterials = action.payload;
    },
    [PUT_AIMS]: (state, action) => {
        state.aims = action.payload;
    },
    [PUT_QUETIONS]: (state, action) => {
        state.questions = action.payload;
    },
    [PUT_BOOK]: (state, action) => {
        state.book = action.payload;
    },
});
