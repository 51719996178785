import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { putApiRequestError } from 'src/redux/error/actions';
import { putIPInfo } from 'src/redux/ipInfo/actions';
import { finishLoading, startLoading } from 'src/redux/loading/actions';

export function* getIPInfoSaga(): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `https://ipinfo.io?token=43d15c5a17203b`);
        yield put(putIPInfo(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
