import { createReducer } from '@reduxjs/toolkit';
import { PUT_NOTIFICATIONS } from './actions';

export type NotificationsState = {
    notifications: any[];
};

const initialState = {
    notifications: [],
};

export const notificationsReducer = createReducer<NotificationsState>(initialState, {
    [PUT_NOTIFICATIONS]: (state, action) => {
        state.notifications = action.payload;
    },
});
