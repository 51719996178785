import { Props } from '../types';

const InfoIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 12C11 11.4477 11.4477 11 12 11H13C13.5523 11 14 11.4477 14 12V18C14.5523 18 15 18.4477 15 19C15 19.5523 14.5523 20 14 20H13C12.4477 20 12 19.5523 12 19V13C11.4477 13 11 12.5523 11 12Z"
            fill={fill}
        />
        <path
            d="M12.5 9C13.3284 9 14 8.32843 14 7.5C14 6.67157 13.3284 6 12.5 6C11.6716 6 11 6.67157 11 7.5C11 8.32843 11.6716 9 12.5 9Z"
            fill={fill}
        />
    </svg>
);

export default InfoIcon;
