import classNames from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './styles.scss';

const CustomLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
    const { className, children } = props;
    return (
        <div className="custom-link-parent">
            <Link {...props} className={classNames(`custom-link ${className}`)}>
                {children}
            </Link>
        </div>
    );
};

export default CustomLink;
