import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAssessmentStudents, putAssessmentStudents } from '../actions';

export function* getAssessmentStudentsSaga({
    payload,
}: ReturnType<typeof getAssessmentStudents>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/classes/${payload.classId}/assessments/${payload.year}/${payload.week}`,
        );
        yield put(putAssessmentStudents(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
