const Filter = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.541754 1.67492C2.47509 4.15825 5.33342 7.83325 5.33342 7.83325V11.9999C5.33342 12.9166 6.08342 13.6666 7.00009 13.6666C7.91675 13.6666 8.66675 12.9166 8.66675 11.9999V7.83325C8.66675 7.83325 11.5251 4.15825 13.4584 1.67492C13.8834 1.12492 13.4918 0.333252 12.7918 0.333252H1.20009C0.508421 0.333252 0.116754 1.12492 0.541754 1.67492Z"
            fill="#1A1C1E"
        />
    </svg>
);

export default Filter;
