import { createReducer } from '@reduxjs/toolkit';
import { PUT_EMAILS } from './actions';
import { EmailType } from './types';

export type EmailsState = {
    emails: EmailType[];
};

const initialState = {
    emails: [],
};

export const emailsReducer = createReducer<EmailsState>(initialState, {
    [PUT_EMAILS]: (state, action) => {
        state.emails = action.payload;
    },
});
