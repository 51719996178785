import { useTranslation } from 'react-i18next';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import moment from 'moment';
import InputCalendar from 'src/components/InputCalendar';
import SelectMenu from 'src/components/SelectMenu';
import { useEffect, useState } from 'react';
import { API } from 'src/api';
import { useDispatch } from 'react-redux';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { getWeeklyPlan } from 'src/redux/weeklyPlan/actions';
import { useParams } from 'react-router';
import { CreateZoomModalType } from '../../types';

import './styles.scss';

const CreateZoomModal = ({
    zoomModal,
    setZoomModal,
    year,
    week,
    disabledDays,
}: {
    zoomModal: CreateZoomModalType;
    setZoomModal: any;
    year: number;
    week: number;
    disabledDays: number[];
}) => {
    const { t } = useTranslation();
    const currentTime = new Date();
    const currentTimeMillis = currentTime.getTime();
    const [date, setDate] = useState(currentTimeMillis / 1000);
    const [start, setStart] = useState(null as unknown as number);
    const [end, setEnd] = useState(null as unknown as number);
    const [topic, setTopic] = useState('');
    const [showSelect, setShowSelect] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        setEnd(zoomModal.data.end || (null as unknown as number));
        setStart(zoomModal.data.start || (null as unknown as number));
        setTopic(zoomModal.data.topic || '');
        setDate(zoomModal.data.date / 1000);

        //todo find why select renders before modal
        setTimeout(() => setShowSelect(true), 0);

        return () => {
            document.removeEventListener('DOMSubtreeModified', (e) => {});
            setShowSelect(false);
            setEnd(null as unknown as number);
            setStart(null as unknown as number);
            setTopic('');
            setDate(currentTimeMillis / 1000);
        };
    }, [zoomModal]);

    const closeModal = () => {
        setZoomModal({
            type: 'Close' as 'Close' | 'Edit' | 'New',
            data: {
                start: null as unknown as number,
                end: null as unknown as number,
                topic: '',
                date: null as unknown as number,
                id: 0,
            },
        } as CreateZoomModalType);
    };

    const save = () => {
        const dataToSave = {
            name: topic,
            type: 'zoom',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            from_date:
                moment(new Date(date * 1000))
                    .startOf('day')
                    .add(start, 'minutes')
                    .toDate()
                    .getTime() / 1000,
            to_date:
                moment(new Date(date * 1000))
                    .startOf('day')
                    .add(end, 'minutes')
                    .toDate()
                    .getTime() / 1000,
        };
        dispatch(startLoading());
        if (zoomModal.data.id) {
            API.patch()(`/staff/classes/${params.id}/meetings/${zoomModal.data.id}`, dataToSave)
                .then(() => {
                    closeModal();
                    dispatch(
                        getWeeklyPlan({
                            year,
                            week,
                            id: params.id,
                        }),
                    );
                })
                .finally(() => {
                    dispatch(finishLoading());
                });
            return;
        }

        API.post()(`/staff/classes/${params.id}/meetings`, dataToSave)
            .then(() => {
                closeModal();
                dispatch(
                    getWeeklyPlan({
                        year,
                        week,
                        id: params.id,
                    }),
                );
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    };

    return (
        <>
            <Modal
                open={zoomModal.type !== 'Close'}
                isNewDesign
                header={
                    <>
                        {zoomModal.type !== 'Close' ? (
                            <span>
                                {t(`weeklyPlan.zoom${zoomModal.type}`)}{' '}
                                {t('weeklyPlan.zoom').toLocaleLowerCase()}
                            </span>
                        ) : (
                            <></>
                        )}
                    </>
                }
                onClose={() => {
                    closeModal();
                }}
                footer={
                    <>
                        <Button
                            type="square"
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            type="secondary"
                            onClick={() => {
                                save();
                            }}
                            disabled={
                                !topic ||
                                end <= start ||
                                moment(new Date(date * 1000))
                                    .startOf('day')
                                    .isBefore(moment(new Date()).startOf('day'))
                            }
                        >
                            {t('general.save')}
                        </Button>
                    </>
                }
            >
                <div className="zoom-create">
                    <div className="zoom-create-row">
                        <span>{t('weeklyPlan.day')}</span>
                        <InputCalendar
                            isUtc
                            withPortal
                            onChange={(e: any) => {
                                setDate(e / 1000);
                            }}
                            minDate={new Date()}
                            date={new Date((date || 0) * 1000)}
                            disabledDays={disabledDays}
                        />
                    </div>

                    <div className="zoom-create-row">
                        <span>{t('weeklyPlan.time')}</span>
                        <span className="zoom-create-row-subtitle">
                            {t('weeklyPlan.timesLocal')}
                        </span>

                        <div className="zoom-create-time">
                            {showSelect && (
                                <SelectMenu
                                    wrapperClass="zoom-select"
                                    text={
                                        <div className="restart-truncate">
                                            {Number.isInteger(start)
                                                ? moment()
                                                      .startOf('day')
                                                      .add(start, 'minutes')
                                                      .format('hh:mm A')
                                                : t('weeklyPlan.start')}
                                        </div>
                                    }
                                    withPortal
                                    alwaysWide
                                    options={Array(96)
                                        .fill(null)
                                        .map((_, i) => i)
                                        .map((idx) => {
                                            return {
                                                name: moment()
                                                    .startOf('day')
                                                    .add(idx * 15, 'minutes')
                                                    .format('hh:mm A'),
                                                key: idx * 15,
                                            };
                                        })
                                        .map((bookData: any) => {
                                            return {
                                                content: (
                                                    <span title={bookData.name}>
                                                        {bookData.name}
                                                    </span>
                                                ),
                                                onClick: () => {
                                                    setStart(bookData.key);
                                                },
                                            };
                                        })}
                                />
                            )}
                            <span>{t('weeklyPlan.to')}</span>
                            {showSelect && (
                                <SelectMenu
                                    wrapperClass="zoom-select"
                                    alwaysWide
                                    text={
                                        <div className="restart-truncate">
                                            {Number.isInteger(end)
                                                ? moment()
                                                      .startOf('day')
                                                      .add(end, 'minutes')
                                                      .format('hh:mm A')
                                                : t('weeklyPlan.end')}
                                        </div>
                                    }
                                    withPortal
                                    options={Array(97)
                                        .fill(null)
                                        .map((_, i) => i)
                                        .map((idx) => {
                                            return {
                                                name: moment()
                                                    .startOf('day')
                                                    .add(idx * 15, 'minutes')
                                                    .format('hh:mm A'),
                                                key: idx * 15,
                                            };
                                        })
                                        .map((bookData: any, idx) => {
                                            return {
                                                content: (
                                                    <span title={bookData.name}>
                                                        {bookData.name}
                                                    </span>
                                                ),
                                                onClick: () => {
                                                    setEnd(idx * 15);
                                                },
                                            };
                                        })}
                                />
                            )}
                        </div>
                    </div>

                    <div className="zoom-create-row">
                        <span>{t('weeklyPlan.topic')}</span>
                        <input
                            placeholder={t('weeklyPlan.addSome')}
                            onChange={(e) => {
                                setTopic(e.target.value);
                            }}
                            className="zoom-create-topic"
                            value={topic}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateZoomModal;
