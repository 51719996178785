import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import CalendarIcon from 'src/components/Icons/CalendarIcon';
import CustomCalendar from 'src/components/CustomCalendar';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { getPartialRange } from 'src/helpers/getPartialRange';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type EmailsDateRangeFilterProps = {
    tab: any;
    EMAIL_LOG: any;
    dateRange: any;
    setDateRange: any;
    mob: any;
};
const EmailsDateRangeFilter = ({
    tab,
    EMAIL_LOG,
    dateRange,
    setDateRange,
    mob,
}: EmailsDateRangeFilterProps) => {
    const { width, isDesktop } = useWindowSize();
    const [openCalendar, setOpenCalendar] = useState(false);
    const calendarRef = useRef(null);
    useOutsideClick(calendarRef, () => setOpenCalendar(false));
    const [isSearchStart, setIsSearchStart] = useState(false);
    const { t } = useTranslation();

    return (
        <div
            className={classNames('emailsLog-filter__item', {
                'd-none': tab !== EMAIL_LOG || (mob ? isDesktop : !isDesktop),
            })}
            ref={calendarRef}
        >
            <div className={classNames('emailsLog-filter-date-selector')}>
                <div className="emailsLog-filter-date-selector__range">
                    {isSearchStart
                        ? `${moment(dateRange[0]).format('ll')} - ${moment(dateRange[1]).format(
                              'll',
                          )}`
                        : t('emails.today')}
                </div>
                <div
                    className="dateSelector-icon pr_hover_color_text_svg_bg"
                    onClick={() => {
                        setOpenCalendar(!openCalendar);
                    }}
                >
                    <CalendarIcon />
                </div>
                {openCalendar && (
                    <div className="emailsLog-filter-date-selector__calendar">
                        <CustomCalendar
                            minDate={null}
                            onChangeReturnType="date"
                            allowPartialRange
                            type="rangeCustom"
                            onChange={(data) => {
                                setIsSearchStart(true);
                                const partialRange = getPartialRange(data);
                                setDateRange(partialRange);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailsDateRangeFilter;
