import classNames from 'classnames';
import Lottie from 'react-lottie';
import Button from '../../Button';
import Modal from '../../Modal';
import './styles.scss';
import { SuccessModalProps } from './types';
import { successCheckboxLottie } from '../../../assets/lottie/success-checkbox';

const SuccessModal = ({ text, onClose, open, lottie }: SuccessModalProps) => {
    return (
        <>
            <Modal open={open} onClose={onClose} modalBackdropClass="success-modal-backdrop">
                <div className="successModal">
                    {lottie && (
                        <div className="successModal__lottie">
                            <Lottie options={{ animationData: successCheckboxLottie }} />
                        </div>
                    )}
                    <span
                        className="successModal-title"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    <div className={classNames('successModal-button', lottie)}>
                        <Button
                            type="secondary"
                            onClick={() => {
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SuccessModal;
