import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selecAssigments } from 'src/redux/weeklyPlan/selectors';
import Checkbox from 'src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import Add from 'src/components/Icons/Add';
import { selectLayout } from 'src/redux/layout/selectors';
import { selectLoading } from 'src/redux/loading/selectors';
import noData1 from 'src/assets/images/noData1.gif';
import { homeworkPrepareAssignmentForSave } from '../helpers';
import { ElearningProps } from '../ELearning/types';

import './styles.scss';

const MyAssignments = ({
    setUpdateLesson,
    updateLesson,
    confirmNavigation,
}: ElearningProps & { confirmNavigation?: any }) => {
    const state = useLocation().state as any;
    const { t } = useTranslation();
    const assignments = useSelector(selecAssigments);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { layout } = useSelector(selectLayout);
    const loading = useSelector(selectLoading);
    const isCurrentAssignmentAdded = ({ assignment }: { assignment: any }) => {
        return updateLesson?.activities?.map(({ id }: any) => id).includes(assignment?.id);
    };

    useEffect(() => {
        if (state && updateLesson && assignments[0]) {
            if (!updateLesson?.activities?.map(({ id }: any) => id).includes(assignments[0]?.id)) {
                setUpdateLesson((data: any) =>
                    homeworkPrepareAssignmentForSave({ data, assignment: assignments[0] }),
                );
                delete state.tab;
                delete state.addAssignment;
            }
        }
    }, [updateLesson, assignments]);

    const AddAssignmentButton = () => (
        <div
            className="homework-create pr_hover_color_text_svg_bg"
            onClick={() => {
                const isStudent =
                    location.pathname.includes('students') &&
                    !location.pathname.includes('all-students');
                const isAllStudent = location.pathname.includes('all-students');
                const isWeeklyPlan = location.pathname.includes('weekly-plan');
                let url = '';

                if (isStudent) {
                    url = `/students/${params.id}/${params.uid}/assignment`;
                }
                if (isAllStudent) {
                    url = `/all-students/${params.uid}/assignment`;
                }
                if (isWeeklyPlan) {
                    url = `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}/assignment`;
                }

                navigate(url, {
                    state: {
                        addToResources:
                            state && 'addToResources' in state ? state.addToResources : [],
                        updateLesson,
                    },
                });

                sessionStorage.setItem(
                    'updateLessonCurrentlyAddedActivities',
                    JSON.stringify(
                        updateLesson.activities.filter(({ currentlyAdded }: any) => currentlyAdded),
                    ),
                );

                confirmNavigation();
            }}
        >
            <Add />
            <span>{t('homework.createMyOwn')}</span>
        </div>
    );

    if (!assignments.length && !loading) {
        return (
            <>
                <div style={{ marginTop: '1rem' }}>
                    <AddAssignmentButton />
                </div>
                <div className="tableLight-noData">
                    <img src={noData1} alt="noData" />
                    <span>{t('profile.noData')}</span>
                </div>
            </>
        );
    }

    return (
        <div className="assigment">
            {!layout.isIelts && <AddAssignmentButton />}

            <div className="assigment-head">
                <span>{t('homework.myAssignments.title')}</span>
                <span>{t('homework.myAssignments.type')}</span>
                <span>{t('homework.myAssignments.addToHomework')}</span>
            </div>

            <div className="assigment-body">
                {assignments.map((assignment: any) => {
                    return (
                        <div key={assignment.id} className="assigment-row">
                            <span>{assignment.name}</span>
                            <span>{assignment.type}</span>
                            {isCurrentAssignmentAdded({ assignment }) ? (
                                <span className="assigment-added">
                                    <Checkbox checked />
                                    {t('homework.myAssignments.added')}
                                </span>
                            ) : (
                                <span
                                    className="sc_hover_color_text_svg"
                                    onClick={() => {
                                        setUpdateLesson((data: any) =>
                                            homeworkPrepareAssignmentForSave({
                                                data,
                                                assignment,
                                            }),
                                        );
                                    }}
                                >
                                    {t('homework.myAssignments.add')}
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyAssignments;
