import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { sendEmail } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* sendEmailSaga({ payload }: ReturnType<typeof sendEmail>): SagaIterator {
    if (!payload) return;
    try {
        yield put(startLoading());
        yield call(API.post(), `/staff/emails/send`, payload);
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
