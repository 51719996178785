import classNames from 'classnames';
import { useLayoutEffect, useState } from 'react';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import Copy from 'src/components/Icons/Copy';
import CheckMark from 'src/components/Icons/CheckMark';
import AnimatedRowDummy from 'src/components/AnimatedRowDummy/AnimatedRow';
import Table from 'src/components/Table';
import { API } from 'src/api';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import moment from 'moment';
import { selectLoading } from 'src/redux/loading/selectors';
import { appConstants } from 'src/constants';
import { ZoomReportType } from '../../types';

import './styles.scss';

const ReportZoomModal = ({ report, setReport }: { report: number; setReport: any }) => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);

    const [copied, setCopied] = useState(false);
    const [copiedRecord, setCopiedRecord] = useState(false);
    const [reportData, setReportData] = useState(null as unknown as ZoomReportType);

    const [reportTabs, setReportTabs] = useState({
        info: false,
        ai: false,
        part: false,
    });

    useLayoutEffect(() => {
        if (!report) {
            return () => {
                setReportData(null as unknown as ZoomReportType);
                setReportTabs({
                    info: false,
                    ai: false,
                    part: false,
                });
            };
        }
        dispatch(startLoading());
        API.get()(`/staff/classes/${params.id}/meetings/${report}/report`)
            .then((data) => {
                setReportData(data.data);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    }, [report]);

    if (loading || !reportData) {
        return null;
    }

    return (
        <>
            <Modal
                open={report}
                isNewDesign
                header={<span>{t('weeklyPlan.report')}</span>}
                onClose={() => {
                    setReport(0);
                    setCopied(false);
                    setCopiedRecord(false);
                }}
                footer={
                    <>
                        <Button
                            type="square"
                            onClick={() => {
                                setReport(0);
                                setCopied(false);
                                setCopiedRecord(false);
                            }}
                        >
                            {t('weeklyPlan.close')}
                        </Button>
                    </>
                }
            >
                {moment(new Date(reportData?.to_date * 1000))
                    .utc()
                    .isBefore(moment(new Date())) ? (
                    <div className="zoom-report-full">
                        <div className="zoom-report-tab">
                            <div
                                onClick={() => {
                                    setReportTabs((prev) => ({
                                        part: false,
                                        ai: false,
                                        info: !prev.info,
                                    }));
                                }}
                                className={classNames('zoom-report-tab-head', {
                                    'zoom-report-tab-head--active': reportTabs.info,
                                    'zoom-report-tab-head--nonActive': !reportTabs.info,
                                })}
                            >
                                <span>{t('weeklyPlan.zoomInfo')}</span>
                                <ArrowThin fill="#007FA3" />
                            </div>

                            <AnimatedRowDummy
                                isActive={reportTabs.info}
                                setIsActive={(action) => {
                                    setReportTabs({
                                        part: false,
                                        ai: false,
                                        info: action,
                                    });
                                }}
                                content={
                                    <div className="zoom-info">
                                        <div className="zoom-report-link">
                                            <span
                                                onClick={() => {
                                                    window.open(reportData.link, '_blank');
                                                }}
                                            >
                                                {reportData.link.split('?')[0]}
                                            </span>
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(reportData.link);
                                                    setCopied(true);
                                                }}
                                            >
                                                {!copied ? <Copy /> : <CheckMark fill="#AEBE36" />}
                                            </div>
                                        </div>

                                        <div className="zoom-info-row">
                                            <div className="zoom-report-row">
                                                <span>{t('weeklyPlan.date')}</span>
                                                <span>
                                                    {moment(
                                                        new Date(reportData.from_date * 1000),
                                                    ).format('DD MMM yyyy')}
                                                </span>
                                            </div>
                                            <div className="zoom-info-time">
                                                <div className="zoom-report-row">
                                                    <span>{t('weeklyPlan.start')}</span>
                                                    <span>
                                                        {moment(
                                                            new Date(reportData.from_date * 1000),
                                                        ).format('hh:mm A')}
                                                    </span>
                                                </div>
                                                <div className="zoom-report-row">
                                                    <span>{t('weeklyPlan.end')}</span>
                                                    <span>
                                                        {moment(
                                                            new Date(reportData.to_date * 1000),
                                                        ).format('hh:mm A')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="zoom-report-row">
                                            <span>{t('weeklyPlan.topic')}</span>
                                            <span>{reportData.name}</span>
                                        </div>
                                        <div className="zoom-report-row">
                                            <span>{t('weeklyPlan.host')}</span>
                                            <span>{reportData.host}</span>
                                        </div>
                                        <div className="zoom-report-row">
                                            <span>{t('weeklyPlan.recording')}</span>
                                            {!reportData.recording ? (
                                                <div>
                                                    <span>-</span>
                                                </div>
                                            ) : (
                                                <div className="zoom-report-link">
                                                    <span
                                                        onClick={() =>
                                                            window.open(
                                                                reportData.recording.link,
                                                                '_blank',
                                                            )
                                                        }
                                                    >
                                                        {reportData.recording.link}
                                                    </span>
                                                    <div
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                reportData.recording.link,
                                                            );
                                                            setCopiedRecord(true);
                                                        }}
                                                    >
                                                        {!copiedRecord ? (
                                                            <Copy />
                                                        ) : (
                                                            <CheckMark fill="#AEBE36" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {reportData.recording && reportData.recording.password && (
                                            <div className="zoom-report-row zoom-report-pass">
                                                <span>{t('weeklyPlan.password')}</span>
                                                <span>{reportData.recording.password}</span>
                                            </div>
                                        )}
                                        <div className="zoom-report-row">
                                            <span>{t('weeklyPlan.pool')}</span>
                                            <Button
                                                type="square"
                                                onClick={() => {
                                                    window.open(
                                                        `${appConstants.server}/dashboard/100/0/0/0/0/0/0/0/${report}`,
                                                        '_blank',
                                                        'noopener,noreferrer',
                                                    );
                                                }}
                                            >
                                                {t('weeklyPlan.viewResponses')}
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        {reportData.summary && (
                            <div className="zoom-report-tab">
                                <div
                                    onClick={() => {
                                        setReportTabs((prev) => ({
                                            info: false,
                                            part: false,
                                            ai: !prev.ai,
                                        }));
                                    }}
                                    className={classNames('zoom-report-tab-head', {
                                        'zoom-report-tab-head--active': reportTabs.ai,
                                        'zoom-report-tab-head--nonActive': !reportTabs.ai,
                                    })}
                                >
                                    <span>{t('weeklyPlan.aiSummary')}</span>
                                    <ArrowThin fill="#007FA3" />
                                </div>

                                <AnimatedRowDummy
                                    isActive={reportTabs.ai}
                                    setIsActive={(action) => {
                                        setReportTabs({
                                            info: false,
                                            part: false,
                                            ai: action,
                                        });
                                    }}
                                    content={
                                        <div className="zoom-ai">
                                            <span>{reportData.summary}</span>
                                        </div>
                                    }
                                />
                            </div>
                        )}

                        <div className="zoom-report-tab">
                            <div
                                onClick={() => {
                                    setReportTabs((prev) => ({
                                        info: false,
                                        ai: false,
                                        part: !prev.part,
                                    }));
                                }}
                                className={classNames('zoom-report-tab-head', {
                                    'zoom-report-tab-head--active': reportTabs.part,
                                    'zoom-report-tab-head--nonActive': !reportTabs.part,
                                })}
                            >
                                <div className="zoom-report-tab-subhead">
                                    <span>{t('weeklyPlan.participants')}</span>
                                    <span>
                                        {
                                            [
                                                ...reportData.participants
                                                    .reduce((a, c) => {
                                                        a.set(c.id, c);
                                                        return a;
                                                    }, new Map())
                                                    .values(),
                                            ].length
                                        }
                                    </span>
                                </div>
                                <ArrowThin fill="#007FA3" />
                            </div>

                            <AnimatedRowDummy
                                isActive={reportTabs.part}
                                setIsActive={(action) => {
                                    setReportTabs({
                                        info: false,
                                        ai: false,
                                        part: action,
                                    });
                                }}
                                content={
                                    <div className="zoom-part">
                                        <Table
                                            tableRowsClassName=""
                                            header={
                                                <>
                                                    <span className="zoom-part-name">
                                                        {t('weeklyPlan.name')}
                                                    </span>
                                                    <span className="zoom-part-mail">
                                                        {t('weeklyPlan.email')}
                                                    </span>
                                                    <span className="zoom-part-join">
                                                        {t('weeklyPlan.join')}
                                                    </span>
                                                    <span className="zoom-part-left">
                                                        {t('weeklyPlan.left')}
                                                    </span>
                                                </>
                                            }
                                            rows={reportData.participants.map(
                                                (participant: any, i: number) => (
                                                    <>
                                                        <span
                                                            title={participant.name}
                                                            className="zoom-part-name"
                                                        >
                                                            {participant.name}
                                                        </span>
                                                        <span
                                                            className="zoom-part-mail"
                                                            title={participant.email}
                                                        >
                                                            {participant.email}
                                                        </span>
                                                        <span
                                                            className="zoom-part-join"
                                                            title={moment(
                                                                new Date(participant.join * 1000),
                                                            ).format('hh:mm A')}
                                                        >
                                                            {moment(
                                                                new Date(participant.join * 1000),
                                                            ).format('hh:mm A')}
                                                        </span>
                                                        <span
                                                            className="zoom-part-left"
                                                            title={moment(
                                                                new Date(participant.leave * 1000),
                                                            ).format('hh:mm A')}
                                                        >
                                                            {moment(
                                                                new Date(participant.leave * 1000),
                                                            ).format('hh:mm A')}
                                                        </span>
                                                    </>
                                                ),
                                            )}
                                            headerMobile={<></>}
                                            rowsMobile={reportData.participants.map(
                                                (participant: any, idx: number) => {
                                                    return {
                                                        head: (
                                                            <span
                                                                className="zoom-part-name-mob"
                                                                key={`${participant.id}_${idx}`}
                                                            >
                                                                {participant.name}
                                                            </span>
                                                        ),
                                                        content: (
                                                            <div
                                                                key={`${participant.id}_${idx}`}
                                                                className="zoom-part-body-mob"
                                                            >
                                                                <div className="zoom-part-row-mob">
                                                                    <span>
                                                                        {t('weeklyPlan.email')}
                                                                    </span>
                                                                    <span>{participant.email}</span>
                                                                </div>
                                                                <div className="zoom-part-row-mob">
                                                                    <span>
                                                                        {t('weeklyPlan.join')}
                                                                    </span>
                                                                    <span>
                                                                        {moment(
                                                                            new Date(
                                                                                participant.join *
                                                                                    1000,
                                                                            ),
                                                                        ).format('hh:mm A')}
                                                                    </span>
                                                                </div>
                                                                <div className="zoom-part-row-mob">
                                                                    <span>
                                                                        {t('weeklyPlan.left')}
                                                                    </span>
                                                                    <span>
                                                                        {moment(
                                                                            new Date(
                                                                                participant.leave *
                                                                                    1000,
                                                                            ),
                                                                        ).format('hh:mm A')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ),
                                                    };
                                                },
                                            )}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div className="zoom-report">
                        <div className="zoom-report-link">
                            <span
                                onClick={() => {
                                    window.open(reportData.link, '_blank');
                                }}
                            >
                                {reportData.link.split('?')[0]}
                            </span>
                            <div
                                onClick={() => {
                                    navigator.clipboard.writeText(reportData.link);
                                    setCopied(true);
                                }}
                            >
                                {!copied ? <Copy /> : <CheckMark fill="#AEBE36" />}
                            </div>
                        </div>

                        <div className="zoom-report-row">
                            <span>{t('weeklyPlan.date')}</span>
                            <span>
                                {moment(new Date(reportData.from_date * 1000)).format(
                                    'DD MMM yyyy',
                                )}
                            </span>
                        </div>

                        <div className="zoom-report-row">
                            <span
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                {t('weeklyPlan.start')}
                            </span>
                            <span>
                                {moment(new Date(reportData.from_date * 1000)).format('hh:mm A')}
                            </span>
                        </div>

                        <div className="zoom-report-row">
                            <span>{t('weeklyPlan.topic')}</span>
                            <span>{reportData.name}</span>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ReportZoomModal;
