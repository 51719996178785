import { Props } from '../types';

const Add = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path
            id="add"
            d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18.333A8.333,8.333,0,1,1,18.333,10,8.333,8.333,0,0,1,10,18.333ZM14.167,10a.833.833,0,0,1-.833.833h-2.5v2.5a.833.833,0,0,1-1.667,0v-2.5h-2.5a.833.833,0,1,1,0-1.667h2.5v-2.5a.833.833,0,1,1,1.667,0v2.5h2.5A.833.833,0,0,1,14.167,10Z"
            fill={fill}
        />
    </svg>
);

export default Add;
