import { Props } from '../types';

const AssessmentsIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g id="Attendance">
            <path
                d="M8.40001 4.4C6.41179 4.4 4.80001 6.01179 4.80001 8.00001C4.80001 9.98823 6.41179 11.6 8.40001 11.6C10.3883 11.6 12 9.98823 12 8.00001C12 6.01179 10.3883 4.4 8.40001 4.4ZM2.4 8.00001C2.4 4.6863 5.0863 2 8.40001 2C11.7137 2 14.4 4.6863 14.4 8.00001C14.4 11.3137 11.7137 14 8.40001 14C5.0863 14 2.4 11.3137 2.4 8.00001ZM23.6485 9.55148C24.1171 10.0201 24.1171 10.7799 23.6485 11.2485L18.8485 16.0485C18.3799 16.5171 17.6201 16.5171 17.1515 16.0485L14.7515 13.6485C14.2829 13.1799 14.2829 12.4201 14.7515 11.9515C15.2201 11.4829 15.9799 11.4829 16.4485 11.9515L18 13.503L21.9515 9.55148C22.4201 9.08285 23.1799 9.08285 23.6485 9.55148ZM2.6432 16.8074C4.16907 15.7902 6.20792 15.2 8.40001 15.2C10.5921 15.2 12.631 15.7902 14.1568 16.8074C15.6743 17.8191 16.8 19.3484 16.8 21.2C16.8 21.8628 16.2628 22.4 15.6 22.4C14.9373 22.4 14.4 21.8628 14.4 21.2C14.4 20.4006 13.9139 19.53 12.8255 18.8043C11.7455 18.0843 10.1844 17.6 8.40001 17.6C6.61564 17.6 5.05449 18.0843 3.97448 18.8043C2.88608 19.53 2.4 20.4006 2.4 21.2C2.4 21.8628 1.86274 22.4 1.2 22.4C0.537265 22.4 0 21.8628 0 21.2C0 19.3484 1.12571 17.8191 2.6432 16.8074Z"
                fill={fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_1306_3364">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default AssessmentsIcon;
