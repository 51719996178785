import moment from 'moment/moment';

export const getStartOfUtcDay = (date: Date) => {
    return moment()
        .isoWeekYear(date.getFullYear())
        .month(date.getMonth())
        .date(date.getDate())
        .startOf('day')
        .unix();
};
