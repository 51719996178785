import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import {
    GET_ASSESSMENT,
    GET_ASSESSMENT_STUDENTS,
    GET_ASSESSMENT_TYPES,
    SAVE_ASSESSMENT_MARKS,
} from '../actions';
import { getAssessmentSaga } from './getAssessmentSaga';
import { getAssessmentStudentsSaga } from './getAssessmentStudentsSaga';
import { getAssessmentTypesSaga } from './getAssessmentTypesSaga';
import { saveAssessmentMarksSaga } from './saveAssessmentMarksSaga';

export function* assessmentWatcher(): SagaIterator {
    yield takeLatest(GET_ASSESSMENT, getAssessmentSaga);
    yield takeLatest(GET_ASSESSMENT_TYPES, getAssessmentTypesSaga);
    yield takeLatest(GET_ASSESSMENT_STUDENTS, getAssessmentStudentsSaga);
    yield takeLatest(SAVE_ASSESSMENT_MARKS, saveAssessmentMarksSaga);
}
