import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getClass, putClass } from '../actions';

export function* getClassSaga({ payload }: ReturnType<typeof getClass>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/classes/${payload.classId}`);
        yield put(putClass(data));
        if (payload.cb) {
            payload.cb(data);
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
