import { PutStudentResultsHistoryItemAP } from 'src/redux/students/types';

export const getResultScore = (score: PutStudentResultsHistoryItemAP): string => {
    if (score.score === 'N/A') {
        return 'N/A';
    }

    if (score.score) {
        return `${score.score}${score.scoreType === 'percent' ? '%' : ''}`;
    }

    return 'N/A';
};
