import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import CustomLink from 'src/components/CustomLink';
import CustomLinkOverloaded from 'src/components/CustomLink/CustomLinkOverloaded';
import Delete from 'src/components/Icons/Delete';
import Edit from 'src/components/Icons/Edit';
import AssignModal from 'src/components/Modals/AssignModal';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Table from 'src/components/Table';
import { appConstants } from 'src/constants';
import { getDatesFormattedInterval } from 'src/containers/helpers/getDatesFormattedInterval';
import { getEndOfUtcDay } from 'src/helpers/getEndOfUtcDay';
import { getPartialRange } from 'src/helpers/getPartialRange';
import { getStartOfUtcDay } from 'src/helpers/getStartOfUtcDay';
import { deleteProgress, updateProgress } from 'src/redux/progress/actions';

import './styles.scss';

const Assigned = ({ assigned, activeType }: any) => {
    const { t } = useTranslation();
    const [testToDelete, setTestToDelete] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const [assign, setAssign] = useState(0);
    return (
        <>
            <Table
                tableRowsClassName="progress-table-rows"
                header={
                    <>
                        <span className="progress-list">{t('assigned.listOfAssignedTests')}</span>
                        <span className="progress-available">{t('assigned.available')}</span>
                    </>
                }
                rows={
                    assigned?.map((test: any, i: number) => (
                        <div
                            key={`${test.id}_${i}`}
                            className={classNames('online-test-row-item', {
                                selected: test.currentlySelected,
                            })}
                        >
                            <span
                                className="progress-list progress-active"
                                style={{ cursor: 'default' }}
                                title={test.name}
                            >
                                <b>{i + 1}.</b>
                                <CustomLinkOverloaded
                                    linkProps={{
                                        to: `${appConstants.server}/progress_test/testdisplay/demo/${test.testId}`,
                                        className: 'tr_hover_color_text progress-active',
                                    }}
                                    parentProps={{
                                        style: { display: 'contents' },
                                    }}
                                >
                                    {test.name}
                                </CustomLinkOverloaded>
                            </span>
                            <div className="progress-available progress-available-row">
                                <div
                                    className="progress-edit progress-active tr_hover_color_text"
                                    onClick={() => {
                                        setAssign(test.id);
                                    }}
                                >
                                    <span>{getDatesFormattedInterval(test.from, test.to)}</span>
                                    <Edit />
                                </div>
                                <div className="progress-report">
                                    <CustomLink
                                        className="progress-active tr_hover_color_text_svg"
                                        target="_blank"
                                        rel="noreferrer"
                                        to={`${appConstants.server}/dashboard/${test.report}/${params.id}/0/0/0/0/${test.testId}`}
                                    >
                                        {t('assigned.report')}
                                    </CustomLink>
                                    <div
                                        onClick={() => {
                                            setTestToDelete(test.id);
                                        }}
                                        className="progress-delete sc_hover_color_text_svg"
                                    >
                                        <Delete />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) ?? []
                }
                headerMobile={
                    <>
                        <span>{t('assigned.listOfAssignedTests')}</span>
                    </>
                }
                rowsMobile={
                    assigned?.map((test: any, i: number) => {
                        return {
                            head: (
                                <div
                                    key={`${test.id}_${i}`}
                                    className={classNames('progress-name-mobile progress-active', {
                                        selected: test.currentlySelected,
                                    })}
                                >
                                    <b>{i + 1}.</b>
                                    {test.name}
                                </div>
                            ),
                            content: (
                                <>
                                    <div className="progress-mobile-row">
                                        <div className="progress-mobile-row-title">
                                            <b>{t('assigned.available')}</b>
                                        </div>
                                        <div
                                            className="progress-active progress-mobile-edit"
                                            onClick={() => {
                                                setAssign(test.id);
                                            }}
                                        >
                                            <span>
                                                {getDatesFormattedInterval(test.from, test.to)}
                                            </span>
                                            <Edit />
                                        </div>
                                    </div>
                                    <div className="progress-mobile-row">
                                        <div className="progress-mobile-row-title">
                                            <b>{t('assigned.action')}</b>
                                        </div>
                                        <a
                                            className="automatedTests-active"
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`${appConstants.server}/dashboard/${test.report}/${params.id}/0/0/0/0/${test.testId}`}
                                        >
                                            {t('assigned.report')}
                                        </a>
                                    </div>
                                    <div className="progress-mobile-row">
                                        <div className="progress-mobile-row-title" />
                                        <div
                                            onClick={() => {
                                                setTestToDelete(test.id);
                                            }}
                                            className="progress-delete"
                                        >
                                            <Delete />
                                        </div>
                                    </div>
                                </>
                            ),
                        };
                    }) ?? []
                }
            />
            <ConfirmModal
                text={t('assigned.areYouSure')}
                onClick={() => {
                    dispatch(
                        deleteProgress({
                            classId: params.id,
                            testId: testToDelete,
                            type: activeType,
                        }),
                    );
                    setTestToDelete(0);
                }}
                onClose={() => {
                    setTestToDelete(0);
                }}
                open={testToDelete}
            />
            <AssignModal
                type="rangeCustom"
                onChangeReturnType="date"
                open={assign}
                allowPartialRange
                defaultDateEmpty
                onAssign={(date) => {
                    date = getPartialRange(date);
                    dispatch(
                        updateProgress({
                            classId: params.id,
                            from: getStartOfUtcDay(date[0]),
                            to: getEndOfUtcDay(date[1]),
                            testId: assign,
                            type: activeType,
                        }),
                    );
                }}
                onClose={() => {
                    setAssign(0);
                }}
            />
        </>
    );
};

export default Assigned;
