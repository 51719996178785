import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getWeeklyPlan, restart } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* restartSaga({ payload }: ReturnType<typeof restart>): SagaIterator {
    try {
        const { week, year, unit, classId, fullSet, curated, cb } = payload;
        yield put(startLoading());
        yield call(API.post(), `/staff/classes/${classId}/restart`, {
            week,
            year,
            unit,
            fullSet,
            curated,
        });
        yield put(
            getWeeklyPlan({
                week,
                year,
                id: String(classId),
            }),
        );

        if (cb) {
            cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
