import { Props } from '../types';

const WeeklyPlanIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.696"
        height="23.911"
        viewBox="0 0 25.696 23.911"
    >
        <g id="Weekly_plan" data-name="Weekly plan" transform="translate(0 0)">
            <g id="Group_577" data-name="Group 577" transform="translate(0 0)">
                <path
                    id="Path_1233"
                    data-name="Path 1233"
                    d="M20.343,2.141H19.272V1.071a1.071,1.071,0,1,0-2.141,0V2.141H8.565V1.071a1.071,1.071,0,0,0-2.141,0V2.141H5.353A5.36,5.36,0,0,0,0,7.495V18.558a5.36,5.36,0,0,0,5.353,5.353H20.343A5.36,5.36,0,0,0,25.7,18.558V7.495A5.36,5.36,0,0,0,20.343,2.141ZM2.141,7.495A3.212,3.212,0,0,1,5.353,4.283H20.343a3.212,3.212,0,0,1,3.212,3.212V8.565H2.141Zm18.2,14.276H5.353a3.212,3.212,0,0,1-3.212-3.212V10.707H23.555v7.852A3.212,3.212,0,0,1,20.343,21.77Z"
                    fill={fill}
                />
                <ellipse
                    id="Ellipse_54"
                    data-name="Ellipse 54"
                    cx="1.606"
                    cy="1.606"
                    rx="1.606"
                    ry="1.606"
                    transform="translate(11.242 14.126)"
                    fill={fill}
                />
                <ellipse
                    id="Ellipse_55"
                    data-name="Ellipse 55"
                    cx="1.606"
                    cy="1.606"
                    rx="1.606"
                    ry="1.606"
                    transform="translate(5.889 14.126)"
                    fill={fill}
                />
                <ellipse
                    id="Ellipse_56"
                    data-name="Ellipse 56"
                    cx="1.606"
                    cy="1.606"
                    rx="1.606"
                    ry="1.606"
                    transform="translate(16.595 14.126)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default WeeklyPlanIcon;
