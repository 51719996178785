import classNames from 'classnames';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import Heading from 'src/components/Heading';
import Edit from 'src/components/Icons/Edit';
import EmptyCalendarIcon from 'src/components/Icons/EmptyCalendarIcon';
import ListIcon from 'src/components/Icons/ListIcon';
import Remove from 'src/components/Icons/Remove';
import SheetIcon from 'src/components/Icons/SheetIcon';
import TwoPeopleIcon from 'src/components/Icons/TwoPeopleIcon';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import Search from 'src/components/Search';
import SortableHeader from 'src/components/SortableHeader';
import Table from 'src/components/Table';
import TablePaginationJS from 'src/components/TablePaginationJS';
import TablePaginationLimits from 'src/components/TablePaginationLimits';
import WhiteBox from 'src/components/WhiteBox';
import { PAGINATION_LIMITS } from 'src/constants';
import { useFilterHelper } from 'src/hooks/useFilterHelper';
import { usePaginationJSProcessor } from 'src/hooks/usePaginationJSProcessor';
import useSortable from 'src/hooks/useSortable';
import { NotificationStatusEnum, useNotificationQueue } from 'src/providers/NotificationProvider';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import { deleteToDo, getAllToDo, saveToDo } from 'src/redux/toDo/actions';
import { selectAllToDo } from 'src/redux/toDo/selectors';
import './styles.scss';
import ToDoListAddOrEditItemModal from './ToDoListAddOrEditItemModal';
import {
    TO_DO_LIST_REF,
    TO_DO_LIST_SESSION,
    TO_DO_LIST_TYPE,
} from './ToDoListAddOrEditItemModal/constants';
import ToDoListHeaderButton from './ToDoListHeaderButtons';

const Pagination = ({
    mob,
    sortable,
    filter,
    updateFilter,
    paginatedItems,
}: {
    mob?: boolean;
    sortable: any;
    filter: any;
    updateFilter: any;
    paginatedItems: any;
}) => {
    if (!sortable.items.length) {
        return <></>;
    }
    return (
        <div className={classNames('toDoList-pagination', mob ? 'mob' : 'desc')}>
            <TablePaginationLimits
                hide={!sortable.items.length}
                title="Rows per page"
                filter={filter}
                updateFilter={updateFilter}
            />
            <div className="toDoList-pagination-delimiter" />
            <TablePaginationJS
                type="pages-left"
                alwaysShow
                onLeft={(page) => {
                    updateFilter({ page });
                    paginatedItems.previousPage();
                }}
                onRight={(page) => {
                    updateFilter({ page });
                    paginatedItems.nextPage();
                }}
                paginationProcessor={paginatedItems}
            />
        </div>
    );
};

const TableHeader = ({
    mob,
    setOpenAddItemModal,
    setSearch,
}: {
    mob?: boolean;
    setOpenAddItemModal: Dispatch<SetStateAction<any>>;
    setSearch: Dispatch<SetStateAction<any>>;
}) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('toDoList-table-header', mob ? 'mob' : 'desc')}>
            <Search newDesign alwaysWide onChange={(text: string) => setSearch(text)} />
            <Button
                type="secondary-thin"
                onClick={() => {
                    setOpenAddItemModal(true);
                }}
            >
                <>
                    <div style={{ fontSize: '1.8rem', marginRight: '5px' }}>+</div>
                    {t('toDoList.addItem')}
                </>
            </Button>
        </div>
    );
};
const ToDoList = () => {
    const dispatch = useDispatch();
    const notificationQueue = useNotificationQueue();
    const items = useSelector(selectAllToDo);
    const [openEditModal, setOpenEditModal] = useState(null);
    const [openAddItemModal, setOpenAddItemModal] = useState(false);
    const sortable = useSortable({ itemsToSort: items });
    const [search, setSearch] = useState('');
    const [openDeleteItemModal, setOpenDeleteItemModal] = useState(0);
    const [activeButton, setActiveButton] = useState('all');
    const [filter, setFilter] = useState({
        recordsPerPage: PAGINATION_LIMITS[0],
        limit: PAGINATION_LIMITS[0],
    });
    const { updateFilter } = useFilterHelper({ filter, setFilter });
    const { t } = useTranslation();
    const paginatedItems = usePaginationJSProcessor({
        items: sortable.items,
        limit: filter.recordsPerPage,
    });

    useEffect(() => {
        sortable.updateSearchFilter(
            [
                {
                    attrToSearch: 'timestamp',
                    ruleToSearch: (item, searchValue) => {
                        return moment
                            .unix(item.timestamp)
                            .format()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase());
                    },
                },
                { attrToSearch: 'session' },
                {
                    attrToSearch: 'type',
                    ruleToSearch: (item, searchValue) => {
                        return item.content.type.includes(searchValue.toLowerCase());
                    },
                },
                { attrToSearch: 'text' },
                {
                    attrToSearch: 'published',
                    ruleToSearch: (item, searchValue) => {
                        const name = item.published ? 'published' : 'not published';
                        return name.includes(searchValue.toLowerCase());
                    },
                },
            ],
            search,
        );
        if (activeButton === 'all') {
            sortable.updateFilter('session', '');
        } else if (activeButton) {
            sortable.updateFilter('session', activeButton);
        }
    }, [search, activeButton, items]);

    useEffect(() => {
        dispatch(
            putBreadcrumbs([
                {
                    name: t('toDoList.toDoList'),
                },
            ]),
        );
        dispatch(getAllToDo());
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, []);

    const HEADER_BUTTONS = useMemo(
        () => [
            {
                id: 'all',
                icon: <ListIcon />,
                title: items.length,
                subtitle: t('general.all'),
            },
            {
                id: 'pre-sessional',
                icon: <SheetIcon />,
                title: items.filter((item) => item.session === 'pre-sessional').length,
                subtitle: t('qrCodes.preSessional'),
            },
            {
                id: 'in-school',
                icon: <EmptyCalendarIcon />,
                title: items.filter((item) => item.session === 'in-school').length,
                subtitle: t('toDoList.inSchool'),
            },
            {
                id: 'post-sessional',
                icon: <TwoPeopleIcon />,
                title: items.filter((item) => item.session === 'post-sessional').length,
                subtitle: t('toDoList.postSessional'),
            },
        ],
        [items],
    );

    const updateToDoItemStatus = (pageItem: any) => {
        dispatch(
            saveToDo({
                session: pageItem.session,
                text: pageItem.text,
                published: !pageItem.published,
                content:
                    TO_DO_LIST_REF.isText(pageItem.type) || !pageItem.type
                        ? null
                        : pageItem.content,
                toDoId: pageItem.id,
                cb: () => {
                    notificationQueue.add('ToDoDelete', {
                        message: pageItem.published
                            ? t('toDoList.successfulUnpublished')
                            : t('toDoList.successfulPublished'),
                        status: NotificationStatusEnum.success,
                    });
                },
            }),
        );
    };

    const handleOnClickDeleteModal = () =>
        dispatch(
            deleteToDo({
                toDoId: openDeleteItemModal,
                cb: () => {
                    setOpenDeleteItemModal(0);
                    notificationQueue.add('ToDoDelete', {
                        message: t('toDoList.removalSuccessful'),
                        status: NotificationStatusEnum.success,
                    });
                },
            }),
        );

    const getTypeTitle = (type: string) => {
        return (
            TO_DO_LIST_TYPE(t).find((curType) => {
                return curType.value === type;
            })?.name ?? ''
        );
    };

    const getSessionTitle = (sessionName: string) => {
        return (
            TO_DO_LIST_SESSION(t).find((curType) => {
                return curType.value === sessionName;
            })?.name ?? ''
        );
    };
    return (
        <div className="toDoList">
            <Heading type="main">{t('toDoList.toDoList')}</Heading>
            <div className="toDoList-buttons">
                {HEADER_BUTTONS.map((button, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <ToDoListHeaderButton
                                active={activeButton === button.id}
                                onClick={() => setActiveButton(button.id)}
                                icon={button.icon}
                                title={button.title}
                                subtitle={button.subtitle}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
            <TableHeader mob setOpenAddItemModal={setOpenAddItemModal} setSearch={setSearch} />
            <div className="toDoListTable-parent">
                <WhiteBox>
                    <TableHeader setOpenAddItemModal={setOpenAddItemModal} setSearch={setSearch} />
                    <div className="toDoList-table">
                        <Table
                            tableRowsClassName="toDoList-table-row"
                            header={
                                <>
                                    <SortableHeader
                                        addClass=""
                                        direction={sortable.getDirection('timestamp')}
                                        onClick={() =>
                                            sortable.updateSort(
                                                'timestamp',
                                                (item: any) => item.timestamp,
                                            )
                                        }
                                    >
                                        {t('general.date').toUpperCase()}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass=""
                                        direction={sortable.getDirection('session')}
                                        onClick={() =>
                                            sortable.updateSort(
                                                'session',
                                                (item: any) => item.session,
                                            )
                                        }
                                    >
                                        {t('general.session').toUpperCase()}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass=""
                                        direction={sortable.getDirection('content')}
                                        onClick={() =>
                                            sortable.updateSort(
                                                'content',
                                                (item: any) => item.content?.type,
                                            )
                                        }
                                    >
                                        {t('homework.myAssignments.type').toUpperCase()}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass=""
                                        direction={sortable.getDirection('text')}
                                        onClick={() =>
                                            sortable.updateSort('text', (item: any) => item.text)
                                        }
                                    >
                                        {t('general.description').toUpperCase()}
                                    </SortableHeader>
                                    <SortableHeader
                                        addClass=""
                                        direction={sortable.getDirection('published')}
                                        onClick={() =>
                                            sortable.updateSort(
                                                'published',
                                                (item: any) => item.published,
                                            )
                                        }
                                    >
                                        {t('general.status').toUpperCase()}
                                    </SortableHeader>
                                    <span />
                                </>
                            }
                            rows={paginatedItems.data.map((pageItem: any) => {
                                return (
                                    <React.Fragment key={pageItem.id}>
                                        <span title={pageItem.timestamp}>
                                            {moment.unix(pageItem.timestamp).format()}
                                        </span>
                                        <span title={getSessionTitle(pageItem.session)}>
                                            {getSessionTitle(pageItem.session)}
                                        </span>
                                        <span
                                            title={
                                                pageItem.content?.type
                                                    ? getTypeTitle(pageItem.content.type)
                                                    : t('general.text')
                                            }
                                        >
                                            {pageItem.content?.type
                                                ? getTypeTitle(pageItem.content.type)
                                                : t('general.text')}
                                        </span>
                                        <span title={pageItem.text}>{pageItem.text}</span>
                                        <span
                                            className={classNames('toDoList-row-status', {
                                                active: pageItem.published,
                                            })}
                                        >
                                            <div
                                                onClick={() => updateToDoItemStatus(pageItem)}
                                                className="cursor-pointer"
                                                title={
                                                    pageItem.published
                                                        ? t('general.published')
                                                        : t('general.notPublished')
                                                }
                                            >
                                                {pageItem.published
                                                    ? t('general.published')
                                                    : t('general.notPublished')}
                                            </div>
                                        </span>

                                        <span className="toDoList-table-actions">
                                            <span
                                                onClick={() => {
                                                    setOpenAddItemModal(true);
                                                    setOpenEditModal(pageItem);
                                                }}
                                                className="sc_hover_color_text_svg cursor-pointer"
                                            >
                                                <Edit />
                                            </span>
                                            <span
                                                onClick={() => {
                                                    setOpenDeleteItemModal(pageItem.id);
                                                }}
                                                className="sc_hover_color_text_svg cursor-pointer"
                                            >
                                                <Remove />
                                            </span>
                                        </span>
                                    </React.Fragment>
                                );
                            })}
                        />
                    </div>
                    <Pagination
                        sortable={sortable}
                        filter={filter}
                        updateFilter={updateFilter}
                        paginatedItems={paginatedItems}
                    />
                </WhiteBox>
            </div>
            <Pagination
                mob
                sortable={sortable}
                filter={filter}
                updateFilter={updateFilter}
                paginatedItems={paginatedItems}
            />
            <ToDoListAddOrEditItemModal
                open={openAddItemModal}
                onClose={() => {
                    setOpenAddItemModal(false);
                    setOpenEditModal(null);
                }}
                edit={openEditModal}
            />
            <ConfirmModal
                open={openDeleteItemModal}
                onClick={handleOnClickDeleteModal}
                onClose={() => setOpenDeleteItemModal(0)}
                text={t('general.deleteItemConfirmation')}
            />
        </div>
    );
};

export default ToDoList;
