import { Props } from '../types';

const CalendarIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30.098"
        height="30.098"
        viewBox="0 0 30.098 30.098"
    >
        <g id="Calendar" transform="translate(256 256)">
            <g id="Group_507" data-name="Group 507" transform="translate(-256 -256)">
                <path
                    id="Path_1050"
                    data-name="Path 1050"
                    d="M23.827,2.508H22.573V1.254a1.254,1.254,0,0,0-2.508,0V2.508H10.033V1.254a1.254,1.254,0,0,0-2.508,0V2.508H6.27A6.278,6.278,0,0,0,0,8.778V23.827A6.278,6.278,0,0,0,6.27,30.1H23.827a6.278,6.278,0,0,0,6.27-6.27V8.778A6.278,6.278,0,0,0,23.827,2.508ZM2.508,8.778A3.762,3.762,0,0,1,6.27,5.016H23.827a3.762,3.762,0,0,1,3.762,3.762v1.254H2.508ZM23.827,27.589H6.27a3.762,3.762,0,0,1-3.762-3.762V12.541H27.589V23.827A3.762,3.762,0,0,1,23.827,27.589Z"
                    fill={fill}
                />
                <circle
                    id="Ellipse_28"
                    data-name="Ellipse 28"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(6.897 15.049)"
                    fill={fill}
                />
                <circle
                    id="Ellipse_45"
                    data-name="Ellipse 45"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(6.897 20.516)"
                    fill={fill}
                />
                <circle
                    id="Ellipse_29"
                    data-name="Ellipse 29"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(19.438 15.049)"
                    fill={fill}
                />
                <circle
                    id="Ellipse_48"
                    data-name="Ellipse 48"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(13.168 15.049)"
                    fill={fill}
                />
                <circle
                    id="Ellipse_47"
                    data-name="Ellipse 47"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(19.438 20.516)"
                    fill={fill}
                />
                <circle
                    id="Ellipse_49"
                    data-name="Ellipse 49"
                    cx="1.881"
                    cy="1.881"
                    r="1.881"
                    transform="translate(13.168 20.516)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default CalendarIcon;
