import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import TestByAimIcon from 'src/components/Icons/TestByAim';
import TestByUnitIcon from 'src/components/Icons/TestByUnit';
import { selectBook } from 'src/redux/coursePlanner/selectors';
import { Method } from '../../types';
import './styles.scss';

type SelectMethodTypes = {
    onChange: (data: { [key: string]: string | number[] }) => void;
    method?: string;
};

export default function SelectMethod({ onChange, method }: SelectMethodTypes) {
    const book = useSelector(selectBook);

    const handleChange = (e: SyntheticEvent) => {
        onChange({
            method: (e.target as HTMLInputElement).value,
            units: [] as number[],
            unitTest: [] as number[],
            name: '',
        });
    };
    return (
        <div className="makeUnit-stepContent selectMethod">
            <h1 className="makeUnit-stepTitle">Select Method</h1>
            <div className="makeUnit-testVariant">
                <input
                    type="radio"
                    id="testByAim"
                    name="Method"
                    onChange={handleChange}
                    value={Method.TestByAim}
                    defaultChecked={method === Method.TestByAim}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="testByAim">
                    <TestByAimIcon />
                    <div>
                        <h4>Test by aims</h4>
                        <span>Create your own test using aims</span>
                    </div>
                </label>
            </div>
            <div className="makeUnit-testVariant">
                <input
                    type="radio"
                    id="testByUnit"
                    name="Method"
                    disabled={!book.id}
                    value={Method.TestByUnit}
                    onChange={handleChange}
                    defaultChecked={method === Method.TestByUnit}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="testByUnit">
                    <TestByUnitIcon />
                    <div>
                        <h4>Test by Unit</h4>
                        <span>
                            Create your own test based on the units of the course book you are using
                        </span>
                    </div>
                </label>
            </div>
        </div>
    );
}
