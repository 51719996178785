import { createReducer } from '@reduxjs/toolkit';
import { FINISH_LOADING, START_LOADING } from './actions';

export type LoadingState = {
    loading: boolean[];
};

const initialState = {
    loading: [],
};

export const loadingReducer = createReducer<LoadingState>(initialState, {
    [START_LOADING]: (state) => {
        state.loading.push(true);
    },
    [FINISH_LOADING]: (state) => {
        state.loading.pop();
    },
});
