import { useEffect, useState } from 'react';

export type UsePaginationJSProcessorReturnProps = {
    nextPage: () => void;
    previousPage: () => void;
    data: any[];
    dataAll: any[];
    pages: number;
    page: number;
    getCurrentPageStartIndex: () => number;
    getItemsCount: () => number;
    limit: number;
};

export const usePaginationJSProcessor = ({
    items,
    limit,
}: {
    items: Array<any>;
    limit: number;
}): UsePaginationJSProcessorReturnProps => {
    const [page, setPage] = useState(1);
    const [newItems, setNewItems] = useState({ data: [], page: 1, pages: 0 });
    const [slicedItems, setSlicedItems] = useState<any[]>([]);
    const [dataAll, setDataAll] = useState([] as any);
    const getItemsCount = () => {
        return slicedItems.flat().length;
    };

    const spliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        if (typeof chunkSize !== 'number') {
            throw new Error('Chunk size must be a number.');
        }
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }
        return res;
    };

    const getCurrentPageStartIndex = () => {
        return limit * (page - 1);
    };

    const nextPage = () => {
        const curPage = +newItems.page;
        const tempNextPage = curPage + 1;
        const curNextPage = tempNextPage > newItems.pages ? curPage : tempNextPage;
        setPage(curNextPage);
    };

    const previousPage = () => {
        const curPage = +newItems.page;
        const curPrevPage = curPage > 1 ? curPage - 1 : 1;
        setPage(curPrevPage);
    };

    const createPaginatedItems = () => {
        if (limit && items) {
            const tempItems = JSON.parse(JSON.stringify(items));
            const temp = spliceIntoChunks(tempItems, limit);
            setSlicedItems(temp);
        }
    };

    useEffect(() => {
        setPage(page !== 1 ? page : 1);
        createPaginatedItems();
    }, [items]);

    useEffect(() => {
        setPage(1);
        createPaginatedItems();
    }, [limit]);

    useEffect(() => {
        createPaginatedItems();
    }, []);

    useEffect(() => {
        if (slicedItems.length) {
            setDataAll(slicedItems.flat());
            if (slicedItems[page - 1]) {
                setNewItems({ data: slicedItems[page - 1], page, pages: slicedItems.length });
            } else {
                setPage(1);
                setNewItems({ data: slicedItems[0], page, pages: slicedItems.length });
            }
        } else {
            setNewItems({ data: [], page, pages: slicedItems.length });
        }
    }, [slicedItems, page]);

    return {
        nextPage,
        previousPage,
        data: newItems.data,
        pages: newItems.pages,
        page: newItems.page,
        getCurrentPageStartIndex,
        getItemsCount,
        limit,
        dataAll,
    };
};
