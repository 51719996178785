import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ActionText from 'src/components/ActionText';
import Button from 'src/components/Button';
import InputText from 'src/components/InputText';
import Modal from 'src/components/Modal';
import SuccessModal from 'src/components/Modals/SuccessModal';
import Table from 'src/components/Table';
import TableMobRow from 'src/components/TableMobRow';
import TablePaginationJS from 'src/components/TablePaginationJS';
import WhiteBox from 'src/components/WhiteBox';
import { appConstants, ERRORS, PAGINATION_LIMITS } from 'src/constants';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { useFilterHelper } from 'src/hooks/useFilterHelper';
import { usePaginationJSProcessor } from 'src/hooks/usePaginationJSProcessor';
import { putSidebar } from 'src/redux/layout/actions';
import { updateStudent } from 'src/redux/students/actions';
import { getDuplicatedStudents, getUnmatchedStudents } from 'src/redux/users/actions';
import { selectUnmatchedStudents } from 'src/redux/users/selectors';
import * as yup from 'yup';

import './styles.scss';
import { useTranslation } from 'react-i18next';
import UnmatchedStudentsHeader from './UnmatchedStudentsHeader';
import UnmatchedStudentsViewModal from './UnmatchedStudentsViewModal';
import { useValuesFilter } from './useValuesFilter';

const UnmatchedStudents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openChangeReportedId, setOpenChangeReportedId] = useState(null as any);
    const [openSimilarName, setOpenSimilarName] = useState(null as any);
    const [openSuccessModal, setOpenSuccessModal] = useState(null as any);
    const [openSameClassId, setOpenSameClassId] = useState(null as any);
    const [filter, setFilter] = useState({
        recordsPerPage: PAGINATION_LIMITS[0],
    } as any);
    const { t } = useTranslation();

    const filterHelper = useFilterHelper({ filter, setFilter });

    const unmatchedStudents = useSelector(selectUnmatchedStudents);
    const filteredStudents = useValuesFilter({ values: unmatchedStudents, filter });
    const paginationProcessor = usePaginationJSProcessor({
        items: filteredStudents.newValues,
        limit: filter.recordsPerPage,
    });

    const formik = useFormik({
        initialValues: {
            reportedId: '',
        },
        onSubmit: () => {
            formik.setTouched({});
            dispatch(
                updateStudent({
                    data: {
                        reportedId: formik.values.reportedId,
                    },
                    studentId: openChangeReportedId.uid,
                    cb: () => {
                        setOpenChangeReportedId(false);
                        setOpenSuccessModal(true);
                        dispatch(getDuplicatedStudents({}));
                        dispatch(getUnmatchedStudents());
                    },
                }),
            );
        },
        validationSchema: yup.object().shape({
            reportedId: yup
                .string()
                .required(ERRORS.required)
                .test({
                    message: ERRORS.same,
                    test: (value) => {
                        return value !== openChangeReportedId.reportedId;
                    },
                }),
        }),
    });

    useEffect(() => {
        if (openChangeReportedId) {
            formik.setFieldValue('reportedId', openChangeReportedId.reportedId);
        }
    }, [openChangeReportedId]);

    useEffect(() => {
        dispatch(putSidebar('root'));
        dispatch(getUnmatchedStudents());
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch]);

    return (
        <WhiteBox styles={{ height: '100%' }}>
            <UnmatchedStudentsHeader filterHelper={filterHelper} items={paginationProcessor} />
            <div className="unmatchedStudents-info">
                <hr />
                {t('unmatchedStudents.invitedStudentsWithoutMatchingSchoolID')}
                <br /> {t('unmatchedStudents.changeReportedIDOrMatchManually')}
            </div>
            <div className="unmatchedStudents-table">
                <Table
                    customEmptyMessage={t('unmatchedStudents.noResults')}
                    tableRowsClassName="unmatchedStudents-rows"
                    header={
                        <>
                            <span>{t('general.name')}</span>
                            <span>{t('general.email')}</span>
                            <span>{t('general.startDate')}</span>
                            <span>{t('general.lastClass')}</span>
                            <span>{t('general.reportedID')}</span>
                            <span>{t('general.changeID')}</span>
                            <span>{t('general.sameClass')}</span>
                            <span>{t('general.similarName')}</span>
                        </>
                    }
                    rows={paginationProcessor.data.map((oneStudent: any) => {
                        return (
                            <>
                                <span title={getNoEmpty(oneStudent.name)}>
                                    <ActionText
                                        to={`${appConstants.server}/administration/studentmanagement/report/${oneStudent.uid}`}
                                    >
                                        {getNoEmpty(oneStudent.name)}
                                    </ActionText>
                                </span>
                                <span title={getNoEmpty(oneStudent.mail)}>
                                    {getNoEmpty(oneStudent.mail)}
                                </span>
                                <span
                                    title={moment
                                        .unix(oneStudent.startAccess as number)
                                        .format('DD MMM YYYY')}
                                >
                                    {moment
                                        .unix(oneStudent.startAccess as number)
                                        .format('DD MMM YYYY')}
                                </span>

                                <span title={getNoEmpty(oneStudent?.class?.name)}>
                                    {getNoEmpty(oneStudent?.class?.name)}
                                </span>
                                <span title={getNoEmpty(oneStudent.reportedId)}>
                                    {getNoEmpty(oneStudent.reportedId)}
                                </span>
                                <span>
                                    <ActionText onClick={() => setOpenChangeReportedId(oneStudent)}>
                                        {t('general.changeID').toUpperCase()}
                                    </ActionText>
                                </span>
                                <span>
                                    {oneStudent.class && (
                                        <ActionText
                                            onClick={() => {
                                                setOpenSameClassId(oneStudent);
                                            }}
                                        >
                                            {t('general.view').toUpperCase()}
                                        </ActionText>
                                    )}
                                </span>
                                <span>
                                    {oneStudent.name && (
                                        <ActionText onClick={() => setOpenSimilarName(oneStudent)}>
                                            {t('general.view').toUpperCase()}
                                        </ActionText>
                                    )}
                                </span>
                            </>
                        );
                    })}
                    headerMobile={
                        <>
                            <span>{t('general.student')}</span>
                        </>
                    }
                    rowsMobile={paginationProcessor.data.map((oneStudentMob: any, i: number) => {
                        return {
                            head: (
                                <>
                                    <div className="students-name-mobile students-active">
                                        <b>
                                            {paginationProcessor.getCurrentPageStartIndex() + i + 1}
                                            .
                                        </b>
                                        {getNoEmpty(oneStudentMob.name)}
                                    </div>
                                </>
                            ),
                            content: (
                                <>
                                    <TableMobRow title={t('general.name')}>
                                        <ActionText
                                            to={`${appConstants.server}/administration/studentmanagement/report/${oneStudentMob.uid}`}
                                        >
                                            {getNoEmpty(oneStudentMob.name)}
                                        </ActionText>
                                    </TableMobRow>
                                    <TableMobRow title={t('general.email')}>
                                        {getNoEmpty(oneStudentMob.mail)}
                                    </TableMobRow>
                                    <TableMobRow title={t('general.startDate')}>
                                        {moment
                                            .unix(oneStudentMob.startAccess as number)
                                            .format('DD MMM YYYY')}
                                    </TableMobRow>
                                    <TableMobRow title={t('general.lastClass')}>
                                        {getNoEmpty(oneStudentMob?.class?.name)}
                                    </TableMobRow>
                                    <TableMobRow title={t('general.reportedID')}>
                                        {getNoEmpty(oneStudentMob.reportedId)}
                                    </TableMobRow>
                                    <TableMobRow title={t('general.changeID')}>
                                        <ActionText
                                            onClick={() => {
                                                formik.setFieldValue('reportedId', '');
                                                setOpenChangeReportedId(oneStudentMob);
                                            }}
                                        >
                                            {t('general.changeID').toUpperCase()}
                                        </ActionText>
                                    </TableMobRow>
                                    <TableMobRow title={t('general.sameClass')}>
                                        {oneStudentMob.class && (
                                            <ActionText
                                                onClick={() => {
                                                    setOpenSameClassId(oneStudentMob);
                                                }}
                                            >
                                                {t('general.view').toUpperCase()}
                                            </ActionText>
                                        )}
                                    </TableMobRow>
                                    <TableMobRow title={t('general.similarName')}>
                                        <ActionText
                                            onClick={() => setOpenSimilarName(oneStudentMob)}
                                        >
                                            {t('general.view').toUpperCase()}
                                        </ActionText>
                                    </TableMobRow>
                                </>
                            ),
                        };
                    })}
                />

                <TablePaginationJS
                    mob
                    paginationProcessor={paginationProcessor}
                    onRight={({ page }) => {
                        filterHelper.updateFilter({ page });
                        paginationProcessor.nextPage();
                    }}
                    onLeft={({ page }) => {
                        filterHelper.updateFilter({ page });
                        paginationProcessor.previousPage();
                    }}
                />
            </div>
            {openChangeReportedId && (
                <Modal
                    open={openChangeReportedId}
                    onClose={() => {
                        setOpenChangeReportedId(null);
                    }}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        className="unmatchedStudents-change-reported-id"
                    >
                        <span>
                            {t('unmatchedStudents.enterCorrectUserID')} “{openChangeReportedId.name}
                            ” (id: {openChangeReportedId.mail})
                        </span>
                        <InputText
                            inputProps={{ defaultValue: formik.values.reportedId }}
                            error={formik.touched.reportedId ? formik.errors.reportedId : ''}
                            onChange={(inputValue) =>
                                formik.setFieldValue('reportedId', inputValue)
                            }
                        />
                        <hr />
                        <div className="unmatchedStudents-change-reported-id__buttons">
                            <Button
                                submit
                                disabled={!formik.isValid && formik.touched.reportedId}
                                type="secondary"
                            >
                                {t('general.OK')}
                            </Button>
                            <Button
                                type="secondary"
                                onClick={() => {
                                    formik.setTouched({});
                                    setOpenChangeReportedId(null);
                                }}
                            >
                                {t('general.cancel')}
                            </Button>
                        </div>
                    </form>
                </Modal>
            )}
            {openSameClassId && (
                <UnmatchedStudentsViewModal
                    searchBy="classId"
                    open={openSameClassId}
                    setOpen={setOpenSameClassId}
                />
            )}
            {openSimilarName && (
                <UnmatchedStudentsViewModal
                    searchBy="name"
                    open={openSimilarName}
                    setOpen={setOpenSimilarName}
                />
            )}
            {openSuccessModal && (
                <SuccessModal
                    open={openSuccessModal}
                    text={t('general.checkDuplicatesPageForNewDuplicates')}
                    onClose={() => {
                        setOpenSuccessModal(null);
                    }}
                />
            )}
        </WhiteBox>
    );
};
export default UnmatchedStudents;
