export enum Steps {
    SelectMethod,
    SelectUnits,
    CreateUnitTest,
    TestName,
    Review,
}

export enum Method {
    TestByAim = 'Test by aim',
    TestByUnit = 'Test By unit',
}

export enum QuestionType {
    G = 'Grammar',
    V = 'Vocabulary',
}
