import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getStudents, putStudents } from '../../students/actions';
import { putApiRequestError } from '../../error/actions';
import {
    blockStudent,
    getDuplicatedStudents,
    mergeStudents,
    putDuplicatedStudents,
} from '../actions';

export function* blockStudentSaga({ payload }: ReturnType<typeof blockStudent>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.post(), `/staff/users/block`, payload);
        yield put(getDuplicatedStudents({}));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
