import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LoadingState } from './reducer';

export const getLoadingSelector = (state: RootState): LoadingState => state.loading;

export const selectLoading = createSelector(
    createSelector(getLoadingSelector, (loading) => loading),
    (loading) => {
        return !!loading.loading.length;
    },
);
