import { Props } from '../types';

const SwitchIosOffSvg = ({ fill = '#D4D4D4' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 40 20" fill="none">
        <rect width="40" height="20" rx="10" transform="matrix(1 0 0 -1 0 20)" fill={fill} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z"
            fill="#FAFAFA"
        />
    </svg>
);

export default SwitchIosOffSvg;
