import './styles.scss';
import classNames from 'classnames';
import ArrowLongThinIcon from '../Icons/ArrowLongThinIcon';
import { SortableHeaderProps } from './types';

const SortableHeader = ({ addClass, direction, onClick, children }: SortableHeaderProps) => {
    const getArrow = () => {
        if (direction) {
            return direction === 'asc' ? <ArrowLongThinIcon /> : <ArrowLongThinIcon />;
        }
        return <></>;
    };
    return (
        <span className={classNames('SortableHeader', addClass)}>
            <span onClick={onClick} data-testid="sortableHeader">
                <span className="SortableHeader-children">{children}</span>
                <span
                    className={classNames('SortableHeader-arrow', direction)}
                    data-testid="sortableHeader-arrow"
                >
                    {getArrow()}
                </span>
            </span>
        </span>
    );
};
export default SortableHeader;
