import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { putStudent, updateStudent } from '../actions';

export function* updateStudentSaga({ payload }: ReturnType<typeof updateStudent>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.put(), `/staff/students/${payload.studentId}`, payload.data);
        yield put(putStudent(data));
        if (payload.cb) {
            payload.cb(data);
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
