export const TO_DO_LIST_REF = {
    references: { ereception: 'ereception', infopage: 'infopage', test: 'test' },
    text: 'text',
    url: 'url',
    isText: (valueToCheck: string) => {
        return TO_DO_LIST_REF.text === valueToCheck;
    },
    isUrl: (valueToCheck: string) => {
        return TO_DO_LIST_REF.url === valueToCheck;
    },
    isReference: (valueToCheck: string) => {
        return Object.values(TO_DO_LIST_REF.references).includes(valueToCheck);
    },
};

export const TO_DO_LIST_TYPE = (t: any) => [
    {
        name: t('toDoList.questionnaireRequest'),
        value: TO_DO_LIST_REF.references.ereception,
    },
    {
        name: t('toDoList.informationPage'),
        value: TO_DO_LIST_REF.references.infopage,
    },
    { name: t('toDoList.test'), value: TO_DO_LIST_REF.references.test },
    { name: t('toDoList.URL'), value: TO_DO_LIST_REF.url },
    { name: t('toDoList.text'), value: TO_DO_LIST_REF.text },
];

export const TO_DO_LIST_SESSION = (t: any) => [
    { name: t('toDoList.preSessional'), value: 'pre-sessional' },
    { name: t('toDoList.inSchool'), value: 'in-school' },
    { name: t('toDoList.postSessional'), value: 'post-sessional' },
];
