import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getBooks } from 'src/redux/weeklyPlan/actions';
import { createClass, putClass } from '../actions';

export function* createNewClassSaga({ payload }: ReturnType<typeof createClass>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.post(), `/staff/classes`, {
            name: payload.name,
            level: payload.level,
            room: payload.room,
            type: payload.type,
            teacher: payload.teacher,
        });
        yield put(putClass(data));
        yield put(
            getBooks({
                classId: data.id,
            }),
        );

        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
