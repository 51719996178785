import { createAction } from '@reduxjs/toolkit';

export const PUT_AIMS_MATERIALS = 'PUT_AIMS_MATERIALS';
export const GET_AIMS_MATERIALS = 'GET_AIMS_MATERIALS';

export const PUT_AIMS = 'PUT_AIMS';
export const GET_AIMS = 'GET_AIMS';

export const PUT_QUETIONS = 'PUT_QUETIONS';
export const GET_QUESTIONS = 'GET_QUESTIONS';

export const PUT_BOOK = 'PUT_BOOK';
export const GET_BOOK = 'GET_BOOK';

export const SAVE_UNIT_TEST = 'SAVE_UNIT_TEST';

export const saveUnitTest = createAction<any>(SAVE_UNIT_TEST);

export const putAimsMaterials = createAction<any>(PUT_AIMS_MATERIALS);
export const getAimsMaterials = createAction<any>(GET_AIMS_MATERIALS);

export const putAims = createAction<any>(PUT_AIMS);
export const getAims = createAction<any>(GET_AIMS);

export const putBook = createAction<any>(PUT_BOOK);
export const getBook = createAction<any>(GET_BOOK);

export const putQuestions = createAction<any>(PUT_QUETIONS);
export const getQuestions = createAction<any>(GET_QUESTIONS);
