import { createReducer } from '@reduxjs/toolkit';
import { PUT_SETTINGS_VALIDATION_ERRORS } from './actions';

export type SettingsState = {
    validationErrors: any;
};

const initialState = {
    validationErrors: [],
};

export const settingsReducer = createReducer<SettingsState>(initialState, {
    [PUT_SETTINGS_VALIDATION_ERRORS]: (state, action) => {
        state.validationErrors = action.payload;
    },
});
