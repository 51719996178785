import classNames from 'classnames';
import Sidebar from '../Sidebar';
import { BodyProps } from './types';
import './styles.scss';

const Body = ({ children, showSidebar }: BodyProps) => {
    return (
        <div
            data-testid="body"
            className={classNames('body', {
                'body-showSidebar': showSidebar,
            })}
        >
            {showSidebar && <Sidebar />}
            <div className="body-content">{children}</div>
        </div>
    );
};

export default Body;
