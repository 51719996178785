import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getInvites, putInvites } from '../actions';

export function* getInvitesSaga({ payload }: ReturnType<typeof getInvites>): SagaIterator {
    try {
        yield put(startLoading());
        let data = null;
        if (payload.type === 'teacher') {
            data = yield call(API.get(), `/staff/users/invites`);
        } else {
            data = yield call(API.get(), `/staff/classes/${payload.classId}/invites`);
        }
        yield put(putInvites(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
