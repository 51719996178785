import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useOutsideClick } from 'src/hooks/outsideClick';
import { putSearchValue } from 'src/redux/search/actions';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../Icons/Search';
import { SearchProps } from './types';
import CloseButtonSvg from '../Icons/CloseButtonSvg';
import SearchIconNew from '../Icons/SearchIconNew';
import './styles.scss';

const Search = ({
    alwaysWide,
    onChange,
    onPushEnter,
    fullWidth,
    onClearButton,
    defaultValue = '',
    newDesign = false,
}: SearchProps) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    useOutsideClick(wrapperRef, () => setActive(false));

    const debounceFn = debounce((e) => {
        const curValue = e.target.value;
        setValue(curValue);
        dispatch(putSearchValue({ searchValue: curValue }));
        if (onChange) {
            onChange(curValue);
        }
        if (onPushEnter && curValue === '') {
            onPushEnter();
        }
    }, 100);

    const setEmptyValue = () => {
        setValue('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        dispatch(putSearchValue({ searchValue: '' }));
        if (onChange) {
            onChange('');
        }
    };

    useEffect(() => {
        return () => {
            dispatch(putSearchValue({ searchValue: '' }));
        };
    }, []);

    useEffect(() => {
        if (defaultValue && onChange) {
            onChange(defaultValue);
            dispatch(putSearchValue({ searchValue: defaultValue }));
        }
    }, [defaultValue]);

    return (
        <div
            className={classNames('search', {
                'search-mobile-wide': active || alwaysWide,
                'search-mobile-small': !active && !alwaysWide,
                'search-full-width': fullWidth,
                'search-new': newDesign,
            })}
            onClick={() => setActive(true)}
            ref={wrapperRef}
        >
            <div onClick={() => inputRef.current?.focus()}>
                {newDesign ? <SearchIconNew /> : <SearchIcon />}
            </div>
            <input
                ref={inputRef}
                className="search-input"
                data-testid="search-input"
                placeholder={t('general.search')}
                onKeyDown={(e) => {
                    if (onPushEnter && e.key === 'Enter') {
                        onPushEnter();
                    }
                }}
                defaultValue={value}
                onChange={(e) => {
                    debounceFn(e);
                }}
            />
            {onClearButton && value && (
                <div
                    className="search-clear-button cursor-pointer"
                    data-testid="search-clear-button"
                    onClick={() => {
                        setEmptyValue();
                        inputRef.current?.focus();
                        onClearButton();
                    }}
                >
                    <CloseButtonSvg />
                </div>
            )}
        </div>
    );
};

export default Search;
