import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import {
    GET_PROGRESS_TESTS,
    GET_STAFF_EMAILS_LOG,
    GET_STAFF_REPORTS,
    SEND_TEST_EMAIL,
} from '../actions';
import { getStaffReportsSaga } from './getStaffReportsSaga';
import { getStaffEmailsLogSaga } from './getStaffEmailsLogSaga';
import { sendTestEmailSaga } from './sendTestEmailSaga';
import { getProgressTestsSaga } from './getProgressTestsSaga';

export function* staffWatcher(): SagaIterator {
    yield takeLatest(GET_STAFF_REPORTS, getStaffReportsSaga);
    yield takeLatest(GET_STAFF_EMAILS_LOG, getStaffEmailsLogSaga);
    yield takeLatest(SEND_TEST_EMAIL, sendTestEmailSaga);
    yield takeLatest(GET_PROGRESS_TESTS, getProgressTestsSaga);
}
