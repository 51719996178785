const Avatar = () => (
    <svg
        enableBackground="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m512 256c0-141-115-256-256-256s-256 115-256 256c0 77.7 35.3 147.6 90.1 194.6.7.7 1.3 1.3 2.6 1.9 44.4 37.2 101.2 59.4 163.9 59.4 61.4 0 118.2-22.2 162.6-58.1 2.6-.7 4.6-2.6 5.9-4.6 53-47.6 86.9-116.1 86.9-193.2zm-488.5 0c0-128 104.5-232.5 232.5-232.5s232.5 104.5 232.5 232.5c0 62.7-24.8 119.5-65.3 161.3-6.5-30.7-26.1-83-86.2-116.9 13.7-17 21.5-39.2 21.5-62.7 0-56.2-45.7-101.9-101.9-101.9s-101.9 45.7-101.9 101.9c0 23.5 8.5 45.1 21.5 62.7-60.7 33.9-80.2 86.2-86.1 116.9-41.1-41.1-66.6-98.6-66.6-161.3zm154.2-17.6c0-43.1 35.3-78.4 78.4-78.4s78.4 35.3 78.4 78.4-35.3 78.4-78.4 78.4-78.4-35.3-78.4-78.4zm-66.7 199.2c1.3-18.9 11.7-83 82.3-118.9 17.7 13.7 39.2 21.5 62.7 21.5s45.7-8.5 63.4-22.2c69.9 35.9 81 98.6 83 118.9-39.8 32-90.8 51.6-145.6 51.6-55.6 0-105.9-18.9-145.8-50.9z"
            fill="#fff"
        />
    </svg>
);

export default Avatar;
