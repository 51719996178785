import { createReducer } from '@reduxjs/toolkit';
import { PUT_ALL_TO_DO, PUT_TO_DO_REFERENCES } from './actions';
import { PutAllToDoAP, PutToDoReferencesAP } from './types';

export type ToDoReducerState = {
    toDo: PutAllToDoAP;
    toDoReferences: PutToDoReferencesAP;
};

export const toDoInitialState = {
    toDo: [],
    toDoReferences: [],
};

export const toDoReducer = createReducer<ToDoReducerState>(toDoInitialState, {
    [PUT_ALL_TO_DO]: (state, action) => {
        state.toDo = action.payload;
    },
    [PUT_TO_DO_REFERENCES]: (state, action) => {
        state.toDoReferences = action.payload;
    },
});
