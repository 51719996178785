import './styles.scss';
import { SupportEmailProps } from './types';

const SupportEmail = ({ style, title }: SupportEmailProps) => {
    return (
        <a
            style={style}
            href="mailto: support@guidedelearning.com"
            className="support-email"
            data-testid="supportEmail"
        >
            {title ?? 'support@guidedelearning.com'}
        </a>
    );
};

export default SupportEmail;
