import { createReducer } from '@reduxjs/toolkit';
import { PUT_PROGRESS } from './actions';

export type ProgressTestType = {
    report: any;
    name: string;
    id: string;
    level: string;
};
export type ProgressState = {
    progress: {
        tests: ProgressTestType[];
    };
};

const initialState = {
    progress: {
        tests: [
            {
                report: '',
                name: '',
                id: '',
                level: '',
            },
        ],
    },
};

export const progressReducer = createReducer<ProgressState>(initialState, {
    [PUT_PROGRESS]: (state: any, action: any) => {
        state.progress = action.payload;
    },
});
