import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { getQuestions, putQuestions } from '../actions';

export function* getQuestionsSaga({ payload }: ReturnType<typeof getQuestions>): SagaIterator {
    try {
        const { classId, units, isAim } = payload;
        yield put(startLoading());
        const data = yield all(
            units.map((id: number) =>
                call(
                    API.get(),
                    isAim
                        ? `/staff/tests/${classId}/aims/${id}/questions`
                        : `/staff/tests/${classId}/units/${id}/questions`,
                ),
            ),
        );
        const result = data
            .filter(({ questions }: { questions: any }) => questions.length)
            .map(({ questions }: { questions: any }) => questions)
            .flat();
        yield put(putQuestions(result));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
