import { createAction } from '@reduxjs/toolkit';
import { ClassesData } from 'src/pages/Classes/types';
import {
    PutClassProps,
    SaveClassSagaProps,
    ClassPreviewProps,
    DeleteClassProps,
    GetClassesByGidAP,
    GetClassesProps,
    GetInvitesSagaProps,
    PutClassNameProps,
    PutInvitesDataProps,
} from './types';

export const GET_CLASSES = 'GET_CLASSES';
export const GET_CLASSES_BY_PARAMS = 'GET_CLASSES_BY_PARAMS';
export const PUT_CLASSES = 'PUT_CLASSES';
export const GET_CLASS = 'GET_CLASS';
export const SAVE_CLASS = 'SAVE_CLASS';
export const PUT_CLASS = 'PUT_CLASS';
export const DELETE_CLASS = 'DELETE_CLASS';
export const CREATE_CLASS = 'CREATE_CLASS';
export const CLASS_PREVIEW = 'CLASS_PREVIEW';
export const GET_INVITES = 'GET_INVITES';
export const PUT_INVITES = 'PUT_INVITES';

export const getClasses = createAction<GetClassesProps>(GET_CLASSES);
export const getClassesByParams = createAction<GetClassesByGidAP>(GET_CLASSES_BY_PARAMS);
export const classPreview = createAction<ClassPreviewProps>(CLASS_PREVIEW);
export const putClasses = createAction<ClassesData>(PUT_CLASSES);
export const getClass = createAction<{ classId: string | number; cb?: any }>(GET_CLASS);
export const saveClass = createAction<SaveClassSagaProps>(SAVE_CLASS);
export const putClass = createAction<PutClassProps>(PUT_CLASS);
export const deleteClass = createAction<DeleteClassProps>(DELETE_CLASS);
export const createClass = createAction<PutClassNameProps>(CREATE_CLASS);
export const getInvites = createAction<GetInvitesSagaProps>(GET_INVITES);
export const putInvites = createAction<PutInvitesDataProps>(PUT_INVITES);
