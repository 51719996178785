import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { getDuplicatedStudents, putDuplicatedStudents } from '../actions';

export function* getDuplicatedStudentsSaga({
    payload,
}: ReturnType<typeof getDuplicatedStudents>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/users/duplicates`);
        yield put(putDuplicatedStudents(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
