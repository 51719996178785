import classNames from 'classnames';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import moment from 'moment';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import noData1 from 'src/assets/images/noData1.gif';
import CustomCalendar from 'src/components/CustomCalendar';
import SortAlt from 'src/components/Icons/SortAlt';
import SortableHeader from 'src/components/SortableHeader';
import Table from 'src/components/Table';
import WhiteBox from 'src/components/WhiteBox';
import { useOutsideClick } from 'src/hooks/outsideClick';
import useSortable from 'src/hooks/useSortable';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { getCourseReport } from 'src/redux/reports/actions';
import { selectCourseReport } from 'src/redux/reports/selectors';
import { CourseReport, CourseReportHomeworkReturnProps } from 'src/redux/reports/types';
import { useTranslation } from 'react-i18next';
import { CourseSummaryProps } from '../../types';
import './styles.scss';

const COLORS = ['#197b30', '#f26522', '#ac145a', '#5f259f', '#0B80A4'];
const FONT_FAMILY = 'fira';
const FONT_HEIGHT_KOEF = 1.16;
const FONT_WIDTH_KOEF = 0.6;
const FONT_MULTIPLY = 1;
const FONT_MULTIPLY_MOB = 1;
const CALENDAR_POSITION = {
    BOTTOM: {
        left: '50%',
        top: '-345px',
        transform: 'translate(-50%)',
    },
    TOP: {
        left: '50%',
        top: '25px',
        transform: 'translate(-50%)',
    },
};

const mobileFonts = {
    96: 48,
    48: 24,
    24: 12,
    16: 14,
    14: 12,
    12: 11,
    10: 10,
};

const CourseSummary = ({ from, to, search }: CourseSummaryProps) => {
    const dispatch = useDispatch();
    const params = useParams();
    const { t } = useTranslation();
    const courseReport = useSelector(selectCourseReport);
    const { width: windowWidth, isDesktop } = useWindowSize(true);
    const sortable = useSortable({
        itemsToSort: courseReport.homework || [],
        oneDirection: !isDesktop,
    });
    const [activeTag, setActiveTag] = useState('');
    const [activeButton, setActiveButton] = useState('');
    const wrapperRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [fontloaded, setFontLoaded] = useState(document.fonts.status === 'loaded');
    const [calendarPosition, setCalendarPosition] = useState(CALENDAR_POSITION.BOTTOM as any);

    const [openSort, setOpenSort] = useState(false);
    const sortRef = useRef(null);
    useOutsideClick(sortRef, () => setOpenSort(false));
    const [date, setDate] = useState(
        {} as {
            [key: string]: {
                week: number;
                year: number;
                dayNum: number;
            };
        },
    );
    const getSvgWidth = () => {
        if (windowWidth <= 991) {
            return windowWidth;
        }

        if (windowWidth > 1600) {
            return windowWidth - 500;
        }

        return windowWidth - 150;
    };

    const calculateW = (weight: number) => {
        let w = weight;

        if (windowWidth < 991) {
            w = mobileFonts[weight as unknown as keyof typeof mobileFonts];
        }
        return w;
    };

    const setupCloud = ({ tags, isInit }: Pick<CourseReport, 'tags'> & { isInit: boolean }) => {
        const maxSize = d3.max(tags, (d) => {
            return d.weight;
        });
        const minSize = d3.min(tags, (d) => {
            return d.weight;
        });
        let padding = 1.5;

        if (minSize === maxSize) {
            padding = 5;
        } else if (tags.length < 50) {
            padding = 3.5;
        } else if (tags.length <= 100) {
            padding = 2;
        } else if (tags.length > 300) {
            padding = 1.5;
        }

        const width = getSvgWidth();
        const fontMultiply = isDesktop ? FONT_MULTIPLY : FONT_MULTIPLY_MOB;

        const { height } = tags.reduce(
            (prev, next) => {
                const labelWidth =
                    next.label.length * calculateW(next.weight) * fontMultiply * FONT_WIDTH_KOEF;
                const labelHeight = calculateW(next.weight) * fontMultiply * FONT_HEIGHT_KOEF;
                if (labelWidth + prev.sum >= width) {
                    return {
                        sum: labelWidth,
                        height: prev.height + Math.max(prev.rowHeight, labelHeight),
                        rowHeight: 0,
                    };
                }

                return {
                    height: prev.height,
                    sum: prev.sum + labelWidth,
                    rowHeight: Math.max(prev.rowHeight, labelHeight),
                };
            },
            { height: 50, sum: 0, rowHeight: 0 },
        );
        const svg = d3
            .select('#my-svg')
            .attr('id', 'word-cloud')
            .attr('viewBox', [0, 0, width, height])
            .attr('width', width)
            .attr('height', height)
            .style('font-family', FONT_FAMILY)
            .attr('font-family', FONT_FAMILY)
            .attr('font', FONT_FAMILY)
            .attr('alignment-baseline', 'central')
            .attr('preserveAspectRatio', 'xMinYMid meet')
            .attr('text-anchor', 'start');

        const cloudWord = cloud()
            .size([width, height])
            .words(
                tags.map((tag) =>
                    Object.create({
                        text: tag.label,
                        value: calculateW(tag.weight) * fontMultiply,
                    }),
                ),
            )
            .padding(isDesktop ? padding : 1)
            .rotate(() => 0)
            .spiral('archimedean')
            .font(FONT_FAMILY)
            .fontSize((d) => (d as { value: number }).value)
            .on('word', ({ size, x, y, rotate, text }) => {
                function handleMouseOver(this: any) {
                    d3.select(this)
                        .classed('word-hovered', true)
                        .transition(`mouseover-${text}`)
                        .duration(300)
                        .ease(d3.easeLinear)
                        .attr('font-size', size! + 2)
                        .attr('font-weight', 'bold');
                }

                function handleMouseOut(this: any) {
                    d3.select(this)
                        .classed('word-hovered', false)
                        .interrupt(`mouseover-${text}`)
                        .attr('font-size', size as number)
                        .attr('font-weight', 'normal');
                }

                function handleClick(this: any) {
                    const e = d3.select(this);
                    // eslint-disable-next-line react/no-this-in-sfc
                    const parentChildren = Array.from(this.parentElement.children);
                    if (
                        parentChildren.filter((el: any) =>
                            el.className.baseVal.includes('word-selected'),
                        ).length === 1 &&
                        e.classed('word-selected')
                    ) {
                        parentChildren.forEach((el: any) => {
                            el.className.baseVal = `${el.className.baseVal} word-selected`;
                        });
                        setActiveTag('');
                        return;
                    }
                    parentChildren.forEach((el: any) => {
                        el.className.baseVal = el.className.baseVal.replace('word-selected', '');
                    });
                    e.classed('word-selected', true);
                    setActiveTag(e.text());
                }

                const getSelectedClass = () => {
                    if (activeTag && !isInit) {
                        return activeTag === text ? 'word-selected' : '';
                    }
                    return 'word-selected';
                };

                svg.append('text')
                    .attr('font-size', size || 12)
                    .attr('transform', `translate(${x},${y}) rotate(${rotate})`)
                    .attr('text-anchor', 'middle')
                    .attr('class', getSelectedClass())
                    .attr('cursor', 'pointer')
                    .attr('font-family', FONT_FAMILY)
                    .style('font-family', FONT_FAMILY)
                    .text(text || '')
                    .style('fill', () => {
                        return COLORS[Math.floor(Math.random() * COLORS.length)];
                    })
                    .classed('click-only-text', true)
                    .classed('word-default', true)
                    .on('mouseover', handleMouseOver)
                    .on('mouseout', handleMouseOut)
                    .on('click', handleClick);
            });

        cloudWord.start();
    };

    useOutsideClick({ current: wrapperRef.current[activeButton] }, () => {
        setActiveButton('');
        setCalendarPosition(CALENDAR_POSITION.BOTTOM);
    });
    document.fonts.onloadingdone = () => {
        setFontLoaded(true);
    };

    useLayoutEffect(() => {
        setActiveTag('');
        if (windowWidth && fontloaded) {
            dispatch(
                getCourseReport({
                    classId: params.id as string,
                    from,
                    to,
                    cb: ({ tags }) => setupCloud({ tags, isInit: true }),
                }),
            );
        }
    }, [from, to, windowWidth, fontloaded]);

    const getCalendarPositionStyleObj = (ref: HTMLDivElement | null) => {
        const whiteBox = document.querySelector('.whiteBox');
        if (!whiteBox || !ref) return CALENDAR_POSITION.BOTTOM;
        const rect = ref.getBoundingClientRect();
        if (!activeButton || rect.bottom === 0) return CALENDAR_POSITION.BOTTOM;
        const ADDITIONAL_CALENDAR_HEIGHT = 60;
        return whiteBox.getBoundingClientRect().bottom >=
            rect.bottom + rect.height + ADDITIONAL_CALENDAR_HEIGHT
            ? CALENDAR_POSITION.TOP
            : CALENDAR_POSITION.BOTTOM;
    };

    useLayoutEffect(() => {
        if (activeButton !== '') {
            setCalendarPosition(getCalendarPositionStyleObj(wrapperRef.current[activeButton]));
        }
    }, [activeButton]);

    const openSearchForHomework = (data: CourseReportHomeworkReturnProps) => {
        window.open(
            `/weekly-plan/${params.id}/${date[data.label]?.year || moment(new Date()).isoWeek()}/${
                date[data.label]?.week || moment(new Date()).year()
            }/${
                date[data.label]?.dayNum || moment(new Date()).day()
            }/assigment?searchTerm=${data.searchTerm.replace(/\s/g, '_')}`,
            '_blank',
        );
    };

    const calendar = (data: CourseReportHomeworkReturnProps) => {
        return (
            <>
                <div
                    ref={(ref) => {
                        wrapperRef.current[`${data.label}`] = ref;
                    }}
                    className={classNames('courseSummary-search-calendar', {
                        'courseSummary-search-calendar--active': activeButton === `${data.label}`,
                    })}
                    style={calendarPosition}
                >
                    <CustomCalendar
                        type="short"
                        minDate={null}
                        navigationLabelFormat="MMM. YYYY"
                        onChangeReturnType="date"
                        onChange={(day: Date) => {
                            const week = moment(new Date(day)).isoWeek();
                            const year = moment(new Date(day)).year();
                            const dayNum = moment(new Date(day)).day();
                            setDate({
                                ...date,
                                [data.label]: {
                                    week,
                                    year,
                                    dayNum,
                                },
                            });
                        }}
                    />

                    <div className="weeklyPlan-option-calendar-button">
                        <span
                            className="sc_hover_color_text_svg_bg"
                            onClick={() => openSearchForHomework(data)}
                        >
                            {t('reports.next')}
                        </span>
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className="courseSummary">
            <span className="courseSummary-title courseSummary-title-mob">
                {t('reports.classTopics')}
            </span>
            <WhiteBox
                styles={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    minHeight: 300,
                    justifyContent: 'center',
                    paddingTop: 20,
                    paddingBottom: 20,
                }}
            >
                <svg
                    style={{
                        zIndex: -1,
                        position: 'absolute',
                    }}
                >
                    <text fontFamily="fira" fontSize="30px">
                        {t('reports.fontLoading')}
                    </text>
                </svg>
                <svg
                    id="my-svg"
                    key={`${from}${to}`}
                    style={{ display: courseReport.tags.length ? 'inherit' : 'none' }}
                />
                {courseReport.tags.length ? (
                    <></>
                ) : (
                    <div className="courseSummary-noData">
                        <img src={noData1} alt="noData" />
                        <span>{t('reports.classCoverageAndPerformanceReport')}</span>
                    </div>
                )}
            </WhiteBox>
            <span className="courseSummary-title">{t('reports.testAndHomeworkTopics')}</span>
            <WhiteBox>
                <Table
                    header={
                        <>
                            <SortableHeader
                                addClass="courseSummary-area"
                                direction={sortable.getDirection('label')}
                                onClick={() =>
                                    sortable.updateSort('label', (item) =>
                                        item?.label.toLowerCase(),
                                    )
                                }
                            >
                                {t('reports.area')}
                            </SortableHeader>
                            <SortableHeader
                                addClass="courseSummary-test"
                                direction={sortable.getDirection('tests')}
                                onClick={() => sortable.updateSort('tests', (item) => item?.tests)}
                            >
                                {t('reports.testScore')}
                            </SortableHeader>
                            <SortableHeader
                                addClass="courseSummary-homework"
                                direction={sortable.getDirection('homework')}
                                onClick={() =>
                                    sortable.updateSort('homework', (item) => item?.homework)
                                }
                            >
                                {t('reports.homeworkScore')}
                            </SortableHeader>
                            <SortableHeader
                                addClass="courseSummary-overall"
                                direction={sortable.getDirection('overall')}
                                onClick={() =>
                                    sortable.updateSort('overall', (item) => item?.overall)
                                }
                            >
                                {t('reports.overallScore')}
                            </SortableHeader>
                        </>
                    }
                    rows={sortable.items
                        .filter((item) => {
                            if (!activeTag) {
                                return true;
                            }
                            return activeTag === item.label;
                        })
                        .filter((item) => {
                            return item.label.toLocaleLowerCase().includes(search);
                        })
                        .map((data) => (
                            <>
                                <span className="courseSummary-area">{data.label}</span>
                                <span
                                    className={classNames(
                                        'courseSummary-test courseSummary-score',
                                        {
                                            'courseSummary-score--active': data.tests >= 70,
                                        },
                                    )}
                                >
                                    {data.tests || t('reports.notDone')}
                                </span>
                                <span
                                    className={classNames(
                                        'courseSummary-homework courseSummary-score',
                                        {
                                            'courseSummary-score--active': data.homework >= 70,
                                        },
                                    )}
                                >
                                    {data.homework || t('reports.notDone')}
                                </span>
                                <div className="courseSummary-overall">
                                    <span>{data.overall || '-'}</span>
                                    {data.searchTerm && (
                                        <div className="courseSummary-search-body">
                                            <span
                                                className="courseSummary-search"
                                                onClick={() => {
                                                    const index = `${data.label}`;
                                                    if (activeButton !== index) {
                                                        setActiveButton(index);
                                                    }
                                                }}
                                            >
                                                {t('reports.searchForHomework')}
                                            </span>
                                            {calendar(data)}
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}
                    headerMobile={
                        <div className="courseSummary-mobile-head" ref={sortRef}>
                            <span>{t('reports.area')}</span>
                            <div
                                className="courseSummary-mobile-head-alt"
                                onClick={() => setOpenSort(!openSort)}
                            >
                                <SortAlt />
                                <span>{t('reports.sortBy')}</span>
                            </div>

                            <div
                                className={classNames('courseSummary-mobile-sortBy', {
                                    'courseSummary-mobile-sortBy--active': openSort,
                                })}
                            >
                                <div className="courseSummary-mobile-sortBy-title">
                                    <SortAlt />
                                    <span>{t('reports.sortBy')}</span>
                                </div>
                                <span
                                    className={classNames('courseSummary-mobile-sortBy-item', {
                                        'courseSummary-mobile-sortBy-item--active':
                                            sortable.getDirection('label'),
                                    })}
                                    onClick={() =>
                                        sortable.updateSort('label', (item) => item?.label)
                                    }
                                >
                                    {t('reports.area')}
                                </span>
                                <span
                                    className={classNames('courseSummary-mobile-sortBy-item', {
                                        'courseSummary-mobile-sortBy-item--active':
                                            sortable.getDirection('tests'),
                                    })}
                                    onClick={() =>
                                        sortable.updateSort('tests', (item) => item?.tests)
                                    }
                                >
                                    {t('reports.testScoreLowerCase')}
                                </span>
                                <span
                                    className={classNames('courseSummary-mobile-sortBy-item', {
                                        'courseSummary-mobile-sortBy-item--active':
                                            sortable.getDirection('homework'),
                                    })}
                                    onClick={() =>
                                        sortable.updateSort('homework', (item) => item?.homework)
                                    }
                                >
                                    {t('reports.homeworkScoreLowerCase')}
                                </span>
                                <span
                                    className={classNames('courseSummary-mobile-sortBy-item', {
                                        'courseSummary-mobile-sortBy-item--active':
                                            sortable.getDirection('overall'),
                                    })}
                                    onClick={() =>
                                        sortable.updateSort('overall', (item) => item?.overall)
                                    }
                                >
                                    {t('reports.overallScoreLowerCase')}
                                </span>

                                <div className="courseSummary-mobile-sortBy-button">
                                    <span onClick={() => setOpenSort(false)}>
                                        {t('reports.done')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    arrowLeft
                    rowsMobile={sortable.items
                        .filter((item) => {
                            if (!activeTag) {
                                return true;
                            }
                            return activeTag === item.label;
                        })
                        .filter((item) => {
                            return item.label.toLocaleLowerCase().includes(search);
                        })
                        .map((data, idx) => {
                            return {
                                head: (
                                    <div
                                        key={`${data.label}_${idx}`}
                                        className={classNames(
                                            'assessments-name-mobile automatedTests-active',
                                        )}
                                    >
                                        {data.label}
                                    </div>
                                ),
                                content: (
                                    <>
                                        <div className="courseSummary-mobile-row">
                                            <div className="courseSummary-mobile-row-title">
                                                <b>{t('reports.testScore')}</b>
                                            </div>
                                            <span
                                                className={classNames(
                                                    'courseSummary-test courseSummary-score',
                                                    {
                                                        'courseSummary-score--active':
                                                            data.tests >= 70,
                                                    },
                                                )}
                                            >
                                                {data.tests || t('reports.notDone')}
                                            </span>
                                        </div>
                                        <div className="courseSummary-mobile-row">
                                            <div className="courseSummary-mobile-row-title">
                                                <b>{t('reports.homeworkScore')}</b>
                                            </div>
                                            <span
                                                className={classNames(
                                                    'courseSummary-test courseSummary-score',
                                                    {
                                                        'courseSummary-score--active':
                                                            data.tests >= 70,
                                                    },
                                                )}
                                            >
                                                {data.homework || t('reports.notDone')}
                                            </span>
                                        </div>
                                        <div className="courseSummary-mobile-row">
                                            <div className="courseSummary-mobile-row-title">
                                                <b>{t('reports.overallScore')}</b>
                                            </div>
                                            <span>{data.overall || '-'}</span>
                                        </div>
                                        {data.searchTerm && (
                                            <div className="courseSummary-mobile-row">
                                                <div className="courseSummary-search-body">
                                                    <span
                                                        onClick={() => {
                                                            const index = `${data.label}`;
                                                            if (activeButton !== index) {
                                                                setActiveButton(index);
                                                            }
                                                        }}
                                                        className="courseSummary-search"
                                                    >
                                                        <b>{t('reports.searchForHomework')}</b>
                                                    </span>
                                                    {calendar(data)}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ),
                            };
                        })}
                />
            </WhiteBox>
        </div>
    );
};

export default CourseSummary;
