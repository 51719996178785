import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import CustomLink from 'src/components/CustomLink';
import { selectBreadcrumbs } from 'src/redux/layout/selectors';
import { useTranslation } from 'react-i18next';
import Home from '../Icons/Home';
import './styles.scss';

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const bread = useSelector(selectBreadcrumbs);
    const { t } = useTranslation();

    return (
        <div className="breadcrumbs breadcrumbs-mobile">
            <div className="breadcrumbs-items">
                <CustomLink
                    to="/"
                    className={classNames('breadcrumbs-item sc_hover_color_text_svg', {
                        'breadcrumbs-item--active': !bread.length,
                    })}
                    data-testid="breadcrumbs-home"
                >
                    <Home fill={!bread.length ? '#007FA3' : '#54585a'} />
                    <span>{t('breadcrumbs.home')}</span>
                </CustomLink>
                {bread.map((item, idx) => {
                    return (
                        <Fragment key={idx}>
                            <span className="breadcrumbs-divider">/</span>
                            {item.link ? (
                                <CustomLink
                                    to={item.link || '/'}
                                    className={classNames(
                                        'breadcrumbs-item sc_hover_color_text_svg',
                                        {
                                            'breadcrumbs-item--active': idx === bread.length - 1,
                                        },
                                    )}
                                >
                                    {item.name}
                                </CustomLink>
                            ) : (
                                <div
                                    className={classNames(
                                        'breadcrumbs-item sc_hover_color_text_svg',
                                        {
                                            'breadcrumbs-item--active': idx === bread.length - 1,
                                        },
                                    )}
                                >
                                    {item.name}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default Breadcrumbs;
