import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getBooks, putBooks } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* getBooksSaga({ payload }: ReturnType<typeof getBooks>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/classes/${payload.classId}/books`);
        yield put(putBooks(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
