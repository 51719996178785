import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { getAims, putAims } from '../actions';

export function* getAimsSaga({ payload }: ReturnType<typeof getAims>): SagaIterator {
    try {
        const { classId } = payload;
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/course-planner/${classId}/aims?subCats[]=Grammar&subCats[]=Vocabulary`,
        );
        yield put(putAims(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
