import { Props } from '../types';

const ArrowInSquareIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g id="Artwork_186" data-name="Artwork 186" transform="translate(7.5 7.5)">
            <g id="Group_7166" data-name="Group 7166" transform="translate(-7.5 -7.5)">
                <path
                    id="Path_27086"
                    data-name="Path 27086"
                    d="M222.669.443A.726.726,0,0,0,222,0h-3.92a.726.726,0,0,0-.726.726h0a.726.726,0,0,0,.726.721h2.172l-5.784,5.786h0a.724.724,0,1,0,1.023,1.026l5.786-5.786V4.647a.726.726,0,0,0,.319.6.722.722,0,0,0,.687.067.726.726,0,0,0,.443-.669V.726A.725.725,0,0,0,222.669.443Z"
                    transform="translate(229.256 8.473) rotate(180)"
                    fill={fill}
                />
                <path
                    id="Path_27087"
                    data-name="Path 27087"
                    d="M14.676,7.146a.724.724,0,0,0-1.128.6v3.977a1.811,1.811,0,0,1-.144.709,1.839,1.839,0,0,1-.662.8,1.812,1.812,0,0,1-1.019.312H3.273a1.811,1.811,0,0,1-.709-.144,1.839,1.839,0,0,1-.8-.662,1.811,1.811,0,0,1-.312-1.019V3.276a1.811,1.811,0,0,1,.144-.709,1.839,1.839,0,0,1,.662-.8,1.812,1.812,0,0,1,1.019-.312H7.218A.726.726,0,0,0,7.884.443.729.729,0,0,0,7.622.124.718.718,0,0,0,7.218,0H3.273A3.258,3.258,0,0,0,2,.258,3.291,3.291,0,0,0,.559,1.445,3.257,3.257,0,0,0,0,3.273H0v8.45A3.279,3.279,0,0,0,3.273,15h8.45A3.276,3.276,0,0,0,15,11.727V7.747a.72.72,0,0,0-.321-.6Z"
                    transform="translate(0 0)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default ArrowInSquareIcon;
