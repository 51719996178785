import { MouseEvent } from 'react';
import classNames from 'classnames';
import ArrowThin from '../Icons/ArrowThin';
import './styles.scss';

const Arrow = ({
    direction,
    onClick,
    disabled = false,
    large = false,
    active = false,
    dataTestId = '',
}: {
    onClick: (e: MouseEvent) => void;
    direction: 'left' | 'right';
    disabled?: boolean;
    large?: boolean;
    active?: boolean;
    dataTestId?: string;
}) => {
    const handleClick = (e: MouseEvent) => {
        if (disabled) return;
        onClick(e);
    };
    return (
        <div
            data-testid={dataTestId || 'arrowIcon'}
            className={classNames(
                'arrow pr_hover_color_text_svg_bg',
                `arrow-direction__${direction}`,
                { 'pr_hover_color_text_svg_bg cursor-pointer': active, disabled, large },
            )}
            onClick={handleClick}
        >
            <ArrowThin />
        </div>
    );
};
export default Arrow;
