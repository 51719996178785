import classNames from 'classnames';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import './styles.scss';
import { InputPhoneProps } from './types';

const InputPhone = ({
    fullWidth,
    onChange,
    error,
    template = 1,
    ipCountryCode,
    defaultValue,
    errorAsBlock = false,
    onPhoneNumberBlur = () => {},
    onSelectFlag = () => {},
}: InputPhoneProps) => {
    return (
        <div className="inputPhone">
            <IntlTelInput
                defaultValue={defaultValue}
                defaultCountry={ipCountryCode}
                containerClassName={classNames(
                    'intl-tel-input inputPhone-intlTelInputContainer',
                    `template-${template}`,
                    {
                        fullWidth,
                    },
                )}
                onSelectFlag={(currentNumber, _selectedCountryData, fullNumber, isValid) => {
                    onSelectFlag(isValid, currentNumber, fullNumber);
                }}
                inputClassName={classNames(
                    'form-control inputPhone__field',
                    `template-${template}`,
                    {
                        invalid: error,
                        fullWidth,
                    },
                )}
                onPhoneNumberChange={(isValid, value, _selectedCountryData, fullNumber) => {
                    onChange(isValid, value, fullNumber);
                }}
                onPhoneNumberBlur={(isValid, value, _selectedCountryData, fullNumber) => {
                    onPhoneNumberBlur(isValid, value, fullNumber);
                }}
            />
            <div className={classNames('inputPhone-error', { errorAsBlock })} title={error}>
                {error}
            </div>
        </div>
    );
};

export default InputPhone;
