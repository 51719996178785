const SearchAlt = () => {
    return (
        <svg height="20" viewBox="0 0 18.333 20" width="18.333" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m13.667 5a.833.833 0 0 1 0 1.667h-6.667a.833.833 0 1 1 0-1.667zm6.422 14.756a.833.833 0 0 1 -1.178 0l-2.006-2.006a3.779 3.779 0 1 1 1.178-1.178l2.006 2.006a.833.833 0 0 1 0 1.178zm-5.172-3.089a2.083 2.083 0 1 0 -2.083-2.083 2.083 2.083 0 0 0 2.083 2.083zm-3.75 1.667h-5a2.5 2.5 0 0 1 -2.5-2.5v-11.667a2.5 2.5 0 0 1 2.5-2.5h10a.833.833 0 0 1 .833.833v6.667a.833.833 0 0 0 1.667 0v-6.667a2.5 2.5 0 0 0 -2.5-2.5h-10a4.172 4.172 0 0 0 -4.167 4.167v11.666a4.172 4.172 0 0 0 4.167 4.167h5a.833.833 0 1 0 0-1.667z"
                fill="#54585a"
                transform="translate(-2)"
            />
        </svg>
    );
};
export default SearchAlt;
