import { createSelector } from '@reduxjs/toolkit';
import { StudentsData } from 'src/pages/Students/types';
import { RootState } from '../store';
import {
    PutCreditsProps,
    PutStudentAuditAP,
    PutStudentEmailsAP,
    PutStudentMarkingAP,
    PutStudentProps,
    PutStudentResultsHistoryAP,
    PutStudentTestsAP,
    PutStudentTutorialsAP,
    PutStudentUpgradesAP,
    PutUpgradesProps,
} from './types';

export const getStudentsSelector = (state: RootState): StudentsData[] => state.students.students;

export const selectStudents = createSelector(
    createSelector(getStudentsSelector, (students) => students),
    (students) => students,
);

export const getStudentSelector = (state: RootState): PutStudentProps => {
    return state.student.student;
};

export const selectStudent = createSelector(
    createSelector(getStudentSelector, (student) => student),
    (student) => student,
);

export const getStudentResultsHistorySelector = (state: RootState): PutStudentResultsHistoryAP =>
    state.student.resultsHistory;

export const selectStudentResultsHistory = createSelector(
    createSelector(getStudentResultsHistorySelector, (history) => history),
    (history) => history,
);

export const getStudentAuditSelector = (state: RootState): PutStudentAuditAP => state.student.audit;

export const selectStudentAudit = createSelector(
    createSelector(getStudentAuditSelector, (history) => history),
    (history) => history,
);

export const getStudentEmailsSelector = (state: RootState): PutStudentEmailsAP =>
    state.student.emails;

export const selectStudentEmails = createSelector(
    createSelector(getStudentEmailsSelector, (emails) => emails),
    (emails) => emails,
);

export const getStudentTutorialsSelector = (state: RootState): PutStudentTutorialsAP =>
    state.student.tutorials;

export const selectStudentTutorials = createSelector(
    createSelector(getStudentTutorialsSelector, (tutorials) => tutorials),
    (tutorials) => tutorials,
);

export const getStudentTestsSelector = (state: RootState): PutStudentTestsAP => state.student.tests;

export const selectStudentTests = createSelector(
    createSelector(getStudentTestsSelector, (tests) => tests),
    (tests) => tests,
);

export const getStudentMarkingSelector = (state: RootState): PutStudentMarkingAP =>
    state.student.markings;

export const selectStudentMarkings = createSelector(
    getStudentMarkingSelector,
    (markings) => markings,
);

export const getStudentUpgradesSelector = (state: RootState): PutStudentUpgradesAP =>
    state.student.studentUpgrades;

export const selectStudentUpgrades = createSelector(
    getStudentUpgradesSelector,
    (upgrades) => upgrades,
);

export const getStudentCreditsSelector = (state: RootState): PutCreditsProps =>
    state.student.credits;

export const selectStudentCredits = createSelector(getStudentCreditsSelector, (credits) => credits);

export const getUpgradesSelector = (state: RootState): PutUpgradesProps => state.student.upgrades;

export const selectUpgrades = createSelector(getUpgradesSelector, (upgrades) => upgrades);
