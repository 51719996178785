import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API } from '../../../api';
import { putActivities, putUnits } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* getBooks({ classId, id }: any): SagaIterator {
    try {
        const books = yield call(API.get(), `/staff/course-planner/${classId}/books/${id}`);
        const activitiesData = books.units
            .map(({ pages }: any) => pages)
            .flat()
            .map(({ activities, page }: any) => {
                return activities.map((activity: any) => ({
                    ...activity,
                    page,
                }));
            })
            .flat();

        const units = books.units.map(({ name, pages, number, id: unitId }: any) => {
            return {
                name,
                number,
                id: unitId,
                pages: pages
                    .map((page: any) =>
                        page.activities.map((activity: any) => {
                            return {
                                exName: `Page ${page.page} ${
                                    activity.activity.includes('-') ? 'exercises' : 'exercise'
                                } ${activity.activity}`,
                                id: activity.id,
                            };
                        }),
                    )
                    .flat()
                    .filter((data: any) => data.id),
            };
        });
        yield put(putActivities(activitiesData));
        yield put(putUnits(units));
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
