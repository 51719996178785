const NoResultsIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102.584"
        height="105.857"
        viewBox="0 0 102.584 105.857"
    >
        <g id="no-results" transform="translate(-7.916)">
            <path
                id="Path_27397"
                data-name="Path 27397"
                d="M111.712,43.349v76.222a4.563,4.563,0,0,1-4.564,4.564l-85.432-2.029a4.563,4.563,0,0,1-4.564-4.564l-.464-74.309,47.838-3.05Z"
                transform="translate(-6.959 -31.875)"
                fill="#fece85"
            />
            <path
                id="Path_27398"
                data-name="Path 27398"
                d="M107.1,4.564v6.91H10.952l-.077-6.263c0-2.521,1.639-4.56,4.16-4.56L16.811,0h85.725A4.563,4.563,0,0,1,107.1,4.564Z"
                transform="translate(-2.347 0)"
                fill="#918291"
            />
            <path
                id="Path_27399"
                data-name="Path 27399"
                d="M16.647,124.172a2.183,2.183,0,0,1-2.183-2.183V49.5L11.47,47.934,7.916,49.5V125.72a4.564,4.564,0,0,0,4.564,4.564H98.087a20.1,20.1,0,0,0-1.681-6.111Z"
                transform="translate(0 -38.024)"
                fill="#fdb97e"
            />
            <path
                id="Path_27400"
                data-name="Path 27400"
                d="M14.464,0V11.474H7.916V4.564A4.563,4.563,0,0,1,12.48,0Z"
                fill="#7a6d79"
            />
            <g id="Group_7249" data-name="Group 7249" transform="translate(19.768 4.1)">
                <g id="Group_7246" data-name="Group 7246">
                    <path
                        id="Path_27401"
                        data-name="Path 27401"
                        d="M66.88,23.1a1.637,1.637,0,0,1,0-3.274h0a1.637,1.637,0,0,1,0,3.274Z"
                        transform="translate(-65.242 -19.83)"
                        fill="#fe99a0"
                    />
                </g>
                <g id="Group_7247" data-name="Group 7247" transform="translate(6.848)">
                    <path
                        id="Path_27402"
                        data-name="Path 27402"
                        d="M100,23.1a1.637,1.637,0,0,1,0-3.274h0a1.637,1.637,0,0,1,0,3.274Z"
                        transform="translate(-98.363 -19.83)"
                        fill="#fece85"
                    />
                </g>
                <g id="Group_7248" data-name="Group 7248" transform="translate(13.696)">
                    <path
                        id="Path_27403"
                        data-name="Path 27403"
                        d="M133.122,23.1a1.637,1.637,0,0,1,0-3.274h0a1.637,1.637,0,0,1,0,3.274Z"
                        transform="translate(-131.484 -19.83)"
                        fill="#bbf49b"
                    />
                </g>
            </g>
            <circle
                id="Ellipse_176"
                data-name="Ellipse 176"
                cx="25.208"
                cy="25.208"
                r="25.208"
                transform="translate(31.126 26.624)"
                fill="#dfeef4"
            />
            <ellipse
                id="Ellipse_177"
                data-name="Ellipse 177"
                cx="19.102"
                cy="19.102"
                rx="19.102"
                ry="19.102"
                transform="translate(33.964 66.973) rotate(-79.106)"
                fill="#f2fbff"
            />
            <path
                id="Path_27404"
                data-name="Path 27404"
                d="M0,0H8.215V13.348H0Z"
                transform="translate(79.056 80.126) rotate(-45)"
                fill="#7a6d79"
            />
            <path
                id="Path_27405"
                data-name="Path 27405"
                d="M385.159,396.842l2.087-2.658,4.688-4.685a2.274,2.274,0,0,1,3.214,0l11.874,11.957a7.494,7.494,0,0,1,1.7,7.98s-1.335,1.573-2.106,2.343l-.046.045c-2.929,2.927-7.286,2.157-10.215-.77l-11.2-11A2.271,2.271,0,0,1,385.159,396.842Z"
                transform="translate(-298.719 -308.442)"
                fill="#918291"
            />
            <path
                id="Path_27406"
                data-name="Path 27406"
                d="M299.87,318.88l13.55-13.542,1.488,3.592,3.63,1.524.354.354a5.187,5.187,0,0,1,0,7.338l-6.222,6.219-1.417.167c-2.025,2.024-4.476,1.5-6.5-.524Z"
                transform="translate(-231.592 -242.209)"
                fill="#f2fbff"
            />
            <path
                id="Path_27407"
                data-name="Path 27407"
                d="M406.18,429.964a7.459,7.459,0,0,1-1.7,2.621l-.046.046a7.5,7.5,0,0,1-10.608,0L381.95,420.673a2.27,2.27,0,0,1,0-3.213l2.75-2.748,13.489,13.554A7.5,7.5,0,0,0,406.18,429.964Z"
                transform="translate(-296.173 -328.969)"
                fill="#7a6d79"
            />
            <path
                id="Path_27408"
                data-name="Path 27408"
                d="M307.651,321.756l2.652,2.6-.607.607a5.185,5.185,0,0,1-7.333,0l-.349-.349-5.127-5.123,4.188-4.188,9.979-9.973,5.118,5.116a34.921,34.921,0,0,1-8.485,10.339A.653.653,0,0,0,307.651,321.756Z"
                transform="translate(-229.225 -242.205)"
                fill="#dfeef4"
            />
            <path
                id="Path_27409"
                data-name="Path 27409"
                d="M128.315,101.746a30.7,30.7,0,0,0-19.747,7.153,46.259,46.259,0,0,0-3.455,3.691c-4.622,5.386-6.455,12.3-6.455,19.952,0,17.009,12.639,28.56,29.658,28.56,9.515,0,17.275-2.159,23.673-8.846a30.792,30.792,0,0,0-23.673-50.511Zm0,53.609a22.812,22.812,0,1,1,22.826-22.812A22.819,22.819,0,0,1,128.315,155.355Z"
                transform="translate(-71.98 -80.71)"
                fill="#918291"
            />
            <path
                id="Path_27410"
                data-name="Path 27410"
                d="M147.545,179.706a30.807,30.807,0,1,1-43.419-43.356,30.806,30.806,0,0,0,43.419,43.356Z"
                transform="translate(-67.536 -108.159)"
                fill="#7a6d79"
            />
            <path
                id="Path_27411"
                data-name="Path 27411"
                d="M197.253,200.42l7.48-7.476a1.368,1.368,0,0,0,0-1.936l-2.905-2.9a1.37,1.37,0,0,0-1.937,0l-7.481,7.476a.437.437,0,0,1-.617,0l-7.481-7.476a1.37,1.37,0,0,0-1.937,0l-2.905,2.9a1.368,1.368,0,0,0,0,1.936l7.48,7.476a.437.437,0,0,1,0,.618l-7.48,7.476a1.368,1.368,0,0,0,0,1.936l2.905,2.9a1.37,1.37,0,0,0,1.937,0l7.481-7.476a.436.436,0,0,1,.617,0l7.481,7.476a1.37,1.37,0,0,0,1.937,0l2.905-2.9a1.368,1.368,0,0,0,0-1.936l-7.48-7.476A.437.437,0,0,1,197.253,200.42Z"
                transform="translate(-135.767 -148.896)"
                fill="#fe99a0"
            />
        </g>
    </svg>
);

export default NoResultsIcon;
