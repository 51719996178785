const Unchecked = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g
            id="Rectangle_279"
            data-name="Rectangle 279"
            fill="none"
            stroke="#707070"
            strokeWidth="0.5"
        >
            <rect width="12" height="12" rx="2" stroke="none" />
            <rect x="0.25" y="0.25" width="11.5" height="11.5" rx="1.75" fill="none" />
        </g>
    </svg>
);

export default Unchecked;
