import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putCourseReport, getCourseReport } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* getCourseReportSaga({
    payload,
}: ReturnType<typeof getCourseReport>): SagaIterator {
    try {
        const { classId, cb, from, to } = payload;
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/classes/${classId}/reports/course?from=${from}&to=${to}`,
        );
        yield put(putCourseReport(data));
        cb({ ...data });
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
