import { createAction } from '@reduxjs/toolkit';

export const PUT_SETTINGS = 'PUT_SETTINGS';
export const PUT_SETTINGS_VALIDATION_ERRORS = 'PUT_SETTINGS_VALIDATION_ERRORS';
export const PUT_2FA_PHONE_NUMBER = 'PUT_2FA_PHONE_NUMBER';
export const DELETE_2FA_PHONE_NUMBER = 'DELETE_2FA_PHONE_NUMBER';
export const CONFIRM_2FA_CODE = 'CONFIRM_2FA_CODE';

export type PutSettingsType = {
    pass?: string;
    newMail?: string;
    newPass?: string;
    cb?: () => void;
};

export type Put2FAPhoneNumberType = {
    number: string;
    cb?: () => void;
};

export type Confirm2FACode = {
    phoneNumber: string;
    code: string;
    cb?: () => void;
    cbError?: () => void;
};

export type DeletePhoneNumberType = {
    cb?: () => void;
};

export const putSettings = createAction<PutSettingsType>(PUT_SETTINGS);
export const putSettingsValidationErrors = createAction<any>(PUT_SETTINGS_VALIDATION_ERRORS);
export const put2FAPhoneNumber = createAction<Put2FAPhoneNumberType>(PUT_2FA_PHONE_NUMBER);
export const delete2FAPhoneNumber = createAction<DeletePhoneNumberType>(DELETE_2FA_PHONE_NUMBER);
export const confirm2FACode = createAction<Confirm2FACode>(CONFIRM_2FA_CODE);
