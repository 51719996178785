const SortAlt = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.511" height="15" viewBox="0 0 12.511 15">
            <path
                id="sort-alt"
                d="M13.621,3.5,12,1.875V15h-1.25V1.875L9.142,3.481,8.258,2.6,10.489.366a1.252,1.252,0,0,1,1.768,0L14.5,2.614ZM5.747,13.125V0H4.5V13.125L2.877,11.5l-.884.884,2.246,2.246a1.251,1.251,0,0,0,1.767,0L8.24,12.4l-.884-.884L5.747,13.125Z"
                transform="translate(-1.993)"
                fill="#fff"
            />
        </svg>
    );
};
export default SortAlt;
