import { appConstants } from 'src/constants';

export const homeworkAddResourcesFromState = ({
    state,
    currentLesson,
    setUpdateLesson,
}: {
    state: any;
    currentLesson: any;
    setUpdateLesson: any;
}) => {
    if (state && state.addToResources && currentLesson) {
        state.addToResources.forEach((curAddToResources: any) => {
            const tempCurAddToResources = structuredClone(curAddToResources);
            tempCurAddToResources.currentlyAdded = true;
            setUpdateLesson((data: any) => {
                const newActivities = data.activities
                    .map(({ id }: any) => id)
                    .includes(tempCurAddToResources.id)
                    ? data.activities
                    : [...data.activities, tempCurAddToResources];
                if (!data.teacherText.includes(`<p>${tempCurAddToResources.description}</p>`)) {
                    return {
                        ...data,
                        activities: newActivities,
                        teacherText: `${data.teacherText}<p>${tempCurAddToResources.description}</p>`,
                    };
                }
                return {
                    ...data,
                    activities: newActivities,
                };
            });
        });
    }
};

export const homeworkPrepareAssignmentForSave = ({
    data,
    assignment,
}: {
    data: any;
    assignment: any;
}) => {
    const tempAssignment = {
        ...assignment,
        type: 'assignment',
        currentlyAdded: true,
        preview: `${appConstants.server}/administration/assignments/reportswitchboard/${assignment.id}/1`,
    };

    return {
        ...data,
        activities: [...(data.activities || []), tempAssignment],
    };
};
