import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';

import Remove from 'src/components/Icons/Remove';
import SelectMenu from 'src/components/SelectMenu';
import MoveTo from 'src/components/Icons/MoveTo';
import Pause from 'src/components/Icons/Pause';
import Publish from 'src/components/Icons/Publish';
import CustomCalendar from 'src/components/CustomCalendar';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { addClassHoliday, moveDay, updateWeeklyPlan } from 'src/redux/weeklyPlan/actions';
import AssignModal from 'src/components/Modals/AssignModal';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import SquareCheckedIcon from 'src/components/Icons/SquareCheckedIcon';
import ArrowInSquareIcon from 'src/components/Icons/ArrowInSquareIcon';
import { WeeklyPlanMenuProps } from '../types';
import '../styles.scss';

const WeeklyPlanMenu = ({
    week,
    year,
    lesson,
    columns,
    setColumns,
    days,
    setDays,
    columnIndex,
    getUpdatedDays,
    lessons,
    restWeekly,
    onSave,
    deleteDayContent,
    setSavedHistoryData,
}: WeeklyPlanMenuProps) => {
    const { t } = useTranslation();
    const params = useParams();
    const { isDesktop } = useWindowSize();
    const dispatch = useDispatch();
    const [dayToMove, setDayToMove] = useState(new Date());
    const [moveAssignModal, setMoveAssignModal] = useState(false);
    const [moveConfirmModal, setMoveConfirmModal] = useState(false);
    const [openDeleteDayModal, setOpenDeleteDayModal] = useState(null as any);
    const [classHolidayDate, setClassHolidayDate] = useState([new Date()]);
    const [classHolidayDateAssignModal, setClassHolidayDateAssignModal] = useState(false);
    const [classHolidayDateConfirmModal, setClassHolidayDateConfirmModal] = useState(false);
    const isLastDayOfWeek = lesson.day === lessons.length;

    const isPublished = (day: number) => {
        return days.find(({ day: columnDay }: any) => columnDay === day)?.lessons[0].published;
    };

    if (!lesson) {
        return null;
    }

    return (
        <>
            <SelectMenu
                isCloseDropdownOnClick={false}
                wrapperClass="weeklyPlan-day"
                disableOutside={!isDesktop && moveAssignModal}
                text={
                    <div className={classNames('weeklyPlan-day-name sc_hover_color_text')}>
                        {isPublished(lesson.day) ? <SquareCheckedIcon /> : null}
                        <span>
                            {moment()
                                .day(lesson.day)
                                .isoWeekYear(year)
                                .isoWeek(week)
                                .format(isDesktop ? 'dddd, DD MMM' : 'ddd, DD MMM')}
                        </span>
                    </div>
                }
                options={[
                    {
                        content: (
                            <div className="weeklyPlan-option">
                                <MoveTo />
                                <span
                                    className="weeklyPlan-option-move-content-to"
                                    onClick={() => setMoveAssignModal(true)}
                                >
                                    {t('weeklyPlan.moveContentTo')}
                                </span>
                                {isDesktop && (
                                    <>
                                        <div
                                            className="weeklyPlan-option-calendar"
                                            style={{
                                                left: isLastDayOfWeek ? 'unset' : '100%',
                                                right: isLastDayOfWeek ? '100%' : 'unset',
                                            }}
                                        >
                                            <CustomCalendar
                                                week={week}
                                                year={year}
                                                type="short"
                                                minDate={null}
                                                navigationLabelFormat="MMM. YYYY"
                                                onChangeReturnType="date"
                                                onChange={(day: any) => setDayToMove(day)}
                                            />

                                            <div
                                                onClick={() => setMoveConfirmModal(true)}
                                                className="weeklyPlan-option-calendar-button"
                                            >
                                                <span className="sc_hover_color_text_svg_bg">
                                                    {t('weeklyPlan.move')}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className="weeklyPlan-option"
                                onClick={() => setClassHolidayDateAssignModal(true)}
                            >
                                <Pause />
                                <span>{t('weeklyPlan.addClassHoliday')}</span>
                                {isDesktop && (
                                    <div
                                        className="weeklyPlan-option-calendar"
                                        style={{
                                            left: isLastDayOfWeek ? 'unset' : '100%',
                                            right: isLastDayOfWeek ? '100%' : 'unset',
                                        }}
                                    >
                                        <CustomCalendar
                                            week={week}
                                            year={year}
                                            type="rangeCustom"
                                            navigationLabelFormat="MMM. YYYY"
                                            allowPartialRange
                                            onChangeReturnType="date"
                                            onChange={(date) => setClassHolidayDate(date)}
                                        />

                                        <div
                                            onClick={() => setClassHolidayDateConfirmModal(true)}
                                            className="weeklyPlan-option-calendar-button"
                                        >
                                            <span className="sc_hover_color_text_svg_bg">
                                                {t('weeklyPlan.add')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className="weeklyPlan-option"
                                onClick={() => {
                                    getUpdatedDays(columns, (daysToUpdate: any) => {
                                        const newDays = JSON.parse(JSON.stringify(daysToUpdate));
                                        newDays.splice(lesson.day - 1, 1, {
                                            ...newDays[lesson.day - 1],
                                            lessons: newDays[lesson.day - 1].lessons.map(
                                                (data: any) => {
                                                    return {
                                                        ...data,
                                                        published: isPublished(lesson.day) ? 0 : 1,
                                                    };
                                                },
                                            ),
                                        });
                                        setDays(newDays);
                                        dispatch(
                                            updateWeeklyPlan({
                                                data: {
                                                    ...restWeekly,
                                                    days: newDays,
                                                    lessons,
                                                },
                                                classId: params.id,
                                            }),
                                        );
                                    });
                                }}
                            >
                                {isPublished(lesson.day) ? <ArrowInSquareIcon /> : <Publish />}

                                <span>
                                    {isPublished(lesson.day)
                                        ? t('weeklyPlan.unpublish')
                                        : t('weeklyPlan.publish')}
                                </span>
                            </div>
                        ),
                    },
                    {
                        content: (
                            <div
                                className="weeklyPlan-option"
                                onClick={() => {
                                    setOpenDeleteDayModal({ columns, setColumns, columnIndex });
                                }}
                            >
                                <Remove />
                                <span>{t('weeklyPlan.deleteAllContent')}</span>
                            </div>
                        ),
                    },
                ]}
            />
            {!isDesktop && (
                <>
                    <AssignModal
                        text={t('weeklyPlan.moveContentTo')}
                        buttonText={t('weeklyPlan.move')}
                        onChangeReturnType="date"
                        type="short"
                        open={moveAssignModal}
                        navigationLabelFormat="MMM. YYYY"
                        onAssign={(date: any) => {
                            setDayToMove(date);
                            setMoveConfirmModal(true);
                        }}
                        onClose={() => setMoveAssignModal(false)}
                        minDate={null}
                    />
                    <AssignModal
                        text={t('weeklyPlan.addClassHoliday')}
                        buttonText={t('weeklyPlan.add')}
                        type="rangeCustom"
                        allowPartialRange
                        onChangeReturnType="date"
                        navigationLabelFormat="MMM. YYYY"
                        open={classHolidayDateAssignModal}
                        onAssign={(date: any) => {
                            setClassHolidayDate(date);
                            setClassHolidayDateConfirmModal(true);
                        }}
                        onClose={() => setClassHolidayDateAssignModal(false)}
                    />{' '}
                </>
            )}
            <ConfirmModal
                open={moveConfirmModal}
                text={t('weeklyPlan.moveConfirm')}
                onClose={() => setMoveConfirmModal(false)}
                onClick={() => {
                    const yearNum = moment(dayToMove).year();
                    const weekNum = moment(dayToMove).isoWeek();
                    const dayNumber = moment(dayToMove).day();
                    onSave(() => {
                        getUpdatedDays(columns, (_dataToSave: any) => {
                            const resp = {
                                from: {
                                    year: restWeekly.year,
                                    week: restWeekly.week,
                                    day: lesson.day,
                                },
                                to: {
                                    week: weekNum,
                                    year: yearNum,
                                    day: dayNumber,
                                },
                            };

                            dispatch(
                                moveDay({
                                    resp,
                                    classId: params.id,
                                }),
                            );

                            setSavedHistoryData(null);
                        });
                    });
                }}
            />
            <ConfirmModal
                open={classHolidayDateConfirmModal}
                text={t('weeklyPlan.addConfirm')}
                onClick={() => {
                    onSave(() => {
                        const getClassHoliday = (date: Date[]) => {
                            const isRange = +(date.length > 1);
                            return {
                                from: {
                                    year: moment(date[0]).year(),
                                    week: moment(date[0]).isoWeek(),
                                    day: moment(date[0]).day(),
                                },
                                to: {
                                    year: moment(date[isRange]).year(),
                                    week: moment(date[isRange]).isoWeek(),
                                    day: moment(date[isRange]).day(),
                                },
                            };
                        };
                        dispatch(
                            addClassHoliday({
                                dataToSave: getClassHoliday(classHolidayDate),
                                classId: params.id,
                                year,
                                week,
                            }),
                        );
                        setClassHolidayDateConfirmModal(false);
                        setSavedHistoryData(null);
                    });
                }}
                onClose={() => setClassHolidayDateConfirmModal(false)}
            />
            <ConfirmModal
                text={t('weeklyPlan.deleteConfirm')}
                onClick={() => {
                    deleteDayContent({ dayIndex: openDeleteDayModal.columnIndex, lesson });
                    setOpenDeleteDayModal(null);
                    setSavedHistoryData(null);
                }}
                onClose={() => {
                    setOpenDeleteDayModal(null);
                }}
                open={openDeleteDayModal}
            />
        </>
    );
};

export default WeeklyPlanMenu;
