import './index.scss';
import WhiteBox from 'src/components/WhiteBox';
import spLoaderGif from 'src/assets/images/sp_loader.gif';
import { useEffect } from 'react';
import { sideItems } from 'src/containers/Layout/components/Sidebar/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const { isModuleActive } = useIsModuleActive();
    const { t } = useTranslation();

    const redirect = (type: string) => {
        // eslint-disable-next-line no-restricted-globals, @typescript-eslint/naming-convention
        const [_, start, locId] = location.pathname.split('/');
        if (
            sideItems(t)
                [type].map(({ link }: { link: string }) => link)
                .includes(`/${start.trim()}`)
        ) {
            const firstActive = sideItems(t)[type].find(({ id }: { id: Modules }) => {
                if ([Modules.Information, Modules.Settings].includes(id)) {
                    return false;
                }
                return isModuleActive(id);
            });
            if (firstActive) {
                navigate(`${firstActive.link}${locId ? `/${locId}` : ''}`);
            }
        }
    };

    useEffect(() => {
        redirect('root');
        redirect('classSelected');
        // eslint-disable-next-line no-restricted-globals
    }, [location.pathname]);

    return (
        <WhiteBox>
            <div className="notFound">
                <h1>{t('NotFoundPage.pageNotFound')}</h1>
                <h2>{t('NotFoundPage.pageNotFound')}</h2>
                <img src={spLoaderGif} alt="loader" />
            </div>
        </WhiteBox>
    );
};
export default NotFoundPage;
