import { createAction } from '@reduxjs/toolkit';
import {
    AssignAutomatedProps,
    DeleteAutomatedProps,
    GetAutomatedProps,
    GetAutomatedTestProps,
    PutAutomatedProps,
    PutAutomatedTestProps,
} from './types';

export const GET_AUTOMATED = 'GET_AUTOMATED';
export const PUT_AUTOMATED = 'PUT_AUTOMATED';
export const DELETE_AUTOMATED = 'DELETE_AUTOMATED';
export const ASSIGN_AUTOMATED = 'ASSIGN_AUTOMATED';
export const GET_AUTOMATED_TEST = 'GET_AUTOMATED_TEST';
export const PUT_AUTOMATED_TEST = 'PUT_AUTOMATED_TEST';

export const getAutomated = createAction<GetAutomatedProps>(GET_AUTOMATED);
export const putAutomated = createAction<PutAutomatedProps>(PUT_AUTOMATED);
export const deleteAutomated = createAction<DeleteAutomatedProps>(DELETE_AUTOMATED);

export const assignAutomated = createAction<AssignAutomatedProps>(ASSIGN_AUTOMATED);
export const getAutomatedTest = createAction<GetAutomatedTestProps>(GET_AUTOMATED_TEST);
export const putAutomatedTest = createAction<PutAutomatedTestProps>(PUT_AUTOMATED_TEST);
