import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteToDo, getAllToDo } from '../actions';

export function* deleteToDoSaga({ payload }: ReturnType<typeof deleteToDo>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.delete(), `/staff/todo/${payload.toDoId}`);
        yield put(getAllToDo());
        yield put(
            getAllToDo({
                cb: () => {
                    if (payload.cb) {
                        payload.cb();
                    }
                },
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
