import { Props } from '../types';

const CheckMark = ({ fill = '#FAFAFA' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
            d="M4.61246 7.47424L2.78479 5.6976L2 6.46048L4.61246 9L10 3.76288L9.21521 3L4.61246 7.47424Z"
            fill={fill}
        />
    </svg>
);

export default CheckMark;
