import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { GET_EMAILS, SEND_EMAIL } from '../actions';
import { getEmailsSaga } from './getEmailsSaga';
import { sendEmailSaga } from './sendEmailSaga';

export function* emailsWatcher(): SagaIterator {
    yield takeLatest(GET_EMAILS, getEmailsSaga);
    yield takeLatest(SEND_EMAIL, sendEmailSaga);
}
