import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { putApiRequestError } from 'src/redux/error/actions';
import { getErrorMessage, SagaError } from 'src/redux/helpers';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { inviteStudentMail } from '../actions';

export function* inviteStudentEmailSaga({
    payload,
}: ReturnType<typeof inviteStudentMail>): SagaIterator {
    try {
        yield put(startLoading());
        const body = {
            name: payload.name,
            email: payload.email,
        } as Record<string, any>;
        if (payload.upgradeId) {
            body.upgrade = payload.upgradeId;
        }
        yield call(API.post(), `/staff/classes/${payload.classId}/invites/send`, body);
        if (payload.cb) {
            payload.cb();
        }
    } catch (error: SagaError) {
        if (error.code === 400) {
            if (payload.cbError) {
                payload.cbError(getErrorMessage(error));
            }
        } else {
            yield put(putApiRequestError(error));
        }
    } finally {
        yield put(finishLoading());
    }
}
