import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActiveClasses } from 'src/pages/Classes/constants';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getClasses, saveClass } from '../actions';

export function* saveClassSaga({ payload }: ReturnType<typeof saveClass>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.put(), `/staff/classes/${payload.classId}`, payload.dataToSave);
        if (payload.cb) {
            payload.cb();
        }
        yield put(getClasses(ActiveClasses.OPEN));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
