import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CourseReport } from './types';

export const getReportsSelector = (state: RootState): any => state.reports.reports;
export const selectReports = createSelector(
    createSelector(getReportsSelector, (item) => item),
    (item) => item,
);

export const getStundentReportSelector = (state: RootState): any => state.reports.studentReport;
export const selectStundentReport = createSelector(
    createSelector(getStundentReportSelector, (item) => item),
    (item) => item,
);

export const getCourseReportSelector = (state: RootState): CourseReport => state.reports.course;
export const selectCourseReport = createSelector(getCourseReportSelector, (item) => item);
