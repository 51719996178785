const ArrowSort = () => (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.19917 9.16251C8.93917 8.90251 8.51917 8.90251 8.25917 9.16251L5.66584 11.7492V1.63584C5.66584 1.26918 5.36584 0.969177 4.99917 0.969177C4.63251 0.969177 4.33251 1.26918 4.33251 1.63584V11.7558L1.74584 9.16918C1.48584 8.90918 1.06584 8.90918 0.80584 9.16918C0.54584 9.42918 0.54584 9.84918 0.80584 10.1092L4.53251 13.8358C4.79251 14.0958 5.21251 14.0958 5.47251 13.8358L9.19917 10.1092C9.45251 9.84918 9.45251 9.42251 9.19917 9.16251Z"
            fill="black"
        />
    </svg>
);

export default ArrowSort;
