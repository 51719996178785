import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAssessmentTypes, putAssessmentTypes } from '../actions';

export function* getAssessmentTypesSaga({
    payload,
}: ReturnType<typeof getAssessmentTypes>): SagaIterator {
    try {
        yield put(startLoading());
        const types = yield call(API.get(), `/staff/classes/${payload.classId}/assessments/types`);
        yield put(putAssessmentTypes(types));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
