import NoResultsIcon from '../Icons/NoResultsIcon';
import './styles.scss';
import { NoResultsProps } from './types';

const NoResults = ({ title, subtitle }: NoResultsProps) => {
    return (
        <div className="noResults">
            <NoResultsIcon />
            <span className="noResults__title" data-testid="noResults-title">
                {title}
            </span>
            {subtitle && (
                <div
                    className="noResults__subtitle"
                    data-testid="noResults-subtitle"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
            )}
        </div>
    );
};

export default NoResults;
