import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { confirm2FACode, putSettingsValidationErrors } from '../actions';

export function* confirm2FACodeSaga({ payload }: ReturnType<typeof confirm2FACode>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.post(), `/settings/phone/confirm`, {
            number: payload.phoneNumber,
            code: payload.code,
        });
        yield put(putSettingsValidationErrors(true));
        yield put(putSettingsValidationErrors(false));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        if (payload.cbError) {
            payload.cbError();
        }
        yield put(
            putSettingsValidationErrors({
                message: [{ message: 'Error to confirm code. Please try again.' }],
            }),
        );
    } finally {
        yield put(finishLoading());
    }
}
