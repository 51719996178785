import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Search from 'src/components/Search';
import WhiteBox from 'src/components/WhiteBox';
import { putBreadcrumbs } from 'src/redux/layout/actions';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const Management = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(putBreadcrumbs([{ name: t('general.management') }]));
    }, [dispatch]);

    return (
        <>
            <div className="management-header">
                <Search />
            </div>
            <WhiteBox />
        </>
    );
};

export default Management;
