import { Props } from '../types';

const CheckedType2 = ({ fill = '#fff' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g id="Checked" transform="translate(234.665 234.665)">
            <g id="Group_497" data-name="Group 497" transform="translate(-234.665 -234.665)">
                <path
                    id="Path_981"
                    data-name="Path 981"
                    d="M83.023,118.125l-4.867,4.394a.606.606,0,0,1-.8,0l-2.615-2.361a.237.237,0,0,1,0-.359l.4-.359a.3.3,0,0,1,.4,0l2.217,2,4.469-4.034a.3.3,0,0,1,.4,0l.4.359A.237.237,0,0,1,83.023,118.125Z"
                    transform="translate(-72.884 -114.145)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default CheckedType2;
