import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button';
import CloseButton3Svg from 'src/components/Icons/CloseButton3Svg';
import InputText from 'src/components/InputText';
import InputTextDropdown from 'src/components/InputTextDropdown';
import Modal from 'src/components/Modal';
import { ERRORS } from 'src/constants';
import { NotificationStatusEnum, useNotificationQueue } from 'src/providers/NotificationProvider';
import { getUpgrades, inviteStudentMail } from 'src/redux/students/actions';
import { selectUpgrades } from 'src/redux/students/selectors';
import * as yup from 'yup';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const InviteStudentEmailModal = ({
    open = true,
    classId,
    setOpen,
}: {
    open: boolean | string;
    classId?: number | string;
    setOpen: (open: boolean) => void;
}) => {
    const dispatch = useDispatch();
    const notificationQueue = useNotificationQueue();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [mainError, setMainError] = useState<null | string>(null);
    const upgrades = useSelector(selectUpgrades);
    const [tempUpgrades, setTempUpgrades] = useState<{ title: string; id: number }[]>([]);
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            upgradeId: undefined,
            upgradeName: '',
        },
        validateOnChange,
        onSubmit: (values) => {
            dispatch(
                inviteStudentMail({
                    name: values.name,
                    email: values.email,
                    upgradeId: values.upgradeId,
                    classId,
                    cb: () => {
                        notificationQueue.add(t('modals.inviteStudentEmail.successfullySent'), {
                            message: t('modals.inviteStudentEmail.emailSent'),
                            status: NotificationStatusEnum.success,
                        });
                        formik.resetForm();
                        setMainError(null);
                        setOpen(false);
                    },
                    cbError: (errorMessage) => {
                        setMainError(errorMessage);
                    },
                }),
            );
        },
        validationSchema: yup.object().shape({
            email: yup.string().required(ERRORS.required).email(ERRORS.email),
            name: yup.string().required(ERRORS.required),
            upgradeId: yup.mixed().test({
                message: t('modals.inviteStudentEmail.choosePackage'),
                test: (value) => {
                    setValidateOnChange(true);
                    if (upgrades.length) {
                        return upgrades.some((item) => item.id === value);
                    }
                    return true;
                },
            }),
        }),
    });

    const onClose = () => {
        setOpen(false);
        formik.resetForm();
        setMainError(null);
    };

    const getError = (inputName: keyof (typeof formik)['initialValues']) => {
        return formik.errors[inputName];
    };

    useEffect(() => {
        if (open) {
            dispatch(getUpgrades());
        }
    }, [open]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            setMainError(null);
        };
    }, []);

    useEffect(() => {
        setTempUpgrades(
            upgrades.map((item) => {
                return { title: item.name, id: item.id };
            }),
        );
    }, [upgrades]);

    return (
        <Modal open={open} onClose={onClose} noPadding template={3} overflowEnable>
            <div className="inviteStudentEmailModal">
                <div className="inviteStudentEmailModal-header">
                    <span className="inviteStudentEmailModal-header-title">
                        {t('modals.inviteStudentEmail.inviteStudent')}
                    </span>
                    <span className="inviteStudentEmailModal-header-close" onClick={onClose}>
                        <CloseButton3Svg />
                    </span>
                </div>
                <div className="inviteStudentEmailModal-hr" />
                <div className="inviteStudentEmailModal-body">
                    <div className="inviteStudentEmailModal-body-item">
                        <div className="inviteStudentEmailModal-body-label">
                            {t('modals.inviteStudentEmail.studentName')}
                        </div>
                        <InputText
                            errorAsBlock
                            template={3}
                            fullWidth
                            inputProps={{ placeholder: t('modals.inviteStudentEmail.name') }}
                            error={getError('name')}
                            onChange={(v) => {
                                setMainError(null);
                                formik.setFieldValue('name', v);
                            }}
                        />
                    </div>
                    <div className="inviteStudentEmailModal-body-item">
                        <div className="inviteStudentEmailModal-body-label">
                            {t('modals.inviteStudentEmail.studentEmail')}
                        </div>
                        <InputText
                            errorAsBlock
                            template={3}
                            fullWidth
                            inputProps={{ placeholder: 'myemail@gmail.com' }}
                            error={getError('email')}
                            onChange={(v) => {
                                setMainError(null);
                                formik.setFieldValue('email', v);
                            }}
                        />
                    </div>
                    {upgrades.length ? (
                        <div className="inviteStudentEmailModal-body-item">
                            <div className="inviteStudentEmailModal-body-label">
                                {t('modals.inviteStudentEmail.package')}
                            </div>
                            <InputTextDropdown
                                errorAsBlock
                                template={1}
                                onChange={(v) => {
                                    setMainError(null);
                                    formik.setFieldValue('upgradeName', v);
                                    formik.setFieldValue('upgradeId', undefined);
                                    if (v === '') {
                                        setTempUpgrades(
                                            upgrades.map((item) => {
                                                return { title: item.name, id: item.id };
                                            }),
                                        );
                                    } else {
                                        const pastToFirstPlaces = tempUpgrades.filter((item) =>
                                            item.title.includes(v),
                                        );
                                        const other = tempUpgrades.filter(
                                            (item) => !item.title.includes(v),
                                        );
                                        setTempUpgrades([...pastToFirstPlaces, ...other]);
                                    }
                                }}
                                onSelect={(title, id) => {
                                    formik.setFieldValue('upgradeName', title);
                                    formik.setFieldValue('upgradeId', id);
                                }}
                                options={tempUpgrades}
                                value={formik.values.upgradeName}
                                error={getError('upgradeId')}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {mainError && (
                        <div className="inviteStudentEmailModal-body-mainError">{mainError}</div>
                    )}
                </div>
                <div className="inviteStudentEmailModal-hr" />
                <div className="inviteStudentEmailModal-footer">
                    <Button
                        type="outline-grey"
                        className="inviteStudentEmailModal-footer-btn inviteStudentEmailModal-footer-btn-cancel"
                        onClick={onClose}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        disabled={!formik.isValid || !!mainError}
                        type="secondary"
                        className="inviteStudentEmailModal-footer-btn inviteStudentEmailModal-footer-btn-invite"
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                    >
                        {t('modals.inviteStudentEmail.sendInviteEmail')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default InviteStudentEmailModal;
