import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AimMaterialType } from './types';

type Aim = {
    completed: boolean;
    id: number;
    name: string;
    rank: number;
    timesDone: number;
};

type SubCategory = {
    name: string;
    aims: Aim[];
};

type Category = {
    name: string;
    selected: boolean;
    subCategories: SubCategory[];
};

type Level = {
    name: string;
    categories: Category[];
};

type TableAim = Aim & { category: string; level: string };

export const getAimsMaterialsSelector = (state: RootState): AimMaterialType =>
    state.coursePlanner.aimsMaterials;

export const getBookSelector = (state: RootState): any => state.coursePlanner.book;

export const selectBook = createSelector(
    createSelector(getBookSelector, (book) => book),
    (book) => book,
);

export const selectAimsMaterials = createSelector(
    createSelector(getAimsMaterialsSelector, (report) => report),
    (report) => report,
);
export const getAimsSelector = (state: RootState): any => state.coursePlanner.aims;

export const selectAims = createSelector(
    createSelector(getAimsSelector, (report) => report),
    (report) => {
        if (!report) return [];
        const aims: TableAim[] = [];
        report.levels?.forEach((level: Level) => {
            level.categories.forEach((category: Category) => {
                category.subCategories.forEach((sub: SubCategory) => {
                    sub.aims.forEach((aim: Aim) => {
                        aims.push({
                            ...aim,
                            category: sub.name,
                            level: level.name,
                        });
                    });
                });
            });
        });
        return aims;
    },
);

export const getQuestionsSelector = (state: RootState): any => state.coursePlanner.questions;

export const selectQuestions = createSelector(
    createSelector(getQuestionsSelector, (questions) => questions),
    (questions) => questions,
);
