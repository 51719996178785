import { useSelector } from 'react-redux';
import { selectAims } from 'src/redux/coursePlanner/selectors';
import { selectUnits } from 'src/redux/weeklyPlan/selectors';
import { Method } from '../../types';
import './styles.scss';

export default function Review({
    testName,
    method,
    units,
    unitTest,
}: {
    testName: string;
    method: string;
    units: number[];
    unitTest: number[];
}) {
    const tableUnits = useSelector(selectUnits);
    const aims = useSelector(selectAims);

    const unitsNames = () => {
        if (method === Method.TestByAim) {
            const filtered = aims
                .filter(({ id }: { id: number }) => units.includes(id))
                .map(({ level }) => level);

            const result = [...new Set(filtered)];

            return result.join(', ');
        }

        return tableUnits
            .filter((item: any) => units.includes(item.id))
            .map(({ number }: any) => `Unit ${number}`)
            .join(', ');
    };

    const content = [
        {
            title: 'Method',
            data: method,
            mpbileText: 'Method',
        },
        {
            title: 'Content',
            data: unitsNames(),
            mpbileText: 'Contents',
        },
        {
            title: 'Questions',
            data: unitTest.length,
            mpbileText: 'Questions',
        },
        {
            title: 'Test Name',
            data: testName,
            mpbileText: 'test name',
        },
    ];

    return (
        <div className="makeUnit-stepContent">
            <h1 className="makeUnit-stepTitle">Review & confirm</h1>

            <div className="makeUnit-reviewBody">
                <span className="makeUnit-reviewTitle">Test Details</span>

                {content.map((row, index) => (
                    <div key={index} className="makeUnit-reviewRow">
                        <span className="makeUnit-reviewRowTitle">{row.title}</span>
                        <div className="makeUnit-reviewRowDataBody">
                            <span className="makeUnit-reviewRowData-subhead">{row.title}</span>
                            <span className="makeUnit-reviewRowData">{row.data}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
