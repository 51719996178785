import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteStudent, getStudents } from '../actions';

export function* deleteStudentSaga({ payload }: ReturnType<typeof deleteStudent>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.delete(), `/staff/classes/${payload.classId}/students/${payload.uid}`);
        yield put(
            getStudents({
                classId: payload.classId,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
