const Italic = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16">
        <text
            id="I"
            transform="translate(2 13)"
            fill="#54585a"
            fontSize="14"
            fontFamily="Raleway-Italic, Raleway"
            fontStyle="italic"
        >
            <tspan x="-1" y="0">
                I
            </tspan>
        </text>
    </svg>
);

export default Italic;
