import { Props } from '../types';

const OnlineTestingIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        id="Online_Testing"
        data-name="Online  Testing"
        xmlns="http://www.w3.org/2000/svg"
        width="26.085"
        height="23.911"
        viewBox="0 0 26.085 23.911"
    >
        <g id="Group_575" data-name="Group 575" transform="translate(0 0)">
            <path
                id="Path_1222"
                data-name="Path 1222"
                d="M20.651,0H5.434A5.441,5.441,0,0,0,0,5.434V18.477a5.441,5.441,0,0,0,5.434,5.434H20.651a5.441,5.441,0,0,0,5.434-5.434V5.434A5.441,5.441,0,0,0,20.651,0ZM5.434,2.174H20.651a3.261,3.261,0,0,1,3.261,3.261V6.521H2.174V5.434A3.261,3.261,0,0,1,5.434,2.174ZM20.651,21.738H5.434a3.261,3.261,0,0,1-3.261-3.261V8.7H23.911v9.782A3.261,3.261,0,0,1,20.651,21.738Zm1.257-8.7a1.087,1.087,0,0,1-1.087,1.087H14.062a1.087,1.087,0,1,1,0-2.174h6.759A1.087,1.087,0,0,1,21.908,13.043ZM18.239,17.39a1.087,1.087,0,0,1-1.087,1.087H14.062a1.087,1.087,0,0,1,0-2.174h3.091A1.087,1.087,0,0,1,18.239,17.39ZM3.261,4.348A1.087,1.087,0,1,1,4.348,5.434,1.087,1.087,0,0,1,3.261,4.348Zm3.261,0A1.087,1.087,0,1,1,7.608,5.434,1.087,1.087,0,0,1,6.521,4.348Zm3.261,0a1.087,1.087,0,1,1,1.087,1.087A1.087,1.087,0,0,1,9.782,4.348Z"
                fill={fill}
            />
            <g id="Group_574" data-name="Group 574" transform="translate(3.917 12.487)">
                <path
                    id="Path_1223"
                    data-name="Path 1223"
                    d="M83.938,248.873a.714.714,0,0,0-.414-.251h0a.715.715,0,0,0-.844.559,2.2,2.2,0,1,1-2.161-2.648,2.243,2.243,0,0,1,.3.02h0a.716.716,0,1,0,.19-1.419,3.637,3.637,0,1,0,3.078,4.33.714.714,0,0,0-.145-.592Z"
                    transform="translate(-76.882 -245.102)"
                    fill={fill}
                />
                <path
                    id="Path_1224"
                    data-name="Path 1224"
                    d="M157.72,245.742h0Z"
                    transform="translate(-153.601 -245.709)"
                    fill={fill}
                />
                <path
                    id="Path_1225"
                    data-name="Path 1225"
                    d="M157.8,245.752Z"
                    transform="translate(-153.676 -245.719)"
                    fill={fill}
                />
                <path
                    id="Path_1226"
                    data-name="Path 1226"
                    d="M119.775,261.169l-.058.058.058-.058a.661.661,0,0,0-.935.935l.887.887a.661.661,0,0,0,.935,0l2.813-2.813a.661.661,0,0,0-.935-.935l-2.345,2.345Z"
                    transform="translate(-116.519 -258.339)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default OnlineTestingIcon;
