import { Props } from '../types';

const Exit = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.22"
        height="15.219"
        viewBox="0 0 15.22 15.219"
    >
        <g id="exit" transform="translate(7.61 7.61)">
            <g id="Group_406" data-name="Group 406" transform="translate(-7.61 -7.61)">
                <g id="Group_405" data-name="Group 405">
                    <path
                        id="Path_856"
                        data-name="Path 856"
                        d="M8.507,7.61l6.527-6.527a.634.634,0,0,0-.9-.9L7.61,6.713,1.083.186a.634.634,0,0,0-.9.9L6.713,7.61.186,14.137a.634.634,0,1,0,.9.9L7.61,8.507l6.527,6.527a.634.634,0,0,0,.9-.9Z"
                        transform="translate(0 0)"
                        fill={fill}
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default Exit;
