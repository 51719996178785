import { Props } from '../types';

const SquareCheckedIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Group_288"
        data-name="Group 288"
        width="17.127"
        height="17.127"
        viewBox="0 0 17.127 17.127"
    >
        <g id="Group_288-2" data-name="Group 288">
            <path
                id="Path_494"
                data-name="Path 494"
                d="M16.43,6.889a.811.811,0,0,0-1.263.672v5.564a2.027,2.027,0,0,1-.161.794,2.059,2.059,0,0,1-.741.9,2.029,2.029,0,0,1-1.141.349H3.664A2.028,2.028,0,0,1,2.87,15a2.058,2.058,0,0,1-.9-.741,2.027,2.027,0,0,1-.349-1.141V3.668a2.027,2.027,0,0,1,.161-.794,2.059,2.059,0,0,1,.741-.9,2.028,2.028,0,0,1,1.141-.349H8.081A.815.815,0,0,0,8.533.139.8.8,0,0,0,8.081,0H3.664A3.667,3.667,0,0,0,0,3.664H0v9.46a3.671,3.671,0,0,0,3.664,3.669h9.46a3.668,3.668,0,0,0,3.669-3.664V7.562a.806.806,0,0,0-.359-.672Z"
                transform="translate(0 0.334)"
                fill="#54585a"
            />
            <rect
                id="Rectangle_6468"
                data-name="Rectangle 6468"
                width="1.615"
                height="5.293"
                rx="0.807"
                transform="translate(4.935 5.743) rotate(-40.685)"
                fill={fill}
            />
            <rect
                id="Rectangle_6469"
                data-name="Rectangle 6469"
                width="1.615"
                height="12.162"
                rx="0.807"
                transform="translate(15.986) rotate(45)"
                fill={fill}
            />
        </g>
    </svg>
);

export default SquareCheckedIcon;
