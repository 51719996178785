import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { createClass, getClasses, saveClass } from 'src/redux/classes/actions';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import './styles.scss';
import Button from '../../Button';
import CloseButtonSvg from '../../Icons/CloseButtonSvg';

type Props = {
    open: any;
    onClose(): void;
    classData: any;
    activeClasses: any;
};

const EditClassModal = ({ onClose, open, classData, activeClasses }: Props) => {
    const [inputs, setInputs] = useState({} as any);
    const getValue = (valueName: string) => {
        return inputs[valueName] ?? '';
    };
    const [currentClassName, setCurrentClassName] = useState('');
    const dispatch = useDispatch();
    const [nextStepDisabled, setNextStepDisabled] = useState(false);
    const curOnClose = () => {
        setNextStepDisabled(false);
        onClose();
    };
    const handleChange = ({ target: { name, value, required } }: any) => {
        if (name === 'name' && value === '') {
            setNextStepDisabled(true);
        } else if (name === 'name' && value !== '') {
            setNextStepDisabled(false);
        } else if (inputs.name === '') {
            setNextStepDisabled(true);
        }
        setInputs((currentInputs: any) => ({ ...currentInputs, [name]: value }));
    };
    const { t } = useTranslation();

    useEffect(() => {
        if (open !== 'create') {
            setInputs({
                name: classData.name,
                level: classData.level,
                room: classData.room,
                type: classData.type,
                teacher: classData.teacher,
            });
            setCurrentClassName(classData.name);
        }

        return () => {
            setInputs({});
            setCurrentClassName('');
        };
    }, [classData, open]);

    return (
        <>
            <Modal open={open} onClose={curOnClose} modalBackdropClass="EditClassModalBackdrop">
                <div className="EditClassM">
                    <div
                        className="modal-close sc_hover_color_text_svg"
                        onClick={() => curOnClose()}
                    >
                        <CloseButtonSvg />
                    </div>
                    <div>
                        <div className="EditClassM-info__title">
                            {open !== 'create' ? (
                                <>
                                    {t('modals.editClass.classDetails')}{' '}
                                    <span className="main_color">{currentClassName}</span>
                                </>
                            ) : (
                                <>{t('modals.editClass.newClass')}</>
                            )}
                        </div>
                    </div>

                    <div className="EditClassM-fieldset">
                        <div className="EditClassM-info-fieldset__title">
                            {t('general.name')}
                            <span className="red">*</span>
                        </div>
                        <input
                            type="text"
                            placeholder=""
                            name="name"
                            value={getValue('name')}
                            onChange={(event) => handleChange(event)}
                            required
                            maxLength={255}
                        />
                        <div className="EditClassM-info-fieldset__title">{t('general.level')}</div>
                        <input
                            type="text"
                            placeholder=""
                            name="level"
                            value={getValue('level')}
                            onChange={(event) => handleChange(event)}
                            maxLength={255}
                        />
                        <div className="EditClassM-info-fieldset__title">{t('general.room')}</div>
                        <input
                            type="text"
                            placeholder=""
                            name="room"
                            value={getValue('room')}
                            onChange={(event) => handleChange(event)}
                            maxLength={255}
                        />
                        <div className="EditClassM-info-fieldset__title">
                            {t('modals.createClass.classType')}
                        </div>
                        <input
                            type="text"
                            placeholder=""
                            name="type"
                            value={getValue('type')}
                            onChange={(event) => handleChange(event)}
                            maxLength={255}
                        />
                        <div className="EditClassM-info-fieldset__title">
                            {t('general.teacher')}
                        </div>
                        <input
                            type="text"
                            placeholder=""
                            name="teacher"
                            value={getValue('teacher')}
                            onChange={(event) => handleChange(event)}
                            maxLength={255}
                        />
                    </div>
                    <div className="EditClassM-footer">
                        <Button
                            type="square"
                            disabled={nextStepDisabled}
                            onClick={() => {
                                const dataToSave = {
                                    name: getValue('name'),
                                    level: getValue('level'),
                                    room: getValue('room'),
                                    type: getValue('type'),
                                    teacher: getValue('teacher'),
                                };
                                if (open === 'create') {
                                    dispatch(
                                        createClass({
                                            ...dataToSave,
                                            cb: () => {
                                                dispatch(getClasses(activeClasses));
                                                curOnClose();
                                            },
                                        }),
                                    );
                                    return;
                                }
                                dispatch(
                                    saveClass({
                                        classId: classData.id,
                                        cb: () => curOnClose(),
                                        dataToSave,
                                    }),
                                );
                            }}
                        >
                            {t('general.save')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EditClassModal;
