const ChartHistogram = () => {
    return (
        <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <g fill="#54585a">
                <path d="m19.167 18.333h-15a2.5 2.5 0 0 1 -2.5-2.5v-15a.833.833 0 1 0 -1.667 0v15a4.172 4.172 0 0 0 4.167 4.167h15a.833.833 0 1 0 0-1.667z" />
                <path
                    d="m5.818 18.36a.818.818 0 0 0 .818-.818v-5.724a.818.818 0 0 0 -1.636 0v5.725a.818.818 0 0 0 .818.817z"
                    transform="translate(-.826 -1.731)"
                />
                <path
                    d="m10 9.818v7.36a.818.818 0 0 0 1.636 0v-7.36a.818.818 0 1 0 -1.636 0z"
                    transform="translate(-1.653 -1.382)"
                />
                <path
                    d="m15 12.818v4.907a.818.818 0 0 0 1.636 0v-4.907a.818.818 0 0 0 -1.636 0z"
                    transform="translate(-2.479 -1.907)"
                />
                <path
                    d="m20 8.818v8.182a.818.818 0 0 0 1.636 0v-8.182a.818.818 0 1 0 -1.636 0z"
                    transform="translate(-3.271 -1.209)"
                />
                <path
                    d="m5.818 7.36a.818.818 0 0 0 .582-.239l2.929-2.933a.838.838 0 0 1 1.156 0l1.776 1.776a2.453 2.453 0 0 0 3.469 0l4.57-4.564a.818.818 0 1 0 -1.158-1.16l-4.568 4.567a.818.818 0 0 1 -1.156 0l-1.777-1.775a2.453 2.453 0 0 0 -3.469 0l-2.932 2.932a.818.818 0 0 0 .578 1.4z"
                    transform="translate(-.538)"
                />
            </g>
        </svg>
    );
};
export default ChartHistogram;
