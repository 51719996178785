import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { IPInfoReducer, IPInfoReducerState } from 'src/redux/ipInfo/reducer';
import { layoutReducer, LayoutState } from './layout/reducer';
import { classesReducer, ClassesState } from './classes/reducer';
import rootSaga from './rootSaga';
import { studentReducer, studentsReducer, StudentsState, StudentState } from './students/reducer';
import { automatedReducer } from './automated/reducer';
import { unassignedReducer, UnassignedState } from './unassigned/reducer';
import { WeeklyPlanInitialStateProps } from './weeklyPlan/types';
import { weeklyPlanReducer } from './weeklyPlan/reducer';
import { loadingReducer, LoadingState } from './loading/reducer';
import { assessmentReducer, AssessmentState } from './assessment/reducer';
import { attendanceReducer, AttendanceState } from './attendance/reducer';
import { progressReducer, ProgressState } from './progress/reducer';
import { AutomatedState } from './automated/types';
import { reportsReducer, ReportsState } from './reports/reducer';
import { notificationsReducer, NotificationsState } from './notifications/reducer';
import { errorReducer, ErrorState } from './error/reducer';
import { searchReducer, SearchState } from './search/reducer';
import { settingsReducer, SettingsState } from './settings/reducer';
import { allStudentsReducer, AllStudentsState } from './allStudents/reducer';
import { staffReportsReducer, StaffReportsState } from './staff/reducer';
import { CourPlannerState, coursePlannerReducer } from './coursePlanner/reducer';
import { usersReducer, UsersReducerState } from './users/reducer';
import { emailsReducer, EmailsState } from './emails/reducer';
import { schoolsReducer, SchoolsReducerState } from './schools/reducer';
import { toDoReducer, ToDoReducerState } from './toDo/reducer';
import { qrCodesReducer, QrCodesReducerState } from './qrCodes/reducer';

export interface RootState {
    classes: ClassesState;
    students: StudentsState;
    layout: LayoutState;
    automated: AutomatedState;
    emailsReducer: EmailsState;
    unassigned: UnassignedState;
    weeklyPlan: WeeklyPlanInitialStateProps;
    loading: LoadingState;
    assessment: AssessmentState;
    attendance: AttendanceState;
    progress: ProgressState;
    reports: ReportsState;
    student: StudentState;
    notifications: NotificationsState;
    error: ErrorState;
    search: SearchState;
    settings: SettingsState;
    allStudents: AllStudentsState;
    staffReports: StaffReportsState;
    coursePlanner: CourPlannerState;
    users: UsersReducerState;
    schools: SchoolsReducerState;
    toDo: ToDoReducerState;
    IPInfo: IPInfoReducerState;
    qrCodes: QrCodesReducerState;
}

export const reducer = combineReducers({
    classes: classesReducer,
    layout: layoutReducer,
    students: studentsReducer,
    automated: automatedReducer,
    unassigned: unassignedReducer,
    weeklyPlan: weeklyPlanReducer,
    loading: loadingReducer,
    assessment: assessmentReducer,
    attendance: attendanceReducer,
    progress: progressReducer,
    student: studentReducer,
    reports: reportsReducer,
    notifications: notificationsReducer,
    error: errorReducer,
    search: searchReducer,
    settings: settingsReducer,
    allStudents: allStudentsReducer,
    staffReports: staffReportsReducer,
    coursePlanner: coursePlannerReducer,
    users: usersReducer,
    schools: schoolsReducer,
    emailsReducer,
    toDo: toDoReducer,
    IPInfo: IPInfoReducer,
    qrCodes: qrCodesReducer,
});

interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(reducer, enhancer);
export type RootsState = ReturnType<typeof reducer>;
export default store;
sagaMiddleware.run(rootSaga);
