import { Props } from '../types';

const QRCodesIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M1.09092 8.72727C0.436377 8.72727 0 8.29089 0 7.63635V3.27271C0 1.41817 1.41817 0 3.27271 0H7.63635C8.29089 0 8.72727 0.436377 8.72727 1.09092C8.72727 1.74546 8.29089 2.18184 7.63635 2.18184H3.27271C2.61817 2.18184 2.18179 2.61822 2.18179 3.27276V7.63639C2.18184 8.29089 1.74546 8.72727 1.09092 8.72727Z"
            fill={fill}
        />
        <path
            d="M22.9091 8.72727C22.2546 8.72727 21.8182 8.29089 21.8182 7.63635V3.27271C21.8182 2.61817 21.3818 2.18179 20.7273 2.18179H16.3636C15.7091 2.18179 15.2727 1.74542 15.2727 1.09087C15.2727 0.436332 15.7091 0 16.3636 0H20.7273C22.5818 0 24 1.41817 24 3.27271V7.63635C24 8.29089 23.5636 8.72727 22.9091 8.72727Z"
            fill={fill}
        />
        <path
            d="M7.63635 24H3.27271C1.41817 24 0 22.5818 0 20.7273V16.3636C0 15.7091 0.436377 15.2727 1.09092 15.2727C1.74546 15.2727 2.18184 15.7091 2.18184 16.3636V20.7273C2.18184 21.3818 2.61821 21.8182 3.27276 21.8182H7.63639C8.29093 21.8182 8.72731 22.2546 8.72731 22.9091C8.72726 23.5636 8.29089 24 7.63635 24Z"
            fill={fill}
        />
        <path
            d="M20.7273 24H16.3636C15.7091 24 15.2727 23.5636 15.2727 22.9091C15.2727 22.2546 15.7091 21.8182 16.3636 21.8182H20.7273C21.3818 21.8182 21.8182 21.3818 21.8182 20.7273V16.3636C21.8182 15.7091 22.2546 15.2727 22.9091 15.2727C23.5636 15.2727 24 15.7091 24 16.3636V20.7273C24 22.5818 22.5818 24 20.7273 24Z"
            fill={fill}
        />
        <path
            d="M9.8182 10.9091H5.45457C4.80002 10.9091 4.36365 10.4727 4.36365 9.8182V5.45457C4.36365 4.80002 4.80002 4.36365 5.45457 4.36365H9.8182C10.4727 4.36365 10.9091 4.80002 10.9091 5.45457V9.8182C10.9091 10.4727 10.4727 10.9091 9.8182 10.9091ZM6.54549 8.72728H8.72732V6.54544H6.54549V8.72728Z"
            fill={fill}
        />
        <path
            d="M9.8182 19.6364H5.45457C4.80002 19.6364 4.36365 19.2 4.36365 18.5455V14.1819C4.36365 13.5273 4.80002 13.0909 5.45457 13.0909H9.8182C10.4727 13.0909 10.9091 13.5273 10.9091 14.1819V18.5455C10.9091 19.2 10.4727 19.6364 9.8182 19.6364ZM6.54549 17.4546H8.72732V15.2727H6.54549V17.4546Z"
            fill={fill}
        />
        <path
            d="M18.5454 10.9091H14.1818C13.5273 10.9091 13.0909 10.4727 13.0909 9.8182V5.45457C13.0909 4.80002 13.5273 4.36365 14.1818 4.36365H18.5454C19.2 4.36365 19.6364 4.80002 19.6364 5.45457V9.8182C19.6364 10.4727 19.2 10.9091 18.5454 10.9091ZM15.2727 8.72728H17.4546V6.54544H15.2727V8.72728Z"
            fill={fill}
        />
        <path
            d="M14.1818 16.3636C13.5273 16.3636 13.0909 15.9272 13.0909 15.2727V14.1817C13.0909 13.5272 13.5273 13.0908 14.1818 13.0908C14.8363 13.0908 15.2727 13.5272 15.2727 14.1817V15.2727C15.2727 15.9272 14.8363 16.3636 14.1818 16.3636Z"
            fill={fill}
        />
        <path
            d="M18.5454 15.2728H17.4545C16.8 15.2728 16.3636 14.8364 16.3636 14.1819C16.3636 13.5273 16.8 13.0909 17.4545 13.0909H18.5454C19.2 13.0909 19.6363 13.5273 19.6363 14.1819C19.6363 14.8364 19.2 15.2728 18.5454 15.2728Z"
            fill="#007FA3"
        />
        <path
            d="M18.5455 19.6364H15.2727C14.6182 19.6364 14.1818 19.2001 14.1818 18.5455C14.1818 17.891 14.6182 17.4546 15.2727 17.4546H18.5455C19.2 17.4546 19.6364 17.891 19.6364 18.5455C19.6364 19.2001 19.2 19.6364 18.5455 19.6364Z"
            fill="#007FA3"
        />
    </svg>
);

export default QRCodesIcon;
