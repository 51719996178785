import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { deleteWeekly, getWeeklyPlan } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* deleteWeeklySaga({ payload }: ReturnType<typeof deleteWeekly>): SagaIterator {
    try {
        const { week, year, classId } = payload;
        yield put(startLoading());
        yield call(API.delete(), `/staff/course-planner/${classId}/${year}/${week}`);
        yield put(
            getWeeklyPlan({
                week,
                year,
                id: classId,
            }),
        );
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
