import { createReducer } from '@reduxjs/toolkit';
import { PUT_SEARCH_VALUE } from './actions';

export type SearchState = {
    searchValue: string;
};

const initialState = {
    searchValue: '',
};

export const searchReducer = createReducer<SearchState>(initialState, {
    [PUT_SEARCH_VALUE]: (state, action) => {
        state.searchValue = action.payload.searchValue;
    },
});
