import { useWindowSize } from 'src/hooks/useWindowSize';
import './styles.scss';
import { Props } from './types';

const MobileControls = ({ children }: Props) => {
    const { isDesktop } = useWindowSize();

    if (isDesktop) {
        return null;
    }

    return (
        <div className="mobileControls" id="mobileControls" data-testid="mobileControls">
            {children}
        </div>
    );
};

export default MobileControls;
