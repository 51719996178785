import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'src/api';
import Search from 'src/components/Search';
import { useSearchParams } from 'react-router-dom';
import TableLight from 'src/pages/Profile/ProfileInfo/TableLight';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { appConstants } from 'src/constants';
import { ElearningProps } from '../ELearning/types';

import './styles.scss';

const Flashpoint = ({ setUpdateLesson, updateLesson }: ElearningProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [flashpoints, setFlashpoints] = useState(
        [] as unknown as {
            date: string;
            description: string;
            domain: string;
            ease: string;
            headline: string;
            number: number;
        }[],
    );
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const isCurrentLessonAdded = (num: number) => {
        return updateLesson?.activities?.map(({ id }: any) => id).includes(num);
    };

    useEffect(() => {
        dispatch(startLoading());
        API.get()(`/staff/flash-points`)
            .then((data) => {
                setFlashpoints(data.data.flashPoints);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    }, []);

    return (
        <div className="flaspoints">
            <Search
                defaultValue={(searchParams.get('searchTerm') || '').replace(/_/g, ' ')}
                onPushEnter={() => {}}
                onClearButton={() => {}}
                alwaysWide
                onChange={(text: string) => {
                    setSearch(text);
                }}
            />

            <TableLight
                className="flaspoints-table"
                header={
                    <>
                        <span className="flaspoints-table-head flaspoints-table-no">
                            {t('homework.flaspoints.no')}
                        </span>
                        <span className="flaspoints-table-head flaspoints-table-title">
                            {t('homework.flaspoints.title')}
                        </span>
                        <span className="flaspoints-table-head flaspoints-table-domain">
                            {t('homework.flaspoints.domain')}
                        </span>
                        <span className="flaspoints-table-head flaspoints-table-date">
                            {t('homework.flaspoints.date')}
                        </span>
                        <span className="flaspoints-table-head flaspoints-table-ease">
                            {t('homework.flaspoints.ease')}
                        </span>
                        <span className="flaspoints-table-head flaspoints-table-actions">
                            {t('homework.flaspoints.actions')}
                        </span>
                    </>
                }
                rows={
                    flashpoints
                        ?.filter(({ description, domain, ease, headline }) => {
                            return `${description} ${domain} ${ease} ${headline}`
                                .toLowerCase()
                                .includes(search);
                        })
                        .map((flashpoint: any, i: number) => (
                            <div
                                key={i}
                                className={classNames('flaspoints-table-row', {
                                    'flaspoints-table-row--extraWidth': flashpoints.length > 6,
                                })}
                            >
                                <span className="flaspoints-table-no">{i + 1}.</span>
                                <div className="flaspoints-table-title">
                                    <b>{flashpoint.headline}</b>
                                    <span
                                        dangerouslySetInnerHTML={{ __html: flashpoint.description }}
                                    />
                                </div>

                                <span className="flaspoints-table-domain">{flashpoint.domain}</span>
                                <span className="flaspoints-table-date">{flashpoint.date}</span>
                                <span
                                    className="flaspoints-table-ease"
                                    dangerouslySetInnerHTML={{ __html: flashpoint.ease }}
                                />
                                <div className="flaspoints-table-actions">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                `${appConstants.frontend}/flashpoint/${flashpoint.number}`,
                                                '_blank',
                                            );
                                        }}
                                    >
                                        {t('homework.flaspoints.view')}
                                    </span>
                                    <span
                                        onClick={() => {
                                            window.open(
                                                `${appConstants.server}/fpt?id=${flashpoint.number}`,
                                                '_blank',
                                            );
                                        }}
                                    >
                                        {t('homework.flaspoints.notes')}
                                    </span>
                                    {!isCurrentLessonAdded(flashpoint.number) && (
                                        <span
                                            onClick={() => {
                                                const tempAssignment = {
                                                    name: flashpoint.headline,
                                                    type: 'flashPoint',
                                                    id: flashpoint.number,
                                                    currentlyAdded: true,
                                                    preview: `${appConstants.frontend}/flashpoint/${flashpoint.number}`,
                                                };
                                                setUpdateLesson((data: any) => ({
                                                    ...data,
                                                    activities: [
                                                        ...data.activities,
                                                        tempAssignment,
                                                    ],
                                                }));
                                            }}
                                        >
                                            {t('homework.flaspoints.set')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )) ?? []
                }
            />
        </div>
    );
};

export default Flashpoint;
