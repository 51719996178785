import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { GET_ATTENDANCE_STUDENTS, SAVE_ATTENDANCE } from '../actions';
import { getAttendanceStudentsSaga } from './getAttendanceStudentsSaga';
import { saveAttendanceSaga } from './saveAttendanceSaga';

export function* attendanceWatcher(): SagaIterator {
    yield takeLatest(GET_ATTENDANCE_STUDENTS, getAttendanceStudentsSaga);
    yield takeLatest(SAVE_ATTENDANCE, saveAttendanceSaga);
}
