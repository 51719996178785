import { createReducer } from '@reduxjs/toolkit';
import { PUT_AUTOMATED, PUT_AUTOMATED_TEST } from './actions';
import { AutomatedState } from './types';

const initialState = {
    automated: {
        tests: [],
    },
    test: null,
};

export const automatedReducer = createReducer<AutomatedState>(initialState, {
    [PUT_AUTOMATED]: (state, action) => {
        state.automated = action.payload;
    },
    [PUT_AUTOMATED_TEST]: (state, action) => {
        state.test = action.payload;
    },
});
