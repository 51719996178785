import './styles.scss';
import AllStudentsFilter from 'src/pages/AllStudents/StudentsFilter';
import { Props } from './types';
import Modal from '../../Modal';

const AllStudentsFiltersModal = ({
    open,
    setOpenFilterModal,
    onClose,
    filter,
    setFilter,
}: Props) => {
    const onCloseChild = () => {
        onClose();
    };
    return (
        <>
            <Modal
                modalBackdropClass="all-users-filters-modal-backdrop"
                open={open}
                onClose={onCloseChild}
            >
                <div className="all-students-filter-modal">
                    <AllStudentsFilter
                        filter={filter}
                        setFilter={setFilter}
                        setOpenFilterModal={setOpenFilterModal}
                    />
                </div>
            </Modal>
        </>
    );
};

export default AllStudentsFiltersModal;
