import { Props } from '../types';

const UnmatchedStudentsIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 512 512"
        viewBox="0 0 512 512"
        width={20}
    >
        <g fill={fill}>
            <circle cx="106.7" cy="53.3" r="53.3" />
            <path d="m149.3 320h-64c-23.5 0-42.7-19.2-42.7-42.7v-64c0-23.5 19.2-42.7 42.7-42.7 11.7 0 21.3-9.6 21.3-21.3s-9.5-21.3-21.3-21.3c-47.1 0-85.3 38.2-85.3 85.3v64c0 31.6 17.3 59.1 42.7 73.8v139.5c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-128h42.7v128c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-149.3c.1-11.7-9.5-21.3-21.3-21.3z" />
            <circle cx="405.3" cy="53.3" r="53.3" />
            <path d="m426.7 128c-11.7 0-21.3 9.6-21.3 21.3s9.6 21.3 21.3 21.3c23.5 0 42.7 19.2 42.7 42.7v64c0 23.5-19.2 42.7-42.7 42.7h-64c-11.7 0-21.3 9.6-21.3 21.3v149.3c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-128h42.7v128c0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3v-139.5c25.4-14.7 42.7-42.5 42.7-73.8v-64c0-47.1-38.2-85.3-85.3-85.3z" />
            <path d="m336.4 141.9c-20.7-20.7-49.5-33.6-81.1-33.6-31.6 0-60.4 12.9-81.1 33.6s-33.6 49.5-33.6 81.1c0 31.6 12.9 60.4 33.6 81.1s49.5 33.6 81.1 33.6c31.6 0 60.4-12.9 81.1-33.6s33.6-49.4 33.6-81v-.1c0-31.6-12.9-60.4-33.6-81.1zm-155.7 81.1c0-10.8 2.3-21.5 6.9-31.4s11.4-18.9 20.4-26.3c13.3-10.9 30-16.9 47.2-16.9h.1c12.8 0 25.4 3.4 36.4 9.6l-101.4 101.5c-6.4-11.4-9.6-23.9-9.6-36.5zm121.8 57.7c-13.3 10.9-30 16.9-47.2 16.9h-.1-.1c-12.8 0-25.4-3.4-36.4-9.6l101.4-101.4c6.4 11.4 9.6 24 9.6 36.5 0 10.8-2.3 21.5-6.9 31.4-4.5 9.8-11.3 18.8-20.3 26.2z" />
        </g>
    </svg>
);

export default UnmatchedStudentsIcon;
