const CloseButton3Svg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <g id="Close">
                <path
                    id="Vector"
                    d="M10 8.22222L3.77778 2L2 3.77778L8.22222 10L2 16.2222L3.77778 18L10 11.7778L16.2222 18L18 16.2222L11.7778 10L18 3.77778L16.2222 2L10 8.22222Z"
                    fill="#737373"
                />
            </g>
        </svg>
    );
};

export default CloseButton3Svg;
