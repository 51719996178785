import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import WhiteBox from 'src/components/WhiteBox';
import { finishLoading } from 'src/redux/loading/actions';
import { putAssigments, putElearning } from 'src/redux/weeklyPlan/actions';
import { appConstants } from 'src/constants';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { selectLayout } from 'src/redux/layout/selectors';

import './styles.scss';

const RESOURCE = 'resource';
const WRITING = 'writing';

const NewAssigment = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const classData = useSelector(selectClass);
    const state = useLocation().state as any;
    const [tab, setTab] = useState(RESOURCE);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isDesktop } = useWindowSize();
    const location = useLocation();
    const { layout } = useSelector(selectLayout);
    const getIframeSrc = (curTab: any) => {
        switch (curTab) {
            case RESOURCE:
                return `${appConstants.server}/node/add/content_collaborative_page___com?schoolid=1792&embed=2`;
            case WRITING:
                return `${appConstants.server}/administration/writing?embed=2`;
            default:
                return '';
        }
    };

    useEffect(() => {
        if (layout.isIelts) {
            navigate('/');
        }
    }, [layout]);

    const redirectBackToHomework = (e: any) => {
        ((curEvent: any) => {
            if (curEvent.data === 'done') {
                const tempState = Object.assign(state ?? {}, {
                    tab: 'assignments',
                    addAssignment: true,
                });
                const isStudent =
                    location.pathname.includes('students') &&
                    !location.pathname.includes('all-students');
                const isAllStudent = location.pathname.includes('all-students');
                const isWeeklyPlan = location.pathname.includes('weekly-plan');
                let url = '';

                if (isStudent) {
                    url = `/students/${params.id}/${params.uid}/assignments`;
                }
                if (isAllStudent) {
                    url = `/all-students/${params.uid}/assignments`;
                }
                if (isWeeklyPlan) {
                    url = `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`;
                }

                navigate(url, { state: tempState });
            }
        }).apply(this, [e]);
    };

    useEffect(() => {
        window.addEventListener('message', redirectBackToHomework);
        dispatch(putElearning({}));
        return () => {
            window.removeEventListener('message', redirectBackToHomework);
        };
    }, []);

    useEffect(() => {
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        return () => {
            dispatch(putAssigments([]));
        };
    }, []);

    useEffect(() => {
        if (params.id && +params.id !== classData?.id) {
            dispatch(
                getClass({
                    classId: params.id,
                    cb: (data: any) => {
                        dispatch(
                            putBreadcrumbs([
                                {
                                    name: `Class ${data.name}`,
                                },
                            ]),
                        );
                    },
                }),
            );
        } else if (params.id && classData?.id) {
            dispatch(
                putBreadcrumbs([
                    {
                        name: `Class ${classData.name}`,
                        link: `/weekly-plan/${params.id}`,
                    },

                    {
                        name: t('homework.assignments'),
                        link: `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`,
                    },

                    {
                        name: t('homework.createAssignment'),
                        link: `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}/assigment`,
                    },
                ]),
            );
        }
    }, [dispatch, params, classData?.id]);

    return (
        <div className="newAssisment">
            {!isDesktop && (
                <div
                    className="homework-backButton pr_hover_color_text_svg_bg"
                    onClick={() => {
                        const tempState = Object.assign(state ?? {}, {
                            tab: 'assignments',
                        });

                        const isStudent =
                            location.pathname.includes('students') &&
                            !location.pathname.includes('all-students');
                        const isAllStudent = location.pathname.includes('all-students');
                        const isWeeklyPlan = location.pathname.includes('weekly-plan');
                        let url = '';

                        if (isStudent) {
                            url = `/students/${params.id}/${params.uid}/assignments`;
                        }
                        if (isAllStudent) {
                            url = `/all-students/${params.uid}/assignments`;
                        }
                        if (isWeeklyPlan) {
                            url = `/weekly-plan/${params.id}/${params.year}/${params.week}/${params.day}`;
                        }

                        navigate(url, { state: tempState });
                    }}
                >
                    <ArrowThin />
                    <span>{t('NewAssigment.addAssignment')}</span>
                </div>
            )}
            <span className="homework-title">{t('homework.createAssignment')}</span>
            <WhiteBox>
                <div className="homework-tabBar">
                    <div className="homework-tabs">
                        <span
                            className={classNames('homework-tab sc_hover_color_text_svg', {
                                'homework-tab--active': tab === RESOURCE,
                            })}
                            onClick={() => setTab(RESOURCE)}
                        >
                            {t('NewAssigment.resourcePage')}
                        </span>
                        <span
                            className={classNames('homework-tab sc_hover_color_text_svg', {
                                'homework-tab--active': tab === WRITING,
                            })}
                            onClick={() => setTab(WRITING)}
                        >
                            {t('NewAssigment.writing')}
                        </span>
                    </div>
                </div>
                <>
                    {[RESOURCE, WRITING].includes(tab) && (
                        <iframe
                            src={getIframeSrc(tab)}
                            id="iframe"
                            title="writing"
                            className="newAssisment-iFrame"
                            onLoad={() => dispatch(finishLoading())}
                        />
                    )}
                </>
            </WhiteBox>
        </div>
    );
};

export default NewAssigment;
