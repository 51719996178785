import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { getTimezoneForRequest } from 'src/helpers/getTimezoneForRequest';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getProgress, updateProgress } from '../actions';

export function* updateProgressSaga({ payload }: ReturnType<typeof updateProgress>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(
            API.put(),
            `/staff/classes/${payload.classId}/progress-tests/assigned/${
                payload.testId
            }?${getTimezoneForRequest()}`,
            {
                from: payload.from,
                to: payload.to,
            },
        );
        yield put(
            getProgress({
                classId: payload.classId,
                type: payload.type,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
