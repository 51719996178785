import { Props } from '../types';

const CalendarX = ({ fill = '#1F2937' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path
            d="M13 3H3C2.72386 3 2.5 3.22386 2.5 3.5V13.5C2.5 13.7761 2.72386 14 3 14H13C13.2761 14 13.5 13.7761 13.5 13.5V3.5C13.5 3.22386 13.2761 3 13 3Z"
            stroke={fill}
        />
        <path d="M11 2V4" stroke={fill} />
        <path d="M5 2V4" stroke={fill} />
        <path d="M2.5 6H13.5" stroke={fill} />
        <path d="M9.5 8.5L6.5 11.5" stroke={fill} />
        <path d="M9.5 11.5L6.5 8.5" stroke={fill} />
    </svg>
);

export default CalendarX;
