import { Props } from '../types';

const EyeSlashSvg = ({ fill = 'black' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
            d="M6 5L26 27"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.3625 19.7C18.4449 20.5406 17.2444 21.0048 16 21C14.9908 20.9999 14.0053 20.6945 13.173 20.1238C12.3407 19.5531 11.7006 18.7439 11.3368 17.8026C10.973 16.8613 10.9025 15.8319 11.1347 14.8498C11.3668 13.8677 11.8907 12.9788 12.6375 12.3"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.25 8.57495C4.15 11.15 2 16 2 16C2 16 6 25 16 25C18.3432 25.019 20.6572 24.4791 22.75 23.425"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.075 21.1375C28.8 18.7 30 16 30 16C30 16 26 7.00003 16 7.00003C15.133 6.99832 14.2675 7.0694 13.4125 7.21253"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.9375 11.0875C18.0008 11.289 18.9697 11.8309 19.6981 12.6313C20.4264 13.4318 20.8746 14.4475 20.975 15.525"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default EyeSlashSvg;
