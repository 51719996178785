import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { getUnassigned, putUnassigned } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* getUnassignedSaga({ payload }: ReturnType<typeof getUnassigned>): SagaIterator {
    try {
        yield put(putUnassigned(payload));
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
