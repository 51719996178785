import { useScrollbar } from 'src/hooks/useScrollbar';
import { useTranslation } from 'react-i18next';
import {
    deleteStudentMarking,
    deleteStudentProgressTest,
    deleteStudentProgressTestResult,
    deleteStudentUnitTestResult,
    getStudentMarking,
    getStudentTests,
} from 'src/redux/students/actions';
import ActionText from 'src/components/ActionText';
import { appConstants } from 'src/constants';

export const useAddLeftBorderToTableHeader = () => {
    const tableParentScrollbar = useScrollbar();

    const addScroll = () => {
        const tableRowsScrollWidht = tableParentScrollbar.scrollbarWidth(
            document.querySelector('.profileInfoTableModal-table'),
        );

        if (tableRowsScrollWidht) {
            document
                .querySelector('.profileInfoTableModal .table-header')
                ?.setAttribute(
                    'style',
                    `border-right:${tableRowsScrollWidht}px solid rgb(var(--mainColor));`,
                );
        }
    };

    return {
        addScroll,
    };
};

export const deleteTestDispatch = (
    result: any,
    params: any,
    setOpenSuccessModal: any,
    dispatch: any,
    t: any,
) => {
    if (result.score && result.type !== 'unit') {
        dispatch(
            deleteStudentProgressTestResult({
                studentId: params.id as string,
                resultTestId: result.id,
                cb: () => {
                    dispatch(
                        getStudentTests({
                            studentId: params.id as string,
                            dataType: 'tests',
                            limit: 4,
                        }),
                    );
                    setOpenSuccessModal(t('profile.progressTestResultDeletedSuccessfully'));
                },
            }),
        );
    } else if (result.score && result.type === 'unit') {
        dispatch(
            deleteStudentUnitTestResult({
                studentId: params.id as string,
                resultTestId: result.id,
                cb: () => {
                    dispatch(
                        getStudentTests({
                            studentId: params.id as string,
                            dataType: 'tests',
                            limit: 4,
                        }),
                    );
                    setOpenSuccessModal(t('profile.progressTestDeletedSuccessfully'));
                },
            }),
        );
    } else {
        dispatch(
            deleteStudentProgressTest({
                studentId: params.id as string,
                testId: result.id,
                cb: () => {
                    dispatch(
                        getStudentTests({
                            studentId: params.id as string,
                            dataType: 'tests',
                            limit: 4,
                        }),
                    );
                    setOpenSuccessModal(t('profile.progressTestDeletedSuccessfully'));
                },
            }),
        );
    }
};

export const profileInfoGetReportBlock = (
    tempResult: any,
    profile: any,
    open: any,
    classesData: any,
    params: any,
) => {
    let result = <></>;
    switch (tempResult.type) {
        case 'progress': {
            const link = `${appConstants.server}/dashboard/61/${params.classId || 0}/0/${
                params.id
            }/0/0/${tempResult.testId}`;
            result = <ActionText onClick={() => window.open(link, '_blank')}>REPORT</ActionText>;
            break;
        }
        case 'unit': {
            const link = `${appConstants.server}/dashboard/${tempResult?.report}/${
                profile.classes.length ? profile?.classes[0]?.id : classesData[0]?.id
            }/0/0/0/${tempResult.exerciseId}`;
            result = <ActionText onClick={() => window.open(link, '_blank')}>REPORT</ActionText>;
            break;
        }
        default:
            result = <div />;
            break;
    }
    return result;
};

export const getReportLink = ({
    id,
    timestamp,
    uid,
    type,
}: {
    id: number;
    timestamp: number;
    uid: number;
    type: 'playlist' | 'exercise' | 'assignment' | 'task' | 'part' | 'flashpoint';
}) => {
    const links = {
        playlist: `${appConstants.server}/administration/assignments/playlistreport/${id}/0/0/0/0/0/${timestamp}/${uid}`,
        exercise: `${appConstants.server}/administration/assignments/playlistreport/0/0/${id}/0/0/0/${timestamp}/${uid}`,
        assignment: `${appConstants.server}/administration/assignments/reportswitchboard/${id}/1/0/${timestamp}`,
        task: `${appConstants.server}/administration/assignments/ielts_report/writing/${id}/0/${timestamp}/${uid}`,
        part: `${appConstants.server}/administration/assignments/ielts_report/speaking/${id}/0/${timestamp}/${uid}`,
        flashpoint: `${appConstants.server}/administration/assignments/playlistreport/0/0/0/0/0/${id}/${timestamp}/${uid}`,
    };
    const result = links[type];

    return result;
};
