import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import Button from 'src/components/Button';
import Heading from 'src/components/Heading';
import InputTextarea from 'src/components/InputTextarea';
import InputTextDropdown from 'src/components/InputTextDropdown';
import InputText from 'src/components/InputText';
import Modal from 'src/components/Modal';
import SelectMenu from 'src/components/SelectMenu';
import { ERRORS } from 'src/constants';
import { NotificationStatusEnum, useNotificationQueue } from 'src/providers/NotificationProvider';
import { getToDoReferences, putToDoReferences, saveToDo } from 'src/redux/toDo/actions';
import { selectToDoReferences } from 'src/redux/toDo/selectors';
import { PutToDoReferencesAP } from 'src/redux/toDo/types';
import { useTranslation } from 'react-i18next';
import { TO_DO_LIST_REF, TO_DO_LIST_SESSION, TO_DO_LIST_TYPE } from './constants';
import { Props, UseLoadReturnProps } from './props';

import './styles.scss';

const ToDoListAddOrEditItemModal = ({ open, onClose, edit }: Props) => {
    const { t } = useTranslation();
    const notificationQueue = useNotificationQueue();
    const dispatch = useDispatch();
    const useLoad = (): UseLoadReturnProps => {
        const loaders = useRef(null as any);
        const start = (loaderName: string) => {
            loaders.current = { ...loaders.current, [loaderName]: true };
        };

        const stop = (loaderName: string) => {
            loaders.current = { ...loaders.current, [loaderName]: false };
        };

        const isLoad = (loaderName: string) => {
            if (loaders.current) {
                return loaders.current[loaderName] ?? false;
            }
            return false;
        };

        return {
            start,
            stop,
            isLoad,
        };
    };
    const load = useLoad();
    const toDoReferences = useSelector(selectToDoReferences);
    const formik = useFormik({
        initialValues: {
            text: '',
            session: '',
            timestamp: '',
            contentId: '',
            contentType: '',
            contentUrl: '',
            toDoId: '',
            sessionName: '',
            contentTypeName: '',
            published: '',
            contentReferenceName: '',
        },
        onSubmit: (values) => {
            let toSave = {
                text: values.text,
                session: values.session,
                toDoId: values.toDoId,
                published: values.toDoId ? values.published : true,
            } as any;

            if (TO_DO_LIST_REF.isReference(values.contentType)) {
                toSave = {
                    ...toSave,
                    content: {
                        type: values.contentType,
                        id: values.contentId,
                    },
                };
            } else if (TO_DO_LIST_REF.isUrl(values.contentType)) {
                toSave = {
                    ...toSave,
                    content: {
                        type: values.contentType,
                        url: values.contentUrl,
                    },
                };
            } else if (TO_DO_LIST_REF.isText(values.contentType)) {
                toSave = {
                    ...toSave,
                    content: null,
                };
            }

            dispatch(
                saveToDo({
                    ...toSave,
                    cb: () => {
                        onClose();
                        notificationQueue.add('ToDoSaved', {
                            message: values.toDoId
                                ? `"${values.sessionName}" ${t(
                                      'toDoList.itemWasSuccessfullyUpdated',
                                  )}`
                                : t('toDoList.newItem', {
                                      sessionName: values.sessionName,
                                  }),
                            status: NotificationStatusEnum.success,
                        });
                    },
                }),
            );
        },
        validationSchema: yup.object().shape({
            text: yup.string().required(ERRORS.required),
            session: yup.string().required(ERRORS.required),
            contentType: yup.string().required(ERRORS.required),
            contentReferenceName: yup.mixed().test({
                message: t('toDoList.referenceNotSelected'),
                test: (value, ctx) => {
                    return !(
                        TO_DO_LIST_REF.isReference(ctx.parent.contentType) &&
                        !toDoReferences.some((ref) => ref.id === ctx.parent.contentId)
                    );
                },
            }),
            contentUrl: yup.string().when('contentType', (contentType, schema, ctx) => {
                if (TO_DO_LIST_REF.isUrl(ctx.parent.contentType)) {
                    return schema.required(ERRORS.required).url(ERRORS.url);
                }
                return schema;
            }),
        }),
        validateOnChange: false,
    });

    const getToDoReferencesDispatch = (
        tempType: string,
        search: string,
        cb?: (data: PutToDoReferencesAP) => void,
    ) => {
        dispatch(
            getToDoReferences({
                type: `${tempType}s`,
                search,
                cb,
            }),
        );
    };

    const getReferencesDebounceFn = React.useRef(
        _.debounce((curType, search, cb) => {
            getToDoReferencesDispatch(curType, search, cb);
        }, 1000),
    ).current;

    useEffect(() => {
        dispatch(putToDoReferences([]));
        if (edit) {
            const getSessionName = (apiSessionName: string) => {
                return (
                    TO_DO_LIST_SESSION(t).find((item) => item.value === apiSessionName)?.name ?? ''
                );
            };
            const getTypeName = (apiType: string) => {
                return TO_DO_LIST_TYPE(t).find((item) => item.value === apiType)?.name ?? '';
            };
            formik.setValues({
                text: edit.text,
                session: edit.session,
                timestamp: edit.timestamp,
                contentId: edit.content?.id,
                contentType: edit.content?.type,
                contentUrl: edit.content?.url,
                toDoId: edit.id,
                sessionName: getSessionName(edit.session),
                contentTypeName: getTypeName(edit.content.type),
                published: edit.published,
                contentReferenceName: '',
            });
            if (
                typeof edit.content?.id === 'number' &&
                TO_DO_LIST_REF.isReference(edit.content.type)
            ) {
                load.start('contentReferenceName');
                getToDoReferencesDispatch(edit.content.type, '', (data: PutToDoReferencesAP) => {
                    const item = data.find((ref) => ref.id === edit.content.id);
                    const titleUpdated = formik.setFieldValue('contentReferenceName', item?.title);
                    titleUpdated.then(() => {
                        formik.validateField('contentReferenceName');
                    });

                    load.stop('contentReferenceName');
                });
            }
        }
        return () => {
            formik.resetForm();
        };
    }, [open]);

    useEffect(() => {
        if (formik.dirty) {
            formik.validateForm();
        }
    }, [formik.values, formik.dirty, formik.validateForm]);

    const getError = (inputName: keyof (typeof formik)['initialValues']) => {
        return formik.errors[inputName] && formik.touched[inputName]
            ? formik.errors[inputName]
            : '';
    };

    return (
        <Modal overflowEnable noPadding open={open} onClose={onClose}>
            <form onSubmit={formik.handleSubmit} className="toDoListAddItemModal">
                <Heading type="modal-title-1">{t('toDoList.addItem')}</Heading>
                <div>
                    <div className="toDoListAddItemModal-inputs">
                        <div className="toDoListAddItemModal-input">
                            <div className="toDoListAddItemModal-inputs__title">
                                {t('toDoList.chooseSession')}
                            </div>
                            <SelectMenu
                                error={getError('session')}
                                inputName="session"
                                alwaysWide
                                wrapperClass="type_3"
                                options={TO_DO_LIST_SESSION(t).map((item: any) => {
                                    return {
                                        content: (
                                            <div title={item.name}>
                                                <span>{item.name}</span>
                                            </div>
                                        ),
                                        onClick: () => {
                                            formik.setFieldValue('session', item.value);
                                            formik.setFieldValue('sessionName', item.name);
                                        },
                                    };
                                })}
                                text={formik.values.sessionName}
                            />
                        </div>
                        <div className="toDoListAddItemModal-input">
                            <div className="toDoListAddItemModal-inputs__title">
                                {t('toDoList.chooseType')}
                            </div>
                            <SelectMenu
                                error={getError('contentType')}
                                inputName="contentType"
                                alwaysWide
                                wrapperClass="type_3"
                                options={TO_DO_LIST_TYPE(t).map((item) => {
                                    return {
                                        content: (
                                            <div title={item.name}>
                                                <span>{item.name}</span>
                                            </div>
                                        ),
                                        onClick: () => {
                                            dispatch(putToDoReferences([]));
                                            if (TO_DO_LIST_REF.isReference(item.value)) {
                                                load.start('contentReferenceName');
                                                getToDoReferencesDispatch(item.value, '', () => {
                                                    load.stop('contentReferenceName');
                                                });
                                            }
                                            formik.setFieldValue('contentId', '');
                                            formik.setFieldValue('contentType', item.value);
                                            formik.setFieldValue('contentTypeName', item.name);
                                        },
                                    };
                                })}
                                text={formik.values.contentTypeName}
                            />
                        </div>
                        <div
                            className={classNames('toDoListAddItemModal-input', {
                                'd-none': !TO_DO_LIST_REF.isReference(formik.values.contentType),
                            })}
                        >
                            <div className="toDoListAddItemModal-inputs__title">
                                {t('toDoList.reference')}
                            </div>
                            <InputTextDropdown
                                onSelect={(inputValue, inputDropdownValue) => {
                                    formik.setFieldValue('contentReferenceName', inputValue);
                                    formik.setFieldValue('contentId', inputDropdownValue);
                                }}
                                error={getError('contentReferenceName')}
                                value={formik.values.contentReferenceName}
                                load={load.isLoad('contentReferenceName')}
                                dropdownMaxHeight={243}
                                onChange={(inputValue) => {
                                    formik.setFieldValue('contentReferenceName', inputValue);
                                    load.start('contentReferenceName');
                                    getReferencesDebounceFn(
                                        formik.values.contentType,
                                        inputValue,
                                        () => {
                                            load.stop('contentReferenceName');
                                        },
                                    );
                                }}
                                options={toDoReferences}
                                fullWidth
                            />
                        </div>
                        <div
                            className={classNames('toDoListAddItemModal-input', {
                                'd-none': !TO_DO_LIST_REF.isUrl(formik.values.contentType),
                            })}
                        >
                            <div className="toDoListAddItemModal-inputs__title">
                                {t('toDoList.URL')}
                            </div>
                            <InputText
                                inputProps={{ defaultValue: formik.values.contentUrl }}
                                error={getError('contentUrl')}
                                onChange={(inputValue) =>
                                    formik.setFieldValue('contentUrl', inputValue)
                                }
                                fullWidth
                            />
                        </div>
                        <div className="toDoListAddItemModal-input">
                            <div className="toDoListAddItemModal-inputs__title">
                                {t('toDoList.description')}
                            </div>
                            <InputTextarea
                                defaultValue={formik.values.text}
                                error={getError('text')}
                                onChange={(inputValue) => formik.setFieldValue('text', inputValue)}
                            />
                        </div>
                    </div>
                </div>
                <div className="toDoListAddItemModal-action-buttons">
                    <Button type="quaternary" onClick={onClose}>
                        {t('general.cancel')}
                    </Button>
                    <Button
                        submit
                        disabled={!(formik.isValid && formik.dirty)}
                        type="quaternary-new"
                    >
                        {t('general.save')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default ToDoListAddOrEditItemModal;
