import { createReducer } from '@reduxjs/toolkit';
import { PUT_PROGRESS_TESTS, PUT_STAFF_EMAILS_LOG, PUT_STAFF_REPORTS } from './actions';

export type EmailLogType = {
    email: string;
    receiver: string;
    sender: string;
    status: boolean;
    subject: string;
    timestamp: number;
};

export type StaffReportType = {
    desktop: string;
    mobile: string;
    name: string;
};

export type StaffProgressTest = {
    id: string;
    name: string;
};
export type StaffReportsState = {
    staffReports: StaffReportType[];
    staffEmailsLog: EmailLogType[];
    progressTests: StaffProgressTest[];
};
const initialState = {
    staffReports: [],
    staffEmailsLog: [],
    progressTests: [],
};

export const staffReportsReducer = createReducer<StaffReportsState>(initialState, {
    [PUT_STAFF_REPORTS]: (state, action) => {
        state.staffReports = action.payload;
    },
    [PUT_STAFF_EMAILS_LOG]: (state, action) => {
        state.staffEmailsLog = action.payload;
    },
    [PUT_PROGRESS_TESTS]: (state, action) => {
        state.progressTests = action.payload;
    },
});
