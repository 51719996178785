import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { putApiRequestError } from '../../error/actions';
import { getUsernameAvailability } from '../actions';

export function* getUsernameAvailabilitySaga({
    payload,
}: ReturnType<typeof getUsernameAvailability>): SagaIterator {
    try {
        const data = yield call(API.post(), `/staff/users/username-availability`, {
            username: payload.username,
        });
        if (payload.cb) {
            payload.cb(data);
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
