import classNames from 'classnames';
import AssessmentsIcon from 'src/components/Icons/AssessmentsIcon';
import Remove from 'src/components/Icons/Remove';
import { MobileRowProps } from '../types';
import RangeSelect from '../RangeSelect';
import { defaultInputValue } from '../defaultInputValue';

const MobileRow = ({
    text,
    dataToSave,
    student,
    currentCategory,
    onSelect,
    openModal,
    isAlreadyCommented,
    showEmpty,
    value,
    disabled,
    clickOnRemove,
}: MobileRowProps) => {
    return (
        <div
            className={classNames('assessments-mobile-row', {
                disabled,
            })}
        >
            <div className="assessments-mobile-row-title">
                <b>{text}</b>
            </div>
            <div className="assessments-mobile-row-content">
                <RangeSelect
                    value={value}
                    showEmpty={showEmpty}
                    currentCategory={currentCategory}
                    dataToSave={dataToSave}
                    onSelect={onSelect}
                    student={student}
                    disabled={disabled}
                />
                {defaultInputValue(dataToSave, currentCategory, student) !== '' && (
                    <>
                        <div
                            onClick={() => {
                                openModal();
                            }}
                            className="assessments-info-comment sc_hover_color_text_svg"
                            title={isAlreadyCommented}
                        >
                            <AssessmentsIcon fill={isAlreadyCommented ? '#0B80A4' : '#54585a'} />
                        </div>
                        <div
                            onClick={clickOnRemove}
                            className="assessments-info-comment sc_hover_color_text_svg"
                        >
                            <Remove />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MobileRow;
