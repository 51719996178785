import classNames from 'classnames';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './styles.scss';

const CustomLinkOverloaded = ({
    parentProps,
    linkProps,
    children,
}: {
    parentProps: React.HTMLAttributes<HTMLDivElement>;
    linkProps: LinkProps & React.RefAttributes<HTMLAnchorElement>;
    children: React.ReactNode;
}) => {
    return (
        <div {...parentProps} className={classNames('custom-link-parent', parentProps.className)}>
            <Link {...linkProps} className={classNames('custom-link', linkProps.className)}>
                {children}
            </Link>
        </div>
    );
};

export default CustomLinkOverloaded;
