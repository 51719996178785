import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import './styles.scss';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { getStudentAudit } from 'src/redux/students/actions';
import { selectStudentAudit } from 'src/redux/students/selectors';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { useAddLeftBorderToTableHeader } from '../../helpers';

const ProfileInfoAuditLogModal = ({ open, onClose }: { open: any; onClose: any }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectStudentAudit);
    const { addScroll } = useAddLeftBorderToTableHeader();

    useEffect(() => {
        if (open) {
            dispatch(getStudentAudit({ studentId: open, dataType: 'audit' }));
        }
    }, [open]);

    useEffect(() => {
        addScroll();
    }, [items]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal open={open} onClose={() => onClose()} noPadding>
            <div className="profileInfoTableModal auditLog">
                <div className="profileInfoTableModal-title">{t('profile.auditLog')}</div>
                <Table
                    tableRowsClassName="profileInfoTableModal-table"
                    header={
                        <>
                            <span>{t('profile.username')}</span>
                            <span>{t('general.action')}</span>
                            <span>{t('emails.date')}</span>
                        </>
                    }
                    rows={items.map((oneResult: any) => {
                        return (
                            <>
                                <span title={getNoEmpty(oneResult.username)}>
                                    {getNoEmpty(oneResult.username)}
                                </span>
                                <span title={getNoEmpty(oneResult.action)}>
                                    {getNoEmpty(oneResult.action)}
                                </span>
                                <span title={moment.unix(oneResult.timestamp).format()}>
                                    {moment.unix(oneResult.timestamp).format()}
                                </span>
                            </>
                        );
                    })}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoAuditLogModal;
