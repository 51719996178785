import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAllToDo, putAllToDo } from '../actions';

export function* getAllToDoSaga({ payload }: ReturnType<typeof getAllToDo>): SagaIterator {
    try {
        yield put(startLoading());
        let data = yield call(API.get(), '/staff/todo');
        data = data.map((item: any) => {
            if (!item.content) {
                return { ...item, content: { type: 'text' } };
            }
            return item;
        });
        yield put(putAllToDo(data));
        if (payload) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
