export const allStudentsAreInSchoolFilterItems = (t: (key: string) => string) => [
    { name: t('allStudents.areInSchool'), id: 'in_school' },
    { name: t('allStudents.startSchool'), id: 'start_school' },
    { name: t('allStudents.endSchool'), id: 'end_school' },
    { name: t('allStudents.created'), id: 'created' },
];

export const allStudentsSchoolFilterItems = (t: (key: string) => string) => [
    { id: 'gid', name: t('general.mySchool') },
    { id: 'all', name: t('general.allSchools') },
];

export const allStudentsStudentFilterItems = (t: (key: string) => string) => [
    { name: t('general.active'), id: true },
    { name: t('general.cancelled'), id: false },
];

export const allStudentsDefaultClass = (t: (key: string) => string) => [
    { id: null, name: t('general.all') },
];
