const Hyperlink = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10.002" viewBox="0 0 10 10.002">
        <g id="Link" transform="translate(5 5.001)">
            <g id="Group_958" data-name="Group 958" transform="translate(-5 -5.001)">
                <path
                    id="Path_2263"
                    data-name="Path 2263"
                    d="M5.769,183.875l-1.359,1.359a2.1,2.1,0,0,1-2.963-2.962l1.359-1.361a.417.417,0,0,0-.59-.589L.857,181.682A2.929,2.929,0,0,0,5,185.823l1.359-1.359a.417.417,0,0,0-.589-.589h0Z"
                    transform="translate(0 -176.679)"
                    fill="#54585a"
                />
                <path
                    id="Path_2264"
                    data-name="Path 2264"
                    d="M185.786.859A2.908,2.908,0,0,0,183.715,0h0a2.91,2.91,0,0,0-2.07.857l-1.361,1.36a.417.417,0,0,0,.589.59l1.361-1.359a2.081,2.081,0,0,1,1.481-.614h0A2.1,2.1,0,0,1,185.2,4.411L183.836,5.77a.417.417,0,1,0,.59.59h0L185.785,5A2.932,2.932,0,0,0,185.786.859Z"
                    transform="translate(-176.642 0)"
                    fill="#54585a"
                />
                <path
                    id="Path_2265"
                    data-name="Path 2265"
                    d="M173,171.027l-2.5,2.5a.417.417,0,1,0,.579.6l.01-.01,2.5-2.5a.417.417,0,0,0-.589-.589Z"
                    transform="translate(-167.041 -167.571)"
                    fill="#54585a"
                />
            </g>
        </g>
    </svg>
);

export default Hyperlink;
