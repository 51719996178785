const ArrowBoldUp = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.282" height="5.346" viewBox="0 0 9.282 5.346">
        <g id="Arrow_down" data-name="Arrow down" transform="translate(4.641 2.673) rotate(180)">
            <path
                id="Path_688"
                data-name="Path 688"
                d="M.7,0H8.581a.7.7,0,0,1,.493,1.205L5.142,5.138a.7.7,0,0,1-1,0l0,0L.208,1.205A.7.7,0,0,1,.7,0Z"
                transform="translate(-4.641 -2.673)"
                fill="#54585a"
            />
        </g>
    </svg>
);

export default ArrowBoldUp;
