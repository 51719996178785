const Link = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    d="M13.2543 6.06377L14.2855 5.03252C14.6914 4.62654 15.1733 4.30449 15.7036 4.08477C16.234 3.86504 16.8024 3.75195 17.3765 3.75195C17.9505 3.75195 18.519 3.86504 19.0493 4.08477C19.5797 4.30449 20.0615 4.62654 20.4674 5.03252C20.8734 5.43838 21.1954 5.92025 21.4152 6.4506C21.6349 6.98095 21.748 7.54939 21.748 8.12346C21.748 8.69752 21.6349 9.26596 21.4152 9.79631C21.1954 10.3267 20.8734 10.8085 20.4674 11.2144L18.1818 13.5L17.2124 14.4694C16.8061 14.8758 16.3236 15.1981 15.7926 15.4178C15.2615 15.6376 14.6924 15.7505 14.1177 15.75C13.543 15.7496 12.974 15.6359 12.4433 15.4153C11.9126 15.1948 11.4306 14.8717 11.0249 14.4647C10.6049 14.0449 10.2752 13.5437 10.056 12.9919C9.83671 12.44 9.73256 11.8492 9.74991 11.2556"
                    stroke="#54585a"
                    strokeWidth="2"
                />
                <path
                    d="M10.7457 17.9362L9.71448 18.9674C9.30814 19.3738 8.82565 19.6961 8.29463 19.9159C7.7636 20.1356 7.19446 20.2485 6.61976 20.2481C6.04506 20.2477 5.47609 20.1339 4.94539 19.9134C4.4147 19.6928 3.9327 19.3698 3.52698 18.9628C2.70947 18.1424 2.2509 17.0311 2.25195 15.873C2.25301 14.7148 2.7136 13.6044 3.5326 12.7856L6.7876 9.53057C7.19347 9.12458 7.67534 8.80253 8.20569 8.58281C8.73604 8.36309 9.30448 8.25 9.87854 8.25C10.4526 8.25 11.021 8.36309 11.5514 8.58281C12.0817 8.80253 12.5636 9.12458 12.9695 9.53057C13.3912 9.95034 13.7224 10.4521 13.9426 11.0048C14.1629 11.5576 14.2675 12.1496 14.2501 12.7443"
                    stroke="#54585a"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};
export default Link;
