import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import {
    getStudentAudit,
    putStudentAudit,
    putStudentEmails,
    putStudentMarking,
    putStudentTests,
    putStudentTutorials,
    putStudentUpgrades,
} from '../actions';

export function* getStudentDataSaga({ payload }: ReturnType<typeof getStudentAudit>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/students/${payload.studentId}/${payload.dataType}${
                payload.limit ? `?limit=${payload.limit}` : ''
            }`,
        );
        switch (payload.dataType) {
            case 'audit':
                yield put(putStudentAudit(data));
                break;
            case 'emails':
                yield put(putStudentEmails(data));
                break;
            case 'tests':
                yield put(putStudentTests(data));
                break;
            case 'tutorials':
                yield put(putStudentTutorials(data));
                break;
            case 'ielts/markings':
                yield put(putStudentMarking(data));
                break;
            case 'ielts/upgrades':
                yield put(putStudentUpgrades(data));
                break;
            default:
                break;
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
