import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { selectLayout } from 'src/redux/layout/selectors';
import { getStudents, putStudents } from '../actions';

export function* getStudentsSaga({ payload }: ReturnType<typeof getStudents>): SagaIterator {
    try {
        yield put(startLoading());
        const { layout } = yield select(selectLayout);
        let link = `/staff/classes/${payload.classId}/students`;
        if (layout.isIelts) {
            link = `/staff/classes/${payload.classId}/students?type=ielts`;
        }
        const data = yield call(API.get(), link);
        yield put(putStudents(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
