import { createAction } from '@reduxjs/toolkit';
import { GetReports, PutReports, CourseReport } from './types';

export const GET_REPORTS = 'GET_REPORTS';
export const PUT_REPORTS = 'PUT_REPORTS';

export const getReports = createAction<GetReports>(GET_REPORTS);
export const putReports = createAction<PutReports>(PUT_REPORTS);

export const GET_STUDENT_REPORT = 'GET_STUDENT_REPORT';
export const PUT_STUDENT_REPORT = 'PUT_STUDENT_REPORT';

export const getStudentReport = createAction<any>(GET_STUDENT_REPORT);
export const putStudentReport = createAction<any>(PUT_STUDENT_REPORT);

export const GET_COUTSE_REPORT = 'GET_COUTSE_REPORT';
export const PUT_COUTSE_REPORT = 'PUT_COUTSE_REPORT';

export const getCourseReport = createAction<{
    classId: number | string;
    from: number;
    to: number;
    cb: ({ tags, homework }: CourseReport) => void;
}>(GET_COUTSE_REPORT);
export const putCourseReport = createAction<CourseReport>(PUT_COUTSE_REPORT);
