import { createReducer } from '@reduxjs/toolkit';
import {
    PUT_QR_CODE,
    PUT_QR_CODES,
    PUT_STUDENT_AND_TEACHER_QR_CODE,
    RESET_STUDENT_AND_TEACHER_QR_CODE,
} from './actions';
import { QrCode } from './types';

export type QrCodesReducerState = {
    qrCodes: QrCode[];
    studentAndTeacherQrCode: {
        student: QrCode;
        teacher: QrCode;
    };
};

const initialState = {
    qrCodes: [],
    studentAndTeacherQrCode: {
        student: {} as QrCode,
        teacher: {} as QrCode,
    },
};

export const qrCodesReducer = createReducer<QrCodesReducerState>(initialState, {
    [PUT_QR_CODES]: (state, action) => {
        state.qrCodes = action.payload;
    },
    [PUT_QR_CODE]: (state, action) => {
        state.qrCodes = state.qrCodes.map((qrCode) => {
            if (qrCode.id === action.payload.id) {
                return action.payload;
            }

            return qrCode;
        });
    },
    [PUT_STUDENT_AND_TEACHER_QR_CODE]: (state, action) => {
        state.studentAndTeacherQrCode = action.payload;
    },
    [RESET_STUDENT_AND_TEACHER_QR_CODE]: (state, action) => {
        state.studentAndTeacherQrCode = { student: {} as QrCode, teacher: {} as QrCode };
    },
});
