import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import Modal from '../../Modal';
import './styles.scss';
import { ConfirmModalProps } from './types';
import { successCheckboxLottie } from '../../../assets/lottie/success-checkbox';

const ConfirmModal = ({
    text,
    onClick,
    onClose,
    open,
    modalBackdropClass,
    success,
    isNewDesign,
}: ConfirmModalProps) => {
    const { t } = useTranslation();

    if (isNewDesign) {
        return (
            <>
                <Modal
                    open={open}
                    onClose={onClose}
                    modalBackdropClass={modalBackdropClass}
                    isNewDesign
                    header={<span>Delete</span>}
                    footer={
                        success ? (
                            <>
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    {t('general.OK')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="square"
                                    onClick={() => {
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    {t('general.cancel')}
                                </Button>
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        if (onClick) {
                                            onClick();
                                        }
                                    }}
                                >
                                    {t('general.OK')}
                                </Button>
                            </>
                        )
                    }
                >
                    <div className="confirm-modal-new">
                        {success ? (
                            <>
                                <div className="confirmModal__lottie">
                                    <Lottie options={{ animationData: successCheckboxLottie }} />
                                </div>
                                <span>{success}</span>
                            </>
                        ) : (
                            <>
                                <span dangerouslySetInnerHTML={{ __html: text }} />
                                <span>Please confirm.</span>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }

    return (
        <>
            <Modal open={open} onClose={onClose} modalBackdropClass={modalBackdropClass}>
                <div className="confirm-modal">
                    {success ? (
                        <>
                            <div className="confirmModal__lottie">
                                <Lottie options={{ animationData: successCheckboxLottie }} />
                            </div>
                            <span className="confirm-modal-title">{success}</span>
                        </>
                    ) : (
                        <span
                            className="confirm-modal-title"
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                    <div className="confirm-modal-button">
                        {success ? (
                            <>
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    {t('general.OK')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                >
                                    {t('general.no')}
                                </Button>
                                <Button
                                    type="square"
                                    onClick={() => {
                                        if (onClick) {
                                            onClick();
                                        }
                                    }}
                                >
                                    {t('general.yes')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ConfirmModal;
