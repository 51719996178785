const SearchIconNew = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.3138 10.0595H10.6555L10.4221 9.83452C11.4221 8.66786 11.9388 7.07619 11.6555 5.38452C11.2638 3.06786 9.33046 1.21786 6.99713 0.934522C3.47213 0.501189 0.505461 3.46786 0.938794 6.99286C1.22213 9.32619 3.07213 11.2595 5.38879 11.6512C7.08046 11.9345 8.67213 11.4179 9.83879 10.4179L10.0638 10.6512V11.3095L13.6055 14.8512C13.9471 15.1929 14.5055 15.1929 14.8471 14.8512C15.1888 14.5095 15.1888 13.9512 14.8471 13.6095L11.3138 10.0595ZM6.31379 10.0595C4.23879 10.0595 2.56379 8.38452 2.56379 6.30952C2.56379 4.23452 4.23879 2.55952 6.31379 2.55952C8.38879 2.55952 10.0638 4.23452 10.0638 6.30952C10.0638 8.38452 8.38879 10.0595 6.31379 10.0595Z"
            fill="black"
        />
    </svg>
);

export default SearchIconNew;
