import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { urlQueryFromObject } from 'src/helpers/queryFromObject';
import { selectLayout } from 'src/redux/layout/selectors';
import { putAllStudents } from '../actions';
import { allStudentsInitialState } from '../reducer';

export function* getAllStudentsSaga({ payload }: ReturnType<any>): SagaIterator {
    try {
        const { layout } = yield select(selectLayout);
        let queryObj = payload;
        if (layout.isIelts) {
            queryObj = {
                ...queryObj,
                type: 'ielts',
            };
        }
        const query = urlQueryFromObject(queryObj);
        yield put(startLoading());
        const data = yield call(API.get(), `/staff/students?${query}`);
        if (data.data) {
            yield put(putAllStudents(data));
        } else {
            yield put(putAllStudents(allStudentsInitialState.allStudents));
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
