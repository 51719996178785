import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getStudents, saveStudentComment } from '../actions';

export function* saveStudentCommentSaga({
    payload,
}: ReturnType<typeof saveStudentComment>): SagaIterator {
    try {
        yield put(startLoading());
        if (payload.classId === undefined) {
            yield call(API.post(), `/staff/students/${payload.uid}/note`, {
                note: payload.note,
            });
            if (payload.cb) {
                payload.cb();
            }
        } else {
            yield call(
                API.post(),
                `/staff/classes/${payload.classId}/students/${payload.uid}/note`,
                {
                    note: payload.note,
                },
            );
            yield put(
                getStudents({
                    classId: payload.classId,
                }),
            );
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
