import classNames from 'classnames';
import _, { range } from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Button from 'src/components/Button';
import HeaderButton from 'src/components/HeaderButton';
import CloseButtonSvg from 'src/components/Icons/CloseButtonSvg';
import Pencil from 'src/components/Icons/Pencil';
import Remove from 'src/components/Icons/Remove';
import Save from 'src/components/Icons/Save';
import InputCalendar from 'src/components/InputCalendar';
import InputTextOld from 'src/components/InputTextOld';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import SuccessModal from 'src/components/Modals/SuccessModal';
import SelectMenu from 'src/components/SelectMenu';
import WhiteBox from 'src/components/WhiteBox';
import { getInlineLangLevel } from 'src/helpers/getInlineLangLevel';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { validateValue } from 'src/helpers/validateValue';
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt';
import useInputProcessor from 'src/hooks/useInputProcessor';
import { profileGetExclamationIcon } from 'src/pages/Profile/profileGetExclamationIcon';
import { getSchools } from 'src/redux/schools/actions';
import { selectSchools } from 'src/redux/schools/selectors';
import { selectLayout } from 'src/redux/layout/selectors';
import { resetPassword, updateStudent } from 'src/redux/students/actions';
import { PutStudentProps, UpdateStudentAP } from 'src/redux/students/types';
import { getMailAvailability, getUsernameAvailability } from 'src/redux/users/actions';
import { useTranslation } from 'react-i18next';
import ProfileEditAssignClass from './ProfileEditClassActions/ProfileEditAssignClass';
import ProfileEditChangeClass from './ProfileEditClassActions/ProfileEditChangeClass';
import './styles.scss';
import { Modules, useIsModuleActive } from '../../../hooks/useIsModuleActive';

const ProfileEdit = ({ profile }: { profile: PutStudentProps }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [successUpdateStudent, setSuccessUpdateStudent] = useState<PutStudentProps>();
    const [inputs, setInputs] = useState({ values: [], errors: [], loading: [] });
    const inputProcessor = useInputProcessor({ inputs, setInputs });
    const [openAssignClass, setOpenAssignClass] = useState(false);
    const [openResetStudyPlan, setOpenResetStudyPlan] = useState<PutStudentProps | null>();
    const params = useParams();
    const [successResetPassword, setSuccessResetPassword] = useState<string | null>(null);
    const [successResetStudyPlan, setSuccessResetStudyPlan] = useState<PutStudentProps | null>();
    const [, setSearchParams] = useSearchParams();
    const [editProfileClass, setEditProfileClass] = useState<{
        data: any;
        index: number;
        id?: string;
    }>();
    const [openResetPassword, setOpenResetPassword] = useState(null as any);
    const [openDeleteClass, setOpenDeleteClass] = useState(null as any);
    const [initialMailValue, setInitialMailValue] = useState<string>('');
    const { layout } = useSelector(selectLayout);
    const dispatch = useDispatch();
    const { isModuleActive } = useIsModuleActive();
    const isStudentProfileFixedEndAccessActive = isModuleActive(
        Modules.StudentProfileFixedEndAccess,
    );
    const diffBetweenStartAndEnd = moment
        .unix(inputProcessor.getValue('endAccess'))
        .diff(moment.unix(inputProcessor.getValue('startDate')), 'month', true);

    useEffect(() => {
        inputProcessor.updateInput(profile);
    }, [profile]);

    const schools = useSelector(selectSchools);
    useEffect(() => {
        dispatch(getSchools());
    }, []);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty) as any;
    const { t } = useTranslation();

    const getDate = (attr: string) => {
        return inputProcessor.getValue(attr)
            ? new Date(inputProcessor.getValue(attr) * 1000)
            : new Date();
    };

    useEffect(() => {
        if (JSON.stringify({ ...inputProcessor.values }) !== JSON.stringify(profile)) {
            setIsDirty(true);
        }
    }, [inputProcessor.values]);

    const [userNameCheckError, setUserNameCheckError] = useState<string | null>(null);
    useEffect(() => {
        inputProcessor.updateError({ username: userNameCheckError });
    }, [userNameCheckError]);

    const [mailCheckError, setMailCheckError] = useState(false as any);
    useEffect(() => {
        inputProcessor.updateError({ mail: mailCheckError });
        inputProcessor.stopLoading('mail');
    }, [mailCheckError]);

    useEffect(() => {
        const mailProcessorValue = inputProcessor.getValue('mail');

        if (mailProcessorValue && !initialMailValue) {
            setInitialMailValue(mailProcessorValue);
        }
    }, [inputProcessor.getValue('mail')]);

    const checkUserNameDebounce = React.useRef(
        _.debounce((checkUserNameInputProcessor) => {
            dispatch(
                getUsernameAvailability({
                    username: checkUserNameInputProcessor.getValue('username'),
                    cb: (data) => {
                        checkUserNameInputProcessor.stopLoading('username');
                        setUserNameCheckError(!data.available ? t('profile.idTaken') : '');
                    },
                }),
            );
        }, 1500),
    ).current;

    const checkMailDebounce = React.useRef(
        _.debounce((checkMailInputProcessor, initialValue) => {
            if (
                !Object.keys(
                    validateValue(checkMailInputProcessor.getValue('mail'), '', { email: 1 }),
                ).length
            ) {
                checkMailInputProcessor.startLoading('mail');

                if (
                    initialValue.toLowerCase() !==
                    checkMailInputProcessor.getValue('mail').toLowerCase()
                ) {
                    dispatch(
                        getMailAvailability({
                            mail: checkMailInputProcessor.getValue('mail'),
                            cb: (data) => {
                                if (!data.available && profile?.mail !== data.mail) {
                                    setMailCheckError(null);
                                    setMailCheckError(t('profile.emailTaken'));
                                } else {
                                    setMailCheckError(null);
                                    setMailCheckError('');
                                }
                            },
                        }),
                    );
                }
            }
        }, 300),
    ).current;

    const getStudentNameForTitle = (curStudent: PutStudentProps | undefined | null) => {
        return curStudent?.name ?? t('general.student');
    };

    return (
        <div className="profileEdit">
            <WhiteBox>
                <div className="profileEdit-header">
                    <div className="profileEdit-header__name">
                        <InputTextOld
                            inputName="name"
                            inputLabel="Student name"
                            inputProcessor={inputProcessor}
                            validators={{ empty: 1, maxLength: 255 }}
                        />
                        <div className="profileEdit-header-buttons">
                            <HeaderButton
                                disabled={inputProcessor.hasError([
                                    'name',
                                    'mail',
                                    'username',
                                    'reportedId',
                                    'courseCode',
                                ])}
                                onClick={() => {
                                    inputProcessor.updateInput({
                                        school: inputProcessor.getValue('school')?.gid,
                                    });
                                    dispatch(
                                        updateStudent({
                                            data: {
                                                ...inputProcessor.values,
                                            } as UpdateStudentAP['data'],
                                            studentId: params.id as string,
                                            cb: (data) => {
                                                setIsDirty(false);
                                                setSuccessUpdateStudent(data);
                                            },
                                        }),
                                    );
                                }}
                            >
                                <Save />
                                {t('general.save')}
                            </HeaderButton>
                            <HeaderButton onClick={() => setSearchParams('')}>
                                <CloseButtonSvg />
                            </HeaderButton>
                        </div>
                    </div>
                    <div className="profile-main-subtitle">{t('general.student')}</div>
                </div>
                <hr />
                <div className="profileEdit-content">
                    <div className="profileEdit-content-class">
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label">
                                {t('general.ID')}
                            </div>
                            <div className="profileEdit-inputs-parent__inputs reportedId">
                                <div className="profileEdit-cur-input">
                                    <InputTextOld
                                        validators={{ empty: 1, maxLength: 255 }}
                                        inputProcessor={inputProcessor}
                                        inputName="username"
                                        onChange={(e: ChangeEvent) => {
                                            const { value } = e.target as HTMLInputElement;
                                            if (value !== '' && value !== profile.username) {
                                                inputProcessor.startLoading('username');
                                                checkUserNameDebounce(inputProcessor);
                                            }
                                        }}
                                        inputLabel={t('general.ID')}
                                    />
                                </div>
                                <div className="profileEdit-reported">
                                    <b>
                                        {t('profile.reportedID')}
                                        {profileGetExclamationIcon(
                                            inputProcessor.getValue('username'),
                                            inputProcessor.getValue('reportedId'),
                                        )}
                                    </b>
                                    <InputTextOld
                                        validators={{ maxLength: 255 }}
                                        inputProcessor={inputProcessor}
                                        inputName="reportedId"
                                        inputLabel={t('profile.reportedID')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label">
                                {t('general.school')}
                            </div>
                            <div className="profileEdit-inputs-parent__inputs">
                                <SelectMenu
                                    alwaysWide
                                    wrapperClass="type_3"
                                    options={schools.map((item) => {
                                        return {
                                            content: (
                                                <div title={item.name}>
                                                    <span>{item.name}</span>
                                                </div>
                                            ),
                                            onClick: () => {
                                                inputProcessor.updateInput({
                                                    school: {
                                                        gid: item.gid,
                                                        name: item.name,
                                                    },
                                                });
                                            },
                                        };
                                    })}
                                    text={inputProcessor.getValue('school')?.name}
                                />
                            </div>
                        </div>
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label">
                                {t('profile.currentClasses')}
                            </div>
                            <div className="profileEdit-inputs-parent__inputs">
                                <div className="profileEdit-current-classes-parent">
                                    <div className="profileEdit-classes-list">
                                        {inputProcessor
                                            .getValue('classes')
                                            ?.map((curClass: any, curClassIndex: number) => {
                                                return (
                                                    <React.Fragment
                                                        key={`${curClass.id}_${curClassIndex}`}
                                                    >
                                                        <div className="profileEdit-classes-list-item">
                                                            <div className="profileEdit-classes-list-item__index">
                                                                <b>{curClassIndex + 1}.</b>
                                                            </div>
                                                            <div className="profileEdit-classes-list-item__name">
                                                                <b>{t('general.class')}: </b>
                                                                {curClass.name}
                                                            </div>
                                                            <div className="profileEdit-classes-list-item__buttons">
                                                                <div
                                                                    className={classNames(
                                                                        'cursor-pointer sc_hover_color_text_svg',
                                                                        {
                                                                            active:
                                                                                editProfileClass?.id ===
                                                                                curClass.id,
                                                                        },
                                                                    )}
                                                                    onClick={() =>
                                                                        setEditProfileClass({
                                                                            data:
                                                                                editProfileClass?.id !==
                                                                                curClass.id
                                                                                    ? curClass
                                                                                    : null,
                                                                            index: curClassIndex,
                                                                        })
                                                                    }
                                                                >
                                                                    <Pencil fill="#54585A" />
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setOpenDeleteClass(
                                                                            curClass,
                                                                        );
                                                                    }}
                                                                    className="cursor-pointer sc_hover_color_text_svg"
                                                                >
                                                                    <Remove />
                                                                </div>
                                                            </div>
                                                            <div className="profileEdit-classes-list-item__dates">
                                                                {moment
                                                                    .unix(curClass.from)
                                                                    .format(
                                                                        'DD. MMM YYYY. hh:mm',
                                                                    )}{' '}
                                                                -{' '}
                                                                {moment
                                                                    .unix(curClass.to)
                                                                    .format('DD. MMM YYYY. hh:mm')}
                                                            </div>
                                                        </div>
                                                        {editProfileClass &&
                                                        editProfileClass.data.id === curClass.id &&
                                                        editProfileClass.index === curClassIndex ? (
                                                            <ProfileEditChangeClass
                                                                curClassIndex={
                                                                    editProfileClass.index
                                                                }
                                                                open={editProfileClass.data}
                                                                setOpen={setEditProfileClass}
                                                                inputProcessor={inputProcessor}
                                                            />
                                                        ) : null}
                                                    </React.Fragment>
                                                );
                                            })}
                                        {!openAssignClass && (
                                            <div className="profileEdit-assign-new-class">
                                                <Button
                                                    type="square"
                                                    onClick={() => {
                                                        setOpenAssignClass(true);
                                                    }}
                                                >
                                                    {t('profile.assignNewClass')}
                                                </Button>
                                            </div>
                                        )}
                                        {openAssignClass && (
                                            <ProfileEditAssignClass
                                                open={openAssignClass}
                                                setOpen={setOpenAssignClass}
                                                classesForDropdownGid={
                                                    inputProcessor.getValue('school')?.gid
                                                }
                                                inputProcessor={inputProcessor}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profileEdit-content-class additional">
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label">
                                {t('general.Email')}
                            </div>
                            <div className="profileEdit-inputs-parent__inputs">
                                <div className="profileEdit-cur-input">
                                    <InputTextOld
                                        validators={{ email: 1, empty: 1 }}
                                        inputProcessor={inputProcessor}
                                        inputName="mail"
                                        inputLabel={t('general.Email')}
                                        onChange={(e: any) => {
                                            if (e.target.value !== '') {
                                                checkMailDebounce(inputProcessor, initialMailValue);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {isStudentProfileFixedEndAccessActive ? (
                            <>
                                <div className="profileEdit-inputs-parent">
                                    <div
                                        className="profileEdit-inputs-parent__label"
                                        style={{ margin: 0 }}
                                    >
                                        {t('profile.startDate')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            {moment(getDate('startDate')).format('DD MMM YYYY')}
                                        </div>
                                    </div>
                                </div>

                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.months')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <SelectMenu
                                                alwaysWide
                                                wrapperClass="type_3"
                                                options={range(1, 25).map((item: number) => {
                                                    return {
                                                        content: (
                                                            <div title={item.toString()}>
                                                                <span>{item}</span>
                                                            </div>
                                                        ),
                                                        onClick: () => {
                                                            inputProcessor.updateInput({
                                                                endDate: moment
                                                                    .unix(
                                                                        inputProcessor.getValue(
                                                                            'startDate',
                                                                        ),
                                                                    )
                                                                    .add(item, 'months')
                                                                    .unix(),
                                                            });

                                                            inputProcessor.updateInput({
                                                                endAccess: moment
                                                                    .unix(
                                                                        inputProcessor.getValue(
                                                                            'startDate',
                                                                        ),
                                                                    )
                                                                    .add(item, 'months')
                                                                    .unix(),
                                                            });
                                                        },
                                                    };
                                                })}
                                                text={
                                                    diffBetweenStartAndEnd % 1 === 0
                                                        ? diffBetweenStartAndEnd
                                                        : t('profile.selectMonths')
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="profileEdit-inputs-parent">
                                    <div
                                        className="profileEdit-inputs-parent__label"
                                        style={{ margin: 0 }}
                                    >
                                        {t('profile.endAccessDate')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            {moment(getDate('endAccess')).format('DD MMM YYYY')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.startDate')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <InputCalendar
                                                onChange={(e: any) => {
                                                    inputProcessor.updateInput({
                                                        startDate: moment(e).unix(),
                                                    });
                                                    if (
                                                        inputProcessor.getValue('endDate') <
                                                        moment(e).unix()
                                                    ) {
                                                        inputProcessor.updateInput({
                                                            endDate: moment(e).add(2, 'day').unix(),
                                                        });
                                                    }
                                                    if (
                                                        inputProcessor.getValue('endAccess') <
                                                        moment(e).unix()
                                                    ) {
                                                        inputProcessor.updateInput({
                                                            endAccess: moment(e)
                                                                .add(2, 'day')
                                                                .unix(),
                                                        });
                                                    }
                                                }}
                                                date={getDate('startDate')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.endDate')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <InputCalendar
                                                minDate={
                                                    new Date(
                                                        moment
                                                            .unix(
                                                                inputProcessor.getValue(
                                                                    'startDate',
                                                                ),
                                                            )
                                                            .add(2, 'day')
                                                            .format(),
                                                    )
                                                }
                                                onChange={(e: any) => {
                                                    inputProcessor.updateInput({
                                                        endDate: moment(e).unix(),
                                                    });
                                                }}
                                                date={getDate('endDate')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.endAccessDate')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <InputCalendar
                                                minDate={
                                                    new Date(
                                                        moment
                                                            .unix(
                                                                inputProcessor.getValue(
                                                                    'startDate',
                                                                ),
                                                            )
                                                            .add(2, 'day')
                                                            .format(),
                                                    )
                                                }
                                                onChange={(e: any) => {
                                                    inputProcessor.updateInput({
                                                        endAccess: moment(e).unix(),
                                                    });
                                                }}
                                                date={getDate('endAccess')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label">
                                {t('profile.status')}
                            </div>
                            <div className="profileEdit-inputs-parent__inputs">
                                <div className="profileEdit-cur-input">
                                    <SelectMenu
                                        alwaysWide
                                        wrapperClass="type_3"
                                        options={[
                                            { name: t('profile.active'), value: true },
                                            {
                                                name: t('profile.canceled'),
                                                value: false,
                                            },
                                        ].map((item: any) => {
                                            return {
                                                content: (
                                                    <div title={item.name}>
                                                        <span>{item.name}</span>
                                                    </div>
                                                ),
                                                onClick: () => {
                                                    inputProcessor.updateInput({
                                                        status: item.value,
                                                    });
                                                },
                                            };
                                        })}
                                        text={
                                            inputProcessor.getValue('status')
                                                ? t('profile.active')
                                                : t('profile.canceled')
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {!layout.isIelts && (
                            <>
                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.studyPlanLevel')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <SelectMenu
                                                alwaysWide
                                                wrapperClass="type_3"
                                                options={[2, 3, 4, 5, 6].map((item: any) => {
                                                    return {
                                                        content: (
                                                            <div
                                                                title={getInlineLangLevel(item, t)}
                                                            >
                                                                <span>
                                                                    {getInlineLangLevel(item, t)}
                                                                </span>
                                                            </div>
                                                        ),
                                                        onClick: () => {
                                                            inputProcessor.updateInput({
                                                                level: item,
                                                            });
                                                        },
                                                    };
                                                })}
                                                text={getInlineLangLevel(
                                                    inputProcessor.getValue('level'),
                                                    t,
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="underline-active"
                                            onClick={() => setOpenResetStudyPlan(profile)}
                                        >
                                            {t('profile.resetStudyPlan')}
                                        </div>
                                    </div>
                                </div>
                                <div className="profileEdit-inputs-parent">
                                    <div className="profileEdit-inputs-parent__label">
                                        {t('profile.courseCode')}
                                    </div>
                                    <div className="profileEdit-inputs-parent__inputs">
                                        <div className="profileEdit-cur-input">
                                            <InputTextOld
                                                validators={{ maxLength: 255 }}
                                                inputProcessor={inputProcessor}
                                                inputName="courseCode"
                                                inputLabel={t('profile.courseCode')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="profileEdit-inputs-parent">
                            <div className="profileEdit-inputs-parent__label" />
                            <div className="profileEdit-inputs-parent__inputs">
                                <span
                                    className="underline-active"
                                    onClick={() => {
                                        setOpenResetPassword(true);
                                    }}
                                >
                                    {t('profile.resetPassword')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </WhiteBox>
            <SuccessModal
                open={Boolean(successResetStudyPlan)}
                lottie
                text={t('profile.studyPlanResetSuccess', {
                    studentName: getStudentNameForTitle(successResetStudyPlan),
                })}
                onClose={() => setSuccessResetStudyPlan(null)}
            />
            <SuccessModal
                open={Boolean(successUpdateStudent)}
                lottie
                text={t('profile.studentsDataUpdated', {
                    studentName: getStudentNameForTitle(successResetStudyPlan),
                })}
                onClose={() => {
                    setSearchParams('');
                }}
            />
            <ConfirmModal
                open={Boolean(openResetStudyPlan)}
                text={t('profile.resetStudyPlanConfirm', {
                    studentName: getStudentNameForTitle(openResetStudyPlan),
                })}
                onClick={() => {
                    dispatch(
                        updateStudent({
                            data: { level: 0 },
                            studentId: params.id as string,
                            cb: () => {
                                setSuccessResetStudyPlan(openResetStudyPlan);
                            },
                        }),
                    );
                }}
                onClose={() => {
                    setOpenResetStudyPlan(null);
                }}
            />
            <ConfirmModal
                open={openResetPassword}
                text={t('profile.resetStudentPasswordConfirm', {
                    studentName: getNoEmpty(profile.name),
                })}
                success={successResetPassword}
                onClick={() => {
                    dispatch(
                        resetPassword({
                            studentId: params.id as string,
                            cb: (data) => {
                                setSuccessResetPassword(
                                    t('profile.resetStudentPasswordSuccess', {
                                        studentName: getNoEmpty(profile.name),
                                        username: data.username,
                                        email: data.email,
                                        password: data.password,
                                    }),
                                );
                            },
                        }),
                    );
                }}
                onClose={() => {
                    setSuccessResetPassword(null);
                    setOpenResetPassword(null);
                }}
            />
            <ConfirmModal
                open={openDeleteClass}
                onClose={() => setOpenDeleteClass(null)}
                text={t('profile.deleteClassRecordConfirmation')}
                onClick={() => {
                    const tempClasses = structuredClone(inputProcessor.getValue('classes'));
                    const classIndex = tempClasses.findIndex((fClass: any) => {
                        return fClass.id === openDeleteClass.id;
                    });
                    tempClasses.splice(classIndex, 1);
                    inputProcessor.updateInput({
                        classes: tempClasses,
                    });
                    setOpenDeleteClass(null);
                }}
            />
            <ConfirmModal
                text={t('profile.unsavedChangesConfirmation')}
                onClick={() => {
                    confirmNavigation();
                }}
                onClose={() => {
                    cancelNavigation();
                }}
                open={showPrompt}
            />
        </div>
    );
};

export default ProfileEdit;
