import { useSelector } from 'react-redux';
import { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { selectUnits } from 'src/redux/weeklyPlan/selectors';
import { selectAims } from 'src/redux/coursePlanner/selectors';
import Checkbox from 'src/components/Checkbox';
import CheckedMinus from 'src/components/Icons/CheckedMinus';
import Search from 'src/components/Search';
import Filter from 'src/components/Icons/Filter';
import noData2 from 'src/assets/images/noData2.gif';
import PaperList from 'src/components/Icons/PaperList';
import ArrowSort from 'src/components/Icons/ArrowSort';
import AnimatedRowDummy from 'src/components/AnimatedRowDummy/AnimatedRow';
import CategorySelector from 'src/components/CategorySelector';
import Button from 'src/components/Button';
import { omitObjectProp } from 'src/helpers/omitObjectProp';
import { selectLoading } from 'src/redux/loading/selectors';
import { Method } from '../../types';
import './styles.scss';

const RowBook = memo(
    function Dummy({ unit, isActive, onRowClick }: any) {
        return (
            <tr
                key={unit.id}
                className={classNames({
                    'makeUnit-row--active': isActive,
                })}
                onClick={() => onRowClick(unit.id)}
            >
                <td className="makeUnit-tableText">
                    <div className="makeUnit-ckeckbox">
                        <Checkbox checked={isActive} />
                        <span>Unit {unit.name}</span>
                    </div>
                </td>
                <td className="makeUnit-tableText">{unit.number}</td>
            </tr>
        );
    },
    (prev, next) => {
        return prev.isActive === next.isActive;
    },
);

const BookTable = ({
    selectedItems,
    onChange,
    setSelectedItems,
    unitsData,
    filteredUnits,
    isSelected,
    onRowClick,
    onSort,
    sorted,
}: {
    selectedItems: number[];
    onChange: ({ content }: any) => void;
    setSelectedItems: (data: number[]) => void;
    unitsData: any;
    filteredUnits: any;
    isSelected: (id: number) => boolean;
    onRowClick: any;
    onSort: (data: { [key: string]: boolean }) => void;
    sorted: { [key: string]: boolean };
}) => {
    return (
        <table>
            <thead>
                <tr>
                    <th className="makeUnit-tableHead">
                        <div
                            className={classNames('makeUnit-tableHeadSort', {
                                'makeUnit-tableHeadSort--active': sorted.number,
                            })}
                            onClick={() =>
                                onSort(
                                    !sorted.number
                                        ? {
                                              ...sorted,
                                              number: !sorted.number,
                                          }
                                        : omitObjectProp(sorted, 'number'),
                                )
                            }
                        >
                            <div className="makeUnit-ckeckbox">
                                <Checkbox
                                    onClick={() => {
                                        if (!selectedItems.length) {
                                            setSelectedItems(
                                                unitsData.map(({ id }: { id: number }) => id),
                                            );
                                            onChange({
                                                content: unitsData.map(
                                                    ({ id }: { id: number }) => id,
                                                ),
                                            });
                                            return;
                                        }

                                        setSelectedItems([]);
                                        onChange({ content: [] });
                                    }}
                                    checked={!!selectedItems.length}
                                    customIcon={<CheckedMinus />}
                                />
                                <span>Unit</span>
                            </div>
                            <ArrowSort />
                        </div>
                    </th>
                    <th className="makeUnit-tableHead">
                        <div
                            className={classNames('makeUnit-tableHeadSort', {
                                'makeUnit-tableHeadSort--active': sorted.name,
                            })}
                            onClick={() =>
                                onSort(
                                    !sorted.name
                                        ? {
                                              ...sorted,
                                              name: !sorted.name,
                                          }
                                        : omitObjectProp(sorted, 'name'),
                                )
                            }
                        >
                            <span>Name</span>
                            <ArrowSort />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {filteredUnits.map((unit: any) => (
                    <RowBook
                        key={unit.id}
                        unit={unit}
                        isActive={isSelected(unit.id)}
                        onRowClick={onRowClick}
                    />
                ))}
            </tbody>
        </table>
    );
};

const RowAims = memo(
    function Dummy({ unit, isActive, onRowClick }: any) {
        return (
            <tr
                className={classNames({
                    'makeUnit-row--active': isActive,
                })}
                onClick={() => onRowClick(unit.id)}
            >
                <td className="makeUnit-tableText">
                    <div className="makeUnit-ckeckbox">
                        <Checkbox checked={isActive} />
                        <span>{unit.level}</span>
                    </div>
                </td>
                <td className="makeUnit-tableText">{unit.category}</td>
                <td className="makeUnit-tableText">{unit.name}</td>
            </tr>
        );
    },
    (prev, next) => {
        return prev.isActive === next.isActive;
    },
);

const AimTable = ({
    selectedItems,
    onChange,
    setSelectedItems,
    aimsData,
    filteredUnits,
    isSelected,
    onRowClick,
    onSort,
    sorted,
}: {
    selectedItems: number[];
    onChange: ({ content }: any) => void;
    setSelectedItems: (data: number[]) => void;
    aimsData: any;
    filteredUnits: any;
    isSelected: (id: number) => boolean;
    onRowClick: any;
    onSort: (data: { [key: string]: boolean }) => void;
    sorted: { [key: string]: boolean };
}) => {
    return (
        <table>
            <thead>
                <tr>
                    <th className="makeUnit-tableHead">
                        <div className="makeUnit-ckeckbox">
                            <Checkbox
                                onClick={() => {
                                    const filteredIds = filteredUnits.map(
                                        ({ id }: { id: number }) => id,
                                    );
                                    const selectedCurrentFilter = [
                                        ...selectedItems.filter(
                                            (item) => !filteredIds.includes(item),
                                        ),
                                    ];
                                    const isChecked = selectedItems.some((item) =>
                                        filteredUnits
                                            .map(({ id }: { id: number }) => id)
                                            .includes(item),
                                    );
                                    if (!isChecked) {
                                        setSelectedItems([
                                            ...selectedCurrentFilter,
                                            ...filteredIds,
                                        ]);
                                        onChange({
                                            content: [...selectedCurrentFilter, ...filteredIds],
                                        });
                                        return;
                                    }

                                    setSelectedItems(selectedCurrentFilter);
                                    onChange({ content: selectedCurrentFilter });
                                }}
                                checked={selectedItems.some((item) =>
                                    filteredUnits
                                        .map(({ id }: { id: number }) => id)
                                        .includes(item),
                                )}
                                customIcon={<CheckedMinus />}
                            />
                            <span>LEVEL</span>
                        </div>
                    </th>
                    <th>
                        <div
                            className={classNames('makeUnit-tableHeadSort', {
                                'makeUnit-tableHeadSort--active': sorted.category,
                            })}
                            onClick={() =>
                                onSort(
                                    !sorted.category
                                        ? {
                                              ...sorted,
                                              category: !sorted.category,
                                          }
                                        : omitObjectProp(sorted, 'category'),
                                )
                            }
                        >
                            <span>CATEGORY</span>
                            <ArrowSort />
                        </div>
                    </th>
                    <th>
                        <div
                            className={classNames('makeUnit-tableHeadSort', {
                                'makeUnit-tableHeadSort--active': sorted.name,
                            })}
                            onClick={() =>
                                onSort(
                                    !sorted.name
                                        ? {
                                              ...sorted,
                                              name: !sorted.name,
                                          }
                                        : omitObjectProp(sorted, 'name'),
                                )
                            }
                        >
                            <span>AIM</span>
                            <ArrowSort />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {filteredUnits.map((unit: any) => (
                    <RowAims
                        key={unit.id}
                        unit={unit}
                        isActive={isSelected(unit.id)}
                        onRowClick={onRowClick}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default function SelectUnits({
    method,
    onChange,
    selectedUnits = [],
}: {
    method?: string;
    onChange: ({ content }: any) => void;
    selectedUnits: number[];
}) {
    const loading = useSelector(selectLoading);
    const isAim = method === Method.TestByAim;
    const units = useSelector(selectUnits);
    const aims = useSelector(selectAims);
    const [search, setSearch] = useState('');
    const [aimFilters, setAimsFilters] = useState({} as { [key: string]: number });
    const [activeAimFilter, setActiveAimFilter] = useState('');
    const [sortUp, setSortUp] = useState({} as any);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFilters, setSelectedFilters] = useState('');

    const [selectedItems, setSelectedItems] = useState(selectedUnits);

    const isSelected = (id: number) => selectedItems.includes(id);

    const filteredUnits = useMemo(() => {
        const data = isAim ? aims : units;
        let result = (data as { name: string }[]).filter(({ name }) => {
            if (!search) {
                return true;
            }
            return name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        });

        if (isAim) {
            result = JSON.parse(JSON.stringify(result))
                .filter(({ level }: any) => {
                    return level === activeAimFilter;
                })
                .filter(({ category }: any) => {
                    if (!selectedFilters || selectedFilters === 'All') {
                        return true;
                    }
                    return category === selectedFilters;
                });
        }

        Object.keys(sortUp).forEach((sortBy) => {
            result = JSON.parse(JSON.stringify(result)).sort((prev: any, next: any) => {
                if (prev[sortBy] < next[sortBy]) {
                    return sortUp[sortBy] ? 1 : -1;
                }
                if (prev[sortBy] > next[sortBy]) {
                    return sortUp[sortBy] ? -1 : 1;
                }
                return 0;
            });
        });

        return result;
    }, [units, search, aims, activeAimFilter, sortUp, selectedFilters]);

    const handleRowCLick = (unitId: number) => {
        onChange({
            questions: [] as number[],
            name: '',
        });
        setSelectedItems((prev) => {
            if (prev.includes(unitId)) {
                const filtered = prev.filter((id: number) => id !== unitId);
                return filtered;
            }
            return [...prev, unitId];
        });
    };

    useEffect(() => {
        onChange({
            content: selectedItems,
        });
    }, [selectedItems]);

    useEffect(() => {
        const filters: { [key: string]: number } = {};
        aims.forEach((aim) => {
            filters[aim.level] = (filters[aim.level] || 0) + 1;
        });
        setAimsFilters(filters);
        setActiveAimFilter(Object.keys(filters)[0] as string);
    }, [aims]);

    return (
        <div className="makeUnit-stepContent selectUnits">
            <h1 className="makeUnit-stepTitle">Select Content</h1>
            {method === Method.TestByAim && (
                <div className="makeUnit-aimFilters">
                    {Object.keys(aimFilters).map((filter) => {
                        return (
                            <div
                                key={filter}
                                className={classNames('makeUnit-aimFilter', {
                                    'makeUnit-aimFilter--active': filter === activeAimFilter,
                                })}
                                onClick={() => {
                                    setActiveAimFilter(filter);
                                }}
                            >
                                <div className="makeUnit-aimFilter-icon">
                                    <PaperList
                                        fill={filter === activeAimFilter ? '#FFFFFF' : '#676E7A'}
                                    />
                                </div>
                                <div className="makeUnit-aimFilter-text">
                                    <span className="makeUnit-aimFilter-title">{filter}</span>
                                    <span className="makeUnit-aimFilter-number">
                                        {aimFilters[filter]}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="makeUnit-tableContainer">
                <div className="makeUnit-tableSearch">
                    {isAim && (
                        <div
                            className="makeUnit-tableFilter"
                            onClick={() => {
                                setFiltersOpen((val) => {
                                    if (val && !selectedFilters) {
                                        setSelectedCategory('');
                                    }
                                    return !val;
                                });
                            }}
                        >
                            <Filter />
                        </div>
                    )}
                    <Search
                        newDesign
                        alwaysWide
                        onChange={(text: string) => {
                            setSearch(text);
                        }}
                    />
                </div>
                <div>
                    <AnimatedRowDummy
                        isActive={filtersOpen}
                        setIsActive={setFiltersOpen}
                        content={
                            <div className="makeUnit-filtersBody">
                                <CategorySelector
                                    options={
                                        new Array(...new Set(aims.map(({ category }) => category)))
                                    }
                                    onSelect={setSelectedCategory}
                                    selected={selectedCategory}
                                    name="Category"
                                />
                                <div>
                                    <Button
                                        type="grey"
                                        onClick={() => {
                                            setSelectedFilters(selectedCategory);
                                            setFiltersOpen(false);
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                </div>
                {!filteredUnits.length && !loading ? (
                    <div className="makeUnit-tableNoData">
                        <img src={noData2} alt="noData" />
                        <span>No data to display</span>
                    </div>
                ) : (
                    <div className="makeUnit-tableScroll">
                        {method === Method.TestByAim ? (
                            <AimTable
                                selectedItems={selectedItems}
                                onChange={onChange}
                                setSelectedItems={setSelectedItems}
                                aimsData={aims}
                                filteredUnits={filteredUnits}
                                isSelected={isSelected}
                                onRowClick={handleRowCLick}
                                onSort={(data) => setSortUp(data)}
                                sorted={sortUp}
                            />
                        ) : (
                            <BookTable
                                selectedItems={selectedItems}
                                onChange={onChange}
                                setSelectedItems={setSelectedItems}
                                unitsData={units}
                                filteredUnits={filteredUnits}
                                isSelected={isSelected}
                                onRowClick={handleRowCLick}
                                onSort={(data) => setSortUp(data)}
                                sorted={sortUp}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
