import { createAction } from '@reduxjs/toolkit';
import {
    DeleteToDoAP,
    GetAllToDoAP,
    GetToDoReferencesAP,
    PutAllToDoAP,
    PutToDoReferencesAP,
    SaveToDoAP,
} from './types';

export const GET_ALL_TO_DO = 'GET_ALL_TO_DO';
export const PUT_ALL_TO_DO = 'PUT_ALL_TO_DO';
export const SAVE_TO_DO = 'SAVE_TO_DO';
export const DELETE_TO_DO = 'DELETE_TO_DO';
export const GET_TO_DO_REFERENCES = 'GET_TO_DO_REFERENCES';
export const PUT_TO_DO_REFERENCES = 'PUT_TO_DO_REFERENCES';

export const getAllToDo = createAction<GetAllToDoAP>(GET_ALL_TO_DO);
export const putAllToDo = createAction<PutAllToDoAP>(PUT_ALL_TO_DO);
export const saveToDo = createAction<SaveToDoAP>(SAVE_TO_DO);
export const deleteToDo = createAction<DeleteToDoAP>(DELETE_TO_DO);
export const getToDoReferences = createAction<GetToDoReferencesAP>(GET_TO_DO_REFERENCES);
export const putToDoReferences = createAction<PutToDoReferencesAP>(PUT_TO_DO_REFERENCES);
