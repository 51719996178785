import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { API } from '../../../api';
import { getAssigments, putAssigments } from '../actions';
import { putApiRequestError } from '../../error/actions';
import { finishLoading, startLoading } from '../../loading/actions';

export function* getAssigmentsSaga({ payload }: ReturnType<typeof getAssigments>): SagaIterator {
    try {
        yield put(startLoading());
        const { classId } = payload;
        const data = yield call(
            API.get(),
            `/staff/course-planner${classId ? `/${classId}` : ''}/assignments`,
        );
        yield put(putAssigments(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
