import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import CircleCheck from 'src/components/Icons/CircleCheck';
import CircleCheckGradientIcon from 'src/components/Icons/CircleCheckGradient';
import CircleCloseIcon from 'src/components/Icons/CircleClose';
import Edit from 'src/components/Icons/Edit';
import Remove from 'src/components/Icons/Remove';
import Editable from 'src/components/Editable';
import { sanitizeConf } from 'src/components/Editable/constants';
import Move from 'src/components/Icons/Move';
import { notInString } from 'src/helpers/notInString';
import {
    removeColumn,
    WEEKLY_PLAN_ITEM_CURATED_OFF,
    WEEKLY_PLAN_ITEM_CURATED_ON,
    WEEKLY_PLAN_ITEM_EDIT_OFF,
} from '../../helpers';
import { CustomDraggableProps } from '../../CustomDraggable/types';
import { EditableColumnType } from '../../types';

const CustomDraggableMob = ({
    lessonIndex,
    setMoveToModalData,
    item,
    indexDraggable,
    editedColumns,
    setColumns,
    columns,
    setEditedColumns,
    activeDay,
    setIsDirty,
    searchValue,
    wrapperRef,
    setEdit,
    edit,
    isCuratedHead,
}: CustomDraggableProps & Record<string, any>) => {
    const getNewColumnsForSave = (
        currentItem: EditableColumnType,
        tempColumns?: EditableColumnType[][],
    ) => {
        const newColumns = JSON.parse(JSON.stringify(tempColumns ?? editedColumns));
        const editedItem = newColumns[activeDay - 1].find(({ id }: { id: string }) => {
            return id === currentItem.id;
        });
        const tempText = editedItem.content
            .replaceAll(/(<div><br><\/div>)|(<div>)/gi, '<br>')
            .replaceAll(/<\/div>/gi, '');
        editedItem.content = sanitizeHtml(tempText, sanitizeConf);
        return newColumns;
    };

    const updateCuratedStatus = () => {
        const newColumns = JSON.parse(JSON.stringify(editedColumns));
        const editedItem = newColumns[activeDay - 1].find(({ id }: { id: string }) => {
            return id === item.id;
        });
        editedItem.curated =
            item.curated === WEEKLY_PLAN_ITEM_CURATED_ON
                ? WEEKLY_PLAN_ITEM_CURATED_OFF
                : WEEKLY_PLAN_ITEM_CURATED_ON;
        setColumns(getNewColumnsForSave(item, newColumns));
        setEditedColumns(getNewColumnsForSave(item, newColumns));
    };

    if (!item.isNewItem && item.content === '') {
        return <></>;
    }
    return (
        <Draggable key={item.id} draggableId={item.id} index={indexDraggable}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                const curated = item.curated === WEEKLY_PLAN_ITEM_CURATED_ON;

                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames('weeklyPlan-draggable', {
                            'd-none': notInString(item.content, searchValue),
                            curated,
                        })}
                    >
                        <div
                            key={indexDraggable}
                            className={classNames({
                                'd-none': notInString(item.content, searchValue),
                            })}
                            ref={(ref) => {
                                (wrapperRef as any).current[item.id] = ref;
                            }}
                        >
                            {curated && (
                                <div className="weeklyPlan-draggable-curated-check">
                                    <CircleCheckGradientIcon />
                                </div>
                            )}
                            <div
                                className={classNames('weeklyPlan-editable', {
                                    curated,
                                })}
                            >
                                <div
                                    className={classNames('weeklyPlan-draggable-controls', {
                                        'weeklyPlan-draggable-controls--disable': edit,
                                        'd-none': item.editable === WEEKLY_PLAN_ITEM_EDIT_OFF,
                                        curated,
                                    })}
                                >
                                    <div
                                        style={{ display: 'none' }}
                                        {...provided.dragHandleProps}
                                    />
                                    <div
                                        className={classNames(
                                            'weeklyPlan-draggable-controls-icons sc_hover_color_text_svg',
                                            { 'd-none': !isCuratedHead },
                                        )}
                                        onClick={updateCuratedStatus}
                                    >
                                        {curated ? (
                                            <CircleCloseIcon />
                                        ) : (
                                            <CircleCheck fill="#4B5563" />
                                        )}
                                    </div>
                                    <div
                                        className="weeklyPlan-move grab weeklyPlan-draggable-controls-icons"
                                        onClick={() => {
                                            setMoveToModalData({
                                                provided,
                                                item,
                                                lessonIndex,
                                                indexDraggable,
                                            });
                                        }}
                                    >
                                        <Move />
                                    </div>
                                    <div
                                        className={classNames(
                                            'weeklyPlan-draggable-controls-icons',
                                            {
                                                'd-none': isCuratedHead,
                                            },
                                        )}
                                        onClick={() => setEdit(item.id)}
                                    >
                                        <Edit fill="#54585a" />
                                    </div>
                                    <div
                                        className="weeklyPlan-draggable-controls-icons"
                                        onClick={() =>
                                            removeColumn(
                                                columns,
                                                setColumns,
                                                activeDay - 1,
                                                item.id,
                                            )
                                        }
                                    >
                                        <Remove />
                                    </div>
                                </div>

                                <Editable
                                    html={
                                        edit
                                            ? editedColumns[activeDay - 1].find(({ id }) => {
                                                  return id === item.id;
                                              })?.content
                                            : item.content
                                    }
                                    edit={edit === item.id}
                                    onChange={(e) => {
                                        const newColumns = JSON.parse(
                                            JSON.stringify(editedColumns),
                                        );

                                        const editedItem = newColumns[activeDay - 1].find(
                                            ({ id }: { id: string }) => {
                                                return id === item.id;
                                            },
                                        );
                                        editedItem.content = e.target.value;
                                        setEditedColumns(newColumns);
                                    }}
                                    onSave={() => {
                                        setColumns(getNewColumnsForSave(item));
                                        setEdit('');
                                        setIsDirty(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
};

export default CustomDraggableMob;
