import { createReducer } from '@reduxjs/toolkit';
import { ClassesData } from 'src/pages/Classes/types';
import { PUT_CLASS, PUT_CLASSES, PUT_INVITES } from './actions';
import { PutClassProps, PutInvitesDataProps } from './types';

export type ClassesState = {
    classes: ClassesData[];
    class: PutClassProps;
    invites: PutInvitesDataProps;
};

const initialState = {
    classes: [],
    class: {
        id: 0,
        level: null,
        name: '',
        room: '',
        type: '',
        teacher: '',
        students: 0,
    },
    invites: {
        teacher: {
            id: '',
            expires: 0,
            used_count: 0,
            used_limit: 0,
            url: '',
            poster: {
                pdf: '',
                png: '',
            },
        },
        student: {
            id: '',
            expires: 0,
            used_count: 0,
            used_limit: 0,
            url: '',
            poster: {
                pdf: '',
                png: '',
            },
        },
    },
};

export const classesReducer = createReducer<ClassesState>(initialState, {
    [PUT_CLASSES]: (state, action) => {
        state.classes = action.payload;
    },
    [PUT_CLASS]: (state, action) => {
        state.class = action.payload;
    },
    [PUT_INVITES]: (state, action) => {
        state.invites = action.payload;
    },
});
