import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAttendanceStudents, putAttendanceStudents } from '../actions';

export function* getAttendanceStudentsSaga({
    payload,
}: ReturnType<typeof getAttendanceStudents>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/classes/${payload.classId}/attendance/${payload.year}/${payload.week}`,
        );

        yield put(putAttendanceStudents(data.students));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
