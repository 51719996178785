const ArrowLongThinIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-icon"
        viewBox="0 0 1024 1024"
        version="1.1"
    >
        <path d="M640 768c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733333l213.333333-213.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0s17.066667 42.666667 0 59.733333l-213.333333 213.333333C661.333333 763.733333 652.8 768 640 768z" />
        <path d="M853.333333 554.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-213.333333-213.333333c-17.066667-17.066667-17.066667-42.666667 0-59.733333s42.666667-17.066667 59.733333 0l213.333333 213.333333c17.066667 17.066667 17.066667 42.666667 0 59.733333C874.666667 550.4 866.133333 554.666667 853.333333 554.666667z" />
        <path d="M853.333333 554.666667 170.666667 554.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667l682.666667 0c25.6 0 42.666667 17.066667 42.666667 42.666667S878.933333 554.666667 853.333333 554.666667z" />
    </svg>
);

export default ArrowLongThinIcon;
