import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { ASSIGN_AUTOMATED, DELETE_AUTOMATED, GET_AUTOMATED, GET_AUTOMATED_TEST } from '../actions';
import { assignAutomatedSaga } from './assignAutomatedSaga';
import { deleteAutomatedSaga } from './deleteAutomatedSaga';
import { getAutomatedSaga } from './getAutomatedSaga';
import { getAutomatedTestSaga } from './getAutomatedTestSaga';

export function* automatedWatcher(): SagaIterator {
    yield takeLatest(GET_AUTOMATED, getAutomatedSaga);
    yield takeLatest(DELETE_AUTOMATED, deleteAutomatedSaga);
    yield takeLatest(ASSIGN_AUTOMATED, assignAutomatedSaga);
    yield takeLatest(GET_AUTOMATED_TEST, getAutomatedTestSaga);
}
