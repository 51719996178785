import { createReducer } from '@reduxjs/toolkit';
import { GetUnassignedProps, PUT_UNASSIGNED } from './actions';

export type UnassignedState = {
    unassigned: GetUnassignedProps[];
};

const initialState = {
    unassigned: [],
};

export const unassignedReducer = createReducer<UnassignedState>(initialState, {
    [PUT_UNASSIGNED]: (state, action) => {
        state.unassigned = action.payload;
    },
});
