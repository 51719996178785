const Pause = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.878"
        height="14.434"
        viewBox="0 0 16.878 14.434"
    >
        <g id="Pause" transform="translate(8.439 7.217)">
            <g id="Group_393" data-name="Group 393" transform="translate(-8.439 -7.217)">
                <path
                    id="Path_801"
                    data-name="Path 801"
                    d="M13.361,0H3.516A3.521,3.521,0,0,0,0,3.516V8.1a3.521,3.521,0,0,0,3.516,3.516H7.736v1.406H5.626a.7.7,0,1,0,0,1.407h5.626a.7.7,0,1,0,0-1.407H9.142V11.621h4.219A3.521,3.521,0,0,0,16.878,8.1V3.516A3.521,3.521,0,0,0,13.361,0Zm2.11,5.626V8.1a2.11,2.11,0,0,1-2.11,2.11H3.516A2.11,2.11,0,0,1,1.407,8.1V3.516a2.11,2.11,0,0,1,2.11-2.11h9.845a2.11,2.11,0,0,1,2.11,2.11v2.11Z"
                    fill="#54585a"
                />
                <path
                    id="Path_802"
                    data-name="Path 802"
                    d="M82,37.67h0l0,0Z"
                    transform="translate(-79.297 -36.428)"
                />
                <path
                    id="Path_803"
                    data-name="Path 803"
                    d="M429.865,37.67v0l0,0Z"
                    transform="translate(-415.69 -36.428)"
                />
                <g id="Group_392" data-name="Group 392" transform="translate(6.32 3.574)">
                    <rect
                        id="Rectangle_214"
                        data-name="Rectangle 214"
                        width="1.406"
                        height="4.508"
                        rx="0.703"
                        fill="#54585a"
                    />
                    <rect
                        id="Rectangle_215"
                        data-name="Rectangle 215"
                        width="1.406"
                        height="4.508"
                        rx="0.703"
                        transform="translate(2.802)"
                        fill="#54585a"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default Pause;
