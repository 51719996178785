import React from 'react';
import './styles.scss';
import classNames from 'classnames';

const ToDoListHeaderButton = ({
    title,
    subtitle,
    icon,
    active = false,
    onClick,
}: {
    title: string | number;
    subtitle: string;
    icon: JSX.Element;
    active?: boolean | number | Array<any> | undefined | null | string;
    onClick: () => void;
}) => {
    return (
        <div
            onClick={onClick}
            className={classNames('toDoListHeaderButton cursor-pointer', {
                active,
            })}
        >
            <div className="toDoListHeaderButton-icon">{icon}</div>
            <div className="toDoListHeaderButton-content">
                <div className="toDoListHeaderButton-item__subtitle">{subtitle}</div>
                <div className="toDoListHeaderButton-item__title">{title}</div>
            </div>
        </div>
    );
};

export default ToDoListHeaderButton;
