import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import emojiRelieved from 'src/assets/images/emojiRelieved.png';
import emojiThinkingFace from 'src/assets/images/emojiThinkingFace.png';
import Button from 'src/components/Button';
import InputPhone from 'src/components/InputPhone';
import InputText from 'src/components/InputText';
import SettingsModalValidationError from 'src/components/Modals/SettingsModal/SettingsModalValidationError';
import { getLayout } from 'src/redux/layout/actions';
import {
    confirm2FACode,
    put2FAPhoneNumber,
    putSettingsValidationErrors,
} from 'src/redux/settings/actions';
import * as yup from 'yup';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export type SettingsModal2FAType = {
    phoneNumber: string | null;
    state: 'enterCode' | 'errorEnterPhone' | 'success' | null;
    setState: Dispatch<SetStateAction<'enterCode' | 'errorEnterPhone' | 'success' | null>>;
    setOpenParent: (open: boolean) => void;
};
const TIMER = 20;
const SettingsModal2FA = ({
    phoneNumber,
    state = 'errorEnterPhone',
    setState,
    setOpenParent,
}: SettingsModal2FAType) => {
    const { t } = useTranslation();
    const [resendCodeTimer, setResendCodeTimer] = useState(TIMER);
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const formik = useFormik({
        initialValues: {
            code: '',
            phoneNumber: null,
        },
        onSubmit: () => {},
        validationSchema: yup.object().shape({
            code: yup.string().required(),
        }),
    });

    useEffect(() => {
        if (phoneNumber) {
            formik.setFieldValue('phoneNumber', phoneNumber);
            setRefresh(!refresh);
        }
    }, [phoneNumber]);

    useLayoutEffect(() => {
        if (resendCodeTimer < 1) {
            return;
        }
        const timer = setInterval(() => {
            setResendCodeTimer((seconds) => seconds - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [resendCodeTimer]);

    switch (state) {
        case 'enterCode':
            return (
                <div className="settingsModal2FA">
                    <div className="settingsModal2FA-title">{t('modals.settings.2fa')}</div>
                    <div className="settingsModal2FA-subtitle">{t('modals.settings.2faSent')}</div>
                    <div className="settingsModal2FA-phoneNumber">{formik.values.phoneNumber}</div>
                    <div className="settingsModal2FA-inputNote">
                        {t('modals.settings.enterBelow')}
                    </div>
                    <div className="settingsModal2FA-inputLabel">{t('modals.settings.code')}</div>
                    <InputText
                        onChange={(v) => formik.setFieldValue('code', v)}
                        template={2}
                        inputProps={{ placeholder: '*** ***' }}
                        fullWidth
                    />
                    <div
                        className={classNames('settingsModal2FA-resend', {
                            disabled: resendCodeTimer > 0,
                        })}
                        onClick={() => {
                            dispatch(
                                put2FAPhoneNumber({
                                    number: formik.values.phoneNumber || '',
                                    cb: () => {
                                        setResendCodeTimer(TIMER);
                                        setState('enterCode');
                                    },
                                }),
                            );
                        }}
                    >
                        {t('modals.settings.resendCode')}{' '}
                        {resendCodeTimer ? (
                            <span>
                                {t('modals.settings.wait')} {resendCodeTimer}
                            </span>
                        ) : null}
                    </div>
                    <SettingsModalValidationError />
                    <div className="settingsModal2FA-buttons">
                        <Button
                            type="square"
                            onClick={() => {
                                dispatch(putSettingsValidationErrors(false));
                                setState(null);
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!formik.values.code.length}
                            type="quaternary-new"
                            onClick={() => {
                                dispatch(
                                    confirm2FACode({
                                        phoneNumber: formik.values.phoneNumber || '',
                                        code: formik.values.code,
                                        cb: () => {
                                            setState('success');
                                        },
                                        cbError: () => {
                                            setState('errorEnterPhone');
                                        },
                                    }),
                                );
                            }}
                        >
                            {t('general.submit')}
                        </Button>
                    </div>
                </div>
            );

        case 'success':
            return (
                <div className="settingsModal2FA success">
                    <img
                        className="settingsModal2FA-emoji"
                        src={emojiRelieved}
                        alt="emoji relieved"
                    />
                    <div className="settingsModal2FA-title">{t('modals.settings.success')}</div>
                    <div className="settingsModal2FA-thanks">{t('modals.settings.thanks')}</div>
                    <div className="settingsModal2FA-youNeed">
                        {t('modals.settings.verifyEachLogin')}
                    </div>
                    <div className="settingsModal2FA-buttons">
                        <Button
                            type="square"
                            onClick={() => {
                                dispatch(putSettingsValidationErrors(false));
                                setState(null);
                                setOpenParent(false);
                                dispatch(getLayout());
                            }}
                        >
                            {t('modals.settings.gotIt')}
                        </Button>
                    </div>
                </div>
            );
        case 'errorEnterPhone':
            return (
                <div className="settingsModal2FA errorEnterPhone">
                    <img
                        className="settingsModal2FA-emoji"
                        src={emojiThinkingFace}
                        alt="emoji relieved"
                    />
                    <div className="settingsModal2FA-title">
                        {t('modals.settings.somethingWentWrong')}
                    </div>
                    <div className="settingsModal2FA-again">
                        {t('modals.settings.numberNotVerified')}
                        <br />
                        {t('modals.settings.tryAgain')}
                    </div>
                    <InputPhone
                        errorAsBlock
                        fullWidth
                        template={2}
                        defaultValue={formik.values.phoneNumber || ''}
                        error={formik.errors.phoneNumber}
                        onChange={(isValid, _inputValue, fullNumber) => {
                            formik.setFieldValue('phoneNumber', fullNumber, false);
                            if (!isValid) {
                                formik.setFieldError(
                                    'phoneNumber',
                                    t('modals.settings.invalidNumberTryAgain'),
                                );
                            } else {
                                formik.setFieldError('phoneNumber', undefined);
                            }
                        }}
                    />
                    <SettingsModalValidationError />
                    <div className="settingsModal2FA-buttons">
                        <Button
                            type="square"
                            onClick={() => {
                                dispatch(putSettingsValidationErrors(false));
                                setState(null);
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            disabled={!!formik.errors.phoneNumber}
                            type="quaternary-new"
                            onClick={() => {
                                dispatch(
                                    put2FAPhoneNumber({
                                        number: formik.values.phoneNumber || '',
                                        cb: () => {
                                            setState('enterCode');
                                        },
                                    }),
                                );
                            }}
                        >
                            {t('general.submit')}
                        </Button>
                    </div>
                </div>
            );
        default:
            return <></>;
    }
};
export default SettingsModal2FA;
