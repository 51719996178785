import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { inviteStudentEmailSaga } from 'src/redux/students/sagas/inviteStudentEmailSaga';
import {
    ADD_STUDENT_PROGRESS_TEST,
    ASSIGN_PACKAGES,
    ASSIGN_STUDENT_CREDITS,
    BULK_UPDATE_STUDENTS,
    DELETE_STUDENT,
    DELETE_STUDENT_MARKING,
    DELETE_STUDENT_PACKAGE,
    DELETE_STUDENT_PROGRESS_TEST,
    DELETE_STUDENT_PROGRESS_TEST_RESULT,
    DELETE_STUDENT_TUTORIAL,
    DELETE_STUDENT_UNIT_TEST_RESULT,
    GET_CREDITS,
    GET_STUDENT,
    GET_STUDENT_AUDIT,
    GET_STUDENT_EMAILS,
    GET_STUDENT_MARKING,
    GET_STUDENT_RESULTS_HISTORY,
    GET_STUDENT_TESTS,
    GET_STUDENT_TUTORIALS,
    GET_STUDENT_UPGRADES,
    GET_STUDENTS,
    GET_UPGRADES,
    INVITE_STUDENT_MAIL,
    RESET_PASSWORD,
    SAVE_STUDENT_COMMENT,
    SEND_STUDENT_MAIL,
    UPDATE_STUDENT,
} from '../actions';
import { addStudentProgressTestSaga } from './addStudentProgressTestSaga';
import { assignPackagesSaga } from './assignPackagesSaga';
import { assignStudentCreditsSaga } from './assignStudentCreditsSaga';
import { deleteStudentMarkingSaga } from './deleteStudentMarkingSaga';
import { deleteStudentPackageSaga } from './deleteStudentPackageSaga';
import { deleteStudentProgressTestResultSaga } from './deleteStudentProgressTestResultSaga';
import { deleteStudentProgressTestSaga } from './deleteStudentProgressTestSaga';
import { deleteStudentSaga } from './deleteStudentSaga';
import { deleteStudentTutorialSaga } from './deleteStudentTutorialSaga';
import { deleteStudentUnitTestResultSaga } from './deleteStudentUnitTestResultSaga';
import { getStudentCreditsSaga } from './getStudentCreditsSaga';
import { getStudentDataSaga } from './getStudentDataSaga';
import { getStudentResultsHistorySaga } from './getStudentResultsHistorySaga';
import { getStudentSaga } from './getStudentSaga';
import { getStudentsSaga } from './getStudentsSaga';
import { getStudentUpgradesSaga } from './getStudentUpgradesSaga';
import { resetPasswordSaga } from './resetPasswordSaga';
import { saveStudentCommentSaga } from './saveStudentCommentSaga';
import { sendStudentMailSaga } from './sendStudentMailSaga';
import { updateStudentSaga } from './updateStudentSaga';
import { bulkUpdateStudentsSaga } from './bulkUpdateStudentsSaga';

export function* studentsWatcher(): SagaIterator {
    yield takeLatest(GET_STUDENTS, getStudentsSaga);
    yield takeLatest(DELETE_STUDENT, deleteStudentSaga);
    yield takeLatest(SAVE_STUDENT_COMMENT, saveStudentCommentSaga);
    yield takeLatest(GET_STUDENT, getStudentSaga);
    yield takeLatest(UPDATE_STUDENT, updateStudentSaga);
    yield takeLatest(GET_STUDENT_RESULTS_HISTORY, getStudentResultsHistorySaga);
    yield takeLatest(GET_STUDENT_AUDIT, getStudentDataSaga);
    yield takeLatest(GET_STUDENT_MARKING, getStudentDataSaga);
    yield takeLatest(GET_STUDENT_UPGRADES, getStudentDataSaga);
    yield takeLatest(GET_STUDENT_EMAILS, getStudentDataSaga);
    yield takeLatest(GET_STUDENT_TESTS, getStudentDataSaga);
    yield takeLatest(GET_STUDENT_TUTORIALS, getStudentDataSaga);
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
    yield takeLatest(SEND_STUDENT_MAIL, sendStudentMailSaga);
    yield takeLatest(DELETE_STUDENT_TUTORIAL, deleteStudentTutorialSaga);
    yield takeLatest(ADD_STUDENT_PROGRESS_TEST, addStudentProgressTestSaga);
    yield takeLatest(DELETE_STUDENT_PROGRESS_TEST, deleteStudentProgressTestSaga);
    yield takeLatest(DELETE_STUDENT_PROGRESS_TEST_RESULT, deleteStudentProgressTestResultSaga);
    yield takeLatest(DELETE_STUDENT_UNIT_TEST_RESULT, deleteStudentUnitTestResultSaga);
    yield takeLatest(DELETE_STUDENT_MARKING, deleteStudentMarkingSaga);
    yield takeLatest(DELETE_STUDENT_PACKAGE, deleteStudentPackageSaga);
    yield takeLatest(GET_CREDITS, getStudentCreditsSaga);
    yield takeLatest(ASSIGN_STUDENT_CREDITS, assignStudentCreditsSaga);
    yield takeLatest(GET_UPGRADES, getStudentUpgradesSaga);
    yield takeLatest(ASSIGN_PACKAGES, assignPackagesSaga);
    yield takeLatest(INVITE_STUDENT_MAIL, inviteStudentEmailSaga);
    yield takeLatest(BULK_UPDATE_STUDENTS, bulkUpdateStudentsSaga);
}
