import { createReducer } from '@reduxjs/toolkit';
import { PAGINATION_LIMITS } from 'src/constants';
import { PUT_ALL_STUDENTS } from './actions';
import { AllStudentsDataType } from './types';

export type AllStudentsState = {
    allStudents: AllStudentsDataType;
};

export const allStudentsInitialState = {
    allStudents: {
        page: 0,
        pages: 0,
        recordsPerPage: PAGINATION_LIMITS[0],
        data: [],
    },
};

export const allStudentsReducer = createReducer<AllStudentsState>(allStudentsInitialState, {
    [PUT_ALL_STUDENTS]: (state, action) => {
        state.allStudents = action.payload;
    },
});
