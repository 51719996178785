import { easeBounceOut } from 'd3-ease';
import { interpolateString } from 'd3-interpolate';
import { useEffect, useRef } from 'react';
import { AnimatedRows, AnimatedRowsProps, AnimatedRowsSlide, SlideDirection } from './types';

function startAnimationLoop({ onProgress, onComplete, duration, initialProgress }: AnimatedRows) {
    let start: number | null = null;
    let requestId = 0;

    const startTimeDiff = (initialProgress || 0) * duration;

    const step = (timestamp: number) => {
        if (!start) start = timestamp - startTimeDiff;
        let progress = (timestamp - start) / duration;
        if (progress > 1) {
            progress = 1;
        }
        onProgress(progress);

        if (progress < 1) {
            requestId = window.requestAnimationFrame(step);
        } else if (onComplete) {
            onComplete();
        }
    };
    requestId = window.requestAnimationFrame(step);

    return {
        stop() {
            cancelAnimationFrame(requestId);
        },
    };
}

function getStyles(element: Element, props: string[]) {
    // @TODO any or what???
    const computed: CSSStyleDeclaration = window.getComputedStyle(element);
    return props.reduce((obj: any, prop: any) => {
        obj[prop] = computed[prop];
        return obj;
    }, {});
}

/* custom animations */
function slide(element: HTMLElement, { duration, direction, onComplete }: AnimatedRowsSlide) {
    element.style.overflow = 'hidden';
    const collapsedStyles = {
        marginTop: '0px',
        marginBottom: '0px',
        height: '0px',
        zIndex: '-1',
    };
    const props = Object.keys(collapsedStyles);

    const [startStyles, targetStyles] =
        direction === 'DOWN'
            ? [collapsedStyles, getStyles(element, props)]
            : [getStyles(element, props), collapsedStyles];
    const interpolators = new Map(
        props.map((prop) => [prop, interpolateString(startStyles[prop], targetStyles[prop])]),
    );

    return startAnimationLoop({
        duration,
        onComplete: () => {
            element.style.height = 'unset';
            element.style.overflow = 'unset';
            if (onComplete) {
                onComplete();
            }
        },
        onProgress: (progress: number) => {
            const delta = easeBounceOut(progress);
            // @TODO replace any
            interpolators.forEach((interpolator, prop: any) => {
                element.style[prop] = interpolator(delta);
            });
        },
    });
}

function slideDown(element: HTMLElement, { duration = 750, onComplete } = {} as SlideDirection) {
    return slide(element, { direction: 'DOWN', duration, onComplete });
}

function slideUp(element: HTMLElement, { duration = 750, onComplete } = {} as SlideDirection) {
    return slide(element, { direction: 'UP', duration, onComplete });
}

const AnimatedRowDummy = ({ content, isActive, setIsActive }: AnimatedRowsProps) => {
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        if (isActive) {
            slideDown(ref.current);
        } else {
            slideUp(ref.current, {
                onComplete: () => {
                    setIsActive(false);
                },
            });
        }
    }, [isActive]);

    return (
        <>
            {isActive && (
                <div ref={ref as React.RefObject<HTMLDivElement>} style={{ overflow: 'hidden' }}>
                    {content}
                </div>
            )}
        </>
    );
};

export default AnimatedRowDummy;
