import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { deleteAutomated, getAutomated } from '../actions';

export function* deleteAutomatedSaga({
    payload,
}: ReturnType<typeof deleteAutomated>): SagaIterator {
    try {
        yield put(startLoading());
        yield call(API.delete(), `/staff/tests/${payload.classId}/assignments/${payload.testId}`);
        if (payload.type) {
            yield put(
                getAutomated({
                    classId: payload.classId,
                    type: payload.type,
                }),
            );
        }
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
