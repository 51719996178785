import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ProgressState } from './reducer';

export const getProgressSelector = (state: RootState): ProgressState['progress'] =>
    state.progress.progress;

export const selectProgress = createSelector(
    createSelector(getProgressSelector, (progress) => progress),
    (progress) => progress,
);
