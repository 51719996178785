import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Table from 'src/components/Table';
import WhiteBox from 'src/components/WhiteBox';
import { useClass } from 'src/hooks/useClass';
import { getAutomatedTest } from 'src/redux/automated/actions';
import { selectAutomatedTest } from 'src/redux/automated/selectors';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import { PutAutomatedTestQuestionsProps } from 'src/redux/automated/types';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const AutomatedTest = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { t } = useTranslation();
    const test = useSelector(selectAutomatedTest);
    const classData = useSelector(selectClass);
    useClass();

    useEffect(() => {
        dispatch(getAutomatedTest({ classId: params.id, testId: params.subId }));
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        if (test?.id) {
            dispatch(
                putBreadcrumbs([
                    {
                        name: `${t('general.class')} ${classData.name}`,
                        link: `online-testing/automated-tests/${params.id}`,
                    },
                    {
                        name: test.testName || (test.name as string),
                    },
                ]),
            );
        }

        return () => {
            dispatch(putBreadcrumbs([]));
        };
    }, [classData, test]);

    if (!test?.id) {
        return <WhiteBox />;
    }

    return (
        <>
            <WhiteBox>
                <div className="automatedTest-header">
                    <b>
                        {t('automatedTest.by')} {test.teacher}
                    </b>
                </div>
                <Table
                    header={
                        <>
                            <span className="automatedTest-lang">
                                {t('automatedTest.langFocus')}
                            </span>
                            <span className="automatedTest-question">
                                {t('automatedTest.question')}
                            </span>
                            <span className="automatedTest-options">
                                {t('automatedTest.options')}
                            </span>
                            <span className="automatedTest-answer">
                                {t('automatedTest.answer')}
                            </span>
                        </>
                    }
                    rows={test.questions.map(
                        (question: PutAutomatedTestQuestionsProps, idx: number) => (
                            <>
                                <span className="automatedTest-lang">
                                    <b>{idx + 1}.</b>
                                    <span
                                        title={`${question.type} ${question.focus}`}
                                    >{`${question.type} ${question.focus}`}</span>
                                </span>
                                <div className="automatedTest-question">
                                    <span title={question.question}>{question.question}</span>
                                </div>
                                <div className="automatedTest-options">
                                    <span title={question.answers.join(' / ')}>
                                        {question.answers.join(' / ')}
                                    </span>
                                </div>
                                <span className="automatedTest-answer" title={question.answer}>
                                    {question.answer}
                                </span>
                            </>
                        ),
                    )}
                    headerMobile={
                        <>
                            <span>{t('automatedTest.listOfQuestions')}</span>
                        </>
                    }
                    rowsMobile={test.questions.map(
                        (question: PutAutomatedTestQuestionsProps, idx: number) => {
                            return {
                                head: (
                                    <>
                                        <div className="automatedTest-lang-mobile">
                                            <b>{idx + 1}.</b>
                                            <span>{`${question.type} ${question.focus}`}</span>
                                        </div>
                                    </>
                                ),
                                content: (
                                    <>
                                        <div className="automatedTest-mobile-row">
                                            <div className="automatedTest-mobile-row-title">
                                                <b>{t('automatedTest.question')}</b>
                                            </div>
                                            <span>{question.question}</span>
                                        </div>
                                        <div className="automatedTest-mobile-row">
                                            <div className="automatedTest-mobile-row-title">
                                                <b>{t('automatedTest.options')}</b>
                                            </div>
                                            <span>{question.answers.join(' / ')}</span>
                                        </div>
                                        <div className="automatedTest-mobile-row">
                                            <div className="automatedTest-mobile-row-title">
                                                <b>{t('automatedTest.answer')}</b>
                                            </div>
                                            <span>{question.answer}</span>
                                        </div>
                                    </>
                                ),
                            };
                        },
                    )}
                />
            </WhiteBox>
        </>
    );
};

export default AutomatedTest;
