import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import moment from 'moment';
import { getTimezoneForRequest } from 'src/helpers/getTimezoneForRequest';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { clearActivities, getWeeklyPlan, putWeeklyPlan } from '../actions';
import { PutWeeklyPlanLessonsLessonProps, PutWeeklyPlanLessonsProps } from '../types';
import { putApiRequestError } from '../../error/actions';
import { getBooks } from './getBooks';

export function* getWeeklyPlanSaga({ payload }: ReturnType<typeof getWeeklyPlan>): SagaIterator {
    try {
        yield put(startLoading());
        yield put(clearActivities());
        yield put(putWeeklyPlan({} as any));

        const data = yield call(
            API.get(),
            `/staff/course-planner/${payload.id}/${payload.year}/${
                payload.week
            }?${getTimezoneForRequest()}`,
        );

        const newData = JSON.parse(JSON.stringify(data));
        if (data.days.length) {
            data.lessons.forEach((lesson: PutWeeklyPlanLessonsProps) => {
                if (newData.days.find(({ day }: any) => day === lesson.day)) {
                    return;
                }
                newData.days.push({
                    day: lesson.day,
                    tests: [],
                    lessons: lesson.lessons.map(
                        (lessonsData: PutWeeklyPlanLessonsLessonProps, i: number) => {
                            return {
                                ...lessonsData,
                                published: 0,
                                suggestion: 0,
                                syllabus: [],
                                studentText: newData.days[0].lessons[i].studentText
                                    .split(/<\/?(?:strong)[^>]*>\s*/im)
                                    .map((text: string) => {
                                        if (text.match(/<\/?(?:div|p|br)[^>]*>\s*/im)) {
                                            return '';
                                        }

                                        return `<strong>${text}</strong>`;
                                    })
                                    .join(''),
                                teacherText: '',
                            };
                        },
                    ),
                });
            });
            newData.days.sort(({ day: prevDay }: any, { day: nextDay }: any) => {
                return prevDay - nextDay;
            });
        }

        yield put(putWeeklyPlan(newData));

        const { days } = data;

        if (!days.length && payload.cb && payload.week === moment(new Date()).isoWeek()) {
            payload.cb();
        }

        const bookIds = days
            .map((day: any) => {
                return day.lessons.map((lesson: any) => {
                    return lesson.syllabus.map(({ bookId, id }: any) => {
                        return bookId;
                    });
                });
            })
            .flat()
            .flat()
            .flat()
            .filter(
                (value: number, index: number, array: number[]) => array.indexOf(value) === index,
            );

        yield all(
            bookIds.map((id: number) =>
                call(getBooks, {
                    id,
                    classId: payload.id,
                }),
            ),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
