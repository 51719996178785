import { createAction } from '@reduxjs/toolkit';
import { PutProgressTestsAP } from './types';

export const GET_STAFF_REPORTS = 'GET_STAFF_REPORTS';
export const PUT_STAFF_REPORTS = 'PUT_STAFF_REPORTS';
export const GET_STAFF_EMAILS_LOG = 'GET_STAFF_EMAILS_LOG';
export const PUT_STAFF_EMAILS_LOG = 'PUT_STAFF_EMAILS_LOG';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const GET_PROGRESS_TESTS = 'GET_PROGRESS_TESTS';
export const PUT_PROGRESS_TESTS = 'PUT_PROGRESS_TESTS';

export const getStaffReports = createAction<any>(GET_STAFF_REPORTS);
export const putStaffReports = createAction<any>(PUT_STAFF_REPORTS);
export const getStaffEmailsLog = createAction<any>(GET_STAFF_EMAILS_LOG);
export const putStaffEmailsLog = createAction<any>(PUT_STAFF_EMAILS_LOG);
export const sendTestEmail = createAction<any>(SEND_TEST_EMAIL);
export const getProgressTests = createAction(GET_PROGRESS_TESTS);
export const putProgressTests = createAction<PutProgressTestsAP>(PUT_PROGRESS_TESTS);
