import { useState } from 'react';
import './styles.scss';

export default function TestName({
    onChange,
    testName = '',
}: {
    testName: string;
    onChange: ({ name }: any) => void;
}) {
    const [name, setName] = useState(testName);
    return (
        <div className="makeUnit-stepContent">
            <h1 className="makeUnit-stepTitle">Name your unit test</h1>

            <label className="makeUnit-nameContainer" htmlFor="testName">
                <span className="makeUnit-nameLabel">Test name</span>
                <input
                    type="text"
                    placeholder="Enter"
                    id="testName"
                    className="makeUnit-nameInput"
                    onChange={(event) => {
                        onChange({ name: event.target.value });
                        setName(event.target.value);
                    }}
                    value={name}
                    maxLength={50}
                />
            </label>
            <span className="makeUnit-nameCount">{name.length} / 50</span>
        </div>
    );
}
