import moment from 'moment';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getWeekRange } from 'src/helpers/getWeekRange';
import './styles.scss';
import { Props } from './types';

const CustomCalendar = ({
    week = moment(new Date()).isoWeek(),
    year = moment(new Date()).year(),
    onChange,
    type = 'range',
    defaultDateEmpty = false,
    onChangeReturnType,
    navigationLabelFormat = 'MMMM YYYY',
    allowPartialRange = false,
    maxDate = undefined,
    minDate = undefined,
    definedDate = undefined,
}: Props) => {
    const [date, setDate] = useState(() => {
        if (defaultDateEmpty) {
            return undefined;
        }
        switch (type) {
            case 'defined':
                return definedDate;
            case 'range':
                return getWeekRange(
                    new Date(moment().isoWeekYear(year).isoWeek(week) as unknown as string),
                );
            default:
                return new Date(moment().isoWeekYear(year).isoWeek(week) as unknown as string);
        }
    });
    const getMinDate = () => {
        if (typeof minDate !== 'undefined') {
            return minDate;
        }
        if (type === 'rangeCustom' || type === 'range' || minDate === null) {
            return null as any;
        }
        return new Date();
    };

    return (
        <Calendar
            formatMonth={(locale, curDate) => moment(curDate).format('MMM')}
            formatShortWeekday={(_, dateFormat) => moment(dateFormat, 'DDD').toString()[0]}
            locale="en"
            value={date}
            navigationLabel={(d) => moment(d.date).format(navigationLabelFormat)}
            minDate={getMinDate()}
            maxDate={maxDate}
            selectRange={type === 'rangeCustom'}
            allowPartialRange={allowPartialRange}
            onChange={(day: Date) => {
                setDate(() => {
                    let result: Date | [Date, Date];
                    switch (type) {
                        case 'range':
                            result = getWeekRange(day);
                            break;
                        default:
                            result = day;
                            break;
                    }
                    return result;
                });
                if (onChange) {
                    switch (onChangeReturnType) {
                        case 'date':
                            onChange(day);
                            break;
                        default:
                            onChange(moment(day).isoWeek());
                            break;
                    }
                }
            }}
        />
    );
};

export default CustomCalendar;
