import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import InviteStudentButtonModal from 'src/components/InviteStudentButtonModal';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import DuplicateIcon from 'src/components/Icons/DuplicateIcon';
import HeaderButton from 'src/components/HeaderButton';
import Button from 'src/components/Button';
import ArrowLongThin2Icon from 'src/components/Icons/ArrowLongThin2Icon';
import BanIcon from 'src/components/Icons/BanIcon';
import { useWindowSize } from 'src/hooks/useWindowSize';
import CloseButtonSvg from 'src/components/Icons/CloseButtonSvg';
import InlineConfirm from 'src/components/InlineConfirm';
import { blockStudent, getDuplicatedStudents, mergeStudents } from 'src/redux/users/actions';
import { selectDuplicatedStudents } from 'src/redux/users/selectors';
import NoResults from 'src/components/NoResults';
import { selectLoading } from 'src/redux/loading/selectors';
import UnmatchedStudentsIcon from 'src/components/Icons/UnmatchedStudentsIcon';
import { appConstants } from 'src/constants';
import './styles.scss';

const DuplicateStudents = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector(selectLoading);
    const { isDesktop } = useWindowSize();
    const [currentTab, setCurrentTab] = useState(
        'main' as 'main' | 'whatIsMerge' | 'merge' | 'blockBoth' | 'blockOne',
    );
    const duplicatedStudents = useSelector(selectDuplicatedStudents);
    const [studentsToEdit, setStudentsToEdit] = useState({
        originalUid: {},
        targetUid: {},
    } as { originalUid: any; targetUid: any });
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(putSidebar('root'));
        if (!duplicatedStudents.length) {
            dispatch(getDuplicatedStudents({}));
        }
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch]);
    useEffect(() => {
        dispatch(
            putBreadcrumbs([
                {
                    name: t('general.students'),
                    link: `/all-students`,
                },
                {
                    name: t('general.duplicatedStudents'),
                },
            ]),
        );
    }, []);

    const navigateToStudent = (uid: any) => {
        navigate(`${appConstants.server}/administration/studentmanagement/report/${uid}`);
    };

    const oneStudent = (twoStudents: any, index: any) => {
        const curStudent1 = twoStudents[index];
        const tempStudent = structuredClone(twoStudents);
        tempStudent.splice(index, 1);
        const isBold = () => {
            return tempStudent[0].lastAccess > curStudent1.lastAccess ? 'normal' : 'bold';
        };
        return (
            <div key={`${curStudent1.id}_${index}`} className="duplicateStudents-student">
                <div className="duplicateStudents-student__id">{curStudent1.id}</div>
                <div className="duplicateStudents-student-info">
                    <div className="duplicateStudents-student-info__title">{t('general.name')}</div>
                    <div
                        className="duplicateStudents-student-info__name cursor-pointer"
                        onClick={() => navigateToStudent(curStudent1.uid)}
                    >
                        {curStudent1.name}
                    </div>
                    <div className="duplicateStudents-student-info__title">
                        {t('general.reportedID')}
                    </div>
                    <div
                        className="duplicateStudents-student-info__reported-id cursor-pointer"
                        onClick={() => navigateToStudent(curStudent1.uid)}
                    >
                        {curStudent1.reportedId}
                    </div>
                    <div className="duplicateStudents-student-info__title">
                        {t('general.Email')}
                    </div>
                    <div
                        className="duplicateStudents-student-info__email cursor-pointer"
                        onClick={() => navigateToStudent(curStudent1.uid)}
                    >
                        {curStudent1.mail}
                    </div>
                    <div className="duplicateStudents-student-info__title">
                        {t('general.available')}
                    </div>
                    <div
                        className="duplicateStudents-student-info__available cursor-pointer"
                        onClick={() => navigateToStudent(curStudent1.id)}
                    >
                        {moment.unix(curStudent1.startAccess).format('DD MMM YYYY')} -{' '}
                        {moment.unix(curStudent1.endAccess).format('DD MMM YYYY')}
                    </div>
                    <div className="duplicateStudents-student-info__title">
                        {t('duplicateStudents.lastAccess')}
                    </div>
                    <div
                        className="duplicateStudents-student-info__last-login"
                        onClick={() => navigateToStudent(curStudent1.id)}
                        style={{ fontWeight: isBold() }}
                    >
                        {moment.unix(curStudent1.lastAccess).format('DD MMM YYYY')}
                    </div>
                </div>
                <div className="duplicateStudents-student__block">
                    <Button
                        type="secondary"
                        onClick={() => {
                            setCurrentTab('blockOne');
                            setStudentsToEdit({ originalUid: curStudent1, targetUid: {} });
                        }}
                    >
                        {t('general.block')}
                    </Button>
                </div>
            </div>
        );
    };
    const studentsMain = (coupleOfStudents: any) => {
        return (
            <>
                {oneStudent(coupleOfStudents, 0)}
                <div className="duplicateStudents-merge">
                    <HeaderButton
                        onClick={() => {
                            setCurrentTab('merge');
                            setStudentsToEdit({
                                originalUid: coupleOfStudents[1],
                                targetUid: coupleOfStudents[0],
                            });
                        }}
                    >
                        {t('general.merge')}
                        <ArrowLongThin2Icon />
                    </HeaderButton>
                    <HeaderButton
                        onClick={() => {
                            setCurrentTab('merge');
                            setStudentsToEdit({
                                originalUid: coupleOfStudents[0],
                                targetUid: coupleOfStudents[1],
                            });
                        }}
                    >
                        <ArrowLongThin2Icon />
                        {t('general.merge')}
                    </HeaderButton>
                    <HeaderButton
                        onClick={() => {
                            setCurrentTab('blockBoth');
                            setStudentsToEdit({
                                originalUid: coupleOfStudents[1],
                                targetUid: coupleOfStudents[0],
                            });
                        }}
                    >
                        <BanIcon /> {t('duplicateStudents.blockBothAccounts')}
                    </HeaderButton>
                </div>
                {oneStudent(coupleOfStudents, 1)}
            </>
        );
    };

    const whatMerge = (
        <>
            <div
                className="duplicateStudentsWhatMerge__close-button cursor-pointer"
                onClick={() => setCurrentTab('main')}
            >
                <CloseButtonSvg />
            </div>
            {currentTab === 'whatIsMerge' && (
                <>
                    <div className="duplicateStudentsWhatMerge__title">
                        {t('duplicateStudents.whatIsMergeAndBlock')}
                    </div>
                    <p>{t('duplicateStudents.detectedDuplicateAccount')}.</p>
                    <p>{t('duplicateStudents.duplicateIDReportedByUsers')}. </p>
                    <p>{t('duplicateStudents.mergeDuplicateAccounts')}.</p>
                </>
            )}

            <div className="duplicateStudentsWhatMerge-description">
                <b>{t('duplicateStudents.thisMeans')}:</b>
                <ul>
                    <li>{t('duplicateStudents.blockedUserLogin')}.</li>
                    <li>
                        {t('duplicateStudents.blockedUserRecordInformation')}:
                        <div>
                            <ul>
                                <li>{t('duplicateStudents.tutorialRecords')}.</li>
                                <li>{t('duplicateStudents.eLearningScores')}.</li>
                                <li>{t('duplicateStudents.classingInformation')}.</li>
                                <li>{t('duplicateStudents.progressTestResults')}.</li>
                                <li>{t('duplicateStudents.unitTestResults')}.</li>
                            </ul>
                        </div>
                    </li>
                    <li>{t('duplicateStudents.preservedBlockedUserLogs')}.</li>
                    <li>{t('duplicateStudents.mergeRecordChangeLog')}.</li>
                    <li>{t('duplicateStudents.mergeNotificationEmail')}.</li>
                    <li>{t('duplicateStudents.mergedAccountsInvoiceReports')}.</li>
                </ul>
                <p>{t('duplicateStudents.editStudentProfileAfterMerge')}.</p>
                <b>{t('duplicateStudents.undoNotPossibleMergeProcess')}.</b>
            </div>
        </>
    );

    const blockUser = (count: any) => (
        <>
            <div
                className="duplicateStudentsWhatMerge__close-button cursor-pointer"
                onClick={() => setCurrentTab('main')}
            >
                <CloseButtonSvg />
            </div>

            <div className="duplicateStudentsWhatMerge-description block">
                <b>{t('duplicateStudents.thisMeans')}:</b>
                {count === 1 ? (
                    <ul>
                        <li>{t('duplicateStudents.blockedUserLogin')}.</li>
                        <li>{t('duplicateStudents.keptBlockedUserRecords')}.</li>
                        <li>{t('duplicateStudents.unblockAccountByAPIOrUser')}.</li>
                        <li>{t('duplicateStudents.blockRecordUserAccount')}.</li>
                        <li>{t('duplicateStudents.cancelledAccountInvoiceReports')}.</li>
                    </ul>
                ) : (
                    <ul>
                        <li>{t('duplicateStudents.blockedUserLoginDisabled')}.</li>
                        <li>{t('duplicateStudents.keptBlockedUserAccountsRecords')}.</li>
                        <li>{t('duplicateStudents.unblockAccountsByAPIOrUser')}.</li>
                        <li>{t('duplicateStudents.blockRecordUserAccounts')}.</li>
                        <li>{t('duplicateStudents.cancelledAccountsInvoiceReports')}.</li>
                    </ul>
                )}
            </div>
        </>
    );

    const getNoResultsStyle = () => {
        return !duplicatedStudents.length && currentTab === 'main'
            ? {
                  height: '100%',
                  gridTemplateRows: 'auto 1fr',
              }
            : {};
    };
    const duplicatedStudentsLength =
        duplicatedStudents.length > 9 ? '9+' : duplicatedStudents.length;
    return (
        <div className="duplicateStudents" style={getNoResultsStyle()}>
            <div className="duplicateStudents-header">
                <HeaderButton infoText={duplicatedStudentsLength} active to="/all-students">
                    <DuplicateIcon />
                    <span>{t('duplicateStudents.duplicateStudents')}</span>
                </HeaderButton>
                <InviteStudentButtonModal page="duplicateStudent" />
                <HeaderButton to="/all-students/unmatched-students">
                    <UnmatchedStudentsIcon />
                    <span className="all-students-header__invite-student-title">
                        {t('duplicateStudents.unmatchedInvitedStudents')}
                    </span>
                </HeaderButton>
                <span
                    onClick={() => {
                        if (currentTab === 'whatIsMerge') {
                            setCurrentTab('main');
                        } else {
                            setCurrentTab('whatIsMerge');
                        }
                    }}
                    className="duplicateStudents-what-merge cursor-pointer"
                >
                    {t('duplicateStudents.whatIsMergeAndBlock')}
                </span>
            </div>
            {currentTab === 'main' && (
                <div className="duplicateStudents-items">
                    {!duplicatedStudents.length && !loading && (
                        <NoResults title={t('duplicateStudents.sorryNothingHere')} />
                    )}
                    {duplicatedStudents.map((coupleOfStudents, coupleOfStudentsIndex) => {
                        return (
                            <React.Fragment
                                key={`${coupleOfStudentsIndex}_${coupleOfStudents[0].id}_${coupleOfStudents[0].mail}`}
                            >
                                <hr className="duplicateStudents-hr" />
                                {isDesktop ? (
                                    studentsMain(coupleOfStudents)
                                ) : (
                                    <div className="duplicateStudents-student-mob">
                                        {studentsMain(coupleOfStudents)}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
            {currentTab === 'merge' && (
                <>
                    <hr className="duplicateStudents-hr duplicateStudents-hr_full" />
                    <div className="duplicateStudentsMerge">
                        <div className="duplicateStudentsMerge__title">
                            {t('duplicateStudents.mergeConfirmation')} {studentsToEdit.targetUid.id}{' '}
                            ({t('duplicateStudents.lastAccessed')}:{' '}
                            {moment
                                .unix(studentsToEdit.targetUid.lastAccess)
                                .format('DD / MM / YY')}
                            ) {t('duplicateStudents.into')} {studentsToEdit.originalUid.id} (
                            {t('duplicateStudents.lastAccessed')}:{' '}
                            {moment
                                .unix(studentsToEdit.targetUid.lastAccess)
                                .format('DD / MM / YY')}
                            )
                        </div>
                        {whatMerge}
                        <InlineConfirm
                            onClick={() => {
                                dispatch(
                                    mergeStudents({
                                        originalUid: studentsToEdit.originalUid.uid,
                                        targetUid: studentsToEdit.targetUid.uid,
                                        cb: () => setCurrentTab('main'),
                                    }),
                                );
                            }}
                            onClose={() => {
                                setCurrentTab('main');
                            }}
                            onClickText="Merge"
                            onCloseText="Cancel"
                        >
                            {t('duplicateStudents.areYouSureYouWantToMerge')} "
                            {studentsToEdit.targetUid.name}" (id: {studentsToEdit.targetUid.id}){' '}
                            {t('duplicateStudents.into')} "{studentsToEdit.originalUid.name}" (id:{' '}
                            {studentsToEdit.originalUid.id}) {t('duplicateStudents.andBlock')} "
                            {studentsToEdit.targetUid.name}".
                            <br />
                            {t('duplicateStudents.cannotBeReverted')}
                        </InlineConfirm>
                    </div>
                </>
            )}
            {currentTab === 'blockBoth' && (
                <>
                    <hr className="duplicateStudents-hr duplicateStudents-hr_full" />
                    <div className="duplicateStudentsMerge">
                        <div className="duplicateStudentsMerge__title">
                            {t('duplicateStudents.blockConfirmation')} "
                            {studentsToEdit.targetUid.name}" (id: {studentsToEdit.targetUid.id}){' '}
                            {t('general.and')} "{studentsToEdit.originalUid.name}" (id:{' '}
                            {studentsToEdit.originalUid.id})
                        </div>
                        {blockUser(2)}
                        <InlineConfirm
                            onClick={() => {
                                dispatch(blockStudent({ uid: studentsToEdit.originalUid.uid }));
                                dispatch(
                                    blockStudent({
                                        uid: studentsToEdit.targetUid.uid,
                                        cb: () => {
                                            setCurrentTab('main');
                                        },
                                    }),
                                );
                            }}
                            onClose={() => {
                                setCurrentTab('main');
                            }}
                            onClickText="Block"
                            onCloseText="Cancel"
                        >
                            {t('duplicateStudents.blockConfirmation')} "
                            {studentsToEdit.targetUid.name}" (id: {studentsToEdit.targetUid.id}){' '}
                            {t('general.and')} "{studentsToEdit.originalUid.name}" (id:{' '}
                            {studentsToEdit.originalUid.id})
                        </InlineConfirm>
                    </div>
                </>
            )}
            {currentTab === 'blockOne' && (
                <>
                    <hr className="duplicateStudents-hr duplicateStudents-hr_full" />
                    <div className="duplicateStudentsMerge">
                        <div className="duplicateStudentsMerge__title">
                            {t('duplicateStudents.blockConfirmation')} "
                            {studentsToEdit.originalUid.name}" (id: {studentsToEdit.originalUid.id})
                        </div>
                        {blockUser(1)}
                        <InlineConfirm
                            onClick={() => {
                                dispatch(
                                    blockStudent({
                                        uid: studentsToEdit.originalUid.uid,
                                        cb: () => setCurrentTab('main'),
                                    }),
                                );
                            }}
                            onClose={() => {
                                setCurrentTab('main');
                            }}
                            onClickText={t('general.block')}
                            onCloseText={t('general.Cancel')}
                        >
                            {t('duplicateStudents.blockConfirmation')} "
                            {studentsToEdit.originalUid.name}" (id: {studentsToEdit.originalUid.id})
                        </InlineConfirm>
                    </div>
                </>
            )}

            {currentTab === 'whatIsMerge' && (
                <>
                    <hr className="duplicateStudents-hr duplicateStudents-hr_full" />
                    <div className="duplicateStudentsWhatMerge">{whatMerge}</div>
                </>
            )}
        </div>
    );
};
export default DuplicateStudents;
