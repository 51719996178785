import { Props } from '../types';

const QuestionIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            id="comment-question"
            d="M12,0a12,12,0,0,0,0,24H24V12A12.013,12.013,0,0,0,12,0ZM22,22H12A10,10,0,1,1,22,12ZM15.937,8.283a4.021,4.021,0,0,1-2.009,4.222A2.345,2.345,0,0,0,13,14.257V15H11v-.743a4.312,4.312,0,0,1,1.962-3.5,2,2,0,0,0,1.005-2.125,2.025,2.025,0,0,0-1.6-1.6A2,2,0,0,0,10,9H8A3.991,3.991,0,0,1,9.429,5.936a4,4,0,0,1,6.508,2.348ZM11,17h2v2H11Z"
            fill={fill}
        />
    </svg>
);

export default QuestionIcon;
