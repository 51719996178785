import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getWeeklyPlan, updateWeeklyPlan } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* updateWeeklyPlanSaga({
    payload,
}: ReturnType<typeof updateWeeklyPlan>): SagaIterator {
    try {
        const { classId, week, year, data } = payload;
        yield put(startLoading());
        yield call(API.post(), `/staff/course-planner/${classId}`, data);
        yield put(
            getWeeklyPlan({
                id: payload.classId,
                year: data.year,
                week: data.week,
            }),
        );
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
