import { createAction } from '@reduxjs/toolkit';
import { GetWeeklyPlanProps, WeeklyPlanInitialStateProps, WeeklyPlanType } from './types';

export const GET_WEEKLY_PLAN = 'GET_WEEKLY_PLAN';
export const PUT_WEEKLY_PLAN = 'PUT_WEEKLY_PLAN';
export const UPDATE_WEEKLY_PLAN = 'UPDATE_WEEKLY_PLAN';
export const PUT_ACTIVITIES = 'PUT_ACTIVITIES';
export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
export const MOVE_DAY = 'MOVE_DAY';
export const GET_BOOKS = 'GET_BOOKS';
export const PUT_BOOKS = 'PUT_BOOKS';
export const RESTART = 'RESTART';
export const DELETE_WEEKLY = 'DElETE_WEEKLY';
export const ADD_CLASS_HOLIDAY = 'ADD_CLASS_HOLIDAY';
export const PUT_UNITS = 'PUT_UNITS';
export const GET_ELEARNINGS = 'GET_ELEARNINGS';
export const PUT_ELEARNINGS = 'PUT_ELEARNINGS';
export const GET_ASSIGMENTS = 'GET_ASSIGMENTS';
export const PUT_ASSIGMENTS = 'PUT_ASSIGMENTS';
export const UPDATE_WEEKLY_DAY = 'UPDATE_WEEKLY_DAY';
export const UPDATE_CURATED_HEADS = 'UPDATE_CURATED_HEADS';

export const getWeeklyPlan = createAction<GetWeeklyPlanProps>(GET_WEEKLY_PLAN);
export const putWeeklyPlan = createAction<WeeklyPlanType>(PUT_WEEKLY_PLAN);
export const updateWeeklyPlan = createAction<any>(UPDATE_WEEKLY_PLAN);
export const putActivities = createAction<any>(PUT_ACTIVITIES);
export const clearActivities = createAction(CLEAR_ACTIVITIES);
export const moveDay = createAction<any>(MOVE_DAY);
export const getBooks = createAction<{ classId?: string }>(GET_BOOKS);
export const putBooks = createAction<any>(PUT_BOOKS);
export const restart = createAction<{
    week: number;
    year: number;
    unit: string;
    classId: number | string | undefined;
    fullSet: boolean;
    curated: boolean;
    cb?: () => void;
}>(RESTART);
export const deleteWeekly = createAction<any>(DELETE_WEEKLY);
export const addClassHoliday = createAction<any>(ADD_CLASS_HOLIDAY);
export const putUnits = createAction<any>(PUT_UNITS);
export const getElearning = createAction<any>(GET_ELEARNINGS);
export const putElearning = createAction<any>(PUT_ELEARNINGS);
export const getAssigments = createAction<any>(GET_ASSIGMENTS);
export const putAssigments = createAction<any>(PUT_ASSIGMENTS);
export const updateWeeklyDay = createAction<any>(UPDATE_WEEKLY_DAY);
export const updateWeeklyPlanCuratedHeads =
    createAction<WeeklyPlanInitialStateProps['weeklyPlanCuratedHeads']>(UPDATE_CURATED_HEADS);
