import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getAssessment, putAssessment } from '../actions';

export function* getAssessmentSaga({ payload }: ReturnType<typeof getAssessment>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/classes/${payload.classId}/assessments/types/${payload.typeId}`,
        );
        yield put(putAssessment(data));
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
