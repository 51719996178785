import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { putApiRequestError } from '../../error/actions';
import { saveUnitTest } from '../actions';

export function* saveUnitTestSaga({ payload }: ReturnType<typeof saveUnitTest>): SagaIterator {
    try {
        const { classId, data, cb } = payload;
        yield put(startLoading());
        yield call(API.post(), `/staff/tests/${classId}`, data);
        cb();
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
