import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import WhiteBox from 'src/components/WhiteBox';
import TabBar from 'src/components/TabBar';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { putSidebar } from 'src/redux/layout/actions';
import Button from 'src/components/Button';
import Add from 'src/components/Icons/Add';
import { getStaffEmailsLog } from 'src/redux/staff/actions';
import { appConstants } from 'src/constants';
import EmailsEmailLog from './EmailLog';
import EmailsMessageCentre from './MessageCenter';
import EmailsDateRangeFilter from './EmailsDateRangeFilter';
import './styles.scss';

const Emails = () => {
    const { t } = useTranslation();
    const MESSAGE_CENTRE = t('emails.messageCentre');
    const EMAIL_LOG = t('emails.emailLog');
    const [tab, setTab] = useState(MESSAGE_CENTRE);
    const { isDesktop } = useWindowSize();
    const params = useParams();
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([new Date(), new Date()] as [Date] | [Date, Date]);

    useEffect(() => {
        dispatch(putSidebar('root'));
        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);
    return (
        <>
            <WhiteBox
                styles={
                    isDesktop
                        ? { height: '100%' }
                        : {
                              background: 'unset',
                              boxShadow: 'unset',
                              borderRadius: 'unset',
                              height: '100%',
                          }
                }
            >
                <div className="emails">
                    <div className="emails-tabBar">
                        <div className="emails-tabs">
                            <TabBar
                                activeTab={tab}
                                tabs={[{ name: MESSAGE_CENTRE }, { name: EMAIL_LOG }].map(
                                    (item) => {
                                        return {
                                            tabId: item.name,
                                            label: item.name,
                                            onClick: () => {
                                                setTab(item.name);
                                            },
                                        };
                                    },
                                )}
                            />
                        </div>
                        <EmailsDateRangeFilter
                            tab={tab}
                            EMAIL_LOG={EMAIL_LOG}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            mob={false}
                        />
                        <div
                            className={classNames('emailLog-filter__button', {
                                'd-none': tab !== EMAIL_LOG || !isDesktop,
                            })}
                        >
                            <Button
                                onClick={() => {
                                    dispatch(
                                        getStaffEmailsLog({ from: dateRange[0], to: dateRange[1] }),
                                    );
                                }}
                                type="secondary"
                            >
                                {t('general.search')}
                            </Button>
                        </div>
                        <div
                            className={classNames({
                                'd-none': !isDesktop || tab !== MESSAGE_CENTRE,
                            })}
                        >
                            <Button
                                onClick={() => {
                                    window.open(`${appConstants.server}/messagecentre/add`);
                                }}
                                type="square"
                            >
                                <>
                                    <Add />
                                    {t('emails.createNewEmail')}
                                </>
                            </Button>
                        </div>
                    </div>
                    <div className="emails-items">
                        <div
                            className={classNames('emails-items__mob-button-add', {
                                'd-none': isDesktop || tab !== MESSAGE_CENTRE,
                            })}
                        >
                            <Button
                                onClick={() => {
                                    window.open(`${appConstants.server}/messagecentre/add`);
                                }}
                                type="square"
                            >
                                <>
                                    <Add />
                                    {t('emails.createNewEmail')}
                                </>
                            </Button>
                        </div>
                        <EmailsDateRangeFilter
                            tab={tab}
                            EMAIL_LOG={EMAIL_LOG}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            mob
                        />
                        <div
                            className={classNames('emailLog-filter__button', {
                                'd-none': tab !== EMAIL_LOG || isDesktop,
                            })}
                        >
                            <Button
                                onClick={() => {
                                    dispatch(
                                        getStaffEmailsLog({ from: dateRange[0], to: dateRange[1] }),
                                    );
                                }}
                                type="secondary"
                            >
                                {t('general.search')}
                            </Button>
                        </div>
                        {tab === MESSAGE_CENTRE && <EmailsMessageCentre />}
                        {tab === EMAIL_LOG && <EmailsEmailLog />}
                    </div>
                </div>
            </WhiteBox>
        </>
    );
};

export default Emails;
