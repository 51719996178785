import { Props } from '../types';

const Pencil = ({ fill = '#bcbcbc' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.119"
        height="18.093"
        viewBox="0 0 18.119 18.093"
    >
        <g id="Edit" transform="translate(4.159 7.096)">
            <g
                id="_01_align_center"
                data-name=" 01 align center"
                transform="translate(-4.159 -7.096)"
            >
                <path
                    id="Path_458"
                    data-name="Path 458"
                    d="M106.667,19.327h4.216L123.914,6.3a2.985,2.985,0,0,0,0-4.216,3.051,3.051,0,0,0-4.216,0L106.667,15.111Zm1.91-3.425L121.049,3.43a1.1,1.1,0,0,1,1.515,0,1.073,1.073,0,0,1,0,1.515L110.092,17.417h-1.515Z"
                    transform="translate(-106.667 -1.234)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export default Pencil;
