import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import TabBar from 'src/components/TabBar';
import { putAllStudents } from 'src/redux/allStudents/actions';
import { allStudentsInitialState } from 'src/redux/allStudents/reducer';
import { getClass } from 'src/redux/classes/actions';
import { selectClass } from 'src/redux/classes/selectors';
import { putBreadcrumbs, putSidebar } from 'src/redux/layout/actions';
import { getStudent } from 'src/redux/students/actions';
import { selectStudent } from 'src/redux/students/selectors';
import { useTranslation } from 'react-i18next';
import ProfileEdit from './ProfileEdit';
import ProfileInfo from './ProfileInfo';
import ProfileReport from './ProfileReport';
import './styles.scss';

const Profile = ({ match }: { match: string }) => {
    const dispatch = useDispatch();
    const profile = useSelector(selectStudent);
    const params = useParams();
    const classData = useSelector(selectClass);
    const location = useLocation() as any;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (classData.id && params.classId) {
            dispatch(putSidebar('classSelected'));
            dispatch(
                putBreadcrumbs([
                    {
                        name: `${t('general.class')} ${classData.name}`,
                        link: `weekly-plan/${classData.id}`,
                    },
                    {
                        name: t('general.students'),
                        link: `students/${classData.id}`,
                    },
                    {
                        name: `${profile.name?.slice(0, 100) ?? t('general.notAvailable')}`,
                    },
                ]),
            );
        } else if (
            (location.state && location.state?.allStudents) ||
            location.pathname.includes('/all-students/')
        ) {
            dispatch(putSidebar('root'));
            dispatch(
                putBreadcrumbs([
                    {
                        name: t('general.students'),
                        link: `/all-students`,
                    },
                    {
                        name: `${profile.name?.slice(0, 100) ?? t('general.notAvailable')}`,
                    },
                ]),
            );
        }
    }, [profile, classData]);

    useEffect(() => {
        if (params.id) {
            dispatch(getStudent({ uid: +params.id }));
        }
        if (params.classId) {
            dispatch(
                getClass({
                    classId: params.classId,
                }),
            );
        }
        return () => {
            dispatch(putAllStudents(allStudentsInitialState.allStudents));
            dispatch(putSidebar());
        };
    }, []);

    return (
        <div className="profile">
            <TabBar
                mobUnderline
                activeTab={params.tab}
                tabs={[
                    {
                        tabId: 'profile',
                        label: t('profile.profile'),
                        onClick: () => {
                            navigate(
                                generatePath(match, {
                                    ...params,
                                    tab: 'profile',
                                }),
                            );
                        },
                    },
                    {
                        tabId: 'dashboard',
                        label: t('profile.dashboard'),
                        onClick: () => {
                            navigate(
                                generatePath(match, {
                                    ...params,
                                    tab: 'dashboard',
                                }),
                            );
                        },
                    },
                ]}
            />
            {params.tab === 'profile' && (
                <>
                    {searchParams.get('action') === 'edit' ? (
                        <ProfileEdit profile={profile} />
                    ) : (
                        <ProfileInfo profile={profile} />
                    )}
                </>
            )}
            {params.tab === 'dashboard' && <ProfileReport />}
        </div>
    );
};
export default Profile;
