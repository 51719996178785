import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import {
    assignPackages,
    getCredits,
    getStudent,
    getStudentMarking,
    getStudentUpgrades,
    getUpgrades,
} from 'src/redux/students/actions';
import SuccessModal from 'src/components/Modals/SuccessModal';
import { selectStudentCredits, selectUpgrades } from 'src/redux/students/selectors';

import './styles.scss';
import SelectMenu from 'src/components/SelectMenu';
import Check from 'src/components/Icons/Check';

const ProfileInfoAssignPackagesModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<{
        id: number;
        name: string;
    } | null>(null);
    const credits = useSelector(selectStudentCredits);
    const upgrades = useSelector(selectUpgrades);
    useEffect(() => {
        if (open) {
            dispatch(getCredits());
            dispatch(getUpgrades());
            setSelectedPackage(null);
        }
    }, [open]);

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            disableOutsideClick={openSuccessModal}
            open={open}
            noPadding
            onClose={() => {
                setOpenSuccessModal(false);
                onClose();
            }}
            className="creditModal packageModal"
        >
            <div className="creditModal-content">
                <div className="creditModal-head">
                    <span>{t('profile.assignCredits')}</span>
                </div>
                <div className="creditModal-body">
                    <div className="creditModal-assign">
                        <div className="packageModal-assign-body">
                            <span>{t('profile.availablePackages')}</span>
                            <SelectMenu
                                alwaysWide
                                wrapperClass="packageModal-select"
                                text={
                                    <div>
                                        <div className="restart-truncate packageModal-select-title">
                                            {selectedPackage
                                                ? selectedPackage.name
                                                : t('general.select')}
                                        </div>
                                        <div className="restart-truncate packageModal-select-title-active">
                                            {t('general.select')}
                                        </div>
                                    </div>
                                }
                                options={upgrades.map((item) => {
                                    return {
                                        content: (
                                            <div className="packageModal-select-item">
                                                {selectedPackage?.id === item.id ? (
                                                    <Check />
                                                ) : (
                                                    <div style={{ width: '20px' }} />
                                                )}
                                                <span>{item.name}</span>
                                            </div>
                                        ),
                                        onClick: () => {
                                            setSelectedPackage(item);
                                        },
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="creditModal-buttons">
                    <Button
                        type="outline"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        type="secondary"
                        onClick={() => {
                            dispatch(
                                assignPackages({
                                    id: params.id as string,
                                    packageId: selectedPackage?.id || 0,
                                    cb: () => {
                                        dispatch(
                                            getStudentUpgrades({
                                                dataType: 'ielts/upgrades',
                                                studentId: params.id as string,
                                            }),
                                        );
                                        dispatch(getStudent({ uid: +params.id! }));
                                        setOpenSuccessModal(true);
                                        dispatch(
                                            getStudentMarking({
                                                dataType: 'ielts/markings',
                                                studentId: params.id as string,
                                            }),
                                        );
                                    },
                                }),
                            );
                        }}
                        disabled={!selectedPackage}
                    >
                        {t('general.OK')}
                    </Button>
                </div>
                <SuccessModal
                    lottie
                    open={openSuccessModal}
                    text="Package assigned."
                    onClose={() => {
                        setOpenSuccessModal(false);
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ProfileInfoAssignPackagesModal;
