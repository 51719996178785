import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { PutStudentProps } from 'src/redux/students/types';
import { selectLayout } from 'src/redux/layout/selectors';
import { getNoEmpty } from 'src/helpers/getNoEmpty';
import { getInlineLangLevel } from 'src/helpers/getInlineLangLevel';
import { profileGetExclamationIcon } from 'src/pages/Profile/profileGetExclamationIcon';
import HeaderButton from 'src/components/HeaderButton';
import Button from 'src/components/Button';
import Pencil from 'src/components/Icons/Pencil';
import CalendarX from 'src/components/Icons/CalendarX';
import FileLock from 'src/components/Icons/FileLock';
import InputCalendar from 'src/components/InputCalendar';
import { updateStudent } from 'src/redux/students/actions';
import Modal from 'src/components/Modal';
import './styles.scss';

const ActionPopup = ({
    close,
    action,
    profile,
}: {
    close: () => void;
    action: null | 'end' | 'block';
    profile: PutStudentProps;
}) => {
    const { t } = useTranslation();
    const [courseEndDate, setCourseEndDate] = useState(profile.endDate);
    const [accessEndDate, setAccessEndDate] = useState(profile.endAccess);
    const dispatch = useDispatch();

    useEffect(() => {
        setCourseEndDate(profile.endDate);
        setAccessEndDate(profile.endAccess);
    }, [profile]);

    if (!action) {
        return null;
    }

    const handleConfirm = () => {
        dispatch(
            updateStudent({
                data: {
                    ...(action === 'end' && { endDate: courseEndDate, endAccess: accessEndDate }),
                    ...(action === 'block' && { status: false }),
                },
                studentId: profile.uid,
                cb: () => {
                    close();
                },
            }),
        );
    };

    return (
        <Modal open={!!action} onClose={close} noPadding>
            <div className="qrcodes-popup">
                <div className="qrcodes-popup-head">
                    {action === 'end' ? t('profile.endStudentCourse') : t('profile.blockAccess')}
                </div>
                <div className="qrcodes-popup-content">
                    {action === 'end' ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                maxWidth: '15rem',
                                margin: '0 auto',
                            }}
                        >
                            <div>
                                {t('profile.courseEndDate')}
                                <InputCalendar
                                    isUtc
                                    onChange={(e: any) => {
                                        setCourseEndDate(e / 1000);
                                    }}
                                    date={new Date((courseEndDate || 0) * 1000)}
                                />
                            </div>
                            <div>
                                {t('profile.accessEndDate')}
                                <InputCalendar
                                    isUtc
                                    onChange={(e: any) => {
                                        setAccessEndDate(e / 1000);
                                    }}
                                    date={new Date((accessEndDate || 0) * 1000)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: t('profile.willBlock') }} />
                    )}
                </div>
                <div className="qrcodes-popup-footer">
                    <Button type="outline-grey" onClick={() => close()} minWidth={80}>
                        {t('general.cancel')}
                    </Button>
                    <Button maxWidth={80} type="secondary" onClick={() => handleConfirm()}>
                        {t('general.OK')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const ProfileMain = ({ profile }: { profile: PutStudentProps }) => {
    const { t } = useTranslation();
    const [, setSearchParams] = useSearchParams();
    const layout = useSelector(selectLayout);
    const { isModuleActive, getModuleName } = useIsModuleActive();
    const [action, setAction] = useState<null | 'end' | 'block'>(null);

    if (!isModuleActive(Modules.StudentProfileDetails)) {
        return null;
    }

    return (
        <div className="profileCard student_profile_details">
            <div className="profile-main">
                <div className="profile-main__title profile-title">
                    {profile.name}
                    <div className="profile-main-subtitle">
                        {getModuleName(Modules.StudentProfileDetails) || t('general.student')}
                    </div>
                </div>
                <div style={{ height: 'max-content' }}>
                    <div className="profile-main__buttons">
                        <HeaderButton
                            onClick={() => {
                                setSearchParams('action=edit');
                            }}
                        >
                            <>
                                <Pencil fill="#54585A" />
                                {t('general.edit')}
                            </>
                        </HeaderButton>
                        <div className="mobile-hidden">
                            <Button
                                type="outline-grey"
                                onClick={() => setAction('end')}
                                padding="0.75rem"
                            >
                                <div
                                    style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                                >
                                    <CalendarX />
                                    {t('profile.endCourse')}
                                </div>
                            </Button>
                        </div>
                        <div className="mobile-hidden">
                            <Button
                                type="square"
                                onClick={() => setAction('block')}
                                padding="0.75rem"
                                className="svg-stroke-hover-color-white"
                            >
                                <div
                                    className="profile-main__buttons-button-content"
                                    style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                                >
                                    <FileLock />
                                    {t('profile.blockAccess')}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-main__buttons-mobile">
                <Button
                    type="outline-grey"
                    onClick={() => setAction('end')}
                    padding="0.75rem"
                    minWidth={160}
                    maxWidth={160}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <CalendarX />
                        {t('profile.endCourse')}
                    </div>
                </Button>
                <Button
                    type="square"
                    onClick={() => setAction('block')}
                    padding="0.75rem"
                    className="svg-stroke-hover-color-white"
                    minWidth={160}
                    maxWidth={160}
                >
                    <div
                        className="profile-main__buttons-button-content"
                        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                    >
                        <FileLock />
                        {t('profile.blockAccess')}
                    </div>
                </Button>
            </div>
            <hr />
            <div className="profile-main-info">
                <div>
                    <div>ID</div>
                    <div>{getNoEmpty(profile.username)}</div>
                    <div>{t('profile.reportedID')}</div>
                    <div>
                        {getNoEmpty(profile.reportedId)}
                        {profileGetExclamationIcon(profile.username, profile.reportedId)}
                    </div>
                    <div>{t('general.school')}</div>
                    <div>{getNoEmpty(profile.school?.name)}</div>
                    <div>{t('general.class')}</div>
                    <div>
                        {profile.classes?.length &&
                        profile.classes[0]?.from < moment().unix() &&
                        profile.classes[0]?.to > moment().unix()
                            ? profile.classes[0]?.name
                            : 'N/A'}
                    </div>
                    <div>{t('profile.startDate')}</div>
                    <div>{moment.unix(profile.startDate).format('DD. MMM YYYY.')}</div>
                    <div>{t('profile.endDate')}</div>
                    <div>{moment.unix(profile.endDate).format('DD. MMM YYYY.')}</div>
                </div>
                <div>
                    <div>{t('general.Email')}</div>
                    <div>{getNoEmpty(profile.mail)}</div>
                    <div>{t('profile.endAccessDate')}</div>
                    <div>{moment.unix(profile.endAccess).format('DD. MMM YYYY.')}</div>
                    <div>{t('profile.status')}</div>
                    <div>{profile.status ? t('general.active') : t('general.cancelled')}</div>
                    {!layout.layout.isIelts && (
                        <>
                            <div>{t('profile.studyPlanLevel')}</div>
                            <div>{getInlineLangLevel(profile.level, t)}</div>
                            <div>{t('profile.courseCode')}</div>
                            <div>
                                {profile.courseCode === '' || !profile.courseCode
                                    ? 'N/A'
                                    : profile.courseCode}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ActionPopup action={action} close={() => setAction(null)} profile={profile} />
        </div>
    );
};

export default ProfileMain;
