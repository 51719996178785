import { Props } from '../types';

const FileLock = ({ fill = '#007FA3' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path d="M9.5 2.5V6H13" stroke={fill} />
        <path
            d="M10.5 14.5H12.5C12.6326 14.5 12.7598 14.4473 12.8536 14.3536C12.9473 14.2598 13 14.1326 13 14V6L9.5 2.5H3.5C3.36739 2.5 3.24021 2.55268 3.14645 2.64645C3.05268 2.74021 3 2.86739 3 3V8.5"
            stroke={fill}
        />
        <path d="M8 11.75H3.5V14.5H8V11.75Z" stroke={fill} />
        <path
            d="M4.5 11.75V11C4.5 10.6685 4.6317 10.3505 4.86612 10.1161C5.10054 9.8817 5.41848 9.75 5.75 9.75C6.08152 9.75 6.39946 9.8817 6.63388 10.1161C6.8683 10.3505 7 10.6685 7 11V11.75"
            stroke={fill}
        />
    </svg>
);

export default FileLock;
