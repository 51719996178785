import { createAction } from '@reduxjs/toolkit';
import {
    GetLayoutProps,
    PutBreadcrumbsProps,
    PutLayoutProps,
    PutLayoutUserFieldsProps,
    PutModuleProps,
} from './types';

export const PUT_BREADCRUMBS = 'PUT_BREADCRUMBS';
export const PUT_SIDEBAR = 'PUT_SIDEBAR';
export const GET_LAYOUT = 'GET_LAYOUT';
export const PUT_LAYOUT = 'PUT_LAYOUT';
export const PUT_MODULES = 'PUT_MODULES';
export const PUT_LAYOUT_USER_FIELDS = 'PUT_LAYOUT_USER_FIELDS';

export const putBreadcrumbs = createAction<PutBreadcrumbsProps[]>(PUT_BREADCRUMBS);
export const putSidebar = createAction<'classSelected' | 'root' | undefined>(PUT_SIDEBAR);
export const getLayout = createAction<GetLayoutProps | undefined>(GET_LAYOUT);
export const putLayout = createAction<PutLayoutProps>(PUT_LAYOUT);
export const putModules = createAction<PutModuleProps>(PUT_MODULES);
export const putLayoutUserFields = createAction<PutLayoutUserFieldsProps>(PUT_LAYOUT_USER_FIELDS);
