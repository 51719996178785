import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getStudents, putStudent } from '../actions';

export function* getStudentSaga({ payload }: ReturnType<typeof getStudents>): SagaIterator {
    try {
        yield put(startLoading());
        if (payload.classId === undefined) {
            const data = yield call(API.get(), `/staff/students/${payload.uid}`);
            yield put(putStudent(data));
        } else {
            const data = yield call(
                API.get(),
                `/staff/classes/${payload.classId}/students/${payload.uid}`,
            );
            yield put(putStudent(data));
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
