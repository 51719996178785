import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectLayout } from 'src/redux/layout/selectors';
import { putLayoutUserFields } from 'src/redux/layout/actions';
import { TooltipProps } from './types';
import CloseButton from '../CloseButton';
import './styles.scss';

const Tooltip = ({ id, desc, styles, hideButton = false }: TooltipProps) => {
    const dispatch = useDispatch();
    const { layout } = useSelector(selectLayout);
    const [isShown, setIsShown] = useState(layout.user.fields[id] === '1');

    const closeTooltip = () => {
        setIsShown(true);
        dispatch(putLayoutUserFields({ name: id, value: '1' }));
    };

    if (isShown) return null;

    return (
        <div className="tooltip" style={styles}>
            {desc}
            {!hideButton && <CloseButton onClick={() => closeTooltip()} />}
        </div>
    );
};

export default Tooltip;
