import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { TIME } from 'src/constants';
import './styles.scss';
import { InputTextareaProps } from './types';

const InputTextarea = ({ fullWidth, defaultValue, error, onChange }: InputTextareaProps) => {
    const handleOnChange = _.debounce((e) => {
        onChange(e.target.value);
    }, TIME.inputDebounce);

    return (
        <div className="inputTextarea">
            <textarea
                data-testid="inputTextareaFormik"
                defaultValue={defaultValue}
                className={classNames('inputTextarea__field', {
                    invalid: error,
                    fullWidth,
                })}
                onChange={handleOnChange}
            />
            <div className="inputTextarea-error">{error}</div>
        </div>
    );
};

export default InputTextarea;
