const SearchIcon = () => (
    <svg
        id="Search"
        xmlns="http://www.w3.org/2000/svg"
        width="24.989"
        height="25"
        viewBox="0 0 24.989 25"
    >
        <g id="Group_268" data-name="Group 268">
            <path
                id="Path_315"
                data-name="Path 315"
                d="M.216,24.778A.73.73,0,0,0,.743,25a.753.753,0,0,0,.527-.222L7.339,18.7A10.728,10.728,0,1,0,6.292,17.65L.216,23.725A.749.749,0,0,0,.216,24.778Zm14.124-4.95A9.166,9.166,0,1,1,23.5,10.66,9.178,9.178,0,0,1,14.34,19.828Z"
                transform="translate(0)"
                fill="#565656"
            />
        </g>
    </svg>
);

export default SearchIcon;
