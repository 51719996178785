import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { finishLoading, startLoading } from '../../loading/actions';
import { getWeeklyPlan, moveDay } from '../actions';
import { putApiRequestError } from '../../error/actions';

export function* moveDaySaga({ payload }: ReturnType<typeof moveDay>): SagaIterator {
    try {
        const { classId, resp } = payload;
        yield put(startLoading());

        const data = yield call(
            API.post(),
            `/staff/course-planner/${classId}/manipulations/move/day`,
            resp,
        );

        yield put(
            getWeeklyPlan({
                id: classId,
                year: resp.from.year,
                week: resp.from.week,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
