import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import Modal from '../../Modal';
import './styles.scss';
import { Props } from './types';

const CommentSaveModal = ({ open, onSave, comment, onClose }: Props) => {
    const [textToSave, setTextToSave] = useState(comment || '');
    const { t } = useTranslation();

    const onCloseChild = () => {
        onClose();
        setTextToSave('');
    };

    const onSaveChild = (textForSave: any) => {
        const oldTextToSave = structuredClone(textForSave);
        setTextToSave('');
        onSave(oldTextToSave);
    };

    useEffect(() => {
        if (comment) {
            setTextToSave(comment || '');
        }
        return () => {
            setTextToSave('');
        };
    }, [comment]);

    useEffect(() => {
        if (textToSave === '' && !open) {
            setTextToSave(comment || '');
        }
    }, [textToSave]);

    return (
        <>
            <Modal open={open} onClose={onCloseChild}>
                <div className="comment-save-modal">
                    <span className="comment-save-modal__title">
                        {t('modals.commentSave.comment')}
                    </span>
                    <textarea
                        className="comment-save-modal__textarea"
                        value={textToSave}
                        onChange={(e) => setTextToSave(e.target.value)}
                    />
                    <div className="comment-save-modal__button">
                        <Button type="square" onClick={() => onSaveChild(textToSave)}>
                            {t('modals.commentSave.saveComment')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CommentSaveModal;
