import { createReducer } from '@reduxjs/toolkit';
import { StudentsData } from 'src/pages/Students/types';
import {
    PUT_CREDITS,
    PUT_STUDENT,
    PUT_STUDENT_AUDIT,
    PUT_STUDENT_EMAILS,
    PUT_STUDENT_MARKING,
    PUT_STUDENT_RESULTS_HISTORY,
    PUT_STUDENT_TESTS,
    PUT_STUDENT_TUTORIALS,
    PUT_STUDENT_UPGRADES,
    PUT_STUDENTS,
    PUT_UPGRADES,
} from './actions';
import {
    PutCreditsProps,
    PutStudentAuditAP,
    PutStudentEmailsAP,
    PutStudentMarkingAP,
    PutStudentProps,
    PutStudentResultsHistoryAP,
    PutStudentTestsAP,
    PutStudentTutorialsAP,
    PutStudentUpgradesAP,
    PutUpgradesProps,
} from './types';

export type StudentState = {
    student: PutStudentProps;
    resultsHistory: PutStudentResultsHistoryAP;
    audit: PutStudentAuditAP;
    emails: PutStudentEmailsAP;
    tutorials: PutStudentTutorialsAP;
    tests: PutStudentTestsAP;
    markings: PutStudentMarkingAP;
    studentUpgrades: PutStudentUpgradesAP;
    credits: PutCreditsProps;
    upgrades: PutUpgradesProps;
};

const studentInitial = {
    tests: [
        {
            id: 0,
            testId: 0,
            timestamp: 0,
            name: '',
            score: 0,
            type: '',
        },
    ],
    tutorials: [
        {
            id: '0',
            timestamp: 0,
        },
    ],
    emails: [
        {
            subject: '',
            timestamp: 0,
        },
    ],
    audit: [
        {
            action: '',
            timestamp: 0,
            username: null,
        },
    ],
    resultsHistory: [
        {
            actionType: '',
            id: '',
            attempt: 0,
            datetime: 0,
            score: '',
            scoreType: '',
            paper: '',
            type: '',
            review: null,
            context: null,
            contextId: null,
        },
    ],
    student: {
        name: '',
        reportedId: '',
        note: '',
        status: false,
        level: 0,
        school: {
            gid: 0,
            name: '',
        },
        classes: [
            {
                id: 0,
                name: '',
                from: 0,
                to: 0,
            },
        ],
        uid: 0,
        username: '',
        mail: '',
        startDate: 0,
        endDate: 0,
        endAccess: 0,
        courseCode: '',
    },
    markings: {
        general: 0,
        ai: 0,
        trial: false,
        history: [],
    },
    studentUpgrades: [],
    credits: {
        general: 0,
        ai: 0,
    },
    upgrades: [],
};

export const studentReducer = createReducer<StudentState>(studentInitial, {
    [PUT_STUDENT]: (state, action) => {
        state.student = action.payload;
    },
    [PUT_STUDENT_RESULTS_HISTORY]: (state, action) => {
        state.resultsHistory = action.payload;
    },
    [PUT_STUDENT_AUDIT]: (state, action) => {
        state.audit = action.payload;
    },
    [PUT_STUDENT_EMAILS]: (state, action) => {
        state.emails = action.payload;
    },
    [PUT_STUDENT_TUTORIALS]: (state, action) => {
        state.tutorials = action.payload;
    },
    [PUT_STUDENT_TESTS]: (state, action) => {
        state.tests = action.payload;
    },
    [PUT_STUDENT_MARKING]: (state, action) => {
        state.markings = action.payload;
    },
    [PUT_STUDENT_UPGRADES]: (state, action) => {
        state.studentUpgrades = action.payload;
    },
    [PUT_CREDITS]: (state, action) => {
        state.credits = action.payload;
    },
    [PUT_UPGRADES]: (state, action) => {
        state.upgrades = action.payload;
    },
});

export type StudentsState = {
    students: StudentsData[];
};

const initialState = {
    students: [],
};

export const studentsReducer = createReducer<StudentsState>(initialState, {
    [PUT_STUDENTS]: (state, action) => {
        state.students = action.payload;
    },
});
