import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { InputTextDropdownSelectProps } from './props';

const OPTIONS_BOTTOM_PADDING = 70;
const OPTIONS_MIN_HEIGHT = 250;

const InputTextDropdownSelect = ({
    options,
    onClick,
    dropdownIndex,
    button,
    open,
    dropdownMaxHeight,
}: InputTextDropdownSelectProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const wrapperRect = wrapperRef.current?.getBoundingClientRect();
    const [maxHeight, setMaxHeight] = useState('200px');
    const windowSize = useWindowSize();

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            const tempWrapperRect = wrapperRef.current.getBoundingClientRect();
            const curMaxHeight = window.innerHeight - tempWrapperRect.y - OPTIONS_BOTTOM_PADDING;
            const dropHeight =
                curMaxHeight < OPTIONS_MIN_HEIGHT ? OPTIONS_MIN_HEIGHT : curMaxHeight;
            if (dropdownMaxHeight) {
                setMaxHeight(
                    `${dropHeight < dropdownMaxHeight ? dropHeight : dropdownMaxHeight}px`,
                );
            } else {
                setMaxHeight(`${dropHeight}px`);
            }
        }
    }, [windowSize, options, open]);

    const optionRef = useRef<HTMLInputElement[]>([]);

    if (!options.length || !open) {
        return <></>;
    }

    const scrollDropdownByKeyboard = (
        idx: number,
        curDropdownIndex: number | string,
        curWrapperRect: DOMRect | undefined,
        curWrapperRef: { current: HTMLDivElement | null },
    ) => {
        if (idx === curDropdownIndex && curWrapperRect && curWrapperRef.current) {
            const item = optionRef.current[idx];
            const itemRect = item.getBoundingClientRect();
            if (button === 'ArrowDown' && itemRect.bottom > curWrapperRect.bottom) {
                curWrapperRef.current.scrollTop += item.offsetHeight;
            } else if (button === 'ArrowUp' && itemRect.top < curWrapperRect.top) {
                curWrapperRef.current.scrollTop -= item.offsetHeight;
            }
        }
    };
    return (
        <div className="inputTextDropdown-select" data-testid="inputTextDropdown__dropdown">
            <div
                ref={wrapperRef}
                style={
                    maxHeight
                        ? {
                              maxHeight,
                          }
                        : {}
                }
                className="inputTextDropdown-select-options"
            >
                {options.map((option, idx) => {
                    scrollDropdownByKeyboard(idx, dropdownIndex, wrapperRect, wrapperRef);
                    return (
                        <div
                            ref={(element) => {
                                optionRef.current[idx] = element as HTMLInputElement;
                            }}
                            key={`${option.title}${option.id}`}
                            onClick={() => {
                                onClick(idx);
                            }}
                            className={classNames(
                                'inputTextDropdown-select__option qr_hover_color_text_svg_bg',
                                {
                                    active: idx === dropdownIndex,
                                },
                            )}
                        >
                            {option.title}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default InputTextDropdownSelect;
