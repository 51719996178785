import { Props } from '../types';

const ListIcon = ({ fill = '#676E7A' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill={fill}>
        <path
            d="M9.20898 4.54C9.20898 3.99 8.75898 3.54 8.20898 3.54H1.20898C0.658984 3.54 0.208984 3.99 0.208984 4.54C0.208984 5.09 0.658984 5.54 1.20898 5.54H8.20898C8.75898 5.54 9.20898 5.09 9.20898 4.54ZM9.20898 12.54C9.20898 11.99 8.75898 11.54 8.20898 11.54H1.20898C0.658984 11.54 0.208984 11.99 0.208984 12.54C0.208984 13.09 0.658984 13.54 1.20898 13.54H8.20898C8.75898 13.54 9.20898 13.09 9.20898 12.54ZM15.259 6.83C14.869 7.22 14.239 7.22 13.849 6.83L11.729 4.71C11.339 4.32 11.339 3.69 11.729 3.3C12.119 2.91 12.749 2.91 13.139 3.3L14.549 4.71L18.089 1.17C18.479 0.780002 19.109 0.780002 19.499 1.17C19.889 1.56 19.889 2.19 19.499 2.58L15.259 6.83ZM15.259 14.83C14.869 15.22 14.239 15.22 13.849 14.83L11.729 12.71C11.339 12.32 11.339 11.69 11.729 11.3C12.119 10.91 12.749 10.91 13.139 11.3L14.549 12.71L18.089 9.17C18.479 8.78 19.109 8.78 19.499 9.17C19.889 9.56 19.889 10.19 19.499 10.58L15.259 14.83Z"
            fill={fill}
        />
    </svg>
);

export default ListIcon;
