import { useEffect, useState } from 'react';
import RadioButtonOffIcon from 'src/components/Icons/RadioButtonOffIcon';
import RadioButtonOnIcon from 'src/components/Icons/RadioButtonOnIcon';
import './styles.scss';

type InputRadioButtonsType = {
    buttons: {
        label: string;
        value: string | null | number;
    }[];
    checkedIndex?: number;
    onChange: (button: InputRadioButtonsType['buttons'][number]) => void;
};

const InputRadioButtons = ({ buttons, checkedIndex, onChange }: InputRadioButtonsType) => {
    const [curCheckedIndex, setCurCheckedIndex] = useState(checkedIndex);

    useEffect(() => {
        setCurCheckedIndex(checkedIndex);
    }, [checkedIndex]);

    return (
        <div className="input-radio-buttons" onClick={() => {}}>
            {buttons.map((button, index) => {
                return (
                    <div
                        key={index}
                        className="input-radio-button"
                        onClick={() => {
                            setCurCheckedIndex(index);
                            onChange(button);
                        }}
                    >
                        <div className="input-radio-button-icon">
                            {curCheckedIndex === index ? (
                                <RadioButtonOnIcon />
                            ) : (
                                <RadioButtonOffIcon />
                            )}
                        </div>
                        <div className="input-radio-button-label">{button.label}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default InputRadioButtons;
