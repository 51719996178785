import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { GET_COUTSE_REPORT, GET_REPORTS, GET_STUDENT_REPORT } from '../actions';
import { getReportsSaga } from './getReportsSaga';
import { getStudentReportSaga } from './getStudentReportSaga';
import { getCourseReportSaga } from './getCourseReportSaga';

export function* reportsWatcher(): SagaIterator {
    yield takeLatest(GET_REPORTS, getReportsSaga);
    yield takeLatest(GET_STUDENT_REPORT, getStudentReportSaga);
    yield takeLatest(GET_COUTSE_REPORT, getCourseReportSaga);
}
