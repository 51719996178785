import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { getProgress, putProgress } from '../actions';

export function* getProgressSaga({ payload }: ReturnType<typeof getProgress>): SagaIterator {
    try {
        yield put(startLoading());
        let tests = null;
        switch (payload.type) {
            case 'Unassigned':
                tests = yield call(API.get(), `/staff/classes/${payload.classId}/progress-tests`);
                break;
            case 'Assigned':
                tests = yield call(
                    API.get(),
                    `/staff/classes/${payload.classId}/progress-tests/assigned`,
                );
                break;
            case 'Past Tests':
                tests = yield call(
                    API.get(),
                    `/staff/classes/${payload.classId}/progress-tests/assigned?completed`,
                );
                break;
            case 'Assigned this week':
                tests = yield call(
                    API.get(),
                    `/staff/classes/${payload.classId}/progress-tests/assigned`,
                );
                tests = tests.filter((item: any) => {
                    return item.from <= moment().endOf('isoWeek').unix();
                });
                break;
            default:
                reportError('Test type not set');
                break;
        }
        yield put(
            putProgress({
                tests,
            }),
        );
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
