import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import cookie from 'react-cookies';
import { appConstants } from 'src/constants';

export type HTTPMethod = 'get' | 'post' | 'delete' | 'put' | 'patch';

export interface JsonBody {
    /*tslint:disable-next-line no-any*/
    [key: string]: any;
}

export interface RequestOptions {
    withHeaders?: boolean;
}

export interface Request {
    method: HTTPMethod;
    url: string;
    body?: JsonBody;
}

const buildRequest = (request: Request) => {
    const { body, method, url } = request;
    const contentType = body instanceof FormData ? 'multipart/form-data' : 'application/json';

    const headers = {
        'content-type': contentType,
        Authorization: `Bearer ${cookie.load('API_TOKEN') || cookie.load('IELTS_API_TOKEN')}`,
    };

    const apiUrl = appConstants.api;

    const requestConfig: AxiosRequestConfig = {
        baseURL: apiUrl,
        data: body,
        headers,
        method,
        url,
        withCredentials: false,
    };

    return requestConfig;
};

export const defaultResponse: Partial<AxiosError['response']> = {
    status: 500,
    data: {
        error: 'Server error',
    },
};

export const formatError = (responseError: AxiosError) => {
    const response: any = responseError.response || defaultResponse;
    const errors = (response.data && (response.data.errors || [response.data.error])) || [];
    return {
        code: response.status,
        message: errors,
    };
};

export const makeRequest = async (request: Request, configData?: RequestOptions) => {
    const requestConfig = buildRequest(request);

    return new Promise((resolve, reject) => {
        const axiosRequest: AxiosPromise = axios(requestConfig);
        axiosRequest
            .then((response: AxiosResponse) => {
                if (configData && configData.withHeaders) {
                    resolve(response);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    window.location.href = `${appConstants.server}/corelogin`;
                }
                // if (error && error.response && error.response.status === 400) {
                //     window.location.href = `//${window.location.host}/error`;
                // }
                reject(formatError(error));
            });
    });
};
