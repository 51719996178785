import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLoading } from 'src/redux/loading/selectors';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClick = (ref: any, action: () => void, disableOutside = false) => {
    const loading = useSelector(selectLoading);
    useEffect(() => {
        if (disableOutside) {
            return () => {};
        }

        /**
         * Alert if clicked on outside of element
         */

        function handleClickOutside(event: any) {
            if (!loading) {
                if (event?.target?.className === 'clickEffect') {
                    return;
                }
                if (
                    ref.current &&
                    Array.isArray(ref.current) &&
                    ref.current.every((refItem: any) => refItem)
                ) {
                    if (ref.current.some((refItem: any) => refItem.contains?.(event.target))) {
                        return;
                    }
                    action();
                    return;
                }

                if (ref.current && !ref.current?.contains?.(event.target)) {
                    action();
                }
            }
        }

        /*Bind the event listener*/
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            /*Unbind the event listener on clean up*/
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [action, ref, disableOutside, loading]);
};
