import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import moment from 'moment';
import ChartHistogram from 'src/components/Icons/ChartHistogram/index';
import SearchAlt from 'src/components/Icons/SearchAlt/index';
import Remove from 'src/components/Icons/Remove';
import Move from 'src/components/Icons/Move';
import { selectSearchValue } from 'src/redux/search/selector';
import { selectActivities } from 'src/redux/weeklyPlan/selectors';
import { notInString } from 'src/helpers/notInString';
import {
    getActivitiesHrefChartHistogram,
    getActivitiesHrefSearchAlt,
} from '../helpers/getActivitiesHref';

export const ResourcesRenderMob = ({
    activeDay,
    days,
    getUpdatedDays,
    columns,
    setDays,
    setIsDirty,
    setMoveToModalData,
    index,
    activeResourcesRows,
    week,
    year,
}: {
    activeDay: any;
    days: any;
    getUpdatedDays: any;
    columns: any;
    setDays: any;
    setIsDirty: any;
    lessons: any;
    setMoveToModalData: any;
    index: number;
    activeResourcesRows: any;
    week: number;
    year: number;
}) => {
    const activities = useSelector(selectActivities);
    const params = useParams();
    const searchValue = useSelector(selectSearchValue);
    const removeResource = (dayIndex: any, currentLessonId: any, linkId: any, linkType: any) => {
        getUpdatedDays(columns, (daysToUpdate: any) => {
            const newDays = JSON.parse(JSON.stringify(daysToUpdate));
            const currentLesson = newDays[dayIndex].lessons.filter(
                ({ lessonId }: { lessonId: any }) => lessonId === currentLessonId,
            )[0];
            currentLesson.activities = currentLesson.activities.filter((activity: any) => {
                return !(activity.id === linkId && activity.type === linkType);
            });
            setIsDirty(true);
            setDays(newDays);
        });
    };
    const timestamp = moment()
        .utc(true)
        .day(activeDay)
        .isoWeekYear(year)
        .isoWeek(week)
        .startOf('day')
        .unix();

    const currentDay = activeDay - 1;

    return (
        <div className="weeklyPlan-resources">
            {activeResourcesRows.size > 1 && index > 0 && <hr />}

            <DragDropContext onDragEnd={() => {}}>
                {[days[currentDay]].map((day: any) => {
                    const activityIds: number[] = [];
                    return (
                        <Droppable droppableId={`${currentDay}`} key={day.day}>
                            {(providedDroppable) => {
                                return (
                                    <div
                                        key={day.day}
                                        className="weeklyPlan-droppable"
                                        {...(providedDroppable.droppableProps as any)}
                                        ref={providedDroppable.innerRef}
                                        style={{ width: '100%' }}
                                    >
                                        <div
                                            key={day.day}
                                            className="weeklyPlan-droppable weeklyPlan-droppable-mobile"
                                        >
                                            {day.lessons.map((lesson: any) => {
                                                const isActivityDuplicate = (id: number) => {
                                                    let hide = false;
                                                    if (activityIds.includes(id)) {
                                                        hide = true;
                                                    } else {
                                                        activityIds.push(id);
                                                    }
                                                    return hide;
                                                };
                                                return lesson.syllabus.map(({ id }: any) => {
                                                    return null;
                                                    if (!activities.length) {
                                                        return null;
                                                    }
                                                    let hideActivity = false;
                                                    const currentActivity = activities.find(
                                                        (activity: any) => {
                                                            if (activity.id === id) {
                                                                hideActivity =
                                                                    isActivityDuplicate(id);
                                                                return true;
                                                            }
                                                            return false;
                                                        },
                                                    );
                                                    return (
                                                        <div
                                                            className={classNames('', {
                                                                'd-none': hideActivity,
                                                            })}
                                                            key={id}
                                                        >
                                                            <div className="weeklyPlan-editable">
                                                                {`Page ${currentActivity?.page} ${
                                                                    currentActivity?.activity.includes(
                                                                        '-',
                                                                    )
                                                                        ? 'exercises'
                                                                        : 'exercise'
                                                                } ${currentActivity?.activity}`}
                                                            </div>
                                                        </div>
                                                    );
                                                });
                                            })}

                                            {day?.lessons[index]?.activities?.map(
                                                (activity: any, activityIndex: number) => {
                                                    const draggableId = `${activity.id}-${day.lessons[index].lessonId}-${activity.type}-${activity.id}`;
                                                    return (
                                                        <Draggable
                                                            key={activity.id}
                                                            draggableId={draggableId}
                                                            index={activityIndex}
                                                        >
                                                            {(provided: any) => {
                                                                return (
                                                                    <div
                                                                        className={classNames(
                                                                            'weeklyPlan-draggable',
                                                                        )}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div
                                                                            className={classNames(
                                                                                '',
                                                                                {
                                                                                    'd-none':
                                                                                        notInString(
                                                                                            activity.name,
                                                                                            searchValue,
                                                                                        ),
                                                                                },
                                                                            )}
                                                                            key={activity.id}
                                                                        >
                                                                            <div
                                                                                className={classNames(
                                                                                    'weeklyPlan-draggable-controls',
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className="weeklyPlan-draggable-controls-icons"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            getActivitiesHrefSearchAlt(
                                                                                                {
                                                                                                    activity,
                                                                                                },
                                                                                            ),
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <SearchAlt />
                                                                                </div>
                                                                                <div
                                                                                    className="weeklyPlan-draggable-controls-icons"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            getActivitiesHrefChartHistogram(
                                                                                                {
                                                                                                    activity,
                                                                                                    classId:
                                                                                                        params.id,
                                                                                                    timestamp,
                                                                                                },
                                                                                            ),
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <ChartHistogram />
                                                                                </div>
                                                                                <div
                                                                                    {...provided.dragHandleProps}
                                                                                />
                                                                                <div
                                                                                    className="weeklyPlan-move grab weeklyPlan-draggable-controls-icons"
                                                                                    onClick={() => {
                                                                                        setMoveToModalData(
                                                                                            {
                                                                                                type: 'resources',
                                                                                                provided:
                                                                                                    {
                                                                                                        draggableId,
                                                                                                        source: {
                                                                                                            index: activityIndex,
                                                                                                            droppableId:
                                                                                                                currentDay,
                                                                                                        },
                                                                                                    },
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <Move />
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        removeResource(
                                                                                            currentDay,
                                                                                            day
                                                                                                .lessons[
                                                                                                index
                                                                                            ]
                                                                                                .lessonId,
                                                                                            activity.id,
                                                                                            activity.type,
                                                                                        );
                                                                                    }}
                                                                                    className="weeklyPlan-draggable-controls-icons"
                                                                                >
                                                                                    <Remove />
                                                                                </div>
                                                                            </div>
                                                                            <div className="weeklyPlan-editable">
                                                                                {activity?.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                );
                            }}
                        </Droppable>
                    );
                })}
            </DragDropContext>
        </div>
    );
};
