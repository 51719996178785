import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ActivityType, BookType, ELearningType, UnitsType, WeeklyPlanType } from './types';

export const getWeeklyPlanSelector = (state: RootState): WeeklyPlanType =>
    state.weeklyPlan.weeklyPlan;

export const selectWeeklyPlan = createSelector(
    createSelector(getWeeklyPlanSelector, (weeklyPlan) => weeklyPlan),
    (weeklyPlan) => weeklyPlan,
);

export const getActivitiesSelector = (state: RootState): ActivityType[] =>
    state.weeklyPlan.activities;

export const selectActivities = createSelector(
    createSelector(getActivitiesSelector, (activities) => activities),
    (activities) => activities,
);

export const getBooksSelector = (state: RootState): BookType[] => state.weeklyPlan.books;

export const selectBooks = createSelector(
    createSelector(getBooksSelector, (books) => books),
    (books) => books,
);

export const selectTableUnits = createSelector(
    createSelector(getBooksSelector, (books) => books),
    (books) => {
        if (!books) return [];
        const units: { name: string; number: number; id: number }[] = [];
        books.forEach((book: any) => {
            book.levels.forEach((level: any) => {
                level.units.forEach((unit: any) => {
                    units.push(unit);
                });
            });
        });
        return units;
    },
);

export const getUnitsSelector = (state: RootState): UnitsType[] => state.weeklyPlan.units;

export const selectUnits = createSelector(
    createSelector(getUnitsSelector, (units) => units),
    (units) => units,
);

export const getElerningsSelector = (state: RootState): ELearningType[] =>
    state.weeklyPlan.elernings;

export const selectElernings = createSelector(
    createSelector(getElerningsSelector, (elernings) => elernings),
    (elernings) => elernings,
);

export const getAssigmentsSelector = (state: RootState): any => state.weeklyPlan.assigments;

export const selecAssigments = createSelector(
    createSelector(getAssigmentsSelector, (assigments) => assigments),
    (assigments) => assigments,
);

export const weeklyPlanCuratedHeadsSelector = (state: RootState): string[] =>
    state.weeklyPlan.weeklyPlanCuratedHeads;

export const selectWeeklyPlanCuratedHeads = createSelector(
    createSelector(weeklyPlanCuratedHeadsSelector, (item) => item),
    (item) => item,
);
