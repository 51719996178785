import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { GET_LAYOUT, PUT_LAYOUT_USER_FIELDS } from '../actions';
import { getLayoutSaga } from './getLayoutSaga';
import { putLayoutUserFieldsSaga } from './putLayoutUserFields';

export function* layoutWatcher(): SagaIterator {
    yield takeLatest(GET_LAYOUT, getLayoutSaga);
    yield takeLatest(PUT_LAYOUT_USER_FIELDS, putLayoutUserFieldsSaga);
}
