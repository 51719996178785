const PlayItem = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM6.91675 11.2251V6.77508C6.91675 6.11675 7.65008 5.71675 8.20008 6.07508L11.6584 8.30008C12.1667 8.62508 12.1667 9.37508 11.6584 9.70008L8.20008 11.9251C7.65008 12.2834 6.91675 11.8834 6.91675 11.2251Z"
            fill="#001C3B"
        />
    </svg>
);

export default PlayItem;
