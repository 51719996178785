const validEmail = (email: string) => {
    return !String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const validateValue = (
    value: string,
    valueLabel: string,
    curValidators?: Record<string, any>,
) => {
    const curError: Record<string, any> = {};
    if (!curValidators) return curError;
    Object.keys(curValidators).forEach((objectKey) => {
        switch (objectKey) {
            case 'empty':
                if (value === '' || value === null || value === undefined) {
                    curError.empty = `Cannot be empty.`;
                }
                break;
            case 'maxLength':
                if (value?.length > curValidators.maxLength) {
                    curError.maxLength = `${valueLabel} value cannot be longer than ${curValidators.maxLength}. `;
                }
                break;
            case 'email':
                if (validEmail(value)) {
                    curError.email = `${valueLabel} is not valid.`;
                }
                break;
            case 'byRule':
                if (curValidators.byRule) {
                    const validationResult = curValidators.byRule();
                    if (validationResult) curError.byRule = validationResult;
                }
                break;
            case 'url':
                if (
                    !new RegExp(
                        '^([a-zA-Z]+:\\/\\/)?' + // protocol
                            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
                            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                            '(\\#[-a-z\\d_]*)?$', // fragment locator
                        'i',
                    ).test(value)
                ) {
                    curError.url = 'Invalid url.';
                }
                break;
            default:
                break;
        }
    });

    return curError;
};
