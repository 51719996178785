import { createNotificationContext } from 'react-notification-provider';

export enum NotificationStatusEnum {
    success,
    error,
    warning,
}
// You can customize the notification interface to include whatever props your notifications need to render.
export interface Notification {
    message: string;
    duration?: number;
    status: NotificationStatusEnum;
}
// This function creates a React context and hooks for you so you'll want to export these.
const { NotificationProvider, useNotificationQueue } = createNotificationContext<Notification>();

export { NotificationProvider, useNotificationQueue };
