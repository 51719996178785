const MoveTo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
        <g id="Artwork_185" data-name="Artwork 185" transform="translate(7.5 7.5)">
            <g id="Group_287" data-name="Group 287" transform="translate(-7.5 -7.5)">
                <path
                    id="Path_491"
                    data-name="Path 491"
                    d="M11.875,1.25H11.25V.625a.625.625,0,0,0-1.25,0V1.25H5V.625a.625.625,0,0,0-1.25,0V1.25H3.125A3.129,3.129,0,0,0,0,4.375v7.5A3.129,3.129,0,0,0,3.125,15h8.75A3.129,3.129,0,0,0,15,11.875v-7.5A3.129,3.129,0,0,0,11.875,1.25ZM1.25,4.375A1.875,1.875,0,0,1,3.125,2.5h8.75A1.875,1.875,0,0,1,13.75,4.375V5H1.25ZM11.875,13.75H3.125A1.875,1.875,0,0,1,1.25,11.875V6.25h12.5v5.625A1.875,1.875,0,0,1,11.875,13.75Z"
                    fill="#54585a"
                />
                <path
                    id="Path_492"
                    data-name="Path 492"
                    d="M161.641,257.217l-1.125-1.149a.6.6,0,0,0-.85,0l0,0h0a.6.6,0,0,0,0,.848l.939.957h-3.68a.6.6,0,0,0-.6.6h0a.6.6,0,0,0,.6.6h3.716l-.9.9a.6.6,0,0,0,0,.85l0,0h0a.6.6,0,0,0,.85,0l0,0,1.052-1.058A1.822,1.822,0,0,0,161.641,257.217Z"
                    transform="translate(-151.74 -248.393)"
                    fill="#54585a"
                />
            </g>
        </g>
    </svg>
);

export default MoveTo;
