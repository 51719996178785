import CloseButtonSvg from '../Icons/CloseButtonSvg';
import './styles.scss';

const CloseButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            data-testid="closeButton"
            className="close-button pr_hover_color_text_svg_bg"
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            <CloseButtonSvg />
        </div>
    );
};

export default CloseButton;
