import { Props } from '../types';

const AssessmentsIcon = ({ fill = '#54585a' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.085"
        height="23.911"
        viewBox="0 0 26.085 23.911"
    >
        <g id="Assessments" transform="translate(0)">
            <path
                id="Path_1209"
                data-name="Path 1209"
                d="M20.651,0H5.434A5.441,5.441,0,0,0,0,5.434v8.7a5.441,5.441,0,0,0,5.434,5.434h6.521v2.174H7.608a1.087,1.087,0,0,0,0,2.174H18.477a1.087,1.087,0,0,0,0-2.174H14.13V19.564h6.521a5.441,5.441,0,0,0,5.434-5.434v-8.7A5.441,5.441,0,0,0,20.651,0Zm3.261,14.13a3.261,3.261,0,0,1-3.261,3.261H5.434A3.261,3.261,0,0,1,2.174,14.13v-8.7A3.261,3.261,0,0,1,5.434,2.174H20.651a3.261,3.261,0,0,1,3.261,3.261ZM21.738,9.782a1.087,1.087,0,0,1-1.087,1.087H17.428L15.58,13.646a1.087,1.087,0,0,1-.908.484h-.067a1.087,1.087,0,0,1-.905-.6L11.278,8.682l-1.135,1.7a1.087,1.087,0,0,1-.9.484h-3.8a1.087,1.087,0,1,1,0-2.174H8.657L10.5,5.918a1.073,1.073,0,0,1,.975-.484,1.087,1.087,0,0,1,.905.6l2.423,4.846,1.135-1.7a1.087,1.087,0,0,1,.9-.481h3.8A1.087,1.087,0,0,1,21.738,9.782Z"
                transform="translate(0 0)"
                fill={fill}
            />
        </g>
    </svg>
);

export default AssessmentsIcon;
