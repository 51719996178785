import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { putApiRequestError } from '../../error/actions';
import { getMailAvailability } from '../actions';

export function* getMailAvailabilitySaga({
    payload,
}: ReturnType<typeof getMailAvailability>): SagaIterator {
    try {
        const data = yield call(API.post(), `/staff/users/mail-availability`, {
            mail: payload.mail,
        });
        if (payload.cb) {
            payload.cb(data);
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    }
}
