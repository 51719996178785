import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import CustomLink from 'src/components/CustomLink';
import { TextWithIconProps } from './types';

const TextWithIcon = ({
    icon,
    text,
    link,
    onClick,
    className,
    navigateAsLink,
}: TextWithIconProps) => {
    const navigate = useNavigate();
    if (navigateAsLink) {
        return (
            <CustomLink
                to={link || '/'}
                style={{ height: '100%', width: '100%' }}
                data-testid="textWithIcon"
                className={`textWithIcon sc_hover_color_text_svg ${className || ''}`}
                title={text}
            >
                {icon}
                <span>{text}</span>
            </CustomLink>
        );
    }
    return (
        <div
            data-testid="textWithIcon"
            className={`textWithIcon sc_hover_color_text_svg ${className || ''}`}
            onClick={(e: MouseEvent) => {
                if (onClick) {
                    onClick(e);
                } else {
                    navigate(link || '');
                }
            }}
            title={text}
        >
            {icon}
            <span>{text}</span>
        </div>
    );
};

export default TextWithIcon;
