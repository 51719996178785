import { useEffect, useState } from 'react';
import _ from 'lodash';

const useSortable = <T>({
    itemsToSort,
    oneDirection = false,
}: {
    itemsToSort: Array<T>;
    oneDirection?: boolean;
}) => {
    const [items, setItems] = useState(itemsToSort);
    const [filters, setFilters] = useState({} as any);
    const [sorters, setSorters] = useState({} as any);
    const [searchFilters, setSearchFilters] = useState({} as any);
    const getDirection = (attr: string) => {
        return sorters[attr]?.direction;
    };

    const createDirection = (direction: 'desc' | 'asc') => {
        let result;

        if (oneDirection) {
            result = direction === 'desc' ? null : 'desc';
            return result;
        }

        if (direction === 'desc') {
            result = null;
        } else if (direction === 'asc') {
            result = 'desc';
        } else {
            result = 'asc';
        }
        return result;
    };

    const updateFilter = (key: string, value: any) => {
        const tempFilter = structuredClone(filters);
        setFilters({});
        const tempObj: any = {};
        tempObj[key] = value;
        setFilters(Object.assign(tempFilter, tempObj));
    };

    const updateSearchFilter = (
        attrsToSort: Array<{
            attrToSearch: string;
            ruleToSearch?: (item: any, searchValue: string) => boolean;
        }>,
        value: string,
    ) => {
        const tempFilter = { ...searchFilters };
        attrsToSort.forEach((item) => {
            tempFilter[item.attrToSearch] = { value, ruleToSearch: item.ruleToSearch };
        });
        setSearchFilters(tempFilter);
    };

    const updateSort = (key: string, sorterRule?: (item: T) => void) => {
        const tempDirection = sorters[key]?.direction;
        setSorters({});
        const tempObj: any = {};
        tempObj[key] = {
            direction: createDirection(tempDirection),
            sorterRule,
        };
        setSorters(tempObj);
    };

    useEffect(() => {
        if (!itemsToSort.length) {
            return () => {};
        }
        let returnItems = structuredClone(itemsToSort);

        if (Object.values(filters).length > 0) {
            returnItems = returnItems.filter((item: any) => {
                const filterFlag = structuredClone(filters);

                Object.entries(filterFlag).forEach(([key, value]: any) => {
                    if (typeof item[key] === 'string') {
                        filterFlag[key] = item[key].toLowerCase().includes(value.toLowerCase());
                    } else {
                        filterFlag[key] = item[key].includes(value);
                    }
                });

                const uniqueFilter = new Set(Object.values(filterFlag));
                return uniqueFilter.size === 1 && uniqueFilter.values().next().value;
            });
        }

        if (Object.values(searchFilters).length > 0) {
            let foundItems: any = [];
            Object.entries(searchFilters).forEach(([key, value]: any) => {
                const temp = returnItems.filter((item: any) => {
                    if (value.ruleToSearch) {
                        return value.ruleToSearch(item, value.value);
                    }
                    return item[key].toLowerCase().includes(value.value.toLowerCase());
                });
                foundItems.push(temp);
                let newJsonItems: any = [];
                foundItems.flat().forEach((item: any) => {
                    newJsonItems.push(JSON.stringify(item));
                });
                newJsonItems = Array.from(new Set(newJsonItems));
                newJsonItems = newJsonItems.map((item: any) => {
                    return JSON.parse(item);
                });
                foundItems = newJsonItems;
            });
            returnItems = foundItems;
        }

        if (Object.values(sorters).length && returnItems.length > 1) {
            Object.entries(sorters).forEach(([, value]: any) => {
                if (value.direction !== null) {
                    returnItems = _.orderBy(returnItems, value.sorterRule, value.direction);
                }
            });
        }

        setItems(returnItems);
    }, [filters, sorters, searchFilters]);

    useEffect(() => {
        setItems(itemsToSort);
    }, [itemsToSort]);

    return {
        items,
        getDirection,
        updateFilter,
        updateSort,
        updateSearchFilter,
    };
};

export default useSortable;
