import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { putApiRequestError } from 'src/redux/error/actions';
import { putStaffEmailsLog } from '../actions';

export function* getStaffEmailsLogSaga({ payload }: ReturnType<any>): SagaIterator {
    try {
        yield put(startLoading());
        const data = yield call(
            API.get(),
            `/staff/emails/log?from=${moment(payload.from).unix()}&to=${moment(payload.to).unix()}`,
        );
        yield put(putStaffEmailsLog(data));
        if (payload.cb) {
            payload.cb();
        }
    } catch (error) {
        yield put(putApiRequestError(error));
    } finally {
        yield put(finishLoading());
    }
}
