import classNames from 'classnames';
import './styles.scss';
import { WhiteBoxProps } from './types';

const WhiteBox = ({ children, styles, hideShadowOnMobile = false }: WhiteBoxProps) => {
    return (
        <div
            className={classNames('whiteBox', {
                'whiteBox-clear': hideShadowOnMobile,
            })}
            style={styles}
            data-testid="whiteBox"
        >
            {children}
        </div>
    );
};

export default WhiteBox;
