import { Props } from '../types';

const SettingsIcon = ({ fill = '#54585a' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="settings-sliders" transform="translate(0 0)">
            <path
                id="Path_951"
                data-name="Path 951"
                d="M.833,4.156h2.28a3.135,3.135,0,0,0,3,2.406,3.135,3.135,0,0,0,3-2.406H19.167A.855.855,0,0,0,20,3.281a.855.855,0,0,0-.833-.875H9.109A3.135,3.135,0,0,0,6.111,0a3.135,3.135,0,0,0-3,2.406H.833A.855.855,0,0,0,0,3.281.855.855,0,0,0,.833,4.156ZM6.111,1.75A1.5,1.5,0,0,1,7.569,3.281,1.5,1.5,0,0,1,6.111,4.812,1.5,1.5,0,0,1,4.653,3.281,1.5,1.5,0,0,1,6.111,1.75Z"
                transform="translate(0 0)"
                fill={fill}
            />
            <path
                id="Path_952"
                data-name="Path 952"
                d="M19.167,10.656h-2.28a3.07,3.07,0,0,0-6,0H.833a.876.876,0,0,0,0,1.75H10.892a3.07,3.07,0,0,0,5.995,0h2.28a.876.876,0,0,0,0-1.75Zm-5.277,2.406a1.533,1.533,0,1,1,1.458-1.531,1.5,1.5,0,0,1-1.458,1.531Z"
                transform="translate(0 -1.531)"
                fill={fill}
            />
            <path
                id="Path_953"
                data-name="Path 953"
                d="M19.167,18.906H9.109a3.135,3.135,0,0,0-3-2.406,3.135,3.135,0,0,0-3,2.406H.833a.876.876,0,0,0,0,1.75h2.28a3.135,3.135,0,0,0,3,2.406,3.135,3.135,0,0,0,3-2.406H19.167a.876.876,0,0,0,0-1.75ZM6.111,21.312a1.533,1.533,0,0,1,0-3.062,1.533,1.533,0,0,1,0,3.062Z"
                transform="translate(0 -3.062)"
                fill={fill}
            />
        </g>
    </svg>
);

export default SettingsIcon;
