import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Header from 'src/components/Header';
import {
    selectBreadcrumbs,
    selectLayout,
    selectModule,
    selectSidebar,
} from 'src/redux/layout/selectors';
import { selectLoading } from 'src/redux/loading/selectors';
import { getLayout } from 'src/redux/layout/actions';
import loader from 'src/assets/images/loaderBird.gif';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Props } from './types';
import Body from './components/Body';
import './styles.scss';
import 'moment/locale/es';
import 'moment/locale/pt';

const Layout = ({ children }: Props) => {
    const bread = useSelector(selectBreadcrumbs);
    const showSidebar = useSelector(selectSidebar);
    const loading = useSelector(selectLoading);
    const modules = useSelector(selectModule);
    const { layout } = useSelector(selectLayout);
    const [activeLoader, setActiveLoader] = useState('');
    const body = document.querySelector(`body`);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (loading) {
            setActiveLoader(loader);

            (body as HTMLElement).style.overflow = 'hidden';
        }

        return () => {
            if (body) {
                body.style.overflow = 'auto';
            }
            setActiveLoader('');
        };
    }, [loading]);

    useEffect(() => {
        dispatch(getLayout());
    }, []);

    useEffect(() => {
        if (layout?.user?.lang) {
            i18n.changeLanguage(layout?.user?.lang);
            localStorage.setItem('lang', JSON.stringify(layout?.user?.lang));

            moment.locale(layout?.user?.lang);
        }
    }, [layout]);

    return (
        <>
            {loading && activeLoader && (
                <div className="loader">
                    <img src={activeLoader} alt="loader" />
                </div>
            )}
            <Header />
            <Breadcrumbs />
            <Body showSidebar={Boolean(showSidebar)}>
                {modules && layout?.user?.uid ? children : null}
            </Body>
        </>
    );
};

export default Layout;
