import { createReducer } from '@reduxjs/toolkit';
import { PUT_COUTSE_REPORT, PUT_REPORTS, PUT_STUDENT_REPORT } from './actions';
import { CourseReport, PutReports } from './types';

export type ReportsState = {
    reports: PutReports;
    studentReport: any;
    course: CourseReport;
};

const initialState = {
    reports: [],
    studentReport: {},
    course: {
        tags: [],
        homework: [],
    },
};

export const reportsReducer = createReducer<ReportsState>(initialState, {
    [PUT_REPORTS]: (state, action) => {
        state.reports = action.payload;
    },
    [PUT_STUDENT_REPORT]: (state, action) => {
        state.studentReport = action.payload;
    },
    [PUT_COUTSE_REPORT]: (state, action) => {
        state.course = action.payload;
    },
});
