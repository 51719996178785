/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useMemo } from 'react';
import {
    Notification,
    NotificationStatusEnum,
    useNotificationQueue,
} from 'src/providers/NotificationProvider';
import Exit from 'src/components/Icons/Exit';
import CircleCheck from 'src/components/Icons/CircleCheck';

export default function NotificationItem({
    notification,
    id,
}: {
    notification: Notification;
    id: string;
}) {
    const notificationQueue = useNotificationQueue();

    const titleAndIcon = useMemo(() => {
        const result = {} as { title: string; icon: JSX.Element };
        if (notification?.status === NotificationStatusEnum.success) {
            result.title = 'Success';
            result.icon = <CircleCheck />;
        }
        return result;
    }, []);

    useEffect(() => {
        let timoutId: ReturnType<typeof setTimeout>;
        if (notification) {
            timoutId = setTimeout(() => {
                notificationQueue.remove(id);
            }, notification.duration || 5000);
        }
        return () => {
            clearTimeout(timoutId);
        };
    }, []);

    return (
        <div className="notifications-wrapper">
            <div className="notifications-statusIcon">{titleAndIcon.icon}</div>
            <div className="notifications-title">{titleAndIcon.title}</div>
            <div className="notifications-message">{notification.message}</div>
            <button
                type="button"
                className="notifications-closeButton"
                onClick={() => notificationQueue.remove(id)}
            >
                <Exit />
            </button>
        </div>
    );
}
