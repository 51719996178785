import { Props } from '../types';

const Move = ({ fill = '#54585a' }: Props) => (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m19.463 8.544c-.194-.219-.385-.427-.516-.558l-1.871-1.9a.833.833 0 0 0 -1.189 1.164l1.876 1.908.007.008h-6.935v-6.879l1.865 1.829a.833.833 0 0 0 1.167-1.188l-1.9-1.867c-.139-.136-.348-.326-.567-.519a2.146 2.146 0 0 0 -1.375-.542h-.025-.007a2.154 2.154 0 0 0 -1.447.539c-.219.194-.428.385-.558.516l-1.9 1.872a.833.833 0 1 0 1.163 1.189l1.909-1.876.008-.007v6.934h-6.934l.013-.013 1.87-1.9a.833.833 0 1 0 -1.189-1.171l-1.866 1.9c-.136.136-.327.344-.52.562a2.151 2.151 0 0 0 -.542 1.446v.009c0 .008 0 .016.005.024a2.144 2.144 0 0 0 .537 1.376c.194.218.384.427.515.558l1.871 1.9a.833.833 0 0 0 1.189-1.158l-1.83-1.867h6.881v6.933l-.013-.012-1.9-1.87a.833.833 0 1 0 -1.167 1.187l1.9 1.866c.136.136.344.326.563.52a2.157 2.157 0 0 0 2.859 0c.218-.194.426-.383.558-.516l1.9-1.87a.833.833 0 1 0 -1.168-1.186l-1.864 1.828v-6.88h6.881l-1.834 1.861a.833.833 0 1 0 1.189 1.167l1.866-1.9c.136-.136.326-.344.52-.562a2.157 2.157 0 0 0 0-2.855z"
            fill={fill}
        />
    </svg>
);

export default Move;
