import { createReducer } from '@reduxjs/toolkit';
import { PUT_IP_INFO, PutIPInfoType } from 'src/redux/ipInfo/actions';

export type IPInfoReducerState = {
    IPInfo: PutIPInfoType;
};

const initialState: IPInfoReducerState = {
    IPInfo: null,
};

export const IPInfoReducer = createReducer<IPInfoReducerState>(initialState, {
    [PUT_IP_INFO]: (state, action) => {
        state.IPInfo = action.payload;
    },
});
