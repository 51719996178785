import { createAction } from '@reduxjs/toolkit';
import {
    GetAssessmentPropss,
    GetAssessmentStudentsProps,
    GetAssessmentTypesProps,
    PutAssessmentProps,
    PutAssessmentStudentsProps,
    PutAssessmentTypesProps,
    SaveAssessmentMarksProps,
} from './types';

export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const PUT_ASSESSMENT = 'PUT_ASSESSMENT';
export const GET_ASSESSMENT_TYPES = 'GET_ASSESSMENT_TYPES';
export const PUT_ASSESSMENT_TYPES = 'PUT_ASSESSMENT_TYPES';
export const GET_ASSESSMENT_STUDENTS = 'GET_ASSESSMENT_STUDENTS';
export const PUT_ASSESSMENT_STUDENTS = 'PUT_ASSESSMENT_STUDENTS';
export const SAVE_ASSESSMENT_MARKS = 'SAVE_ASSESSMENT_MARKS';

export const getAssessment = createAction<GetAssessmentPropss>(GET_ASSESSMENT);
export const putAssessment = createAction<PutAssessmentProps>(PUT_ASSESSMENT);
export const getAssessmentTypes = createAction<GetAssessmentTypesProps>(GET_ASSESSMENT_TYPES);
export const putAssessmentTypes = createAction<PutAssessmentTypesProps[]>(PUT_ASSESSMENT_TYPES);
export const getAssessmentStudents =
    createAction<GetAssessmentStudentsProps>(GET_ASSESSMENT_STUDENTS);
export const putAssessmentStudents =
    createAction<PutAssessmentStudentsProps>(PUT_ASSESSMENT_STUDENTS);
export const saveAssessmentMarks = createAction<SaveAssessmentMarksProps>(SAVE_ASSESSMENT_MARKS);
