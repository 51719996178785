import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Modal from 'src/components/Modal';
import TextWithIcon from 'src/components/TextWithIcon';
import ArrowThin from 'src/components/Icons/ArrowThin';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Button from 'src/components/Button';
import { getAims, getBook, saveUnitTest } from 'src/redux/coursePlanner/actions';
import { capitalize } from 'src/helpers/capitalize';
import { selectUnits } from 'src/redux/weeklyPlan/selectors';
import { selectAims } from 'src/redux/coursePlanner/selectors';
import { getAutomated } from 'src/redux/automated/actions';
import { useNotificationQueue, NotificationStatusEnum } from 'src/providers/NotificationProvider';
import { useTranslation } from 'react-i18next';
import { Method, Steps } from './types';
import CreateUnitTest from './steps/CreateUnitTest';
import TestName from './steps/TestName';
import Review from './steps/Review';
import SelectUnits from './steps/SelectUnits';
import SelectMethod from './steps/SelectMethod';
import StepHistory from './steps/StepHistory';
import './styles.scss';

const initialUnitDataState = {
    method: Method.TestByAim,
    content: [] as number[],
    questions: [] as number[],
    name: '',
};

export default function AddUnitTestModal({
    open,
    onClose,
    parentTab,
}: {
    open: boolean;
    onClose: () => void;
    parentTab: string;
}) {
    const { t } = useTranslation();
    const params = useParams();
    const [stepId, setStepId] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const units = useSelector(selectUnits);
    const aims = useSelector(selectAims);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [unitData, setUnitData] = useState(initialUnitDataState);
    const { isDesktop } = useWindowSize();
    const dispatch = useDispatch();
    const notificationQueue = useNotificationQueue();

    const onStepClick = (step: number) => {
        setStepId(step);
    };

    const setInitialState = () => {
        setCompletedSteps([]);
        setStepId(0);
        setUnitData(initialUnitDataState);
    };

    const handleBack = () => {
        if (stepId !== 0) {
            setStepId((id) => id - 1);
        }
    };

    const handleNext = () => {
        setStepId((id) => id + 1);
        if (!completedSteps.includes(stepId)) {
            setCompletedSteps((steps) => [...steps, stepId]);
        }
    };

    const onChange = (data: Partial<typeof unitData>) => {
        setUnitData({
            ...unitData,
            ...data,
        });
    };

    const handleConfirmCreate = () => {
        dispatch(
            saveUnitTest({
                classId: params.id,
                data: {
                    global: true,
                    type: unitData.method === Method.TestByAim ? 'aims' : 'pages',
                    name: unitData.name,
                    questions: unitData.questions,
                },
                cb: () => {
                    if (parentTab === t('modals.addUnitTest.tabLibrary')) {
                        dispatch(
                            getAutomated({
                                classId: params.id,
                                type: 'Unassigned',
                            }),
                        );
                    }
                    notificationQueue.add('TestCreated', {
                        message: t('modals.addUnitTest.testAddedToLibraryNotification', {
                            name: unitData.name,
                        }),
                        status: NotificationStatusEnum.success,
                    });
                    setInitialState();
                    onClose();
                },
            }),
        );
    };

    const summaryInfo = (key: keyof typeof unitData) => {
        switch (key) {
            case 'method':
                return unitData.method;
            case 'content':
                if (unitData.method === Method.TestByAim) {
                    const filtered = aims
                        .filter(({ id }: { id: number }) => unitData.content.includes(id))
                        .map(({ level }) => level);

                    const result = [...new Set(filtered)];

                    return result.join(', ');
                }
                return units
                    .filter((item: any) => unitData.content.includes(item.id))
                    .map(({ number }: any) => `Unit ${number}`)
                    .join(', ');
            case 'questions':
                return unitData.questions.length;
            default:
                return '';
        }
    };

    useEffect(() => {
        if (open) {
            dispatch(
                getBook({
                    classId: params.id,
                }),
            );
            dispatch(
                getAims({
                    classId: params.id,
                }),
            );
        }
    }, [dispatch, open]);

    useEffect(() => {
        const rootEl = document.getElementById('root');
        if (rootEl && open) rootEl.classList.add('disableRootScroll');
        return () => {
            setUnitData({
                method: Method.TestByAim,
                content: [] as number[],
                questions: [] as number[],
                name: '',
            });
            setStepId(Steps.SelectMethod);
            if (rootEl) rootEl.classList.remove('disableRootScroll');
        };
    }, [open]);

    if (openConfirm) {
        return (
            <Modal
                open={open}
                onClose={() => {
                    setOpenConfirm(false);
                }}
                noPadding
            >
                <div className="closeModal">
                    <span className="closeModal-title">
                        {t('modals.addUnitTest.discardChangesConfirm')}
                    </span>
                    <span className="closeModal-text">
                        {t('modals.addUnitTest.discardChangesConfirm')}
                    </span>
                    <div className="closeModal-buttons">
                        <Button
                            type="grey"
                            onClick={() => {
                                setOpenConfirm(false);
                            }}
                        >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            className="closeModal-buttons__discard"
                            type="secondary"
                            onClick={() => {
                                onClose();
                                setOpenConfirm(false);
                                setStepId(0);
                                setCompletedSteps([]);
                            }}
                        >
                            {t('modals.addUnitTest.discardContinue')}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                if (unitData.content.length) {
                    setOpenConfirm(true);
                    return;
                }

                onClose();
            }}
            fullWindow
        >
            <>
                <div className="makeUnit-header">
                    {stepId !== Steps.SelectMethod && !isDesktop && (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <button
                            type="button"
                            onClick={handleBack}
                            className="makeUnit-header__back"
                        >
                            <ArrowThin />
                        </button>
                    )}
                    <h3 className="makeUnit-title">{t('modals.addUnitTest.createUnitTest')}</h3>
                </div>
                <div className="makeUnit-content">
                    <StepHistory
                        completedSteps={completedSteps}
                        stepId={stepId}
                        onStepClick={onStepClick}
                    />
                    <div className="makeUnit-stepContainer">
                        {stepId !== Steps.SelectMethod && isDesktop && (
                            <TextWithIcon
                                className="makeUnit-back"
                                text={t('modals.addUnitTest.back')}
                                icon={<ArrowThin />}
                                onClick={handleBack}
                            />
                        )}
                        {stepId === Steps.SelectMethod && (
                            <SelectMethod onChange={onChange} method={unitData.method} />
                        )}
                        {stepId === Steps.SelectUnits && (
                            <SelectUnits
                                onChange={onChange}
                                method={unitData.method}
                                selectedUnits={unitData.content}
                            />
                        )}
                        {stepId === Steps.CreateUnitTest && (
                            <CreateUnitTest
                                onChange={onChange}
                                method={unitData.method}
                                units={unitData.content}
                                selectedQuestions={unitData.questions}
                            />
                        )}
                        {stepId === Steps.TestName && (
                            <TestName onChange={onChange} testName={unitData.name} />
                        )}
                        {stepId === Steps.Review && (
                            <Review
                                testName={unitData.name}
                                method={unitData.method}
                                units={unitData.content}
                                unitTest={unitData.questions}
                            />
                        )}

                        {Steps.Review === stepId && (
                            <div className="makeUnit-nextButtonWrapper">
                                <Button type="secondary" onClick={handleConfirmCreate}>
                                    {t('general.confirm')}
                                </Button>
                            </div>
                        )}

                        {[Steps.SelectMethod, Steps.TestName].includes(stepId) && (
                            <div className="makeUnit-nextButtonWrapper">
                                <Button
                                    type="secondary"
                                    onClick={handleNext}
                                    disabled={
                                        stepId === Steps.TestName
                                            ? !unitData.name.trim().length
                                            : false
                                    }
                                >
                                    {t('general.next')}
                                </Button>
                            </div>
                        )}
                        {[Steps.SelectUnits, Steps.CreateUnitTest].includes(stepId) && (
                            <div className="makeUnit-tableButton">
                                <div className="makeUnit-hl" />
                                <div className="makeUnit-nextButtonWrapper">
                                    <Button
                                        type="secondary"
                                        onClick={handleNext}
                                        disabled={
                                            stepId === Steps.SelectUnits
                                                ? !unitData.content.length
                                                : !unitData.questions.length
                                        }
                                    >
                                        {t('general.next')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    {isDesktop && stepId !== Steps.Review && (
                        <div className="makeUnit-summary">
                            {Object.keys(unitData)
                                .slice(0, stepId)
                                .map((key) => (
                                    <div key={key} className="makeUnit-summary__block">
                                        <h4>{capitalize(key)}</h4>
                                        <span>{summaryInfo(key as keyof typeof unitData)}</span>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </>
        </Modal>
    );
}
