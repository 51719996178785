import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import WhiteBox from 'src/components/WhiteBox';
import Delete from 'src/components/Icons/Delete';
import { useOutsideClick } from 'src/hooks/outsideClick';
import Disc from 'src/components/Icons/Disc';
import { selectWeeklyPlan } from 'src/redux/weeklyPlan/selectors';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { updateWeeklyDay } from 'src/redux/weeklyPlan/actions';
import { useTranslation } from 'react-i18next';
import { API } from 'src/api';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { NotesProps } from './types';
import './styles.scss';

const HomeworkNotes = ({ currentLesson, setUpdateLesson, setIsDirty, assignments }: NotesProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [edit, setEdit] = useState('');
    const wrapperRef = useRef({});
    useOutsideClick({ current: (wrapperRef as any).current[edit] }, () => setEdit(''));
    const weekly = useSelector(selectWeeklyPlan) as any;
    const params = useParams();
    const [dataToUpdate, setDataToUpdate] = useState(null as any);
    const location = useLocation();
    const isStudent = location.pathname.includes('students');

    return (
        <WhiteBox>
            <div className="homeworkNotes-tabBar">
                <span>{t('homework.homeworkNotes.resources')}</span>
                <div
                    className="homeworkNotes-disc pr_hover_color_text_svg_bg"
                    onClick={() => {
                        if (isStudent) {
                            setDataToUpdate(currentLesson.activities);
                            return;
                        }
                        const toUpdate = JSON.parse(JSON.stringify(weekly));
                        const dayIndex = toUpdate.days.findIndex(
                            ({ day }: any) => day === +params.day!,
                        );
                        const lessonToUpdate = weekly.days[dayIndex].lessons.findIndex(
                            ({ lessonId, columnId }: any) =>
                                lessonId === currentLesson.lessonId &&
                                columnId === currentLesson.columnId,
                        );
                        toUpdate.days[dayIndex].lessons[lessonToUpdate] = currentLesson;
                        setDataToUpdate(toUpdate);
                    }}
                >
                    <Disc />
                    {t('homework.homeworkNotes.save')}
                </div>
            </div>

            <div className="homeworkNotes-resources">
                {currentLesson?.activities.map((activity: any) => {
                    return (
                        <div
                            key={activity.id}
                            className={classNames('homeworkNotes-resource', {
                                currentlyAdded: activity.currentlyAdded,
                            })}
                        >
                            <span
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <span
                                    className={classNames(
                                        activity.currentlyAdded
                                            ? 'currentlyAdded-circle active'
                                            : 'currentlyAdded-circle',
                                    )}
                                />
                                {activity.name}
                            </span>
                            <div
                                onClick={() => {
                                    setUpdateLesson((dataL: any) => {
                                        return {
                                            ...dataL,
                                            activities: dataL.activities.filter(
                                                (act: any) => act.id !== activity.id,
                                            ),
                                        };
                                    });
                                }}
                                className="classes-delete sc_hover_color_text_svg"
                            >
                                <Delete />
                            </div>
                        </div>
                    );
                })}
            </div>
            <ConfirmModal
                text={t('homework.homeworkNotes.wantToSaveChanges')}
                onClick={() => {
                    setIsDirty(false);
                    if (isStudent) {
                        dispatch(startLoading());
                        const dataToDelete = assignments
                            .map((assign) => ({
                                id: assign.id || assign.contextId,
                                contextId: assign.contextId,
                            }))
                            .filter(
                                ({ id }) =>
                                    !dataToUpdate
                                        ?.map(({ id: idData }: { id: number }) => idData)
                                        .includes(id),
                            )
                            .map((assign) => assign.contextId);

                        const dataToPost = dataToUpdate
                            .filter(
                                ({ currentlyAdded }: { currentlyAdded: boolean }) => currentlyAdded,
                            )
                            .map(({ id, type }: { id: number; type: string }) => ({
                                id,
                                type: type === 'flashPoint' ? type : type.toLowerCase(),
                            }));

                        Promise.all([
                            API.post()(`staff/students/${params.uid}/assignments`, dataToPost),
                            API.delete()(`staff/students/${params.uid}/assignments`, dataToDelete),
                        ])
                            .then(() => {
                                const isAllStudent = location.pathname.includes('all-students');
                                const url = !isAllStudent
                                    ? `/students/${params.id}/${params.uid}/profile`
                                    : `/all-students/${params.uid}/profile`;

                                setDataToUpdate(null);
                                navigate(url);
                            })
                            .finally(() => {
                                setDataToUpdate(null);
                                dispatch(finishLoading());
                            });
                        return;
                    }
                    dispatch(
                        updateWeeklyDay({
                            classId: params.id,
                            cb: () => {
                                setDataToUpdate(null);
                                navigate(`/weekly-plan/${params.id}`);
                            },
                            data: dataToUpdate,
                        }),
                    );
                }}
                onClose={() => {
                    setDataToUpdate(null);
                }}
                open={dataToUpdate}
            />
        </WhiteBox>
    );
};

export default HomeworkNotes;
