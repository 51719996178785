import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import WhiteBox from 'src/components/WhiteBox';
import { putSidebar } from 'src/redux/layout/actions';
import './styles.scss';
import { useClass } from 'src/hooks/useClass';
import { getReports } from 'src/redux/reports/actions';
import { selectReports } from 'src/redux/reports/selectors';
import { useWindowSize } from 'src/hooks/useWindowSize';
import TabBar from 'src/components/TabBar';
import DateSelector from 'src/components/DateSelector';
import moment from 'moment';
import Search from 'src/components/Search';
import classNames from 'classnames';
import MobileControls from 'src/components/MobileControls';
import { ReportType } from 'src/redux/reports/types';
import { COURSE_SUMMARY } from 'src/redux/reports/sagas/getReportsSaga';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { useTranslation } from 'react-i18next';
import CourseSummary from './components/CourseSummary';

const Reports = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const reports = useSelector(selectReports);
    const [activeReports, setActiveReports] = useState(reports as ReportType[]);
    const { isDesktop } = useWindowSize();
    const { t } = useTranslation();
    const [tab, setTab] = useState('null');
    const [report, setReport] = useState({} as ReportType);
    const [search, setSearch] = useState('');
    const weekStartUnix = moment().startOf('isoWeek').unix();
    const weekEndUnix = moment().endOf('isoWeek').unix();
    const monthStartUnix = moment().startOf('month').unix();
    const monthEndUnix = moment().endOf('month').unix();
    const [{ from, to }, setDate] = useState({
        from: weekStartUnix,
        to: weekEndUnix,
    });
    const [activeButton, setActiveButton] = useState('week');
    const { isModuleActive, getModuleName } = useIsModuleActive();
    useClass();

    useEffect(() => {
        dispatch(
            getReports({
                classId: params.id as string,
            }),
        );
        dispatch(putSidebar('classSelected'));

        return () => {
            dispatch(putSidebar());
        };
    }, [dispatch, params]);

    useEffect(() => {
        if (reports.length) {
            setActiveReports(
                reports
                    .filter((rep: ReportType) => {
                        if (rep.name === COURSE_SUMMARY.name) {
                            return isModuleActive(Modules.ClassReportsSummary);
                        }

                        return true;
                    })
                    .map((rep: ReportType) => {
                        if (rep.name === COURSE_SUMMARY.name) {
                            return {
                                name: getModuleName(Modules.ClassReportsSummary),
                            };
                        }

                        return rep;
                    }),
            );
            setTab(reports[0].name);
            setReport(reports[0]);
        }
    }, [reports, isModuleActive(Modules.ClassReportsSummary)]);

    const onDateSelectorClick = () => {
        const header = document.getElementById('report-header');
        header!.style.overflowX = header!.style.overflowX === 'auto' ? 'unset' : 'auto';
        header!.style.overflowY = header!.style.overflowY === 'hidden' ? 'initial' : 'hidden';
    };

    return (
        <>
            <WhiteBox hideShadowOnMobile>
                <div
                    className="report-header"
                    id="report-header"
                    style={{ overflowX: 'auto', overflowY: 'hidden' }}
                >
                    <div className="report-header-tabs">
                        <TabBar
                            activeTab={tab}
                            tabs={activeReports.map((item: ReportType) => {
                                return {
                                    tabId: item.name,
                                    label: item.name,
                                    onClick: () => {
                                        setTab(item.name);
                                        setReport(item);
                                    },
                                };
                            })}
                        />
                    </div>
                    {tab === getModuleName(Modules.ClassReportsSummary) ? (
                        <div className="report-header-summary">
                            <Search
                                onChange={(text: string) => {
                                    setSearch(text);
                                }}
                            />
                            <span
                                className={classNames(
                                    'report-header-summary-button pr_hover_color_text_svg_bg',
                                    {
                                        'report-header-summary-button--active':
                                            activeButton === 'week',
                                    },
                                )}
                                onClick={() => {
                                    setActiveButton('week');
                                    setDate({
                                        from: weekStartUnix,
                                        to: weekEndUnix,
                                    });
                                }}
                            >
                                {t('reports.coveredThisWeek')}
                            </span>
                            <span
                                className={classNames(
                                    'report-header-summary-button pr_hover_color_text_svg_bg',
                                    {
                                        'report-header-summary-button--active':
                                            activeButton === 'month',
                                    },
                                )}
                                onClick={() => {
                                    setActiveButton('month');
                                    setDate({
                                        from: monthStartUnix,
                                        to: monthEndUnix,
                                    });
                                }}
                            >
                                {t('reports.coveredThisMonth')}
                            </span>
                            <DateSelector
                                weekNumber={moment(new Date()).isoWeek()}
                                year={moment(new Date()).year()}
                                hideControls
                                type="rangeCustom"
                                className={
                                    activeButton === 'custom'
                                        ? 'report-header-summary-calendar--active'
                                        : ''
                                }
                                onClick={onDateSelectorClick}
                                onChange={(
                                    weekNumber: number,
                                    yearNumber: number,
                                    date: Date[],
                                ) => {
                                    if (date) {
                                        setActiveButton('custom');
                                        setDate({
                                            from: moment(date[0]).unix(),
                                            to: moment(date[1]).unix(),
                                        });
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {tab === getModuleName(Modules.ClassReportsSummary) ? (
                    <div className="report-controls">
                        <MobileControls>
                            <>
                                <Search
                                    onChange={(text: string) => {
                                        setSearch(text);
                                    }}
                                />
                                <span
                                    className={classNames(
                                        'report-header-summary-button pr_hover_color_text_svg_bg',
                                        {
                                            'report-header-summary-button--active':
                                                activeButton === 'week',
                                        },
                                    )}
                                    onClick={() => {
                                        setActiveButton('week');
                                        setDate({
                                            from: weekStartUnix,
                                            to: weekEndUnix,
                                        });
                                    }}
                                >
                                    {t('reports.coveredThisWeek')}
                                </span>
                                <span
                                    className={classNames(
                                        'report-header-summary-button pr_hover_color_text_svg_bg',
                                        {
                                            'report-header-summary-button--active':
                                                activeButton === 'month',
                                        },
                                    )}
                                    onClick={() => {
                                        setActiveButton('month');
                                        setDate({
                                            from: monthStartUnix,
                                            to: monthEndUnix,
                                        });
                                    }}
                                >
                                    {t('reports.coveredThisMonth')}
                                </span>
                                <DateSelector
                                    weekNumber={moment(new Date()).isoWeek()}
                                    year={moment(new Date()).year()}
                                    hideControls
                                    type="rangeCustom"
                                    className={
                                        activeButton === 'custom'
                                            ? 'report-header-summary-calendar--active report-header-summary-calendar'
                                            : 'report-header-summary-calendar'
                                    }
                                    onChange={(
                                        weekNumber: number,
                                        yearNumber: number,
                                        date: Date[],
                                    ) => {
                                        if (date) {
                                            setActiveButton('custom');
                                            setDate({
                                                from: moment(date[0]).unix(),
                                                to: moment(date[1]).unix(),
                                            });
                                        }
                                    }}
                                />
                            </>
                        </MobileControls>
                    </div>
                ) : (
                    <></>
                )}
                <div className="reports">
                    {tab !== getModuleName(Modules.ClassReportsSummary) ? (
                        <iframe
                            frameBorder="0"
                            src={isDesktop ? report.desktop : report.mobile}
                            title={report.name}
                            id="iframe"
                        />
                    ) : (
                        <CourseSummary from={from} to={to} search={search} />
                    )}
                </div>
            </WhiteBox>
        </>
    );
};

export default Reports;
