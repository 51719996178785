import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';

import { useWindowSize } from 'src/hooks/useWindowSize';
import { useClass } from 'src/hooks/useClass';
import { putBreadcrumbs } from 'src/redux/layout/actions';
import { selectClass } from 'src/redux/classes/selectors';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import InputCalendar from 'src/components/InputCalendar';
import {
    extendQrCode,
    getStudentAndTeacherQrCode,
    regenerateQrCode,
} from 'src/redux/qrCodes/actions';
import { selectStudentAndTeacherQrCode } from 'src/redux/qrCodes/selectors';
import { QrCode } from 'src/redux/qrCodes/types';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const ActionPopup = ({
    close,
    action,
    inviteType,
    classId,
    invites,
    isPreSessionalClass,
}: {
    close: () => void;
    action: null | 'extend' | 'invalidate';
    inviteType: 'student' | 'teacher';
    classId?: string;
    invites: { student: QrCode; teacher: QrCode };
    isPreSessionalClass?: boolean;
}) => {
    const [expirationDate, setExpirationDate] = useState(invites[inviteType].expires);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setExpirationDate(invites[inviteType].expires);
    }, [invites]);

    if (!action) {
        return null;
    }

    const handleConfirm = () => {
        if (action === 'extend') {
            dispatch(
                extendQrCode({
                    id: invites[inviteType].id,
                    classId,
                    expires: expirationDate - new Date().getTimezoneOffset() * 60,
                    cb: () => close(),
                }),
            );
        } else {
            dispatch(
                regenerateQrCode({
                    id: invites[inviteType].id,
                    isPreSessionalClass,
                    classId,
                    cb: () => close(),
                }),
            );
        }
    };

    return (
        <Modal open={!!action} onClose={close} noPadding>
            <div className="qrcodes-popup">
                <div className="qrcodes-popup-head">
                    {action === 'extend' ? 'Extend date' : 'Invalidate'}
                </div>
                <div className="qrcodes-popup-content">
                    {action === 'extend' ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                maxWidth: '15rem',
                                margin: '0 auto',
                            }}
                        >
                            {t('invite.modal.extendExpiryTo')}
                            <InputCalendar
                                onChange={(e: any) => {
                                    setExpirationDate(e / 1000);
                                }}
                                date={new Date(expirationDate * 1000)}
                                isUtc
                            />
                        </div>
                    ) : (
                        <>
                            {t('invite.modal.isConfirmationRequired')}{' '}
                            <b>{t('invite.modal.invalidateAndGenerateNewCode')}</b>?
                        </>
                    )}
                </div>
                <div className="qrcodes-popup-footer">
                    <Button type="outline-grey" onClick={() => close()} minWidth={80}>
                        {t('general.cancel')}
                    </Button>
                    <Button maxWidth={80} type="secondary" onClick={() => handleConfirm()}>
                        {t('general.OK')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const Invite = ({ inviteType }: { inviteType: string }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const classData = useSelector(selectClass);
    const invites = useSelector(selectStudentAndTeacherQrCode);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const iframeRef = useRef(null);
    const { isDesktop } = useWindowSize();
    const [popupAction, setPopupAction] = useState<null | 'extend' | 'invalidate'>(null);

    const checkBrowser = () => {
        let browser = '';
        const c = navigator.userAgent.search('Chrome');
        const f = navigator.userAgent.search('Firefox');
        const m8 = navigator.userAgent.search('MSIE 8.0');
        const m9 = navigator.userAgent.search('MSIE 9.0');
        if (c > -1) {
            browser = 'Chrome';
        } else if (f > -1) {
            browser = 'Firefox';
        } else if (m9 > -1) {
            browser = 'MSIE 9.0';
        } else if (m8 > -1) {
            browser = 'MSIE 8.0';
        }
        return browser;
    };

    const getMargin = (type: 'left' | 'top') => {
        if (type === 'left') {
            if (checkBrowser() === 'Firefox') {
                return '-8%';
            }
            return '-1%';
        }
        if (checkBrowser() === 'Firefox') {
            return '-8%';
        }
        return '-1%';
    };

    const getIframeHeight = (type?: string) => {
        let result = 0;
        const block = iframeRef.current as any;
        if (block) {
            if (checkBrowser() === 'Firefox') {
                if (type === 'parent') {
                    result = Math.round(block.offsetWidth * 1.45 ?? 0);
                } else {
                    result = Math.round(block.offsetWidth * 2.5 ?? 0);
                }
            } else if (checkBrowser() !== 'Firefox') {
                if (type === 'parent') {
                    result = Math.round(block.offsetWidth * 1.42 ?? 0);
                } else {
                    result = Math.round(block.offsetWidth * 1.45 ?? 0);
                }
            }
        }
        return `${result}px`;
    };

    const getIframeWidth = (type?: string) => {
        let result = '0';
        const block = iframeRef.current as any;
        if (checkBrowser() === 'Firefox') {
            result = '112';
        } else {
            result = '102';
        }
        return `${result}%`;
    };

    useClass(false);

    useEffect(() => {
        if (params?.id) {
            dispatch(
                putBreadcrumbs([
                    {
                        name: `${t('general.class')} ${classData.name}`,
                        link: `students/${params.id}`,
                    },
                    {
                        name: t('general.inviteStudent'),
                    },
                ]),
            );
        } else {
            dispatch(
                putBreadcrumbs([
                    {
                        name:
                            inviteType === 'teacher'
                                ? t('general.inviteTeacher')
                                : t('general.inviteStudent'),
                    },
                ]),
            );
        }

        return () => {
            dispatch(putBreadcrumbs([]));
        };
    }, [params]);

    useEffect(() => {
        dispatch(
            getStudentAndTeacherQrCode({
                classId: params.id,
            }),
        );
    }, []);

    const Iframe = useCallback(
        () => (
            <div
                className="invite__iframe"
                ref={iframeRef}
                style={{ height: getIframeHeight('parent') }}
            >
                {iframeRef.current === undefined ||
                invites[inviteType as 'teacher' | 'student'].poster?.pdf === undefined ||
                invites[inviteType as 'teacher' | 'student'].poster?.pdf === '' ? (
                    <></>
                ) : (
                    <iframe
                        style={{
                            marginTop: getMargin('top'),
                            marginLeft: getMargin('left'),
                        }}
                        title="invite-student"
                        scrolling="no"
                        frameBorder="0"
                        src={`${
                            invites[inviteType as 'teacher' | 'student'].poster?.pdf
                        }#toolbar=0&navpanes=0&scrollbar=0&scrolling=no`}
                        width={getIframeWidth()}
                        height={getIframeHeight()}
                    />
                )}
            </div>
        ),
        [iframeRef, JSON.stringify(invites)],
    );

    switch (inviteType) {
        case 'teacher':
            if (!invites.teacher) {
                navigate(-1);
            }
            return (
                <>
                    <div className="invite-parent">
                        <div className="invite">
                            <div className="invite__title">{t('invite.inviteTeacher')}</div>
                            <div className="invite__desc">
                                {t('invite.askTeacherToScanCodeOrDownloadPdfForStaffRoom')}
                            </div>
                            <div className="invite__desc">
                                {t('invite.scanCodeToRegisterAsTeacherAndAccessPortal')}
                            </div>
                            <Button
                                onClick={() => {
                                    window.open(
                                        invites.teacher.poster?.pdf,
                                        '_blank',
                                        'noopener,noreferrer',
                                    );
                                }}
                                type="secondary"
                            >
                                {t('invite.downloadPdf')}
                            </Button>
                        </div>
                        {isDesktop ? (
                            <Iframe />
                        ) : (
                            <img
                                className="invite-poster"
                                src={`${invites.teacher.poster?.png}`}
                                alt="invite poster"
                            />
                        )}
                    </div>
                </>
            );
        case 'student':
            if (!invites.student) {
                navigate(-1);
            }
            return (
                <>
                    <div className="invite-parent">
                        <div className="invite">
                            <div className="invite__title">
                                {params.id
                                    ? `${t('invite.inviteStudentTo')} ${classData.name}`
                                    : t('invite.inviteStudentToPreSessionalAccess')}
                            </div>
                            <div className="invite__desc">
                                {params.id
                                    ? t('invite.askStudentToScanCodeOrDownloadPdfForSchool')
                                    : t('invite.askStudentToScanCodeOrDownloadPdfForClassroom')}
                            </div>
                            <div className="invite__desc">
                                {params.id
                                    ? t('invite.scannedCodeClassAccess')
                                    : t('invite.scannedCodeSchoolAccessPlacementTest')}
                            </div>
                            <div className="invite__desc">
                                {t('invite.newStudentRegistrationRequired')}
                            </div>
                            <div className="invite__actions">
                                <div className="invite__actions-expire">
                                    {t('invite.expiryDate')}{' '}
                                    <b>
                                        {invites[inviteType].expires
                                            ? moment
                                                  .unix(invites[inviteType].expires)
                                                  .format('DD MMM YYYY')
                                            : ''}
                                    </b>
                                </div>
                                <div className="invite__actions-buttons">
                                    <Button
                                        onClick={() => setPopupAction('extend')}
                                        type="outline-grey"
                                        minWidth={187}
                                        padding="0.75rem"
                                    >
                                        {t('invite.extendDate')}
                                    </Button>
                                    <Button
                                        onClick={() => setPopupAction('invalidate')}
                                        type="quaternary"
                                        minWidth={217}
                                        padding="0.75rem"
                                    >
                                        {t('invite.invalidateAndRegenerate')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            window.open(
                                                invites.student.poster?.pdf,
                                                '_blank',
                                                'noopener,noreferrer',
                                            );
                                        }}
                                        type="secondary"
                                        minWidth={187}
                                        padding="0.75rem"
                                    >
                                        {t('invite.downloadPdf')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {isDesktop ? (
                            <Iframe />
                        ) : (
                            <img
                                className="invite-poster"
                                src={`${invites.student.poster?.png}`}
                                alt="invite poster"
                            />
                        )}
                    </div>
                    <ActionPopup
                        close={() => setPopupAction(null)}
                        action={popupAction}
                        inviteType={inviteType}
                        invites={invites}
                        classId={params.id}
                        isPreSessionalClass={!params.id}
                    />
                </>
            );
        default:
            return <></>;
    }
};

export default Invite;
