import './styles.scss';
import { TableMobRowProps } from './types';

const TableMobRow = ({ title, children }: TableMobRowProps) => {
    return (
        <div className="tableMobileRow" data-testid="tableMobileRow">
            <div className="tableMobileRow-title">
                <b>{title}</b>
            </div>
            <span>{children} </span>
        </div>
    );
};

export default TableMobRow;
