import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'src/api';
import Search from 'src/components/Search';
import { useSearchParams } from 'react-router-dom';
import TableLight from 'src/pages/Profile/ProfileInfo/TableLight';
import { finishLoading, startLoading } from 'src/redux/loading/actions';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { appConstants } from 'src/constants';
import { ElearningProps } from '../ELearning/types';

import './styles.scss';

const LiveClass = ({ setUpdateLesson, updateLesson }: ElearningProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [liveClass, setLiveClass] = useState(
        [] as unknown as {
            ref: string;
            datetime: number;
            description: string;
            duration: number;
            name: string;
            playlistId: number;
            type: string;
        }[],
    );
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const isCurrentLessonAdded = (num: number) => {
        return updateLesson?.activities?.map(({ id }: any) => id).includes(num);
    };

    useEffect(() => {
        dispatch(startLoading());
        API.get()('/ielts/webinars')
            .then((data) => {
                const unique = [
                    ...data
                        .reduce((a: any, c: any) => {
                            a.set(c.playlistId, c);
                            return a;
                        }, new Map())
                        .values(),
                ];

                setLiveClass(unique);
            })
            .finally(() => {
                dispatch(finishLoading());
            });
    }, []);

    return (
        <div className="liveClass">
            <Search
                defaultValue={(searchParams.get('searchTerm') || '').replace(/_/g, ' ')}
                onPushEnter={() => {}}
                onClearButton={() => {}}
                alwaysWide
                onChange={(text: string) => {
                    setSearch(text);
                }}
            />

            <TableLight
                className="liveClass-table"
                header={
                    <>
                        <span className="liveClass-table-head liveClass-table-ref">
                            {t('homework.liveClass.ref')}
                        </span>
                        <span className="liveClass-table-head liveClass-table-time">
                            {t('homework.liveClass.time')}
                        </span>
                        <span className="liveClass-table-head liveClass-table-name">
                            {t('homework.liveClass.name')}
                        </span>
                        <span className="liveClass-table-head liveClass-table-desc">
                            {t('homework.liveClass.description')}
                        </span>
                        <span className="liveClass-table-head liveClass-table-actions">
                            {t('homework.flaspoints.actions')}
                        </span>
                    </>
                }
                rows={
                    liveClass
                        ?.filter(({ ref, name, description }) => {
                            return `${description} ${name} ${ref}`.toLowerCase().includes(search);
                        })
                        .map((classItem: any, i: number) => (
                            <div
                                key={i}
                                className={classNames('liveClass-table-row', {
                                    'liveClass-table-row--extraWidth': classItem.length > 6,
                                })}
                            >
                                <span className="liveClass-table-ref">{classItem.reference}</span>
                                <span className="liveClass-table-time">{classItem.duration}</span>
                                <div className="liveClass-table-name">
                                    <span dangerouslySetInnerHTML={{ __html: classItem.name }} />
                                </div>
                                <div className="liveClass-table-desc">
                                    <span
                                        dangerouslySetInnerHTML={{ __html: classItem.description }}
                                    />
                                </div>
                                <div className="liveClass-table-actions">
                                    <span
                                        onClick={() => {
                                            if (isCurrentLessonAdded(classItem.playlistId)) {
                                                return;
                                            }
                                            const tempAssignment = {
                                                name: classItem.name,
                                                type: 'playlist',
                                                id: classItem.playlistId,
                                                currentlyAdded: true,
                                                preview: `${appConstants.frontend}/playlist/${classItem.playlistId}`,
                                            };
                                            setUpdateLesson((data: any) => ({
                                                ...data,
                                                activities: [...data.activities, tempAssignment],
                                            }));
                                        }}
                                    >
                                        {isCurrentLessonAdded(classItem.playlistId)
                                            ? t('homework.liveClass.added')
                                            : t('homework.liveClass.add')}
                                    </span>
                                </div>
                            </div>
                        )) ?? []
                }
            />
        </div>
    );
};

export default LiveClass;
