import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { ASSIGN_PROGRESS, DELETE_PROGRESS, GET_PROGRESS, UPDATE_PROGRESS } from '../actions';
import { deleteProgressSaga } from './deleteProgressSaga';
import { getProgressSaga } from './getProgressSaga';
import { assignProgressSaga } from './assignProgressSaga';
import { updateProgressSaga } from './updateProgressSaga';

export function* progressWatcher(): SagaIterator {
    yield takeLatest(GET_PROGRESS, getProgressSaga);
    yield takeLatest(DELETE_PROGRESS, deleteProgressSaga);
    yield takeLatest(ASSIGN_PROGRESS, assignProgressSaga);
    yield takeLatest(UPDATE_PROGRESS, updateProgressSaga);
}
