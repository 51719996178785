import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AllStudentsDataType } from './types';

export const getAllStudentsSelector = (state: RootState): AllStudentsDataType =>
    state.allStudents.allStudents;

export const selectAllStudents = createSelector(
    createSelector(getAllStudentsSelector, (item) => item),
    (item) => item,
);
