import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import Modal from '../../Modal';
import './styles.scss';
import { Props } from './types';
import SimpleCalendar from '../../SimpleCalendar';

const newDateState = new Date(moment().startOf('day').format());

const AssignModal = ({
    open,
    onAssign,
    onClose,
    text = 'modals.assign.assignTest',
    buttonText = 'modals.assign.button',
}: Props) => {
    const [date, setDate] = useState<Date>(newDateState);
    const [searchParams] = useSearchParams();
    const [currentDate, setCurrentDate] = useState<Date>();
    const { t } = useTranslation();

    useEffect(() => {
        const searchCurDate = searchParams.get('currentDate');
        if (searchCurDate) {
            let tempDate = new Date();
            if (+searchCurDate > moment().unix()) {
                tempDate = new Date(moment.unix(Number(searchCurDate)).format());
            }
            setDate(tempDate);
            setCurrentDate(tempDate);
        } else {
            setCurrentDate(date);
        }
    }, [searchParams]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <div className="assign-modal">
                    <span className="assign-modal-title">{t(text)}</span>
                    <SimpleCalendar
                        onChange={(changedDate) => {
                            setDate(changedDate);
                        }}
                        defaultDate={currentDate}
                        minDate={newDateState}
                        allowPartialRange
                        selectRange
                        navigationLabelFormat="MMMM YYYY"
                    />
                    <div className="assign-modal-button">
                        <Button
                            type="square"
                            onClick={() => {
                                onAssign(date as never as Date | [Date, Date]);
                                onClose();
                            }}
                        >
                            {t(buttonText)}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AssignModal;
