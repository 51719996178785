import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { useParams } from 'react-router';
import InputRadioButtons from 'src/components/InputRadioButtons';
import { restart } from 'src/redux/weeklyPlan/actions';
import { selectBooks } from 'src/redux/weeklyPlan/selectors';
import { Modules, useIsModuleActive } from 'src/hooks/useIsModuleActive';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import Modal from '../../Modal';
import { RestartRowProps } from './types';
import SelectMenu from '../../SelectMenu';
import ConfirmModal from '../ConfirmModal';

import './styles.scss';

const RestartRow = ({ options, onSelect, selected, title, name }: any) => {
    return (
        <div
            className={classNames('restart-modal-row', {
                'restart-modal-row--disabled': !options.length,
            })}
        >
            <span className="restart-modal-row-name">{title}:</span>
            <SelectMenu
                alwaysWide
                wrapperClass="restart-select"
                text={<div className="restart-truncate">{selected?.name || name}</div>}
                options={options.map((bookData: any) => {
                    return {
                        content: (
                            <div className="restart-option" title={bookData.name}>
                                <span className="restart-truncate">{bookData.name}</span>
                            </div>
                        ),
                        onClick: () => {
                            onSelect(bookData);
                        },
                    };
                })}
            />
        </div>
    );
};

const RestartClass = ({ onClose, open, week, year, onSubmit }: RestartRowProps) => {
    const books = useSelector(selectBooks);
    const [book, setActiveBook] = useState(null as any);
    const [level, setActiveLevel] = useState(null as any);
    const [unit, setActiveUnit] = useState(null as any);
    const [submit, setSubmit] = useState(false);
    const [curated, setCurated] = useState(false);
    const { isModuleActive } = useIsModuleActive();
    const { t } = useTranslation();
    const assignOptions = [
        {
            name: t('modals.restartClass.theWholeBook'),
            val: true,
        },
        {
            name: t('modals.restartClass.thisUnitOnly'),
            val: false,
        },
    ];
    const [assign, setAssign] = useState(assignOptions[0]);
    const params = useParams();
    const dispatch = useDispatch();

    const levelOptions = useMemo(() => {
        return books.find(({ name }: any) => name === book?.name)?.levels || [];
    }, [books, book]);

    const unitOptions = useMemo(() => {
        return levelOptions.find(({ name }: any) => name === level?.name)?.units || [];
    }, [books, level, book]);

    useEffect(() => {
        return () => {
            setActiveBook(null);
            setActiveLevel(null);
            setActiveUnit(null);
            setSubmit(false);
            setCurated(false);
        };
    }, [open]);

    return (
        <>
            <ConfirmModal
                text={
                    assign.val
                        ? t('modals.restartClass.confirmChangeWeeksOnwards')
                        : t('modals.restartClass.confirmChangeThisWeek')
                }
                onClick={() => {
                    dispatch(
                        restart({
                            week,
                            year,
                            unit: unit.id,
                            classId: params.id,
                            fullSet: assign.val,
                            curated,
                            cb: () => {
                                if (onSubmit) {
                                    onSubmit();
                                }
                            },
                        }),
                    );

                    onClose();
                }}
                onClose={() => {
                    onClose();
                }}
                open={submit && open}
            />
            <Modal open={open && !submit} onClose={onClose}>
                <div className="restart-modal">
                    <span className="restart-modal-title">{t('weeklyPlan.changeBookOrUnit')}</span>
                    <div className="restart-modal-body">
                        <RestartRow
                            options={books}
                            onSelect={(data: any) => {
                                setActiveLevel(null);
                                setActiveUnit(null);
                                setActiveBook(data);
                                setCurated(false);
                            }}
                            selected={book}
                            title={t('modals.createClass.chooseBook')}
                            name={t('modals.createClass.bookName')}
                        />
                        <RestartRow
                            options={levelOptions}
                            onSelect={(data: any) => {
                                setActiveLevel(data);
                                setActiveUnit(null);
                                setCurated(false);
                            }}
                            selected={level}
                            title={t('modals.createClass.chooseLevel')}
                            name={t('modals.createClass.levelName')}
                        />
                        <RestartRow
                            options={unitOptions}
                            onSelect={(data: any) => {
                                setActiveUnit(data);
                                setCurated(false);
                            }}
                            selected={unit}
                            title={t('modals.createClass.chooseUnit')}
                            name={t('modals.createClass.unitName')}
                        />
                        <RestartRow
                            options={unit ? assignOptions : []}
                            onSelect={(data: any) => {
                                setAssign(data);
                            }}
                            selected={unit ? assign : ''}
                            title={t('modals.restartClass.assign')}
                            name={t('modals.restartClass.theWholeBook')}
                        />
                        {isModuleActive(Modules.WeeklyPlannerApplyTemplateCurated) && (
                            <div
                                className={classNames('restart-modal-row', {
                                    'restart-modal-row--disabled': !unit || !unit.curated,
                                })}
                            >
                                <span className="restart-modal-row-name">
                                    {t('modals.createClass.aims')}
                                </span>
                                <div className="restart-modal-row-radio-buttons">
                                    <InputRadioButtons
                                        buttons={[
                                            {
                                                label: t('general.all'),
                                                value: t('general.all'),
                                            },
                                            {
                                                label: t('general.core'),
                                                value: t('general.core'),
                                            },
                                        ]}
                                        onChange={(button) => {
                                            setCurated(button.value === t('general.core'));
                                        }}
                                        checkedIndex={curated ? 1 : 0}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className={classNames('restart-modal-button', {
                            'restart-modal-button--disabled': !unit,
                        })}
                    >
                        <Button
                            type="square"
                            onClick={() => {
                                setSubmit(true);
                            }}
                        >
                            {t('general.submit')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export { RestartRow };
export default RestartClass;
